import { Button, Col, Form, Input, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../context/GlobalContext';
import {
  editClosingType,
  newClosingType
} from '../../../../../../helpers/fetchData/fetchClosingTypes';
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const ClosingTypesForm = ({
  drawerData,
  setDrawerData,
  setClosingTypes
}) => {
  const { messageApi, instance } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    if (!values.name || !values.color) {
      messageApi.info('Nombre y color son campos obligatorios');
      return;
    }

    setLoading(true);
    if (!drawerData.item) {
      newClosingType(instance?.userData?.apiKey, values)
        .then((res) => {
          if (res?.status === 200 && res?.data?._id) {
            setClosingTypes((prevState) => {
              let array = [...prevState];

              array.push({
                _id: res.data._id,
                ...values
              });

              return array;
            });

            messageApi.success('Tipo de cierre creado correctamente');
            setDrawerData({ open: false, item: null });
          } else {
            messageApi.error('Ocurrió un error al crear un tipo de cierre');
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error('Ocurrió un error al crear un tipo de cierre');
        })
        .finally(() => setLoading(false));
    } else {
      editClosingType(instance?.userData?.apiKey, drawerData.item._id, values)
        .then((res) => {
          if (res?.status === 200) {
            setClosingTypes((prevState) => {
              return prevState.map((element) => {
                if (element._id === drawerData.item?._id) {
                  return {
                    ...element,
                    ...values
                  };
                } else {
                  return element;
                }
              });
            });

            messageApi.success('Tipo de cierre modificado correctamente');
            setDrawerData({ open: false, item: null });
          } else {
            messageApi.error('Ocurrió un error al editar un tipo de cierre');
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error('Ocurrió un error al editar un tipo de cierre');
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (drawerData?.item) {
      const { name, description, color } = drawerData.item;
      form.setFieldsValue({
        name: name || '',
        description: description || '',
        color: color || '#000000'
      });
    }
  }, [drawerData]);

  return (
    <Form
      className='form-nodos'
      name='closingTypeForm'
      form={form}
      requiredMark='optional'
      layout='vertical'
      onFinish={onFinish}
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>
        {drawerData.item ? 'Editar tipo de cierre' : 'Nuevo tipo de cierre'}
      </p>

      <Row style={{ justifyContent: 'space-between' }}>
        <Col span={16}>
          <Form.Item
            label='Nombre'
            name='name'
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input
              size='large'
              maxLength={25}
              showCount
              onInput={(e) => (e.target.value = e.target.value?.toUpperCase())}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            label='Color'
            name='color'
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
            initialValue='#000000'
          >
            <Input type='color' size='large' />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label='Descripción' name='description'>
        <Input.TextArea
          size='large'
          autoSize={{ minRows: 4 }}
          maxLength={150}
          showCount
        />
      </Form.Item>

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            // loading={loading}
            icon={loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => setDrawerData({ open: false, item: null })}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
