import { useContext, useMemo } from "react";
import { TbUserOff } from "react-icons/tb";
import { GlobalContext } from "../../../context/GlobalContext";
import { getContactName } from "../campaigns/campaignBuilder/utils/getContactName";
import { Empty } from "antd";

export const NotAssignedTab = ({
  data,
  selectedConversation,
  setSelectedConversation,
  busqueda,
}) => {
  const { contactos } = useContext(GlobalContext); // Corregir uso de contactos paginado

  const formattedData = useMemo(() => {
    return data?.map((element) => {
      const contactName =
        contactos?.find(
          (contacto) => contacto.phones?.[0]?.phone === element.phoneNumber
        )?.name || {};

      element = {
        ...element,
        name: getContactName(contactName),
        type: "notAssigned",
      };

      return element;
    });
  }, [data]);

  const filteredData = useMemo(() => {
    let array = formattedData;
    if (busqueda) {
      array = array.filter((element) =>
        element.name?.toLowerCase()?.includes(busqueda.toLowerCase())
      );
    }

    return array;
  }, [formattedData, busqueda]);

  return (
    <div className="chat-list-wrapper">
      {filteredData?.length > 0 ? (
        filteredData.map((element, index) => {
          return (
            <span
              className={
                element.conversationStatus !== "blocked"
                  ? "chat-contact-wrapper fade-in"
                  : "chat-contact-wrapper blocked-conversation"
              }
              onClick={() => setSelectedConversation(element)}
              key={index}
              style={
                selectedConversation?.phoneNumber === element.phoneNumber
                  ? {
                      border: "1px solid var(--primary-color)",
                    }
                  : {}
              }
            >
              <span className="columna" style={{ gap: "6px", flex: 1 }}>
                <span className="horizontal">
                  <p className="chat-contact-name">{element.name}</p>
                  <span style={{ marginLeft: "auto" }}>
                    {element.conversationStatus === "blocked" && (
                      <TbUserOff color="#FF6962" size={16} />
                    )}
                  </span>
                </span>
                <p
                  className="chat-contact-display-message"
                  // id={`lastmessage-${element._id}`}
                >
                  {
                    element.conversation?.[element.conversation.length - 1]
                      ?.text?.body
                  }
                </p>
                {/* <span className="horizontal" style={{ fontSize: "14px" }}> */}

                {/*
                            {renderTimeToExpired(element.time)}
                            {renderLastTime(element.lastSeen)} */}
                {/* </span> */}
              </span>
            </span>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
