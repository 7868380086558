export const handleListIds = (listContent) => {
  let object = { ...listContent };
  let count = 1;

  if (object?.sections?.length > 0) {
    object.sections.forEach((section) => {
      section.rows.forEach((row) => {
        row.id = count.toString();
        count++;
      });
    });
  }

  return object;
};
