import dayjs from "dayjs";

export const renderDateDivider = (message, index, messages) => {
  let flag = false;
  if (index === 0) {
    flag = true;
  } else {
    if (messages?.[index]?.date !== messages?.[index - 1]?.date) {
      flag = true;
    }
  }

  if (flag) {
    return (
      <span className="chat-date-wrapper fade-in" key={`date-${message.id}`}>
        {message.date === dayjs()?.format("DD/MM/YYYY") ? "HOY" : message.date}
      </span>
    );
  }

  return <></>;
};

export const renderLiveDateDivider = (liveConversation, chatFormattedData) => {
  if (liveConversation?.length < 1) {
    return null;
  }

  if (chatFormattedData?.messages) {
    const lastChat =
      chatFormattedData.messages[chatFormattedData.messages.length - 1];
    if (lastChat) {
      const lastMessage = lastChat.messages?.[lastChat.messages.length - 1];

      if (lastMessage) {
        const date = dayjs(lastMessage.timestamp, "x")?.format("DD/MM/YYYY");

        const lastLiveMessage = liveConversation[liveConversation.length - 1];

        const liveDate = dayjs(lastLiveMessage.timestamp, "x")?.format(
          "DD/MM/YYYY"
        );

        if (liveDate !== date) {
          return (
            <span className="chat-date-wrapper fade-in" key={`date-live`}>
              HOY
            </span>
          );
        }
      }
    }
  }

  return null;
};
