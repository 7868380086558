import { useContext } from 'react';
import { GlobalContext } from '../../../../../../../context/GlobalContext';
import { useAccess } from '../../../../../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../../../../../helpers/access';
import { Tooltip } from 'antd';
import { FiEdit3 } from 'react-icons/fi';
import { MdWebhook } from 'react-icons/md';

export const AppInfoActions = () => {
  const { messageApi, application, setAppStage } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const handleWebhookCopy = () => {
    let tenant = `https://webhook.brocoly.ar/webhook?tenant=${application?.[0]?.urlTenant}`;

    if (tenant) {
      navigator.clipboard.writeText(tenant);
      messageApi.info(`Texto copiado: ${tenant}`);
    } else {
      messageApi.info('No se encontró URL');
    }
  };

  return (
    <>
      {isAllowed(accessDataFormat('channels', 'manage')) && (
        <Tooltip title='Editar'>
          <FiEdit3
            size={16}
            style={{ cursor: 'pointer', color: '#252525' }}
            onClick={() => setAppStage(3)}
          />
        </Tooltip>
      )}
      <Tooltip title='Webhook URL'>
        <MdWebhook
          size={16}
          style={{ cursor: 'pointer', color: '#252525' }}
          onClick={() => handleWebhookCopy()}
        />
      </Tooltip>
    </>
  );
};
