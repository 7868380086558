import { Image } from "antd";
import dayjs from "dayjs";

const style = { borderRadius: 4 };
const size = 70;

export const workspacesColumns = () => {
  return [
    { title: "Nombre", key: "orgName", dataIndex: "orgName" },
    { title: "Base de datos", key: "name", dataIndex: "name" },
    {
      title: "Imagen",
      key: "profilePicture_url",
      dataIndex: "profilePicture_url",
      render: (data) => {
        return (
          <Image
            src={data || "/assets/image-placeholder.png"}
            width={size}
            preview={true}
            style={style}
          />
        );
      },
    },
    {
      title: "Fecha de creación",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "right",
      width: 140,
      render: (data) => {
        if (data) {
          return dayjs(data, "x")?.format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
    },
  ];
};
