export const UNIDADES_MEDIDA = [
  { value: "cl", label: "Centilitros" },
  { value: "cm", label: "Centímetros" },
  { value: "g", label: "Gramos" },
  { value: "kg", label: "Kilogramos" },
  { value: "l", label: "Litros" },
  { value: "m", label: "Metros" },
  { value: "cbm", label: "Metros cúbicos" },
  { value: "sqm", label: "Metros cuadrados" },
  { value: "mg", label: "Miligramos" },
  { value: "ml", label: "Mililitros" },
];
