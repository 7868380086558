import { useContext, useEffect, useMemo, useState } from "react";
import { getContactName } from "../campaigns/campaignBuilder/utils/getContactName";
import { ContactsChat } from "./ContactsChat";
import { Empty, Spin } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../context/GlobalContext";
import { LoadingOutlined } from "@ant-design/icons";

export const ContactsTab = ({
  conversations,
  selectedContact,
  setSelectedContact,
  teams,
  busqueda,
  activeTabKey,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [contactos, setContactos] = useState([]); //Corregir uso de contactos paginado
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey && activeTabKey === "contacts") {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/chat/contactsStatus`,
        headers: {
          Authorization: instance.userData.apiKey,
        },
      };

      setLoading(true);

      axios
        .request(config)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setContactos(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener contactos");
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener contactos");
        })
        .finally(() => setLoading(false));
    }
  }, [instance, activeTabKey]);

  const formattedData = useMemo(() => {
    let array =
      contactos?.map((contacto) => {
        contacto = {
          ...contacto,
          name: getContactName(contacto.name),
          phoneNumber: contacto.phones?.[0]?.phone,
        };

        let chat = conversations?.find(
          (chat) => chat.phoneNumber === contacto.phoneNumber
        );

        if (chat) {
          contacto = {
            ...contacto,
            chats: { teamId: chat.teamId, userId: chat.userId },
          };
        }

        return contacto;
      }) || [];

    return array;
  }, [contactos, conversations]);

  const filteredData = useMemo(() => {
    let array = [...formattedData];
    if (busqueda) {
      array = array.filter((element) =>
        element.name?.toLowerCase()?.includes(busqueda.toLowerCase())
      );
    }

    return array;
  }, [formattedData, busqueda]);

  if (loading) {
    return (
      <div className="chat-list-wrapper">
        <Spin
          indicator={<LoadingOutlined size={22} spin />}
          style={{ marginTop: "150px" }}
        />
      </div>
    );
  }

  return (
    <div className="chat-list-wrapper">
      {filteredData?.length > 0 ? (
        filteredData.map((element, index) => {
          return (
            <ContactsChat
              contacto={element}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              teams={teams}
              key={index}
            />
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
