export const httpRequestFallbackOptions = (nodes) => {
  let array = [];

  if (nodes && Array.isArray(nodes)) {
    nodes?.forEach((node) => {
      if (
        [
          "message",
          "buttons",
          "list",
          "iterableList",
          "requestLocation",
        ].includes(node.type)
      ) {
        let string = `#${node.id} ${node.data?.label}`;
        let value = Number(node.id);

        if (node?.data?.auto === false) {
          array.push({ label: string, value: value });
        }
      }
    });
  }

  return array;
};
