import { useContext } from "react";
import {
  getCountByItem,
  handleItemAdd,
  handleItemCount,
  handleItemRemove,
  handleRemoveItemFromCart,
} from "../../utils/home";
import { Button, Image, InputNumber, Tag } from "antd";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { PresupuestosContext } from "../../context/PresupuestosContext";

export const ProductCard = ({ producto, total, idsCart, fromCart = false }) => {
  const { cartDetail, setCartDetail, allProducts } =
    useContext(PresupuestosContext);

  return (
    <div className="product-wrapper">
      <span
        className="horizontal"
        style={{ alignItems: "flex-start", width: "100%" }}
      >
        <span className="img-wrapper">
          <Image
            width="100%"
            src={producto.image_link}
            preview={false}
            style={{ borderRadius: 4 }}
          />
        </span>
        <div className="product-details">
          <div className="product-top-details">
            <p className="product-title">{producto.title}</p>
            <span className="product-description-row">
              <span className="product-description">
                SKU:
                <p className="product-description-highlight">
                  {producto.sku}
                </p>
              </span>
              <span className="product-description brand-description">
                Marca:
                <p className="product-description-highlight">
                  {producto.brand}
                </p>
              </span>
            </span>
            {fromCart  && producto?.listName && (
              <Tag color="green" style={{ marginRight: 0, maxWidth: "100%" }}>
                {producto.listName}
              </Tag>
            )}
          </div>
          <div className="product-box-right">
            <span
              className="horizontal"
              style={
                total
                  ? { marginTop: "auto", gap: 2 }
                  : {
                      marginTop: "auto",
                      marginBottom: 32,
                      gap: 2,
                    }
              }
            >
              <span className="horizontal" style={{ marginRight: 8 }}>
                <p>{`$${producto.price?.split(" ")?.[0]}`}</p>
                {producto.unit && <p>{producto.unit}</p>}
              </span>
              <Button
                onClick={() =>
                  handleItemRemove(
                    producto.id,
                    idsCart,
                    cartDetail,
                    setCartDetail
                  )
                }
              >
                <MinusOutlined size={14} />
              </Button>
              <InputNumber
                value={getCountByItem(producto.id, idsCart, cartDetail)}
                style={{ width: 60 }}
                controls={false}
                type="number"
                onChange={(v) =>
                  handleItemCount(
                    v,
                    producto,
                    setCartDetail,
                    idsCart,
                    cartDetail
                  )
                }
              />
              <Button
                onClick={() =>
                  handleItemAdd(producto, idsCart, cartDetail, setCartDetail)
                }
              >
                <PlusOutlined size={14} />
              </Button>
            </span>

            {total != false && (
              <span
                className="horizontal"
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <span
                  style={{ height: 20 }}
                  className="pointer"
                  onClick={() =>
                    handleRemoveItemFromCart(producto.id, setCartDetail)
                  }
                >
                  <DeleteOutlined size={20} />
                </span>
                <p className="product-total">{`$${total}`}</p>
              </span>
            )}
          </div>
        </div>
      </span>
    </div>
  );
};
