import { useContext, useState } from 'react';
import { Button, Popover, Tooltip } from 'antd';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import axios from 'axios';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const AccionesBloqueos = ({ item, setBloqueados }) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loading, setLoading] = useState(false);

  function deleteBloqueado() {
    setLoading(true);

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/blacklist/manageBlacklist`,
      headers: {
        Authorization: instance?.userData?.apiKey
      },
      data: {
        removeNumbers: [item.phoneNumber]
      }
    };

    axios(config)
      .then(() => {
        setBloqueados((prevState) => {
          let newArray = prevState.filter((i) => {
            return i._id !== item.id;
          });

          return newArray;
        });
        setShowPopover(false);
        messageApi.success(`${item.name} desbloqueado`);
      })
      .catch((error) => {
        console.error(error);
        messageApi.error('Error al desbloquear número');
      })
      .finally(setLoading(false));
  }

  return (
    <div className='TablaBloqueos-acciones'>
      <Tooltip title='Desbloquear' placement='right'>
        <Popover
          open={showPopover}
          content={
            <span className='columna' style={{ maxWidth: 280 }}>
              <p>{`¿Está seguro de desbloquear a ${
                item.name === 'Sin agendar' ? item.phoneNumber : item.name
              }?`}</p>
              <span className='horizontal'>
                <Button
                  type='primary'
                  className='btn-borrar'
                  onClick={deleteBloqueado}
                  icon={
                    loading ? (
                      <LoadingIcon size='small' color='#FFFFFF' />
                    ) : null
                  }
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  type='secondary'
                  className='btn-cancelar'
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </span>
            </span>
          }
        >
          <IoMdCheckmarkCircleOutline
            className='icono-acciones'
            onClick={() => setShowPopover(true)}
          />
        </Popover>
      </Tooltip>
    </div>
  );
};
