import { Button, Drawer, Form, Input, Select, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { updateNode } from "../utils/updateNode";
import { getVariableName } from "../utils/getVariableName";
import { GlobalContext } from "../../../../context/GlobalContext";
import { manageWaitNode } from "../utils/manageWaitNode";
import { MediaForm } from "./MediaForm";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { clearNodeVariables } from "../utils/clearNodeVariables";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { LineaGris } from "../../../linea-gris/LineaGris";

export const MessageForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    mostrarDrawer,
    setMostrarDrawer,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [messageType, setMessageType] = useState("text");
  const [inputText, setInputText] = useState("");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [preview, setPreview] = useState("");
  const [auto, setAuto] = useState(true);
  const [nombreNodo, setNombreNodo] = useState("");
  const [variableType, setVariableType] = useState("string");
  const [mostrarDrawerMedia, setMostrarDrawerMedia] = useState(false);
  const [url, setURL] = useState("");
  const [location, setLocation] = useState({});
  const [caption, setCaption] = useState("");
  const [form] = Form.useForm();

  const handleVariableType = () => {
    if (variableType === "string" || variableType === "number") {
      return ["flow_", "response"];
    } else {
      return [`${variableType}_`, `001`];
    }
  };

  const handleAuto = () => {
    let arrayVar = handleVariableType();

    if (auto === false) {
      return (
        <div>
          <div className="form-item-column">
            <span
              className="horizontal"
              style={{ justifyContent: "space-between" }}
            >
              <Form.Item
                label="Nombre de la variable"
                name="variableName"
                initialValue={nodoSeleccionado?.data?.variableName}
                style={{ width: "63%" }}
              >
                <Input addonBefore={arrayVar[0]} placeholder={arrayVar[1]} />
              </Form.Item>
              <Form.Item
                label="Tipo"
                name="variableType"
                initialValue={
                  nodoSeleccionado?.data?.variable?.type
                    ? nodoSeleccionado?.data?.variable?.type
                    : "string"
                }
                style={{ width: "33%" }}
              >
                <Select
                  options={[
                    {
                      label: "Texto",
                      options: [
                        { value: "string", label: "String" },
                        { value: "number", label: "Number" },
                      ],
                    },
                    {
                      label: "Media",
                      options: [
                        { value: "image", label: "Image" },
                        { value: "audio", label: "Audio" },
                        { value: "document", label: "Document" },
                        { value: "video", label: "Video" },
                        { value: "geo", label: "Location" },
                      ],
                    },
                  ]}
                  onChange={(v) => setVariableType(v)}
                  value={variableType}
                />
              </Form.Item>
            </span>
            <BoxInfo
              message={[
                `En caso de no configurar el nombre de una variable se tomará
                  por defecto "flow_response".`,
                `Recuerde que la respuesta debe respetar el tipo de variable
                  esperada.`,
              ]}
            />
          </div>
        </div>
      );
    }
  };

  const onFinish = (values) => {
    values.label = nombreNodo;
    values.auto = auto;
    // values.bodyContent = bodyContent;
    values.bodyText = inputText;
    values.headerType = messageType;

    if (messageType === "media") {
      values.headerMediaType = headerMediaType;

      if (headerMediaType === "location") {
        if (!location.latitude || !location.longitude) {
          messageApi.info("Latitud y longitud son campos obligatorios");
          return;
        }

        values.location = location;
      } else {
        if (url) {
          values.url = url;
        } else {
          messageApi.info("Ingrese una url");
          return;
        }

        if (caption) {
          values.caption = caption;
          values.bodyText = "";
        }
      }
    } else {
      values.caption = null;
    }

    values.variable = {};

    if (!auto) {
      if (!values.variableName) {
        return messageApi.error("Ingrese un nombre para la variable");
      }
      let variableName = getVariableName(values.variableName, variableType);
      values.variableName = variableName;
      values.variable.name = variableName;
      values.variable.type = variableType;

      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        values.variable,
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;
    } else {
      // En caso de desabilitar "esperar respuesta" hay que borrar la variable
      clearNodeVariables(nodoSeleccionado?.id, setFlowVariables);
    }

    values.handleCount = 1;

    manageWaitNode(nodes, setNodes, edges, setEdges, nodoSeleccionado, auto);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (!mostrarDrawer) {
      setAuto(true);
      setInputText("");
    }
  }, [mostrarDrawer]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      let mediaType = "image";

      if (data.headerMediaType || data.headerMediaImage) {
        mediaType = data.headerMediaType || data.headerMediaImage;
      }

      setHeaderMediaType(mediaType);

      setNombreNodo(data.label);
      if (data.auto !== undefined) {
        setAuto(data.auto);
      } else {
        setAuto(true);
      }

      setVariableType(data.variableType ? data.variableType : "string");

      setInputText(data.bodyText);

      if (data.url) {
        setURL(data.url);
      }

      if (data.location) {
        setLocation(data.location);
      }

      if (data.caption) {
        setCaption(data.caption);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />
      <HeaderTextOrMedia
        messageType={messageType}
        setMessageType={setMessageType}
        nodeId={nodoSeleccionado?.id}
        headerMediaType={headerMediaType}
        setHeaderMediaType={setHeaderMediaType}
        text={inputText}
        setText={setInputText}
        url={url}
        setURL={setURL}
        setPreview={setPreview}
        caption={caption}
        setCaption={setCaption}
        location={location}
        setLocation={setLocation}
        context="message"
      />
      <LineaGris />
      <Form.Item
        name="auto"
        initialValue={!auto}
        style={{ marginBottom: "8px" }}
      >
        <span className="linea-esperar-switch" style={{ marginBottom: "0px" }}>
          <span>Esperar respuesta</span>
          <Switch
            onChange={(v) => {
              setAuto(!v);
            }}
            checked={!auto}
          />
        </span>
      </Form.Item>
      {handleAuto()}
      <Drawer
        open={mostrarDrawerMedia}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <MediaForm
          url={url}
          setURL={setURL}
          setMostrarDrawerMedia={setMostrarDrawerMedia}
          mediaType={headerMediaType}
        />
      </Drawer>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
              setAuto(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
