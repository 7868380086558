import { Button, Form, Input, Popover, Select, Spin, TreeSelect } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AiOutlineStrikethrough } from 'react-icons/ai';
import { BsEmojiSmile } from 'react-icons/bs';
import { FiBold, FiItalic } from 'react-icons/fi';
import { ImWhatsapp } from 'react-icons/im';
import { FlowContext } from '../../../../context/FlowContext';
import {
  agregarVariableWhatsapp,
  formatoTextHtml
} from '../../../../../helpers/utilidadesTemplates';
import { GlobalContext } from '../../../../context/GlobalContext';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import {
  getCatalogDetail,
  getCatalogInstance
} from '../../../../../helpers/fetchData/fetchCatalog';
import { LoadingOutlined } from '@ant-design/icons';
import { updateNode } from '../utils/updateNode';
import { HeaderForm } from './componentsForm/HeaderForm';

export const SingleProductForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [bodyText, setBodyText] = useState('');
  const [bodyHtml, setBodyHtml] = useState('');
  const [loading, setLoading] = useState(true);
  const [catalogDetail, setCatalogDetail] = useState({});
  const [catalogId, setCatalogId] = useState('');

  const [selectedProduct, setSelectedProduct] = useState(null);

  const decorarTexto = (seccion, wrappingString, emoji, variable) => {
    let textArea = document.getElementById(seccion);

    if (textArea) {
      let s = textArea.selectionStart;
      let e = textArea.selectionEnd;

      let oldValue = textArea.value;

      if (
        wrappingString !== '' &&
        wrappingString !== '${' &&
        wrappingString !== 'emoji'
      ) {
        let newValue =
          oldValue.slice(0, s) +
          wrappingString +
          oldValue.slice(s, e) +
          wrappingString +
          oldValue.slice(e, oldValue.length);
        textArea.value = newValue;
      }

      if (wrappingString === '${') {
        switch (seccion) {
          case 'textBody':
            textArea.value = agregarVariableWhatsapp(
              textArea.value,
              variable,
              'textBody'
            );
            break;
          default:
            break;
        }
      }

      if (wrappingString === 'emoji') {
        textArea.value = oldValue + emoji.emoji;
      }

      switch (seccion) {
        case 'textBody':
          setBodyText(textArea.value);
          break;
        default:
          break;
      }

      if (!textArea.value) {
        switch (seccion) {
          case 'textBody':
            setBodyHtml('');
            break;
          default:
            break;
        }
      } else {
        switch (seccion) {
          case 'textBody':
            setBodyHtml(formatoTextHtml(textArea.value));
            break;
          default:
            break;
        }
      }
    }
  };

  const onFinish = (values) => {
    if (!bodyText) {
      return messageApi.info('Es necesario que ingrese cuerpo del mensaje');
    }

    if (!catalogId) {
      return messageApi.info('No se encontró el id del catálogo');
    }

    if (!selectedProduct?.id) {
      return messageApi.info('Es necesario que seleccione un producto');
    }

    values.catalog_id = catalogId;

    values.label = nombreNodo;
    values.auto = false;

    values.bodyText = bodyText;
    values.bodyHtml = bodyHtml;

    values.handleCount = 1;

    values.product = selectedProduct;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { data } = nodoSeleccionado;

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }
      if (data.bodyHtml !== undefined) {
        setBodyHtml(data.bodyHtml);
      }

      setNombreNodo(data.label);

      if (data.product) {
        setSelectedProduct(data.product);
      }
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getCatalogInstance(instance.userData.apiKey, messageApi)
        .then((res) => {
          if (res?.status === 200) {
            setCatalogId(res.data?.catalog?.catalogId);
          }
        })
        .catch((error) => console.log(error));

      getCatalogDetail(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setCatalogDetail(res.data);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, []);

  const treeData = useMemo(() => {
    if (catalogDetail?.products?.length > 0) {
      let array = [];

      array = catalogDetail.products.map((lista) => {
        let listName =
          catalogDetail.lists?.find((element) => element.id === lista._id)
            ?.name || 'Sin lista';

        return {
          title: listName,
          value: `lista-${lista._id}`,
          selectable: false,
          children: lista.products.map((producto) => {
            return {
              title: producto.title,
              value: producto.id
            };
          })
        };
      });

      return array;
    }

    return [];
  }, [catalogDetail]);

  const handleProductChange = (v) => {
    if (!v.value.toString()?.startsWith('lista')) {
      setSelectedProduct({ id: v.value, title: v.label });
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='formBotones'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={32} />
        }
      />

      <Form.Item label='Mensaje'>
        <>
          <Input.TextArea
            className='input-text-area'
            placeholder='Escribir texto'
            maxLength={1024}
            autoSize={{ minRows: 3 }}
            showCount
            id='textBody'
            onChange={() => decorarTexto('textBody', '')}
            value={bodyText}
            style={{ marginTop: '8px' }}
          />
          <div
            className='contenedor-botones-texto'
            style={{ marginTop: '4px', marginBottom: '20px' }}
          >
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '*')}
            >
              <FiBold size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '_')}
            >
              <FiItalic size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '~')}
            >
              <AiOutlineStrikethrough size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '```')}
              style={{ fontSize: '14px' }}
            >
              {`${'</>'}`}
            </div>
            <Popover
              trigger='click'
              content={
                <EmojiPicker
                  onEmojiClick={(e) => decorarTexto('textBody', 'emoji', e)}
                  emojiStyle='native'
                  lazyLoadEmojis={true}
                />
              }
            >
              <div className='boton-texto'>
                <BsEmojiSmile />
              </div>
            </Popover>
            <BotonAgregarVariable
              nodeId={nodoSeleccionado.id}
              seccion='textBody'
              decorarTexto={decorarTexto}
              context='list'
            />
          </div>
        </>
      </Form.Item>
      {loading ? (
        <span className='horizontal'>
          <Spin
            style={{ marginInline: 'auto', marginTop: 50 }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24
                }}
                spin
              />
            }
          />
        </span>
      ) : (
        <>
          <Form.Item label='Producto'>
            <TreeSelect
              style={{
                width: '100%'
              }}
              value={selectedProduct?.id}
              dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto'
              }}
              showSearch
              labelInValue
              filterTreeNode={(input, option) =>
                option.title?.toLowerCase()?.includes(input.toLowerCase())
              }
              treeData={treeData}
              placeholder='Seleccione un producto'
              onChange={(v) => handleProductChange(v)}
            />
          </Form.Item>
        </>
      )}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            id='multiproductOk'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={(e) => {
              e.stopPropagation();
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
