import { getContactName } from "../campaigns/campaignBuilder/utils/getContactName";
import { Empty } from "antd";
import { MyConversationsItem } from "./MyConversationsItem";
import { useContext, useMemo } from "react";
import { GlobalContext } from "../../../context/GlobalContext";

export const MyConversationsTab = ({
  conversations,
  selectedConversation,
  setSelectedConversation,
  teams,
  busqueda,
}) => {
  const { contactos, instance } = useContext(GlobalContext); // Corregir uso de contactos paginado

  const formattedData = useMemo(() => {
    if (conversations?.length < 1) {
      return [];
    }

    let array = conversations
      ?.filter((element) => {
        if (
          element.userId === instance?.userData?.userId &&
          element.status !== "CLOSED"
        ) {
          return true;
        }
        return false;
      })
      ?.map((element) => {
        let contactName =
          contactos?.find(
            (contacto) => contacto.phones?.[0]?.phone === element.phoneNumber
          )?.name || {};

        return {
          ...element,
          name: contactName,
        };
      });

    return array?.map((element) => {
      element = {
        ...element,
        name: getContactName(element.name),
        type: "chat",
      };

      return element;
    });
  }, [conversations]);

  const filteredData = useMemo(() => {
    let array = [...formattedData];
    if (busqueda) {
      array = array.filter((element) =>
        element.name?.toLowerCase()?.includes(busqueda.toLowerCase())
      );
    }

    return array;
  }, [formattedData, busqueda]);

  return (
    <div className="chat-list-wrapper">
      {filteredData?.length > 0 ? (
        filteredData.map((element, index) => {
          return (
            <MyConversationsItem
              element={element}
              setSelectedConversation={setSelectedConversation}
              selectedConversation={selectedConversation}
              teams={teams}
              key={index}
            />
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};
