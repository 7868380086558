import { newAttachedEdge } from "./newAttachedEdge";

export const manageWaitNode = (
  nodes,
  setNodes,
  edges,
  setEdges,
  parent,
  auto
) => {
  if (auto) {
    // SI NO CONFIGURA VARIABLE, BUSCA Y ELIMINA SU WAIT NODE Y SU EDGE
    let filter = edges.filter((edge) => edge.source === parent.id);

    if (filter.length > 0) {
      let idNodo = filter[0].target;

      let nextNode = nodes.filter((node) => node.id === idNodo);
      if (nextNode.length > 0) {
        if (nextNode[0].type === "saveResponse") {
          setEdges((prevState) => [
            ...prevState.filter((edge) => edge.source !== parent.id),
          ]);

          setNodes((prevState) => [
            ...prevState.filter((node) => node.id !== idNodo),
          ]);
        }
      }
    }
  } else {
    if (parent.data.auto === false) {
      // SI YA ERA AUTO FALSE NO MODIFICAR NADA
      return;
    }
    // SI CONFIGURA UNA VARIABLE DEBE CREAR UN NUEVO WAITNODE CON SU EDGE

    let position = {
      x: parent.position?.x ? parent.position.x : parent.xPos,
      y: parent.position?.y ? parent.position.y : parent.yPos,
    };
    let nodo = {
      type: "saveResponse",
      data: {
        label: "saveResponse",
      },
      position: {
        x: position.x + 400,
        y: position.y,
      },
    };
    let id = Number(nodes[nodes.length - 1].id);
    id += 1;
    nodo.id = id.toString();

    // LIMPIA EL SIGUIENTE EDGE PARA DARLE LUGAR AL WAITNODE

    setEdges((prevState) => [
      ...prevState.filter((edge) => {
        if (
          edge.source === parent.id &&
          !edge.sourceHandle?.includes("fallback")
        ) {
          return false;
        } else {
          return true;
        }
      }),
    ]);

    // BUSCA EL EDGE DEL WAIT NODE

    let filtro = edges.filter((edge) => {
      if (edge.source === parent.id && edge.id.startsWith("saveResponse")) {
        return true;
      } else {
        return false;
      }
    });

    // FILTRA EL VIEJO WAIT NODE Y CREA UNO NUEVO. NO ESTOY SEGURO POR QUE HACIA ESTO.

    if (filtro.length > 0) {
      setNodes((prevState) => [
        ...prevState.filter((node) => node.id !== filtro[0].target),
        nodo,
      ]);
    } else {
      setNodes((prevState) => [...prevState, nodo]);
    }

    // CREA UN NUEVO EDGE
    newAttachedEdge("saveResponse", parent.id, id, edges, setEdges);
  }

  return;
};