import { Input, Select, Tooltip } from "antd";
import { useMemo } from "react";
import { getVariableTag } from "../../../utils/getVariableTag";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { BoxInfo } from "../box-info/BoxInfo";
import { handleButtonVars } from "../template-form/utils";

export const ButtonsVariableInput = ({
  boton,
  index,
  section,
  buttonsVars,
  setButtonsVars,
  varOptions,
  placeholder,
}) => {
  const handleModeType = (e) => {
    if (buttonsVarsIndex >= 0) {
      const newState =
        buttonsVars?.[buttonsVarsIndex]?.valueType === "var" ? "text" : "var";

      setButtonsVars((prevState) => {
        let array = [...prevState];

        array[buttonsVarsIndex] = {
          ...array[buttonsVarsIndex],
          valueType: newState,
          value: null,
        };

        return array;
      });
    }
  };

  const formattedVarOptions = useMemo(() => {
    return varOptions?.map((element) => {
      return {
        value: element.value,
        label: (
          <span className="horizontal gap8" style={{ width: "100%" }}>
            {getVariableTag(element.value)}
            <p>{element.label}</p>
          </span>
        ),
        label2: element.label,
      };
    });
  }, [varOptions]);

  const buttonsVarsIndex = useMemo(() => {
    const indice = buttonsVars?.findIndex((element) => element.index === index);

    return indice;
  }, [buttonsVars, index]);

  const buttonData = useMemo(() => {
    const obj = buttonsVars[buttonsVarsIndex];

    return obj;
  }, [buttonsVars, buttonsVarsIndex]);

  return (
    <span
      className="horizontal"
      style={{ marginBottom: "8px", gap: "0px", alignItems: "stretch" }}
    >
      {boton.type === "URL" ? (
        <Tooltip
          title={
            <BoxInfo
              message="Ingrese solo el valor de la variable"
              color="white"
            />
          }
        >
          <span
            className="prefix-select"
            onClick={(e) => handleModeType(e)}
            style={{ cursor: "pointer" }}
          >
            <HiOutlineSwitchHorizontal size={16} style={{ padding: "auto" }} />
          </span>
        </Tooltip>
      ) : (
        <span
          className="prefix-select"
          onClick={(e) => handleModeType(e)}
          style={{ cursor: "pointer" }}
        >
          <HiOutlineSwitchHorizontal size={16} style={{ padding: "auto" }} />
        </span>
      )}
      {buttonData?.valueType === "var" ? (
        <Select
          options={formattedVarOptions}
          className="options-template-variables"
          value={buttonData?.value}
          onChange={(v) =>
            handleButtonVars(
              v,
              buttonsVarsIndex,
              boton.type,
              setButtonsVars,
              "var"
            )
          }
          showSearch
          filterOption={(input, option) =>
            (option.label2 ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Seleccione una variable"
        />
      ) : (
        <Input
          key={`${section}-${index}-i`}
          value={buttonData?.value}
          onChange={(v) =>
            handleButtonVars(
              v.target.value,
              buttonsVarsIndex,
              boton.type,
              setButtonsVars,
              "text"
            )
          }
          placeholder={placeholder}
          style={{ borderRadius: "0px 6px 6px 0px" }}
        />
      )}
    </span>
  );
};
