import { Button, Form, Modal, Select, Table } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { InboxOutlined } from "@ant-design/icons";
import { sendProductsFileData } from "../../../../../../helpers/fetchData/fetchCatalog";
import { articulosColumnsSimple } from "./utils/columns";
import { FiX } from "react-icons/fi";
// import { setListsLocalStorage } from "./utils/manageListsLocalStorage";
import { normFile } from "../../../flows/utils/cargaMedia";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const ModalCargaArchivo = ({
  modalArchivo,
  setModalArchivo,
  listas,
  fetchCatalogDetail,
}) => {
  const { messageApi, instance } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [selectedListId, setSelectedListId] = useState("sin lista");
  const [selectedCurrency, setSelectedCurrency] = useState("ARS");

  const [showDataTable, setShowDataTable] = useState({
    visible: false,
    data: null,
  });

  const ACCEPTED_TYPES =
    "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const customRequest = (v) => {
    if (ACCEPTED_TYPES.includes(v.file.type)) {
      if (v.file.size < 1048576 * 5) {
        v.onSuccess();
      } else {
        messageApi.error(`El tamaño excede el limite 5MB`);
        v.onError(`El tamaño excede el limite 5MB`);
      }
    } else {
      messageApi.error("El formato no es válido");
      v.onError("El formato no es válido");
    }
  };

  const onFinish = async (values) => {
    if (!selectedCurrency) {
      return messageApi.error("Es necesario que seleccione una divisa");
    }

    if (!values.file) {
      return messageApi.error("Es necesario que seleccione un archivo");
    }

    let myHeaders = new Headers();

    myHeaders.append("Authorization", instance?.userData?.apiKey);

    let blob = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () =>
        resolve(new Blob([reader.result], { type: values.file[0]?.type }));
      reader.readAsArrayBuffer(values.file[0]?.originFileObj);
    });

    let formData = new FormData();
    formData.append("file", blob, values.file[0]?.name);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_PRODUCCION}/readFile`, requestOptions)
      .then((res) => res?.text())
      .then((result) => {
        let object = JSON.parse(result);

        let error = null;

        if (object.status === 200) {
          messageApi.info(object.message);

          let productos = object.data.map((producto) => {
            let precio = producto?.price?.toString()?.trim() || "";
            precio = parseFloat(precio.replace(",", ".")?.split(" ")?.[0]);

            if (Number.isNaN(precio)) {
              error = producto.title;
            }

            precio = precio.toFixed(2).replace(".", ",");

            return {
              ...producto,
              price: precio,
            };
          });

          if (!error) {
            setShowDataTable({ visible: true, data: productos });
          } else {
            messageApi.info(
              `El producto ${error} no cumple el formato de precio esperado`
            );
          }
        } else {
          messageApi.error("Ocurrió un error al procesar el archivo");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al procesar el archivo");
      })
      .finally(() => setLoading(false));
  };

  const handleConfirmar = () => {
    let data = {
      data: showDataTable.data,
    };

    setLoading(true);
    sendProductsFileData(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data?.message === "OK") {
            messageApi.success("Artículos cargados correctamente");

            if (res.data.result?.insertados) {
              messageApi.info(
                `Se insertaron ${res.data.result.insertados} artículos`
              );
            }

            if (res.data.result?.actualizados) {
              messageApi.info(
                `Se actualizaron ${res.data.result.actualizados} artículos`
              );
            }

            // setListsLocalStorage(selectedListId);
            
            fetchCatalogDetail();

            setModalArchivo({ visible: false, data: null });
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const handleCerrar = () => {
    form.resetFields();
    setShowDataTable({
      visible: false,
      data: null,
    });
    setModalArchivo({ visible: false, data: null });
  };

  return (
    <Modal
      open={modalArchivo.visible}
      footer={null}
      width={showDataTable.visible ? "85%" : 500}
      destroyOnClose
      style={{ maxHeight: "80%", overflowY: "auto", left: 35 }}
      closeIcon={false}
    >
      {showDataTable.visible ? (
        <div className="columna">
          <span className="horizontal" style={{ marginBottom: 16 }}>
            <Select
              style={{ width: "50%", maxWidth: 200, marginRight: 4 }}
              options={[...listas, { id: "sin lista", name: "Sin lista" }]?.map(
                (element) => {
                  return {
                    value: element.id,
                    label: element.name,
                  };
                }
              )}
              value={selectedListId}
              onChange={(v) => {
                setSelectedListId(v);
                setSelectedCurrency(
                  listas.find((element) => element.id === v)?.currency
                );
              }}
              placeholder="Seleccione una lista"
            />
            {selectedListId !== "sin lista" ? (
              <span>
                <b>Divisa</b>{" "}
                <b
                  style={{
                    display: "inline",
                    color: "var(--primary-color)",
                  }}
                >
                  {
                    listas.find((element) => element.id === selectedListId)
                      ?.currency
                  }
                </b>
              </span>
            ) : (
              <p style={{ color: "#8d8d8d" }}>
                Seleccionar lista para asignar una divisa
              </p>
            )}
            <Button
              style={{ marginLeft: "auto", width: "fit-content", opacity: loading ? 0.65 : 1 }}
              type="primary"
              className="btn-guardar"
              //loading={loading}
              icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
              onClick={() => handleConfirmar()}
            >
              Subir
            </Button>
            <Button
              onClick={() =>
                setShowDataTable((prevState) => {
                  return { ...prevState, visible: false };
                })
              }
              style={{ width: "fit-content" }}
              type="secondary"
              className="btn-cancelar"
            >
              Volver
            </Button>
            <FiX
              style={{
                cursor: "pointer",
                marginBottom: "auto",
                marginLeft: 16,
              }}
              size={18}
              onClick={() => handleCerrar()}
            />
          </span>
          <Table
            columns={articulosColumnsSimple()}
            dataSource={showDataTable.data || []}
            rowKey={(data) => {
              return JSON.stringify(data);
            }}
            size="small"
            pagination={false}
          />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="newArchivo"
          requiredMark={false}
          onFinish={(v) => onFinish(v)}
          className="form-nodos"
        >
          <Form.Item
            style={{ marginBottom: 80 }}
            name="file"
            rules={[{ required: true, message: "Seleccione un archivo" }]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Dragger
              name="file"
              customRequest={customRequest}
              maxCount={1}
              accept={ACCEPTED_TYPES}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Haga click o arrastre un archivo aquí
              </p>
              <p className="ant-upload-hint">{`Soporta archivos en formato ${ACCEPTED_TYPES}.`}</p>
              <p className="ant-upload-hint">{`Con un máximo de 5MB.`}</p>
            </Dragger>
          </Form.Item>
          <Form.Item className="form-custom-footer">
            <div className="botones-wrapper-content">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-guardar"
                size="large"
                //loading={loading}
                icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                className="btn-cancelar"
                size="large"
                type="secondary"
                onClick={() => handleCerrar()}
              >
                Cancelar
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
