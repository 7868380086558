import { Table } from 'antd';
import { DrawerNuevoTipoTicket } from './DrawerNuevoTipoTicket';
import { useContext, useEffect, useState } from 'react';
import { getTickets } from '../../../../../../helpers/fetchData/fetchTickets';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { columnasTiposTickets } from './utils';
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const TicketTypes = ({ showDrawerNuevo, setShowDrawerNuevo }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [tiposTicket, setTiposTicket] = useState([]);
  const [loadingTabla, setLoadingTabla] = useState(false);

  // Obtener y mostrar tipos de ticket:
  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTabla(true);

      getTickets(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setTiposTicket(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error('Error al cargar la tabla de tipos de ticket');
        })
        .finally(() => setLoadingTabla(false));
    }
  }, [instance]);
  return (
    <div>
      <Table
        className='tabla-config'
        columns={columnasTiposTickets(setTiposTicket)}
        dataSource={tiposTicket || []}
        //loading={loadingTabla}
        loading={{
          spinning: loadingTabla,
          indicator: <LoadingIcon size="large" />,
        }}
        size='small'
        rowKey='_id'
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} tipos de ticket`
        }}
      />

      <DrawerNuevoTipoTicket
        showDrawerNuevo={showDrawerNuevo}
        setShowDrawerNuevo={setShowDrawerNuevo}
        setTiposTicket={setTiposTicket}
      />
    </div>
  );
};
