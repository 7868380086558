export const getVariableName = (variableName, type, isIndexedVar = false) => {
  let name = variableName;
  let comienzo;

  if (["string", "number"].includes(type)) {
    comienzo = "flow_";
    if (!name) {
      name = "response";
    }
  } else {
    comienzo = `${type}_`;
    if (!name) {
      name = "001";
    }
  }

  if (!name.startsWith(comienzo)) {
    name = comienzo + name;
  }

  if (isIndexedVar) {
    let firstUnderscoreIndex = name.indexOf("_");
    let secondUnderscoreIndex = name.indexOf("_", firstUnderscoreIndex + 1);
    name =
      name.slice(0, secondUnderscoreIndex) +
      name.slice(secondUnderscoreIndex + 1);
  }

  return name;
};
