import { Button, Popover, Tooltip } from 'antd';
import { FiCopy, FiDownload, FiEdit3 } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { DrawerEditFile } from './DrawerEditFile';
import { deleteMediaResource } from '../../../../../../../helpers/fetchData/fetchMedia';
import { downloadFileFromURL } from '../../../../../../../utils/downloadFile';
import { mimeToExtensionMap } from '../../../../../../../utils/mimeToExtensionMap';

export const AccionesMedia = ({ item, setFiles }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingEliminar, setLoadingEliminar] = useState(false);
  const [showPopoverEliminar, setShowPopoverEliminar] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(item.publicURL);
    messageApi.info(`URL copiada: ${item.publicURL}`);
  };

  const deleteFile = () => {
    if (instance && item) {
      setLoadingEliminar(true);
      deleteMediaResource(instance.userData?.apiKey, item._id)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Archivo eliminado correctamente');

            setFiles((prevState) => {
              const array = prevState.filter(
                (element) => element._id !== item._id
              );

              return array;
            });
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('Error al eliminar el archivo');
        })
        .finally(() => setLoadingEliminar(false));
    }
  };

  const handleDownload = () => {
    const { publicURL, name, mimetype } = item;

    const extension = mimeToExtensionMap[mimetype] || mimetype.split('/')[1];
    const fileNameWithExtension = `${name}.${extension}`;

    downloadFileFromURL(publicURL, fileNameWithExtension, mimetype);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8
        }}
      >
        <Tooltip title='Copiar URL'>
          <span style={{ height: 18 }}>
            <FiCopy className='icono-acciones' onClick={handleCopyUrl} />
          </span>
        </Tooltip>

        <Tooltip title='Editar'>
          <span style={{ height: 18 }}>
            <FiEdit3
              className='icono-acciones'
              onClick={() => setOpenEditDrawer(true)}
            />
          </span>
        </Tooltip>

        <Tooltip title='Descargar'>
          <span style={{ height: 18 }}>
            <FiDownload className='icono-acciones' onClick={handleDownload} />
          </span>
        </Tooltip>

        {/* <Popover
          open={showPopoverEliminar}
          content={
            <span className='columna' style={{ maxWidth: 280 }}>
              <p>{`¿Está seguro de eliminar ${item.name}?`}</p>

              <span className='horizontal'>
                <Button
                  type='primary'
                  className='btn-borrar'
                  icon={
                    loadingEliminar ? (
                      <LoadingIcon size='small' color='#FFFFFF' />
                    ) : null
                  }
                  style={{ opacity: loadingEliminar ? 0.65 : 1 }}
                  onClick={deleteFile}
                >
                  Confirmar
                </Button>
                <Button
                  type='secondary'
                  className='btn-cancelar'
                  onClick={() => setShowPopoverEliminar(false)}
                >
                  Cancelar
                </Button>
              </span>
            </span>
          }
        >
          <Tooltip title='Eliminar'>
            <span style={{ height: 18 }}>
              <TbTrash
                className='icono-acciones'
                onClick={() => setShowPopoverEliminar(true)}
              />
            </span>
          </Tooltip>
        </Popover> */}
      </div>

      <DrawerEditFile
        item={item}
        setFiles={setFiles}
        open={openEditDrawer}
        setOpen={setOpenEditDrawer}
      />
    </>
  );
};
