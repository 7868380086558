import { Button, Popover, Tag, Tooltip } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FiEye } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import { accessDataFormat } from "../../../../../../helpers/access";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const templateColumns = (setShowTemplate) => {
  const TemplateAcciones = ({ item }) => {
    const { messageApi } = useContext(GlobalContext);

    const isAllowed = useAccess();

    const [showPopover, setShowPopover] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
      <span
        className="horizontal gap8"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Tooltip title="Ver">
          <FiEye
            size={16}
            style={{ cursor: "pointer" }}
            onClick={() => setShowTemplate({ open: true, item: item })}
          />
        </Tooltip>
        {isAllowed(accessDataFormat("templates", "manage")) && (
          <Popover
          open={showPopover}
          content={
            <div className="columna">
              <p>¿Está seguro de borrar esta plantilla?</p>
              <span className="horizontal">
                <Button
                  type="primary"
                  className="btn-borrar"
                  // onClick={() => handleDeleteResource()}
                  //loading={loading}
                  icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  type="secondary"
                  className="btn-cancelar"
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </span>
            </div>
          }
        >
          <Tooltip title="Eliminar">
            <TbTrash
              size={16}
              onClick={() => setShowPopover(true)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </Popover>
        )}
      </span>
    );
  };

  return [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (data, item) => {
        return (
          <span
            className="horizontal"
            onClick={() => setShowTemplate({ open: true, item: item })}
            style={{ cursor: "pointer" }}
          >
            <b>{data}</b>
          </span>
        );
      },
    },
    {
      title: "Categoría",
      dataIndex: "category",
      key: "category",
      render: (data) => <Tag color="geekblue">{data}</Tag>,
      filterSearch: true,
      onFilter: (value, record) => record.category.includes(value),
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Lenguaje",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => {
        let status = getStatusData(data);

        return <Tag color={status.color}>{status.name}</Tag>;
      },
    },
    {
      title: "...",
      dataIndex: "",
      key: "",
      align: "center",
      render: (data, item) => <TemplateAcciones item={item} />,
    },
  ];
};

const getStatusData = (status) => {
  switch (status) {
    case "APPROVED":
      return {
        name: "APROBADO",
        color: "green",
      };
    case "UNAPPROVED":
      return {
        name: "No aprobado",
        color: "red",
      };
    default:
      return {
        name: "PENDIENTE",
        color: "geekblue",
      };
  }
};
