import { useContext, useState, useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { GlobalContext } from "../../../context/GlobalContext";
import { actualizarLista } from "./helpers/actualizarLista";
import { postCliente } from "./fetchData/formCliente/postCliente";
import { putCliente } from "./fetchData/formCliente/putCliente";
import LoadingIcon from "../../Loader/LoadingIcon";
import "./formClientes.css";

function FormClientes({
  editarClientesValues,
  setDrawerFormCliente,
  setCliente,
  idTablaSelecc,
  setRefetchClientes,
}) {
  const [formCliente] = Form.useForm();

  const { messageApi, instance } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();

  useEffect(() => {
    if (editarClientesValues) {
      // console.log("editarClientesValues", editarClientesValues);
      formCliente.setFieldsValue({
        name: editarClientesValues.name,
        email: editarClientesValues.email,
        cuit: editarClientesValues.cuit,
        phone: editarClientesValues.phone,
        type: editarClientesValues.type,
        idSistema: editarClientesValues.idSistema,
      });

      setType(editarClientesValues.type);
    }
  }, []);

  const onFinishCrear = (v) => {
    // console.log("crear cliente", v);
    if (instance?._id) {
      let data = {
        name: v.name?.trim() || "",
        email: v.email?.trim() || "",
        cuit: v.cuit?.trim() || "",
        phone: v.phone?.trim() || "",
        type: v.type,
        state: 1,
        idSistema: v.idSistema || "",
        contacts: [],
      };

      setLoading(true);

      postCliente(data, instance)
        .then((res) => {
          // console.log("res", res);
          if (res?.status === 201) {
            //console.log(res?.data);
            messageApi.success("Nuevo cliente agregado");
            // actualizarLista(
            //     "crear",
            //     data,
            //     setClientes,
            //     res?.data.new_id,
            //     idTablaSelecc
            // );
            setRefetchClientes((prevState) => {
              // console.log("prevState setRefechClientes", prevState);

              return !prevState;
            });

            setDrawerFormCliente(false);
          } else {
            messageApi.error("Ocurrió un error al crear cliente");
          }
        })
        .catch((error) => {
          // console.log('error catch', error);
          if (error.response && error.response.status === 409) {
            return messageApi.error(
              "El valor de cuenta ya se encuentra en uso"
            );
          }
          messageApi.error("Ocurrió un error al crear cliente");
        })
        .finally(() => setLoading(false));
    }
  };

  const onFinishEditar = (v) => {
    // console.log("editar cliente", v);

    if (instance?._id) {
      let data = {
        _id: editarClientesValues._id,
        name: v.name?.trim() || "",
        email: v.email?.trim() || "",
        cuit: v.cuit?.trim() || "",
        phone: v.phone?.trim() || "",
        type: v.type,
        idSistema: v.idSistema ? v.idSistema.trim() : "",
        contacts: editarClientesValues.contacts,
      };

      setLoading(true);

      putCliente(data, instance)
        .then((res) => {
          // console.log(res);
          if (res?.status === 200) {
            messageApi.success("Cliente editado correctamente");
            setDrawerFormCliente(false);
            setCliente(undefined);
            // actualizarLista(
            //     "editar",
            //     data,
            //     setClientes,
            //     editarClientesValues._id,
            //     idTablaSelecc
            // );
            setRefetchClientes((prevState) => {
              //console.log('prevState setRefechClientes', prevState)

              return !prevState;
            });
          } else {
            messageApi.error("Ocurrió un error al editar un cliente");
          }
        })
        .catch((error) => {
          // console.log('error catch', error);
          if (error.response && error.response.status === 409) {
            return messageApi.error(
              "El valor de cuenta ya se encuentra en uso"
            );
          }
          messageApi.error("Ocurrió un error al editar cliente");
        })
        .finally(() => setLoading(false));
    }
  };

  // console.log('editarClientesValues', editarClientesValues)

  return (
    <Form
      form={formCliente}
      onFinish={editarClientesValues ? onFinishEditar : onFinishCrear}
      layout="vertical"
      style={{ padding: "24px" }}
      className="form-clientes"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>
        {editarClientesValues ? "Editar cliente" : "Nuevo cliente"}
      </p>

      <Form.Item
        label="Razón Social"
        name="name"
        rules={[
          {
            required: true,
            message: "Campo requerido",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>

      <Form.Item
        label="CUIT"
        name="cuit"
        rules={[
          {
            required: true,
            message: "Campo requerido",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Teléfono"
        name="phone"
        rules={[
          {
            pattern: new RegExp(/^[0-9]*$/),
            message: "No se permiten espacios ni caracteres especiales",
          },
          {
            len: 12,
            message:
              "El número debe contener 12 caracteres, (ejemplo 543535123456)",
          },
          // {
          //     required: true,
          //     message: 'Campo requerido'
          // }
        ]}
      >
        <Input placeholder="543535362409" />
      </Form.Item>

      <span className="form-clientes-horizontal">
        <Form.Item
          name="type"
          label="Tipo"
          className="half-50"
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Select
            onChange={(v) => setType(v)}
            options={[
              { value: "LEAD", label: "LEAD" },
              { value: "CLIENT", label: "CLIENTE" },
            ]}
          />
        </Form.Item>

        {type === "CLIENT" ? (
          <Form.Item
            label="Cuenta"
            name="idSistema"
            className="half-50"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          ""
        )}
      </span>

      <Form.Item className="form-clientes-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={
              loading ? <LoadingIcon size="default" color="#FFFFFF" /> : null
            }
            //loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              // setSideDrawerConfig({
              //     visible: false,
              //     content: "",
              // })
              setCliente(undefined);
              setDrawerFormCliente(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default FormClientes;
