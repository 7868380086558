import { Button, Result, Tabs, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import './index.css';
import { ImWhatsapp } from 'react-icons/im';
import { Estadisticas } from '../statistics/Estadisticas';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { PerfilNegocio } from '../perfilNegocio/PerfilNegocio';
import { getBusinessCompliance } from '../../../../../../../helpers/fetchData/fetchProfile';
import { AppCard } from './cardWapp/AppCard';

export const CardWapp = () => {
  const {
    instance,
    setAppStage,
    application,
    whatsappProfile,
    loadingProfile
  } = useContext(GlobalContext);

  const [loadingBusinessCompliance, setLoadingBusinessCompliance] =
    useState(false);
  const [businessCompliance, setBusinessCompliance] = useState([]);

  useEffect(() => {
    if (instance) {
      setLoadingBusinessCompliance(true);
      getBusinessCompliance(application, instance.userData?.apiKey)
        .then((res) => {
          setBusinessCompliance(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoadingBusinessCompliance(false));
    }
  }, [application, instance]);

  const items = [
    {
      key: 1,
      label: 'Perfil del negocio',
      children: <PerfilNegocio />
    },
    {
      key: 2,
      label: 'Estadísticas',
      children: <Estadisticas />
    }
  ];

  return application?.length > 0 ? (
    <>
      <AppCard
        businessCompliance={businessCompliance}
        loading={loadingBusinessCompliance}
      />

      {whatsappProfile.length > 0 ? (
        <div className='conf-content fade-in' style={{ maxWidth: 1200 }}>
          <Tabs items={items} />
        </div>
      ) : (
        <Spin
          style={{
            marginTop: 100
          }}
          indicator={<LoadingIcon size='large' />}
        />
      )}
    </>
  ) : (
    <>
      <Result
        className='fade-in'
        icon={<ImWhatsapp size={56} color='var(--primary-color)' />}
        title='No existe perfil creado'
        subTitle='Es necesario crear un perfil para poder utilizar la plataforma.'
        extra={[
          <div style={{ display: 'flex', justifyContent: 'center' }} key={0}>
            <Button
              type='primary'
              key='console'
              className='btn-agregar'
              onClick={() => {
                setAppStage(3);
              }}
            >
              Editar aplicación
            </Button>
          </div>
        ]}
      />
    </>
  );
};
