import { Button, Popover, Tooltip } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { getContactName } from "../../../../campaigns/campaignBuilder/utils/getContactName";
import LoadingIcon from "../../../../../Loader/LoadingIcon";
import { unarchiveContact } from "../../../../../../../helpers/fetchData/fetchArchivedContacts";
import { MdOutlineUnarchive } from "react-icons/md";

export const DesarchivarContacto = ({ item }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Tooltip title="Desarchivar">
      <Popover
        open={showPopover}
        content={
          <PopoverDesarchivar item={item} setShowPopover={setShowPopover} />
        }
      >
        <span>
          <MdOutlineUnarchive
            className="icono-acciones"
            onClick={() => setShowPopover(true)}
          />
        </span>
      </Popover>
    </Tooltip>
  );
};

const PopoverDesarchivar = ({ item, setShowPopover }) => {
  const { messageApi, instance, setContactos } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  return (
    <span className="columna" style={{ maxWidth: 280 }}>
      <p>{`¿Está seguro de archivar a ${getContactName(item.name)}?`}</p>
      <span className="horizontal">
        <Button
          type="primary"
          className="btn-borrar"
          onClick={() =>
            unarchiveContact(
              item,
              instance,
              messageApi,
              setContactos,
              setLoading,
              setShowPopover
            )
          }
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Confirmar
        </Button>
        <Button
          type="secondary"
          className="btn-cancelar"
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </span>
    </span>
  );
};
