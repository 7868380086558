import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { getDraftWorkspaces } from "../../../../../../helpers/fetchData/fetchBrocolyAdmin";
import { Input, Table } from "antd";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import { solicitudesColumns } from "./columns";
import { BsQuestionSquare } from "react-icons/bs";

const style = { width: "100%", maxWidth: 1200 };

export const Solicitudes = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [drafts, setDrafts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [busqueda, setBusqueda] = useState("");

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getDraftWorkspaces(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              res.data.sort((a, b) => b.createdAt - a.createdAt);
              setDrafts(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener solicitudes");
          }
        })
        .catch((error) => {
          console.log("error", error);
          messageApi.error("Ocurrió un error al obtener solicitudes");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  const filteredData = useMemo(() => {
    if (drafts?.length < 1) {
      return [];
    }

    let array = [...drafts];

    if (busqueda) {
      const search = busqueda.toLowerCase();
      array = array.filter((element) => {
        if (element.orgName?.toLowerCase()?.includes(search)) {
          return true;
        }

        if (element.contactName?.toLowerCase()?.includes(search)) {
          return true;
        }

        if (element.userEmail?.toLowerCase()?.includes(search)) {
          return true;
        }

        if (element.phoneNumber?.toLowerCase()?.includes(search)) {
          return true;
        }

        if (element.status?.toLowerCase()?.includes(search)) {
          return true;
        }

        return false;
      });
    }

    return array;
  }, [drafts, busqueda]);

  return (
    <div
      className="config-main-wrapper"
      style={{ width: "clamp(500px, 1000px, 100%)" }}
    >
      <div className="test-main-header" style={style}>
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <BsQuestionSquare size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Solicitudes</span>
            </span>
            <p className="header-subtitle">
              Borradores de workspaces pendientes de aprobación
            </p>
          </span>

          <Input.Search
            style={{ maxWidth: 250 }}
            value={busqueda}
            placeholder="Buscar..."
            onChange={(v) => setBusqueda(v.target.value)}
          />
        </span>
      </div>

      <div className="tabla-config" style={{ maxWidth: 1200 }}>
        <Table
          columns={solicitudesColumns(setDrafts)}
          dataSource={filteredData}
          loading={{
            spinning: loading,
            indicator: <LoadingIcon size="large" />,
          }}
          rowKey={"_id"}
          size="small"
          className="tabla-config"
          style={style}
        />
      </div>
    </div>
  );
};
