import { Button, Result } from "antd";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { TbMessagePlus } from "react-icons/tb";
import LoadingIcon from '../../../Loader/LoadingIcon';

export const Initiate = ({
  setConfigTemplate,
  loadingNewChat,
  sendDefaultTemplate,
  defaultTemplate,
  loadingDefaultTemplate,
}) => {
  return (
    <span className="chat-inner-input-wrapper">
      <Result
        icon={<TbMessagePlus size={24} />}
        style={{ padding: "0px" }}
        status="info"
        title={<p style={{ fontSize: "15px" }}>Conversacion sin iniciar</p>}
        subTitle={
          <p style={{ fontSize: "13px" }}>
            Puede iniciarla enviando una plantilla
          </p>
        }
        extra={[
          <span
            className="horizontal"
            style={{ justifyContent: "center" }}
            key="extra"
          >
            {defaultTemplate && (
              <Button
                type="primary"
                onClick={() => sendDefaultTemplate()}
                className="horizontal gap0"
                //loading={loadingDefaultTemplate}
                icon={loadingDefaultTemplate ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                style={{ opacity: loadingDefaultTemplate ? 0.65 : 1 }}
              >
                Inicio rápido
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => setConfigTemplate({ open: true, data: null })}
              // loading={loadingNewChat}
              // icon={<FaRegFilePowerpoint />}
              icon={loadingNewChat ? <LoadingIcon size="small" color="#FFFFFF" /> : <FaRegFilePowerpoint />}
              style={{ opacity: loadingNewChat ? 0.65 : 1 }}
              className="horizontal gap0"
            >
              Seleccionar plantilla
            </Button>
          </span>,
        ]}
      />
    </span>
  );
};
