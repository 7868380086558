import { useContext, useEffect, useState } from "react";
import { CreateErrorMessages } from "./CreateErrorMessages";
import { EditErrorMessages } from "./EditErrorMessages";
// import { BiMessageError } from "react-icons/bi";
import { Spin } from "antd";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { fetchConfig } from "../../../../../../../helpers/fetchData/fetchConfig";
import LoadingIcon from "../../../../../Loader/LoadingIcon";
import { BoxInfo } from "../../../../../box-info/BoxInfo";

export const ErrorMessages = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [config, setConfig] = useState(null);
  const [errorMessagesStage, setErrorMessagesStage] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleStage = () => {
    switch (errorMessagesStage) {
      case 0:
        return (
          <CreateErrorMessages setErrorMessagesStage={setErrorMessagesStage} />
        );
      case 1:
        return (
          <EditErrorMessages
            setErrorMessagesStage={setErrorMessagesStage}
            config={config}
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (instance?._id) {
      fetchConfig(instance, setConfig, messageApi);
    } else {
      setLoading(false);
    }
  }, [instance]);

  useEffect(() => {
    if (config) {
      if (config.defaultMessages) {
        setErrorMessagesStage(1);
      }

      setLoading(false);
    }
  }, [config]);

  return (
    <div>
      {/* <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <BiMessageError size={24} color="#E57373" />
              </span>
              <span className="header-title-templates">Mensajes de error</span>
            </span>
           
          </span>
        </span>
      </div> */}
      {/* <p className="header-subtitle">
        Configure mensajes que se dispararán en distintos casos de error.
      </p> */}
      <BoxInfo
        message={
          "Configure mensajes que se dispararán en distintos casos de error."
        }
      />
      {loading ? (
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spin
            style={{
              marginTop: 100,
              marginInline: "auto",
            }}
            indicator={<LoadingIcon size="large" />}
          />
        </span>
      ) : (
        instance?._id && <>{handleStage()}</>
      )}
    </div>
  );
};
