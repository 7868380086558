import { Button, Form, Upload, Select, Switch } from "antd";
import { useContext, useEffect, useState, useMemo } from "react";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import { InboxOutlined } from "@ant-design/icons";
// import { customRequest } from "../../../campaigns/campaignBuilder/utils/template";
import { flattenFirstElementGetProducts } from "../../../flows/utils/flattenJSON";
import { upsertClientConfig } from "../../../../../../helpers/fetchData/fetchClientConfig";
import { executeExternalClientsLoadExcel } from "../../../../../../helpers/fetchData/fetchClients";
import { LoadNotification } from "../catalog/budgetConfigSteps/LoadNotification";
import { customRequest } from "../../../../template-form/utils";

export const ClientsLoadExcel = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setMostrarRequestDrawer,
  setClientsConfig,
}) => {
  const { messageApi, instance, notificationApi } = useContext(GlobalContext);

  const { Dragger } = Upload;

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Excel
  const [xlsData, setXlsData] = useState([]);

  const handleGuardar = async (values) => {
    try {
      if (!xlsData) {
        messageApi.info("Es necesario que ingrese un archivo excel valido");
        return;
      }

      let objMap = {
        idSistema: values.idSistema, // Cuenta, identificador usado por ellos.
        name: values.name,
        email: values.email,
        cuit: values.cuit,
        phone: values.phone,
      };

      setTmpConfig((prevState) => {
        let obj = { ...prevState };

        let clientsLoadExcel = { ...obj.clientsLoadExcel };

        clientsLoadExcel.objMap = objMap;

        obj.clientsLoadExcel = clientsLoadExcel;
        return obj;
      });

      let clientsLoad = tmpConfig.clientsLoad;
      let clientsLoadExcel = {
        objMap: objMap,
        bajaClient: values.chkEnableBaja,
      };

      let data = {
        clientsLoad: clientsLoad,
        clientsLoadExcel: clientsLoadExcel,
      };
      // Guardado de configuracion
      setLoading(true);
      upsertClientConfig(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Configuración guardada correctamente");
            setClientsConfig((prevState) => {
              let obj = { ...prevState };

              obj.clientsLoadExcel = clientsLoadExcel;

              return obj;
            });
            setMostrarRequestDrawer(false);

            //Sincronizar clientes brocoly - excel
            executeExternalClientsLoadExcel(instance?.userData?.apiKey, xlsData)
              .then((res) => {
                console.log(res);
                if (res?.status === 200) {
                  notificationApi.success({
                    message: "Carga de clientes completada correctamente.",
                    description: <LoadNotification data={res.data} />,
                    placement: "bottomRight",
                    duration: 10,
                  });
                } else {
                  messageApi.error(
                    "Ocurrió un error al ejecutar la carga de clientes"
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                messageApi.error(
                  "Ocurrió un error al ejecutar la carga de clientes"
                );
              })
              .finally(() => setLoading(false));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tmpConfig?.clientsLoadExcel) {
      const { objMap, bajaClient } = tmpConfig.clientsLoadExcel;

      if (objMap) {
        const { name, idSistema, email, cuit, phone } =
          tmpConfig.clientsLoadExcel.objMap;

        form.setFieldsValue({
          name: name,
          idSistema: idSistema,
          email: email,
          cuit: cuit,
          phone: phone,
        });

        if (bajaClient) {
          //console.log('UE bajaClient: ', bajaClient)
          form.setFieldsValue({
            chkEnableBaja: bajaClient,
          });
        }
      }
    }
  }, [tmpConfig]);

  //Excel
  const xlsOptions = useMemo(() => {
    if (!xlsData || xlsData.length < 1) {
      return [];
    }

    let options = Object.keys(xlsData[0])?.map((element) => {
      return {
        value: element,
        label: element,
      };
    });

    return options;
  }, [xlsData]);

  const keyOptions = useMemo(() => {
    if (xlsData) {
      let flattenedArray = flattenFirstElementGetProducts(xlsData);

      return (
        flattenedArray?.map((element) => {
          return { value: element, label: element };
        }) || []
      );
    }

    return [];
  }, [xlsData]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleGuardar}
      name="clientsLoadGet"
      className="form-nodos"
    >
      <b style={{ marginBottom: "12px" }}>
        Configuremos cómo cargará los clientes
      </b>
      <span style={{ marginBottom: "24px" }}>
        <BoxInfo
          message={[
            "Suba un archivo donde obtendremos el listado de variables.",
            "El archivo deberá tener el formato correspondiente.",
          ]}
        />
      </span>

      <Form.Item>
        <Dragger
          name="file"
          customRequest={
            (v) => customRequest(v, messageApi, setXlsData) // PROBABLEMENTE DEBEREMOS HACER NUESTRA VERSION
          }
          maxCount={1}                                                                                                                                                                                            
          accept={
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre un archivo aquí
          </p>
          <p className="ant-upload-hint">{`Soporta archivos en formato xls, xlsx.`}</p>
          <p className="ant-upload-hint">{`Con un máximo de 100MB.`}</p>
        </Dragger>
      </Form.Item>

      <span style={{ marginTop: "16px" }}>
        <BoxInfo
          message={[
            "Configure qué tratamiento se le dará a los clientes eliminados.",
            "Se darán de baja los clientes en caso de ser activada.",
          ]}
        />
      </span>
      <div className="baja-clients">
        <Form.Item name="chkEnableBaja" initialValue={false}>
          <Switch
            checkedChildren="Dar de baja"
            unCheckedChildren="Mantener activos"
          />
        </Form.Item>
      </div>

      {xlsOptions?.length > 0 && (
        <>
          <span style={{ marginTop: "24px", marginBottom: "12px" }}>
            <BoxInfo
              message={[
                "Necesitamos asociar las características de sus clientes para que coincidan con el formato utilizado en Brocoly.",
              ]}
            />
          </span>
        </>
      )}
      <Form.Item
        name="idSistema"
        label="Cuenta (Código de identificación)"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name="name"
        label="Razón social"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name="email" label="Email">
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name="cuit" label="CUIT">
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name="phone" label="Teléfono">
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            style={{ opacity: loading ? 0.65 : 1 }}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            disabled={xlsData.length === 0}
          >
            Guardar
          </Button>

          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarRequestDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
