import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { getUserById } from '../../../../helpers/fetchData/fetchUsers';

export const CreatedBy = ({ userId }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [authorName, setAuthorName] = useState('-');

  useEffect(() => {
    if (instance?.userData?.apiKey && userId) {
      getUserById(instance, userId)
        .then((res) => {
          if (res?.status === 200) {
            setAuthorName(res.data?.name);
          } else {
            messageApi.error('No se pudo obtener el autor de la campaña');
            setAuthorName('-');
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('No se pudo obtener el autor de la campaña');
          setAuthorName('-');
        });
    }
  }, [instance, userId]);

  return <span>{authorName}</span>;
};
