import { Button, Calendar, Select } from 'antd';
import dayjs from 'dayjs';

export const ExtrasCalendar = ({
  selectedDate,
  setSelectedDate,
  chatFormattedData
}) => {
  function fullCellRender(current) {
    const currentDate = current.format('D/M');

    const todayDate = dayjs().format('D/M');

    const daysHaveTickets = chatFormattedData?.messages?.map((ticket) =>
      dayjs(ticket.createdAt, 'x').format('D/M')
    );

    let selected = '';
    if (selectedDate !== 'todo') {
      selected = selectedDate?.format('D/M');
    }

    // Asigna clases a cada celda según su estado:
    let cellClasses = '';
    if (selectedDate !== 'todo' && current.month() !== selectedDate?.month()) {
      cellClasses += 'cell-calendar-otherMonth';
    }
    if (currentDate === selected) {
      cellClasses += ' cell-calendar-selected';
    }
    if (daysHaveTickets?.includes(currentDate)) {
      cellClasses += ' cell-calendar-haveTickets';
    }
    if (currentDate === todayDate) {
      cellClasses += ' cell-calendar-today';
    }

    return (
      <div className={`cell-calendar ${cellClasses}`}>
        <span>{current.date()}</span>
      </div>
    );
  }

  function handleCalendarChange(selectedDay) {
    setSelectedDate(selectedDay);
  }

  return (
    <div className='ExtrasCalendar'>
      <Calendar
        fullscreen={false}
        fullCellRender={fullCellRender}
        onChange={handleCalendarChange}
        validRange={[dayjs().date(1).month(1).year(2000), dayjs()]}
        headerRender={({ value, onChange }) => (
          <HeaderCalendar
            value={value}
            onChange={onChange}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      />
    </div>
  );
};

const HeaderCalendar = ({ value, onChange, selectedDate, setSelectedDate }) => {
  const start = 0;
  const end = 12;
  const months = [];
  const monthOptions = [];
  let current = value.clone();
  const localeData = value.localeData();

  // Array de abreviaturas de meses:
  for (let i = start; i < end; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }
  // Selectores de meses:
  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i}>
        {months[i]}
      </Select.Option>
    );
  }
  const year = value.year();
  const month = value.month();
  const options = [];
  // Selectores de años:
  for (let i = 2000; i <= dayjs().year(); i += 1) {
    options.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>
    );
  }

  function handleTodoClick() {
    setSelectedDate('todo');
  }

  return (
    <div className='HeaderCalendar'>
      <div className='header-calendar-selectors'>
        <Select
          size='small'
          value={year}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
        >
          {options}
        </Select>
        <Select
          size='small'
          value={month}
          onChange={(newMonth) => {
            const now = value.clone().month(newMonth);
            onChange(now);
          }}
        >
          {monthOptions}
        </Select>
      </div>
      <Button
        type={selectedDate === 'todo' ? 'primary' : 'default'}
        size='small'
        onClick={handleTodoClick}
      >
        Todo
      </Button>
    </div>
  );
};
