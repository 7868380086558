import { useState, useContext } from 'react';
import { Button, Modal, Popover, QRCode, Space, Tooltip } from 'antd';
import { MdQrCode2 } from 'react-icons/md';
import { TbDownload, TbTrash } from 'react-icons/tb';
import { GlobalContext } from '../../../../context/GlobalContext';
import { deleteQrCode } from '../../../../../helpers/fetchData/fetchQrCodes';

export const CardBotQRPreview = ({ flow, qrCode, setQrCodes }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const downloadQR = () => {
    const canvas = document.getElementById('qrCode')?.querySelector('canvas');

    if (canvas) {
      const url = canvas.toDataURL();

      const a = document.createElement('a');
      a.download = `qr-${flow.name}.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
      <div className='qr-container'>
        <MdQrCode2 size={20} onClick={() => setOpenModal(true)} />
      </div>

      <Modal
        className='preview-modal'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <div className='qr-preview-container'>
          <div className='qr-preview-header'>
            <p>{flow.name}</p>

            <div className='qr-preview-actions'>
              <Tooltip title='Descargar'>
                <TbDownload
                  className='pointer'
                  size={20}
                  onClick={() => downloadQR()}
                />
              </Tooltip>

              <Popover
                trigger='click'
                open={showPopover}
                content={
                  <ContentPopoverEliminar
                    flow={flow}
                    qrCode={qrCode}
                    setQrCodes={setQrCodes}
                    setShowPopover={setShowPopover}
                  />
                }
              >
                <Tooltip title='Eliminar'>
                  <TbTrash
                    className='pointer'
                    size={20}
                    onClick={() => setShowPopover(true)}
                  />
                </Tooltip>
              </Popover>
            </div>
          </div>

          <Space id='qrCode'>
            <QRCode
              type='canvas'
              bgColor='white'
              errorLevel='H'
              size={400}
              value={qrCode.deep_link_url}
            />
          </Space>
        </div>
      </Modal>
    </>
  );
};

const ContentPopoverEliminar = ({
  flow,
  qrCode,
  setQrCodes,
  setLoading,
  setShowPopover
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingBorrar, setLoadingBorrar] = useState(false);

  const handleDelete = (qrCode) => {
    const data = {
      _id: qrCode._id,
      metaId: qrCode.code
    };

    setLoadingBorrar(true);
    deleteQrCode(instance, data)
      .then((res) => {
        if (res?.status === 200) {
          setQrCodes((prevState) => {
            let array = [...prevState];

            array.filter((qr) => qr._id !== qrCode._id);
          });

          messageApi.success('Código QR eliminado');
        }
      })
      .catch((err) => {
        messageApi.error('No se pudo eliminar el código QR');
        console.error(err);
      })
      .finally(() => setLoadingBorrar(false));
  };

  return (
    <div className='columna-simple'>
      <span
        style={{ marginBottom: '8px' }}
      >{`¿Está seguro de borrar el código QR de ${flow?.name}?`}</span>
      <div className='botones-wrapper-content' style={{ width: '100%' }}>
        <Button
          type='primary'
          className='btn-borrar'
          onClick={() => handleDelete(qrCode)}
          loading={loadingBorrar}
        >
          Confirmar
        </Button>
        <Button
          className='btn-cancelar'
          type='secondary'
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
