import axios from "axios";

export const registerApp = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/apps/registerApp`,
      headers: {
        Authorization: key,
      },
      data: { data: data },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
