import { Tooltip } from 'antd';
import { FiUploadCloud } from 'react-icons/fi';

export const CardBotPublicar = ({ flow, updateStatus }) => {
  return (
    <Tooltip title='Publicar'>
      <span style={{ cursor: 'pointer' }}>
        <FiUploadCloud
          size={20}
          onClick={() => updateStatus(flow._id, 'publish')}
        />
      </span>
    </Tooltip>
  );
};
