import { Button, Form, Input, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { FiPlus, FiX } from 'react-icons/fi';
import {
  handleConditionValue,
  handleConditionsCount,
  handleConditionsOperator
} from '../../../../../helpers/utilidadesTemplates';
import { updateNode } from '../utils/updateNode';
import { GlobalContext } from '../../../../context/GlobalContext';
import { operadores } from '../utils/utilidadesFlow';
import { BiGitBranch } from 'react-icons/bi';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import { HeaderForm } from './componentsForm/HeaderForm';

export const BranchForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    edges,
    setEdges
  } = useContext(FlowContext);

  const [form] = Form.useForm();

  const { messageApi } = useContext(GlobalContext);

  const [variable, setVariable] = useState({});

  const [showPopover, setShowPopover] = useState(false);

  const [condiciones, setCondiciones] = useState([
    { id: 1, value: '', operator: [], childId: '' }
  ]);

  const [nombreNodo, setNombreNodo] = useState('');

  const onFinish = (values) => {
    let valores = {};

    valores.variable = variable ? variable : {};

    valores.label = nombreNodo;
    valores.handleCount = 1;

    if (condiciones.length > 0) {
      let flag = false;
      condiciones.forEach((element) => {
        if (
          element.value === '' ||
          element.value === undefined ||
          element.value === null ||
          element.operator.length < 1
        ) {
          flag = true;
        }
      });

      if (flag) {
        return messageApi.error(
          'Cada condición debe tener un operador y un valor'
        );
      }

      let condicionesIds = condiciones.map((element) => element.id.toString());

      setEdges((prevState) => {
        let filtro = [...prevState].filter((element) => {
          let flag = true;
          if (
            element.source === nodoSeleccionado.id &&
            element.sourceHandle &&
            element.sourceHandle !== 'default'
          ) {
            if (!condicionesIds.includes(element.sourceHandle)) {
              flag = false;
            }
          }
          return flag;
        });

        return filtro;
      });

      valores.cases = condiciones;
      valores.handleCount += condiciones.length;
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, valores, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      if (data.cases?.length > 0) {
        let casos = [...data.cases];

        let lastElement = casos[casos.length - 1];

        if (lastElement?.value === undefined || lastElement?.value === '') {
          casos.pop();
        }

        setCondiciones(casos);
      } else {
        setCondiciones([]);
      }

      if (data.variable) {
        if (data.variable.type) {
          if (
            data.variable.type !== variable.type &&
            variable.type !== undefined
          ) {
            setCondiciones([]);
            form.resetFields();
          }
        }
        setVariable(data.variable);
        if (showPopover) {
          setShowPopover(false);
        }
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formBranch'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<BiGitBranch size={32} />}
      />
      <Form.Item label='Variable'>
        <Input
          disabled={true}
          style={{ color: '#252525' }}
          value={variable?.name}
        />
        <BotonAgregarVariable
          nodeId={nodoSeleccionado.id}
          seccion='textHeader'
          isBranch={true}
          context='buttons'
          boton={
            <Button
              className='btn-agregar'
              type='primary'
              size='small'
              style={{ borderRadius: '6px', marginTop: '8px' }}
              onClick={() => setShowPopover(true)}
            >
              Elegir variable
            </Button>
          }
        />
      </Form.Item>
      <Form.Item label='Condiciones'>
        {variable.type && (
          <div
            className='template-crear-lista'
            style={{ margin: '0px 0px 0px 0px' }}
          >
            {condiciones?.map((condicion, index) => {
              return (
                <div className='item-wrapper' key={index}>
                  <Form.Item
                    style={{ marginBottom: '8px' }}
                    label={
                      <span
                        className='horizontal-list'
                        style={{ width: '100%' }}
                      >
                        <p>Condición</p>
                        <FiX
                          style={{ cursor: 'pointer' }}
                          size={20}
                          onClick={() => {
                            setCondiciones((prevState) =>
                              handleConditionsCount('resta', prevState, index)
                            );
                          }}
                        />
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Campo requerido'
                      }
                    ]}
                  >
                    <Select
                      onChange={(v) =>
                        setCondiciones((prevState) =>
                          handleConditionsOperator(prevState, v, index)
                        )
                      }
                      options={operadores[variable.type]}
                      value={condicion.operator?.[0]}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Valor'
                    style={{ marginBottom: '8px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Campo requerido'
                      }
                    ]}
                  >
                    <Input
                      type={variable.type === 'number' ? 'number' : 'string'}
                      onChange={(v) =>
                        setCondiciones((prevState) =>
                          handleConditionValue(
                            prevState,
                            v.target.value,
                            index,
                            variable.type
                          )
                        )
                      }
                      value={condicion.value}
                    />
                  </Form.Item>
                </div>
              );
            })}
            <Button
              className='btn-agregar btn-oscuro'
              type='primary'
              onClick={() => {
                setCondiciones((prevState) =>
                  handleConditionsCount('suma', prevState)
                );
              }}
              style={{ marginLeft: 'auto' }}
            >
              <FiPlus /> Condición
            </Button>
          </div>
        )}
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
