import { Result } from "antd";
import { IoLockClosedOutline } from "react-icons/io5";

export const NotSelected = () => {
  return (
    <span
      className="chat-inner-input-wrapper not-selected-wrapper fade-in"
    >
      <Result
        icon={<IoLockClosedOutline size={24} />}
        style={{ padding: "0px" }}
        status="info"
        title={
          <p style={{ fontSize: "15px" }}>
            Seleccione una conversación o un contacto...
          </p>
        }
        subTitle={
          <p style={{ fontSize: "13px" }}>
            Podrá ver su historial e interactuar.
          </p>
        }
      />
    </span>
  );
};
