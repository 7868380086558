import { useEffect, useRef, useState } from 'react';
import { Grafico } from './Grafico';
import { CardEstadisticas } from './CardEstadisticas';

export const EstadisticasMensajes = ({ messageStats, datePickerValue }) => {
  const [datosMensajes, setDatosMensajes] = useState({});

  // Inicialización del objeto que almacenará los datos a mostrar:
  let dataMessages = {
    cantEnviados: 0,
    cantEntregados: 0
  };

  const dataPoints = useRef([]);

  useEffect(() => {
    // Si los data_points existen, calcula los datos y los muestra en las estadísticas:
    if (messageStats?.analytics?.data_points) {
      dataPoints.current = messageStats.analytics.data_points;
      const data = calculateDatosMensajes(dataPoints, dataMessages);
      setDatosMensajes(data);
    } else {
      dataPoints.current = [];
      setDatosMensajes(dataMessages);
    }
  }, [messageStats]);

  function calculateDatosMensajes(dataPoints, dataMessages) {
    // Cálculo de los datos:
    dataPoints.current.forEach((i) => {
      dataMessages.cantEnviados += i.sent;
      dataMessages.cantEntregados += i.delivered;
    });

    return dataMessages;
  }

  const statistics = [
    [
      {
        title: 'Mensajes enviados',
        value: datosMensajes.cantEnviados,
        total: true,
        info: (
          <div className='statInfo'>
            <p>
              Número de mensajes que tu negocio envió a cuentas en WhatsApp.
            </p>
          </div>
        )
      }
    ],
    [
      {
        title: 'Mensajes entregados',
        value: datosMensajes.cantEntregados,
        total: true,
        info: (
          <div className='statInfo'>
            <p>
              Número de mensajes que tu negocio envió a cuentas en WhatsApp que
              se recibieron. Es posible que algunas cuentas no los hayan
              recibido, por ejemplo, si el dispositivo estaba fuera de servicio.
            </p>
          </div>
        )
      }
    ]
  ];

  return (
    <div className='EstadisticasMensajes'>
      <div className='Estadisticas-row cardMensajes-row'>
        <CardEstadisticas graphType='mensajes' statistics={statistics[0]} />
        <CardEstadisticas graphType='mensajes' statistics={statistics[1]} />
      </div>
      <div className='Estadisticas-row'>
        <Grafico
          graphType='mensajes'
          dataPoints={dataPoints}
          datePickerValue={datePickerValue}
        />
      </div>
    </div>
  );
};
