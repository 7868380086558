import MainLayout from "../ui/Layout/MainLayout";
import { Settings } from "../ui/Layout/settings/Settings";

export const ConfigView = ({children}) => {
  return (
    <MainLayout>
      <Settings >
        {children}
      </Settings>
    </MainLayout>
  );
};
