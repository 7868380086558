import { useContext } from 'react';
import { isSelectedClass } from '../../../../../helpers/isSelected';
import { FlowContext } from '../../../../context/FlowContext';
import { TbTrash } from 'react-icons/tb';
import { useBorrarNode } from '../utils/useBorrarNode';
import { Handle, Position } from 'reactflow';
import { AiOutlineMail } from 'react-icons/ai';
import { getNodeName } from '../utils/getNodeName';
import { checkValidConnection } from '../utils/utilidadesFlow';

export const SendEmailNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className='borrar-nodo' onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}

      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
      />

      <div className='node-header'>
        <AiOutlineMail style={{ color: 'var(--dark-color)' }} size={32} />
        <div className='node-description'>
          <span className='node-type'>{getNodeName(node)}</span>
          <span className='mensaje-nodo'>{node.data?.label}</span>
        </div>
      </div>

      <span className='contenedor-handles'>
        <span className='linea-handle'>
          <p>Next step</p>
          <Handle
            type='source'
            className='custom-handle next-step'
            position={Position.Right}
            id='nextstep'
            isConnectable={checkValidConnection(edges, node.id, 'nextstep')}
          />
        </span>
      </span>

      <div className='path-id'>{node?.id}</div>
    </div>
  );
};
