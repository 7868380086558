import { Button, Form, Input, Popover, Select, Switch, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { RequestHeaders } from "../../requestHeaders/RequestHeaders";
import { UrlParams } from "../../urlParams/UrlParams";
import { BoxInfo } from "../../box-info/BoxInfo";
import {
  deleteEnviosReturn,
  updateEnviosReturn,
} from "../../../../helpers/fetchData/fetchEnviosMasivos";
import { GlobalContext } from "../../../context/GlobalContext";
import LoadingIcon from "../../Loader/LoadingIcon";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const MODULO = "envios";

const RESPONSE_EXAMPLE = JSON.stringify(
  {
    enviados: [],
    enviadosData: [
      {
        phone: "Number",
        criterio: "String",
        id: "ID",
      },
    ],
    failures: {
      ocupado: [],
      errorMensaje: [],
      errorMeta: [],
      contactoDesconocido: [],
      requestError: "String || null",
      contentError: "String || null",
      serverError: "String || null",
    },
    envioId: "ID",
    timestamp: "timestamp",
  },
  null,
  2
);

const RESPONSE_INFO = [
  "[] significa Array o conjunto de elementos.",
  "'enviados'. Ids de contactos a los que se envió correctamente.",
  "'enviadosData'. Data de cada elemento, contiene phone (número de teléfono), criterio (valor de la columna o propiedad elegida para coincidir con cuenta cliente o telefono del contacto.) e id (Si el elemento tuviera un identificador 'id').",
  "'failures'. Objeto con información sobre fallos",
  "'ocupado'. No se enviaron por encontrarse utilizando un bot o estar en un chat activo.",
  "'errorMensaje'. No se enviaron porque no había información para completar el envío.",
  "'errorMeta'. No se enviaron por un error de Meta.",
  "'contactoDesconocido'. No se enviaron porque no se encontró un contacto en la lista de filtros",
  "'requestError'. Si se produjera un error en la petición.",
  "'contentError'. si no se contara con la configuración completa.",
  "'serverError'. Si se produjera un error en nuestro servidor.",
  "'envioId'. Identificador del envío.",
  "'timestamp' Fecha en que se ejecuta el proceso, en formato unix ms.",
];

export const EnviosReturn = ({
  drawerReturn,
  setDrawerReturn,
  setEnviosMasivos,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [urlParams, setUrlParams] = useState([]);
  const [headers, setHeaders] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showPopover, setShowPopover] = useState(false);

  const [form] = Form.useForm();

  const updateEnviosArray = (value) => {
    setEnviosMasivos((prevState) => {
      let array = [...prevState];

      const index = array.findIndex(
        (element) => element._id === drawerReturn.item?._id
      );

      if (index >= 0) {
        array[index] = {
          ...array[index],
          webhookRequest: value,
        };
      }

      return array;
    });
  };

  const onFinish = (v) => {
    // Guardar config
    const webhookRequest = {
      headers,
      urlParams,
      url: v.url,
      method: v.method,
    };

    setLoading(true);

    updateEnviosReturn(instance?.userData?.apiKey, drawerReturn?.item?._id, {
      webhookRequest,
    })
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Petición actualizada correctamente");

          updateEnviosArray(webhookRequest);
          setDrawerReturn({ open: false, item: null });
        } else {
          messageApi.error("Ocurrió un error al actualizar el endpoint");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al actualizar el endpoint");
      })
      .finally(() => setLoading(false));
  };

  const handleDesactivar = () => {
    setLoading(true);
    deleteEnviosReturn(instance?.userData?.apiKey, drawerReturn?.item?._id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Endpoint de respuesta desactivado correctamente");

          updateEnviosArray(null);
          setDrawerReturn({ open: false, item: null });
        } else {
          messageApi.error(
            "Ocurrió un error al desactivar el endpoint de respuesta"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error(
          "Ocurrió un error al desactivar el endpoint de respuesta"
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (drawerReturn?.item?.webhookRequest) {
      const data = drawerReturn.item.webhookRequest;

      const { headers, urlParams, url, method } = data || {};

      if (headers) {
        setHeaders(headers);
      }

      if (urlParams) {
        setUrlParams(urlParams);
      }

      form.setFieldsValue({
        url: url || "",
        method: method || null,
      });
    }
  }, [drawerReturn]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <b style={{ marginBottom: 8, fontSize: 15 }}>
        Configurar webhook de respuesta
      </b>
      <BoxInfo
        message={[
          "Opcionalmente configure un endpoint donde enviaremos el resultado del envío.",
          "Recibirá información detallada de que pasó con cada elemento.",
          "Puede ser útil para actualizar su información.",
        ]}
      />
      <Form.Item
        label="Método"
        name="method"
        rules={[{ required: true, message: "Campo requerido" }]}
        style={{ marginTop: 8 }}
      >
        <Select
          options={[
            { value: "POST", label: "POST" },
            { value: "PUT", label: "PUT" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        rules={[{ required: true, message: "Campo requerido" }]}
        style={{ marginBottom: 8 }}
      >
        <Input.TextArea
          autoSize={{ minRows: 2 }}
          type="text"
          // onChange={(v) => setUrl(v.target.value)}
          // value={url}
          id="urlInput"
        />
      </Form.Item>
      <UrlParams
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        modulo={MODULO}
      />
      <div style={{ padding: 8 }}></div>
      <RequestHeaders
        headers={headers}
        setHeaders={setHeaders}
        modulo={MODULO}
      />

      <span className="horizontal" style={{ marginTop: "16px" }}>
        <b>Ejemplo de respuesta</b>
        <BoxInfo message={RESPONSE_INFO} hideMode={true} />
      </span>
      <div style={{ margin: "8px 0px" }}>
        <SyntaxHighlighter
          language="javascript"
          wrapLongLines
          customStyle={{ borderRadius: "8px" }}
          style={dracula}
        >
          {RESPONSE_EXAMPLE}
        </SyntaxHighlighter>
      </div>

      {drawerReturn?.item?.webhookRequest && (
        <span
          className="horizontal-between"
          style={{ marginTop: "auto", marginBottom: 16 }}
        >
          <p>¿Ya no quiere recibir información del envío?</p>
          <Popover
            open={showPopover}
            content={
              <span className="columna gap-8" style={{ alignItems: "center" }}>
                <p>¿Está seguro?</p>
                <span className="horizontal">
                  <Button
                    type="primary"
                    className="btn-borrar"
                    onClick={handleDesactivar}
                    icon={
                      loading ? (
                        <LoadingIcon size="small" color="#FFFFFF" />
                      ) : null
                    }
                    style={{ opacity: loading ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type="secondary"
                    className="btn-cancelar"
                    onClick={() => setShowPopover(false)}
                  >
                    Cancelar
                  </Button>
                </span>
              </span>
            }
          >
            <Tooltip title="Desactivar" placement="left">
              <Switch
                onClick={() => setShowPopover(true)}
                checked={drawerReturn?.item?.webhookRequest}
              />
            </Tooltip>
          </Popover>
        </span>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setDrawerReturn({ open: false, item: null });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
