import { ClockCircleOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState, useEffect } from "react";
import { ImWhatsapp } from "react-icons/im";
import { Handle, Position } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { PreviewNode } from "../PreviewNode";
import { Tag, Popover } from "antd";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { getNodeName } from "../utils/getNodeName";
import { PiAddressBookBold } from "react-icons/pi";
import { GlobalContext } from "../../../../context/GlobalContext";
import { multipleContactsByIds } from "../../../../../helpers/fetchData/fetchContacts";

export const MessageContactNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const { instance, messageApi } = useContext(GlobalContext);
  const [contactos, setContactos] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(false);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const showHandle = () => {
    if (
      node?.data?.buttonsCallContent ||
      node?.data?.buttonsContent ||
      node?.data?.buttonsMarketingContent
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showEsperar = () => {
    if (node.data.auto === undefined) {
      return false;
    } else {
      return !node.data.auto;
    }
  };

  const handleVariableName = () => {
    if (node?.data?.variable?.name) {
      return `${node?.data?.variable?.name}`;
    }
    return "";
  };

  const bodyText = useMemo(() => {
    if (node.data.caption && node.data.headerType === "media") {
      // En caso de media caption es el unico mensaje en el nodo
      return node.data.caption;
    }

    return node.data.bodyText;
  }, [node]);

  useEffect(() => {
    if (instance?.userData?.apiKey && node?.data?.contacts) {
      setLoadingContacts(true);
      multipleContactsByIds(instance.userData.apiKey, node.data.contacts)
        .then((res) => {
          if (res?.status === 200) {
            setContactos(res.data);
          } else {
            messageApi.error(
              "No se pudo cargar los datos de los destinatarios"
            );
          }
        })
        .catch((err) => {
          messageApi.error("No se pudo cargar los datos de los destinatarios");
          console.error(err);
        })
        .finally(() => setLoadingContacts(false));
    }
  }, [instance, node]);

  const getFullNameById = (id) => {
    const contact = contactos?.find((contact) => contact._id === id);
    if (contact?.fullName) {
      return contact.fullName;
    } else {
      return "Contacto";
    }
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div className="answer-node">
          <ImWhatsapp size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node?.data?.label}</span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "8px",
              marginBottom: "2px",
            }}
          >
            <Popover
              placement="top"
              content={
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {node?.data?.contacts?.map((contact, index) => (
                    <span
                      key={index}
                      style={{
                        marginRight:
                          index !== node?.data?.contacts?.length - 1
                            ? "4px"
                            : 0,
                      }}
                    >
                      <Tag
                        icon={<PiAddressBookBold />}
                        color="orange"
                        className="tags-icon-align"
                      >
                        {getFullNameById(contact)}
                      </Tag>
                    </span>
                  ))}
                </div>
              }
            >
              <Tag
                icon={<PiAddressBookBold />}
                color="orange"
                className="tags-icon-align"
              >
                Contactos
              </Tag>
            </Popover>
          </div>
        </div>
      </div>
      <PreviewNode
        node={node}
        ambito={`nodes-${node.id}`}
        bodyContent={bodyText}
        buttonsCallContent={node?.data?.buttonsCallContent}
        buttonsContent={node?.data?.buttonsContent}
        buttonsMarketingContent={node?.data?.buttonsMarketingContent}
        footerContent={node?.data?.footerContent}
        headerContent={node?.data?.headerContent}
        headerMediaType={node?.data?.headerMediaType}
        headerType={node?.data?.headerType}
        inputText={node?.data?.inputText}
        preview={node?.data?.url}
      />
      {showEsperar() && (
        <div className="esperar-wrapper">
          <span className="esperar-item">
            <ClockCircleOutlined />
            <p>Espera...</p>
          </span>
          <Tag color="green" style={{ margin: "0px" }}>
            {handleVariableName()}
          </Tag>
        </div>
      )}
      <div className="path-id">{node?.id}</div>
      {showHandle() && (
        <span className="contenedor-handles">
          <span className="linea-handle">
            <p>Next step</p>
            <Handle
              type="source"
              className="custom-handle next-step"
              position={Position.Right}
              id="nextstep"
              isConnectable={checkValidConnection(edges, node.id, "nextstep")}
            />
          </span>
          {node?.data?.variable?.error?.status && (
            <span className="linea-handle">
              <p>Fallback</p>
              <Handle
                type="source"
                className="custom-handle fallback"
                position={Position.Right}
                id="fallback"
                isConnectable={checkValidConnection(edges, node.id, "fallback")}
              />
            </span>
          )}
        </span>
      )}
    </div>
  );
};
