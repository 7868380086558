import { Button, Form, Input, Select, Spin, TreeSelect } from "antd";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import {
  agregarVariableWhatsapp,
  handleDisableList,
  handleProductItemValue,
  handleProductListCount,
  handleProductListItemCount,
  handleProductListValue,
} from "../../../../../helpers/utilidadesTemplates";
import { GlobalContext } from "../../../../context/GlobalContext";
import {
  getCatalogDetail,
  getCatalogInstance,
} from "../../../../../helpers/fetchData/fetchCatalog";
import { LoadingOutlined } from "@ant-design/icons";
import { updateNode } from "../utils/updateNode";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { LineaGris } from "../../../linea-gris/LineaGris";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";

export const MultiProductForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [listContent, setListContent] = useState({
    sections: [{ title: "", productsSet: null, product_items: [] }],
  });
  const [disableButton, setDisableButton] = useState(false);

  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [catalogDetail, setCatalogDetail] = useState({});
  const [catalogId, setCatalogId] = useState("");
  const [conjuntos, setConjuntos] = useState([]);

  const onFinish = (values) => {
    if (!headerText) {
      return messageApi.info("Es necesario que ingrese un texto al encabezado");
    }

    if (!bodyText) {
      return messageApi.info("Es necesario que ingrese cuerpo del mensaje");
    }

    if (!catalogId) {
      return messageApi.info("No se encontró el id del catálogo");
    }

    values.catalog_id = catalogId;

    let listContentFlag = "";

    let articlesCount = 0;

    listContent.sections.forEach((element) => {
      if (!element.title) {
        listContentFlag = "titulo";
      }

      articlesCount += element.product_items.length;

      if (element.product_items.length < 1) {
        listContentFlag = "rows";
      } else {
        if (element.product_items.some((fila) => !fila.product_retailer_id)) {
          listContentFlag = "rowTitle";
        }
      }
    });

    if (listContentFlag) {
      if (listContentFlag === "titulo") {
        return messageApi.info(
          "Ingrese un titulo para cada una de las secciones"
        );
      } else if (listContentFlag === "rows") {
        return messageApi.info("Ingrese al menos un item a cada sección");
      } else if (listContentFlag === "rowTitle") {
        return messageApi.info(
          "Ingrese un nombre para cada una de los items de una sección"
        );
      }
    }

    if (listContent.sections.length > 10) {
      return messageApi.info("La lista no puede contener mas de 10 secciones");
    }

    if (articlesCount > 30) {
      return messageApi.info(
        "La lista no puede contener mas de 30 articulos en total"
      );
    }

    values.label = nombreNodo;
    values.auto = false;
    values.headerType = messageType;

    values.headerText = headerText;
    values.bodyText = bodyText;
    values.footerText = footerText;

    values.variable = {};

    values.handleCount = 1;

    if (listContent) {
      values.listContent = listContent;
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  const validarOptions = (seccion) => {
    let conjuntoSeleccionado = conjuntos?.find(
      (productSet) => productSet.id === seccion.productsSet
    );

    if (!conjuntoSeleccionado) {
      return [];
    }

    let allProducts = [];

    if (conjuntoSeleccionado.name === "Todos los productos") {
      products.forEach((lista) => {
        let list = catalogDetail.lists?.find(
          (element) => element.id === lista._id
        );

        lista?.products?.forEach((producto) => {
          allProducts.push(producto);
        });
      });
    } else {
      products.forEach((lista) =>
        lista?.products?.forEach((producto) => {
          if (conjuntoSeleccionado.filter.includes(producto.id)) {
            allProducts.push(producto);
          }
        })
      );
    }

    return allProducts.map((product) => product.id);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { data } = nodoSeleccionado;

      if (data.headerText !== undefined) {
        setHeaderText(data.headerText);
      }

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }

      if (data.footerText !== undefined) {
        setFooterText(data.footerText);
      }

      if (data.listContent !== undefined) {
        const clone = structuredClone(data.listContent);

        setListContent(clone);
      }
      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      setNombreNodo(data.label);

      form.setFieldsValue({
        listName: data.listName,
      });
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getCatalogInstance(instance.userData.apiKey, messageApi)
        .then((res) => {
          if (res?.status === 200) {
            setCatalogId(res.data?.catalog?.catalogId);
          }
        })
        .catch((error) => console.log(error));

      getCatalogDetail(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setCatalogDetail(res.data);
            setProducts(res.data?.products);
            setConjuntos(
              res.data?.productsSets?.map((element) => {
                const matches = element.filter?.match(/(?<="is_any":\[)[^\]]+/);
                let numbers = [];

                if (matches?.length > 0) {
                  numbers = matches[0]
                    ?.match(/\d+/g)
                    ?.map((num) => Number(num));
                }

                return {
                  name: element.name,
                  filter: numbers,
                  id: element.id,
                };
              })
            );
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, []);

  const isConjuntoValid = (conjuntoSeleccionado, conjuntos) => {
    let productsSets = conjuntos.map((element) => element.id);

    return productsSets.includes(conjuntoSeleccionado);
  };

  useEffect(() => {
    if (conjuntos.length > 0 && products.length > 0) {
      listContent.sections.forEach((seccion, index) => {
        if (!isConjuntoValid(seccion.productsSet, conjuntos)) {
          messageApi.info(
            `El conjunto seleccionado en la sección ${index + 1} ya no existe`
          );

          setListContent((prevState) => {
            let array = [...prevState.sections];

            array[index].product_items = [];
            array[index].productsSet = null;

            return { sections: array };
          });
        } else {
          let productosDelConjunto = validarOptions(seccion);

          seccion?.product_items?.forEach((producto, productIndex) => {
            if (
              !productosDelConjunto.includes(
                Number(producto.product_retailer_id)
              )
            ) {
              messageApi.info(
                `El producto ${productIndex + 1} de la sección ${
                  index + 1
                } ya no es parte del conjunto`
              );

              setListContent((prevState) => {
                let array = [...prevState.sections];

                array[index].product_items.splice(productIndex, 1);

                return { sections: array };
              });
            }
            // else {
            //   setListContent((prevState) => {
            //     let array = [...prevState?.sections];

            //     let newArray = array.map((oldSection) => {
            //       return {
            //         ...oldSection,
            //         product_items: oldSection.product_items?.map((producto) => {
            //           let productoBuscado = detalleProductos.find(
            //             (element) =>
            //               JSON.parse(element.value)?.id ===
            //               producto.product_retailer_id
            //           );

            //           if (productoBuscado) {
            //             let objectBuscado = JSON.parse(productoBuscado?.value);

            //             return {
            //               product_retailer_id: objectBuscado.id,
            //               title: objectBuscado.title,
            //             };
            //           } else {
            //             return producto;
            //           }
            //         }),
            //       };
            //     });

            //     return { sections: newArray };
            //   });
            // }
          });
        }
      });
    }
  }, [products, conjuntos]);

  const handleProductChange = (v, index, row) => {
    if (!v.value.toString()?.startsWith("lista")) {
      setListContent((prevState) =>
        handleProductItemValue(prevState, v, index, row)
      );
    }
  };

  const treeData = (seccion) => {
    let conjuntoSeleccionado = conjuntos?.find(
      (productSet) => productSet.id === seccion?.productsSet
    );

    if (!conjuntoSeleccionado) {
      return [];
    }

    if (conjuntoSeleccionado.name === "Todos los productos") {
      if (catalogDetail?.products?.length > 0) {
        let array = [];

        array = catalogDetail.products.map((lista) => {
          let listName =
            catalogDetail.lists?.find((element) => element.id === lista._id)
              ?.name || "Sin lista";

          return {
            title: listName,
            value: `lista-${lista._id}`,
            selectable: false,
            children: lista.products.map((producto) => {
              return {
                title: producto.title,
                value: producto.id?.toString(),
              };
            }),
          };
        });

        return array;
      }
    } else {
      let array = [];

      catalogDetail.products.forEach((lista) => {
        let listProducts = [];

        lista?.products?.forEach((producto) => {
          if (conjuntoSeleccionado.filter.includes(producto.id)) {
            listProducts.push({
              title: producto.title,
              value: producto.id?.toString(),
            });
          }
        });

        if (listProducts.length > 0) {
          let listName =
            catalogDetail.lists?.find((element) => element.id === lista._id)
              ?.name || "Sin lista";

          array.push({
            title: listName,
            value: `lista-${lista._id}`,
            selectable: false,
            children: listProducts,
          });
        }
      });

      return array;
    }

    return [];
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />

      <Form.Item label={<span className="label-secciones">ENCABEZADO</span>}>
        <HeaderTextOrMedia
          messageType={messageType}
          setMessageType={setMessageType}
          nodeId={nodoSeleccionado?.id}
          // headerMediaType={headerMediaType}
          // setHeaderMediaType={setHeaderMediaType}
          text={headerText}
          setText={setHeaderText}
          // url={url}
          // setURL={setURL}
          // setPreview={setPreview}
          // caption={caption}
          // setCaption={setCaption}
          // location={location}
          // setLocation={setLocation}
          context="interactive-list"
        />
      </Form.Item>
      <LineaGris />
      <FormattedTextArea
        value={bodyText}
        setValue={setBodyText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textBody"
        label="CUERPO"
        boldLabel
        minRows={3}
      />
      <LineaGris />
      <FormattedTextArea
        value={footerText}
        setValue={setFooterText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textFooter"
        label="PIE DEL MENSAJE"
        boldLabel
        minRows={3}
        showDecorators={false}
      />
      <LineaGris />
      {loading ? (
        <span className="horizontal">
          <Spin
            style={{ marginInline: "auto", marginTop: 50 }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
          />
        </span>
      ) : (
        <div style={{ marginBottom: "16px" }}>
          {listContent?.sections?.map((seccion, index) => {
            return (
              <div className="template-crear-lista" key={index}>
                <span className="horizontal-list">
                  <b>Conjunto</b>
                  <FiX
                    style={{ cursor: "pointer" }}
                    size={20}
                    onClick={() => {
                      setListContent((prevState) =>
                        handleProductListCount("resta", prevState, index)
                      );
                      setDisableButton(
                        handleDisableList("resta", listContent, index)
                      );
                    }}
                  />
                </span>
                <Select
                  options={conjuntos?.map((element) => {
                    return {
                      value: element.id,
                      label: element.name,
                    };
                  })}
                  placeholder="Seleccione un conjunto"
                  value={listContent.sections[index].productsSet}
                  onChange={(v) =>
                    setListContent((prevState) =>
                      handleProductListValue(
                        prevState,
                        v,
                        index,
                        "productsSet",
                        conjuntos
                      )
                    )
                  }
                />
                {seccion.productsSet && (
                  <>
                    <b>Nombre</b>
                    <Input
                      style={{
                        maxWidth: "400px",
                      }}
                      onChange={(v) =>
                        setListContent((prevState) =>
                          handleProductListValue(
                            prevState,
                            v.target.value,
                            index,
                            "title"
                          )
                        )
                      }
                      value={listContent.sections[index].title}
                      showCount
                      maxLength={24}
                      placeholder="Ingrese un título"
                    />
                  </>
                )}
                {seccion.productsSet &&
                  listContent.sections[index]?.product_items?.length > 0 && (
                    <div className="lista-item">
                      {listContent.sections[index].product_items?.map(
                        (element, row) => {
                          return (
                            <div
                              className="item-wrapper"
                              key={`${index}-${row}`}
                            >
                              <span className="horizontal-list">
                                <b>Item</b>
                                <FiX
                                  style={{ cursor: "pointer" }}
                                  size={20}
                                  onClick={() => {
                                    setListContent((prevState) =>
                                      handleProductListItemCount(
                                        "resta",
                                        prevState,
                                        index,
                                        row
                                      )
                                    );
                                    setDisableButton(
                                      handleDisableList(
                                        "resta",
                                        listContent,
                                        index
                                      )
                                    );
                                  }}
                                />
                              </span>
                              <TreeSelect
                                style={{
                                  width: "100%",
                                }}
                                value={element.product_retailer_id}
                                dropdownStyle={{
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                                showSearch
                                labelInValue
                                filterTreeNode={(input, option) =>
                                  option.title
                                    ?.toLowerCase()
                                    ?.includes(input.toLowerCase())
                                }
                                treeData={treeData(seccion)}
                                placeholder="Seleccione un producto"
                                onChange={(v) =>
                                  handleProductChange(v, index, row)
                                }
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {seccion.productsSet && (
                  <span>
                    <Button
                      style={{ marginLeft: "auto" }}
                      className="btn-agregar btn-oscuro"
                      type="primary"
                      size="small"
                      onClick={() => {
                        setListContent((prevState) =>
                          handleProductListItemCount("suma", prevState, index)
                        );
                      }}
                      disabled={disableButton}
                    >
                      Agregar item
                    </Button>
                  </span>
                )}
              </div>
            );
          })}

          <Button
            className="btn-agregar"
            type="primary"
            onClick={() => {
              setListContent((prevState) =>
                handleProductListCount("suma", prevState)
              );
              setDisableButton(handleDisableList("suma", listContent));
            }}
            disabled={disableButton}
          >
            Agregar otra sección
          </Button>
        </div>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            id="multiproductOk"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
