import { Button } from "antd";
import { useContext, useState } from "react";
import { manageInvitation } from "../../../helpers/fetchData/fetchInvitation";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContext } from "../../context/GlobalContext";

export const InvitationCard = ({ invitation }) => {
  const { setWorkspaces } = useContext(GlobalContext);
  const { user } = useAuth0();

  const [loadingInvitation, setLoadingInvitation] = useState({
    accept: false,
    reject: false,
  });

  const handleInvitation = (flag, invitationId) => {
    if (flag) {
      setLoadingInvitation({ accept: true, reject: false });
    } else {
      setLoadingInvitation({ accept: false, reject: true });
    }

    const data = {
      invitationId: invitationId,
      confirm: flag,
      userName: user.name,
    };

    manageInvitation(data)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data?._id) {
            console.log(res.data);
            setWorkspaces((prevState) => {
              let object = {
                ...prevState,
              };

              if (prevState?.instance) {
                object.instance = [...prevState.instance, res.data];
              } else {
                object.instance = [res.data];
              }

              object.invitations = object.invitations?.filter(
                (element) => element._id !== invitation._id
              );

              console.log(object);

              return object;
            });
          } else {
            setWorkspaces((prevState) => {
              let object = {
                ...prevState,
              };

              object.invitations = object.invitations.filter(
                (element) => element._id !== invitation._id
              );

              return object;
            });
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingInvitation({ accept: false, reject: false }));
  };

  return (
    <span className="horizontal invitation-row gap16">
      {`${invitation.user?.name || ""} te invitó a sumarte a ${
        invitation.instance?.name || ""
      }`}
      <span className="horizontal" style={{ marginLeft: "auto" }}>
        <Button
          type="primary"
          className="btn-guardar"
          onClick={() => handleInvitation(true, invitation._id)}
          loading={loadingInvitation.accept}
          size="small"
        >
          Aceptar
        </Button>
        <Button
          type="secondary"
          className="btn-borrar"
          onClick={() => handleInvitation(false, invitation._id)}
          loading={loadingInvitation.reject}
          size="small"
        >
          Rechazar
        </Button>
      </span>
    </span>
  );
};
