import { FiArchive } from "react-icons/fi";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { Handle, Position } from "reactflow";
import { TbTrash } from "react-icons/tb";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { Tag } from "antd";
import { getNodeName } from "../utils/getNodeName";

export const SendTemplateNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <FiArchive style={{ color: "var(--dark-color)" }} size={32} />
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data?.label}</span>
        </div>
      </div>
      {node?.data?.template?.name && (
        <span>
          <Tag color="geekblue">{node.data.template.name}</Tag>
        </span>
      )}
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
      <div className="path-id">{node?.id}</div>
    </div>
  );
};
