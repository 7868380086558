import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { BiSupport } from "react-icons/bi";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { Handle, Position } from "reactflow";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { TbTrash } from "react-icons/tb";
import { Tag } from "antd";
import { getNodeName } from "../utils/getNodeName";

export const ContactAgentNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const borrarNode = useBorrarNode(node.id);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div style={{ height: "32px" }}>
          <BiSupport color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data.label}</span>
        </div>
      </div>
      <div
        className="columna gap16"
        style={{ alignItems: "center", marginTop: 8 }}
      >
        {node?.data?.teamName && (
          <span className="horizontal gap4">
            <p>Equipo</p>
            <Tag color="geekblue">{node.data.teamName}</Tag>
          </span>
        )}
        {node?.data?.agentName && (
          <span className="horizontal gap4">
            <p>Agente</p>
            <Tag color="green">{node.data.agentName}</Tag>
          </span>
        )}
      </div>
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Agente asignado</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="assigned"
            isConnectable={checkValidConnection(edges, node.id, "assigned")}
          />
        </span>
        <span className="linea-handle">
          <p>No asignado</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="notAssigned"
            isConnectable={checkValidConnection(edges, node.id, "notAssigned")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "36px" }}>
        {node?.id}
      </div>
    </div>
  );
};
