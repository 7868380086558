import { useState, useContext } from 'react';
import { Button, Select } from 'antd';
import { generateQrCode } from '../../../../helpers/fetchData/fetchQrCodes';
import { GlobalContext } from '../../../context/GlobalContext';

export const GenerateQrModal = ({ setOpen, flow, setIsQrCodeGenerated }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const { flowData } = flow;

  const [selectedTrigger, setSelectedTrigger] = useState(flowData.trigger[0]);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const triggerOptions = flowData?.trigger.map((t) => ({
    label: t,
    value: t
  }));

  const handleGenerate = () => {
    const data = {
      flowId: flowData._id,
      trigger: selectedTrigger,
      imageFormat: 'PNG'
    };

    setLoadingGenerate(true);
    generateQrCode(instance, data)
      .then((res) => {
        if (res?.status === 201) {
          setIsQrCodeGenerated(true);
          messageApi.success('Código QR generado');
          setOpen(false);
        }
      })
      .catch((err) => {
        messageApi.error('No se pudo generar el código QR');
        console.error(err);
      })
      .finally(() => setLoadingGenerate(false));
  };

  return (
    <div className='confirm-wrapper' style={{ position: 'fixed' }}>
      <div
        className='confirm-form'
        style={{ padding: '16px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <span className='form-title'>Generar código QR</span>

        <p>
          Cree un código QR para tener un acceso rápido a este bot. Al ser
          escaneado, redirigirá al usuario al chat de WhatsApp con el disparador
          seleccionado a continuación ya escrito.
        </p>

        <span style={{ width: '100%' }}>
          <p className='label-duplicate'>Disparador</p>
          <Select
            style={{ width: '100%' }}
            options={triggerOptions}
            value={selectedTrigger}
            onChange={(v) => setSelectedTrigger(v)}
          />
        </span>

        <div
          className='botones-wrapper-content'
          style={{ marginTop: '8px', width: '100%' }}
        >
          <Button
            type='primary'
            className='btn-guardar'
            onClick={handleGenerate}
            loading={loadingGenerate}
          >
            Aceptar
          </Button>
          <Button
            className='btn-cancelar'
            type='secondary'
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};
