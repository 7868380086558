import { ReactFlowProvider } from "reactflow";
import MainLayout from "../ui/Layout/MainLayout";
import { EnviosBuilder } from "../ui/Layout/enviosMasivos/enviosBuilder/EnviosBuilder";

export const EnvioBuilderView = ({ edit = false }) => {
  return (
    <MainLayout>
      <ReactFlowProvider>
        <EnviosBuilder edit={edit} />
      </ReactFlowProvider>
    </MainLayout>
  );
};
