import { Button, Drawer, Form, Input } from 'antd';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { editSavedMessage } from '../../../../../../../helpers/fetchData/fetchSavedMessages';

export const DrawerEditarMensaje = ({
  showDrawerEditar,
  setShowDrawerEditar,
  setGrupos,
  mensaje
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [formEditarMensaje] = Form.useForm();

  function editarMensaje(v) {
    setLoading(true);

    let data;
    if (mensaje.categoryId !== 'default') {
      data = { ...mensaje, ...v };
    } else {
      data = { ...mensaje, ...v, categoryId: null };
    }

    editSavedMessage(instance?.userData?.apiKey, mensaje?._id, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Mensaje editado correctamente');

          setGrupos((prevState) => {
            return prevState.map((grupo) => {
              if (grupo.savedReplies) {
                const updatedSavedReplies = grupo.savedReplies.map(
                  (savedReply) => {
                    if (savedReply._id === mensaje._id) {
                      return { ...savedReply, ...v };
                    } else {
                      return savedReply;
                    }
                  }
                );

                return {
                  ...grupo,
                  savedReplies: updatedSavedReplies
                };
              } else {
                return grupo;
              }
            });
          });
        } else {
          messageApi.error('Ocurrió un error al editar el mensaje');
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error('Ocurrió un error al editar el mensaje');
      })
      .finally(() => {
        setLoading(false);
        setShowDrawerEditar(false);
      });
  }

  return (
    <Drawer open={showDrawerEditar} width={450}>
      <Form
        form={formEditarMensaje}
        layout='vertical'
        name='editMessage'
        requiredMark={false}
        onFinish={(v) => editarMensaje(v)}
        className='form-nodos'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <b
          style={{ marginBottom: '16px' }}
        >{`Editar mensaje - ${mensaje?.name}`}</b>
        <Form.Item
          label='Título'
          name='name'
          rules={[{ required: true, message: 'Campo obligatorio' }]}
          initialValue={mensaje?.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Mensaje'
          name='value'
          rules={[{ required: true, message: 'Campo obligatorio' }]}
          initialValue={mensaje?.value}
        >
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            placeholder='Escriba un mensaje'
          />
        </Form.Item>
        <div
          className='botones-wrapper-content form-custom-footer'
          style={{ marginBottom: 12 }}
        >
          <Button
            type='primary'
            className='btn-guardar'
            size='large'
            htmlType='submit'
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setShowDrawerEditar(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
