import { Badge, Button } from "antd";
import "./index.css";
import { useContext } from "react";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { PresupuestosContext } from "../../context/PresupuestosContext";
import { FiX } from "react-icons/fi";

export const MainLayout = ({ children, title }) => {
  const { cartDetail, payload, setPresupuestoDrawer } =
    useContext(PresupuestosContext);

  const handleStage = (route) => {
    setPresupuestoDrawer((prevState) => {
      return { ...prevState, stage: route };
    });
  };

  return (
    <div className="presupuesto-layout-wrapper">
      <div className="header-wrapper">
        <span className="horizontal">
          <FiX
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setPresupuestoDrawer({
                visible: false,
              })
            }
          />
          <p className="header-title">{title}</p>
        </span>
        {title?.toLowerCase() === "artículos" && (
          <Badge
            count={cartDetail.length}
            color="green"
            className="pointer"
            onClick={() => handleStage("carrito")}
          >
            <span
              className="pointer"
              style={{ height: 26, display: "flex", cursor: "pointer" }}
              onClick={() => handleStage("carrito")}
            >
              <ShoppingCartOutlined style={{ fontSize: 26 }} />
            </span>
          </Badge>
        )}
        {title?.toLowerCase() === "mi carrito" && (
          <Button
            type="secondary"
            className="boton-secundario"
            onClick={() => handleStage("home")}
          >
            Volver
          </Button>
        )}
        {title?.toLowerCase() === "presupuesto" && (
          <Button
            type="secondary"
            className="boton-secundario"
            onClick={() => handleStage("carrito")}
          >
            Volver
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};
