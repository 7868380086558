import { Button } from 'antd';

export const ResetContactConfig = ({ setShowPopover }) => {
  const resetConfig = () => {
    setShowPopover(false);
  };

  return (
    <span className='columna' style={{ maxWidth: 280 }}>
      <p>¿Está seguro de reestablecer la configuración?</p>
      <span className='horizontal'>
        <Button type='primary' className='btn-borrar' onClick={resetConfig}>
          Confirmar
        </Button>
        <Button
          type='secondary'
          className='btn-cancelar'
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </span>
    </span>
  );
};
