import { useContext, useState } from "react";
import { Button, Popover, Tooltip } from "antd";
import { getContactName } from "../../campaigns/campaignBuilder/utils/getContactName";
import { GlobalContext } from "../../../../context/GlobalContext";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { FiArchive } from "react-icons/fi";
import { archiveContact } from "../../../../../helpers/fetchData/fetchArchivedContacts";

export const ArchivarContacto = ({ item }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Tooltip title="Archivar">
      <span>
        <Popover
          open={showPopover}
          content={
            <PopoverArchivar item={item} setShowPopover={setShowPopover} />
          }
        >
          <span>
            <FiArchive
              className="icono-acciones"
              onClick={() => setShowPopover(true)}
            />
          </span>
        </Popover>
      </span>
    </Tooltip>
  );
};

const PopoverArchivar = ({ item, setShowPopover }) => {
  const { messageApi, instance, setContactos } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  return (
    <span className="columna" style={{ maxWidth: 280 }}>
      <p>{`¿Está seguro de archivar a ${getContactName(item.name)}?`}</p>
      <span className="horizontal">
        <Button
          type="primary"
          className="btn-borrar"
          onClick={() =>
            archiveContact(
              item,
              instance,
              messageApi,
              setContactos,
              setLoading,
              setShowPopover
            )
          }
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Confirmar
        </Button>
        <Button
          type="secondary"
          className="btn-cancelar"
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </span>
    </span>
  );
};
