import { splitTriggers } from './splitTriggers';
import { validateTrigger } from './validateTrigger';

export async function addTrigger(
  inputTriggerValue,
  triggers,
  setTriggers,
  setIsValidating,
  setInputTriggerValue,
  messageApi,
  instance,
  setLoading
) {
  let arrayTriggers = [];
  if (triggers.length > 0) {
    arrayTriggers = [...triggers];
  }
  setIsValidating(true);

  const arrayInput = splitTriggers(inputTriggerValue);

  if (inputTriggerValue.length < 1) {
    messageApi.info('El disparador debe tener al menos un caracter');
  } else {
    await validateTrigger(
      inputTriggerValue,
      arrayInput,
      arrayTriggers,
      messageApi,
      setTriggers,
      instance,
      setLoading
    );
    setInputTriggerValue('');
  }
  setIsValidating(false);
}
