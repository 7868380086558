import { formatoTextHtml } from "../../../../../helpers/utilidadesTemplates";
import { clearInnerVars } from "./clearInnerVars";
import { getVariableName } from "./getVariableName";
import { updateInnerVars } from "./updateInnerVars";

export const handleVars = (url, setMostrarDrawerVars, messageApi) => {
  if (isInterpolate(url)) {
    // if (hasSingleVariable(url)) {
    setMostrarDrawerVars(true);
    // } else {
    //   messageApi.error("Solo puede configurar una variable");
    // }
  } else {
    setMostrarDrawerVars(true);
  }
};

export const isInterpolate = (text) => {
  if (text) {
    const regex = /\${[^\s}]+}/g;
    return regex.test(text);
  } else {
    return false;
  }
};

export const hasSingleVariable = (url) => {
  // const regex = /\${(.*?)}/g;
  const regex = /\${[^\s}]+}/g;
  let matches = url.match(regex);
  if (matches?.length > 1) {
    return false;
  } else {
    return true;
  }
};

export const handleKeyPress = (e) => {
  //* solo letras, números y -
  const charRegex = new RegExp("[a-zA-Z0-9-]");
  const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!charRegex.test(pressedKey)) {
    e.preventDefault();
    return false;
  }
};

export const handleKeyPressInstanceName = (e) => {
  //* solo letras, números, - y espacios
  const charRegex = new RegExp("[a-zA-Z0-9- ]"); // Added space inside the character set
  const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!charRegex.test(pressedKey)) {
    e.preventDefault();
    return false;
  }
};

export const handleInnerVars = (
  variableName,
  setFlowVariables,
  nodoId,
  messageApi,
  variables,
  section
) => {
  let array = [];

  clearInnerVars(setFlowVariables, nodoId, messageApi, section);

  if (variables?.length > 0) {
    let filtro = variables.filter((item) => item.checked);

    let prefix = "";

    if (section === "innerVars") {
      prefix = `http_${variableName}_`;
    }

    filtro.forEach((item) => {
      if (section === "innerVars") {
        // Si estuviera anexado el prefijo ya a la variable;
        let itemName = item.name.replace(prefix, "");
        let varName = prefix + itemName;

        array.push({
          name: varName,
          type: item.type,
          checked: true,
        });

        updateInnerVars(setFlowVariables, {
          idNodo: nodoId,
          name: varName,
          type: item.type,
          kind: section,
        });
      } else if (section === "indexedVars") {
        let varName = getVariableName(
          `${variableName}_${item.name}`,
          "itbl",
          true
        );

        // if (section === "indexedVars") {
        varName = formatearVariablesComunes(varName);
        // }

        array.push({
          name: varName,
          type: item.type,
          checked: true,
        });

        updateInnerVars(setFlowVariables, {
          idNodo: nodoId,
          name: varName,
          type: item.type,
          kind: section,
        });
      }
    });
  }

  return array;
};

export const isJSON = (body) => {
  try {
    JSON.parse(body);
    return true;
  } catch (e) {
    return false;
  }
};

export const renderHTMLTag = (text, id) => {
  if (!text || !id) {
    return text;
  }

  if (isInterpolate(text)) {
    let texto = formatoTextHtml(text);

    if (texto) {
      let elemento = document.getElementById(id);
      if (elemento) {
        elemento.innerHTML = texto.split("\n").join("<br>");
      }
    }
  }

  return text;
};

export const formatearVariablesComunes = (element) => {
  if (!element.startsWith("http_")) {
    return element;
  }

  let count = 0;
  for (let i = 0; i < element.length; i++) {
    if (element[i] === "_") {
      count++;
    }
  }

  if (count > 1) {
    element = element.substring(element.indexOf("_") + 1);

    return element.substring(element.indexOf("_") + 1);
  }

  return element;
};

export const getVariablesFromText = (text) => {
  const regex = /\${[^\s}]+}/g;
  let matches = text?.match(regex) || [];

  if (matches.length > 0) {
    let variableNames = [];

    matches.forEach((element) => {
      const match = element.match(/\${([^\s}]+)}/);
      if (match && match?.[1]) {
        variableNames.push(match[1]);
      }
    });

    return variableNames;
  }

  return null;
};
