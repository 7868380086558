import { MdOutlineEmail } from 'react-icons/md';
import { SiMercadopago, SiOpenai } from 'react-icons/si';

export const Options = ({ setSelectedIntegration }) => {
  const options = [
    // {
    //   label: "Mercado Pago",
    //   id: "mercadopago",
    //   icon: <SiMercadopago size={32} />,
    // },
    { label: 'Open AI', id: 'openai', icon: <SiOpenai size={32} /> },
    {
      label: 'Correo electrónico',
      id: 'email',
      icon: <MdOutlineEmail size={32} />
    }
  ];

  return (
    <span className='horizontal' style={{ width: '100%' }}>
      {options.map((item) => {
        return (
          <div
            className='integrations-wrapper'
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedIntegration(item.id)}
            key={item.id}
          >
            <span
              className='horizontal'
              style={{
                justifyContent: 'space-between',
                color: '#252525'
              }}
            >
              <p className='integration-title'>{item.label}</p>
              {item.icon}
            </span>
          </div>
        );
      })}
    </span>
  );
};
