import { Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

export const CampaignDataTime = ({ recipients, campaignData }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const roundToHour = (timestamp) => {
    const time = dayjs(timestamp, 'X');
    return time.minute(0).second(0);
  };

  const getGraphData = () => {
    const result = {};

    if (recipients) {
      recipients.forEach((recipient) => {
        if (recipient.messageStatuses) {
          recipient.messageStatuses.forEach((st) => {
            if (st.status === 'read') {
              const roundedTime = roundToHour(parseInt(st.timestamp));
              const datetime = roundedTime.format('DD/MM/YY HH');
              if (!result[datetime]) {
                result[datetime] = { datetime, reply: 0, read: 0 };
              }
              result[datetime].read += 1;
            }
          });
        }

        if (recipient.responses) {
          recipient.responses.forEach((reply) => {
            const roundedTime = roundToHour(parseInt(reply.timestamp));
            const datetime = roundedTime.format('DD/MM/YY HH');
            if (!result[datetime]) {
              result[datetime] = { datetime, reply: 0, read: 0 };
            }
            result[datetime].reply += 1;
          });
        }
      });
    }

    return result;
  };

  const data = Object.values(getGraphData());

  const processRepliesAndReads = (data) => {
    const result = {};

    data.forEach((entry) => {
      const dateTime = dayjs(entry.datetime, 'DD/MM/YY HH');
      const dateKey = dateTime.format('DD/MM/YY');
      const hour = dateTime.hour();

      // Si el día no existe en el resultado, inicializar un array de 24 horas:
      if (!result[dateKey]) {
        result[dateKey] = Array.from({ length: 24 }, (_, h) => ({
          hour: h,
          reply: 0,
          read: 0
        }));
      }

      // Si el día existe, actualizar la hora específica con sus valores:
      result[dateKey][hour].reply += entry.reply;
      result[dateKey][hour].read += entry.read;
    });

    return result;
  };

  const repliesAndReads = processRepliesAndReads(data);

  const orderRepliesAndReads = () => {
    const result = {};

    const orderedKeys = Object.keys(repliesAndReads).sort(
      (a, b) => dayjs(a, 'DD/MM/YY').unix() - dayjs(b, 'DD/MM/YY').unix()
    );

    orderedKeys.forEach((date) => {
      result[date] = repliesAndReads[date];
    });

    return result;
  };

  const orderedRepliesAndReads = orderRepliesAndReads();

  const dates = Object.keys(orderedRepliesAndReads);

  const selectOptions = dates.map((d) => ({
    label: d,
    value: d
  }));

  useEffect(() => {
    setSelectedDate(
      selectOptions?.[0]?.value ? selectOptions?.[0]?.value : null
    );
  }, [selectOptions]);

  return (
    <>
      {data && (
        <div className='CampaignDataTime'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <span className='campaign-data-time-title'>Análisis temporal</span>

            {recipients?.some(
              (recipient) =>
                recipient.messageStatuses?.length || recipient.responses?.length
            ) && (
              <Select
                size='small'
                options={selectOptions}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            )}
          </div>

          {recipients?.some(
            (recipient) =>
              recipient.messageStatuses?.length || recipient.responses?.length
          ) ? (
            <>
              <div style={{ width: '100%', height: 200 }}>
                <ResponsiveContainer>
                  <AreaChart
                    margin={{ left: -20 }}
                    height={250}
                    data={repliesAndReads[selectedDate]}
                  >
                    <XAxis
                      dataKey='hour'
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      interval='equidistantPreserveStart'
                    />
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      allowDecimals={false}
                      domain={([dataMin, dataMax]) => [
                        0,
                        Math.round(dataMax * 1.5)
                      ]}
                      tickFormatter={(v) => {
                        if (v >= 1000) {
                          return (v / 1000).toFixed(v >= 10000 ? 0 : 1) + ' k';
                        }
                        return v;
                      }}
                    />
                    <Tooltip
                      cursor={false}
                      content={<TooltipContent indicator='dot' />}
                    />
                    <Area
                      type='monotone'
                      dataKey='read'
                      stroke='#ffa940'
                      fill='#ffa940'
                    />
                    {campaignData?.hasButtons && (
                      <Area
                        type='monotone'
                        dataKey='reply'
                        stroke='#4096ff'
                        fill='#4096ffaa'
                      />
                    )}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <span>Aún no hay información</span>
          )}
        </div>
      )}
    </>
  );
};

const TooltipContent = ({ active, payload, label }) => {
  const getDataLabel = (dataKey) => {
    switch (dataKey) {
      case 'reply':
        return 'Respuestas';
      case 'read':
        return 'Lecturas';
      default:
        return '';
    }
  };

  if (active && payload && payload.length) {
    return (
      <div className='campaign-data-time-tooltip'>
        <span className='campaign-data-time-tooltip-title'>
          {label}-{parseInt(label) + 1} hs
        </span>

        {payload.map((pl, index) => (
          <div key={index} className='campaign-data-time-tooltip-content'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
              <div
                className='campaign-data-time-tooltip-color'
                style={{ backgroundColor: pl.color }}
              />
              <span className='campaign-data-time-tooltip-label'>
                {getDataLabel(pl.dataKey)}
              </span>
            </div>

            <span className='campaign-data-time-tooltip-value'>{pl.value}</span>
          </div>
        ))}

        {/* <div className='campaign-data-time-tooltip-content'>
          <div
            className='campaign-data-time-tooltip-color'
            style={{ backgroundColor: payload[0].color }}
          />
          <span className='campaign-data-time-tooltip-label'>
            {payload[0].dataKey}
          </span>
          <span className='campaign-data-time-tooltip-value'>
            {payload[0].value}
          </span>
        </div> */}
      </div>
    );
  }
};
