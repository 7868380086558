import { Tooltip } from 'antd';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export const CardBotEditar = ({ flow }) => {
  const navigate = useNavigate();

  return (
    <Tooltip title='Editar'>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate(`/flows/edit?id=${flow._id}`);
        }}
      >
        <FiEdit3 size={20} />
      </span>
    </Tooltip>
  );
};
