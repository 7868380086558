import { Button, Form, Input, Select, Switch } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { MdLockOpen } from 'react-icons/md';
import { updateNode } from '../utils/updateNode';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { getVariableName } from '../utils/getVariableName';
import { handleVariablesDisponibles } from '../utils/handleVariablesDisponibles';
import { HeaderForm } from './componentsForm/HeaderForm';

export const DecodeForm = () => {
  const [nombreNodo, setNombreNodo] = useState('');
  const [isContentJSON, setIsContentJSON] = useState(false);

  const [secret, setSecret] = useState({
    visible: false,
    value: ''
  });

  const [form] = Form.useForm();

  const { messageApi } = useContext(GlobalContext);

  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    flowVariables,
    setFlowVariables,
    setEdges,
    edges
  } = useContext(FlowContext);

  const onFinish = (values) => {
    if (!values.variableName) {
      messageApi.info('Ingrese un nombre para la variable');
      return;
    }

    if (!values.variableInput) {
      messageApi.info('Seleccione una variable por favor');
      return;
    }

    values.variable = {};

    let variableName = getVariableName(values.variableName, 'decode');

    values.variableName = variableName;
    values.variable.name = variableName;
    values.variable.type = 'decode';
    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    values.label = nombreNodo;

    values.inputType = 'string';

    values.body = values.variableInput;

    values.secret = null;

    if (secret.visible) {
      if (!secret.value) {
        messageApi.info('Ingrese un secret u oculteló');
        return;
      }

      values.secret = secret.value;
    }

    values.outputType = 'base64';

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  const getVariableOptions = () => {
    let array = [];
    if (flowVariables) {
      let vars = handleVariablesDisponibles(
        flowVariables,
        nodoSeleccionado.id,
        false,
        edges,
        ['encode']
      );

      vars?.regular?.forEach((variable) => {
        if (variable.type === 'encode') {
          array.push({ value: variable.name, label: variable.name });
        }
      });
    }

    return array;
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      if (data.inputType === 'json') {
        setIsContentJSON(true);
      }

      if (data.secret) {
        setSecret({
          visible: true,
          value: data.secret
        });
      }

      let variableName;
      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf('_') + 1);
      } else {
        variableName = '';
      }

      form.setFieldsValue({
        variableName: variableName,
        variableInput: data.body
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      name='decode-form'
      layout='vertical'
      onFinish={onFinish}
      requiredMark={false}
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <MdLockOpen
            style={{ color: 'var(--dark-color)' }}
            size={32}
          />
        }
      />

      <Form.Item label='Variable input' name='variableInput'>
        <Select
          options={getVariableOptions()}
          placeholder='Seleccione una variable'
        />
      </Form.Item>

      <div style={{ margin: '16px 0px' }}>
        {!secret.visible ? (
          <Button
            onClick={() =>
              setSecret((prevState) => {
                return { ...prevState, visible: true };
              })
            }
          >
            Agregar secret
          </Button>
        ) : (
          <>
            <Form.Item
              label={
                <span
                  className='horizontal gap8'
                  onClick={(e) => e.preventDefault()}
                >
                  <p>Secret</p>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setSecret({ value: '', visible: false });
                    }}
                  >
                    Quitar
                  </Button>
                </span>
              }
            >
              <Input
                placeholder='Ingrese una palabra o frase'
                value={secret.value}
                onChange={(v) =>
                  setSecret((prevState) => {
                    return { ...prevState, value: v.target.value };
                  })
                }
              />
            </Form.Item>
          </>
        )}
      </div>

      <span
        className='horizontal'
        style={{ margin: '16px 0px', justifyContent: 'space-between' }}
      >
        <span className='horizontal gap8' style={{ margin: '16px 0px' }}>
          <p>Tipo de salida</p>
          <span
            className='horizontal gap8'
            style={{
              fontSize: 13,
              padding: '2px 4px',
              backgroundColor: '#f4f4f4',
              borderRadius: 6
            }}
          >
            <p>Texto</p>
            <Switch
              size='small'
              onChange={(v) => {
                setIsContentJSON(v);
              }}
              checked={isContentJSON}
            />
            <p>JSON</p>
          </span>
        </span>
      </span>
      <Form.Item
        label='Nombre de la variable'
        name='variableName'
        initialValue={nodoSeleccionado?.data?.variableName}
        rules={[
          {
            pattern: /^[a-zA-Z0-9-]+$/,
            message: 'Se permiten solo letras, números o "-"'
          },
          { required: true, message: 'Es necesario que ingrese un nombre' }
        ]}
        // style={{ width: "63%" }}
      >
        <Input addonBefore={'decode_'} />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            // disabled={method === "POST" ? false : !isValid}
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
