export const openNotification = (
  notificationApi,
  type = "info",
  message = "",
  descripción = null
) => {
  if (notificationApi) {
    notificationApi[type]({
      message: message,
      description: descripción,
      placement: "bottomRight",
    });
  }
};
