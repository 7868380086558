import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { Tag } from "../../tag/Tag";

export const EtiquetasXContactos = ({ data }) => {
  const { etiquetas } = useContext(GlobalContext);

  const getEtiquetas = (tag) => {
    return etiquetas.find((element) => element._id === tag);
  };

  return (
    <div className="celda-tags">
      {data?.map((tag, index) => {
        const obj = getEtiquetas(tag);

        return (
          <Tag hex={obj?.color} nombre={obj?.name} key={obj?._id || index} />
        );
      })}
    </div>
  );
};
