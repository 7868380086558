import dayjs from 'dayjs';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { TooltipGrafico } from './TooltipGrafico';

export const Grafico = ({ graphType, dataPoints, datePickerValue }) => {
  const daysInMonth = datePickerValue.daysInMonth();

  let dataGrafico = [];

  // Rellena el array con los días del mes:
  for (let i = 1; i <= daysInMonth; i++) {
    dataGrafico.push({
      day: i,
      sent: 0,
      delivered: 0,
      conversation: 0
    });
  }

  // Inserta la cantidad de conversaciones del 'dataPoint' en el día correspondiente:
  dataPoints.current.forEach((dataPoint) => {
    const matchingDayIndex = dataGrafico.findIndex((i) => {
      return i.day === dayjs(dataPoint.start, 'X').get('D');
    });

    if (matchingDayIndex !== -1) {
      if (graphType === 'mensajes') {
        dataGrafico[matchingDayIndex].sent += dataPoint.sent;
        dataGrafico[matchingDayIndex].delivered += dataPoint.delivered;
      } else {
        dataGrafico[matchingDayIndex].conversation += dataPoint.conversation;
      }
    }
  });

  function getLegendFormat(_, entry) {
    const { color, value } = entry;

    let label;
    switch (value) {
      case 'conversation':
        label = 'Conversaciones';
        break;
      case 'sent':
        label = 'Enviados';
        break;
      case 'delivered':
        label = 'Entregados';
        break;
      default:
        break;
    }

    return <span style={{ color }}>{label}</span>;
  }

  return (
    <ResponsiveContainer width='100%' aspect={4}>
      <BarChart
        data={dataGrafico}
        margin={{
          top: 20,
          right: 20
        }}
      >
        <XAxis dataKey='day' />
        <YAxis allowDecimals={false} />
        <CartesianGrid strokeDasharray='1 2' vertical={false} />
        <Tooltip
          content={
            <TooltipGrafico
              graphType={graphType}
              datePickerValue={datePickerValue}
            />
          }
        />
        <Legend formatter={getLegendFormat} />
        {graphType === 'mensajes' ? (
          <>
            <Bar dataKey='delivered' fill='#57B583' />
            <Bar dataKey='sent' fill='#B56057' />
          </>
        ) : (
          <Bar dataKey='conversation' fill='#57B583' />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
