import {
  Button,
  Popover,
  Row,
  Col,
  Input,
  Form,
  Radio,
  Space,
  Select,
  InputNumber,
  List,
  Spin,
} from "antd";
import { GlobalContext } from "../../../context/GlobalContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { FiEdit3, FiPlus, FiCheck, FiX } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import "./formConfigTabla.css";
import { getFiltros } from "./fetchData/filtros/getFiltros";
import { postFiltroAnd } from "./fetchData/filtros/postFiltroAnd";
import { actualizarListaFiltros } from "./helpers/actualizarListaFiltros";
import { postFiltroOr } from "./fetchData/filtros/postFiltroOr";
import { putFiltro } from "./fetchData/filtros/putFiltro";
import { deleteFiltro } from "./fetchData/filtros/deleteFiltro";
import { formatoRegex } from "./helpers/formatoRegex";
import LoadingIcon from "../../Loader/LoadingIcon";
import { Instance } from "../settings/content/instance/Instance";

function FiltroConfigTable({ editarTablaValues, setNewFilter }) {
  const { messageApi, filtros, setFiltros, instance } =
    useContext(GlobalContext);

  const [showFilterForm, setShowFilterForm] = useState(false);
  const [columna, setColumna] = useState();
  const [filtro, setFiltro] = useState();
  const [action, setAction] = useState();
  const [idGrupoSelecc, setIdGrupoSelecc] = useState();
  const [operador, setOperador] = useState("AND");
  const [condicion, setCondicion] = useState();

  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    if (instance?._id) {
      const data = { _id: editarTablaValues._id };
      setLoading(true);
      getFiltros(instance, data)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            setFiltros(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener los filtros");
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener los filtros");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  const cantFiltros = useMemo(() => {
    return filtros?.reduce((total, obj) => {
      return total + obj.filtros.length;
    }, 0);
  }, [filtros]);

  //Lo comentado va, pero para desarrollar uso el otro.
  //const optionsColumna = editarTablaValues.columnas.filter(item => !item.hidden).map((col) => ({
  const optionsColumna = editarTablaValues.columnas.map((col) => ({
    value: Number(col.key),
    label: col.title,
    tipo: col.tipo,
    columna: col.dataIndex,
  }));

  const optionsNumber = [
    {
      value: 1,
      label: "=",
      operador: "eq",
    },
    {
      value: 2,
      label: ">",
      operador: "gt",
    },
    {
      value: 3,
      label: "<",
      operador: "lt",
    },
    {
      value: 4,
      label: ">=",
      operador: "gte",
    },
    {
      value: 5,
      label: "<=",
      operador: "lte",
    },
    {
      value: 6,
      label: "!=",
      operador: "ne",
    },
  ];

  const optionsString = [
    {
      value: 7,
      label: "isEqual",
      operador: "regex", // antes: operador: 'eq'. Ahora cambiado por regExp que permite case insensitive
    },
    {
      value: 8,
      label: "contains",
      operador: "regex", // antes:  operador: 'regex:”'. Objetivo: { $regex: /p.*/ } contiene p
    },
    {
      value: 9,
      label: "doesNotContain",
      operador: "not", // antes: operador: 'not:{$regex:”' en este caso queda sin nuevos simbolos el campo valor. Objetivo: { $not: /p.*/ } no contiene p
    },
    {
      value: 10,
      label: "startsWith",
      operador: "regex", // antes: operador: 'regex:"^' en este caso, agregar el simbolo ^ al principio del valor en el campo valor. Objetivo: { $regex: /^p.*/ } empieza con p
    },
    {
      value: 11,
      label: "doesNotStartWith",
      operador: "not", // antes: operador: 'not:{$regex:"^' en este caso, agregar el simbolo ^ al principio del valor en el campo valor. objetivo: { $not: /^p.*/ } no empieza con p
    },
  ];

  const onChangeCol = (value, event) => {
    setColumna(event);
  };

  const onChangeOperador = (value, event) => {
    setCondicion(event);
  };

  const onFinishCrear = (v) => {
    if (!instance?._id) return;

    let newFiltro = {
      idColumna: v.idColumna,
      columna: columna.columna,
      idOperador: v.idOperatorString ? v.idOperatorString : v.idOperatorNumber,
      operador: condicion.operador,
      valor: v.valorString
        ? formatoRegex(
            v.valorString?.trim(),
            v.idOperatorString == 8 || v.idOperatorString == 9
              ? "contains"
              : v.idOperatorString == 10 || v.idOperatorString == 11
              ? "doesNotStartWith"
              : v.idOperatorString == 7
              ? "equals"
              : "normal"
          ) || ""
        : v.valorNumber || "",
      filtroId: operador === "AND" ? idGrupoSelecc : "",
    };

    //Si estamos creando por OR:
    let newGrupo = {
      idTabla: editarTablaValues._id,
      filtros: [],
    };

    setLoading(true); // podria cambiarlo a un loading de boton.

    //Si estamos creando por AND:
    if (operador === "AND") {
      postFiltroAnd(newFiltro, instance)
        .then((res) => {
          if (res?.status === 201) {
            messageApi.success("Nuevo filtro agregado");
            actualizarListaFiltros(
              "crear",
              newFiltro,
              setFiltros,
              res?.data.new_id,
              operador,
              idGrupoSelecc
            );
            setNewFilter(true);
          } else {
            messageApi.error("Ocurrió un error al crear filtro");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al crear filtro");
        })
        .finally(() => setLoading(false));
    }

    //Si estamos creando por OR:
    if (operador === "OR") {
      newGrupo.filtros.push(newFiltro);
      postFiltroOr(newGrupo, instance)
        .then((res) => {
          if (res?.status === 201) {
            messageApi.success("Nuevo filtro agregado");
            actualizarListaFiltros(
              "crear",
              newGrupo,
              setFiltros,
              res?.data,
              operador,
              idGrupoSelecc
            );
            setNewFilter(true);
          } else {
            messageApi.error("Ocurrió un error al crear filtro");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al crear filtro");
        })
        .finally(() => setLoading(false));
    }

    clearInputs();
  };

  const clearInputs = () => {
    setShowFilterForm(false);
    setColumna(undefined);
    setCondicion(undefined);
    form.resetFields();
  };

  // Funcion que prepara el form para actualizar, segun el tipo de dato que posea la columna.
  const editarFiltro = (v, _id) => {
    setFiltro(v);

    //De acuerdo al filtro seleccionado, busca que columna posee, para obtener el tipo de dato.
    let columnaFind = optionsColumna?.find((item) => item.value == v.idColumna);
    setColumna(columnaFind);

    let keyOperator =
      columnaFind.tipo === "string" ? "idOperatorString" : "idOperatorNumber";
    let keyValor =
      columnaFind.tipo === "string" ? "valorString" : "valorNumber";
    form.setFieldsValue({
      idColumna: v?.idColumna,
      [keyOperator]: v?.idOperador,
      [keyValor]: formatoRegex(
        v?.valor,
        keyValor === "valorString" ? "noRegex" : "normal"
      ),
    });

    //De acuerdo al filtro seleccionado, busca que condicion posee.
    let condicionFind = [...optionsNumber, ...optionsString]?.find(
      (item) => item.value == v.idOperador
    );
    setCondicion(condicionFind);

    setIdGrupoSelecc(_id);
  };

  const onFinishEditar = (v) => {
    if (instance?._id) {
      let onEditFiltro = {
        _id: filtro._id,
        idColumna: v.idColumna,
        columna: columna.columna,
        idOperador: v.idOperatorString
          ? v.idOperatorString
          : v.idOperatorNumber,
        operador: condicion.operador,
        valor: v.valorString
          ? formatoRegex(
              v.valorString?.trim(),
              v.idOperatorString == 8 || v.idOperatorString == 9
                ? "contains"
                : v.idOperatorString == 10 || v.idOperatorString == 11
                ? "doesNotStartWith"
                : v.idOperatorString == 7
                ? "equals"
                : "normal"
            ) || ""
          : v.valorNumber || "",
        filtroId: filtro.filtroId,
      };

      setLoading(true); // podria cambiarlo a un loading de boton.

      putFiltro(onEditFiltro, instance)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Filtro editado correctamente");
            actualizarListaFiltros(
              "editar",
              onEditFiltro,
              setFiltros,
              filtro._id,
              operador,
              idGrupoSelecc
            );
            setNewFilter(true);
          } else {
            messageApi.error("Ocurrió un error al editar filtro");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al editar filtro");
        })
        .finally(() => setLoading(false));

      clearInputs();
    }
  };

  const eliminarFiltro = (v, _idGrupo) => {
    if (instance?._id) {
      setLoading(true); // podria cambiarlo a un loading de boton.

      deleteFiltro(v._id, instance)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Filtro eliminado correctamente");
            actualizarListaFiltros(
              "eliminar",
              {},
              setFiltros,
              v._id,
              operador,
              _idGrupo
            );
            setNewFilter(true);
          } else {
            messageApi.error("Ocurrió un error al eliminar filtro");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al editar filtro");
        })
        .finally(() => setLoading(false));

      clearInputs();
    }
  };

  const crearCondicion = (operadorLogico, indexG, _idGrupo) => {
    setShowFilterForm(true);
    setAction("crear");

    //Para crear una nueva condicion dentro de un grupo ya existente, el operador logico sera AND, y debemos insertarlo en un grupo especifico.
    setIdGrupoSelecc(_idGrupo);

    setOperador(operadorLogico);
  };

  return (
    <>
      {loading ? (
        <span className="filtro-loading">
          {/* <Spin indicator={<LoadingOutlined spin />} size="large" /> */}
          <LoadingIcon size="large" />
        </span>
      ) : (
        <Form
          form={form}
          name="formFiltroTable"
          layout="vertical"
          onFinish={action === "crear" ? onFinishCrear : onFinishEditar}
          className="form-configTabla"
        >
          <Row>
            <Col xs={24} sm={24} md={24}>
              <Row className="filtro-form-btns-accion">
                <div style={{ fontSize: "14px", fontWeight: "600" }}>
                  Filtros ({cantFiltros}):
                </div>
                <Space direction="horizontal">
                  {filtros.length == 0 && !showFilterForm ? (
                    <Button
                      className="filtro-btn-agregarFiltro"
                      type="primary"
                      icon={<FiPlus />}
                      ghost
                      onClick={() => crearCondicion("OR")}
                    />
                  ) : (
                    ""
                  )}

                  {showFilterForm ? (
                    <>
                      <Button
                        className="filtro-btn-agregarFiltro"
                        type="primary"
                        icon={<FiX />}
                        danger
                        ghost
                        onClick={() => {
                          clearInputs();
                        }}
                      />

                      <Button
                        className="filtro-btn-agregarFiltro"
                        htmlType="submit"
                        type="primary"
                        icon={<FiCheck />}
                        ghost
                        // onClick={() => {
                        //     onFinishCrear();
                        // }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Space>
              </Row>

              {showFilterForm ? (
                <Row className="filtro-form-contenedor">
                  <Col xs={8} sm={8} md={8} style={{ textAlign: "center" }}>
                    <Form.Item
                      //label="Nombre"
                      name="idColumna"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione una columna",
                        },
                      ]}
                      className="filtro-form-btns-popup"
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        size="small"
                        //placeholder="Selecciona una columna"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label.toUpperCase().trim() ?? "").includes(
                            input.toUpperCase().trim()
                          )
                        }
                        options={optionsColumna}
                        //onChange={onChangeCol}
                        name="idColumna"
                        onSelect={(value, event) => onChangeCol(value, event)}
                      />
                    </Form.Item>
                  </Col>

                  {columna ? (
                    <>
                      <Col xs={8} sm={8} md={8} style={{ textAlign: "center" }}>
                        {columna?.tipo === "number" ? (
                          <Form.Item
                            //label="Nombre"
                            name="idOperatorNumber"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione un operador",
                              },
                            ]}
                            className="filtro-form-btns-popup"
                          >
                            <Select
                              showSearch
                              style={{ width: "90%" }}
                              size="small"
                              placeholder=""
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (
                                  option?.label.toUpperCase().trim() ?? ""
                                ).includes(input.toUpperCase().trim())
                              }
                              options={optionsNumber}
                              name="idOperatorNumber"
                              onSelect={(value, event) =>
                                onChangeOperador(value, event)
                              }
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}

                        {columna?.tipo === "string" ? (
                          <Form.Item
                            //label="Nombre"
                            name="idOperatorString"
                            rules={[
                              {
                                required: true,
                                message: "Seleccione un operador",
                              },
                            ]}
                            className="filtro-form-btns-popup"
                          >
                            <Select
                              showSearch
                              style={{ width: "90%" }}
                              size="small"
                              placeholder=""
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (
                                  option?.label.toUpperCase().trim() ?? ""
                                ).includes(input.toUpperCase().trim())
                              }
                              options={optionsString}
                              name="idOperatorString"
                              onSelect={(value, event) =>
                                onChangeOperador(value, event)
                              }
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col xs={8} sm={8} md={8} style={{ textAlign: "center" }}>
                        {columna?.tipo === "number" ? (
                          <Form.Item
                            //label="Nombre"
                            name="valorNumber"
                            rules={[
                              {
                                required: true,
                                message: "Ingrese un valor",
                              },
                            ]}
                            className="filtro-form-btns-popup"
                          >
                            <InputNumber
                              name="valorNumber"
                              size="small"
                              style={{ width: "100%" }}
                              id="valorNumerico"
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            //label="Nombre"
                            name="valorString"
                            rules={[
                              {
                                required: true,
                                message: "Ingrese un valor",
                              },
                            ]}
                            className="filtro-form-btns-popup"
                          >
                            <Input
                              name="valorString"
                              size="small"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <div className={showFilterForm ? "filtro-form-animation" : ""}>
            {filtros.map((grupo, indexG) => {
              return (
                <div key={grupo._id}>
                  <List //GRUPO
                    className="filtro-list"
                    itemLayout="horizontal"
                    dataSource={grupo.filtros}
                    renderItem={(item, indexF) => (
                      <div key={item._id}>
                        <div className="filtro-filtro-contenedor">
                          <div style={{ overflowX: "scroll" }}>
                            <div style={{ overflowWrap: "break-word" }}>
                              <span
                                style={{ fontWeight: "bold", color: "#77BE42" }}
                              >
                                {`
                                    ${
                                      editarTablaValues?.columnas?.find(
                                        (element) =>
                                          element.key == item.idColumna
                                      ).title
                                    }`}
                              </span>

                              <span>
                                {` ${
                                  [...optionsNumber, ...optionsString]?.find(
                                    (element) =>
                                      element.value == item.idOperador
                                  ).label
                                }`}
                              </span>

                              <span>
                                <b
                                  style={{ overflowWrap: "break-word" }}
                                >{` ${formatoRegex(
                                  item.valor,
                                  typeof item.valor === "string"
                                    ? "noRegex"
                                    : "normal"
                                )}`}</b>
                              </span>
                            </div>
                          </div>

                          {/*Botones de filtro*/}
                          <div className="filtro-iconos-acciones">
                            <FiEdit3
                              className="icono-acciones"
                              onClick={() => {
                                setAction("editar");
                                setShowFilterForm(true);
                                editarFiltro(item, grupo._id);
                                // setRightDrawerConfig({
                                //     visible: true,
                                //     content: "Editar Contacto",
                                //     item: item,
                                // });
                              }}
                            />
                            <TbTrash
                              className="icono-acciones"
                              onClick={() => {
                                eliminarFiltro(item, grupo._id);
                                // setRightDrawerConfig({
                                //     visible: true,
                                //     content: "Editar Contacto",
                                //     item: item,
                                // });
                              }}
                            />
                          </div>
                        </div>

                        {grupo.filtros.length - 1 > indexF ? (
                          <div className="filtro-label-y">y</div>
                        ) : (
                          <Button
                            onClick={() =>
                              crearCondicion("AND", indexG, grupo._id)
                            }
                            disabled={showFilterForm ? true : false}
                            className="filtro-btn-and"
                          >
                            Y
                          </Button>
                        )}
                      </div>
                    )}
                  />

                  {filtros.length - 1 > indexG && filtros.length > 1 ? (
                    <div className="filtro-label-o">O</div>
                  ) : (
                    <Button
                      onClick={() => crearCondicion("OR")}
                      disabled={showFilterForm ? true : false}
                      className="filtro-btn-or"
                    >
                      O
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </Form>
      )}
    </>
  );
}

export default FiltroConfigTable;
