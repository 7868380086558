import { Contactos } from "../ui/Layout/contactos/Contactos";
import MainLayout from "../ui/Layout/MainLayout";

export const ContactoView = ({ tab }) => {
  return (
    <MainLayout>
      <Contactos tab={tab} />
    </MainLayout>
  );
};
