import { Base64 } from "js-base64";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../context/GlobalContext";
import { getEnvioLogs } from "../../../../../helpers/fetchData/fetchEnviosMasivos";
import { Button, Table } from "antd";
import { envioLogsColumns } from "./columns";
import LoadingIcon from "../../../Loader/LoadingIcon";

export const EnviosLogs = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const location = useLocation();

  const navigate = useNavigate();

  const [envioData, setEnvioData] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    navigate("/mensajes/envios");
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      try {
        const search = location.search;
        const data = new URLSearchParams(search).get("payload");

        if (data) {
          let envio = JSON.parse(Base64.decode(data));

          if (envio?._id) {
            setEnvioData(envio);
            setLoading(true);
            getEnvioLogs(instance.userData.apiKey, envio._id)
              .then((res) => {
                if (res?.status === 200 && Array.isArray(res?.data)) {
                  setLogs(res.data);
                } else {
                  messageApi.error(
                    "Ocurrió un error al obtener logs del envío"
                  );
                }
              })
              .catch((error) => {
                console.log("error", error);
                messageApi.error("Ocurrió un error al obtener logs del envío");
              })
              .finally(() => setLoading(false));
          }
        }
      } catch (error) {
        console.log(error);
        messageApi.error("No se pudo obtener data de la campaña");
      }
    }
  }, [instance]);

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>{`Logs - ${envioData?.name}`}</span>
        <Button onClick={handleGoBack}  className="boton-secundario" >Volver</Button>
      </div>
      <Table
        columns={envioLogsColumns(setLogs)}
        dataSource={logs || []}
        rowKey="_id"
        size="small"
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} logs`,
        }}
        className="tabla-generica"
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size="large" />,
        }}
      />
    </div>
  );
};
