import axios from "axios";

export const getClientConfig = async (apiKey) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/clientConfig`,
      headers: {
        Authorization: apiKey,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const upsertClientConfig = async (apiKey, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/clientConfig`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    console.log(config);
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteClientConfig = async (apiKey) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/clientConfig`,
      headers: {
        Authorization: apiKey,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
