import { useContext, useEffect, useMemo } from "react";
import {
  findUser,
  lastMessageContent,
  renderNewMessagesCount,
  updateNotSeenMessages,
} from "./utils/chatFunctions";
import { renderAgent } from "./utils/renderAgent";
import { GlobalContext } from "../../../context/GlobalContext";
import { formatoTextHtml } from "../../../../helpers/utilidadesTemplates";
import { Tag } from "antd";

export const MyConversationsItem = ({
  element,
  setSelectedConversation,
  selectedConversation,
  teams,
}) => {
  const { usuarios } = useContext(GlobalContext);

  const notificationCount = useMemo(() => {
    if (selectedConversation?.phoneNumber === element.phoneNumber) {
      return 0;
    }
    return renderNewMessagesCount(element);
  }, [element, selectedConversation]);

  const handleSelect = () => {
    setSelectedConversation((prevState) => {
      if (prevState?._id && prevState?.type === "chat") {
        updateNotSeenMessages(prevState);
      }
      return element;
    });
  };

  const usuario = useMemo(() => {
    if (element?.userId) {
      return renderAgent(usuarios, element.userId);
    }

    return null;
  }, [usuarios, element]);

  const team = useMemo(() => {
    if (element?.teamId) {
      const team = teams?.find((element) => element._id === element.teamId);

      if (team?.name) return <Tag color="geekblue">{team.name}</Tag>;
    }

    return null;
  }, [teams, element]);

  useEffect(() => {
    let content = lastMessageContent(element);

    if (content) {
      content = formatoTextHtml(content);

      let parrafo = document.getElementById(`lastmessage-${element._id}`);

      if (parrafo) {
        parrafo.innerHTML = content.split("\n").join("<br>");
      }
    } else {
      let parrafo = document.getElementById(`lastmessage-${element._id}`);

      if (parrafo) {
        parrafo.innerHTML = "";
      }
    }
  }, [element]);

  return (
    <span
      className={
        element.conversationStatus !== "blocked"
          ? "chat-contact-wrapper fade-in"
          : "chat-contact-wrapper blocked-conversation"
      }
      onClick={() => handleSelect()}
      style={
        selectedConversation?.phoneNumber === element.phoneNumber
          ? {
              border: "1px solid var(--primary-color)",
            }
          : {}
      }
    >
      <span className="columna" style={{ gap: "6px", flex: 1 }}>
        <span className="horizontal">
          <p className="chat-contact-name">{element.name}</p>
          {/* <span style={{ marginLeft: "auto" }}>
            {element.conversationStatus === "blocked" && (
              <TbUserOff color="#FF6962" size={16} />
            )}
          </span> */}
        </span>
        <p
          className="chat-contact-display-message"
          id={`lastmessage-${element._id}`}
        ></p>
        {notificationCount > 0 && (
          <span className="notification-count-position">
            <p className="notification-count">{notificationCount}</p>
          </span>
        )}
        <span className="horizontal gap8">
          {usuario}
          {team}
        </span>

        {/* <span className="horizontal" style={{ fontSize: "14px" }}> */}

        {/*
                  {renderTimeToExpired(element.time)}
                  {renderLastTime(element.lastSeen)} */}
        {/* </span> */}
      </span>
    </span>
  );
};
