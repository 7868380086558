import { useRef, useState } from "react";
import { Input, Popover, Tooltip } from "antd";
import {
  agregarVariableWhatsapp,
  formatoTextHtml,
} from "../../../helpers/utilidadesTemplates";
import { FiBold, FiEye, FiEyeOff, FiItalic } from "react-icons/fi";
import "./formattedTextArea.css";
import { AiOutlineStrikethrough } from "react-icons/ai";
import EmojiPicker from "emoji-picker-react";
import { BotonAgregarVariable } from "../Layout/flows/forms/BotonAgregarVariable";
import { BsEmojiSmile } from "react-icons/bs";

const SIZE = 18;

const FormattedTextArea = ({
  value,
  setValue,
  minRows = 2,
  placeholder,
  nodeId,
  elementId,
  showSelectVariables = true,
  showDecorators = true,
  showEmojis = true,
  previewOnDefault = false,
  style = {},
  label = "",
  boldLabel = false,
  maxLength = null
}) => {
  const textAreaRef = useRef(null);

  const [showPreview, setShowPreview] = useState(previewOnDefault);

  const decorarTexto = (wrappingString, emoji, variable) => {
    let textArea = document.getElementById(elementId);

    if (textArea) {
      let s = textArea.selectionStart;
      let e = textArea.selectionEnd;

      let oldValue = textArea.value;

      if (
        wrappingString !== "" &&
        wrappingString !== "${" &&
        wrappingString !== "emoji"
      ) {
        let newValue =
          oldValue.slice(0, s) +
          wrappingString +
          oldValue.slice(s, e) +
          wrappingString +
          oldValue.slice(e, oldValue.length);
        textArea.value = newValue;
      }

      if (wrappingString === "${") {
        textArea.value = agregarVariableWhatsapp(
          textArea.value,
          variable,
          elementId
        );
      }

      if (wrappingString === "emoji") {
        textArea.value = oldValue + emoji.emoji;
      }

      setValue(textArea.value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div
      style={{
        // position: "relative",
        borderRadius: 8,
        // alignItems: "stretch",
        flexDirection: "column",
        display: "flex",
        width: "100%",
        minHeight: "54px",
        marginBottom: 20,
        ...style,
      }}
      className="gap-4"
    >
      <span className="horizontal-between">
        {boldLabel ? <b>{label}</b> : <p>{label}</p>}
        {showPreview ? (
          <Tooltip title="Editar texto" placement="left">
            <span style={{ height: SIZE }}>
              <FiEyeOff
                size={SIZE}
                className="pointer"
                onClick={() => setShowPreview((prevState) => !prevState)}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Mostrar texto formateado" placement="left">
            <span style={{ height: SIZE }}>
              <FiEye
                size={SIZE}
                className="pointer"
                onClick={() => setShowPreview((prevState) => !prevState)}
              />
            </span>
          </Tooltip>
        )}
      </span>
      {showPreview && (
        <div
          className="text-area-preview"
          dangerouslySetInnerHTML={{ __html: formatoTextHtml(value) }}
        />
      )}
      {!showPreview && (
        <div className="columna gap-4" style={{ width: "100%" }}>
          <Input.TextArea
            value={value}
            onChange={handleChange}
            autoSize={{ minRows: minRows }}
            className="input-formatted-text-area"
            style={{
              ...style,
            }}
            ref={textAreaRef}
            placeholder={placeholder}
            id={elementId}
          />
          <div className="contenedor-botones-texto">
            {showDecorators && (
              <>
                <div className="boton-texto" onClick={() => decorarTexto("*")}>
                  <FiBold size={14} />
                </div>
                <div className="boton-texto" onClick={() => decorarTexto("_")}>
                  <FiItalic size={14} />
                </div>
                <div className="boton-texto" onClick={() => decorarTexto("~")}>
                  <AiOutlineStrikethrough size={14} />
                </div>
                <div
                  className="boton-texto"
                  onClick={() => decorarTexto("```")}
                  style={{ fontSize: "14px" }}
                >
                  {`${"</>"}`}
                </div>
              </>
            )}
            {showEmojis && (
              <Popover
                trigger="click"
                content={
                  <EmojiPicker
                    onEmojiClick={(e) => decorarTexto("emoji", e)}
                    emojiStyle="native"
                    lazyLoadEmojis={true}
                  />
                }
              >
                <div className="boton-texto">
                  <BsEmojiSmile />
                </div>
              </Popover>
            )}
            {showSelectVariables && (
              <BotonAgregarVariable
                decorarTexto={decorarTexto}
                nodeId={nodeId}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormattedTextArea;
