import { Empty, Pagination, Spin } from "antd";
import LoadingIcon from "../../Loader/LoadingIcon";
import { CardBot } from "./cardBot/CardBot";
import { useContext, useEffect, useState } from "react";
import { getFlowLogs } from "../../../../helpers/fetchData/fetchFlows";
import { GlobalContext } from "../../../context/GlobalContext";

export const FlowsMainCards = ({
  filteredFlows,
  setFlows,
  loading,
  setLoading,
  currentPage,
  setCurrentPage,
  qrCodes,
  setQrCodes,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [flowLogs, setFlowLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingLogs(true);

      const currentIds = getCurrentPageFlows()?.map((flow) => flow._id);

      if (currentIds?.length > 0) {
        getFlowLogs(instance.userData.apiKey, { ids: currentIds })
          .then((res) => {
            if (res?.status === 200) {
              setFlowLogs(res?.data);
            }
          })
          .catch((err) => {
            console.error(err);
            messageApi.error("No se pudo obtener las métricas de los bots");
          })
          .finally(() => setLoadingLogs(false));
      }
    }
  }, [instance, filteredFlows, currentPage]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getPageSize() {
    if (screenWidth > 1500) {
      return 15;
    } else if (screenWidth > 1300) {
      return 12;
    } else {
      return 9;
    }
  }

  // Función para obtener los flows de la página actual
  function getCurrentPageFlows() {
    const startIndex = (currentPage - 1) * getPageSize();
    const endIndex = startIndex + getPageSize();

    return filteredFlows.slice(startIndex, endIndex);
  }

  return (
    <div>
      <Spin spinning={loading} indicator={<LoadingIcon size="large" />}>
        {filteredFlows.length > 0 ? (
          <>
            <div className="cards-bots-container">
              {getCurrentPageFlows()?.map((flow) => (
                <CardBot
                  flow={flow}
                  setFlows={setFlows}
                  setLoading={setLoading}
                  loadingLogs={loadingLogs}
                  key={flow?._id}
                  qrCodes={qrCodes}
                  setQrCodes={setQrCodes}
                  flowLogs={flowLogs}
                />
              ))}
            </div>
          </>
        ) : (
          <Empty
            className="empty-bots-list"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Spin>

      {filteredFlows.length > 0 && (
        <Pagination
          className="bots-pagination"
          size="small"
          pageSize={getPageSize()}
          current={currentPage}
          total={filteredFlows.length}
          onChange={(page) => setCurrentPage(page)}
          showSizeChanger={false}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} de ${total} bots`
          }
        />
      )}
    </div>
  );
};
