import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form, Select, Switch } from 'antd';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { BoxInfo } from '../../../../../box-info/BoxInfo';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { flattenFirstElementGetProducts } from '../../../../flows/utils/flattenJSON';
import { getAdditionalFields } from '../../../../../../../helpers/fetchData/fetchAdditionalFields';

export const ContactsLoadGetForm = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setContactsConfig
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [includeClientId, setIncludeClientId] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );
    }
  }, [instance]);

  useEffect(() => {
    if (tmpConfig?.contactsLoad?.objMap) {
      const { additionalFields, ...rest } = tmpConfig.contactsLoad.objMap;

      const formattedAdditionalFields = additionalFields?.reduce((acc, af) => {
        acc[af._id] = af.value;
        return acc;
      }, {});

      const newObjMap = { ...rest, ...formattedAdditionalFields };

      form.setFieldsValue({
        ...newObjMap,
        includeClientId: tmpConfig.contactsLoad.includeClientId
      });
      setIncludeClientId(!!tmpConfig.contactsLoad.includeClientId);
    }
  }, [tmpConfig, form]);

  const handleGuardar = (values) => {
    const {
      includeClientId,
      clientId,
      first_name,
      middle_name,
      last_name,
      birthday,
      phone,
      email,
      company,
      department,
      title,
      type,
      street,
      city,
      state,
      country,
      zip,
      ...otherFields
    } = values;

    const additionalFields = Object.keys(otherFields).map((key) => ({
      _id: key,
      value: otherFields[key]
    }));

    setTmpConfig((prevState) => ({
      ...prevState,
      contactsLoad: {
        ...prevState.contactsLoad,
        objMap: {
          first_name,
          middle_name,
          last_name,
          birthday,
          phone,
          email,
          company,
          department,
          title,
          type,
          street,
          city,
          state,
          country,
          zip,
          clientId,
          additionalFields
        },
        includeClientId
      }
    }));

    handleStage(2);
  };

  const keyOptions = useMemo(() => {
    if (result) {
      let flattenedArray = flattenFirstElementGetProducts(result);

      return (
        flattenedArray?.map((element) => {
          return { value: element, label: element };
        }) || []
      );
    }

    return [];
  }, [result]);

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={handleGuardar}
      name='contactsLoad'
      className='form-nodos'
    >
      <b style={{ marginBottom: '12px' }}>
        Mapeo sobre el resultado de la petición
      </b>
      <span style={{ marginBottom: '24px' }}>
        <BoxInfo
          message={[
            'Necesitamos asociar las características de tus contactos para que coincidan con el formato utilizado en Brocoly.',
            'Si quiere incluir un dato que no se encuentra disponible en los campos a continuación, primero debe crear el campo adicional'
          ]}
        />
      </span>

      <Form.Item name='includeClientId' label='¿Incluye la cuenta de cliente?'>
        <Switch
          checked={includeClientId}
          onChange={() => setIncludeClientId(!includeClientId)}
        />
      </Form.Item>

      {includeClientId && (
        <Form.Item
          name='clientId'
          label='Cuenta de cliente'
          rules={[{ required: true, message: 'Campo requerido' }]}
        >
          <Select options={keyOptions} allowClear />
        </Form.Item>
      )}

      <Form.Item
        name='first_name'
        label='Nombre'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='middle_name' label='Segundo nombre'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name='last_name'
        label='Apellido'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='birthday' label='Fecha de nacimiento'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name='phone'
        label='Teléfono'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='email' label='Correo electrónico'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='company' label='Organización'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='department' label='Departamento'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='title' label='Cargo'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='type' label='Tipo de dirección'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='street' label='Dirección'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='city' label='Localidad'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='state' label='Estado o provincia'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='country' label='País'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item name='zip' label='Código postal'>
        <Select options={keyOptions} allowClear />
      </Form.Item>

      {additionalFields?.length > 0 && (
        <>
          {additionalFields.map((field) => (
            <Form.Item key={field._id} name={field._id} label={field.name}>
              <Select options={keyOptions} allowClear />
            </Form.Item>
          ))}
        </>
      )}

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => handleStage(0)}
          >
            Volver
          </Button>

          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            icon={loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Continuar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
