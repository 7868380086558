import { useAuth0 } from '@auth0/auth0-react';
import { Application } from './appTab/Application';
import { FiSliders } from 'react-icons/fi';

export const CanalesContent = () => {
  const { isLoading } = useAuth0();

  return (
    <div
      className='config-main-wrapper'
      style={{ width: 'clamp(500px, 1000px, 100%)' }}
    >
      <div className='test-main-header'>
        <span
          className='horizontal'
          style={{ justifyContent: 'space-between' }}
        >
          <span className='columna' style={{ gap: '0px' }}>
            <span className='config-header-inline'>
              <span style={{ height: '24px' }}>
                <FiSliders size={24} color='var(--primary-color)' />
              </span>
              <span className='header-title-templates'>Canales</span>
            </span>
            <p className='header-subtitle'>
              Configure canales para poder enviar mensajes
            </p>
          </span>
        </span>
      </div>

      <div className='tabla-config' style={{ maxWidth: 1200 }}>
        {!isLoading && <Application />}
      </div>
    </div>
  );
};
