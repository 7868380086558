import { Presupuestos } from "../carrito/Presupuestos";
import MainLayout from "../ui/Layout/MainLayout";

export const PresupuestoView = () => {
  return (
    <MainLayout>
      <Presupuestos />
    </MainLayout>
  );
};
