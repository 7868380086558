export const borrarFallback = (edges, nodeId) => {
  let array = [...edges];

  let index = edges.findIndex(
    (edge) => edge.source === nodeId && edge.sourceHandle === "fallback"
  );

  if (index >= 0) {
    array.splice(index, 1);
  }

  return array;
};
