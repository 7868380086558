export function formatPhoneNumber(phoneNumber) {
  if (typeof phoneNumber == 'string') {
    if (phoneNumber.length === 12) {
      const countryPrefix = phoneNumber.slice(0, 2);
      const areaCode = phoneNumber.slice(2, 5);
      const firstDigitsLocalNumber = phoneNumber.slice(5, 8);
      const lastDigitsLocalNumber = phoneNumber.slice(8);

      return `+${countryPrefix} ${areaCode} ${firstDigitsLocalNumber}-${lastDigitsLocalNumber}`;
    } else if (phoneNumber.length === 11) {
      const countryPrefix = phoneNumber.slice(0, 2);
      const areaCode = phoneNumber.slice(2, 4);
      const firstDigitsLocalNumber = phoneNumber.slice(4, 7);
      const lastDigitsLocalNumber = phoneNumber.slice(7);

      return `+${countryPrefix} ${areaCode} ${firstDigitsLocalNumber}-${lastDigitsLocalNumber}`;
    }
  }

  return '';
}
