import { accessDataFormat } from '../../../../helpers/access';
import { useAccess } from '../../../../hooks/useAccess';
import { VerContacto } from './accionesContactos/VerContacto';
import { EditarContacto } from './accionesContactos/EditarContacto';
import { BloquearContacto } from './accionesContactos/BloquearContacto';
import { DesbloquearContacto } from './accionesContactos/DesbloquearContacto';
import { ArchivarContacto } from './accionesContactos/ArchivarContacto';

export const AccionesContactos = ({ item }) => {
  const isAllowed = useAccess();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        justifyContent: 'center'
      }}
    >
      <VerContacto item={item} />
      {isAllowed(accessDataFormat('contacts', 'manage')) && (
        <EditarContacto item={item} />
      )}
      {item.isBlocked ? (
        <DesbloquearContacto item={item} />
      ) : (
        <BloquearContacto item={item} />
      )}
      <ArchivarContacto item={item} />
    </div>
  );
};
