import { Button, Tabs, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FiPaperclip, FiPlus } from 'react-icons/fi';
import { CargaMedia } from '../../../../modals/CargaMedia';
import { MdOutlineCloud } from 'react-icons/md';
import { BuyStorage } from './buyStorage/BuyStorage';
import { GlobalContext } from '../../../../../context/GlobalContext';
import {
  getFilesInBd,
  getFolderSize
} from '../../../../../../helpers/fetchData/fetchStorage';
import { formatFileSize } from '../../utils/formatFileSize';
import { calculateSizePercent } from '../../utils/calculateSizePercent';
import { FilesTable } from './tables/FilesTable';
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const StorageSettings = () => {
  const {
    instance,
    messageApi,
    currentPlan,
    currentStorageSize,
    setCurrentStorageSize
  } = useContext(GlobalContext);

  const [tab, setTab] = useState('image');
  const [showCargaMedia, setShowCargaMedia] = useState(false);
  const [showBuyStorage, setShowBuyStorage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCurrentSize, setLoadingCurrentSize] = useState(false);
  const [loadingCurrentPlan, setLoadingCurrentPlan] = useState(false);
  const [currentStorageSizePercent, setCurrentStorageSizePercent] = useState(0);
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTable(true);
      getFilesInBd(instance)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            setFiles(res.data);
          } else {
            messageApi.error('Error al cargar los archivos');
          }
        })
        .catch((err) => {
          messageApi.error('Error al cargar los archivos');
          console.error(err);
        })
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [instance]);

  useEffect(() => {
    const newFilteredFiles = files.filter((file) => file.mediaType === tab);

    setFilteredFiles(newFilteredFiles);
  }, [tab, files]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingCurrentSize(true);
      getFolderSize(instance)
        .then((res) => {
          if (res?.status === 200) {
            const newSize = res.data.folderSize;
            const newPercent = calculateSizePercent(newSize, currentPlan.size);

            setCurrentStorageSize(newSize);
            setCurrentStorageSizePercent(newPercent);
          } else {
            messageApi.error(
              'Error al cargar el espacio de almacenamiento utilizado'
            );
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error(
            'Error al cargar el espacio de almacenamiento utilizado'
          );
        })
        .finally(() => {
          setLoadingCurrentSize(false);
        });
    }
  }, [instance, files]);

  const itemsTab = [
    {
      key: 'image',
      label: 'Imágenes',
      children: (
        <FilesTable
          mediaType='image'
          filteredFiles={filteredFiles}
          setFiles={setFiles}
          loading={loadingTable}
        />
      )
    },
    {
      key: 'document',
      label: 'Documentos',
      children: (
        <FilesTable
          mediaType='document'
          filteredFiles={filteredFiles}
          setFiles={setFiles}
          loading={loadingTable}
        />
      )
    },
    {
      key: 'audio',
      label: 'Audios',
      children: (
        <FilesTable
          mediaType='audio'
          filteredFiles={filteredFiles}
          setFiles={setFiles}
          loading={loadingTable}
        />
      )
    },
    {
      key: 'video',
      label: 'Videos',
      children: (
        <FilesTable
          mediaType='video'
          filteredFiles={filteredFiles}
          setFiles={setFiles}
          loading={loadingTable}
        />
      )
    }
  ];

  return (
    <>
      {showBuyStorage ? (
        <BuyStorage setShowBuyStorage={setShowBuyStorage} />
      ) : (
        <div
          className='config-main-wrapper'
          style={{
            width: 'clamp(500px, 900px, 100%)'
          }}
        >
          <div className='test-main-header'>
            <span
              className='horizontal'
              style={{ justifyContent: 'space-between' }}
            >
              <span className='columna' style={{ gap: '0px' }}>
                <span className='config-header-inline'>
                  <span style={{ height: '24px' }}>
                    <FiPaperclip size={24} color='var(--primary-color)' />
                  </span>
                  <span className='header-title-templates'>Almacenamiento</span>
                </span>
                <p className='header-subtitle'>
                  Administra los archivos y el plan de almacenamiento
                </p>
              </span>

              <div className='espacio-botones'>
                <Tooltip
                  title={
                    currentStorageSize === currentPlan.size
                      ? 'No se pueden subir nuevos archivos. No queda espacio de almacenamiento'
                      : ''
                  }
                >
                  <Button
                    className='btn-agregar boton-dashed'
                    onClick={() => setShowCargaMedia(true)}
                    disabled={currentStorageSize === currentPlan.size}
                  >
                    <FiPlus /> Media
                  </Button>
                </Tooltip>
              </div>
            </span>
          </div>

          <div className='storage-cards-container'>
            <div
              className='CardBot storage-card'
              style={{ flexDirection: 'column' }}
            >
              {loadingCurrentPlan ? (
                <span
                  style={{
                    height: 300,
                    width: '100%',
                    display: 'grid',
                    placeItems: 'center'
                  }}
                >
                  <LoadingIcon size='large' />
                </span>
              ) : (
                <>
                  <div
                    className='storage-card-content'
                    style={{ flexDirection: 'row', gap: 32 }}
                  >
                    <span>{formatFileSize(currentPlan.size)}</span>
                    <span>{currentPlan.name}</span>
                  </div>

                  <Button
                    className='storage-card-btn btn-oscuro'
                    type='primary'
                    onClick={() => setShowBuyStorage(true)}
                  >
                    Comprar almacenamiento
                  </Button>
                </>
              )}
            </div>

            <div className='CardBot storage-card' style={{ gap: 16 }}>
              {loadingCurrentSize ? (
                <span
                  style={{
                    height: 300,
                    width: '100%',
                    display: 'grid',
                    placeItems: 'center'
                  }}
                >
                  <LoadingIcon size='large' />
                </span>
              ) : (
                <>
                  <span>
                    <MdOutlineCloud size={48} />
                  </span>

                  <div className='storage-card-content'>
                    <span>
                      {formatFileSize(currentStorageSize)} (
                      {currentStorageSizePercent}%)
                    </span>
                    <span>utilizados</span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className='conf-content'
            style={{ maxWidth: 900, marginLeft: 0 }}
          >
            <Tabs
              activeKey={tab}
              items={itemsTab}
              onChange={(newTab) => setTab(newTab)}
            />
          </div>
        </div>
      )}

      {showCargaMedia && (
        <div
          className='confirm-wrapper'
          onClick={() => setShowCargaMedia(false)}
        >
          <div className='confirm-form' onClick={(e) => e.stopPropagation()}>
            <CargaMedia
              modal={true}
              setShowCargaMedia={setShowCargaMedia}
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>
      )}
    </>
  );
};
