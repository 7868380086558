import MainLayout from "../ui/Layout/MainLayout"
import { Chat } from "../ui/Layout/chat/Chat"

export const ChatView = () => {
  return (
    <MainLayout >
        <Chat />
    </MainLayout>
  )
}
