import { Button, Form, Input, Spin } from 'antd';
import axios from 'axios';
import { encode } from 'js-base64';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { getProfile } from '../../../../../../../helpers/fetchData/fetchProfile';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const EditarApp = () => {
  const [form] = Form.useForm();

  const [showSpin, setShowSpin] = useState(false);

  const {
    setAppStage,
    instance,
    application,
    setApplication,
    setWhatsappProfile,
    messageApi
  } = useContext(GlobalContext);

  const onFinishApp = (values) => {
    const {
      appName,
      appId,
      wabaId,
      token,
      phoneNumber,
      phoneNumberId,
      businessId,
      webhookToken
    } = values;

    setShowSpin(true);
    const data = {
      appName,
      appId,
      wabaId,
      token,
      phoneNumber,
      phoneNumberId,
      businessId,
      webhookToken
    };

    const stringifiedData = JSON.stringify(data);

    const encoded = encode(stringifiedData);

    let config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/apps/updateApp`,
      headers: {
        Authorization: instance?.userData?.apiKey,
        'Content-Type': 'application/json'
      },
      data: { data: encoded }
    };

    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          setApplication((prevApplication) =>
            prevApplication.map((appObj) => ({
              ...appObj,
              ...data
            }))
          );

          setearPerfil(application);
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('La aplicación no pudo ser registrada');
      })
      .finally(() => setShowSpin(false));
  };

  const setearPerfil = (application) => {
    if (application.length > 0 && instance?.userData?.apiKey) {

      getProfile(application[0], instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setWhatsappProfile([res.data]);
            setTimeout(() => {
              setAppStage(2);
              setShowSpin(false);
            }, 1500);
          }
        })
        .catch((error) => {
          messageApi.error('Error al obtener perfiles');
          setShowSpin(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (application.length > 0) {
      form.setFieldsValue({
        appName: application[0].appName,
        appId: application[0].appId,
        token: application[0].token,
        businessId: application[0].businessId,
        webhookToken: application[0].webhookToken
      });

      if (application[0]?.lines) {
        form.setFieldsValue({
          wabaId: application[0]?.lines[0]?.wabaId,
          phoneNumber: application[0]?.lines[0]?.phoneNumber,
          phoneNumberId: application[0]?.lines[0]?.phoneNumberId
        });
      }
    }
  }, [application]);

  return (
    <div className='conf-content fade-in'>
      {!showSpin ? (
        <Form
          form={form}
          requiredMark='optional'
          name='newApplication'
          layout='vertical'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onFinish={(v) => onFinishApp(v)}
        >
          <Form.Item
            label='Nombre de aplicación'
            name='appName'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ID de aplicación'
            name='appId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input type='numeric' />
          </Form.Item>
          <Form.Item
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <p>Token de usuario (permanente)</p>
              </div>
            }
            name='token'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 10 }}
              className='input-desabilitado'
            />
          </Form.Item>
          <Form.Item
            label='ID de cuenta de WhatsApp Business'
            name='wabaId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ID teléfono'
            name='phoneNumberId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input type='tel' />
          </Form.Item>
          <Form.Item
            label='Número de teléfono'
            name='phoneNumber'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'No se permiten espacios ni caracteres especiales'
              },
              {
                min: 11,
                max: 12,
                message:
                  'El número debe contener 11-12 caracteres (ej: 543535123456)'
              }
            ]}
          >
            <Input
              style={{
                width: '100%'
              }}
            />
          </Form.Item>
          <Form.Item
            label='Business ID'
            name='businessId'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: 'Solo se permiten caracteres númericos'
              }
            ]}
          >
            <Input type='numeric' />
          </Form.Item>

          <Form.Item
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <p>Webhook Token</p>
              </div>
            }
            name='webhookToken'
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Campo requerido'
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              className='input-desabilitado'
            />
          </Form.Item>
          <div className='botones-wrapper-content' style={{ width: '100%' }}>
            <Button
              type='primary'
              htmlType='submit'
              className='btn-guardar'
              size='large'
              disabled={false}
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              type='secondary'
              size='large'
              disabled={false}
              onClick={(e) => {
                // e.preventDefault();
                setAppStage(2);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      ) : (
        <Spin
          style={{
            marginTop: 100
          }}
          indicator={<LoadingIcon size='large' />}
        />
      )}
    </div>
  );
};
