import { Button, Popover } from 'antd';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { TbTrash } from 'react-icons/tb';

export const EliminarDireccionEmail = ({ dataEmail, setDataEmail }) => {
  const [showPopoverDelete, setShowPopoverDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { messageApi } = useContext(GlobalContext);

  async function deleteDireccionEmail() {
    try {
      /* setLoading(true);
      await deleteOpenAiKey(apiKey);
      messageApi.success('API Key eliminada');
      setShowPopoverDelete(false);
      setOpenAiKey('') */
    } catch (error) {
      console.error(error);
      messageApi.error('Error al eliminar la dirección de correo');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Popover
      open={showPopoverDelete}
      content={
        <span className='columna' style={{ maxWidth: 280 }}>
          <p>¿Está seguro de eliminar la dirección de correo?</p>
          <span className='horizontal'>
            <Button
              type='primary'
              className='btn-borrar'
              onClick={deleteDireccionEmail}
              icon={
                loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Confirmar
            </Button>
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={() => setShowPopoverDelete(false)}
            >
              Cancelar
            </Button>
          </span>
        </span>
      }
    >
      <TbTrash
        size={18}
        className='pointer'
        onClick={() => setShowPopoverDelete(true)}
      />
    </Popover>
  );
};
