import { useContext } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Tooltip } from "antd";
import { FiEye } from "react-icons/fi";

export const VerContacto = ({ item }) => {
  const { setRightDrawerConfig } = useContext(GlobalContext);

  return (
    <Tooltip title="Ver">
      <span>
        <FiEye
          className="icono-acciones"
          onClick={() => {
            setRightDrawerConfig({
              visible: true,
              content: "Ver Contacto",
              item: item,
            });
          }}
        />
      </span>
    </Tooltip>
  );
};
