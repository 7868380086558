import { Modal } from "antd";
import { useContext } from "react";
import { FiTag, FiUploadCloud, FiUsers } from "react-icons/fi";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useAccess } from "../../../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../../../helpers/access";
import { PiAddressBookBold } from "react-icons/pi";

export const NewHandlerModal = ({ open, setOpen }) => {
  const isAllowed = useAccess();

  function handleCancel() {
    setOpen(false);
  }

  return (
    <Modal
      title="Nuevo elemento"
      open={open}
      width={400}
      footer={null}
      destroyOnClose
      onCancel={handleCancel}
    >
      <div className="select-trigger-card-container">
        {isAllowed(accessDataFormat("contacts", "manage")) && (
          <NewElementCard
            title="Contacto"
            value="contact"
            icon={<PiAddressBookBold size={42} />}
          />
        )}

        {isAllowed(accessDataFormat("tags", "manage")) && (
          <NewElementCard
            title="Etiqueta"
            value="tag"
            icon={<FiTag size={42} />}
          />
        )}

        <NewElementCard
          title="Media"
          value="media"
          icon={<FiUploadCloud size={42} />}
        />
      </div>
    </Modal>
  );
};

const NewElementCard = ({ title, value, icon }) => {
  const { setSideDrawerConfig } = useContext(GlobalContext);

  function handleClick() {
    setSideDrawerConfig({
      visible: true,
      content: "plus",
      tab: value,
    });
  }

  return (
    <div className="SelectTriggerCard" onClick={handleClick}>
      <span className="horizontal">{icon}</span>
      <span>{title}</span>
    </div>
  );
};
