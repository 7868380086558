import { Checkbox, Select } from "antd";
import { useState } from "react";

export const FilaCheck = ({
  item,
  index,
  tmpVars,
  setTmpVars,
  context = "",
}) => {
  // const [checked, setChecked] = useState(tmpVars[index]?.checked);
  const [type, setType] = useState(tmpVars[index]?.type);

  const handleSelectedItems = (item, checked, type) => {
    setTmpVars((prevState) => {
      let index = prevState.findIndex((fila) => fila.name === item.name);
      if (index >= 0) {
        let array = [...prevState];

        if (context === "indexed") {
          array[index] = {
            name: item.name,
            display: item.display,
            type: type,
            checked: checked,
          };
        } else {
          array[index] = { name: item.name, type: type, checked: checked };
        }

        return array;
      }
    });
  };

  return (
    <div className="horizontal" style={{ padding: "4px" }}>
      <Checkbox
        checked={item.checked}
        onChange={(v) => {
          // setChecked(v.target.checked);
          handleSelectedItems(item, v.target.checked, type);
        }}
        style={{ marginRight: "auto" }}
      >
        <p className="variable-name">
          {context === "indexed" ? item.display : item.name}
        </p>
      </Checkbox>
      {context !== "indexed" && (
        <Select
          size="small"
          style={{ width: "120px" }}
          options={[
            { value: "string", label: "String" },
            { value: "number", label: "Number" },
          ]}
          value={item.type}
          onChange={(v) => {
            setType(v);
            handleSelectedItems(item, item.checked, v);
          }}
        />
      )}
    </div>
  );
};
