import axios from 'axios';

export const getContactConfig = async (apiKey) => {
  try {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/contactConfig`,
      headers: {
        Authorization: apiKey
      }
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const upsertContactConfig = async (apiKey, data) => {
  try {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/contactConfig`,
      headers: {
        Authorization: apiKey
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteContactConfig = async (apiKey) => {
  try {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/contactConfig`,
      headers: {
        Authorization: apiKey
      }
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
