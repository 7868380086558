import { Button, Form, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { updateNode } from '../utils/updateNode';
import { getVariableName } from '../utils/getVariableName';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { TbAuth2Fa } from 'react-icons/tb';
import { HeaderForm } from './componentsForm/HeaderForm';

export const OneTimePasswordForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [nombreNodo, setNombreNodo] = useState('');

  const onFinish = (values) => {
    values.characters = values.characters.trim() || '';
    if (!/^[a-zA-Z0-9]+$/.test(values.characters)) {
      return messageApi.info('Solo se permiten letras y números');
    }

    if (isNaN(Number(values.count))) {
      return messageApi.info('No es un número');
    }

    if (Number(values.count) < 3 || Number(values.count) > 8) {
      return messageApi.info('Debe ingresar un numero entre 3 y 8');
    }

    if (values.characters.length < Number(values.count)) {
      return messageApi.info(
        `Debe ingresar al menos ${values.count} de caracteres`
      );
    }

    values.variableName = values.variableName.trim() || '';

    if (!values.variableName) {
      return messageApi.info('Ingrese un nombre para la variable');
    }

    values.label = nombreNodo;

    let variableName = getVariableName(values.variableName, 'otp');

    values.variableName = variableName;

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      {
        name: variableName,
        type: 'otp'
      },
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let { data } = nodoSeleccionado;
      setNombreNodo(data.label);

      form.setFieldsValue({
        characters: data.characters || '',
        count: data.count || null
      });

      if (data.variableName) {
        form.setFieldsValue({
          variableName: data.variableName.substring(
            data.variableName.indexOf('_') + 1
          )
        });
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formTranscribe'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<TbAuth2Fa style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Form.Item
        label='Caracteres a usar'
        name='characters'
        rules={[{ required: true, message: 'Campo obligatorio' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Cantidad de dígitos'
        name='count'
        rules={[{ required: true, message: 'Campo obligatorio' }]}
      >
        <Input type='number' />
      </Form.Item>
      <Form.Item
        label='Nombre de la variable'
        name='variableName'
        rules={[
          { required: true, message: 'Ingrese un nombre para la variable' }
        ]}
      >
        <Input addonBefore='otp_' />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
