import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { LoadingOutlined } from "@ant-design/icons";
import { getImage, handlePreview, renderPreview } from "./utils/chatFunctions";
import { getChatMediaById } from "../../../../helpers/fetchData/fetchBucket";

//? Esto debería venir de la config "app";

export const MessageMediaHeader = ({ data }) => {
  const { application, instance } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = useState(null);

  const getMediaUrl = () => {
    if (
      application?.[0]?.cloudStorageEnabled &&
      data?.type &&
      instance?.userData?.apiKey
    ) {
      const id = data[data.type]?.id;

      let ok = false;

      if (id) {
        setLoading(true);
        getChatMediaById(instance.userData?.apiKey, id)
          .then((res) => {
            if (res?.status === 200) {
              if (res.data?.publicURL) {
                setPreview(res.data?.publicURL);
                ok = true;
              }
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            if (ok) {
              setLoading(false);
            } else {
              handlePreview(
                data,
                application,
                setLoading,
                instance,
                setPreview
              );
            }
          });
      }
    }
  };

  useEffect(() => {
    if (instance?.userData?.apiKey && application?.[0]) {
      if (application[0].cloudStorageEnabled) {
        getMediaUrl();
      } else {
        handlePreview(data, application, setLoading, instance, setPreview);
      }
    }
  }, [data, application, instance]);

  if (loading) {
    return (
      <span
        style={
          data.type !== "template"
            ? { cursor: "pointer", maxWidth: 280 }
            : { maxWidth: 280 }
        }
      >
        <span
          style={{
            height: 100,
            width: 100,
            display: "grid",
            placeItems: "center",
          }}
        >
          <LoadingOutlined size={26} spin />
        </span>
      </span>
    );
  }

  if (preview) {
    return renderPreview(data, preview);
  }

  return (
    <span
      style={
        data.type !== "template"
          ? { cursor: "pointer", maxWidth: 280 }
          : { maxWidth: 280 }
      }
    >
      {getImage(data)}
    </span>
  );
};
