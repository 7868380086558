import BottomMenu from "./BottomMenu";
import TopMenu from "./TopMenu";

const SideMenu = () => {
  return (
    <>
      <TopMenu />
      <BottomMenu />
    </>
  );
};

export default SideMenu;
