import { Button, Empty, Form, Input, Spin, Tag } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiFillThunderbolt, AiOutlinePlusCircle } from 'react-icons/ai';
import { updateNode } from '../utils/updateNode';
import { FiX } from 'react-icons/fi';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { LoadingOutlined } from '@ant-design/icons';
import { addTrigger } from '../utils/addTrigger';
import { deleteTrigger } from '../utils/deleteTrigger';
import { BoxInfo } from '../../../box-info/BoxInfo';
import { HeaderForm } from './componentsForm/HeaderForm';

export const TriggerForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [inputTriggerValue, setInputTriggerValue] = useState('');
  const [triggers, setTriggers] = useState([]);
  const [nombreNodo, setNombreNodo] = useState('');
  const [form] = Form.useForm();
  const [isValidating, setIsValidating] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (nodoSeleccionado?.type === 'trigger') {
      values.triggers = triggers;
    }
    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      setNombreNodo(nodoSeleccionado?.data?.label);
      if (nodoSeleccionado?.data?.triggers) {
        setTriggers(nodoSeleccionado?.data?.triggers);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formTrigger'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<AiFillThunderbolt fill='rgb(244, 184, 0)' size={32} />}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '0px 4px 24px 0px',
          alignItems: 'center'
        }}
      >
        <Input
          style={{ width: 'calc(100% - 42px)' }}
          value={inputTriggerValue}
          onChange={(e) => setInputTriggerValue(e.target.value.toLowerCase())}
          suffix={
            <BoxInfo
              hideMode
              message='Puede ingresar varias palabras claves, separadas por comas'
            />
          }
        />
        {isValidating === false ? (
          <AiOutlinePlusCircle
            style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
            onClick={() =>
              addTrigger(
                inputTriggerValue,
                triggers,
                setTriggers,
                setIsValidating,
                setInputTriggerValue,
                messageApi,
                instance,
                setLoading
              )
            }
            size={26}
          />
        ) : (
          <LoadingOutlined size={26} spin />
        )}
      </div>
      <Form.Item
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: triggers?.length > 0 ? 'space-between' : 'center'
        }}
      >
        <Spin
          style={
            triggers.length > 0 && {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 402
            }
          }
          size='large'
          indicator={<LoadingOutlined />}
          spinning={loading}
        >
          {triggers?.length > 0 ? (
            <div className='triggers-list'>
              {triggers.map((trigger, index) => {
                return (
                  <span className='trigger-item' key={`${trigger}-${index}`}>
                    <Tag
                      key={`${trigger}-${index}`}
                      color='gold'
                      className='trigger-tag'
                    >
                      <p>{trigger}</p>
                      <span
                        className='delete-trigger'
                        onClick={() =>
                          deleteTrigger(index, triggers, setTriggers)
                        }
                      >
                        <FiX size={12} color='#d4b106' />
                      </span>
                    </Tag>
                  </span>
                );
              })}
            </div>
          ) : (
            <Empty
              description='No hay palabras claves configuradas'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{
                height: 60
              }}
            />
            /* <Result
                  style={{ padding: '12px' }}
                  title='No hay palabras claves configuradas'
                  subTitle='Es necesario que agregue al menos una.'
                  icon={<InfoCircleOutlined />}
                /> */
          )}
        </Spin>
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
