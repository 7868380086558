import { Form, Switch } from "antd";
import { switchType } from "./utils";
import { ButtonInput } from "../template-button-input/ButtonInput";

export const TemplateButtons = ({
  templateVariables,
  setTemplateVariables,
  varOptions,
  showHandles,
  setShowHandles,
  buttonsVars,
  setButtonsVars,
  context,
}) => {

  return (
    <>
      {templateVariables?.buttons?.value && (
        <>
          <Form.Item
            label={
              <span className="horizontal">
                <b style={{ fontSize: 15 }}>Botones</b>
                {context === "envios" &&
                  templateVariables?.buttons?.value?.filter(
                    (element) =>
                      !["PHONE_NUMBER", "URL", "COPY_CODE"].includes(
                        element?.type
                      )
                  )?.length > 0 && (
                    <>
                      <Switch
                        checked={showHandles}
                        onChange={(v) => setShowHandles(v)}
                        size="small"
                      />
                      <p>Resolver respuesta</p>
                    </>
                  )}
              </span>
            }
          >
            <span className="render-buttons-wrapper">
              <div className="layout-botones" style={{ marginTop: 0 }}>
                {templateVariables?.buttons?.value?.map((boton, index) => {
                  return (
                    <span
                      className="campaign-button-wrapper"
                      key={index}
                      style={index === 0 ? { borderTop: "none" } : {}}
                    >
                      <span
                        className="horizontal"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        {switchType(boton)}
                      </span>
                      <ButtonInput
                        index={index}
                        boton={boton}
                        buttonsVars={buttonsVars}
                        setButtonsVars={setButtonsVars}
                        varOptions={varOptions}
                      />
                    </span>
                  );
                })}
              </div>
            </span>
          </Form.Item>
        </>
      )}
    </>
  );
};
