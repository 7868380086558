import { useContext, useEffect, useState } from "react";
import { Button, Empty, InputNumber, Select, Spin, Tag } from "antd";
import { getCartTotal } from "../../utils/cart";
import { ConfirmItem } from "./ConfirmItem";
import { sendPresupuesto } from "../../helpers/sendPresupuesto";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchClientData } from "../../helpers/fetchClientData";
import { PresupuestosContext } from "../../context/PresupuestosContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { getContactName } from "../../../ui/Layout/campaigns/campaignBuilder/utils/getContactName";
import LoadingIcon from "../../../../../src/components/ui/Loader/LoadingIcon";

export const Confirm = () => {
  const {
    cartDetail,
    payload,
    presupuestoDrawer,
    setPresupuestoDrawer,
    setPresupuestos,
  } = useContext(PresupuestosContext);

  const { messageApi, instance, contactos } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [cliente, setCliente] = useState(null);

  const [vencimiento, setVencimiento] = useState(null);

  const [vencimientoType, setVencimientoType] = useState("horas");

  const [productsByList, setProductsByList] = useState([]);

  const [loadingCliente, setLoadingCliente] = useState(false);

  const handleConfirmarPresupuesto = (v) => {
    // if (!payload?.payload?.userNumber) {
    //   messageApi.info(
    //     "No se pudo obtener el número del usuario, no se enviará mensaje de confirmación"
    //   );

    //   return;
    // }

    if (!payload?.payload?.clientId) {
      return messageApi.info("No se pudo identificar el cliente");
    }

    let createdAt = Date.now() - 180 * 60 * 1000;

    let number = null;

    let dueDate = null;

    if (vencimiento) {
      number = Number(vencimiento);
      if (!Number.isInteger(number)) {
        return messageApi.info("Ingrese un numero entero para el vencimiento");
      } else {
        if (vencimientoType === "dias") {
          dueDate = createdAt + number * 24 * 60 * 60 * 1000;
        } else {
          dueDate = createdAt + number * 60 * 60 * 1000;
        }
      }
    }

    let detalle = cartDetail.map((element) => {
      return {
        id: element.id,
        item: {
          id: element.id,
          title: element.item.title,
          price: element.item.price,
          listId: element.item.listId,
          listName: element.item.listName,
          image_link: element.item.image_link,
          brand: element.item.brand,
          sku: element.item.sku,
        },
        count: element.count,
      };
    });

    //! Adaptar a contactos de la plataforma.

    let data = {
      phonenumber: cliente.phone,
      clientId: cliente._id,
      userNumber: cliente.phone,
      cli_nombre: cliente.nombre,
      payload: detalle,
      createdAt: createdAt.toString(),
      duracion: { number: number, type: vencimientoType },
      vencimiento: dueDate ? dueDate.toString() : null,
    };

    if (!presupuestoDrawer?.edit) {
      setLoading(true);

      sendPresupuesto(data, instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);

            if (res.data?.id) {
              setPresupuestos((prevState) => {
                let array = [...prevState];

                array.push({ ...data, _id: res.data.id });

                return array;
              });
            }

            setPresupuestoDrawer({
              visible: false,
              stage: "home",
              edit: false,
              data: null,
            });

            messageApi.success("Presupuesto enviado correctamente");
          } else {
            messageApi.error("Ocurrió un error al enviar presupuesto");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al enviar presupuesto")
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("Guardar presupuesto");
    }
  };

  useEffect(() => {
    if (payload.payload?.clientId) {
      let client = contactos?.find(
        (contacto) => contacto._id === payload.payload.clientId
      );

      if (client) {
        let domicilio = "";

        const { street, city, state } = client.addresses?.[0] || {};

        if (street) {
          domicilio = street;
        }

        if (city) {
          domicilio = `${domicilio}, ${city}`;
        }

        if (state) {
          domicilio = `${domicilio}, ${state}`;
        }

        let obj = {
          phone: client.phones?.[0]?.phone,
          domicilio: domicilio,
          nombre: getContactName(client.name),
          email: client.emails?.[0]?.email,
          _id: client._id,
        };

        setCliente(obj);
      }
    }
  }, [payload, contactos]);

  useEffect(() => {
    if (presupuestoDrawer.edit) {
      if (
        presupuestoDrawer.data?.duration ||
        presupuestoDrawer.data?.duration?.number
      ) {
        setVencimiento(presupuestoDrawer.data.duration.number);
        setVencimientoType(presupuestoDrawer.data.duration.type);
      }
    }
  }, []);

  useEffect(() => {
    const groupedByListId = cartDetail?.reduce((result, item) => {
      const listId = item.item.listId;
      const listName = item.item.listName;

      if (!result[listId]) {
        result[listId] = {
          listId: listId,
          listName: listName,
          items: [],
        };
      }

      result[listId].items.push(item);

      return result;
    }, {});

    const groupedArray = Object.values(groupedByListId);

    setProductsByList(groupedArray);
  }, [cartDetail]);

  return (
    <div className="home-wrapper">
      <div className="presupuesto-content-wrapper">
        <div>
          <p
            className="presupuesto-subtitles"
            style={{ borderBottom: "1px solid #ececec", paddingBottom: 8 }}
          >
            Cliente
          </p>
          <div className="cliente-details">
            {loadingCliente ? (
              <Spin
                indicator={<LoadingIcon size="large" />}
                style={{ margin: "50px 0px" }}
              />
            ) : (
              cliente && (
                <>
                  <p style={{ fontWeight: 500 }}>{cliente.nombre}</p>
                  {/* <p className="client-description-highlight">
                    {cliente.cli_cuit}
                  </p> */}
                  <p className="client-description-highlight">
                    {cliente.phone}
                  </p>
                  <p className="client-description-highlight">
                    {cliente.domicilio}
                  </p>
                  <p className="client-description-highlight">
                    {cliente.email}
                  </p>
                </>
              )
            )}
          </div>
        </div>
        <div>
          <p
            className="presupuesto-subtitles"
            style={{ marginBottom: 8 }}
          >{`Artículos (${cartDetail.length})`}</p>
          <div className="presupuesto-products-layout">
            {productsByList?.length > 0 ? (
              productsByList.map((lista, index) => {
                return (
                  <div key={`lista${index}`}>
                    <Tag
                      color="green"
                      size="large"
                      style={{ maxWidth: "100%" }}
                    >
                      {lista.listName}
                    </Tag>
                    {lista.items.map((item) => (
                      <ConfirmItem element={item} key={item.id} />
                    ))}
                    <span className="total-presupuesto subtotal">
                      <p>Sub total</p>
                      <p>{getCartTotal(lista.items)}</p>
                    </span>
                  </div>
                );
              })
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Agrega artículos a tu carrito"
                style={{ marginTop: "20%" }}
              />
            )}
          </div>
        </div>
        {cartDetail?.length > 0 && (
          <span className="total-presupuesto" style={{ marginTop: "-24px" }}>
            <p>Total</p>
            <p>{getCartTotal(cartDetail)}</p>
          </span>
        )}
        {cartDetail?.length > 0 && (
          <span className="total-presupuesto">
            <span
              style={{
                fontSize: 14,
                display: "inline-flex",
                flexWrap: "wrap",
                gap: 4,
              }}
            >
              Vencimiento{" "}
              <p style={{ color: "#858585", fontWeight: 400 }}>(opcional)</p>
            </span>
            <InputNumber
              value={vencimiento ?? vencimiento}
              style={{ width: 140 }}
              controls={false}
              type="number"
              onChange={(v) => setVencimiento(v)}
              addonAfter={
                <Select
                  value={vencimientoType}
                  onChange={(v) => setVencimientoType(v)}
                  style={{ width: "72px" }}
                  options={[
                    { label: "Hs", value: "horas" },
                    { label: "Días", value: "dias" },
                  ]}
                />
              }
            />
          </span>
        )}
      </div>
      <span
        className="horizontal cart-footer"
        style={{ justifyContent: "flex-end" }}
      >
        <Button
          type="primary"
          onClick={handleConfirmarPresupuesto}
          disabled={!cartDetail?.length || !cliente}
          // loading={loading}
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          {presupuestoDrawer?.edit ? "Guardar" : "Presupuestar"}
        </Button>
      </span>
    </div>
  );
};
