import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, TimePicker } from 'antd';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FiClock, FiPlus, FiX } from 'react-icons/fi';
import { dias } from '../dias';
import {
  handleGranularityCount,
  handleGranularityValues,
  handleTime,
  handleTimeConditionsCount,
  isDaySelected
} from '../utils/utilidadesFlow';
import { updateNode } from '../utils/updateNode';
import dayjs from 'dayjs';
import { HeaderForm } from './componentsForm/HeaderForm';

export const TimeForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, nodes, setNodes } =
    useContext(FlowContext);

  const [form] = Form.useForm();

  const { messageApi } = useContext(GlobalContext);

  const [nombreNodo, setNombreNodo] = useState('');

  const [condiciones, setCondiciones] = useState([
    {
      childId: '',
      value: [
        {
          granularities: {
            weekdays: [],
            time: {
              from: {
                hour: '00',
                minute: '00'
              },
              to: {
                hour: '00',
                minute: '00'
              }
            }
          }
        }
      ]
    }
  ]);

  const onFinish = (values) => {
    values.label = nombreNodo;
    values.type = 'dateTime';
    values.tz = '-3';
    values.payload = condiciones;
    values.handleCount = condiciones.length + 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;
      setNombreNodo(data.label);

      if (data?.payload) {
        if (data.payload?.length > 0) {
          let conditions = [...data.payload];

          let lastElement = conditions[conditions.length - 1];
          if (lastElement?.value.length === 0) {
            conditions.pop();
          }
          setCondiciones(conditions);
        }
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      name='timeForm'
      layout='vertical'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<FiClock style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Form.Item label='Condiciones'>
        <div
          className='template-crear-lista'
          style={{ margin: '0px 0px 0px 0px' }}
        >
          {condiciones?.map((condicion, index) => {
            return (
              <div key={index} className='item-wrapper'>
                <Form.Item
                  style={{ marginBottom: '8px' }}
                  label={
                    <span className='horizontal-list' style={{ width: '100%' }}>
                      <p>Condición</p>
                      <FiX
                        style={{ cursor: 'pointer' }}
                        size={20}
                        onClick={() => {
                          setCondiciones(
                            handleTimeConditionsCount(
                              'resta',
                              condiciones,
                              index
                            )
                          );
                        }}
                      />
                    </span>
                  }
                >
                  <div className='time-conditions-wrapper'>
                    {condicion.value?.map((granularity, granularityIndex) => {
                      return (
                        <div
                          className='condition-time-wrapper'
                          key={`${index}-${granularityIndex}`}
                        >
                          <span className='dias-wrapper'>
                            {dias?.map((dia) => {
                              return (
                                <span
                                  className={isDaySelected(
                                    dia.value,
                                    granularity.granularities.weekdays
                                  )}
                                  key={dia.value}
                                  onClick={() =>
                                    setCondiciones(
                                      handleGranularityValues(
                                        'dia',
                                        dia.value,
                                        condiciones,
                                        index,
                                        granularityIndex
                                      )
                                    )
                                  }
                                >
                                  {dia.label}
                                </span>
                              );
                            })}
                            <FiX
                              size={16}
                              className='borrar-horario'
                              onClick={() =>
                                setCondiciones(
                                  handleGranularityCount(
                                    'resta',
                                    condiciones,
                                    index,
                                    granularityIndex
                                  )
                                )
                              }
                            />
                          </span>
                          <span className='dias-wrapper'>
                            <TimePicker
                              format='HH:mm'
                              placeholder='Inicio'
                              inputReadOnly={true}
                              onOk={(v) => {
                                setCondiciones(
                                  handleGranularityValues(
                                    'inicio',
                                    dayjs(v).format('HH:mm'),
                                    condiciones,
                                    index,
                                    granularityIndex
                                  )
                                );
                              }}
                              value={handleTime(
                                granularity.granularities?.time?.from
                              )}
                              style={{ width: '100px' }}
                              showNow={false}
                            />
                            <p>Hasta</p>
                            <TimePicker
                              format='HH:mm'
                              placeholder='Fin'
                              inputReadOnly={true}
                              onOk={(v) => {
                                setCondiciones(
                                  handleGranularityValues(
                                    'fin',
                                    dayjs(v).format('HH:mm'),
                                    condiciones,
                                    index,
                                    granularityIndex
                                  )
                                );
                              }}
                              value={handleTime(
                                granularity.granularities?.time?.to
                              )}
                              style={{ width: '100px' }}
                              showNow={false}
                            />
                          </span>
                        </div>
                      );
                    })}
                    <Button
                      className='btn-agregar btn-oscuro'
                      type='primary'
                      style={{ marginLeft: 'auto' }}
                      onClick={() =>
                        setCondiciones(
                          handleGranularityCount('suma', condiciones, index)
                        )
                      }
                    >
                      <FiClock size={18} />
                    </Button>
                  </div>
                </Form.Item>
              </div>
            );
          })}
          <Button
            className='btn-agregar btn-oscuro'
            type='primary'
            onClick={() => {
              setCondiciones(handleTimeConditionsCount('suma', condiciones));
            }}
            style={{ marginLeft: 'auto' }}
          >
            <FiPlus /> Condición
          </Button>
        </div>
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
