import { FaMeta } from 'react-icons/fa6';
import { getTier, getTierMessage } from '../../utils/getTier';
import { Image, Tooltip } from 'antd';
import { BoxInfoPopUp } from '../../../../../../box-info/BoxInfoPopUp';

export const MessageLimit = ({ businessCompliance }) => {
  const tier = getTier(businessCompliance);
  const tierMessage = getTierMessage(businessCompliance);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 25
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <span>Límite de mensajes</span>
        <BoxInfoPopUp
          hideMode
          message={
            <>
              <p>
                El <b>Límite de mensajes</b> determina cuántas conversaciones
                puedes iniciar en un periodo de 24 horas. Una conversación se
                considera iniciada por la empresa cuando envías el primer
                mensaje a un cliente. Para alcanzar límites superiores debes
                mantener una buena Calidad de Mensajes y aumentar tu nivel de
                mensajería.
              </p>
              <p>
                <b>Importante:</b> Este límite no aplica a las conversaciones
                iniciadas por el cliente.
              </p>
            </>
          }
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Image
            src='https://binamics.nyc3.digitaloceanspaces.com/logos/logo-brocoly-02.png'
            width={18}
            preview={false}
            style={{ paddingBottom: 4 }}
          />
          <Tooltip title={tierMessage}>
            <span
              style={{
                fontSize: 13,
                fontWeight: 500,
                color: 'black'
              }}
            >
              {tier}
            </span>
          </Tooltip>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <FaMeta fill='#0081FB' size={18} />
          <Tooltip title={tierMessage}>
            <span
              style={{
                fontSize: 13,
                fontWeight: 500,
                color: 'black'
              }}
            >
              {tier}
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
