import { Avatar, Badge } from 'antd';
import { findUser } from './chatFunctions';

export const renderAgent = (usuarios, userId, otherAgents = []) => {
  if (userId && usuarios) {
    const usuario = findUser(userId, usuarios);

    if (usuario?.name) {
      let string = '';

      let array = usuario.name.split(' ');

      array.forEach((element) => {
        string = string + element[0]?.toUpperCase();
      });

      return (
        <>
          {otherAgents?.length > 0 ? (
            <Badge size='small' count='+' title='Otros agentes'>
              <Avatar size='small' style={getColors(usuario.index)}>
                {string}
              </Avatar>
            </Badge>
          ) : (
            <Avatar size='small' style={getColors(usuario.index)}>
              {string}
            </Avatar>
          )}
        </>
      );
    }
  }
  
  return <></>;
};

const colors = [
  '#ff6f00',
  '#607D8B',
  '#42A5F5',
  '#00BCD4',
  '#FF7043',
  '#7E57C2',
  '#66BB6A',
  '#F44336',
  '#FFEB3B',
  '#3F51B5'
];

const getColors = (index) => {
  let newIndex = index % 10;

  const checkBrightness = (hex) => {
    if (!hex) {
      return {};
    }

    hex = hex?.replace('#', '');
    const r = parseInt(hex.substring(0, 0 + 2), 16);
    const g = parseInt(hex.substring(2, 2 + 2), 16);
    const b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    const lowBrightnessStyle = {
      backgroundColor: `#${hex}`,
      color: `rgba(0,0,0,0.5)`,
      borderColor: `rgba(0,0,0,0.3)`
    };

    const highBrightnessStyle = {
      backgroundColor: `rgba(${r},${g},${b}, 0.2)`,
      color: `#${hex}`,
      borderColor: `#${hex}`
    };

    if (brightness > 155) {
      return lowBrightnessStyle;
    } else {
      return highBrightnessStyle;
    }
  };

  return checkBrightness(colors[newIndex]);
};
