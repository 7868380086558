import "./presupuestos.css";
import { useEffect, useState } from "react";
import { PresupuestosContext } from "./context/PresupuestosContext";
import { Button, Drawer, Input, Select } from "antd";
import { FiPlus } from "react-icons/fi";
import { HomeView } from "./views/HomeView";
import { CartView } from "./views/CartView";
import { ConfirmView } from "./views/ConfirmView";
import { PresupuestosTabla } from "./PresupuestosTabla";
import { useAccess } from "../../hooks/useAccess";
import { accessDataFormat } from "../../helpers/access";

export const Presupuestos = () => {
  const [catalogDetail, setCatalogDetail] = useState([]);
  const [cartDetail, setCartDetail] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [presupuestos, setPresupuestos] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [payload, setPayload] = useState({ hash: null, payload: null });
  const [presupuestoDrawer, setPresupuestoDrawer] = useState({
    visible: false,
    edit: false,
    stage: "home",
    data: null,
  });

  const isAllowed = useAccess();

  const [busqueda, setBusqueda] = useState("");

  const [status, setStatus] = useState("todos");

  const ESTADOS = [
    { value: "todos", label: "TODOS" },
    { value: "PENDING", label: "PENDIENTE" },
    { value: "APPROVED", label: "APROBADO" },
    { value: "UNAPPROVED", label: "NO APROBADO" },
  ];

  const handleDrawerContent = () => {
    if (presupuestoDrawer?.stage) {
      switch (presupuestoDrawer.stage) {
        case "home":
          return <HomeView />;
        case "carrito":
          return <CartView />;
        case "confirm":
          return <ConfirmView />;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!presupuestoDrawer.visible) {
      setCartDetail([]);
      setPayload({ hash: null, payload: null });
    }
  }, [presupuestoDrawer]);

  return (
    <PresupuestosContext.Provider
      value={{
        catalogDetail,
        setCatalogDetail,
        cartDetail,
        setCartDetail,
        allProducts,
        setAllProducts,
        selectedList,
        setSelectedList,
        payload,
        setPayload,
        presupuestoDrawer,
        setPresupuestoDrawer,
        presupuestos,
        setPresupuestos,
      }}
    >
      <div className="main-wrapper">
        <div className="main-header">
          <span>Presupuestos</span>
          <span className="horizontal">
            <Input.Search
              value={busqueda}
              onChange={(v) => setBusqueda(v.target.value)}
              placeholder="Buscar por cliente..."
            />
            <span>
              <Select
                options={ESTADOS}
                value={status}
                onChange={(v) => setStatus(v)}
                style={{ width: 150 }}
              />
            </span>
          </span>
          {isAllowed(accessDataFormat("budgets", "manage")) && (
            <div className="espacio-botones">
              <Button
                type="primary"
                className="btn-agregar"
                onClick={() => {
                  setPresupuestoDrawer({
                    visible: true,
                    stage: "home",
                    data: null,
                    edit: false,
                  });
                }}
              >
                <FiPlus /> Presupuesto
              </Button>
            </div>
          )}
        </div>
        <div className="presupuestos-tabs-wrapper">
          <PresupuestosTabla busqueda={busqueda} status={status} />
          <Drawer
            width={500}
            destroyOnClose
            maskClosable={true}
            placement={"right"}
            closable={true}
            open={presupuestoDrawer?.visible}
            key={"presupuestoDrawer"}
            styles={{ header: { display: "none" }, body: { padding: "0px" } }}
          >
            {handleDrawerContent()}
          </Drawer>
        </div>
      </div>
    </PresupuestosContext.Provider>
  );
};
