import React from "react";
import { getTotalByCartItem } from "../../utils/cart";

export const ConfirmItem = ({ element }) => {
  let total = getTotalByCartItem(element);
  const producto = element.item;

  return (
    <div className="presupuesto-product-wrapper">
      <div className="presupuesto-product-detalle">
        <span className="horizontal">
          <p className="presupuesto-product-title">{producto.title}</p>
        </span>
        <span className="horizontal">
          <span className="product-description">
            Cantidad:
            <p className="product-description-highlight">{element.count}</p>
          </span>
          <span className="product-description">
            Precio:
            <p className="product-description-highlight">{`$${
              producto.price?.split(" ")?.[0]
            }`}</p>
          </span>
        </span>
      </div>
      <div className="total-presupuesto-precio">{`$${total}`}</div>
    </div>
  );
};
