import { Button, Form, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getMediaByType } from '../../../../../helpers/fetchData/fetchMedia';
import { GlobalContext } from '../../../../context/GlobalContext';
import { CargaMedia } from '../../../modals/CargaMedia';

export const MediaForm = ({
  url,
  setURL,
  setMostrarDrawerMedia,
  mediaType,
  showNewMediaButton = false
}) => {
  const [form] = Form.useForm();

  const [documents, setDocuments] = useState([]);

  const [showCargaMedia, setShowCargaMedia] = useState(false);

  const [reload, setReload] = useState(false);

  const { instance, messageApi } = useContext(GlobalContext);

  useEffect(() => {
    if (instance?._id) {
      getMediaByType(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            let filtro = res.data.filter(
              (element) => element.mediaType === mediaType
            );

            if (filtro?.length > 0) {
              let array = filtro.map((element) => {
                return { value: element.publicURL, label: element.name };
              });
              setDocuments(array);
            } else {
              messageApi.info('No se encontraron archivos del tipo buscado.');
            }
          } else {
            messageApi.error('No se pudo obtener archivos');
          }
        })
        .catch((error) => {
          messageApi.error('No se pudo obtener archivos');
        });
    }
  }, [instance, reload]);

  const onFinish = (values) => {
    if (values.url) {
      setURL(values.url);
      setMostrarDrawerMedia(false);
    } else {
      messageApi.info('Debe seleccionar un documento antes de guardar');
    }
  };

  return (
    <>
      {showCargaMedia && (
        <div
          className='confirm-wrapper'
          onClick={() => setShowCargaMedia(false)}
        >
          <div className='confirm-form' onClick={(e) => e.stopPropagation()}>
            <CargaMedia
              modal={true}
              setShowCargaMedia={setShowCargaMedia}
              defaultMediaType={mediaType}
              setReload={setReload}
            />
          </div>
        </div>
      )}

      <Form
        form={form}
        layout='vertical'
        name='formMedia'
        requiredMark={false}
        onFinish={(v) => onFinish(v)}
        className='form-nodos'
      >
        <Form.Item label='Seleccione un archivo' name='url'>
          <Select
            options={documents}
            showSearch
            filterOption={(input, option) =>
              (option.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        {showNewMediaButton && (
          <Button
            className='btn-oscuro'
            type='secondary'
            onClick={() => setShowCargaMedia(true)}
          >
            Cargar uno nuevo
          </Button>
        )}
        <Form.Item className='form-custom-footer'>
          <div className='botones-wrapper-content'>
            <Button
              type='primary'
              htmlType='submit'
              className='btn-guardar'
              size='large'
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              size='large'
              type='secondary'
              onClick={() => {
                setMostrarDrawerMedia(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
