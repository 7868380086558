import { FaArrowRight } from "react-icons/fa";

export const Clientes = () => {
  return (
    <section className="landing-section landing-section-clientes">
      <h3 className="landing-section-title">
        <span className="landing-font-highlight">
          Sé parte de un nuevo mundo
        </span>
      </h3>

      <p className="landing-section-title-sm">
        ¡Dale a tus clientes y distribuidores
        <br />
        la atención que merecen!
      </p>
      <span className="horizontal">
        <span className="landing-linea-blanca"></span>
        <p className="landing-section-subtitle">
          Estas empresas están evolucionando en una era de la transformación
        </p>
        <span className="landing-linea-blanca"></span>
      </span>
      <p
        className="landing-section-subtitle"
        style={{ margin: "-42px 0px 3rem 0px" }}
      >
        tecnológica y han elegido brocoly para liderar este cambio.
      </p>

      <span className="landing-logos">
        <span className="landing-clientes-logos">
          <img
            alt="cli-01"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-1.png`}
          />
          <img
            alt="cli-02"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-2.png`}
          />
          <img
            alt="cli-03"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-3.png`}
          />
          <img
            alt="cli-04"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-4.png`}
          />
          <img
            alt="cli-05"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-5.png`}
          />
          <img
            alt="cli-06"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-6.png`}
          />
          <img
            alt="cli-07"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-7.png`}
          />
          <img
            alt="cli-08"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-8.png`}
          />
          <img
            alt="cli-09"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-9.png`}
          />
        </span>
        <span className="landing-clientes-logos">
          <img
            alt="cli-01"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-1.png`}
          />
          <img
            alt="cli-02"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-2.png`}
          />
          <img
            alt="cli-03"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-3.png`}
          />
          <img
            alt="cli-04"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-4.png`}
          />
          <img
            alt="cli-05"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-5.png`}
          />
          <img
            alt="cli-06"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-6.png`}
          />
          <img
            alt="cli-07"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-7.png`}
          />
          <img
            alt="cli-08"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-8.png`}
          />
          <img
            alt="cli-09"
            src={`${process.env.PUBLIC_URL}/assets/landing-images/logos/logo-9.png`}
          />
        </span>
      </span>

      <button className="landing-boton-generico landing-boton-bordered">
        <p>Mirá como funciona</p>
        <FaArrowRight />
      </button>
    </section>
  );
};
