import { Button, Popover, Tooltip } from 'antd';
import { BiMessageAdd } from 'react-icons/bi';
import { FiEdit3 } from 'react-icons/fi';
import { MessagesList } from './MessagesList';
import { TbTrash } from 'react-icons/tb';
import { useContext, useState } from 'react';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { useAccess } from '../../../../../../../hooks/useAccess';
import { deleteCategory } from '../../../../../../../helpers/fetchData/fetchSavedMessages';
import { accessDataFormat } from '../../../../../../../helpers/access';

export const expandedRowRender = (record, setGrupos) => {
  return <MessagesList record={record} setGrupos={setGrupos} />;
};

const AccionesMensajes = ({ item, setMensajesDrawer, setGrupos }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [showPopoverEliminarCategoria, setShowPopoverEliminarCategoria] =
    useState(false);

  function getContentPopoverEliminarCategoria() {
    return (
      <span className='columna' style={{ maxWidth: 280 }}>
        <p>¿Está seguro de eliminar esta categoría?</p>
        <span className='horizontal'>
          <Button
            type='primary'
            className='btn-borrar'
            onClick={eliminarCategoria}
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Confirmar
          </Button>
          <Button
            type='secondary'
            className='btn-cancelar'
            onClick={() => setShowPopoverEliminarCategoria(false)}
          >
            Cancelar
          </Button>
        </span>
      </span>
    );
  }

  const isAllowed = useAccess();

  function eliminarCategoria() {
    if (item.savedReplies?.length === 0) {
      setLoading(true);

      deleteCategory(instance?.userData?.apiKey, item._id)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Categoría eliminada correctamente');
          } else {
            messageApi.error('Error al eliminar la categoría');
          }

          setGrupos((prevState) => {
            let array = prevState.filter((i) => {
              return i._id !== item._id;
            });

            return array;
          });
        })
        .catch((error) => {
          messageApi.error('Error al eliminar la categoría');
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      messageApi.info('Solo puedes eliminar categorías vacías');
    }
  }

  return (
    <span
      className='horizontal gap8'
      style={{ marginInline: 'auto', width: 'fit-content' }}
    >
      {isAllowed(accessDataFormat('savedMessages', 'manage')) && (
        <Tooltip title='Agregar mensaje'>
          <BiMessageAdd
            className='pointer'
            size={18}
            onClick={(e) => {
              e.stopPropagation();
              setMensajesDrawer({
                visible: true,
                content: 'mensaje',
                item: item
              });
            }}
          />
        </Tooltip>
      )}
      {isAllowed(accessDataFormat('savedMessages', 'manage')) && (
        <Tooltip title='Editar categoría'>
          <FiEdit3
            className='pointer'
            size={18}
            onClick={(e) => {
              e.stopPropagation();
              setMensajesDrawer({
                visible: true,
                content: 'editarCategoria',
                item: item
              });
            }}
          />
        </Tooltip>
      )}
      {isAllowed(accessDataFormat('savedMessages', 'manage')) && (
        <Popover
          open={showPopoverEliminarCategoria}
          content={getContentPopoverEliminarCategoria}
        >
          <Tooltip title='Eliminar categoría'>
            <TbTrash
              className='pointer'
              size={18}
              onClick={() => setShowPopoverEliminarCategoria(true)}
            />
          </Tooltip>
        </Popover>
      )}
    </span>
  );
};

export const savedRepliesColumns = (setMensajesDrawer, setGrupos) => {
  return [
    { title: 'Categoría', key: 'name', dataIndex: 'name' },
    {
      title: 'Cantidad',
      key: 'savedReplies',
      dataIndex: 'savedReplies',
      render: (data) => data.length
    },
    {
      title: '...',
      key: '',
      dataIndex: '',
      align: 'center',
      width: 120,
      render: (data, item) => (
        <AccionesMensajes
          item={item}
          setMensajesDrawer={setMensajesDrawer}
          setGrupos={setGrupos}
        />
      )
    }
  ];
};
