import { openNotification } from "../../../../../helpers/openNotification";
import { getContactName } from "../../campaigns/campaignBuilder/utils/getContactName";
import { updateConversations, updateLiveConversation } from "./chatFunctions";

export const manageNotification = (
  notification,
  selectedConversation,
  setNotAssignedTickets,
  setConversations,
  setLiveConversation,
  chatFormattedData,
  instance,
  setSelectedConversation,
  setSelectedContact,
  notificationApi,
  contactos // Corregir uso de contactos paginado
) => {
  const { notification: data } = notification;

  if (!data) {
    return;
  }

  switch (data?.content) {
    case "newChat":
      if (data.payload?._id) {
        if (!data.payload.userId) {
          setNotAssignedTickets((prevState) => {
            let array = [...prevState];

            array.unshift({
              ...data.payload,
              conversation: data.payload?.conversation || [],
            });

            return array;
          });
        } else {
          // chat
          setConversations((prevState) => {
            let array = [...prevState];

            const contacto = contactos?.find(
              (contacto) =>
                contacto.phones?.[0]?.phone === data.payload.phoneNumber
            );

            let contactName = getContactName(contacto?.name);

            const obj = {
              ...data.payload,
              name: contactName || "",
              conversation: data.payload.conversation || [],
            };

            array.unshift(obj);

            return array;
          });
        }
      }
      break;
    case "reassign":
      if (
        data.payload?.teamId ||
        data.payload?.userId === instance?.userData?.userId
      ) {
        console.log(data.payload);
        setConversations((prevState) => {
          let array = [...prevState];
          array = array.filter((element) => element._id !== data.payload._id);
          array.unshift(data.payload);
          return array;
        });

        if (data.payload?.userId === instance?.userData?.userId) {
          openNotification(notificationApi, "info", "Te asignaron un ticket");
        }
      }
      break;
    case "closed":
      if (data.payload?.chatId) {
        const { chatId } = data.payload;

        setConversations((prevState) => {
          let array = prevState.map((chat) => {
            if (chat._id === chatId) {
              // va a actualizar tab contactos y mis conversaciones
              return {
                ...chat,
                status: "CLOSED",
                closeDate: Date.now(),
                teamId: null,
                userId: null,
              };
            }

            return chat;
          });

          return array;
        });

        if (chatId === selectedConversation?._id) {
          setSelectedConversation(null);
          setSelectedContact(null);
        }
      }

      break;
    case "note":
      //! Dibujar nota;
      if (selectedConversation?._id === data.payload?.chatId) {
        updateLiveConversation(
          data.payload,
          setLiveConversation,
          chatFormattedData?.messages
        );
      }

      updateConversations(
        data.payload,
        setConversations,
        setNotAssignedTickets
      );

      break;
    default:
      break;
  }
};
