import axios from "axios";
import { META_API_VERSION } from "./fetchApplication";

export const newCatalogInstance = async (key, id, messageApi) => {
  try {
    let data = JSON.stringify({
      catalogId: id,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/catalog/newCatalogInstance`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    messageApi.error("Ocurrió un error al asociar un catálogo");
  }
};

export const getCatalogInstance = async (key, messageApi) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/catalog/getCatalogInstance`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
    messageApi.error("Ocurrió un error al obtener catálogos");
  }
};

export const handleCatalogInstance = (
  key,
  setSelectedCatalog,
  setStage,
  messageApi,
  setLoading
) => {
  if (key) {
    setLoading(true);
    getCatalogInstance(key, messageApi)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data?.catalog?.id) {
            setSelectedCatalog(res.data);
            setStage(1);
          } else {
            messageApi.info("No existe catálogo asociado");
            setStage(0);
          }
        } else {
          setStage(0);
          messageApi.error("Ocurrió un error al obtener el catálogo");
        }
      })
      .catch((error) => {
        console.log(error);
        setStage(0);
        messageApi.error("Ocurrió un error al obtener el catálogo");
      })
      .finally(() => setLoading(false));
  }
};

export const getCatalogDetail = async (key) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/catalog/getCatalogDetail`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCatalogInstance = async (key, messageApi) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/catalog/deleteCatalogInstance`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    messageApi.error("Ocurrió un error al desactivar un catálogo");
  }
};

export const updateCatalogStatus = async (token, phoneNumberId, status) => {
  try {
    let config = {
      method: "post",
      url: `https://graph.facebook.com/${META_API_VERSION}/${phoneNumberId}/whatsapp_commerce_settings?is_cart_enabled=${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCatalogVisibility = async (token, phoneNumberId, status) => {
  try {
    let config = {
      method: "post",
      url: `https://graph.facebook.com/${META_API_VERSION}/${phoneNumberId}/whatsapp_commerce_settings?is_catalog_visible=${status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const catalogSettings = async (token, phoneNumberId) => {
  try {
    let config = {
      method: "get",
      url: `https://graph.facebook.com/${META_API_VERSION}/${phoneNumberId}/whatsapp_commerce_settings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getLogsSincronizacion = async (token, listId) => {
  try {
    let config = {
      method: "get",
      url: `https://graph.facebook.com/${META_API_VERSION}/${listId}/uploads?limit=1`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getLogsSincronizacionError = async (token, syncId) => {
  try {
    let config = {
      method: "get",
      url: `https://graph.facebook.com/${META_API_VERSION}/${syncId}/errors`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const sendProductsFileData = async (key, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/confirmFile`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateListItems = async (key, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/products/updateListItems`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getListsNew = async (key) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/catalog/getListsNew`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (key, id) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/products/deleteProduct/${id}`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updatePrices = async (key, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/products/updatePrice`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateProductsAndServicesStatus = async (key) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/apps/updateProductsAndService`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
