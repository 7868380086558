import "mapbox-gl/dist/mapbox-gl.css";
import { Map, Marker } from "react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_APIKEY;

export const MessageLocation = ({
  marker,
  viewport,
}) => {
  return (
    <Map
      style={{ borderRadius: 8 }}
      width="100%"
      height="100%"
      mapboxAccessToken={MAPBOX_TOKEN}
      {...viewport}
      mapStyle="mapbox://styles/mapbox/streets-v12"
    >
      {marker && (
        <Marker {...marker}>
          <FaMapMarkerAlt
            fill="var(--primary-color)"
            colorInterpolation="red"
            strokeWidth={5}
            stroke="#1d2c39"
            size={30}
          />
        </Marker>
      )}
    </Map>
  );
};
