import { CampaignLogs } from "../ui/Layout/campaigns/campaignLogs/CampaignLogs";
import MainLayout from "../ui/Layout/MainLayout";

export const CampaignsLogsView = () => {
  return (
    <MainLayout>
      <CampaignLogs />
    </MainLayout>
  );
};
