import { Clientes } from "./Clientes";
import { Content1 } from "./Content1";
import { Content2 } from "./Content2";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./landing-page.css";
import { SabiasQue } from "./SabiasQue";

export const LandingPage = () => {

  
  return (
    <div className="landing-page-wrapper">
      <Header />
      {/* comunicacion inteligente y bidireccional */}
      <Content1 />
      {/* guia a tus clientes a interacciones automáticas */}
      <Content2 />
      {/* se parte de un nuevo mundo */}
      <Clientes />
      {/* sabias que automatizando... */}
      <SabiasQue />
      {/* escríbenos / habla con un representante */}
      <Footer />
    </div>
  );
};
