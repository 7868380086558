import { Button, Form, Input, Radio, Select } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { BsSoundwave } from 'react-icons/bs';
import { updateNode } from '../utils/updateNode';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { HeaderForm } from './componentsForm/HeaderForm';

export const TranscribeAudioForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    flowVariables,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [nombreNodo, setNombreNodo] = useState('');

  const [service, setService] = useState('google');

  const [modelType, setModelType] = useState('command_and_search');

  const [variableSeleccionada, setVariableSeleccionada] = useState('');
  const [transcribeName, setTranscribeName] = useState('001');

  const options = useMemo(() => {
    if (flowVariables?.length > 0) {
      let array = flowVariables?.filter((variable) =>
        ['audio', 'tts'].includes(variable.type)
      );

      return array.map((variable) => {
        return {
          value: variable.name,
          label: variable.name
        };
      });
    } else {
      return [];
    }
  }, [flowVariables]);

  const onVariableChange = (text) => {
    setVariableSeleccionada(text);

    let variableText;
    if (text) {
      variableText = text.substring(text.indexOf('_') + 1);
    } else {
      variableText = '';
    }

    setTranscribeName(variableText);
  };

  const onFinish = (values) => {
    // Unica opcion que quedó
    values.service = 'openai';
    values.modelType = 'whisper-1';
    values.label = nombreNodo;

    if (!variableSeleccionada) {
      messageApi.error('Debe seleccionar una variable de audio');
      return;
    }

    values.variableAudio = variableSeleccionada;

    let variableText;

    if (!transcribeName) {
      variableText = `transcribe_001`;
    } else {
      variableText = `transcribe_${transcribeName}`;
    }
    values.variableText = variableText;

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      {
        name: variableText,
        type: 'string'
      },
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      setNombreNodo(nodoSeleccionado.data.label);

      let data = nodoSeleccionado.data;

      if (data.service) {
        setService(data.service);
      }

      if (data.modelType) {
        setModelType(data.modelType);
      }

      let variableText;
      if (data.variableText) {
        let variable = data.variableText;
        variableText = variable.substring(variable.indexOf('_') + 1);
      } else {
        variableText = '';
      }

      variableText && setTranscribeName(variableText);

      if (data.variableAudio) {
        setVariableSeleccionada(data.variableAudio);
      }
    }
  }, [nodoSeleccionado]);

  const handleModelOptions = () => {
    // if (service === "google") {
    //   return [
    //     { value: "command_and_search", label: "Command & search" },
    //     { value: "default", label: "Default" },
    //   ];
    // } else {
    return [{ value: 'whisper-1', label: 'Whisper' }];
    // }
  };

  // const handleServiceChange = (v) => {
  //   setService(v);
  //   if (v === "google") {
  //     setModelType("command_and_search");
  //   } else {
  //     setModelType("whisper-1");
  //   }
  // };

  return (
    <Form
      form={form}
      layout='vertical'
      name='formTranscribe'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<BsSoundwave style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Form.Item
        label='Seleccionar variable'
        rules={[{ required: true, message: 'Campo requerido' }]}
      >
        <Select
          options={options}
          onChange={(v) => {
            onVariableChange(v);
          }}
          value={variableSeleccionada}
          showSearch
          filterOption={(input, option) =>
            (option.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      {/* <Form.Item label="Servicio">
        <Radio.Group
          onChange={(v) => {
            handleServiceChange(v.target.value);
          }}
          className="radio-media"
          style={{
            width: "100%",
          }}
          value={service}
          options={[
            { label: "Google", value: "google" },
            { label: "OpenAI", value: "openai" },
          ]}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item> */}
      <Form.Item label='Modelo'>
        <Radio.Group
          onChange={(v) => {
            setModelType(v.target.value);
          }}
          className='radio-media'
          style={{
            width: '100%'
          }}
          value={modelType}
          options={handleModelOptions()}
          optionType='button'
          buttonStyle='solid'
        />
      </Form.Item>
      <Form.Item label='Nombre de la variable texto'>
        <Input
          addonBefore='transcribe_'
          value={transcribeName}
          disabled={true}
        />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
