import { NODE_TYPES } from "../nodeTypes";

export const getNodeName = (node) => {
  if (node?.type) {
    const objNode = NODE_TYPES.find((element) => element.id === node.type);

    if (objNode?.name) {
      return objNode.name;
    }
  }

  return "";
};
