import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { isSelectedClass } from "../../../../../../helpers/isSelected";
import { FaRegFilePowerpoint } from "react-icons/fa6";
import { useContext, useEffect, useState } from "react";
import { SendTemplateContext } from "../../../../../context/SendTemplateContext";
import { PreviewTemplate } from "../../../settings/content/templates/PreviewTemplate";
import { formatoTextHtml } from "../../../../../../helpers/utilidadesTemplates";
// import { Statistic } from "antd";
// import { getPorcentaje, innerStatsProps } from "../../utils";

export const TemplateNode = (node) => {
  const { setNodoSeleccionado, setMostrarDrawer, edges, nodes } =
    useContext(SendTemplateContext);

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  const updateNodeInternals = useUpdateNodeInternals();

  const [buttonsContent, setButtonsContent] = useState([]);

  useEffect(() => {
    // NECESARIO PARA QUE RECONOZCA LOS HANDLES CONDICIONALES.
    updateNodeInternals(node.id);
  }, [node]);

  useEffect(() => {
    if (node.data.templateVariables?.buttons?.value) {
      setButtonsContent(node.data.templateVariables?.buttons?.value);
    }
  }, [node]);

  // const resumen = useMemo(() => {
  //   if (metricsData?.metrics) {
  //     return metricsData.metrics.reduce(
  //       (total, envio) => {
  //         return {
  //           sent: (total.sent += envio?.sent || 0),
  //           delivered: (total.delivered += envio?.delivered || 0),
  //           read: (total.read += envio?.read || 0),
  //           contacts: total.contacts,
  //         };
  //       },
  //       {
  //         sent: 0,
  //         delivered: 0,
  //         read: 0,
  //         contacts: node.data?.contactsCount || 0,
  //       }
  //     );
  //   } else {
  //     return { sent: 0, delivered: 0, read: 0, contacts: 0 };
  //   }
  // }, [metricsData, nodes]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => handleClick()}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header-send-template">
        <div>
          <FaRegFilePowerpoint
            //   fill=""
            size={32}
          />
        </div>
        <div className="node-description">
          <span className="node-type">Seleccionar</span>
          <span className="mensaje-nodo">Plantilla</span>
        </div>
      </div>
      {/* {metricsData?.metrics && (
        <span
          className="horizontal"
          style={{ margin: "8px 0px", gap: 0, width: "100%", minWidth: 280 }}
        >
          <span className="columna-estadisticas-inner">
            <Statistic
              title="Enviados"
              value={getPorcentaje("sent", resumen, "inner")}
              {...innerStatsProps}
            />
          </span>
          <span className="columna-estadisticas-inner">
            <Statistic
              title="Entregados"
              value={getPorcentaje("delivered", resumen, "inner")}
              {...innerStatsProps}
            />
          </span>
          <span
            className="columna-estadisticas-inner"
            style={{ borderRight: "none" }}
          >
            <Statistic
              title="Leídos"
              value={getPorcentaje("read", resumen, "inner")}
              {...innerStatsProps}
            />
          </span>
        </span>
      )} */}
      {node.data.templateVariables && (
        <span style={{ marginTop: 8 }}>
          <PreviewTemplate
            ambito="sendTemplate"
            // inputText={inputText}
            bodyContent={formatoTextHtml(
              node.data.templateVariables.body?.value
            )}
            buttonsContent={buttonsContent || []}
            footerContent={node.data.templateVariables.footer?.value}
            headerContent={node.data.templateVariables.header?.value}
            headerType={node.data.templateVariables?.header?.type}
            renderTemplateButtons={true}
            interactions={null}
            edges={edges}
            node={node}
          />
        </span>
      )}
    </div>
  );
};
