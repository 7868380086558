import { Basic } from "./Basic";
import { BasicAuth } from "./BasicAuth";
import { BearerToken } from "./BearerToken";

export const handleAuthType = (type, setAuthData, authData, nodeId) => {
  switch (type) {
    case "No auth":
      return <></>;
    case "Bearer":
      return (
        <BearerToken
          setAuthData={setAuthData}
          authData={authData}
          nodeId={nodeId}
        />
      );
    case "Basic auth":
      return (
        <BasicAuth
          setAuthData={setAuthData}
          authData={authData}
          nodeId={nodeId}
        />
      );
    case "Basic":
      return (
        <Basic setAuthData={setAuthData} authData={authData} nodeId={nodeId} />
      );
    default:
      return <></>;
  }
};
