const SIZES = ['Bytes', 'KB', 'MB', 'GB'];

export const formatFileSize = (size) => {
  // El tamaño de entrada debe estar en bytes.

  if (size === 0) {
    return '0 Bytes';
  }

  const index = Math.floor(Math.log(size) / Math.log(1000));
  const value = size / Math.pow(1000, index);

  // Si el valor es entero devuelve un número redondo:
  const formattedValue = Number.isInteger(value) ? value : value.toFixed(2);

  return `${formattedValue} ${SIZES[index]}`;
};
