import { useContext, useEffect, useMemo, useState } from "react";
import {
  FiCopy,
  FiFile,
  FiImage,
  FiMapPin,
  FiMusic,
  FiPlayCircle,
} from "react-icons/fi";
import { HiPhone, HiOutlineExternalLink } from "react-icons/hi";
import { IoArrowUndoSharp } from "react-icons/io5";
import { TemplateContext } from "../../../../../context/TemplateContext";
import dayjs from "dayjs";
import { BiListUl } from "react-icons/bi";
import { Handle, Position } from "reactflow";
import { checkValidConnection } from "../../../flows/utils/utilidadesFlow";
import { Image } from "antd";
import { isInterpolate } from "../../../flows/utils/httpRequest";

export const PreviewTemplate = ({
  ambito,
  bodyContent,
  buttonsContent,
  footerContent,
  headerContent,
  headerType,
  inputText,
  setShowAll,
  renderTemplateButtons = null,
  interactions,
  edges,
  node,
}) => {
  const [showButtons, setShowButtons] = useState(false);
  const [validateHTML, setValidateHTML] = useState(null);

  const { preview } = useContext(TemplateContext);

  const formattedHeaderType = useMemo(() => {
    // Decide si hay header o no y si es text o media
    if (!headerType || headerType?.toLowerCase() === "none") {
      return null;
    } else {
      return headerType?.toLowerCase() === "text" ? "text" : "media";
    }
  }, [headerType]);

  const formattedHeaderMediaType = useMemo(() => {
    // Si hay header
    if (!headerType) {
      return null;
    } else {
      return headerType?.toLowerCase() || "text";
    }
  }, [headerType]);

  const handleOnChangeHeader = () => {
    switch (formattedHeaderMediaType?.toLowerCase()) {
      case "image":
        if (
          node?.data?.templateVariables?.header?.value?.link &&
          !isInterpolate(node?.data?.templateVariables?.header?.value?.link)
        ) {
          return (
            <Image
              src={node?.data?.templateVariables?.header?.value?.link}
              style={{ width: "100%", objectFit: "cover" }}
            />
          );
        }
        return <FiImage color="white" size={100} />;
      case "video":
        return <FiPlayCircle color="white" size={100} />;
      case "document":
        return <FiFile color="white" size={100} />;
      case "audio":
        return <FiMusic color="white" size={100} />;
      case "location":
        return <FiMapPin color="white" size={100} />;
      default:
        return <></>;
    }
  };

  const renderButtons = () => {
    const SIZE = 14;
    const switchType = (boton, index) => {
      switch (boton.type) {
        case "SHOW_ALL":
          return (
            <span
              className="linea-horizontal-call pointer"
              key={index}
              onClick={() => setShowAll(true)}
            >
              <BiListUl size={SIZE} className="call" />
              <p className="help-link call">{boton.text}</p>
            </span>
          );
        case "PHONE_NUMBER":
          return (
            <span className="linea-horizontal-call" key={index}>
              <HiPhone size={SIZE} className="call" />
              <span className="help-link call">
                <p>{boton.text}</p>
              </span>
            </span>
          );
        case "URL":
          return (
            <span className="linea-horizontal-call" key={index}>
              <HiOutlineExternalLink size={SIZE} className="call" />
              <span className="help-link call">
                <p>{boton.text}</p>
              </span>
            </span>
          );
        case "COPY_CODE":
          return (
            <span className="linea-horizontal-call" key={index}>
              <FiCopy size={SIZE} className="call" />
              <span className="help-link call">
                <p>{boton.text}</p>
              </span>
            </span>
          );
        default:
          return (
            <span className="linea-horizontal-call" key={index}>
              <IoArrowUndoSharp size={SIZE} className="call" />
              <p className="help-link call">{boton.text}</p>
            </span>
          );
      }
    };

    let array = [...buttonsContent];

    if (array.length > 3) {
      array = array.splice(0, 2);

      array.push({ type: "SHOW_ALL", text: "Mostrar todos" });
    }

    return (
      <div className="layout-botones">
        {array?.map((boton, index) => {
          return switchType(boton, index);
        })}
      </div>
    );
  };

  const renderClickRate = (text, interacciones) => {
    if (interacciones[text]) {
      const porcentaje = `(${(
        (interacciones[text] / (interactions.contacts || 0)) *
        100
      ).toLocaleString("de-DE", { maximumFractionDigits: 2 })}%)`;
      return <p className="button-click-rate">{porcentaje}</p>;
    }
  };

  const renderButtonsCampaign = () => {
    let obj = {};

    interactions?.interactions?.forEach((element) => {
      obj[element.button] = element.interactions.length;
    });

    const SIZE = 14;
    const switchType = (boton, index) => {
      switch (boton.type) {
        case "PHONE_NUMBER":
          return (
            <span className="linea-horizontal-call" key={index}>
              <HiPhone size={SIZE} className="call" />
              <span className="help-link call">
                <p>{boton.text}</p>
                {renderClickRate(boton.text, obj)}
              </span>
            </span>
          );
        case "URL":
          return (
            <span className="linea-horizontal-call" key={index}>
              <HiOutlineExternalLink size={SIZE} className="call" />
              <span className="help-link call">
                <p>{boton.text}</p>
                {renderClickRate(boton.text, obj)}
              </span>
            </span>
          );
        case "COPY_CODE":
          return (
            <span className="linea-horizontal-call" key={index}>
              <FiCopy size={SIZE} className="call" />
              <span
                className="help-link call"
                // href={boton.link}
                type="tel"
              >
                <p>{boton.text}</p>
                {renderClickRate(boton.text, obj)}
              </span>
            </span>
          );
        default:
          return (
            <span
              className="linea-horizontal-call"
              key={index}
              style={{ position: "relative" }}
            >
              <IoArrowUndoSharp size={SIZE} className="call" />
              <p className="help-link call">{boton.text}</p>
              {renderClickRate(boton.text, obj)}
              {node.data.showHandles && (
                <Handle
                  type="source"
                  className="custom-handle trigger-handle"
                  style={{ right: "-15px" }}
                  id={index.toString()}
                  position={Position.Right}
                  isConnectable={checkValidConnection(
                    edges,
                    node.id,
                    `${index}`
                  )}
                />
              )}
            </span>
          );
      }
    };

    let array = [...buttonsContent];

    return (
      <div className="layout-botones">
        {array?.map((boton, index) => {
          return switchType(boton, index);
        })}
      </div>
    );
  };

  useEffect(() => {
    if (buttonsContent) {
      if (buttonsContent.length > 0) {
        setShowButtons(false);
        buttonsContent.forEach((element) => {
          if (element !== "") {
            setShowButtons(true);
          }
        });
      } else {
        setShowButtons(false);
      }
    }
  }, [buttonsContent]);

  useEffect(() => {
    const regex = /<\/?[^>]+(>|$)/g;
    if (inputText) {
      setValidateHTML(inputText.match(regex));
    } else {
      setValidateHTML(null);
    }
  }, [inputText]);

  useEffect(() => {
    if (ambito && (bodyContent || bodyContent === "")) {
      let divBodyContent = document.getElementById(ambito);
      if (divBodyContent) {
        if (validateHTML === null) {
          divBodyContent.innerHTML = bodyContent.split("\n").join("<br>");
        } else {
          divBodyContent.innerText = bodyContent.split("\n").join("<br>");
        }
      }
    }
  }, [bodyContent, validateHTML, ambito]);

  return (
    <div
      className={!ambito?.includes("node") ? "bubble-place" : ""}
      style={ambito === "drawer" ? { width: "100%" } : {}}
    >
      <div
        // className={showButtons ? "bubble fit-wrapper" : "bubble"}
        className="bubble"
        style={
          ambito === "sendTemplate"
            ? { border: "1px solid #f0f0f0" }
            : ambito === "drawer"
            ? { width: "100%" }
            : {}
        }
      >
        {formattedHeaderType === "media" && !preview && (
          <span className="media-header-wrapper">{handleOnChangeHeader()}</span>
        )}
        {formattedHeaderMediaType === "image" && preview && (
          <img
            className="image-preview"
            src={preview?.data}
            alt="preview"
            width="calc(100%)"
            height={preview?.data ? "auto" : "200px"}
          />
        )}
        {formattedHeaderMediaType === "document" && preview && (
          <iframe
            className="image-preview"
            title="pdf-preview"
            src={`${preview.data}#page=1`}
            width="calc(100%)"
            height="200px"
          />
        )}
        {formattedHeaderMediaType === "video" && preview && (
          <video
            controls
            className="image-preview"
            src={preview.data}
            width="calc(100%)"
            height="200px"
          />
        )}
        <div className="txt">
          {formattedHeaderType === "text" && (
            <p className="name">{headerContent && headerContent}</p>
          )}
          <p className="message" id={ambito}></p>
          <p className="footer">{footerContent !== "" && footerContent}</p>
        </div>
        {!ambito?.startsWith("chat") && (
          <span className="timestamp-wrapper">
            <span className="timestamp">{dayjs().format("hh:mm a")}</span>
          </span>
        )}
        {/* <div className="bubble-arrow"></div> */}
        {showButtons && !renderTemplateButtons && renderButtons()}
        {renderTemplateButtons && renderButtonsCampaign()}
      </div>
    </div>
  );
};
