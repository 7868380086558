import { useContext, useMemo } from "react";
import { renderAgent } from "./utils/renderAgent";
import { GlobalContext } from "../../../context/GlobalContext";
import { renderTeam } from "./utils/renderTeam";

export const ContactsChat = ({
  contacto,
  selectedContact,
  setSelectedContact,
  teams,
}) => {
  const { usuarios } = useContext(GlobalContext);

  const usuario = useMemo(() => {
    if (contacto?.chats?.userId) {
      return renderAgent(usuarios, contacto?.chats?.userId);
    }

    return null;
  }, [usuarios, contacto]);

  const team = useMemo(() => {
    if (contacto?.chats?.teamId) {
      return renderTeam(teams, contacto.chats.teamId);
    }

    return null;
  }, [teams, contacto]);

  return (
    <span
      className="chat-contact-wrapper fade-in"
      style={
        selectedContact?.phoneNumber === contacto?.phoneNumber
          ? {
              border: "1px solid var(--primary-color)",
            }
          : {}
      }
      onClick={() => setSelectedContact({ ...contacto })}
    >
      <span className="columna" style={{ gap: "6px", flex: 1 }}>
        <span className="horizontal-between">
          <p className="chat-contact-name">{contacto.name}</p>
        </span>
        <span className="horizontal gap8">
          {usuario}
          {team}
        </span>
      </span>
    </span>
  );
};
