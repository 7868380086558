// Se utiliza para probar endpoints desde el cliente.
export const addUrlParams = (url, urlParams) => {
  try {
    const newUrl = new URL(url);
    
    
    urlParams?.forEach((element) => {
      let value = element.value;
      if (element.type === "var") {
        value = element.testValue || "";
      }
      // Checkeo si existe tal parametro
      if (newUrl.searchParams.has(element.key)) {
        // Si existe lo actualizo
        newUrl.searchParams.set(element.key, value);
      } else {
        // Si no existe lo agrego
        newUrl.searchParams.append(element.key, value);
      }
    });

    return newUrl.toString();
  } catch (error) {
    console.log("error", error);
  }
};
