import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, Select, Switch } from 'antd';
import { updateNode } from '../utils/updateNode';
import { FaLink } from 'react-icons/fa6';
import { getEnviosMavisos } from '../../../../../helpers/fetchData/fetchEnviosMasivos';
import { HeaderForm } from './componentsForm/HeaderForm';

export const SubscriptionForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [envios, setEnvios] = useState([]);
  const [selectedEnvio, setSelectedEnvio] = useState(null);
  const [addSubscription, setAddSubscription] = useState(true);
  const [loading, setLoading] = useState(true);

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (!selectedEnvio) {
      messageApi.info('Es necesario que seleccione un envío masivo');
      return;
    }

    values.idEnvioMasivo = selectedEnvio;
    values.addSubscription = addSubscription;

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { label, tag, addTag } = nodoSeleccionado?.data;
      setNombreNodo(label || '');

      if (tag) {
        setSelectedEnvio(tag);
      }

      if (addTag === false) {
        setAddSubscription(false);
      }
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getEnviosMavisos(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            const array =
              res.data.map((envio) => {
                return {
                  label: envio.name,
                  value: envio._id
                };
              }) || [];

            setEnvios(array);
            return;
          }

          messageApi.error('Ocurrió un error al obtener envíos');
        })
        .catch((error) =>
          messageApi.error('Ocurrió un error al obtener envíos')
        )
        .finally(() => setLoading(false));
    }
  }, [instance]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { idEnvioMasivo, addSubscription } = nodoSeleccionado.data;

      if (idEnvioMasivo) {
        setSelectedEnvio(idEnvioMasivo);
      }

      if (addSubscription === false) {
        setAddSubscription(false);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='form-formula'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<FaLink style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Switch
        checked={addSubscription}
        checkedChildren='Agregar suscripción'
        unCheckedChildren='Quitar suscripción'
        onChange={(v) => setAddSubscription(v)}
        style={{ width: 'fit-content', margin: '16px 0px' }}
      />
      <Form.Item label='Envío masivo'>
        <Select
          options={envios || []}
          value={loading ? null : selectedEnvio || null}
          onChange={(v) => setSelectedEnvio(v)}
          loading={loading}
          placeholder='Seleccione un envío masivo'
        />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
