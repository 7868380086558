import { useState } from "react";
import "../catalog/budgetConfigSteps/budgetConfig.css";
import { ClientsLoadGet } from "./ClientsLoadGet";
import { ClientsLoadGetForm } from "./ClientsLoadGetForm";
import { ClientsLoadCron } from "./ClientsLoadCron";
import { ClientsLoadExcel } from "./ClientsLoadExcel";

const INITIAL_CONFIG = {
  clientsLoad: {},
};

export const DrawerConfigClient = ({ clientsConfig, setClientsConfig, closeModal, setMostrarRequestDrawer, stage, setStage, chkCron, setChkCron }) => {
  const [tmpConfig, setTmpConfig] = useState(clientsConfig || INITIAL_CONFIG);
  const [result, setResult] = useState(null);

  const handleStage = (number) => {
    setStage(number);
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return (
          <ClientsLoadGet
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            setMostrarRequestDrawer={setMostrarRequestDrawer}
          />
        );
      case 1:
        return (
          <ClientsLoadGetForm
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            closeModal={closeModal}
            setClientsConfig={setClientsConfig}
          />
        );
        case 2:
          return (
            <ClientsLoadCron
              handleStage={handleStage}
              tmpConfig={tmpConfig}
              setTmpConfig={setTmpConfig}
              result={result}
              setResult={setResult}
              setClientsConfig={setClientsConfig}
              closeModal={closeModal}
              setMostrarRequestDrawer={setMostrarRequestDrawer}
              chkCron={chkCron} 
              setChkCron={setChkCron}
            />
          );
          //Excel method:
          case 3:
          return (
            <ClientsLoadExcel
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            setMostrarRequestDrawer={setMostrarRequestDrawer}
            setClientsConfig={setClientsConfig}
          />
          );
      default:
        return <></>;
    }
  };

  return <div style={{ height: "100%" }}>{renderStage()}</div>;
};