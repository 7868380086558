import { Button, Drawer, Form, Input, Select, Spin } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { FiArchive } from "react-icons/fi";
import { FlowContext } from "../../../../context/FlowContext";
import { getTemplates } from "../../../../../helpers/getTemplates";
import { LoadingOutlined } from "@ant-design/icons";
import { handleVariablesDisponibles } from "../utils/handleVariablesDisponibles";
import { GlobalContext } from "../../../../context/GlobalContext";
import { updateNode } from "../utils/updateNode";
import { MediaForm } from "./MediaForm";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { getVariableName } from "../utils/getVariableName";
import { CONTEXT_VARIABLES } from "../utils/contextVariables";
import { HeaderForm } from "./componentsForm/HeaderForm";
import {
  getButtonVars,
  getVariablesCount,
  handleTemplateVariables,
  SECTIONS,
} from "../../../template-form/utils";
import { TemplateHeader } from "../../../template-form/TemplateHeader";
import { TemplateSelector } from "../../../template-form/TemplateSelector";
import { VariableTypes } from "../../../template-form/VariableTypes";
import { TemplateBody } from "../../../template-form/TemplateBody";
import { TemplateFooter } from "../../../template-form/TemplateFooter";
import { TemplateButtons } from "../../../template-form/TemplateButtons";

export const SendTemplateForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    flowVariables,
    edges,
    setNodes,
    setFlowVariables,
    setEdges,
  } = useContext(FlowContext);

  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(true);

  const [nombreNodo, setNombreNodo] = useState("");
  const [form] = Form.useForm();
  const [templateSeleccionado, setTemplateSeleccionado] = useState({});
  const [templateVariables, setTemplateVariables] = useState({});
  const [variable, setVariable] = useState({ visible: false, value: "" });
  const [url, setURL] = useState("");

  const [varsType, setVarsType] = useState("none");
  const [xlsData, setXlsData] = useState([]);
  const [xlsColumn, setXlsColumn] = useState(null);
  const [matchClientXls, setMatchClientXls] = useState(false);

  const [buttonsVars, setButtonsVars] = useState([]);

  const [headerMediaType, setHeaderMediaType] = useState("image");

  const [marker, setMarker] = useState(null);

  const [viewport, setViewport] = useState({
    longitude: -63.244947,
    latitude: -32.410553,
    zoom: 15,
  });

  const getTemplatesOptions = (data) => {
    if (data) {
      let filtroFlows = data.filter(
        (flow) => !flow.name.toLowerCase().startsWith("sample")
      );
      filtroFlows = filtroFlows.map((template) => {
        return { value: template.id, label: template.name };
      });

      return filtroFlows;
    }

    return [];
  };

  // const getVariablesCount = (text, section, type) => {
  //   const regex = /\{\{(\d+)\}\}/g;

  //   if (section === "BUTTONS") {
  //     setVariable({ visible: true, value: "" });
  //     setTemplateVariables((prevState) => {
  //       return {
  //         ...prevState,
  //         [section.toLowerCase()]: {
  //           value: text,
  //           type: type,
  //         },
  //       };
  //     });
  //   } else {
  //     const variables = text.match(regex) || [];
  //     if (text === "HEADER") {
  //       setHeaderMediaType(section?.toLowerCase());
  //     }

  //     let array = [];

  //     if (nodoSeleccionado.data.templateVariables) {
  //       array = variables.map((_, index) => {
  //         if (
  //           nodoSeleccionado?.data?.templateVariables?.[section?.toLowerCase()]
  //             ?.vars[index] &&
  //           nodoSeleccionado.data?.template.id === templateSeleccionado.id
  //         ) {
  //           return nodoSeleccionado?.data?.templateVariables[
  //             section?.toLowerCase()
  //           ]?.vars[index];
  //         }
  //         return "";
  //       });
  //     }

  //     setTemplateVariables((prevState) => {
  //       return {
  //         ...prevState,
  //         [section.toLowerCase()]: {
  //           value: text,
  //           type: type,
  //           placeholder: variables,
  //           vars: array,
  //         },
  //       };
  //     });
  //   }
  // };

  //! COPIAR ESTRUCTURA LOCATION

  const varOptions = useMemo(() => {
    let array = [];
    if (flowVariables) {
      let vars = handleVariablesDisponibles(
        flowVariables,
        nodoSeleccionado.id,
        true,
        edges
      );

      CONTEXT_VARIABLES.forEach((element) => {
        array.push({ value: element.name, label: element.name });
      });

      vars?.regular?.forEach((variable) => {
        array.push({ value: variable.name, label: variable.name });
      });
    }

    return array;
  }, [flowVariables]);

  const handleOptionsVars = (value, section, index) => {
    setTemplateVariables((prevState) => {
      let secciones = { ...prevState };
      secciones[section].vars[index] = value;

      return secciones;
    });
  };

  const onFinish = (values) => {
    if (!templateSeleccionado.name) {
      return messageApi.info("Debe seleccionar un template");
    }

    let error = false;
    SECTIONS.forEach((section) => {
      if (templateVariables[section.toLowerCase()]?.vars?.length > 0) {
        if (
          templateVariables[section.toLowerCase()].vars.some(
            (element) => !element
          )
        ) {
          error = true;
          messageApi.error("Debe configurar todas las variables");
        }
      }
    });

    if (templateVariables.header) {
      if (templateVariables.header?.type?.toUpperCase() !== "TEXT") {
        setTemplateVariables((prevState) => {
          let obj = { ...prevState };

          prevState.header.value = { link: url };
          return obj;
        });
      }
    }

    if (templateVariables.header?.type === "LOCATION") {
      if (!marker || marker?.longitude == null || marker?.latitude == null) {
        messageApi.info(
          "Seleccione un punto en el mapa o ingrese al menos latitud y longitud"
        );
        return;
      } else {
        setTemplateVariables((prevState) => {
          let obj = { ...prevState };

          obj.header.value = {
            ...marker,
            address: values.address || "",
            name: values.locationName || "",
          };

          return obj;
        });
      }
    }

    if (templateVariables.buttons) {
      if (!values.variableName) {
        return messageApi.error("Ingrese un nombre para la variable");
      }
      let variableName = getVariableName(values.variableName, "template");
      values.variableName = variableName;

      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        { name: variableName, type: "template" },
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;
    }

    if (!error) {
      values.label = nombreNodo;
      values.templateVariables = templateVariables;
      values.template = templateSeleccionado;

      setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
      setMostrarDrawer(false);
    }
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      if (data.template?.id) {
        setTemplateSeleccionado(data.template);
      }

      if (data.templateVariables) {
        const clone = structuredClone(data.templateVariables);
        setTemplateVariables(clone);

        if (data.templateVariables?.header?.type === "LOCATION") {
          const { value } = templateVariables.header;

          setViewport((prevState) => {
            return { ...prevState, ...value };
          });
          setMarker({
            latitude: value?.latitude || 0,
            longitude: value?.longitude || 0,
          });
          form.setFieldsValue({
            address: value?.address || "",
            locationName: value?.name || "",
          });
        }

        if (data.templateVariables.header?.type?.toLowerCase() !== "text") {
          if (data.templateVariables.header.value?.link) {
            setURL(data.templateVariables.header.value.link);
          }
        }
      }

      let variableName;
      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      } else {
        variableName = "";
      }

      form.setFieldsValue({
        variableName: variableName,
      });
    }
  }, [nodoSeleccionado, form]);

  useEffect(() => {
    const prevData = nodoSeleccionado.data;

    handleTemplateVariables(
      templateSeleccionado,
      setHeaderMediaType,
      buttonsVars,
      setButtonsVars,
      prevData,
      setTemplateVariables
    );
  }, [templateSeleccionado, nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTemplates(true);
      getTemplates(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            setTemplates(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener plantillas");
          }
        })
        .catch((err) => {
          messageApi.error("Ocurrió un error al obtener plantillas");
        })
        .finally(() => setLoadingTemplates(false));
    }
  }, [instance]);

  const templateOptions = useMemo(() => {
    if (templates?.length < 1) {
      return [];
    }

    return getTemplatesOptions(templates);
  }, [templates]);

  const xlsOptions = useMemo(() => {
    if (varsType !== "xls") {
      return [];
    }

    if (!xlsData || xlsData.length < 1) {
      return [];
    }

    let options = Object.keys(xlsData[0])?.map((element) => {
      return {
        value: element,
        label: element,
      };
    });

    return options;
  }, [xlsData, varsType]);

  if (loadingTemplates) {
    return (
      <span style={{ display: "flex", justifyContent: "center" }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
                marginTop: 100,
                marginInline: "auto",
              }}
              spin
            />
          }
        />
      </span>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="sendTemplateForm"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<FiArchive style={{ color: "var(--dark-color)" }} size={32} />}
      />

      <TemplateSelector
        templateOptions={templateOptions}
        templates={templates}
        setTemplateSeleccionado={setTemplateSeleccionado}
        templateSeleccionado={templateSeleccionado}
        loadingTemplates={loadingTemplates}
      />

      <VariableTypes
        templateSeleccionado={templateSeleccionado}
        setVarsType={setVarsType}
        varsType={varsType}
        setXlsData={setXlsData}
        xlsOptions={xlsOptions}
        matchClientXls={matchClientXls}
        setMatchClientXls={setMatchClientXls}
        xlsColumn={xlsColumn}
        setXlsColumn={setXlsColumn}
        setMostrarRequestDrawer={null}
      />

      <TemplateHeader
        templateVariables={templateVariables}
        setTemplateVariables={setTemplateVariables}
        varOptions={varOptions}
        marker={marker}
        setMarker={setMarker}
        setViewport={setViewport}
        viewport={viewport}
        url={url}
        setURL={setURL}
        nodeId={nodoSeleccionado?.id}
      />

      <TemplateBody
        templateVariables={templateVariables}
        setTemplateVariables={setTemplateVariables}
        varOptions={varOptions}
      />

      <TemplateFooter
        templateVariables={templateVariables}
        setTemplateVariables={setTemplateVariables}
        varOptions={varOptions}
      />

      <TemplateButtons
        templateVariables={templateVariables}
        setTemplateVariables={setTemplateVariables}
        varOptions={varOptions}
        showHandles={null}
        setShowHandles={null}
        buttonsVars={buttonsVars}
        setButtonsVars={setButtonsVars}
        context="flow"
      />

      {variable.visible && (
        <Form.Item
          label="Nombre de la variable"
          name="variableName"
          initialValue={nodoSeleccionado?.data?.variableName}
          rules={[{ required: true, message: "Ingrese un nombre, por favor" }]}
        >
          <Input addonBefore={"template_"} placeholder={"Ingrese un nombre"} />
        </Form.Item>
      )}

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
