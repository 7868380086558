import { Tag } from "antd";
const SIZE = 10;

export const getVariableTag = (value) => {
  if (!value) {
    return <></>;
  }

  switch (true) {
    case value.startsWith("contact_"):
      return (
        <Tag color="lime" size={SIZE}>
          CONTACTO
        </Tag>
      );
    case value.startsWith("context_"):
      return (
        <Tag color="yellow" size={SIZE}>
          SYS
        </Tag>
      );
    case value.startsWith("itbl_") || value.startsWith("http"):
      return (
        <Tag color="geekblue" size={SIZE}>
          API
        </Tag>
      );
    case value.startsWith("flow_"): 
    return (
      <Tag color="green" size={SIZE}>
        FLOW
      </Tag>
    )
    default:
      return (
        <Tag color="green" size={SIZE}>
          XLSX
        </Tag>
      );
  }
};
