import { Table } from 'antd';
import { columnsCampaigns } from '../columns';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIcon from '../../../Loader/LoadingIcon';
import { AllCampaignsStats } from '../campaignStats/AllCampaignsStats';
import { GlobalContext } from '../../../../context/GlobalContext';

const PAGE_SIZE = 8;

export const CampaignTab = ({
  filteredCampaigns,
  setCampaigns,
  campaignsMetrics,
  loading,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [loadingGraphs, setLoadingGraphs] = useState(true);
  const [author, setAuthor] = useState([]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div className="CampaignTab">
      <AllCampaignsStats filteredCampaigns={filteredCampaigns} />

      <Table
        columns={columnsCampaigns(
          campaignsMetrics,
          navigate,
          loadingGraphs,
          setCampaigns,
          instance,
          messageApi
        )}
        dataSource={filteredCampaigns || []}
        rowKey={"_id"}
        size="small"
        pagination={{
          pageSize: PAGE_SIZE,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} campañas`,
        }}
        onChange={handleTableChange}
        className="tabla-generica"
        style={{ maxWidth: "100%" }}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size="large" />,
        }}
      />
    </div>
  );
};
