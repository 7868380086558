import { useState } from "react";
import { BrocolyAdminContext } from "../../../context/BrocolyAdminContext";
import { Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { adminMenuItems } from "./utils/menuItems";

export const BrocolyAdmin = ({ children }) => {
  const [brocolyAdminDrawerContent, setBrocolyAdminDrawerContent] = useState({
    open: false,
    content: "",
    item: null,
  });

  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = useState(["sub1"]);

  const navigate = useNavigate();

  const handleDrawerContent = () => {
    const { content } = brocolyAdminDrawerContent;

    return <h3>Agregar contenido</h3>;

    // switch (content) {
    //   case "newProduct":
    //     return <NewProduct />;
    //   case "updateProduct":
    //     return <UpdateProduct />;
    //   case "newList":
    //     return <NewList />;
    //   case "newStatus":
    //     return <NewStatus />;
    //   case "editList":
    //     return <EditList />;
    //   case "editStatus":
    //     return <EditStatus />;
    //   case "manageListProducts":
    //     return <ManageListProducts />;
    //   default:
    //     break;
    // }
  };

  const handleDrawerWidth = () => {
    const { content } = brocolyAdminDrawerContent;

    return 450;

    // if (["newConjunto", "updateConjunto"].includes(content)) {
    //   return 600;
    // } else if (content === "manageListProducts") {
    //   return 850;
    // } else {
    //   return 450;
    // }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleSelectedItem = (key) => {
    navigate(`/brocoly-admin/${key}`);
    setSelectedKeys(key);
  };

  return (
    <BrocolyAdminContext.Provider value={{}}>
      <div className="config-module-wrapper">
        <div className="config-main-menu">
          <p className="config-module-title">Administración</p>
          <Menu
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            defaultOpenKeys={["sub1"]}
            onOpenChange={onOpenChange}
            style={{
              width: 200,
              fontSize: "13px",
            }}
            onClick={(v) => handleSelectedItem(v.key)}
            items={adminMenuItems()}
          />
        </div>
        <div className="config-content-wrapper">{children}</div>
        <Drawer
          placement="right"
          open={brocolyAdminDrawerContent?.open}
          destroyOnClose
          onClose={() => {
            setBrocolyAdminDrawerContent({
              open: false,
              content: "",
              item: null,
            });
          }}
          closeIcon={null}
          styles={{ header: { display: "none" }, body: { padding: "24px" } }}
          width={handleDrawerWidth()}
        >
          {handleDrawerContent()}
        </Drawer>
      </div>
    </BrocolyAdminContext.Provider>
  );
};
