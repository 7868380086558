import { Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

export const InfoPopover = ({ statInfo }) => {
  return (
    <Popover className='InfoPopover' content={statInfo}>
      <InfoCircleFilled style={{ color: '#ccc' }} />
    </Popover>
  );
};
