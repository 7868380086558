import { MdOutlineStickyNote2 } from "react-icons/md";
import { FlowContext } from "../../../../context/FlowContext";
import { useContext, useEffect } from "react";
import { useBorrarNode } from "../utils/useBorrarNode";
import { TbTrash } from "react-icons/tb";
import { formatoTextHtml } from "../../../../../helpers/utilidadesTemplates";

export const NoteNode = (node) => {
  const { setNodoSeleccionado, setMostrarDrawer } = useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  useEffect(() => {
    if (node?.data?.text) {
      const text = node.data.text;

      let htmlElement = document.getElementById(`node-text-${node.id}`);
      if (htmlElement) {
        const html = formatoTextHtml(text.split("\n").join("<br>"));
        htmlElement.innerHTML = html;
      }
    }
  }, [node]);

  return (
    <div className="note-node-wrapper" onClick={handleClick}>
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash style={{ color: "var(--dark-color)" }} />
        </span>
      )}
      <span>
        {node?.data?.text ? (
          <p className="note-node-text" id={`node-text-${node.id}`}></p>
        ) : (
          <MdOutlineStickyNote2 size={32} />
        )}
      </span>
    </div>
  );
};
