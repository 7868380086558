import { Form, Select, Switch } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import FormattedTextArea from "../formattedTextArea/FormattedTextArea";
import { getMediaByType } from "../../../helpers/fetchData/fetchMedia";

export const TemplateUrlInput = ({
  url,
  setURL,
  nodeId = 0,
  headerMediaType,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingResources, setLoadingResources] = useState(false);
  const [resources, setResources] = useState([]);
  const [showSavedResources, setShowSavedResources] = useState(false);

  const handleSwitchResources = () => {
    setURL(null);
    setShowSavedResources((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      instance?.userData?.apiKey &&
      showSavedResources &&
      resources?.length < 1
    ) {
      setLoadingResources(true);
      getMediaByType(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setResources(res.data);
              return;
            }
          }

          messageApi.error("Ocurrió un error al obtener recursos");
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener recursos")
        )
        .finally(() => setLoadingResources(false));
    }
  }, [showSavedResources, instance]);

  const options = useMemo(() => {
    if (showSavedResources) {
      setURL(null);
      if (resources?.length > 0) {
        let array = resources
          .filter(
            (element) =>
              element.mediaType?.toLowerCase() === headerMediaType?.toLowerCase() &&
              element.publicURL
          )
          ?.map((element) => {
            return {
              value: element.publicURL,
              label: element.name,
            };
          });

        return array;
      }
    }

    return [];
  }, [headerMediaType, showSavedResources, resources]);

  return (
    <>
      <span className="horizontal" >
        <Switch
          checked={showSavedResources}
          onClick={handleSwitchResources}
          checkedChildren="Recursos almacenados"
          unCheckedChildren="URL"
          style={{ width: "fit-content" }}
        />
      </span>

      {!showSavedResources ? (
        <FormattedTextArea
          value={url}
          setValue={setURL}
          showDecorators={false}
          showEmojis={false}
          nodeId={nodeId}
          elementId="URL"
          label="URL"
        />
      ) : (
        <Form.Item>
          <div className="horizontal">
            <Select
              options={options}
              placeholder="Seleccione un recurso"
              style={{ width: "100%" }}
              onChange={(v) => setURL(v)}
              loading={loadingResources}
            />
          </div>
        </Form.Item>
      )}
    </>
  );
};
