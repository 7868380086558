import { Input, Popover, Spin, Switch, Tooltip, Tree } from "antd";
import { AiOutlineStrikethrough } from "react-icons/ai";
import { FiBold, FiItalic, FiPaperclip } from "react-icons/fi";
import { LuSquareSlash } from "react-icons/lu";
import { ExpirationCountdown } from "./ExpirationCountdown";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { IoSend } from "react-icons/io5";
import EmojiPicker from "emoji-picker-react";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { RiCodeSSlashFill } from "react-icons/ri";
import { BsEmojiSmile } from "react-icons/bs";
import {
  savedMessagesTreeData,
  handleTreeSelect,
} from "../utils/chatFunctions";
import { MediaPicker } from "../MediaPicker";
import { decorarTextoChat } from "../utils/chatFunctions";
import { useState } from "react";
import LoadingIcon from '../../../Loader/LoadingIcon';


const SIZE = 18;

export const ChatInput = ({
  loadingNewChat,
  sendMessage,
  isNota,
  setIsNota,
  setConfigTemplate,
  socket,
  selectedConversation,
  liveConversation,
  setLiveConversation,
  setConversations,
  chatFormattedData,
  setIsConversationExpired,
  selectedContact,
  setSelectedConversation,
  setActiveTabKey,
  setNotAssignedTickets,
  myTeams,
  savedMessages
}) => {
  const [showModalMedia, setShowModalMedia] = useState(false);

  const [showSavedMessages, setShowSavedMessages] = useState(false);

  const [innerText, setInnerText] = useState("");

  const handleSendMessage = () => {
    sendMessage(innerText);

    setInnerText("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      sendMessage(innerText);
      event.preventDefault();

      setInnerText("");
    }
  };

  

  return (
    <>
      <Input.TextArea
        autoSize={{ minRows: 5 }}
        placeholder="Escriba un mensaje"
        style={{ flex: "1" }}
        variant="borderless"
        disabled={loadingNewChat}
        onKeyDown={handleKeyDown}
        value={innerText || ""}
        onChange={(v) => setInnerText(v.target.value)}
        id="chatText"
      />
      <div className="horizontal">
        <div
          className="boton-texto"
          onClick={() => decorarTextoChat("*", "", setInnerText)}
        >
          <FiBold size={SIZE} />
        </div>
        <div
          className="boton-texto"
          onClick={() => decorarTextoChat("_", "", setInnerText)}
        >
          <FiItalic size={SIZE} />
        </div>
        <div
          className="boton-texto"
          onClick={() => decorarTextoChat("~", "", setInnerText)}
        >
          <AiOutlineStrikethrough size={SIZE} />
        </div>
        <div
          className="boton-texto"
          onClick={() => decorarTextoChat("```", "", setInnerText)}
          style={{ fontSize: "14px", display: "flex" }}
        >
          <RiCodeSSlashFill size={SIZE} />
        </div>
        <Popover
          trigger="click"
          content={
            <EmojiPicker
              onEmojiClick={(e) => decorarTextoChat("emoji", e, setInnerText)}
              emojiStyle="native"
              lazyLoadEmojis={true}
            />
          }
        >
          <div className="boton-texto">
            <BsEmojiSmile size={SIZE} />
          </div>
        </Popover>
        {!isNota && (
          <Popover
            open={showModalMedia}
            placement="top"
            destroyOnClose
            trigger={"click"}
            content={
              <MediaPicker
                selectedConversation={selectedConversation}
                setShowModalMedia={setShowModalMedia}
                liveConversation={liveConversation}
                socket={socket}
                setConversations={setConversations}
                setLiveConversation={setLiveConversation}
                selectedContact={selectedContact}
                setSelectedConversation={setSelectedConversation}
                setActiveTabKey={setActiveTabKey}
                setNotAssignedTickets={setNotAssignedTickets}
                myTeams={myTeams}
              />
            }
          >
            <div
              className="boton-texto"
              onClick={() => setShowModalMedia(true)}
            >
              <FiPaperclip size={SIZE} />
            </div>
          </Popover>
        )}
        {!isNota && (
          <Popover
            content={
              <div className="columna">
                <Tree
                  showIcon
                  switcherIcon={<DownOutlined />}
                  treeData={savedMessagesTreeData(savedMessages)}
                  onSelect={(v, e) =>
                    handleTreeSelect(
                      e,
                      savedMessages,
                      setInnerText,
                      setShowSavedMessages
                    )
                  }
                />
              </div>
            }
            title="Mensajes predeterminados"
            open={showSavedMessages}
            arrow={false}
          >
            <span className="boton-texto">
              <LuSquareSlash
                size={SIZE}
                onClick={() => setShowSavedMessages((prevState) => !prevState)}
              />
            </span>
          </Popover>
        )}
        <Tooltip title="Enviar plantilla">
          <span className="boton-texto">
            <FaRegFilePowerpoint
              size={SIZE}
              onClick={() => setConfigTemplate({ open: true, data: null })}
            />
          </span>
        </Tooltip>
        <ExpirationCountdown
          chatFormattedData={chatFormattedData}
          selectedConversation={selectedConversation}
          setIsConversationExpired={setIsConversationExpired}
          liveConversation={liveConversation}
        />
        <span className="horizontal">
          <p>Nota</p>
          <Switch checked={isNota} onChange={(v) => setIsNota(v)} />
        </span>
        {loadingNewChat ? (
          // <Spin indicator={<LoadingOutlined size={22} spin />} />
          <LoadingIcon size="default" />
        ) : (
          <span
            style={{ height: "22px", cursor: "pointer" }}
            onClick={() => handleSendMessage()}
          >
            <IoSend size={22} color="var(--primary-color)" />
          </span>
        )}
      </div>
    </>
  );
};
