export const translateBranches = (node, nodes, edges) => {

  let filtro = edges?.filter((edge) => {
    if (node.type === "buttons") {
      if (edge.source === node.id) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        edge.source === node.id &&
        !edge.sourceHandle.toLowerCase().includes("fallback") &&
        !edge.sourceHandle.toLowerCase().includes("nextstep")
      ) {
        return true;
      } else {
        return false;
      }
    }
  });

  let variables = [];

  if (filtro?.length > 0) {
    if (node.type === "list") {
      let rowsBySection = [];

      node.data?.listContent?.sections?.forEach((section) => {
        rowsBySection.push(section.rows.length);
      });

      filtro.map((edge) => {
        let split = edge.sourceHandle.split("-");
        let index = Number(split[0]);
        if (index > 0) {
          let rowsFilter = rowsBySection.slice(0, index);
          let prevCount = rowsFilter.reduce((a, b) => a + b, 0);

          return variables.push({
            value: Number(prevCount) + Number(split[1]) + 1,
            childId: Number(edge.target),
          });
        } else {
          return variables.push({
            value: Number(split[1]) + 1,
            childId: Number(edge.target),
          });
        }
      });
    } else {
      filtro.map((edge) => {
        return variables.push({
          value: Number(edge.source),
          childId: Number(edge.target),
        });
      });
    }
  }

  let step = {
    step: {
      id: Number(node.id),
      variables: variables,
    },
  };

  return step;
};
