import { TablaClientes } from "../ui/Layout/tablaClientes/TablaClientes";
import MainLayout from "../ui/Layout/MainLayout";

export const TablaClientesView = () => {
  return (
    <MainLayout>
      <TablaClientes />
    </MainLayout>
  );
};
