import { Button, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { updateNode } from "../utils/updateNode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { manageWaitNode } from "../utils/manageWaitNode";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { PiTicketLight } from 'react-icons/pi';
import { clearNodeVariables } from "../utils/clearNodeVariables";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { SiOpenai } from "react-icons/si";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { getTickets } from "../../../../../helpers/fetchData/fetchTickets";
import { getTeams } from "../../../../../helpers/fetchData/fetchTeams";
import { getInvitationsByInstance } from "../../../../../helpers/fetchData/fetchInvitation";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia"; 



export const CreateTicketForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    mostrarDrawer,
    setMostrarDrawer,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(FlowContext);

  const { instance, messageApi } = useContext(GlobalContext);

  const [inputText, setInputText] = useState("");
  const [nombreNodo, setNombreNodo] = useState("");
  const [loadingTicketTypes, setLoadingTicketTypes] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedAsignado, setSelectedAsignado] = useState({});
  const [selectedTicketType, setSelectedTicketType] = useState({});
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [form] = Form.useForm();


  const onFinish = (values) => {
    values.label = nombreNodo;
    values.auto = true;
    values.caption = null;

    if (!inputText || inputText?.trim() === '') {
      return messageApi.error("Texto es un campo requerido");
    }
    values.bodyText = cleanLabel(inputText);
    values.headerType = "text";

    // En caso de desabilitar "esperar respuesta" hay que borrar la variable
    clearNodeVariables(nodoSeleccionado?.id, setFlowVariables);

    values.variable = {};

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    values.handleCount = 1;

    let nodeViewData = {};
    nodeViewData.asignadoLabel = selectedAsignado;
    nodeViewData.ticketTypeLabel = values.ticketType === 'autoAI' ? 'Detección automática AI' : selectedTicketType?.label;
    nodeViewData.ticketTypeColor = selectedTicketType?.color;
    values.nodeViewData = nodeViewData;

    manageWaitNode(nodes, setNodes, edges, setEdges, nodoSeleccionado, true);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (!mostrarDrawer) {
      setInputText("");
    }
  }, [mostrarDrawer]);



  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      setInputText(data.bodyText);

      if (data?.nodeViewData) {
        setSelectedAsignado(data.nodeViewData.asignadoLabel);
        setSelectedTicketType({label: data.nodeViewData?.ticketTypeLabel ,color: data.nodeViewData?.ticketTypeColor});
      }


      form.setFieldsValue({
        ticketType: data.ticketType,
        asignado: data.asignado
      });

    }
  }, [nodoSeleccionado]);



  useEffect(() => {

    setLoadingTicketTypes(true);
    setLoadingTeams(true);
    setLoadingUsers(true);

    const fetchTicketTypes = async () => {
      try {
        const res = await getTickets(instance?.userData?.apiKey);

        if (res.status === 200) {
          setTicketTypes(res?.data);
        } else {
          console.error("Error en la respuesta:", res.status);
        }
      } catch (error) {
        console.error("Error al obtener los tipos de tickets:", error);
      } finally {
        setLoadingTicketTypes(false);
      }
    };

    const fetchTeams = async () => {
      try {
        const res = await getTeams(instance?.userData?.apiKey);

        if (res.status === 200) {
          setTeams(res?.data);
        } else {
          console.error("Error en la respuesta:", res.status);
        }
      } catch (error) {
        console.error("Error al obtener los equipos:", error);
      } finally {
        setLoadingTeams(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const res = await getInvitationsByInstance(instance?.userData?.apiKey);

        if (res.status === 200) {
          setUsers(res?.data?.activeUsers);
        } else {
          console.error("Error en la respuesta:", res.status);
        }
      } catch (error) {
        console.error("Error al obtener los usuarios:", error);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchTicketTypes();
    fetchTeams();
    fetchUsers();

  }, [instance]);

  const ticketTypeOptions = ticketTypes?.map(item => ({
    value: item._id,
    label: item.name,
    color: item.color
  }));

  const usersAndTeamsOptions = [
    {
      label: <span>Equipos</span>,
      title: 'equipos',
      options: teams?.map(item => ({
        value: `team_${item._id}`,
        label: item.name
      })),
    },
    {
      label: <span>Usuarios</span>,
      title: 'usuarios',
      options: users?.map(item => ({
        value: `user_${item._id}`,
        label: item.name
      })),
    },
  ];

  const handleChangeAsignado = (value, fullValues) => {
    // console.log('fullValues handleChangeAsignado: ', fullValues)
    setSelectedAsignado(fullValues?.label)
  };

  const handleChangeTicketType = (value, fullValues) => {
    // console.log('fullValues handleChangeTicketType: ', fullValues)
    setSelectedTicketType(fullValues)
  };

  const cleanLabel = (text) =>
    text
      .replace(/\s+/g, ' ')
      .replace(/\n|\r|\t/g, '')
      .replace(/ {5,}/g, '    ')
      .trim();


  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >

      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <PiTicketLight style={{ color: "var(--dark-color)" }} size={32} />
        }
      />


      {
        !loadingTicketTypes && !loadingUsers && !loadingTeams ?
          <>
            <Form.Item label='Tipo de ticket'
              name='ticketType'
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return option.label.toLowerCase().includes(input.toLowerCase());
                  }
                  // Si el label es un componente, extraemos su texto (en este caso, props.children)
                  const label = option.label?.props?.children;
                  if (typeof label === 'string') {
                    return label.toLowerCase().includes(input.toLowerCase());
                  }
                  // Si el label tiene múltiples children, los concatenamos en un string
                  if (Array.isArray(label)) {
                    const concatenatedLabel = label.join('').toLowerCase();
                    return concatenatedLabel.includes(input.toLowerCase());
                  }

                  return false;
                }}
                style={{
                  width: '100%',
                }}
                options={[
                  {
                    value: 'autoAI',
                    label: (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <SiOpenai style={{ color: 'var(--dark-color)',  marginRight: 8 }} />
                        Detección automática AI
                      </span>
                    ),
                    color: '#4A4A4A'
                  },
                  ...ticketTypeOptions,
                ]}
                loading={loadingTicketTypes}
                onChange={handleChangeTicketType}
              />
            </Form.Item>

            <Form.Item label='Asignar'
              name='asignado'
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) => {
                  const label = typeof option?.label === 'string' ? option?.label : option?.label?.props?.children || '';
                  return label.toLowerCase().includes(input.toLowerCase());
                }}
                style={{
                  width: '100%',
                }}
                onChange={handleChangeAsignado}
                options={usersAndTeamsOptions || []}
                loading={loadingUsers && loadingTeams}
              />
            </Form.Item>

            <HeaderTextOrMedia
              messageType={"text"}
              //setMessageType={setMessageType}
              nodeId={nodoSeleccionado?.id}
              // headerMediaType={headerMediaType}
              // setHeaderMediaType={setHeaderMediaType}
              text={inputText}
              setText={setInputText}
              // url={url}
              // setURL={setURL}
              // setPreview={setPreview}
              // caption={caption}
              // setCaption={setCaption}
              // location={location}
              // setLocation={setLocation}
              context="noContext"
            />
          </>
          :
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
            <LoadingIcon size="large" />
          </div>
      }

      <Form.Item className="form-custom-footer" style={{ paddingTop: '4px' }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={loadingTicketTypes ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loadingTicketTypes ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
              //setAuto(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
