import axios from 'axios';

export const getQrCodes = async (instance) => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/qrCodes/getQrCodes`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deleteQrCode = async (instance, data) => {
  try {
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_PRODUCCION}/qrCodes/deleteQrCode`,
      headers: {
        Authorization: instance?.userData?.apiKey
      },
      data: data
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const generateQrCode = async (instance, data) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/qrCodes/generateQrCode`,
      headers: {
        Authorization: instance?.userData?.apiKey
      },
      data: data
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
