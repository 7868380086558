import { Button, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { FiPlus } from "react-icons/fi";
import "./contactos.css";
import { useAccess } from "../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../helpers/access";
import { useLocation, useNavigate } from "react-router-dom";
import { TablaContactsLeads } from "./leads/TablaContactsLeads";
import { SearchInput } from "../../searchInput/SearchInput";
import { ContactsTable } from "./ContactsTable";

export const Contactos = ({ tab }) => {
  const { setContactos, setSideDrawerConfig } = useContext(GlobalContext);

  const navigate = useNavigate();

  const location = useLocation();
  const isAllowed = useAccess();

  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [activeTab, setActiveTab] = useState(tab || "activos");

  //Buscador search query
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  // ------ //

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilteredLeads([...leads]);
  }, [leads]);

  useEffect(() => {
    setActiveTab(tab);
  }, [location, tab]);

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>Contactos</span>

        {activeTab === "activos" && (
          <SearchInput
            element="contactos"
            setSearch={setSearch}
            page={page}
            setPage={setPage}
            loading={loading}
          />
        )}

        {/* {activeTab === "leads" && (
          <Buscador
          element="contacto"
          data={unarchivedContactos}
          setFilteredData={setFilteredContactos}
          searchKeys={["fullName", "phoneNumber", "email"]}
          />
          )} */}

        <div className="espacio-botones">
          {isAllowed(accessDataFormat("contacts", "manage")) && (
            <Button
              type="primary"
              className="btn-agregar"
              onClick={() =>
                setSideDrawerConfig({
                  visible: true,
                  content: "plus",
                  tab: "contact",
                })
              }
            >
              <FiPlus /> Contacto
            </Button>
          )}
        </div>
      </div>

      <Tabs
        style={{ marginInline: "7.5%" }}
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={(v) => {
          navigate(`/contactos/${v}`);
          setPage(1);
          setSearch("");
          // setActiveTab(v);
        }}
        items={[
          {
            label: "Contactos",
            key: "activos",
            children: (
              <ContactsTable
                search={search}
                setLoading={setLoading}
                page={page}
                setPage={setPage}
              />
            ),
          },
          {
            label: "Leads",
            key: "leads",
            children: (
              <TablaContactsLeads
                leads={leads}
                setLeads={setLeads}
                filteredLeads={filteredLeads}
                setContactos={setContactos}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
