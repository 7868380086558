export const handleVariablesDisponibles = (
  flowVariables,
  nodeId,
  isStringNumber,
  edges,
  lookingForTypes = [],
  excludedTypes = []
) => {
  let array = [...flowVariables];

  let nodosAnteriores = getLastNode(edges, nodeId);

  if (isStringNumber) {
    array = array.filter((variable) => {
      if (
        nodosAnteriores.has(variable.idNodo) &&
        ["string", "number"].includes(variable.type)
      ) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    array = array.filter((variable) => nodosAnteriores.has(variable.idNodo));
  }

  let regular = array.filter((element) => {
    let flag = true;
    if (element.kind) {
      if (element.kind === "indexedVars") {
        flag = false;
      }
    }

    return flag;
  });

  if (lookingForTypes.length > 0) {
    regular = regular.filter((element) =>
      lookingForTypes.some((type) => type === element.type)
    );
  }

  let indexedVars = array.filter((element) => {
    let flag = false;

    if (element.kind) {
      if (element.kind === "indexedVars") {
        if (!element.name.includes("[idx]")) {
          flag = true;
        }
      }
    }

    return flag;
  });

  if (excludedTypes?.length > 0) {
    regular = regular.filter(
      (element) => !excludedTypes.includes(element.type)
    );
  }

  return { regular: regular, indexedVars: indexedVars };
};

export const getLastNode = (edges, nodeId) => {
  let set = new Set();

  const getLast = (id) => {
    const filtro = edges.filter((edge) => {
      if (
        edge.target === id &&
        edge.source !== id &&
        edge.type !== "fallback"
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (filtro.length > 0) {
      filtro.map((edge) => {
        //Condición para que no loopee
        if (!set.has(edge.source)) {
          set.add(edge.source);
          getLast(edge.source);
        }
      });
    }
  };

  getLast(nodeId);

  return set;
};
