export const TooltipGrafico = ({
  graphType,
  active,
  payload,
  label,
  datePickerValue
}) => {
  const month = datePickerValue.format('MMM');
  const year = datePickerValue.year();

  function getLabel(label) {
    const customLabel = `${label} de ${month}. ${year}`;

    return customLabel;
  }

  let delivered, sent;
  if (graphType === 'mensajes') {
    delivered = payload.find((i) => i.name === 'delivered') || {};

    sent = payload.find((i) => i.name === 'sent') || {};
  }

  return (
    <>
      {active && payload && payload.length && (
        <div className='TooltipGrafico'>
          <p>{getLabel(label)}</p>
          {graphType === 'mensajes' ? (
            <div>
              <p style={{ color: delivered.color }}>
                Entregados: {delivered.value}
              </p>
              <p style={{ color: sent.color }}>Enviados: {sent.value}</p>
            </div>
          ) : (
            <p style={{ color: payload[0].color }}>
              Conversaciones: {payload[0].value}
            </p>
          )}
        </div>
      )}
    </>
  );
};
