export const paginacion = (array, name, size = 10) => {
  if (array?.length > 0) {
    return {
      pageSize: size,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} de ${total} ${name}`,
    };
  } else {
    return false;
  }
};
