import { useContext, useEffect } from "react";
import { formatoTextHtml } from "../../../../helpers/utilidadesTemplates";
import { GlobalContext } from "../../../context/GlobalContext";
import dayjs from "dayjs";
import { findUser, renderMessageStatus } from "./utils/chatFunctions";
import { MessageMediaHeader } from "./MessageMediaHeader";

export const Message = ({ data }) => {
  const { usuarios } = useContext(GlobalContext);

  const renderSystemMessage = () => {
    let date = null;

    if (data.timestamp) {
      let timestamp = data.timestamp?.toString();
      date = dayjs(timestamp, "x")?.format("DD/MM/YYYY HH:mm");
    }

    let text = "";
    if (data?.systemType === "reassign") {
      const userFrom = findUser(data.userFrom, usuarios)?.name;

      const userTo = findUser(data.userTo, usuarios)?.name;

      if (userFrom) {
        text = `${userFrom} `;
      }

      text = text + `reasignó el ticket `;

      if (userTo) {
        text = text + `a ${userTo}`;
      }
    }

    if (date) {
      text = text + ` - ${date}`;
    }

    return text;
  };

  const bubbleType = (data) => {
    if (data.isNote) {
      return "chat-bubble chat-bubble-note";
    } else {
      if (data.userType === "agent") {
        return "chat-bubble chat-bubble-right";
      } else if (data.userType === "system") {
        return "chat-bubble chat-bubble-system";
      } else {
        return "chat-bubble";
      }
    }
  };

  useEffect(() => {
    if (data.text?.body) {
      let textElement = document.getElementById(`message-${data.id}`);

      if (textElement) {
        textElement.innerHTML = formatoTextHtml(
          data.text.body.split("\n").join("<br>")
        );
      }
    }
  }, [data]);

  if (data?.userType === "system") {
    return (
      <div className="horizontal gap0 chat-wrapper fade-in">
        <div className="chat-bubble chat-bubble-system">
          {renderSystemMessage()}
        </div>
      </div>
    );
  }

  return (
    <div className="horizontal gap0 chat-wrapper fade-in">
      <div
        className={bubbleType(data)}
        style={
          data.userType === "agent"
            ? {
                backgroundColor: "#DCF8C6",
                border: "1px solid #99FFA266",
              }
            : {
                backgroundColor: "#f4f4f4",
                border: "1px solid #d8d8d8",
              }
        }
      >
        {data.type && data.type !== "text" && (
          <>
            <MessageMediaHeader data={data} />
            {data[data.type]?.caption && (
              <p className="message header-caption">{data[data.type]?.caption}</p>
            )}
          </>
        )}
        {!data[data.type]?.caption && (
          <div className="txt">
            {data.header?.type === "text" && (
              <p className="name">{data.header.text && data.header.text}</p>
            )}
            <p className="message" id={`message-${data.id}`}></p>
            <p className="footer">{data.footer !== "" && data.footer}</p>
          </div>
        )}
        <span className="chat-timestamp-wrapper">
          <span className="timestamp">
            {dayjs(data.timestamp, "x")?.format("hh:mm a")}
          </span>
          {!data.isNote && (
            <span style={{ height: "16px" }}>
              {renderMessageStatus(data.status)}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};
