export const flattenJSON = (object) => {
  const array = [];

  const printObjectEntries = (entries, prefix = "") => {
    if (entries) {
      for (let [key, value] of entries) {
        if (Array.isArray(value)) {
          printArray(value, `${prefix ? prefix + "." : ""}${key}`);
        } else if (typeof value === "object" && value !== null) {
          printObject(value, `${prefix ? prefix + "." : ""}${key}`);
        } else {
          array.push(prefix ? `${prefix}.${key}` : key);
        }
      }
    }
  };

  const printObject = (object, prefix = "") => {
    if (object) {
      const entries = Object.entries(object);
      printObjectEntries(entries, prefix);
    }
  };

  const printArray = (array, prefix) => {
    array.forEach((item, index) => {
      if (typeof item === "object" && item !== null) {
        printObject(item, `${prefix}[${index}]`);
      } else {
        array.push(`${prefix ? `${prefix}[${index}]` : `[${index}]`}`);
      }
    });
  };

  printObject(JSON.parse(object));

  return array.map((element) => {
    const formattedElement = element
      .replace(/^(\d+)\./, (match, number) => `[${number}].`)
      .replace(/\.\d+\./g, (match) => `[${match.replace(/\./g, "")}]`);

    return formattedElement;
  });
};

export const flattenFirstElement = (object) => {
  let array = [];
  if (object) {
    object = JSON.parse(object);
    if (Array.isArray(object)) {
      if (object.length > 0) {
        array = flattenJSON(JSON.stringify(object[0]));
      }
    }
  }

  return array.map((element) => {
    return { name: `[idx].${element}`, display: element };
  });
};

export const flattenFirstElementGetProducts = (object) => {
  let array = [];

  if (!object) {
    return array;
  }

  if (Array.isArray(object)) {
    if (object.length > 0) {
      array = flattenJSON(JSON.stringify(object[0]));
    }
  } else if (typeof object === "object") {
    array = flattenJSON(JSON.stringify(object));
  }

  return array;
};
