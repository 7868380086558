import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Button, Form, Input } from 'antd';
import { upsertOpenAiKey } from '../../../../../../../helpers/fetchData/fetchOpenAI';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const EditarOpenAi = ({ openAiKey, setOpenAiKey, setShowEdit }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const apiKey = instance?.userData?.apiKey;

  const [inputValue, setInputValue] = useState(openAiKey);
  const [loading, setLoading] = useState(false);

  const [formOpenAi] = Form.useForm();

  function onFinish() {
    if (inputValue) {
      const data = { apiKey: inputValue };

      fetchUpsertOpenAiKey(data);
    } else {
      messageApi.error('El campo no puede estar vacío');
    }
  }

  async function fetchUpsertOpenAiKey(data) {
    try {
      setLoading(true);
      await upsertOpenAiKey(apiKey, data);
      messageApi.success('API Key cambiada');
      setShowEdit(false);
      setOpenAiKey(inputValue);
    } catch (error) {
      console.error(error);
      messageApi.error('Error al cargar la API Key');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ width: 500 }}>
      <Form
        form={formOpenAi}
        name='formOpenAi'
        style={{
          margin: '30px 0'
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label='API Key'
          rules={[
            {
              required: true,
              message: 'Campo requerido'
            }
          ]}
        >
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            <Button 
              type='primary' 
              htmlType='submit'
              icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
              style={{ opacity: loading ? 0.65 : 1 }} >
              Guardar
            </Button>
            <Button onClick={() => setShowEdit(false)}>Cancelar</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
