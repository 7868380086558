export const getEnvioData = (nodes, edges, messageApi, name) => {
  try {
    let error = false;
    let data = {};

    let cronNode = nodes.find((nodo) => nodo.type === "cron");

    if (!cronNode?.data?.schedule) {
      messageApi.info("No se encontró frecuencia configurada");
      error = true;
      return { data, error };
    }

    // Obtiene toda la data del formulario template
    let template = nodes.find((nodo) => nodo.type === "template")?.data;

    if (!template?.template?.name) {
      messageApi.info("Seleccione y complete su plantilla");
      error = true;

      return { data, error };
    }

    let actions = [];
    let botones = nodes.filter((element) => element.type === "button");

    if (botones?.length > 0) {
      botones.forEach((boton) => {
        let filteredEdges = edges?.filter((edge) => edge.target === boton.id);

        filteredEdges?.forEach((edge, index) => {
          let text =
            template?.templateVariables?.buttons?.value?.[edge?.sourceHandle]
              ?.text || "";

          actions.push({
            ...boton.data,
            index: edge?.sourceHandle || "",
            text: text,
          });
        });
      });
    }

    data = {
      schedule: cronNode?.data?.schedule,
      template: template, // Contiene info de template, templateVariables, showHandles, buttonVars
      actions: actions,
      name: name,
      type: "regular",
    };

    return { data: data, error: error };
  } catch (error) {
    return { data: {}, error: true };
  }
};
