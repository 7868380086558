import dayjs from "dayjs";
import { renderInput } from "./chatFunctions";

export const handleConversationStage = (
  selectedConversation,
  selectedContact,
  instance,
  isNota,
  conversationExpired,
  liveConversation,
  chatFormattedData
) => {
  if (!selectedConversation?._id) {
    if (!selectedContact) {
      return "";
    } else {
      // Hay un contacto seleccionado
      if (selectedContact.chats?.teamId || selectedContact.chats?.userId) {
        return "notMine";
      } else if (
        renderInput(
          selectedConversation,
          isNota,
          conversationExpired,
          liveConversation
        )
      ) {
        return "textInput";
      } else {
        return "initiate";
      }
    }
  } else {
    // hay uno seleccionado
    if (
      (selectedConversation?.userId || selectedConversation?.chats?.userId) &&
      selectedConversation?.userId !== instance?.userData?.userId &&
      selectedConversation?.chats?.userId !== instance?.userData?.userId
    ) {
      //Está asignado a otro agente
      return "notMine";
    }

    //Está asignado a mi, puedo interactuar
    if (
      renderInput(
        selectedConversation,
        isNota,
        conversationExpired,
        liveConversation
      )
    ) {
      //Ticket abierto y la ventana está vigente
      return "textInput";
    } else {
      const lastDay = dayjs().format("x") - 24 * 60 * 60 * 1000;

      if (selectedConversation?.createdAt) {
        let createdAt = selectedConversation?.createdAt;
        if (createdAt.length < 12) {
          createdAt = Math.floor(createdAt * 1000);
        }

        if (
          selectedConversation?.status === "OPEN" &&
          createdAt >= lastDay &&
          conversationExpired &&
          !liveConversation.some((element) => element.userType === "user") &&
          !chatFormattedData?.messages?.[
            chatFormattedData.messages?.length - 1
          ]?.messages?.some((element) => element.userType === "user")
        ) {
          //Esperando que se inicie la ventana o llege un mensaje del receptor
          return "waiting";
        }
      }

      if (
        !isNota &&
        conversationExpired &&
        !liveConversation.some((element) => element.userType === "user")
      ) {
        //Conversación expirada
        return "expired";
      }

      if (
        selectedConversation?.status === "CLOSED" &&
        selectedConversation?.type === "chat"
        // selectedConversation?.type !== "chat"
      ) {
        //No hay ticket
        return "initiate";
      }
    }
  }
  return null;
};
