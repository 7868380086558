import axios from 'axios';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { validateDuplicateTrigger } from './utils/validateDuplicateTrigger';
import {
  deleteFlow,
  getFlows,
  newFlow,
  newTest,
  updateFlowStatus
} from '../../../../helpers/fetchData/fetchFlows';
import { Button, Input, Popover, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FiCloudOff, FiEdit3, FiUploadCloud } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { TbTrash } from 'react-icons/tb';
import { accessDataFormat } from '../../../../helpers/access';
import { useAccess } from '../../../../hooks/useAccess';
import LoadingIcon from '../../Loader/LoadingIcon';
import { MdOutlineChecklistRtl } from 'react-icons/md';
import { BoxInfo } from '../../box-info/BoxInfo';
import { SelectContactos } from '../../selectContactos/SelectContactos';

export const FlowsAcciones = ({ item, setFlows, setLoading }) => {
  const { messageApi, instance, contactos } = useContext(GlobalContext);
  const [showPopover, setShowPopover] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateData, setDuplicateData] = useState({
    name: '',
    trigger: '',
    description: ''
  });

  const [testData, setTestData] = useState({ open: false, contactId: null });
  const [loadingTest, setLoadingTest] = useState(false);

  const isAllowed = useAccess();

  const [dupLoading, setDupLoading] = useState(false);

  const [loadingBorrar, setLoadingBorrar] = useState(false);

  const navigate = useNavigate();

  const handleDelete = (flowId) => {
    let data = JSON.stringify({
      flowId: flowId
    });

    deleteFlow(
      data,
      messageApi,
      setFlows,
      setLoading,
      instance,
      setLoadingBorrar
    );
  };

  const handleDuplicateData = (value, type) => {
    setDuplicateData((prevState) => {
      let data = { ...prevState };

      data[type] = value;

      return data;
    });
  };

  const updateStatus = (flowId, status) => {
    let data = JSON.stringify({
      flowId: flowId,
      newStatus: status
    });

    // No actualiza la tabla. el get no lo afecta
    updateFlowStatus(data, messageApi, setFlows, setLoading, instance);
  };

  const handleTest = () => {
    if (!testData?.contactId) {
      messageApi.info('Seleccione un contacto, por favor');
      return;
    }

    const phoneNumber = contactos.find(
      (element) => element._id === testData.contactId
    )?.phones?.[0]?.phone;

    const data = { flowId: item._id, phoneNumber: phoneNumber };

    setLoadingTest(true);
    newTest(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Enviamos una plantilla al contacto indicado');

          setTestData({ open: false, contactId: null });
          return;
        }

        messageApi.error('Ocurrió un error al iniciar un test');
      })
      .catch((error) => {
        console.log('error', error);

        messageApi.error('Ocurrió un error al iniciar un test');
      })
      .finally(() => setLoadingTest(false));
  };

  const onFinish = async () => {
    if (!duplicateData.name || !duplicateData.trigger) {
      return messageApi.info('Debe ingresar un nombre y un trigger');
    }

    setDupLoading(true);

    let flag = await validateDuplicateTrigger(
      duplicateData.trigger,
      messageApi,
      instance?.userData?.apiKey
    );

    if (!flag) {
      setDupLoading(false);
      return;
    }

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/getFlowById?flowId=${item?._id}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    axios
      .request(config)
      .then((res) => {
        let data = res?.data;

        if (data.flowData) {
          let flow = {
            publishedBy: null,
            publishedAt: null,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            status: 'draft',
            createdBy: null,
            useContextVars: true,
            name: duplicateData.name,
            description: duplicateData.description,
            trigger: [duplicateData.trigger],
            steps: data.flowData.steps,
            flowVars: data.flowData.flowVars
          };

          let nodes = [...data.flowArchitectureData?.nodes];

          nodes[0].data.triggers = [duplicateData.trigger];

          let flowArchitecture = {
            edges: data.flowArchitectureData?.edges,
            variablesNodos: data.flowArchitectureData?.variablesNodos,
            nodes: nodes
          };

          let flowData = {
            flow: flow,
            flowArchitecture: flowArchitecture,
            idInstance: instance?._id
          };

          newFlow(instance?.userData?.apiKey, flowData)
            .then((res) => {
              if (res?.status === 200) {
                messageApi.open({
                  type: 'success',
                  content: 'Bot guardado correctamente como borrador',
                  style: {
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px'
                  }
                });
                setShowDuplicate(false);
                getFlows(setFlows, messageApi, setLoading, instance);
              } else {
                messageApi.error('No se pudo guardar el Bot');
              }
            })
            .catch((error) => {
              messageApi.error('No se pudo guardar el Bot');
            })
            .finally(() => {
              setDupLoading(false);
            });
        } else {
          messageApi.error('No se pudo obtener información del bot a duplicar');
        }
      })
      .catch((error) => {
        messageApi.error('No se pudo obtener información del bot a duplicar');
      })
      .finally(() => setDupLoading(false));
  };

  return (
    isAllowed(accessDataFormat('bots', 'manage')) && (
      <span className='fila-acciones'>
        <Tooltip title='Editar'>
          <span
            style={{ height: '18px', cursor: 'pointer' }}
            onClick={(e) => {
              navigate(`/flows/edit?id=${item._id}`);
            }}
          >
            <FiEdit3 size={18} />
          </span>
        </Tooltip>
        {item.status === 'draft' ? (
          <Tooltip title='Publicar'>
            <span style={{ height: '18px', cursor: 'pointer' }}>
              <FiUploadCloud
                size={18}
                onClick={() => updateStatus(item._id, 'publish')}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title='Volver a borrador'>
            <span style={{ height: '18px', cursor: 'pointer' }}>
              <FiCloudOff
                size={18}
                onClick={() => updateStatus(item._id, 'draft')}
              />
            </span>
          </Tooltip>
        )}
        <>
          {showDuplicate && (
            <div className='confirm-wrapper' style={{ position: 'fixed' }}>
              <div
                className='confirm-form'
                style={{ padding: '16px' }}
                onClick={(e) => e.stopPropagation()}
              >
                <span style={{ width: '100%' }}>
                  <p className='label-duplicate'>Nombre</p>
                  <Input
                    value={duplicateData.name}
                    onChange={(v) =>
                      handleDuplicateData(v.target.value, 'name')
                    }
                  />
                </span>
                <span style={{ width: '100%' }}>
                  <p className='label-duplicate'>Descripción</p>
                  <Input
                    value={duplicateData.description}
                    onChange={(v) =>
                      handleDuplicateData(v.target.value, 'description')
                    }
                  />
                </span>
                <span style={{ width: '100%' }}>
                  <p className='label-duplicate'>Trigger</p>
                  <Input
                    value={duplicateData.trigger}
                    onChange={(v) =>
                      handleDuplicateData(v.target.value, 'trigger')
                    }
                  />
                </span>
                <div
                  className='botones-wrapper-content'
                  style={{ marginTop: '8px', width: '100%' }}
                >
                  <Button
                    type='primary'
                    className='btn-guardar'
                    onClick={() => onFinish()}
                    icon={
                      dupLoading ? (
                        <LoadingIcon size='small' color='#FFFFFF' />
                      ) : null
                    }
                    style={{ opacity: dupLoading ? 0.65 : 1 }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    className='btn-cancelar'
                    type='secondary'
                    onClick={() => setShowDuplicate(false)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
        <Tooltip title='Duplicar'>
          <span style={{ height: '18px', cursor: 'pointer' }}>
            <IoDuplicateOutline
              size={18}
              onClick={() => setShowDuplicate(true)}
            />
          </span>
        </Tooltip>
        {item?.status?.toLowerCase() === 'draft' && (
          <Popover
            trigger='click'
            placement='bottom'
            open={testData?.open}
            content={
              <div className='columna'>
                <b style={{ marginInline: 'auto' }}>Iniciar prueba</b>
                <span className='horizontal'>
                  <p>Seleccionar contacto</p>

                  <BoxInfo
                    message={[
                      'Se enviará una plantilla al número del contacto seleccionado.',
                      'Al confirmar iniciará la prueba.'
                    ]}
                    hideMode={true}
                  />
                </span>
                {/* <Select
                  options={contactsOptions}
                  placeholder="Seleccione un contacto"
                  filterOption={filterLabelOptions}
                  style={{ width: 220 }}
                  value={testData?.contactId}
                  showSearch
                  onChange={(v) => setTestData({ open: true, contactId: v })}
                /> */}
                <span style={{ width: 220 }}>
                  <SelectContactos
                    value={testData?.contactId}
                    setValue={(v) => setTestData({ open: true, contactId: v })}
                    placeholder='Seleccione un contacto'
                  />
                </span>
                <div
                  className='botones-wrapper-content'
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: 8
                  }}
                >
                  <Button
                    type='primary'
                    className='btn-agregar'
                    onClick={handleTest}
                    icon={
                      loadingTest ? (
                        <LoadingIcon size='small' color='#FFFFFF' />
                      ) : null
                    }
                    style={{ opacity: loadingTest ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    className='btn-cancelar'
                    type='secondary'
                    onClick={() =>
                      setTestData({ open: false, contactId: null })
                    }
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            }
          >
            <span>
              <Tooltip title='Probar'>
                <span style={{ height: '18px', cursor: 'pointer' }}>
                  <MdOutlineChecklistRtl
                    size={18}
                    onClick={() => setTestData({ open: true, contactId: null })}
                  />
                </span>
              </Tooltip>
            </span>
          </Popover>
        )}
        <Popover
          trigger='click'
          placement='bottom'
          open={showPopover}
          content={
            <div className='columna-simple'>
              <span
                style={{ marginBottom: '8px' }}
              >{`¿Está seguro de borrar ${item?.name}?`}</span>
              <div
                className='botones-wrapper-content'
                style={{ width: '100%' }}
              >
                <Button
                  type='primary'
                  className='btn-borrar'
                  onClick={() => handleDelete(item._id)}
                  icon={
                    loadingBorrar ? (
                      <LoadingIcon size='small' color='#FFFFFF' />
                    ) : null
                  }
                  style={{ opacity: loadingBorrar ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  className='btn-cancelar'
                  type='secondary'
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          }
        >
          <span>
            <Tooltip title='Eliminar'>
              <span style={{ height: '18px', cursor: 'pointer' }}>
                <TbTrash size={18} onClick={() => setShowPopover(true)} />
              </span>
            </Tooltip>
          </span>
        </Popover>
      </span>
    )
  );
};
