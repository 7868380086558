import { useContext, useState } from "react";
import { Button, Drawer, Form, Select } from "antd";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { newDefaultFlow } from "../../../../../../helpers/fetchData/fetchFlows";
import { BoxInfo } from "../../../../box-info/BoxInfo";

export const DefaultBotDrawer = ({
  showDrawer,
  setShowDrawer,
  flows,
  selectedFlow,
  setSelectedFlow,
  setDefaultFlow,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingNew, setLoadingNew] = useState(false);

  const [defaultBotForm] = Form.useForm();

  const onFinish = () => {
    if (!selectedFlow) {
      messageApi.info("Debe seleccionar un bot");
      return;
    }

    setLoadingNew(true);
    newDefaultFlow(instance?.userData?.apiKey, selectedFlow)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Bot por defecto guardado correctamente");
          setDefaultFlow(selectedFlow);
          setShowDrawer(false);
        } else {
          messageApi.error("Ocurrió un error al guardar bot por defecto");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al guardar bot por defecto");
      })
      .finally(() => setLoadingNew(false));
  };

  return (
    <Drawer
      open={showDrawer}
      width={400}
      destroyOnClose
      maskClosable={true}
      placement={"right"}
      closable={true}
      closeIcon={null}
      onClose={() => setShowDrawer(false)}
    >
      <Form
        form={defaultBotForm}
        layout="vertical"
        name="form-default-flow"
        requiredMark={false}
        onFinish={(v) => onFinish(v)}
        className="form-nodos"
      >
        <Form.Item
          label={
            <span className="columna">
              <p style={{ fontWeight: 500 }}>Seleccione el bot por defecto</p>
            </span>
          }
        >
          <Select
            options={
              flows
                ?.filter((flow) => flow?.type !== "broadcast")
                ?.map((flow) => {
                  return {
                    label: flow.name,
                    value: flow._id,
                  };
                }) || []
            }
            value={selectedFlow}
            onChange={(v) => setSelectedFlow(v)}
          />
        </Form.Item>
        <Form.Item>
          <BoxInfo
            message={`El bot seleccionado se ejecutará en caso de que el usuario envíe un mensaje que no
                  coincida con ninguna palabra clave (disparador).`}
          />
        </Form.Item>
        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loadingNew}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
