import { Handle, Position } from "reactflow";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { TbClockStop } from "react-icons/tb";
import { Tooltip } from "antd";

export const SaveResponseNode = (node) => {
  const { edges, setMostrarDrawer, setNodoSeleccionado } =
    useContext(FlowContext);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "50%" }}
        isConnectableStart={false}
        isConnectableEnd={false}
      />
      <Tooltip title="Espera respuesta del usuario" placement="top">
        <span>
          <TbClockStop size={32} style={{ margin: 4 }} />
        </span>
      </Tooltip>
      <Handle
        type="source"
        className="custom-handle next-step"
        style={{ right: "-10px" }}
        position={Position.Right}
        id="nextstep"
        isConnectable={checkValidConnection(edges, node.id, "nextstep")}
      />
      {node?.data?.error?.status && (
        <Handle
          type="source"
          style={{ right: "50%" }}
          className="custom-handle fallback"
          position={Position.Bottom}
          id="fallback"
          isConnectable={checkValidConnection(edges, node.id, "fallback")}
        />
      )}
    </div>
  );
};
