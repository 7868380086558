import { Spin, Table } from "antd";
import { presupuestosColumns } from "./utils/columns";
import { useContext, useEffect, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchPresupuestos } from "../../helpers/fetchData/fetchPresupuestos";
import { GlobalContext } from "../context/GlobalContext";
import { PresupuestosContext } from "./context/PresupuestosContext";
import { paginacion } from "../../helpers/paginacion";
import LoadingIcon from '../../../src/components/ui/Loader/LoadingIcon';

export const PresupuestosTabla = ({ busqueda, status }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const { setPresupuestoDrawer, presupuestos, setPresupuestos } =
    useContext(PresupuestosContext);

  const [loading, setLoading] = useState(false);

  const getFilteredPresupuestos = useMemo(() => {
    let array = [];

    if (presupuestos?.length > 0) {
      array = [...presupuestos];

      if (status !== "todos") {
        array = array.filter(
          (element) => element?.status?.toLowerCase() === status.toLowerCase()
        );
      }

      if (busqueda) {
        array = array.filter((element) =>
          element.cli_nombre?.toLowerCase()?.includes(busqueda.toLowerCase())
        );
      }
    }

    return array;
  }, [presupuestos, busqueda, status]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      fetchPresupuestos(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setPresupuestos(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener presupuestos");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener presupuestos")
        )
        .finally(() => setLoading(false));
    }
  }, [instance]);

  return loading ? (
    <span
      style={{
        height: 300,
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <LoadingIcon size="large" />
    </span>
  ) : (
    <Table
      columns={presupuestosColumns(setPresupuestoDrawer, setPresupuestos)}
      dataSource={getFilteredPresupuestos}
      rowKey={"_id"}
      size="small"
      pagination={paginacion(getFilteredPresupuestos, "presupuestos", 10)}
      className="tabla-config"
      style={{ maxWidth: "100%" }}
    />
  );
};
