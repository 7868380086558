// import DashboardLayout from "components/ui/Dashboard/DashboardLayout";
import { Dashboard } from "../ui/dashboard/Dashboard";
import MainLayout from "../ui/Layout/MainLayout";

const DashboardView = () => {

  return (
  <MainLayout >
    <Dashboard />
  </MainLayout>
  );
};

export default DashboardView;
