import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// Filtra los elementos según el día o el ticket seleccionado:
export const useFilteredData = (data, selectedDate, selectedTicket) => {
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([]);

  useEffect(() => {
    function filterByDate(items, key) {
      return selectedDate === 'todo'
        ? items
        : items.filter((item) =>
            selectedDate?.isSame(dayjs(item[key], 'x'), 'day')
          );
    }

    setFilteredTickets(filterByDate(data.messages, 'createdAt')); // ?
    setFilteredNotes(filterByDate(data.notes, 'timestamp')); // ?
    setFilteredMedia(filterByDate(data.media, 'timestamp')); // ?
  }, [data, selectedDate]);

  useEffect(() => {
    if (selectedTicket) {
      function filterByTicket(items, key) {
        return items.filter(
          (item) =>
            item[key] >= selectedTicket.createdAt &&
            item[key] <= selectedTicket.closeDate
        );
      }

      setFilteredNotes(filterByTicket(data.notes, 'timestamp')); // ?
      setFilteredMedia(filterByTicket(data.media, 'timestamp')); // ?
    }
  }, [selectedTicket, data.notes, data.media]);

  return { filteredTickets, filteredNotes, filteredMedia };
};
