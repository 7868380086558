export const formatoRegex = (valor, action) => {

    // contains   { $regex: /p.*/ }     p es valor
    // start with { $regex: /^p.*/ }

    switch (action) {
        case 'equals':
            // console.log('entre en equals string');
            return '^' + valor + '$'
        case 'contains':
            // console.log('entre en contains');
            return valor + '.*'

        case 'doesNotStartWith':
            // console.log('entre en doesNotStartWith');
            return '^' + valor + '.*'
        case 'noRegex': // Eliminar caracteres de formato regex
            // console.log('entre en noRegex')
            return valor.replace(/[.*^$\/]/g, '');
        case 'normal': // valor sin tratamiento
            return valor
    }
};