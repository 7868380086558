import { InfoPopover } from './InfoPopover';

export const CardEstadisticas = ({ graphType, statistics }) => {
  return (
    <div className='CardEstadisticas'>
      {statistics.map((stat, index) => (
        <div
          key={index}
          className={`${
            graphType === 'mensajes'
              ? 'estadistica-mensajes'
              : 'estadistica-conversaciones'
          } ${stat.total ? 'estadistica-total' : null}`}
        >
          {graphType === 'mensajes' ? (
            <>
              <div className='estadistica-titulo estadistica-titulo-mensaje'>
                <span>{stat.title}</span>
                <span>{stat.info && <InfoPopover statInfo={stat.info} />}</span>
              </div>

              <span className='estadistica-valor-mensajes'>{stat.value}</span>
            </>
          ) : (
            <>
              <span className='estadistica-titulo'>
                {stat.title} {stat.info && <InfoPopover statInfo={stat.info} />}
              </span>
              <div className='estadistica-values-container'>
                <span>{stat.value}</span>
                {stat.valueTwo && <span>{stat.valueTwo}</span>}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
