import { useState } from 'react';
import { Button } from 'antd';
import { MdOutlineEmail } from 'react-icons/md';
import { EditarEmail } from './EditarEmail';
import { CardEmail } from './CardEmail';

export const EmailIntegration = ({ setSelectedIntegration }) => {
  const [dataEmail, setDataEmail] = useState({
    host: 'aaa',
    port: 123,
    auth: {
      user: 'usuario123',
      pass: 'contraseña'
    },
    from: {
      name: 'Binamics',
      address: 'dev@binamics.com.ar'
    }
  });
  const [showEdit, setShowEdit] = useState(false);
  const [loadingCardEmail, setLoadingCardEmail] = useState(false);

  return (
    <div>
      <span className='horizontal' style={{ justifyContent: 'space-between' }}>
        <span className='horizontal'>
          <p className='integration-title'>Correo electrónico</p>
          <MdOutlineEmail size={24} />
        </span>
        <Button
          className='btn-cancelar'
          style={{ width: 'fit-content' }}
          onClick={() => setSelectedIntegration(null)}
        >
          Volver
        </Button>
      </span>

      {showEdit ? (
        <EditarEmail
          dataEmail={dataEmail}
          setDataEmail={setDataEmail}
          setShowEdit={setShowEdit}
        />
      ) : (
        <CardEmail
          dataEmail={dataEmail}
          setDataEmail={setDataEmail}
          loadingCardEmail={loadingCardEmail}
          setLoadingCardEmail={setLoadingCardEmail}
          setShowEdit={setShowEdit}
        />
      )}
    </div>
  );
};
