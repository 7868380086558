import { useContext } from "react";
import { GlobalContext } from "../components/context/GlobalContext";

export const useAccess = () => {
  const { instance } = useContext(GlobalContext);

  const isAllowed = (authData) => {
    if (instance?.userData) {
      const { roleInfo } = instance.userData;

      if (roleInfo?.name === "ADMIN") return true;
      const { module, requiredLevel, specific } = authData;

      if (!module || !roleInfo) {
        return false;
      }

      if (!roleInfo?.permissions) {
        return false;
      }

      if (specific) {
      } else {
        if (
          !roleInfo.permissions?.[module] ||
          roleInfo.permissions[module]?.[0] < requiredLevel
        ) {
          // Esto significa si el rol de este usuario(instance), no contiene el modulo (contactos, flows, usuarios y roles, etiquetas, etc) al que esta tratando de acceder este usuario, O, los permisos que tiene este usuario para este modulo son menores (no alcanzan), para el nivel requerido para acceder al modulo, le retorno false y que no pueda entrar.
          return false;
        }
      }

      return true;
    } else {
      return false;
    }
  };

  return isAllowed;
};
