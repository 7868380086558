import { ReactFlowProvider } from "reactflow";
import MainLayout from "../ui/Layout/MainLayout";
import { Flows } from "../ui/Layout/flows/Flows";

export const EditFlowView = ({ edit = false, type }) => {
  return (
    <MainLayout>
      <ReactFlowProvider>
        <Flows edit={edit} type={type} />
      </ReactFlowProvider>
    </MainLayout>
  );
};
