import { useContext, useEffect } from 'react';
import {
  getColorQualityRating,
  getTitleQualityRating
} from '../../utils/getQualityRating';
import { GlobalContext } from '../../../../../../../context/GlobalContext';
import { FaCircle } from 'react-icons/fa';
import { Tooltip } from 'antd';
import { BoxInfoPopUp } from '../../../../../../box-info/BoxInfoPopUp';

export const MessageQuality = () => {
  const { whatsappProfile } = useContext(GlobalContext);

  const colorQualityRating = getColorQualityRating(whatsappProfile);
  const titleQualityRating = getTitleQualityRating(whatsappProfile);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 25
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <span>Calidad de mensajes</span>
        <BoxInfoPopUp
          hideMode
          message={
            <>
              <p>
                La <b>Calidad de mensajes</b> es una evaluación de Meta sobre
                qué tan bien tu empresa interactúa con los clientes a través de
                WhatsApp. Se basa en la recepción de tus mensajes por parte de
                los clientes en los últimos 7 días.
              </p>
              <p>Su valor puede ser:</p>
              <ul>
                <li>
                  <b>Alta:</b> Estás teniendo un buen desempeño. Sigue enviando
                  mensajes relevantes y oportunos.
                </li>
                <li>
                  <b>Media:</b> Tu calificación está disminuyendo. Revisa las
                  razones por las que los usuarios bloquean o reportan tu número
                  en el Meta WhatsApp Manager.
                </li>
                <li>
                  <b>Baja:</b> Tu número está en riesgo de ser "Marcado". Debes
                  mejorar la calidad de tus mensajes para evitar restricciones.
                </li>
              </ul>
            </>
          }
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <FaCircle size={8} fill={colorQualityRating} />
        <Tooltip title={titleQualityRating}>
          <span
            style={{
              fontSize: 13,
              fontWeight: 500,
              color: colorQualityRating
            }}
          >
            {titleQualityRating}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
