import { Form, Input } from "antd";
import { BoxInfo } from "../box-info/BoxInfo";
import { VariableInput } from "../variableInput/VariableInput";
import { handleInputCoordinates } from "./utils";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import Search from "antd/es/input/Search";
import axios from "axios";
import { LocationMap } from "./LocationMap";
import { TemplateUrlInput } from "./TemplateUrlInput";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_APIKEY;

export const TemplateHeader = ({
  templateVariables,
  setTemplateVariables,
  varOptions,
  marker,
  setMarker,
  setViewport,
  viewport,
  url,
  setURL,
  nodeId,
}) => {
  const { messageApi } = useContext(GlobalContext);

  const [loadingSearch, setLoadingSearch] = useState(false);

  const handleSearch = async (value) => {
    setLoadingSearch(true);
    try {
      const res = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
          value?.toLowerCase()
        )}.json?access_token=${MAPBOX_TOKEN}&limit=1`
      );

      if (res?.status === 200) {
        let coordinates = res.data.features[0]?.center;

        if (coordinates) {
          let obj = {
            longitude: coordinates[0],
            latitude: coordinates[1],
          };

          setMarker(obj);
          setViewport((prevState) => {
            return { ...prevState, ...obj };
          });
        }
      } else {
        messageApi.error("No se pudo ubicar la dirección buscada");
      }
    } catch (error) {
      console.log(error);
      messageApi.error("No se pudo ubicar la dirección buscada");
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
  };

  return (
    <>
      {templateVariables?.header &&
        (templateVariables.header?.type?.toUpperCase() === "TEXT" ? (
          <>
            <Form.Item label={<b>Encabezado</b>}>
              <pre
                className="template-section-value"
                id="template-header"
              ></pre>
            </Form.Item>
            {templateVariables.header.placeholder?.length > 0 && (
              <>
                <BoxInfo
                  message="Puede cambiar el modo de ingreso haciendo click en el
                        indicador de variable."
                />
                <Form.Item>
                  {templateVariables.header.placeholder.map((item, index) => {
                    return (
                      <VariableInput
                        item={item}
                        index={index}
                        section="header"
                        templateVariables={templateVariables}
                        setTemplateVariables={setTemplateVariables}
                        key={`headerVar-${index}`}
                        varOptions={varOptions}
                      />
                    );
                  })}
                </Form.Item>
              </>
            )}
          </>
        ) : (
          <>
            {templateVariables?.header?.type === "LOCATION" ? (
              <div className="columna" style={{ marginBottom: 16 }}>
                <span className="horizontal">
                  <b style={{ fontSize: 15 }}>Encabezado</b>
                  <p>(Ubicación)</p>
                </span>
                <div>
                  <Form.Item label="Latitud">
                    <Input
                      type="number"
                      value={marker?.latitude || 0}
                      onChange={(v) =>
                        handleInputCoordinates(
                          "latitude",
                          v.target.value,
                          setViewport,
                          setMarker,
                          messageApi
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Longitud">
                    <Input
                      type="number"
                      value={marker?.longitude || 0}
                      onChange={(v) =>
                        handleInputCoordinates(
                          "longitude",
                          v.target.value,
                          setViewport,
                          setMarker,
                          messageApi
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Domicilio" name="address">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Nombre del lugar" name="locationName">
                    <Input />
                  </Form.Item>
                </div>
                <div className="columna">
                  <Search
                    onPressEnter={(v) => handleSearch(v.target?.value)}
                    placeholder="Busque una ubicacion"
                    loading={loadingSearch}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div style={{ width: "100%", height: 300, marginBottom: 16 }}>
                  <LocationMap
                    marker={marker}
                    setMarker={setMarker}
                    viewport={viewport}
                    setViewport={setViewport}
                  />
                </div>
              </div>
            ) : (
              <div className="columna" style={{ marginBottom: 0 }}>
                <span className="horizontal">
                  <b style={{ fontSize: 15 }}>Encabezado</b>
                  {/* HEADER PUEDE SER SOLO TEXT, IMAGE, VIDEO, DOCUMENT */}
                  <p>{`(${templateVariables?.header?.type})`}</p>
                </span>
                <TemplateUrlInput
                  url={url}
                  setURL={setURL}
                  nodeId={nodeId}
                  headerMediaType={templateVariables?.header?.type}
                />
              </div>
            )}
          </>
        ))}
    </>
  );
};
