import { useEffect, useRef, useState } from 'react';
import { Grafico } from './Grafico';
import { CardEstadisticas } from './CardEstadisticas';
import { StatInfo } from './utils/StatInfo';

export const EstadisticasConversaciones = ({
  conversationStats,
  datePickerValue
}) => {
  const [datosConversaciones, setDatosConversaciones] = useState({});

  // Inicialización del objeto que almacenará los datos a mostrar:
  let dataConversations = {
    cantConversaciones: 0,
    categoriasConversaciones: {
      authentications: 0,
      marketing: 0,
      service: 0,
      utilities: 0
    },
    tiposConversaciones: {
      freeEntries: 0,
      freeTiers: 0,
      regulars: {
        cantRegulars: 0,
        authentications: 0,
        marketing: 0,
        service: 0,
        utilities: 0
      }
    },
    cantConversacionesGratuitas: 0,
    cargos: {
      total: 0,
      authentications: 0,
      marketing: 0,
      service: 0,
      utilities: 0
    }
  };

  const dataPoints = useRef([]);

  useEffect(() => {
    // Si los data_points existen, calcula los datos y los muestra en las estadísticas:
    if (conversationStats?.conversation_analytics?.data?.[0]?.data_points) {
      dataPoints.current =
        conversationStats.conversation_analytics.data[0].data_points;
      const data = calculateDatosConversaciones(dataPoints, dataConversations);
      setDatosConversaciones(data);
    } else {
      dataPoints.current = [];
      setDatosConversaciones(dataConversations);
    }
  }, [conversationStats]);

  function calculateDatosConversaciones(dataPoints, dataConversations) {
    // Cálculo de los datos:
    dataPoints.current.forEach((i) => {
      dataConversations.cantConversaciones += i.conversation;
      dataConversations.cargos.total += i.cost;

      switch (i.conversation_category) {
        case 'AUTHENTICATION':
          dataConversations.categoriasConversaciones.authentications +=
            i.conversation;
          dataConversations.cargos.authentications += i.cost;
          break;
        case 'MARKETING':
          dataConversations.categoriasConversaciones.marketing +=
            i.conversation;
          dataConversations.cargos.marketing += i.cost;
          break;
        case 'SERVICE':
          dataConversations.categoriasConversaciones.service += i.conversation;
          dataConversations.cargos.service += i.cost;
          break;
        case 'UTILITY':
          dataConversations.categoriasConversaciones.utilities +=
            i.conversation;
          dataConversations.cargos.utilities += i.cost;
          break;
        default:
          break;
      }

      switch (i.conversation_type) {
        case 'FREE_ENTRY':
          dataConversations.tiposConversaciones.freeTiers += i.conversation;
          break;
        case 'FREE_TIER':
          dataConversations.tiposConversaciones.freeTiers += i.conversation;
          break;
        case 'REGULAR':
          dataConversations.tiposConversaciones.regulars.cantRegulars +=
            i.conversation;

          switch (i.conversation_category) {
            case 'AUTHENTICATION':
              dataConversations.tiposConversaciones.regulars.authentications +=
                i.conversation;
              break;
            case 'MARKETING':
              dataConversations.tiposConversaciones.regulars.marketing +=
                i.conversation;
              break;
            case 'SERVICE':
              dataConversations.tiposConversaciones.regulars.service +=
                i.conversation;
              break;
            case 'UTILITY':
              dataConversations.tiposConversaciones.regulars.utilities +=
                i.conversation;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    });

    dataConversations.cantConversacionesGratuitas =
      dataConversations.tiposConversaciones.freeEntries +
      dataConversations.tiposConversaciones.freeTiers;

    return dataConversations;
  }

  const statistics = [
    [
      {
        title: 'Todas las conversaciones',
        value: datosConversaciones.cantConversaciones,
        total: true,
        info: <StatInfo type='allConversations' />
      },
      {
        title: 'Marketing',
        value: datosConversaciones.categoriasConversaciones?.marketing
      },
      {
        title: 'Utilidad',
        value: datosConversaciones.categoriasConversaciones?.utilities
      },
      {
        title: 'Autenticación',
        value: datosConversaciones.categoriasConversaciones?.authentications
      },
      {
        title: 'Servicio',
        value: datosConversaciones.categoriasConversaciones?.service
      }
    ],
    [
      {
        title: 'Conversaciones gratuitas',
        value: datosConversaciones.cantConversacionesGratuitas,
        total: true,
        info: <StatInfo type='freeConversations' />
      },
      {
        title: 'Versión gratuita',
        value: datosConversaciones.tiposConversaciones?.freeTiers,
        info: <StatInfo type='freeTiers' />
      },
      {
        title: 'Punto de acceso gratuito',
        value: datosConversaciones.tiposConversaciones?.freeEntries,
        info: <StatInfo type='freeEntries' />
      }
    ],
    [
      {
        title: 'Conversaciones pagadas',
        value: datosConversaciones.tiposConversaciones?.regulars.cantRegulars,
        valueTwo: `$${datosConversaciones.cargos?.total.toFixed(2)}`,
        total: true,
        info: <StatInfo type='paidConversations' />
      },
      {
        title: 'Marketing',
        value: datosConversaciones.tiposConversaciones?.regulars.marketing,
        valueTwo: `$${datosConversaciones.cargos?.marketing.toFixed(2)}`
      },
      {
        title: 'Utilidad',
        value: datosConversaciones.tiposConversaciones?.regulars.utilities,
        valueTwo: `$${datosConversaciones.cargos?.utilities.toFixed(2)}`
      },
      {
        title: 'Autenticación',
        value:
          datosConversaciones.tiposConversaciones?.regulars.authentications,
        valueTwo: `$${datosConversaciones.cargos?.authentications.toFixed(2)}`
      },
      {
        title: 'Servicio',
        value: datosConversaciones.tiposConversaciones?.regulars.service,
        valueTwo: `$${datosConversaciones.cargos?.service.toFixed(2)}`
      }
    ]
  ];

  return (
    <div className='EstadisticasConversaciones'>
      <div className='Estadisticas-row'>
        <CardEstadisticas
          graphType='conversaciones'
          statistics={statistics[0]}
        />
        <CardEstadisticas
          graphType='conversaciones'
          statistics={statistics[1]}
        />
        <CardEstadisticas
          graphType='conversaciones'
          statistics={statistics[2]}
        />
      </div>
      <div className='Estadisticas-row'>
        <Grafico
          graphType='conversaciones'
          dataPoints={dataPoints}
          datePickerValue={datePickerValue}
        />
      </div>
    </div>
  );
};
