import { Button, Form, Input, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { updateNode } from '../utils/updateNode';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { handleVariablesDisponibles } from '../utils/handleVariablesDisponibles';
import { HeaderForm } from './componentsForm/HeaderForm';

export const BudgetForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes, flowVariables, edges } =
    useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [nombreNodo, setNombreNodo] = useState('');

  const getVariableOptions = () => {
    let array = [];
    if (flowVariables) {
      let vars = handleVariablesDisponibles(
        flowVariables,
        nodoSeleccionado.id,
        true,
        edges
      );

      vars.indexedVars?.forEach((variable) => {
        array.push({ value: variable.name, label: variable.name });
      });

      vars?.regular?.forEach((variable) => {
        if (['number', 'string'].includes(variable.type)) {
          array.push({ value: variable.name, label: variable.name });
        }
      });
    }

    return array;
  };

  const PLACEHOLDER = `Ir al carrito `;

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (!values.clientId) {
      return messageApi.info(
        'Es necesario que seleccione una variable para el cliente'
      );
    }

    if (!values.message) {
      values.message = PLACEHOLDER;
    }

    values.handleCount = 1;

    values.listId = null;
    values.products = null;
    values.phoneNumber = null;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let { data } = nodoSeleccionado;
      setNombreNodo(data.label);

      let message = '';

      if (data.message && !data.message?.startsWith(PLACEHOLDER)) {
        message = data.message;
      }

      form.setFieldsValue({
        clientId: data.clientId,
        message: message
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formTranscribe'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<AiOutlineShoppingCart size={32} />}
      />

      <Form.Item
        label='Variable ID cliente'
        name='clientId'
        rules={[{ required: true, message: 'Campo obligatorio' }]}
      >
        <Select options={getVariableOptions()} />
      </Form.Item>
      <Form.Item label='Mensaje anterior a la URL' name='message'>
        <Input.TextArea placeholder={PLACEHOLDER} />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
