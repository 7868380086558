import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Button, Form, Input, Select, Switch } from 'antd';
import { BoxInfo } from '../../../../../box-info/BoxInfo';
import { UrlParams } from '../../../../../urlParams/UrlParams';
import { RequestHeaders } from '../../../../../requestHeaders/RequestHeaders';
import {
  addBodyItem,
  deleteItemBody,
  handleValueItemBody,
  handleVariableType
} from '../../../../flows/utils/httpRequestBody';
import { FiX } from 'react-icons/fi';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { addUrlParams } from '../../../../../../../utils/addUrlParams';
import { hasSingleVariable, isJSON } from '../../../../flows/utils/httpRequest';
import { testRequest } from '../../../../../../../helpers/fetchData/fetchTestRequest';

export const ContactsLoadGet = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setMostrarRequestDrawer
}) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [method, setMethod] = useState('get');
  const [url, setUrl] = useState('');
  const [params, setParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [hasBody, setHasBody] = useState(false);
  const [hasVars, setHasVars] = useState(false);
  const [bodyVars, setBodyVars] = useState([]);
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [bodyType, setBodyType] = useState('JSON'); // Esto depende de lo especificado en el header Content-Type, por ahora solo maneja JSON.
  const [isValid, setIsValid] = useState(false);

  const [form] = Form.useForm();

  const handleFetch = async () => {
    try {
      let testUrl = url;

      if (params?.length > 0) {
        if (
          params.some((element) => {
            if (!element.key) return true;
            if (!element.value) return true;
          })
        ) {
          messageApi.info('Todo parámetro debe contener una key y value');
          setLoading(false);
          return;
        }

        testUrl = addUrlParams(url, params);
      }

      const testHeaders = {};

      if (headers?.length > 0) {
        if (
          headers.some((element) => {
            if (!element.key) return true;
            if (!element.value) return true;
            return false;
          })
        ) {
          messageApi.info('Todo header debe contener una key y value');
          setLoading(false);
          return;
        }

        if (headers?.length > 0) {
          headers.forEach((header) => {
            testHeaders[header.key] = header.value;
          });
        }
      }

      let bodyData = {};

      if (method === 'post') {
        bodyData.hasBody = hasBody;
        bodyData.hasVars = hasVars;
        bodyData.bodyType = bodyType;

        if (!hasVars) {
          let rawString = body.replace(/'/g, '"').replace(/,\s*}/g, '}');
          if (rawString) {
            bodyData.body = rawString;
          }
        } else {
          if (bodyVars?.length > 0) {
            bodyData.bodyVars = bodyVars;

            let flag = bodyVars.some(
              (element) => !element.key || !element.value || !element.type
            );

            if (flag) {
              return messageApi.info(
                'Cada item del body debe contenener key, value y type'
              );
            }

            if (bodyVars.some((element) => !hasSingleVariable(element.value))) {
              return messageApi.info(
                'Los valores deben contener una variable como máximo'
              );
            }
          } else {
            return messageApi.info('Incluya al menos un item en el body');
          }
        }
      }

      let config = {
        method: method,
        url: testUrl,
        headers: testHeaders
      };

      if (method === 'post' && hasBody) {
        if (isJSON(body) || bodyData.bodyVars.length > 0) {
          if (bodyData.bodyVars.length > 0) {
            let obj = {};
            bodyData.bodyVars.forEach((element) => {
              obj[element.key] = element.value;
            });
            config.data = JSON.stringify(obj);
          } else {
            let obj = JSON.parse(body);

            let string = JSON.stringify(obj);

            config.data = string;
          }
        } else {
          setResult(null);
          messageApi.error('El body ingresado no es un JSON');
          setLoading(false);
          return;
        }

        config.headers['Content-Type'] = 'application/json';
      }

      setTmpConfig((prevState) => {
        let obj = { ...prevState };

        let contactsLoad = { ...obj.contactsLoad };

        contactsLoad.request = {
          method: method,
          url: url,
          params: params,
          headers: headers,

          hasBody: bodyData.hasBody,
          body: bodyData.body,
          hasVars: bodyData.hasVars,
          bodyVars: bodyData.bodyVars,
          bodyType: bodyData.bodyType
        };

        contactsLoad.fullRequest = config;

        obj.contactsLoad = contactsLoad;

        return obj;
      });

      setLoading(true);

      // Endpoint al cual enviarle la config para que haga la peticion:
      testRequest(config, instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setResult(res.data);
            handleStage(1);
            messageApi.success('Petición realizada correctamente');
          } else {
            messageApi.error('No obtuvimos respuesta a la petición');
          }

          setIsValid(true);
        })
        .catch((error) => {
          console.log(error);
          setResult(null);
          messageApi.error(
            'No se pudo probar la petición, asegurese que la URL sea correcta'
          );
          setIsValid(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tmpConfig?.contactsLoad) {
      const { request } = tmpConfig.contactsLoad;

      if (request) {
        setUrl(request.url);

        setMethod(request.method);

        if (request.headers) {
          setHeaders(request.headers);
        }

        if (request.params) {
          setParams(request.params);
        }
      }
    }
  }, []);

  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={handleFetch}
      name='contactsLoadGet'
      className='form-nodos'
    >
      <b style={{ marginBottom: '12px' }}>
        Configuremos cómo cargará los contactos
      </b>
      <span style={{ marginBottom: '24px' }}>
        <BoxInfo
          message={[
            'Configure una petición donde obtendremos un listado de contactos.',
            'La petición deberá responder con un array de contactos o al menos un objeto.'
          ]}
        />
      </span>

      <Form.Item label='Método'>
        <Select
          value={method}
          onChange={setMethod}
          options={[
            { value: 'get', label: 'GET' },
            { value: 'post', label: 'POST' }
          ]}
        />
      </Form.Item>

      <Form.Item
        label='URL'
        rules={[{ required: true, message: 'Campo requerido' }]}
        style={{ marginBottom: 8 }}
      >
        <div className='columna'>
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            type='text'
            onChange={(v) => setUrl(v.target.value)}
            value={url}
          />
        </div>
      </Form.Item>

      <UrlParams
        urlParams={params}
        setUrlParams={setParams}
        modulo='loadContact'
      />
      <div style={{ padding: 8 }}></div>
      <RequestHeaders
        headers={headers}
        setHeaders={setHeaders}
        modulo='loadContact'
      />

      {method !== 'get' && (
        <span
          className='horizontal'
          style={{ margin: '30px 0px 16px', justifyContent: 'space-between' }}
        >
          <span className='horizontal'>
            <p>Body</p>
            <Switch
              onChange={(v) => {
                setHasBody(v);
              }}
              checked={hasBody}
            />
          </span>
          {hasBody && method !== 'get' && (
            <span className='horizontal'>
              <p>JSON</p>
              <Switch checked={hasVars} onChange={(v) => setHasVars(v)} />
              <p>Con variables</p>
            </span>
          )}
        </span>
      )}

      {hasBody && method !== 'get' && (
        <>
          {hasVars ? (
            <div className='variables-body-wrapper'>
              <span className='horizontal variables-body-header-wrapper'>
                <p className='variables-body-header'>Key</p>
                <p className='variables-body-header'>Value</p>
                <p className='variables-body-header'>Type</p>
              </span>

              {bodyVars.map((variable, index) => {
                return (
                  <span className='variable-body-item' key={index}>
                    <span className='columna-body-item'>
                      <Input
                        value={variable.key}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            'key',
                            v.target.value
                          )
                        }
                      />
                    </span>

                    <span className='columna-body-item'>
                      <Input
                        value={variable.value}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            'value',
                            v.target.value
                          )
                        }
                      />
                    </span>

                    <Select
                      value={handleVariableType(variable.type, variable.value)}
                      onChange={(v) =>
                        handleValueItemBody(setBodyVars, index, 'type', v)
                      }
                      options={[
                        { value: 'string', label: 'String' },
                        { value: 'number', label: 'Number' }
                      ]}
                    />

                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteItemBody(setBodyVars, index)}
                    >
                      <FiX />
                    </span>
                  </span>
                );
              })}

              <span
                style={{ width: '100%', display: 'flex', margin: '8px 0px' }}
              >
                <Button
                  className='btn-aceptar btn-oscuro'
                  style={{ margin: '0px 8px 8px auto' }}
                  type='primary'
                  size='small'
                  onClick={() => addBodyItem(setBodyVars)}
                >
                  Agregar item
                </Button>
              </span>
            </div>
          ) : (
            <Form.Item>
              <Input.TextArea
                autoSize={{ minRows: 2 }}
                onChange={(v) => setBody(v.target.value)}
                value={body}
              />
            </Form.Item>
          )}
        </>
      )}

      <span className='horizontal' style={{ marginTop: 24 }}>
        <BoxInfo
          message={
            'Al elegir una variable obtenida a traves de una petición, asegúrese que sea tipo "String" o "Number", de otro modo podría fallar.'
          }
        />
      </span>

      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            //disabled={true}
            style={{
              opacity: loading ? 0.65 : 1,
              backgroundColor: 'var(--dark-color) !important'
            }}
            icon={loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null}
          >
            Probar
          </Button>

          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarRequestDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
