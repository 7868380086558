import { Input } from "antd";
import { useCallback, useEffect, useState } from "react";

export const SearchInput = ({
  element,
  setSearch,
  setPage,
  loading = false,
  maxWidth = 250,
}) => {
  const [innerSearchValue, setInnerSearchValue] = useState("");

  const debouncedSearch = useCallback(
    (value) => {
      setSearch(value);
      setPage(1);
    },
    [setSearch, setPage]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedSearch(innerSearchValue);
    }, 500); // Debounce delay of 500ms

    // Si el usuario vuelve a escribir antes de 500ms, se cancela el seteo de search (deboucedSearch)
    return () => clearTimeout(handler);
  }, [innerSearchValue, debouncedSearch]);

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInnerSearchValue(value);
  };

  const handleOnSearch = (value) => {
    setInnerSearchValue(value);
    debouncedSearch(value);
  };

  return (
    <Input.Search
      placeholder={`Buscar ${element}...`}
      value={innerSearchValue}
      onChange={handleChange}
      loading={loading}
      allowClear
      onSearch={(value) => handleOnSearch(value)}
      style={{ maxWidth }}
    />
  );
};
