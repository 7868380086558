export const getColorVerification = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.name_status) {
    switch (businessCompliance?.name_status) {
      case 'APPROVED':
        return 'var(--primary-color)';
      case 'AVAILABLE_WITHOUT_REVIEW':
        return 'var(--primary-color)';
      case 'DECLINED':
        return 'red';
      case 'EXPIRED':
        return 'red';
      case 'PENDING_REVIEW':
        return 'orange';
      default:
        return 'orange';
    }
  }

  return 'black';
};

export const getTitleVerification = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.name_status) {
    switch (businessCompliance?.name_status) {
      case 'APPROVED':
        return 'Aprobado';
      case 'AVAILABLE_WITHOUT_REVIEW':
        return 'Disponible sin revisión';
      case 'DECLINED':
        return 'Desaprobado';
      case 'EXPIRED':
        return 'Vencido';
      case 'PENDING_REVIEW':
        return 'Pendiente de revisión';
      default:
        return 'No verificado';
    }
  }

  return 'Error';
};
