import { Input, Tag, Tooltip } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlowContext } from "../../../context/FlowContext";
import { handleVariablesDisponibles } from "./utils/handleVariablesDisponibles";
import { FiX } from "react-icons/fi";
import {
  BROADCAST_VARIABLES,
  CONTACT_VARIABLES,
  CONTEXT_VARIABLES,
} from "./utils/contextVariables";
import { handleVariablesIndexadas } from "./utils/handleVariablesIndexadas";
import { GlobalContext } from "../../../context/GlobalContext";

const { Search } = Input;

export const SelectVariables = ({
  decorarTexto,
  context = "text",
  seccion,
  nodeId,
  isBranch = false,
  setShowPopover,
  index,
  showContextVars = true,
  excludedTypes = [],
  lookingForTypes = [],
  indexedVars = false,
  selectedVariable = null,
  arrayIndex,
  variableConvertir = null
}) => {
  const {
    flowVariables = [],
    nodoSeleccionado,
    setNodoSeleccionado,
    edges,
    type,
    flowAEditar,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [variablesDisponibles, setVariablesDisponibles] = useState();
  const [busqueda, setBusqueda] = useState("");

  const handleContext = (variable) => {
    if (!isBranch) {
      // context = "iterableList";
      // seccion = "title";
      // decorarTexto = { decorarItemLista };
      setShowPopover(false);
      switch (context) {
        case "text":
          return decorarTexto("${", "", variable, index);
        case "buttons":
          return decorarTexto(seccion, "${", "", variable);
        case "list":
          return decorarTexto(seccion, "${", "", variable);
        case "iterableList":
          return decorarTexto(seccion, variable);
        case "location":
          return decorarTexto(seccion, variable);
        case "request":
          // Se usa en RequestHeaders y UrlParams.
          return decorarTexto(variable, arrayIndex);
        default:
          return;
      }
    } else {
      setShowPopover(false);
      let tmp = { ...nodoSeleccionado };

      tmp.data.variable = variable;
      
      //Bloque convertir
      if (seccion === 'convertir') {
        variableConvertir(variable);
      } else {
        setNodoSeleccionado(tmp);
      }
      
    }
  };

  useEffect(() => {
    if (!nodeId) {
      return;
    }

    if (context === "location") {
      setVariablesDisponibles(
        handleVariablesDisponibles(
          flowVariables,
          nodeId,
          isBranch,
          edges,
          lookingForTypes
        )
      );
    } else {
      if (!indexedVars) {
        setVariablesDisponibles(
          handleVariablesDisponibles(
            flowVariables,
            nodeId,
            isBranch,
            edges,
            lookingForTypes,
            excludedTypes,
            lookingForTypes
          )
        );
      } else {
        setVariablesDisponibles(
          handleVariablesIndexadas(
            flowVariables,
            nodeId,
            edges,
            selectedVariable,
            messageApi
          )
        );
      }
    }
  }, [flowVariables]);

  const filteredVars = useMemo(() => {

    const context_variables = nodeId ? CONTEXT_VARIABLES : CONTEXT_VARIABLES?.filter(
      (element) =>
        !["context_name", "context_phoneNumber"].includes(element.name)
    )

    let filteredObj = {
      broadcast: BROADCAST_VARIABLES,
      contact: CONTACT_VARIABLES,
      context: context_variables,
      indexedVars: variablesDisponibles?.indexedVars || [],
      regularVars: variablesDisponibles?.regular || [],
    };

    if (busqueda) {
      const lowerSearch = busqueda.toLowerCase();

      filteredObj.contact = CONTACT_VARIABLES.filter((element) => {
        if (element.name?.toLowerCase()?.includes(lowerSearch)) return true;
        if (element.label?.toLowerCase()?.includes(lowerSearch)) return true;
        return false;
      });

      filteredObj.context = context_variables.filter((element) => {
        if (element.name?.toLowerCase()?.includes(lowerSearch)) return true;
        if (element.label?.toLowerCase()?.includes(lowerSearch)) return true;
        return false;
      });

      filteredObj.broadcast = BROADCAST_VARIABLES.filter((element) => {
        if (element.name?.toLowerCase()?.includes(lowerSearch)) return true;
        if (element.label?.toLowerCase()?.includes(lowerSearch)) return true;
        return false;
      });

      if (variablesDisponibles?.regular?.length > 0) {
        filteredObj.regularVars = variablesDisponibles.regular.filter(
          (element) => {
            if (element.name?.toLowerCase()?.includes(lowerSearch)) return true;
            return false;
          }
        );
      }

      if (variablesDisponibles?.indexedVars?.length > 0) {
        filteredObj.indexedVars = variablesDisponibles.indexedVars.filter(
          (element) => {
            if (element.name?.toLowerCase()?.includes(lowerSearch)) return true;
            return false;
          }
        );
      }
    }

    return filteredObj;
  }, [busqueda, variablesDisponibles]);

  return (
    <div className="variables-wrapper">
      <span className="horizontal" style={{ marginBottom: 8 }}>
        <Search
          placeholder="Buscar"
          value={busqueda}
          onChange={(v) => setBusqueda(v.target.value)}
        />
        <span style={{ height: 18, width: 18 }}>
          <FiX
            size={18}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={() => setShowPopover(false)}
          />
        </span>
      </span>
      {showContextVars && (
        <>
          <div
            style={{ borderBottom: "1px solid #d8d8d8", paddingBottom: "8px" }}
          >
            <span className="horizontal" style={{ marginBottom: "6px" }}>
              <p>Variables de contexto</p>
            </span>
            <span className="variables-linea">
              {filteredVars.context?.map((variable, index) => {
                return (
                  <Tooltip
                    placement="topRight"
                    title={variable.name}
                    key={index}
                  >
                    <Tag
                      color="green"
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={() => handleContext(variable, index)}
                      key={variable.name}
                    >
                      {variable.label}
                    </Tag>
                  </Tooltip>
                );
              })}
            </span>
          </div>
          <div
            style={{ borderBottom: "1px solid #d8d8d8", paddingBottom: "8px" }}
          >
            <span className="horizontal" style={{ marginBottom: "6px" }}>
              <p>Variables de contacto</p>
            </span>
            <span className="variables-linea">
              {filteredVars.contact?.map((variable, index) => {
                variable = { ...variable, name: variable.value };
                return (
                  <Tooltip
                    placement="topRight"
                    title={variable.name}
                    key={index}
                  >
                    <Tag
                      color="green"
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={() => handleContext(variable, index)}
                      key={variable.name}
                    >
                      {variable.label || variable.name}
                    </Tag>
                  </Tooltip>
                );
              })}
            </span>
          </div>
          {(flowAEditar?.flowData?.type === "broadcast" ||
            type === "broadcast") && (
            <div
              style={{
                borderBottom: "1px solid #d8d8d8",
                paddingBottom: "8px",
              }}
            >
              <span className="horizontal" style={{ marginBottom: "6px" }}>
                <p>Variables de campañas</p>
              </span>
              <span className="variables-linea">
                {filteredVars.broadcast?.map((variable, index) => {
                  variable = { ...variable, name: variable.value };
                  return (
                    <Tooltip
                      placement="topRight"
                      title={variable.name}
                      key={index}
                    >
                      <Tag
                        color="green"
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => handleContext(variable, index)}
                        key={variable.name}
                      >
                        {variable.label || variable.name}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </span>
            </div>
          )}
        </>
      )}
      {variablesDisponibles?.regular?.length > 0 && (
        <div style={{ paddingBottom: "8px" }}>
          <span className="horizontal" style={{ marginBottom: "6px" }}>
            <p>Variables del flujo</p>
            {/* {!showContextVars && (
              <FiX
                size={16}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => setShowPopover(false)}
              />
            )} */}
          </span>
          <span className="variables-linea">
            {filteredVars.regularVars?.map((variable, index) => {
              return (
                <Tooltip placement="topRight" title={variable.name} key={index}>
                  <Tag
                    color="green"
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => handleContext(variable, index)}
                    key={variable.name}
                  >
                    {variable.name}
                  </Tag>
                </Tooltip>
              );
            })}
          </span>
        </div>
      )}
      {variablesDisponibles?.indexedVars?.length > 0 && (
        <div>
          <span className="horizontal">
            <p style={{ marginBottom: "6px" }}>Variables indexadas</p>
            {/* {indexedVars && (
              <FiX
                size={16}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => setShowPopover(false)}
              />
            )} */}
          </span>
          <span className="variables-linea">
            {filteredVars.indexedVars?.map((variable, index) => {
              return (
                <Tooltip placement="topRight" title={variable.name} key={index}>
                  <Tag
                    color="green"
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => handleContext(variable, index)}
                    key={variable.name}
                  >
                    {variable.name}
                  </Tag>
                </Tooltip>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
};
