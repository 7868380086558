// Función para formatear los datos del contacto (nuevo o editado) previo a enviar el formulario

import dayjs from 'dayjs';
import { filterAdditionalFields } from './filterAdditionalFields';

export const formatContactData = (data, additionalFields) => {
  const {
    birthday,
    city,
    company,
    country,
    department,
    email,
    first_name,
    last_name,
    middle_name,
    phone,
    state,
    street,
    tags,
    title,
    type,
    zip,
    clients,
    ...rest
  } = data;

  let newData = {
    addresses: [
      {
        country: country || '',
        state: state || '',
        city: city || '',
        street: street || '',
        zip: zip || '',
        type: type || 'HOME'
      }
    ],
    birthday: birthday ? dayjs(birthday).format('DD/MM/YYYY') : '',
    emails: [
      {
        email: email || '',
        type: 'WORK'
      }
    ],
    name: {
      first_name: first_name || '',
      last_name: last_name || '',
      middle_name: middle_name || ''
    },
    org: {
      company: company || '',
      department: department || '',
      title: title || ''
    },
    phones: [
      {
        type: 'WORK',
        phone: phone || ''
      }
    ],
    tags,
    clients
  };

  const restValues = filterAdditionalFields(rest, additionalFields);

  newData = { ...newData, ...restValues };

  return newData;
};
