import { ButtonNode } from "./customNodes/ButtonNode";
import { ContactsNode } from "./customNodes/ContactsNode";
import { ScheduleNode } from "./customNodes/ScheduleNode";
import { TemplateNode } from "./customNodes/TemplateNode";

export const nodeTypes = {
  contacts: ContactsNode,
  template: TemplateNode,
  button: ButtonNode,
  schedule: ScheduleNode,
};
