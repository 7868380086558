export const getProductTooltipTitle = (item, lists) => {
  if (item && lists) {
    let itemName = item.sku || "Sin SKU";
    let listName =
      lists.find((element) => element.id === item.listId)?.name || "Sin lista";

    return `${itemName} - ${listName}`;
  } else {
    return "";
  }
};
