import axios from "axios";
import { Base64 } from "js-base64";

export const getPDFPresupuesto = async (data) => {
  try {
    data = Base64.encode(JSON.stringify(data), true);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://us-central1-apiplatformwp.cloudfunctions.net/pdfExport`,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      data: JSON.stringify({
        url: "https://storage.googleapis.com/brocoly/64c90e320dcc24527926cdd8/public/presupuesto.html", // URL html presupuesto
        payload: data,
      }),
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const downloadPDF = (data) => {
  const blob = new Blob([data], { type: "application/pdf" });

  // Crea una URL del blob
  const url = window.URL.createObjectURL(blob);

  // Crea un elemento anchor
  const a = document.createElement("a");
  a.href = url;

  // Setea el atributo download y el nombre con el que se va a descargar
  a.download = "presupuesto.pdf";

  // Dispara la descarga
  a.click();

  // Libera el objeto URL
  window.URL.revokeObjectURL(url);
};
