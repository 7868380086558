import { useState } from 'react';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { OpenAi } from './OpenAI/OpenAi';
// import { MercadoPago } from "./MercadoPago";
import { Options } from './Options';
import { EmailIntegration } from './email/EmailIntegration';

export const Integrations = () => {
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const handleContent = () => {
    switch (selectedIntegration) {
      case 'openai':
        return <OpenAi setSelectedIntegration={setSelectedIntegration} />;
      case 'email':
        return (
          <EmailIntegration setSelectedIntegration={setSelectedIntegration} />
        );
      // case "mercadopago":
      //   return <MercadoPago setSelectedIntegration={setSelectedIntegration} />;
      default:
        return <Options setSelectedIntegration={setSelectedIntegration} />;
    }
  };

  return (
    <div className='config-main-wrapper'>
      <div className='test-main-header'>
        <span
          className='horizontal'
          style={{ justifyContent: 'space-between' }}
        >
          <span className='columna' style={{ gap: '0px' }}>
            <span className='config-header-inline'>
              <span style={{ height: '24px' }}>
                <AiOutlinePlusSquare size={24} color='var(--primary-color)' />
              </span>
              <span className='header-title-templates'>Integraciones</span>
            </span>
            <p className='header-subtitle'>Administre sus servicios externos</p>
          </span>
        </span>
      </div>
      <div className='tabla-config' style={{ marginLeft: '36px' }}>
        {handleContent()}
      </div>
    </div>
  );
};
