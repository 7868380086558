import axios from 'axios';

export const getGroupReplies = async (key) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/getGroupReplies`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newMessageCategory = async (key, data) => {
  try {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/newCategory`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newSavedMessage = async (key, data) => {
  try {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/newSavedReply`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editSavedMessage = async (key, id, data) => {
  try {
    let config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/editSavedReplies`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSavedMessage = async (key, id) => {
  try {
    let config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/deleteSavedReplies/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateCategory = async (key, data) => {
  try {
    let config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/updateCategory`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = async (key, id) => {
  try {
    let config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_PRODUCCION}/savedReplies/deleteCategory/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: key
      }
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
