export const newFallbackEdge = (nodoSeleccionadoId, target, setEdges) => {
  let id = `fallback-${nodoSeleccionadoId}`;
  let newEdge = {
    id,
    source: nodoSeleccionadoId,
    target: target,
    sourceHandle: "fallback",
    type: "fallback",
    animated: true,
  };

  setEdges((prevState) => {
    let updatedEdges = [...prevState];

    let flag = false;

    // Si existe actualizo
    for (let i = 0; i < updatedEdges.length; i++) {
      if (updatedEdges[i].id === id) {
        updatedEdges[i] = newEdge;
        flag = true;
      }
    }

    // Si no existe lo creo
    if (!flag) {
      updatedEdges.push(newEdge);
    }

    return updatedEdges;
  });
};
