import { Drawer, Form, Row, Input, Col, Button } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { newTicketType } from "../../../../../../helpers/fetchData/fetchTickets";

export const DrawerNuevoTipoTicket = ({
  showDrawerNuevo,
  setShowDrawerNuevo,
  setTiposTicket,
}) => {
  const { TextArea } = Input;

  const { messageApi, instance } = useContext(GlobalContext);

  const [loadingNuevo, setLoadingNuevo] = useState(false);

  const [formNuevoTipoTicket] = Form.useForm();

  function onClose() {
    setShowDrawerNuevo(false);
  }

  function onFinish(form) {
    setLoadingNuevo(true);

    const data = {
      name: form.name,
      description: form.description ?? "",
      color: form.color,
    };

    newTicketType(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setTiposTicket((prevState) => {
            let newArray = [...prevState];

            const _id = res.data._id;
            const key = res.data._id;

            const newItem = { ...form, _id, key };

            newArray.push(newItem);

            return newArray;
          });
          messageApi.success("Nuevo tipo de ticket creado");
          onClose();
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("Error al crear el tipo de ticket");
      })
      .finally(() => setLoadingNuevo(false));
  }

  return (
    <Drawer
      title="Nuevo tipo de ticket"
      width={500}
      placement="right"
      styles={{ header: { display: "none" }, body: { padding: "0px" } }}
      open={showDrawerNuevo}
      onClose={onClose}
      destroyOnClose
    >
      <Form
        className="form-nodos"
        name="nuevoTipoTicket"
        form={formNuevoTipoTicket}
        requiredMark="optional"
        layout="vertical"
        onFinish={onFinish}
        style={{ padding: 24 }}
        preserve={false}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Nuevo tipo de ticket
        </p>

        <Row style={{ justifyContent: "space-between" }}>
          <Col span={16}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
            >
              <Input
                size="large"
                maxLength={25}
                showCount
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label="Color"
              name="color"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
              initialValue="#000000"
            >
              <Input type="color" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Descripción" name="description">
          <TextArea
            size="large"
            autoSize={{ minRows: 4 }}
            maxLength={150}
            showCount
          />
        </Form.Item>

        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loadingNuevo}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={onClose}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
