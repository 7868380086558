import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { Form, Input, Select } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import { HeaderForm } from './componentsForm/HeaderForm';
import { FooterForm } from './componentsForm/FooterForm';
import { GlobalContext } from '../../../../context/GlobalContext';
import { updateNode } from '../utils/updateNode';
import { agregarVariableWhatsapp } from '../../../../../helpers/utilidadesTemplates';

export const SendEmailForm = () => {
  const { messageApi } = useContext(GlobalContext);
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const [nombreNodo, setNombreNodo] = useState('');
  const [subjectText, setSubjectText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [receivers, setReceivers] = useState([]);

  const [sendEmailForm] = Form.useForm();

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);
      setSubjectText(data.subject);
      setBodyText(data.body);
      setReceivers(data.receivers);
    }
  }, [nodoSeleccionado]);

  /* CONTACTOS QUE TIENEN EMAIL:
  const destinatariosOptions = contactos
    .filter((contacto) => contacto.emails[0].email)
    .map((contacto) => ({
      label: getContactName(contacto.name),
      value: contacto.emails[0].email
    }));
  */

  const receiversOptions = [
    {
      label: 'Email del contacto',
      value: 'contact_email'
    }
  ];

  function decorarTexto(seccion, wrappingString, emoji, variable) {
    let textArea = document.getElementById(seccion);

    if (textArea) {
      if (wrappingString === '${') {
        switch (seccion) {
          case 'inputSubject':
            textArea.value = agregarVariableWhatsapp(
              textArea.value,
              variable,
              'inputSubject'
            );
            break;
          case 'inputBody':
            textArea.value = agregarVariableWhatsapp(
              textArea.value,
              variable,
              'inputBody'
            );
            break;
          default:
            break;
        }
      }

      switch (seccion) {
        case 'inputSubject':
          setSubjectText(textArea.value);
          break;
        case 'inputBody':
          setBodyText(textArea.value);
          break;
        default:
          break;
      }
    }
  }

  function handleFinish(values) {
    if (!subjectText) {
      messageApi.info(
        'Es necesario que ingrese el asunto del correo electrónico'
      );
      return;
    }

    if (!bodyText) {
      messageApi.info(
        'Es necesario que ingrese el cuerpo del correo electrónico'
      );
      return;
    }

    let isValidEmails = true;
    if (receivers.length > 0) {
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

      receivers.forEach((destinatario) => {
        const isValid = emailRegex.test(destinatario);

        if (destinatario === 'contact_email') {
          return;
        }

        if (!isValid) {
          messageApi.info(
            `El destinatario "${destinatario}" no es un correo electrónico válido`
          );
          isValidEmails = false;
          return;
        }
      });
    } else {
      messageApi.info('Es necesario que ingrese al menos un destinatario');
      return;
    }

    if (!isValidEmails) {
      return;
    }

    values.label = nombreNodo;
    values.handleCount = 1;
    values.subject = subjectText;
    values.body = bodyText;
    values.receivers = receivers;
    console.log('Formulario enviado', values);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  }

  return (
    <Form
      className='form-nodos'
      form={sendEmailForm}
      layout='vertical'
      name='send-email-form'
      requiredMark={false}
      onFinish={handleFinish}
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<AiOutlineMail size={32} />}
      />

      <Form.Item
        label={<span className='label-secciones'>Asunto</span>}
        name='subject'
      >
        <Input.TextArea
          id='inputSubject'
          className='input-text-area'
          placeholder='Escribir texto'
          autoSize={{ minRows: 2 }}
          value={subjectText}
          onChange={(e) => setSubjectText(e.target.value)}
        />
        <div
          className='contenedor-botones-texto'
          style={{
            marginTop: '4px',
            justifyContent: 'flex-end'
          }}
        >
          <BotonAgregarVariable
            decorarTexto={decorarTexto}
            nodeId={nodoSeleccionado.id}
            seccion='inputSubject'
            context='buttons'
          />
        </div>
      </Form.Item>

      <Form.Item
        label={<span className='label-secciones'>Cuerpo</span>}
        name='body'
      >
        <Input.TextArea
          id='inputBody'
          className='input-text-area'
          placeholder='Escribir texto'
          autoSize={{ minRows: 3 }}
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
        />
        <div
          className='contenedor-botones-texto'
          style={{
            marginTop: '4px',
            justifyContent: 'flex-end'
          }}
        >
          <BotonAgregarVariable
            decorarTexto={decorarTexto}
            nodeId={nodoSeleccionado.id}
            seccion='inputBody'
            context='buttons'
          />
        </div>
      </Form.Item>

      <Form.Item
        label={<span className='label-secciones'>Destinatario(s)</span>}
        name='receivers'
      >
        <Select
          mode='tags'
          tokenSeparators={[',']}
          options={receiversOptions}
          onChange={(v) => setReceivers(v)}
          value={receivers}
          defaultValue={nodoSeleccionado.data.receivers}
        />
      </Form.Item>

      <FooterForm setMostrarDrawer={setMostrarDrawer} />
    </Form>
  );
};
