import { useState } from "react";
import "./budgetConfigSteps/budgetConfig.css";
// import { ProductsLoad } from "./budgetConfigSteps/ProductsLoad";
// import { StockManagement } from "./budgetConfigSteps/StockManagement";
// import { ProductsLoadExample } from "./budgetConfigSteps/ProductsLoadExample";
import { ProductsLoadGet } from "./budgetConfigSteps/ProductsLoadGet";
import { ProductsLoadGetExample } from "./budgetConfigSteps/ProductsLoadGetExample";

const INITIAL_CONFIG = {
  productsLoad: {},
  stockManagement: { type: "dedicated" },
  output: { type: "api" },
};

export const BudgetConfig = ({ budgetConfig, setBudgetConfig, closeModal }) => {
  // carga productos, gestion stock, estados, orden.
  const [tmpConfig, setTmpConfig] = useState(budgetConfig || INITIAL_CONFIG);
  const [result, setResult] = useState(null);
  const [stage, setStage] = useState(0);

  const handleStage = (number) => {
    setStage(number);
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return (
          <ProductsLoadGet
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
          />
        );
      case 1:
        return (
          <ProductsLoadGetExample
            handleStage={handleStage}
            tmpConfig={tmpConfig}
            setTmpConfig={setTmpConfig}
            result={result}
            setResult={setResult}
            closeModal={closeModal}
            setBudgetConfig={setBudgetConfig}
          />
        );
      // case 1:
      //   return (
      //     <ProductsLoad
      //       handleStage={handleStage}
      //       tmpConfig={tmpConfig}
      //       setTmpConfig={setTmpConfig}
      //     />
      //   );
      // case 1:
      //   return (
      //     <ProductsLoadExample
      //       handleStage={handleStage}
      //       tmpConfig={tmpConfig}
      //     />
      //   );
      // case 2:
      //   return (
      //     <StockManagement
      //       handleStage={handleStage}
      //       tmpConfig={tmpConfig}
      //       setTmpConfig={setTmpConfig}
      //     />
      //   );
      default:
        return <></>;
    }
  };

  return <div>{renderStage()}</div>;
};
