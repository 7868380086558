import { Base64 } from "js-base64";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Button, Table } from "antd";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { getCampaignLogs } from "../../../../../helpers/fetchData/fetchCampaigns";
import { campaignLogsColumn } from "./columns";

export const CampaignLogs = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const location = useLocation();

  const navigate = useNavigate();

  const [campaignData, setCampaignData] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    navigate("/mensajes");
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      try {
        const search = location.search;
        const data = new URLSearchParams(search).get("payload");

        if (data) {
          let campaña = JSON.parse(Base64.decode(data));

          if (campaña?._id) {
            setCampaignData(campaña);
            setLoading(true);
            getCampaignLogs(instance.userData.apiKey, campaña._id)
              .then((res) => {
                if (res?.status === 200 && Array.isArray(res?.data)) {
                  setLogs(res.data);
                } else {
                  messageApi.error(
                    "Ocurrió un error al obtener logs de la campaña"
                  );
                }
              })
              .catch((error) => {
                console.log("error", error);
                messageApi.error(
                  "Ocurrió un error al obtener logs de la campaña"
                );
              })
              .finally(() => setLoading(false));
          }
        }
      } catch (error) {
        console.log(error);
        messageApi.error("No se pudo obtener data de la campaña");
      }
    }
  }, [instance]);

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>{`Logs - ${campaignData?.name}`}</span>
        <Button onClick={handleGoBack} className="boton-secundario">Volver</Button>
      </div>
      <Table
        columns={campaignLogsColumn(setLogs)}
        dataSource={logs || []}
        rowKey="_id"
        size="small"
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} logs`,
        }}
        className="tabla-generica"
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size="large" />,
        }}
      />
    </div>
  );
};
