import { Button, Tag, Tooltip } from 'antd';
import { handleStatus } from '../columns';
import { useLocation, useNavigate } from 'react-router-dom';
import { CampaignDataSummary } from './CampaignDataSummary';
import { CampaignStatCard } from '../campaignStats/CampaignStatCard';
import { useContext, useEffect, useState } from 'react';
import { CampaignDataDonut } from './CampaignDataDonut';
import { CampaignDataTime } from './CampaignDataTime';
import { GlobalContext } from '../../../../context/GlobalContext';
import {
  getCampaignData,
  getRecipientsData
} from '../../../../../helpers/fetchData/fetchCampaigns';
import { Base64 } from 'js-base64';
import dayjs from 'dayjs';
import { CampaignDataRecipientsTable } from './CampaignDataRecipientsTable';
import LoadingIcon from '../../../Loader/LoadingIcon';
import { getTasa } from '../utils';
import { multipleContactsByIds } from '../../../../../helpers/fetchData/fetchContacts';
import { getUserById } from '../../../../../helpers/fetchData/fetchUsers';
import { GrPowerReset } from 'react-icons/gr';

export const CampaignData = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [filter, setFilter] = useState('all');
  const [item, setItem] = useState({});
  const [campaignData, setCampaignData] = useState({});
  const [loading, setLoading] = useState(false);
  const [recipientsData, setRecipientsData] = useState([]);
  const [interactionsData, setInteractionsData] = useState([]);
  const [allRecipientsData, setAllRecipientsData] = useState([]);
  const [loadingRecipients, setLoadingRecipients] = useState(false);
  const [author, setAuthor] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // Decodifica la información pasada por URL:
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedData = searchParams.get('data');

    if (encodedData) {
      const decodedData = JSON.parse(Base64.decode(encodedData));
      setItem(decodedData);
    }
  }, [location.search]);

  useEffect(() => {
    if (instance?.userData?.apiKey && item?._id) {
      setLoading(true);
      getCampaignData(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setCampaignData(res.data);
          } else {
            messageApi.error('No se pudo cargar los datos de la campaña');
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de la campaña');
          console.error(err);
        })
        .finally(() => setLoading(false));
    }
  }, [instance, item]);

  useEffect(() => {
    if (instance?.userData?.apiKey && item?.recipients) {
      setLoadingRecipients(true);
      multipleContactsByIds(instance.userData.apiKey, item.recipients)
        .then((res) => {
          if (res?.status === 200) {
            setRecipientsData(res.data);
          } else {
            messageApi.error(
              'No se pudo cargar los datos de los destinatarios'
            );
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de los destinatarios');
          console.error(err);
        })
        .finally(() => setLoadingRecipients(false));
    }
  }, [instance, item]);

  useEffect(() => {
    if (instance?.userData?.apiKey && item?._id) {
      setLoadingRecipients(true);
      getRecipientsData(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setInteractionsData(res.data);
          } else {
            messageApi.error(
              'No se pudo cargar los datos de los destinatarios'
            );
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de los destinatarios');
          console.error(err);
        })
        .finally(() => setLoadingRecipients(false));
    }
  }, [instance, item]);

  useEffect(() => {
    if (instance?.userData?.apiKey && item?.createdBy) {
      getUserById(instance, item.createdBy)
        .then((res) => {
          if (res?.status === 200) {
            setAuthor(res.data);
          } else {
            messageApi.error('No se pudo obtener el autor de la campaña');
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo obtener el autor de la campaña');
          console.error(err);
        });
    }
  }, [instance, item]);

  useEffect(() => {
    if (recipientsData.length) {
      const combinedData = recipientsData.map((recipient) => {
        const interactions = interactionsData.find(
          (interaction) => interaction._id === recipient._id
        );

        return {
          ...recipient,
          ...(interactions || {})
        };
      });

      setAllRecipientsData(combinedData);
    }
  }, [recipientsData, interactionsData]);

  const {
    cantDelivered = 0,
    cantRead = 0,
    cantRecipients = 0,
    cantSent = 0,
    cantFailed = 0
  } = campaignData.stats || {};

  const replies = campaignData.stats?.cantReplies?.replies;

  const updateCampaignData = () => {
    if (instance?.userData?.apiKey && item?._id && item?.recipients) {
      setLoadingUpdate(true);

      // Cargar datos de campaña:
      setLoading(true);
      getCampaignData(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setCampaignData(res.data);
          } else {
            messageApi.error('No se pudo cargar los datos de la campaña');
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de la campaña');
          console.error(err);
        })
        .finally(() => setLoading(false));

      // Cargar datos de destinatarios:
      setLoadingRecipients(true);
      multipleContactsByIds(instance.userData.apiKey, item.recipients)
        .then((res) => {
          if (res?.status === 200) {
            setRecipientsData(res.data);
          } else {
            messageApi.error(
              'No se pudo cargar los datos de los destinatarios'
            );
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de los destinatarios');
          console.error(err);
        })
        .finally(() => setLoadingRecipients(false));

      // Cargar datos de interacciones de destinatarios:
      setLoadingRecipients(true);
      getRecipientsData(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setInteractionsData(res.data);
          } else {
            messageApi.error(
              'No se pudo cargar los datos de los destinatarios'
            );
          }
        })
        .catch((err) => {
          messageApi.error('No se pudo cargar los datos de los destinatarios');
          console.error(err);
        })
        .finally(() => setLoadingRecipients(false));

      setLoadingUpdate(false);
    }
  };

  const getTemplateTypeTag = () => {
    if (campaignData?.templateCategory) {
      let tagProps = {};

      switch (campaignData?.templateCategory) {
        case 'MARKETING':
          tagProps = {
            children: 'MARKETING',
            color: 'blue'
          };
          break;
        case 'UTILITY':
          tagProps = {
            children: 'UTILIDAD',
            color: 'orange'
          };
          break;
        case 'AUTHENTICATION':
          tagProps = {
            children: 'AUTENTICACIÓN',
            color: 'black'
          };
          break;
        case 'SERVICE':
          tagProps = {
            children: 'SERVICIO',
            color: 'golden'
          };
          break;
        default:
          return null;
      }

      return <Tag {...tagProps} />;
    }

    return null;
  };

  return loading ? (
    <span
      style={{
        height: '100%',
        width: '100%',
        display: 'grid',
        placeItems: 'center'
      }}
    >
      <LoadingIcon size='mainLoader' />
    </span>
  ) : (
    <div className='CampaignData'>
      <div className='campaign-data-main-container'>
        <div className='campaign-data-header'>
          <div className='campaign-data-name-container'>
            <span className='campaign-data-name'>
              {campaignData.campaignName}
            </span>

            {getTemplateTypeTag()}
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <Tooltip title='Actualizar'>
              <span>
                {loadingUpdate ? (
                  <LoadingIcon size='default' />
                ) : (
                  <GrPowerReset
                    size={18}
                    className='pointer'
                    onClick={updateCampaignData}
                  />
                )}
              </span>
            </Tooltip>

            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={() => navigate('/mensajes')}
            >
              Volver
            </Button>
          </div>
        </div>

        <div className='campaign-data-section-container'>
          {/* <div className='campaign-data-section-title'>Estadísticas</div> */}
          <div className='campaign-data-stat-container'>
            <CampaignStatCard
              isInteractive
              stat='recipients'
              data={cantRecipients}
              onClick={() => setFilter('all')}
              isSelected={filter === 'all'}
            />
            <CampaignStatCard
              isInteractive
              stat='sent'
              extra={`Tasa de envío: ${getTasa(cantSent, cantRecipients)}%`}
              data={cantSent}
              onClick={() => setFilter('sent')}
              isSelected={filter === 'sent'}
            />
            <CampaignStatCard
              isInteractive
              stat='delivered'
              extra={`Tasa de entrega: ${getTasa(cantDelivered, cantSent)}%`}
              data={cantDelivered}
              onClick={() => setFilter('delivered')}
              isSelected={filter === 'delivered'}
            />
            <CampaignStatCard
              isInteractive
              stat='read'
              extra={`Tasa de lectura: ${getTasa(cantRead, cantDelivered)}%`}
              data={cantRead}
              onClick={() => setFilter('read')}
              isSelected={filter === 'read'}
            />
            {campaignData.hasButtons && (
              <CampaignStatCard
                isInteractive
                stat='replied'
                extra={`Tasa de respuesta: ${getTasa(
                  campaignData.stats?.cantReplies?.total,
                  cantSent
                )}%`}
                data={campaignData.stats?.cantReplies?.total}
                onClick={() => setFilter('replied')}
                isSelected={filter === 'replied'}
              />
            )}
            <CampaignStatCard
              isInteractive
              stat='failed'
              extra={`Tasa de falla: ${getTasa(cantFailed, cantRecipients)}%`}
              data={cantFailed}
              onClick={() => setFilter('failed')}
              isSelected={filter === 'failed'}
            />
          </div>
        </div>

        <div className='campaign-data-section-container'>
          <CampaignDataRecipientsTable
            filter={filter}
            data={allRecipientsData}
            loading={loadingRecipients}
            campaignData={campaignData}
          />
        </div>
      </div>

      <div className='campaign-data-side-container'>
        <span className='campaign-data-side-title'>Análisis de campaña</span>

        <div className='campaign-data-side-info'>
          <CampaignDataSummary
            label='Fecha programada'
            value={campaignData.schedule?.[0]?.date}
          />
          <CampaignDataSummary
            label='Fecha de creación'
            value={dayjs(campaignData.createdAt).format('DD/MM/YY HH:mm')}
          />
          <CampaignDataSummary
            label='Plantilla'
            value={campaignData.templateName}
          />
          <CampaignDataSummary
            label='Estado'
            value={handleStatus(campaignData.campaignStatus)}
          />
          <CampaignDataSummary
            label='Creado por'
            value={author ? author.name : '-'}
          />
          <CampaignDataSummary
            label='Costo estimado'
            value={
              campaignData.estimatedCost
                ? `$ ${campaignData.estimatedCost.toFixed(2)}`
                : '-'
            }
          />
        </div>

        {campaignData.hasButtons && (
          <CampaignDataDonut
            buttons={campaignData.buttons}
            replies={replies}
            cantSent={cantSent}
          />
        )}
        <CampaignDataTime
          recipients={allRecipientsData}
          campaignData={campaignData}
        />
      </div>
    </div>
  );
};
