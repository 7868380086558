import { Spin } from 'antd';
import { scrollTo } from '../../utils/chatFunctions';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const NotesTab = ({ notes, loading }) => {
  const sortedNotes = notes?.slice().sort((a, b) => b.timestamp - a.timestamp);

  return (
    <div className='extras-section'>
      <Spin spinning={loading} indicator={<LoadingIcon size="default" />}>
        <span className='extras-section-content fade-in'>
          {sortedNotes && sortedNotes.length > 0 ? (
            sortedNotes.map((note) => <Note key={note._id} note={note} />)
          ) : (
            <span className='extras-no-hay'>No hay notas</span>
          )}
        </span>
      </Spin>
    </div>
  );
};

const Note = ({ note }) => {
  return (
    <div className='Note' onClick={() => scrollTo(note._id)}>
      <span>{note.text?.body}</span>
      <span className='note-dateTime'>
        {dayjs(note.timestamp, 'x').format('DD/MM/YYYY HH:mm')}
      </span>
    </div>
  );
};
