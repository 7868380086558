import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { isSelectedClass } from "../../../../../../helpers/isSelected";
import { useContext, useEffect } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Tag } from "antd";
import { EnviosMasivosContext } from "../../../../../context/EnviosMasivosContext";

export const CronNode = (node) => {
  const { setNodoSeleccionado, setMostrarDrawer } =
    useContext(EnviosMasivosContext);

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    // NECESARIO PARA QUE RECONOZCA LOS HANDLES CONDICIONALES.
    updateNodeInternals(node.id);
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => handleClick()}
    >
      <div className="node-header-send-template">
        <div>
          <FaRegCalendarAlt size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">Configurar</span>
          <span className="mensaje-nodo">Frecuencia</span>
        </div>
      </div>
      {node.data.schedule && (
        <span
          className="columna"
          style={{
            marginTop: 12,
          }}
        >
          {node.data.schedule.map((schedule, index) => {
              return (
                <Tag color="geekblue" key={index}>
                  {schedule}
                </Tag>
              );
          })}
        </span>
      )}
      <Handle
        type="source"
        className="custom-handle trigger-handle"
        position={Position.Right}
        isConnectable={false}
      />
    </div>
  );
};
