import { Button, Form, Input, Select } from 'antd';
import { useMemo } from 'react';
import { getVariableTag } from '../../../utils/getVariableTag';
import { FiX } from 'react-icons/fi';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import {
  CONTACT_VARIABLES,
  CONTEXT_VARIABLES
} from '../Layout/flows/utils/contextVariables';
import { handleVariablesDisponibles } from '../Layout/flows/utils/handleVariablesDisponibles';
import { agregarVariableWhatsapp } from '../../../helpers/utilidadesTemplates';
import { BotonAgregarVariable } from '../Layout/flows/forms/BotonAgregarVariable';

export const UrlParams = ({
  urlParams,
  setUrlParams,
  modulo = 'envios',
  flowVariables = [],
  nodeId,
  edges
}) => {
  const handleOptionsVars = (value, index, type) => {
    setUrlParams((prevState) => {
      let array = [...prevState];
      array[index] = { key: array[index].key, type: type, value: value };

      return array;
    });
  };

  const handleKey = (index, value) => {
    setUrlParams((prevState) => {
      let array = [...prevState];

      array[index] = { ...array[index], key: value };

      return array;
    });
  };

  const handleModeType = (index, type) => {
    handleOptionsVars(null, index, type === 'var' ? 'text' : 'var');
  };

  const handleAddParam = () => {
    setUrlParams((prevState) => {
      let array = [...prevState];

      array.push({ key: '', type: 'text', value: null });

      return array;
    });
  };

  const deleteRow = (index) => {
    setUrlParams((prevState) => {
      let array = [...prevState];

      array.splice(index, 1);

      return array;
    });
  };

  const decorarParam = (variable, arrayIndex) => {
    setUrlParams((prevState) => {
      let array = [...prevState];

      array[arrayIndex] = {
        ...array[arrayIndex],
        value: agregarVariableWhatsapp(
          array[arrayIndex]?.value,
          variable,
          `param-${arrayIndex}`
        )
      };

      return array;
    });
  };

  const varOptions = useMemo(() => {
    if (modulo === 'flows') {
      let array = [
        ...CONTACT_VARIABLES,
        ...CONTEXT_VARIABLES.map((element) => {
          return {
            value: element.name,
            ...element
          };
        })
      ];

      if (flowVariables) {
        const variablesDisponibles = handleVariablesDisponibles(
          flowVariables,
          nodeId,
          true,
          edges
        );

        if (variablesDisponibles?.regular) {
          array = [
            ...array,
            ...variablesDisponibles.regular.map((element) => {
              return {
                value: element.name,
                label: element.name
              };
            })
          ];
        }
      }

      return array;
    }

    return [];
  }, [flowVariables]);

  const formattedVarOptions = useMemo(() => {
    return varOptions?.map((element) => {
      return {
        value: element.value,
        label: (
          <span className='horizontal gap8' style={{ width: '100%' }}>
            {getVariableTag(element.value)}
            <p>{element.label}</p>
          </span>
        ),
        label2: element.label
      };
    });
  }, [varOptions]);

  return (
    <>
      {urlParams?.length > 0 && (
        <div className='headers-wrapper'>
          <b>Parámetros</b>
          {urlParams?.map((element, index) => {
            return (
              <span
                className='item-row'
                key={`params-${index}`}
                style={{ alignItems: 'flex-start' }}
              >
                <Form.Item
                  label='Key'
                  style={{ width: '30%', marginBottom: 0 }}
                >
                  <Input
                    value={element.key}
                    onChange={(v) => handleKey(index, v.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '70%', marginBottom: 0 }}
                  label={
                    <span
                      className='horizontal'
                      style={{ justifyContent: 'space-between', width: '100%' }}
                    >
                      <p>Value</p>
                      {modulo === 'flows' && element.type !== 'var' && (
                        <BotonAgregarVariable
                          nodeId={nodeId}
                          lookingForTypes={['string', 'number']}
                          arrayIndex={index}
                          decorarTexto={decorarParam}
                          context='request'
                        />
                      )}
                      <FiX
                        style={{ cursor: 'pointer' }}
                        size={18}
                        onClick={() => deleteRow(index)}
                      />
                    </span>
                  }
                >
                  {modulo === 'flows' && (
                    <span
                      className='horizontal gap0'
                      style={{ alignItems: 'stretch' }}
                    >
                      <span
                        className='prefix-select'
                        onClick={(e) => handleModeType(index, element.type)}
                        style={{ cursor: 'pointer' }}
                      >
                        <HiOutlineSwitchHorizontal size={16} />
                      </span>
                      {element.type === 'var' ? (
                        <Select
                          options={formattedVarOptions}
                          className='options-template-variables'
                          onChange={(v) =>
                            handleOptionsVars(v, index, element.type)
                          }
                          value={urlParams[index]?.value}
                          showSearch
                          filterOption={(input, option) =>
                            (option.label2 ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder='Seleccione una variable'
                        />
                      ) : (
                        <Input.TextArea
                          autoSize={{ minRows: 2 }}
                          key={`${index}-i`}
                          value={urlParams[index]?.value}
                          style={{ borderRadius: '0px 6px 6px 0px' }}
                          onChange={(v) =>
                            handleOptionsVars(
                              v.target.value,
                              index,
                              element.type
                            )
                          }
                          placeholder='Ingrese un texto'
                          id={`param-${index}`}
                        />
                      )}
                    </span>
                  )}

                  {modulo === 'envios' && (
                    <span className='horizontal gap0'>
                      <Input
                        key={`${index}-i`}
                        value={urlParams[index]?.value}
                        // style={{ borderRadius: "0px 6px 6px 0px" }}
                        onChange={(v) =>
                          handleOptionsVars(v.target.value, index, element.type)
                        }
                        placeholder='Ingrese un texto'
                      />
                    </span>
                  )}

                  {modulo === 'configClient' && (
                    <span className='horizontal gap0'>
                      <Input
                        key={`${index}-i`}
                        value={urlParams[index]?.value}
                        // style={{ borderRadius: "0px 6px 6px 0px" }}
                        onChange={(v) =>
                          handleOptionsVars(v.target.value, index, element.type)
                        }
                        placeholder='Ingrese un texto'
                      />
                    </span>
                  )}

                  {modulo === 'loadContact' && (
                    <span className='horizontal gap0'>
                      <Input
                        key={`${index}-i`}
                        value={urlParams[index]?.value}
                        // style={{ borderRadius: "0px 6px 6px 0px" }}
                        onChange={(v) =>
                          handleOptionsVars(v.target.value, index, element.type)
                        }
                        placeholder='Ingrese un texto'
                      />
                    </span>
                  )}
                </Form.Item>
              </span>
            );
          })}
        </div>
      )}
      <span className='horizontal'>
        <Button
          className='btn-oscuro'
          style={{ marginLeft: 'auto' }}
          onClick={handleAddParam}
          type='primary'
          size='small'
        >
          Agregar parámetro
        </Button>
      </span>
    </>
  );
};
