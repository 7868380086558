import { Button, Drawer, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import {
  handleButtonsCount,
  handleButtonValue,
  handleDisableButton,
} from "../../../../../helpers/utilidadesTemplates";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { updateNode } from "../utils/updateNode";
import { getVariableName } from "../utils/getVariableName";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useUpdateNodeInternals } from "reactflow";
import { MediaForm } from "./MediaForm";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { LineaGris } from "../../../linea-gris/LineaGris";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";

export const ButtonsForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [preview, setPreview] = useState("");
  const [buttonsContent, setButtonsContent] = useState([""]);
  const [disableButton, setDisableButton] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [url, setURL] = useState("");
  const [location, setLocation] = useState({});
  const [caption, setCaption] = useState("");
  const [mostrarDrawerMedia, setMostrarDrawerMedia] = useState(false);

  const updateNodeInternals = useUpdateNodeInternals();

  const handleAuto = () => {
    return (
      <div>
        <div className="form-item-column">
          <span
            className="horizontal"
            style={{ justifyContent: "space-between" }}
          >
            <Form.Item
              label="Nombre de la variable"
              name="variableName"
              initialValue={nodoSeleccionado?.data?.variableName}
              style={{ width: "48%" }}
            >
              <Input addonBefore="flow_" placeholder="response" />
            </Form.Item>
            <Form.Item
              label="Tipo"
              name="variableType"
              value="string"
              initialValue="string"
              style={{ width: "48%" }}
            >
              <Select options={[{ value: "string", label: "String" }]} />
            </Form.Item>
          </span>
          <BoxInfo
            message="La salida de la variable es el número del boton. Empezando por 1
                en forma creciente."
          />
        </div>
      </div>
    );
  };

  const onFinish = (values) => {
    if (!bodyText) {
      return messageApi.info("Es necesario que ingrese cuerpo del mensaje");
    }
    values.label = nombreNodo;
    values.auto = false;
    values.headerType = messageType;

    values.headerText = headerText;
    values.bodyText = bodyText;
    values.footerText = footerText;

    if (buttonsContent.some((element) => !element)) {
      messageApi.info("Todo boton debe contener un texto");
      return;
    }

    values.buttonsContent = buttonsContent;

    values.variable = {};

    values.handleCount = buttonsContent.length;

    if (!values.variableName) {
      return messageApi.error("Ingrese un nombre para la variable");
    } else {
      values.handleCount = 1;
      values.variable.name = getVariableName(
        values.variableName,
        values.variableType
      );
      values.variable.type = values.variableType;

      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        values.variable,
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;
    }

    if (messageType === "media") {
      values.headerMediaType = headerMediaType;

      if (headerMediaType === "location") {
        if (!location.latitude || !location.longitude) {
          return messageApi.info("Latitud y longitud son campos obligatorios");
        }

        values.location = location;
      } else {
        if (url) {
          values.url = url;
        } else {
          return messageApi.info("Ingrese una url");
        }

        if (caption) {
          values.caption = caption;
          values.bodyText = "";
        }
      }
    } else {
      values.caption = null;
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
    updateNodeInternals(nodoSeleccionado.id);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.headerText !== undefined) {
        setHeaderText(data.headerText);
      }

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }

      if (data.footerText !== undefined) {
        setFooterText(data.footerText);
      }

      if (data.buttonsContent !== undefined) {
        const clone = structuredClone(data.buttonsContent);
        setButtonsContent(clone);
      }

      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      let mediaType = "image";

      if (data.headerMediaType || data.headerMediaImage) {
        mediaType = data.headerMediaType || data.headerMediaImage;
      }

      setHeaderMediaType(mediaType);

      setNombreNodo(data.label);

      if (data.location) {
        const clone = structuredClone(data.location);
        setLocation(clone);
      }

      if (data.url) {
        setURL(data.url);
      }

      // if (data.id !== undefined) {
      //   setSwitchURL(true);
      //   if (data.id) {
      //     setId(data.id);
      //   }
      // }

      if (data.caption) {
        setCaption(data.caption);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />

      <Form.Item label={<span className="label-secciones">ENCABEZADO</span>}>
        <HeaderTextOrMedia
          messageType={messageType}
          setMessageType={setMessageType}
          nodeId={nodoSeleccionado?.id}
          headerMediaType={headerMediaType}
          setHeaderMediaType={setHeaderMediaType}
          text={headerText}
          setText={setHeaderText}
          url={url}
          setURL={setURL}
          setPreview={setPreview}
          caption={caption}
          setCaption={setCaption}
          location={location}
          setLocation={setLocation}
          context="interactive-buttons"
        />
      </Form.Item>
      <LineaGris />
      <FormattedTextArea
        value={bodyText}
        setValue={setBodyText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textBody"
        label="CUERPO"
        boldLabel
        minRows={3}
      />
      <LineaGris />
      <FormattedTextArea
        value={footerText}
        setValue={setFooterText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textFooter"
        label="PIE DEL MENSAJE"
        boldLabel
        minRows={3}
        showDecorators={false}
      />
      <LineaGris />
      <div style={{ marginBottom: "16px" }}>
        <b className="label-secciones">BOTONES</b>
        {buttonsContent.map((element, index) => {
          return (
            <div className="template-crear-boton" key={index}>
              <b>Texto del botón</b>
              <div className="horizontal">
                <Input
                  style={{ maxWidth: "400px" }}
                  onChange={(v) =>
                    setButtonsContent(
                      handleButtonValue(
                        buttonsContent,
                        "quick",
                        v.target.value,
                        index
                      )
                    )
                  }
                  value={buttonsContent[index]}
                  showCount
                  maxLength={20}
                />
                {buttonsContent.length > 1 && (
                  <FiX
                    style={{ cursor: "pointer" }}
                    size={20}
                    onClick={() => {
                      setButtonsContent(
                        handleButtonsCount("resta", buttonsContent, index)
                      );
                      setDisableButton(
                        handleDisableButton("resta", buttonsContent, index)
                      );
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
        <Button
          className="btn-agregar"
          style={{ marginLeft: "auto" }}
          type="primary"
          onClick={() => {
            setButtonsContent(handleButtonsCount("suma", buttonsContent));
            setDisableButton(handleDisableButton("suma", buttonsContent));
          }}
          disabled={disableButton}
        >
          Agregar otro botón
        </Button>
      </div>
      <LineaGris />
      {handleAuto()}
      <Drawer
        open={mostrarDrawerMedia}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <MediaForm
          url={url}
          setURL={setURL}
          setMostrarDrawerMedia={setMostrarDrawerMedia}
          mediaType={headerMediaType}
        />
      </Drawer>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
