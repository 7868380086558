import { Home } from "../components/ui/Home";
import { MainLayout } from "../components/ui/MainLayout";

export const HomeView = ({ edit = false }) => {
  return (
    <MainLayout title={"Artículos"}>
      <Home edit={edit} />
    </MainLayout>
  );
};
