export const handleInnerVarsEnvios = (variables, section) => {
  let array = [];

  if (variables?.length > 0) {
    let filtro = variables.filter((item) => item.checked);

    filtro.forEach((item) => {
      array.push({
        name: `${section === "indexedVars" ? "itbl" : "http"}_${item.name}`,
        type: item.type,
        checked: true,
      });
    });
  }

  return array;
};
