export const LoadNotification = ({ data }) => {
  const { inserted, updated, errors, deleted } = data || {};

  return (
    <div className="columna gap4">
      <b>{`${inserted || 0} creados.`}</b>
      <b>{`${updated || 0} sincronizados.`}</b>
      {deleted ? <b>{`${deleted || 0} dado de baja.`}</b> : '' }
      <b>{`${errors?.length || 0} errores.`}</b>
    </div>
  );
};
