import { getCursorPosition } from "./getCursorPosition";

export const handleButtonsCount = (operacion, buttonsContent, index) => {
  let array = [...buttonsContent];
  if (operacion === "suma") {
    array.push("");
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleButtonsCountType = (buttonsContent, type) => {
  if (buttonsContent.length >= 10) {
    return buttonsContent;
  }

  let array = [...buttonsContent];

  let object = {};
  switch (type) {
    case "QUICK_REPLY":
      object = { type: type, text: "" };
      break;
    case "URL":
      object = { type: type, text: "", url: "", urlType: "static" };
      break;
    case "PHONE_NUMBER":
      object = { type: type, text: "", country: "54", phone_number: "" };
      break;
    case "COPY_CODE":
      object = { type: type, text: "Copiar código de oferta", example: "" };
      break;
    case "MARKETING":
      object = {
        type: type,
        text: "Detener promociones",
        footer: "¿No te interesa? Toca 'Detener promociones'",
        checked: false,
      };
      break;
    default:
      break;
  }

  array.push(object);

  array.sort((a, b) => a.type.localeCompare(b.type));

  return array;
};

export const handleListCount = (operacion, listContent, index) => {
  let object = { ...listContent };
  if (operacion === "suma") {
    object?.sections?.push({ title: "", rows: [] });
  } else {
    object?.sections?.splice(index, 1);
  }

  return object;
};

export const handleItemCount = (operacion, listContent, index, indexItem) => {
  let object = { ...listContent };

  let rows = object.sections[index].rows;

  if (operacion === "suma") {
    rows.push({ title: "", description: "" });
  } else {
    rows.splice(indexItem, 1);
  }

  object.sections[index].rows = rows;

  return object;
};

export const handleConditionsCount = (operacion, condiciones, index) => {
  let array = [...condiciones];

  if (operacion === "suma") {
    let id = 0;

    if (array.length > 0) {
      array.forEach((element) => {
        if (element.id > id) {
          id = element.id;
        }
      });
    }

    id = id + 1;

    array.push({ id: id, value: "", operator: [], childId: "" });
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleConditionValue = (
  condiciones,
  value,
  index,
  variableType
) => {
  let array = [...condiciones];

  if (variableType === "number") {
    array[index].value = Number(value);
  } else {
    array[index].value = value;
  }

  return array;
};

export const handleConditionsOperator = (condiciones, value, index) => {
  let array = [...condiciones];

  array[index].operator = [value];

  return array;
};

export const handleCallButtonsCount = (
  operacion,
  buttonsCallContent,
  index
) => {
  let array = [...buttonsCallContent];

  if (operacion === "suma") {
    if (buttonsCallContent.length === 0) {
      array.push({ type: "llamada", text: "", select: "+54", link: "" });
    } else {
      if (array[0].type === "llamada") {
        array.push({
          type: "web",
          text: "",
          select: "static",
          link: "",
          variable: "",
        });
      } else {
        array.push({ type: "llamada", text: "", select: "+54", link: "" });
      }
    }
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleButtonsMarketingCount = (
  operacion,
  buttonsMarketingContent,
  index
) => {
  let array = [...buttonsMarketingContent];
  if (operacion === "suma") {
    array.push({ type: "custom", text: "", check: false });
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleDisableButton = (
  operacion,
  buttonsContent,
  isCall = false
) => {
  let max = 10;
  if (isCall) {
    max = 2;
  }
  let flag = false;
  if (operacion === "suma") {
    if (buttonsContent.length === max - 1) {
      flag = true;
    }
  }

  return flag;
};

export const handleDisableList = (operacion, listContent) => {
  let flag = false;

  if (operacion === "suma") {
    if (listContent?.sections?.length === 9) {
      flag = true;
    }
  }

  return flag;
};

export const handleButtonValue = (buttonsContent, ambito, v, index) => {
  switch (ambito) {
    case "quick":
      let array = [...buttonsContent];
      array[index] = v;
      return array;
    case "desactivar":
      let arrayM = [...buttonsContent];
      arrayM[index].text = v;
      return arrayM;
    default:
      return buttonsContent;
  }
};

export const handleNewButtonValues = (
  setButtonsContent,
  index,
  field,
  value
) => {
  setButtonsContent((prevState) => {
    let array = [...prevState];
    array[index] = {
      ...array[index],
      [field]: value,
    };

    return array;
  });
};

export const handleListValue = (listContent, v, index) => {
  let object = { ...listContent };
  object.sections[index].title = v;
  return object;
};

export const handleItemValue = (listContent, v, index, indexItem, ambito) => {
  let object = { ...listContent };
  let rows = object?.sections[index]?.rows;

  rows[indexItem][ambito] = v;

  object.sections[index].rows = rows;

  return object;
};

export const handleCallButtons = (buttonsCallContent, property, index, v) => {
  let array = [...buttonsCallContent];

  array[index][property] = v;

  return array;
};

export const handleButtonsMarketingType = (
  buttonsMarketingContent,
  e,
  index
) => {
  let array = [...buttonsMarketingContent];
  array[index].type = e;
  array[index].check = false;
  let text = "";

  if (e === "default") {
    text = "Detener promociones";
  }
  array[index].text = text;

  return array;
};

export const handleCheckButton = (buttonsMarketingContent, v, index) => {
  let array = [...buttonsMarketingContent];
  array[index].check = v;

  return array;
};

export const formatoTextHtml = (texto) => {
  if (!texto) {
    return "";
  }

  const caracteres = [
    ["*", "<b>", "</b>"],
    ["~", "<s>", "</s>"],
    ["```", "<code>", "</code>"],
  ];

  caracteres.forEach((etiqueta) => {
    texto = texto.replace(
      new RegExp(`\\${etiqueta[0]}(.+?)\\${etiqueta[0]}`, "gs"),
      `${etiqueta[1]} $1 ${etiqueta[2]}`
    );
  });

  let resultado = "";
  let dentro = false;

  for (let i = 0; i < texto.length; i++) {
    let c = texto.charAt(i);

    if (c === "_" && !dentro) {
      dentro = true;
      resultado += `<i> `;
    } else if (c === "_" && dentro) {
      dentro = false;
      resultado += ` </i>`;
    } else if (c === "$" && texto.substring(i, i + 2) === "${") {
      resultado += "$";
      i++; // Saltamos el siguiente caracter "{"
      while (texto.charAt(i) !== "}" && i < texto.length) {
        resultado += texto.charAt(i);
        i++;
      }
      resultado += "}";

      // Si la estructura ${...} termina dentro de los guiones bajos, entonces no la contamos como dentro
      if (texto.charAt(i) !== "_") {
        dentro = true;
      }
    } else {
      resultado += c;
    }
  }

  texto = resultado.replace(
    new RegExp("\\$\\{([^{}]+)\\}", "g"),
    `<span class='custom-tag'>$1</span>`
  );

  texto = texto.replace(
    new RegExp("\\{\\{([^{}]+)\\}\\}", "g"),
    `<span class='custom-variable-reference'>{{$1}}</span>`
  );

  return texto;
};

export const agregarVariable = (inputText) => {
  if (inputText) {
    let matches = inputText.match(/{{\s*\d+\s*}}/g);
    let count = matches ? matches.length : 0;

    return inputText + `{{${count + 1}}}`;
  }

  return `{{${1}}}`;
};

export const agregarVariableHeader = (headerContent) => {
  let matches = headerContent.match(/{{\s*\d+\s*}}/g);
  let count = matches ? matches.length : 0;

  let obj = { headerContent: headerContent, flag: false };
  if (count < 1) {
    obj.headerContent = headerContent + `{{${count + 1}}}`;
    obj.flag = true;
  }

  return obj;
};

export const handleListName = (listContent, value) => {
  let object = { ...listContent };

  object.button = value;

  return object;
};

export const agregarVariableWhatsapp = (input, variable, inputId) => {
  let string = "";

  if (!input) {
    input = "";
  }

  if (inputId) {
    const cursorPosition = getCursorPosition(inputId);
    if (cursorPosition != null) {
      string =
        input?.substring(0, cursorPosition) +
        "$" +
        `{${variable.name}}` +
        input?.substring(cursorPosition);

      return string;
    }
  }

  string = string + input + "$" + `{${variable.name}}`;

  return string;
};

export const htmlToText = (texto) => {
  if (texto) {
    texto = texto.replace(
      new RegExp(`\\<span class='custom-tag'>(.+?)\\</span>`, "gs"),
      `{{$1}}`
    );
  }

  return texto;
};

export const handleProductItemValue = (
  listContent,
  v,
  sectionIndex,
  itemIndex
) => {
  let object = { ...listContent };
  object.sections[sectionIndex].product_items[itemIndex] = {
    product_retailer_id: v.value?.toString(),
    title: v.label,
  };

  return object;
};

export const handleProductListItemCount = (
  operacion,
  listContent,
  index,
  indexItem
) => {
  let object = { ...listContent };

  let product_items = object.sections[index].product_items;

  if (operacion === "suma") {
    product_items.push({ product_retailer_id: "", title: "" });
  } else {
    product_items.splice(indexItem, 1);
  }

  object.sections[index].product_items = product_items;

  return object;
};

export const handleProductListCount = (operacion, listContent, index) => {
  let object = { ...listContent };
  if (operacion === "suma") {
    object?.sections?.push({ title: "", productsSet: null, product_items: [] });
  } else {
    object?.sections?.splice(index, 1);
  }

  return object;
};

export const handleProductListValue = (
  listContent,
  v,
  index,
  property,
  conjuntos
) => {
  let object = { ...listContent };
  object.sections[index][property] = v;

  if (property === "productsSet") {
    object.sections[index].product_items = [];
    object.sections[index].title = conjuntos.find(
      (element) => element.id === v
    )?.name;
  }

  return object;
};

const BUTTON_TYPES_MAX = {
  QUICK_REPLY: 10,
  URL: 2,
  PHONE_NUMBER: 1,
  COPY_CODE: 1,
  MARKETING: 1,
};

export const isButtonTypeDisabled = (type, buttonsContent) => {
  if (buttonsContent.length >= 10) {
    return true;
  }

  let flag = false;

  if (buttonsContent?.length > 0) {
    let filtro = buttonsContent.filter((element) => element.type === type);

    if (filtro?.length >= BUTTON_TYPES_MAX[type]) {
      flag = true;
    }
  }

  return flag;
};
