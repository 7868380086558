import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Image,
  Input,
  Modal,
  Popover,
  Select,
  Table,
} from "antd";
import { useContext, useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { articulosColumns } from "./utils/columns";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { ModalCargaArchivo } from "./ModalCargaArchivo";
import { AiOutlineFileAdd } from "react-icons/ai";
import { updatePrices } from "../../../../../../helpers/fetchData/fetchCatalog";
import { actualizarUpdatePrices } from "./utils/actualizarListas";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import { getCurrencyByListForTable } from "./utils/getCurrencyByList";
import LoadingIcon from "../../../../Loader/LoadingIcon";
// import { setListsLocalStorage } from "./utils/manageListsLocalStorage";

export const TablaArticulos = ({ fetchCatalogDetail }) => {
  const { messageApi, instance } =
    useContext(GlobalContext);

  const { catalogDetail, setCatalogDetail, setConfigDrawerContent } = useContext(SettingsContext);
  
  const [busqueda, setBusqueda] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modal, setModal] = useState({ visible: false, data: null });
  const [filter, setFilter] = useState(null);

  const [loading, setLoading] = useState(false);

  const [porcentaje, setPorcentaje] = useState(null);

  const [modalArchivo, setModalArchivo] = useState({
    visible: false,
    data: null,
  });

  const [showPrecios, setShowPrecios] = useState(false);

  const filteredProducts = useMemo(() => {
    if (!catalogDetail?.products || !catalogDetail?.products?.length) {
      return [];
    }

    let array = [...catalogDetail.products];

    if (filter) {
      array = array.filter((producto) => {
        const lista = catalogDetail.lists.find(
          (element) => element._id === filter
        );

        if (lista?.products) {
          return lista.products.includes(producto.id);
        }

        return false;
      });
    }

    if (busqueda) {
      let search = busqueda.toLowerCase();
      array = array.filter((element) => {
        if (
          element.title?.toLowerCase()?.includes(search) ||
          element.brand?.toLowerCase()?.includes(search) ||
          element.sku?.toLowerCase().includes(search)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    return array;
  }, [catalogDetail, busqueda, filter]);

  const onClick = (item) => {
    switch (item.key) {
      case "1":
        setConfigDrawerContent({
          visible: true,
          content: "newProduct",
          item: null,
        });
        break;
      case "2":
        setModalArchivo({ visible: true, data: null });
        break;
      case "3":
        setShowPrecios(true);
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: "Carga manual",
      key: "1",
      icon: <FiPlus size={16} />,
    },
    {
      label: "Carga por archivo",
      key: "2",
      icon: <AiOutlineFileAdd size={16} />,
    },
    {
      label: "Actualizar precios",
      key: "3",
      icon: <BiDollar size={16} />,
    },
  ];

  const handleUpdatePrices = (selectedRowKeys, porcentaje) => {
    if (selectedRowKeys.length < 1) {
      return messageApi.info(
        "Por favor seleccione al menos un articulo si desea actualizar precios"
      );
    }

    if (!porcentaje || porcentaje === "e") {
      return messageApi.info("Por favor ingrese un porcentaje válido");
    }

    let data = {
      products: selectedRowKeys,
      percent: Number(porcentaje),
    };

    setLoading(true);
    updatePrices(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Precios actualizados correctamente");
          messageApi.success(res.data.message);
          setShowPrecios(false);
          setPorcentaje(null);

          // setListsLocalStorage(filter);

          actualizarUpdatePrices(
            setCatalogDetail,
            selectedRowKeys,
            parseFloat(porcentaje)
          );
        } else {
          messageApi.error("Ocurrió un error al actualizar precios");
        }
      })
      .catch((error) =>
        messageApi.error("Ocurrió un error al actualizar precios")
      )
      .finally(() => setLoading(false));
  };

  const selectOptions = useMemo(() => {
    let array = [];

    if (catalogDetail.lists?.length > 0) {
      array = catalogDetail.lists.map((element) => {
        return {
          value: element._id,
          label: element.name,
        };
      });
    }

    return array;
  }, [catalogDetail]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getUnitPrice = (item) => {
    let unit = ``;
    if (item?.unit_price) {
      try {
        let price = JSON.parse(item.unit_price);

        if (price?.unit) {
          unit = `/${price.unit}`;
        }
      } catch (error) {
        console.log(error);

        let price = item.unit_price;

        if (price?.unit) {
          unit = `/${price.unit}`;
        }
      }
    }

    return unit;
  };

  return (
    <>
      <span className="horizontal" style={{ marginBottom: "16px" }}>
        <Select
          style={{ maxWidth: "250px", minWidth: "150px" }}
          options={selectOptions}
          value={filter}
          showSearch
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(v) => setFilter(v)}
          placeholder="Seleccione una lista"
          allowClear
        />
        <Input
          type="search"
          placeholder="Buscar artículos"
          onChange={(v) => setBusqueda(v.target.value)}
          addonAfter={<SearchOutlined onClick={() => {}} />}
          style={{ maxWidth: "300px", marginLeft: "auto" }}
        />
        <Dropdown placement="bottomRight" menu={{ items, onClick }}>
          <Popover
            placement="bottomRight"
            destroyOnClose
            content={
              <div className="columna" style={{ width: 220 }}>
                <b>Porcentaje</b>
                <Input
                  type="number"
                  onChange={(v) => setPorcentaje(v.target.value)}
                  value={porcentaje}
                  disabled={loading}
                />
                <span className="horizontal">
                  <Button
                    type="primary"
                    className="btn-guardar"
                    onClick={() =>
                      handleUpdatePrices(selectedRowKeys, porcentaje)
                    }
                    //loading={loading}
                    icon={
                      loading ? (
                        <LoadingIcon size="small" color="#FFFFFF" />
                      ) : null
                    }
                    style={{ opacity: loading ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    type="secondary"
                    className="btn-cancelar"
                    onClick={() => {
                      setShowPrecios(false);
                      setPorcentaje(null);
                    }}
                  >
                    Cancelar
                  </Button>
                </span>
              </div>
            }
            trigger={"click"}
            open={showPrecios}
          >
            <BsThreeDotsVertical size={16} style={{ cursor: "pointer" }} />
          </Popover>
        </Dropdown>
      </span>
      <Modal
        open={modal.visible}
        footer={null}
        width={848}
        destroyOnClose
        closeIcon={
          <CloseOutlined
            onClick={() => setModal({ visible: false, data: null })}
          />
        }
      >
        {modal?.data && (
          <div className="modal-articulo-wrapper">
            <span
              className="horizontal"
              style={{ alignItems: "flex-start", gap: "16px" }}
            >
              <span>
                <Image
                  placeholder
                  style={{ objectFit: "cover" }}
                  width={300}
                  height={300}
                  src={modal.data.image_link}
                  preview={false}
                />
              </span>
              <span
                className="columna"
                style={{ width: 484, padding: "0px 12px" }}
              >
                <p className="articulo-title">{modal.data.title}</p>
                <p className="articulo-brand">{modal.data.brand}</p>
                <span className="horizontal articulo-subtitle">
                  <p>{`SKU ${modal.data.sku}`}</p>
                  <p>-</p>
                  <p style={{ color: "green" }}>
                    {modal.data.condition?.toUpperCase()}
                  </p>
                </span>
                <p className="articulo-description">{modal.data.description}</p>
                <p className="articulo-price">{`${
                  modal.data.price?.split(" ")?.[0]
                } ${getCurrencyByListForTable(
                  catalogDetail,
                  filter
                )}${getUnitPrice(modal.data)}`}</p>
              </span>
            </span>
          </div>
        )}
      </Modal>
      <ModalCargaArchivo
        modalArchivo={modalArchivo}
        setModalArchivo={setModalArchivo}
        listas={catalogDetail?.lists}
        fetchCatalogDetail={fetchCatalogDetail}
      />
      <Table
        columns={articulosColumns(setModal, catalogDetail, filter)}
        dataSource={filteredProducts}
        rowKey={"id"}
        size="small"
        pagination={false}
        className="tabla-config"
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
      />
    </>
  );
};
