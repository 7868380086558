import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { BROCOLY_INSTANCES } from "../../utils/BROCOLY_INSTANCES";
import { useNavigate } from "react-router-dom";

export const BrocolyAdminRouter = ({ children }) => {
  const { instance } = useContext(GlobalContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (instance?._id) {
      if (!BROCOLY_INSTANCES.includes(instance?._id)) {
        navigate("/dashboard");
      }
    }
  }, [instance]);

  if (BROCOLY_INSTANCES.includes(instance?._id)) {
    return children;
  } else {
    return null;
    // return <Navigate link="/" />;
  }
};
