import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  Controls,
  useEdgesState,
  useNodesState,
  addEdge,
  useReactFlow,
} from "reactflow";
import { nodeTypes } from "./nodeTypes";
import { Button, Drawer, Form, Input, Modal } from "antd";
import { SendTemplateContext } from "../../../../context/SendTemplateContext";
import { ContactsForm } from "./form/ContactsForm";
import { TemplateForm } from "./form/TemplateForm";
import { ButtonForm } from "./form/ButtonForm";
import { ScheduleForm } from "./form/ScheduleForm";
import {
  // getInnerMetrics,
  newCampaign,
  updateCampaign
} from '../../../../../helpers/fetchData/fetchCampaigns';
import { GlobalContext } from '../../../../context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSendTemplateData } from './utils/getSendTemplateData';
import { Base64 } from 'js-base64';
import { dataToNodes } from './utils/dataToNodes';
// import {
//   formatearEstados,
//   formatearInteracciones
// } from './utils/metricsFormat';
import { getFlows } from '../../../../../helpers/fetchData/fetchFlows';
import LoadingIcon from '../../../Loader/LoadingIcon';
// import { WarningModal } from '../../../modals/WarningModal';
import { accessDataFormat } from '../../../../../helpers/access';
import { useAccess } from '../../../../../hooks/useAccess';

export const CampaignBuilder = ({ edit = false }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const navigate = useNavigate();

  const location = useLocation();

  const isAllowed = useAccess();

  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const nextNodeId = useRef("3");
  const idHandle = useRef(null);

  const [mostrarDrawer, setMostrarDrawer] = useState(false);
  const [nodoSeleccionado, setNodoSeleccionado] = useState({});
  // const [contactosSeleccionados, setContactosSeleccionados] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [campaignData, setCampaignData] = useState(null);

  const [showConfirmar, setShowConfirmar] = useState(false);

  const [loading, setLoading] = useState(false);

  const [flows, setFlows] = useState([]);

  const [loadingFlows, setLoadingFlows] = useState(true);

  // const [metricsData, setMetricsData] = useState({});

  const [isEditable, setIsEditable] = useState(true);

  const [form] = Form.useForm();

  const onConnect = useCallback((params) => {
    let parametros = { ...params };

    setEdges((eds) => addEdge(parametros, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    connectingNodeId.current = nodeId;
    idHandle.current = handleId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = nextNodeId.current.toString();

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX - left,
            y: event.clientY - top - 60,
          }),
          type: "button",
        };

        setNodes((nds) => nds.concat(newNode));

        let newEdge = {
          id,
          source: connectingNodeId.current,
          target: id,
        };

        if (idHandle) {
          newEdge = {
            id,
            source: connectingNodeId.current,
            target: id,
            sourceHandle: idHandle.current,
          };
        }

        setEdges((eds) => eds.concat(newEdge));

        setNodoSeleccionado(newNode);
        setMostrarDrawer(true);
      }
    },
    [screenToFlowPosition]
  );

  const initialNodes = [
    {
      id: "0",
      position: { x: 0, y: 0 },
      type: "contacts",
      data: {
        label: "Contactos",
        deletable: false,
      },
    },
    {
      id: "1",
      position: { x: 250, y: 0 },
      type: "schedule",
      data: { label: "Tiempo", deletable: false },
    },
    {
      id: "2",
      position: { x: 500, y: 0 },
      type: "template",
      data: { label: "Plantilla", deletable: false },
    },
  ];

  const initialEdges = [
    { id: "1", source: "0", target: "1" },
    { id: "2", source: "1", target: "2" },
  ];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleFormContent = () => {
    const { type } = nodoSeleccionado;

    switch (type) {
      case "contacts":
        return <ContactsForm edit={!isEditable} />;
      case "template":
        return <TemplateForm edit={!isEditable} />;
      case "button":
        return <ButtonForm edit={!isEditable} />;
      case "schedule":
        return <ScheduleForm edit={!isEditable} />;
      default:
        return <p>Tipo no soportado...</p>;
    }
  };

  const onFinish = (values) => {
    let name = values.name.trim() || "";

    if (name.length < 3) {
      messageApi.info("Ingrese un nombre con al menos 3 caracteres");
      return;
    }

    let { data, error } = getSendTemplateData(
      nodes,
      messageApi,
      name,
      500,
      edges
    );

    data.status = 'pending';

    if (error) {
      return messageApi.error("No se puede guardar la campaña");
    }

    if (edit && isEditable) {
      delete data._id;

      setLoading(true);
      updateCampaign(instance?.userData?.apiKey, campaignData?._id, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Campaña guardada correctamente");
            setShowConfirmar(false);
            navigate("/mensajes/campañas");
          } else {
            messageApi.info("Ocurrió un error al guardar la campaña");
          }
        })
        .catch((error) =>
          messageApi.info("Ocurrió un error al guardar la campaña")
        )
        .finally(() => setLoading(false));
    } else if (!edit) {
      setLoading(true);

      newCampaign(instance, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Campaña guardada correctamente");
            setShowConfirmar(false);
            navigate("/mensajes/campañas");
          } else {
            messageApi.info("Ocurrió un error al guardar la campaña");
          }
        })
        .catch((error) =>
          messageApi.info("Ocurrió un error al guardar la campaña")
        )
        .finally(() => setLoading(false));
    }
  };

  const onLoad = (reactFlowInstance) => {
    setTimeout(() => {
      reactFlowInstance.fitView({ padding: 0.5 });
    }, 200);
  };

  useEffect(() => {
    if (edit && instance?.userData?.apiKey) {
      try {
        const search = location.search;
        const data = new URLSearchParams(search).get("payload");

        if (data) {
          let campaign = JSON.parse(Base64.decode(data));

          if (campaign) {
            if (campaign.status?.toLowerCase() === "sent") {
              setIsEditable(false);
            } else {
              setIsEditable(true);
            }

            setCampaignData(campaign);
            dataToNodes(campaign, setNodes, setEdges, nodes);

            // getInnerMetrics(instance.userData.apiKey, campaign._id)
            //   .then((res) => {
            //     if (res?.status === 200) {
            //       if (Array.isArray(res.data)) {
            //         setMetricsData({
            //           metrics: formatearEstados(res.data),
            //           interactions: formatearInteracciones(res.data)
            //         });
            //       }
            //     } else {
            //       messageApi.error(
            //         'Ocurrió un error al obtener data de la campaña'
            //       );
            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     messageApi.error(
            //       'Ocurrió un error al obtener data de la campaña'
            //     );
            //   });
          }
        }
      } catch (error) {
        console.log(error);
        messageApi.error("No se pudo obtener data de la campaña");
      }
    }
  }, [instance]);

  useEffect(() => {
    if (Array.isArray(nodes)) {
      let number = Number(nodes[nodes.length - 1]?.id);
      number += 1;
      nextNodeId.current = number.toString();
    }
  }, [nodes]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getFlows(setFlows, messageApi, setLoadingFlows, instance);
    }
  }, [instance]);

  //! filtros para seleccionar contactos, que se guarde el criterio.

  function handleBackClick() {
    // setIsModalOpen(true);
    navigate("/mensajes/campañas");
  }

  /* function handleModalOk() {
    setIsModalOpen(false);
    navigate('/mensajes/campañas');
  } */

  return (
    <SendTemplateContext.Provider
      value={{
        setMostrarDrawer,
        nodoSeleccionado,
        setNodoSeleccionado,
        // contactosSeleccionados,
        // setContactosSeleccionados,
        nodes,
        setNodes,
        edges,
        setEdges,
        // metricsData,
        flows,
        loadingFlows,
      }}
    >
      <div className="main-wrapper-flow" ref={reactFlowWrapper}>
        <div className="flow-header">
          <span>
            <span>{campaignData?.name ? campaignData.name : "Campaña"}</span>
          </span>
          <div className="panel-flow">
            <Button
              type="secondary"
              className="btn-cancelar"
              style={{ width: "fit-content" }}
              onClick={handleBackClick}
            >
              Volver
            </Button>
            {isEditable &&
              isAllowed(accessDataFormat("broadcast", "manage")) && (
                <Button
                  type="primary"
                  className="btn-agregar"
                  onClick={() => setShowConfirmar(true)}
                >
                  Guardar
                </Button>
              )}
          </div>
        </div>
        <Drawer open={mostrarDrawer} width={450} destroyOnClose={true}>
          {handleFormContent()}
        </Drawer>
        <Modal
          open={showConfirmar}
          footer={null}
          // width={showDataTable.visible ? "85%" : 500}
          width={450}
          destroyOnClose
          style={{ maxHeight: "80%", overflowY: "auto", left: 35 }}
          closeIcon={false}
        >
          <Form
            form={form}
            name="send-template"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item style={{ marginBottom: 8 }}>
              <b>Guardar campaña</b>
            </Form.Item>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true, message: "Ingrese un nombre" }]}
              initialValue={campaignData?.name || ""}
            >
              <Input placeholder="Ingrese un nombre" />
            </Form.Item>
            <div className="horizontal">
              <Button
                htmlType="submit"
                type="primary"
                className="btn-guardar"
                //loading={loading}
                icon={
                  loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                onClick={() => setShowConfirmar(false)}
                type="secondary"
                className="btn-cancelar"
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </Modal>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          nodesConnectable={true}
          fitView={true}
          onInit={onLoad}
          fitViewOptions={{ padding: 0.5 }}
          nodeTypes={nodeTypes}
          connectionLineType="bezier"
          deleteKeyCode={null}
        >
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>

      {/* <WarningModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onOk={handleModalOk}
      /> */}
    </SendTemplateContext.Provider>
  );
};
