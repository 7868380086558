import axios from "axios";

export const newChatMediaResource = async (apiKey, data) => {
  try {
    const config = {
      url: `${process.env.REACT_APP_API_PRODUCCION}/chatMedia`,
      method: "post",
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios.request(config);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getChatMediaById = async (apiKey, id) => {
  try {
    const config = {
      url: `${process.env.REACT_APP_API_PRODUCCION}/chatMedia/${id}`,
      method: "get",
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = await axios.request(config);

    return res;
  } catch (error) {
    console.log(error);
  }
};
