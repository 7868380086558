import "./content.css";

export const Content1 = () => {
  return (
    <section className="landing-section">
      {/* Izq */}
      <div className="landing-section-column">
        <span className="horizontal gap-8" style={{ marginBottom: 24 }}>
          <span className="landing-linea-blanca"></span>
          <p className="landing-datos-upper-text">
            Comunicación inteligente y bidireccional
          </p>
        </span>
        <h3 className="landing-section-title">
          <span className="landing-font-highlight">Whatsapp</span> y{" "}
          <span className="landing-font-highlight">Chatbot</span> <br />
          una combinación
          <br /> perfecta
        </h3>

        <p className="landing-section-subtitle">
          Potencia tu comunicación con la combinación perfecta
          <br /> de WhatsApp (Meta) e inteligencia artificial de OpenAI,
          <br />
          todo a través de brocoly. ¡Sin necesidad de apps adicionales!
          <br />
          <br />
          Descubre cómo esta poderosa combinación puede
          <br /> transformar tu forma de conectar con tus clientes,
          <br /> aumentar tus ventas y personalizar la experiencia en
          <br /> tiempo real.
        </p>

        <div className="section-boxes-layout">
          <div className="section-box">
            <p className="section-box-title">
              CONSTRUCCIÓN
              <br /> DE <strong>BOTS</strong>
            </p>
            <p className="section-box-subtitle">
              Construye tu bot de forma fácil,
              <br /> rápida y adaptable a tu negocio.
            </p>
          </div>

          <div className="section-box">
            <p className="section-box-title">
              <strong>CAMPAÑAS</strong>
              <br />
              PERSONALIZADAS
            </p>
          </div>
          <div className="section-box">
            <p className="section-box-title">
              ENVÍOS <strong>MASIVOS</strong>
            </p>
            <p className="section-box-subtitle">
              Con integración a tu sistema de gestión.
            </p>
          </div>
          <div className="section-box">
            <p className="section-box-title">
              <strong>INTEGRACION</strong> CON ERP, CRM,
              <br />
              FORMULARIOS WEB Y OTROS.
            </p>
            <p className="section-box-subtitle">
              Construye tu bot de forma fácil,
              <br /> rápida y adaptable a tu negocio.
            </p>
          </div>
        </div>
      </div>
      <div className="landing-section-column">
        <img
          src={`${process.env.PUBLIC_URL}/assets/landing-images/section1.png`}
          alt="section1"
          className="landing-image-1"
        />
      </div>
    </section>
  );
};

{
  /* <span className="horizontal gap-16" style={{ marginTop: 50 }}>
          <button className="landing-boton-generico boton-rojo">
            Inteligencia de negocios
          </button>
          <button className="landing-boton-generico boton-verde">
            Inteligencia del cliente
          </button>
        </span> */
}
