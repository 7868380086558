import { useMemo } from "react";
import { ButtonsVariableInput } from "../variableInput/ButtonsVariableInput";

export const ButtonInput = ({
  index,
  boton,
  buttonsVars,
  setButtonsVars,
  varOptions,
}) => {
  const handleAddonVariables = (element, index) => {
    let type = "URL";
    let placeholder = "Complete la URL";

    if (element.type === "COPY_CODE") {
      type = "Código";
      placeholder = "Ingrese el código a enviar";
    }

    return {
      placeholder: placeholder,
      addon: `${type} - ${index + 1}`,
    };
  };

  const indices = useMemo(() => {
    return buttonsVars?.map((element) => element.index);
  }, [buttonsVars]);

  if (!indices.includes(index)) {
    return <></>;
  }

  let obj = handleAddonVariables(boton, index);

  return (
    <>
      <span style={{ width: "100%", padding: "0px 8px" }}>
        <ButtonsVariableInput
          index={index}
          boton={boton}
          buttonsVars={buttonsVars}
          setButtonsVars={setButtonsVars}
          placeholder={obj.placeholder}
          varOptions={varOptions}
        />
      </span>
    </>
  );
};
