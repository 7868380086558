import { Button, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import {
  getTaggedContacts,
  updateTagContacts,
} from "../../../../../helpers/fetchData/fetchTags";
import { Tag } from "../../../tag/Tag";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { SelectContactos } from "../../../selectContactos/SelectContactos";

export const ManageTagContacts = () => {
  const {
    rightDrawerConfig,
    setRightDrawerConfig,
    messageApi,
    instance,
  } = useContext(GlobalContext);

  const [initialTargetKeys, setInitialTargetKeys] = useState([]);

  const [targetKeys, setTargetKeys] = useState([]);

  const [loadingInitialValues, setLoadingInitialValues] = useState(false);

  const [loading, setLoading] = useState(false);

  const updateTags = () => {
    let contactsToSend = [...initialTargetKeys];

    targetKeys.forEach((key) => {
      if (!contactsToSend.includes(key)) {
        contactsToSend.push(key);
      } else {
        let index = contactsToSend.indexOf(key);
        contactsToSend.splice(index, 1);
      }
    });

    let data = JSON.stringify({
      tagId: rightDrawerConfig?.item?._id,
      contacts: contactsToSend,
    });

    setLoading(true);
    updateTagContacts(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Contactos etiquetados correctamente.");
          // setRefreshContactos(!refreshContactos);
          setRightDrawerConfig({ visible: false, content: "" });
        } else {
          messageApi.error("No se pudo etiquetar contactos.");
        }
      })
      .catch((error) => {
        messageApi.error("No se pudo etiquetar contactos.");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      if (rightDrawerConfig?.item?._id) {
        // Buscar contactos etiquetados
        const apiKey = instance.userData.apiKey;
        const tagId = rightDrawerConfig.item._id;
        setLoadingInitialValues(true);
        getTaggedContacts(apiKey, tagId)
          .then((res) => {
            if (res?.status === 200) {
              if (Array.isArray(res?.data)) {
                setInitialTargetKeys(res.data);
                setTargetKeys(res.data);
              }
            } else {
              messageApi.error("No se puso obtener contactos etiquetados");
            }
          })
          .catch((error) => {
            messageApi.error("No se puso obtener contactos etiquetados");
          })
          .finally(() => setLoadingInitialValues(false));
      }
    }
  }, [instance, rightDrawerConfig]);

  return (
    <div className="asignar-contactos-wrapper form-nodos">
      <div className="asignar-header-wrapper">
        <p style={{ fontWeight: 600 }}>Etiquetar contactos</p>
        <Tag
          hex={rightDrawerConfig?.item?.color}
          nombre={rightDrawerConfig?.item?.name}
          key={rightDrawerConfig?.item?._id}
        />
      </div>
      <div>
        {targetKeys !== "undefined" ? (
          <>
            <SelectContactos
              value={targetKeys}
              setValue={setTargetKeys}
              mode="multiple"
            />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin
              style={{ marginTop: "100px" }}
              indicator={<LoadingIcon size="large" />}
            />
          </div>
        )}
      </div>
      <span className="form-custom-footer" style={{ marginBottom: 12 }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
            onClick={updateTags}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() =>
              setRightDrawerConfig({
                visible: false,
                content: "",
              })
            }
          >
            Cancelar
          </Button>
        </div>
      </span>
    </div>
  );
};
