import { Button, Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Cron } from 'react-js-cron';
import "react-js-cron/dist/styles.css";
import { EnviosMasivosContext } from '../../../../../context/EnviosMasivosContext';
import { updateNode } from '../../../flows/utils/updateNode';
import { CUSTOM_LOCALE } from '../utils/customLocale';
import { GlobalContext } from '../../../../../context/GlobalContext';

export const CronForm = () => {
  const { messageApi } = useContext(GlobalContext);
  const { setNodes, setMostrarDrawer, setNodoSeleccionado, nodoSeleccionado } =
    useContext(EnviosMasivosContext);

  const [cronValue, setCronValue] = useState('30 * * * *');

  const [form] = Form.useForm();

  const onFinish = () => {
    let data = {};

    if (!cronValue) {
      // Validar

      messageApi.info('No es un valor válido');
      return;
    }

    data.schedule = [cronValue];

    setNodes((prevState) => updateNode(nodoSeleccionado, data, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      if (nodoSeleccionado.data.schedule?.[0]) {
        setCronValue(nodoSeleccionado.data.schedule[0]);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <p style={{ fontWeight: 500, marginBottom: 16 }}>
        Configure la frecuencia con la que lo enviará
      </p>
      <Cron
        value={cronValue}
        setValue={setCronValue}
        clearButton={false}
        locale={CUSTOM_LOCALE}
      />
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
