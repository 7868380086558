import { useState, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { CargaMedia } from '../modals/CargaMedia';
import { GlobalContext } from '../../context/GlobalContext';

export const CargarMediaButton = () => {
  const { currentStorageSize, currentPlan } = useContext(GlobalContext);

  const [showCargaMedia, setShowCargaMedia] = useState(false);

  return (
    <>
      <Tooltip
        title={
          currentStorageSize === currentPlan.size
            ? 'No se pueden subir nuevos archivos. No queda espacio de almacenamiento'
            : ''
        }
      >
        <Button
          type='secondary'
          className='btn-cancelar'
          onClick={() => setShowCargaMedia(true)}
          style={{ width: 'fit-content' }}
          disabled={currentStorageSize === currentPlan.size}
        >
          Cargar media
        </Button>
      </Tooltip>

      {showCargaMedia && (
        <div
          className='confirm-wrapper'
          onClick={() => setShowCargaMedia(false)}
        >
          <div className='confirm-form' onClick={(e) => e.stopPropagation()}>
            <CargaMedia modal={true} setShowCargaMedia={setShowCargaMedia} />
          </div>
        </div>
      )}
    </>
  );
};
