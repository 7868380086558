import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Button, Popover, Tooltip } from "antd";
import { MdOutlineBlock } from "react-icons/md";
import { getContactName } from "../../campaigns/campaignBuilder/utils/getContactName";
import { addToBlacklist } from "../../../../../helpers/fetchData/fetchBlacklist";
import LoadingIcon from "../../../Loader/LoadingIcon";

export const BloquearContacto = ({ item }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Tooltip title="Bloquear">
      <span>
        <Popover
          open={showPopover}
          content={
            <PopoverBloquear item={item} setShowPopover={setShowPopover} />
          }
        >
          <span>
            <MdOutlineBlock
              className="icono-acciones"
              onClick={() => setShowPopover(true)}
            />
          </span>
        </Popover>
      </span>
    </Tooltip>
  );
};

const PopoverBloquear = ({ item, setShowPopover }) => {
  const { messageApi, instance, setContactos } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  return (
    <span className="columna" style={{ maxWidth: 280 }}>
      <p>{`¿Está seguro de bloquear a ${getContactName(item.name)}?`}</p>
      <span className="horizontal">
        <Button
          type="primary"
          className="btn-borrar"
          onClick={() =>
            addToBlacklist(
              item,
              instance,
              messageApi,
              setContactos,
              setLoading,
              setShowPopover
            )
          }
          // loading={loading}
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Confirmar
        </Button>
        <Button
          type="secondary"
          className="btn-cancelar"
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </span>
    </span>
  );
};
