import { Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import { TemplateContext } from "../../../../../context/TemplateContext";

export const Muestra = ({
  bodyContent,
  buttonsCallContent,
  buttonsContent,
  footerContent,
  headerContent,
  headerMediaType,
  headerType,
  inputText,
}) => {
  const [messageUpload, setMessageUpload] = useState([
    "Elegir archivo JPG o PNG",
    ".png, .jpeg, .jpg",
  ]);

  const { preview, setPreview } = useContext(TemplateContext);

  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    let file = e.target.files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setPreview({ name: file.name, data: reader.result });
    };

    //FUNCIONA Y NO SE COMO. SI BORRAS ESTA LINEA DEJA DE FUNCIONAR ???
    reader.readAsDataURL(file);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const fileType = () => {
    switch (headerMediaType) {
      case "image":
        return "Imagen";
      case "video":
        return "Video";
      case "document":
        return "Documento";
      default:
        return "Imagen";
    }
  };

  useEffect(() => {
    switch (headerMediaType) {
      case "image":
        setMessageUpload(["Elegir archivo JPG o PNG", ".png, .jpeg, .jpg"]);
        break;
      case "video":
        setMessageUpload(["Elegir archivo MP4", ".mp4"]);
        break;
      case "document":
        setMessageUpload(["Elegir archivo PDF", ".pdf"]);
        break;
      default:
        setMessageUpload(["Elegir archivo JPG o PNG", ".png, .jpeg, .jpg"]);
    }
  }, [headerMediaType]);

  return (
    <div className="muestra-layout-wrapper">
      <b style={{ fontSize: "15px" }}>Ejemplos de contenido del encabezado</b>
      <div className="layout-muestra">
        <div className="layout-muestra-izq">
          <span>
            <p style={{ color: "rgba(28,43,51,.65)" }}>
              Para ayudarnos a revisar el contenido, proporciona ejemplos de las
              variables o el contenido multimedia en el encabezado. No incluyas
              información de ningún cliente.
            </p>
          </span>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <span className="horizontal">
              <b>{fileType(headerMediaType)}</b>
              {!preview ? (
                <>
                  <label htmlFor="inputTag">
                    <Button
                      className="btn-agregar"
                      type="primary"
                      onClick={() => handleButtonClick()}
                    >
                      {messageUpload[0]}
                    </Button>
                    <input
                      id="inputTag"
                      style={{ display: "none" }}
                      type="file"
                      ref={inputRef}
                      accept={messageUpload[1]}
                      onChange={handleFileChange}
                    />
                  </label>
                  <span className="horizontal">
                    <p></p>
                  </span>
                </>
              ) : (
                <>
                  <label htmlFor="inputTag">
                    <Button
                      className="btn-agregar"
                      type="primary"
                      onClick={() => handleButtonClick()}
                    >
                      {preview.name}
                    </Button>
                    <input
                      id="inputTag"
                      style={{ display: "none" }}
                      type="file"
                      ref={inputRef}
                      accept={messageUpload[1]}
                      onChange={handleFileChange}
                    />
                  </label>
                  <FiX
                    size={18}
                    style={{ cursor: "pointer" }}
                    onClick={() => setPreview()}
                  />
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
