import { Button, Form, Input, Popover, Select, Switch } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import {
  addBodyItem,
  deleteItemBody,
  handleValueItemBody,
  handleVariableType
} from '../utils/httpRequestBody';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { SelectVariables } from '../SelectVariables';
import { TbCodeDots } from 'react-icons/tb';
import { MdLockOutline } from 'react-icons/md';
import { updateNode } from '../utils/updateNode';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { getVariableName } from '../utils/getVariableName';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import { agregarVariableWhatsapp } from '../../../../../helpers/utilidadesTemplates';
import { isJSON } from '../utils/httpRequest';
import { HeaderForm } from './componentsForm/HeaderForm';

export const EncodeForm = () => {
  const [nombreNodo, setNombreNodo] = useState('');
  const [hasVars, setHasVars] = useState(false);
  const [body, setBody] = useState('');
  const [isContentJSON, setIsContentJSON] = useState(false);

  const [secret, setSecret] = useState({
    visible: false,
    value: ''
  });

  const [bodyVars, setBodyVars] = useState([]);
  // const [bodyType, setBodyType] = useState("JSON");

  const [form] = Form.useForm();

  const { messageApi } = useContext(GlobalContext);

  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    flowVariables,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const AddonVariable = ({ index }) => {
    const [showPopover, setShowPopover] = useState(false);

    const decorarVariable = (wrappingString, emoji, variable, index) => {
      if (wrappingString === '${') {
        handleValueItemBody(
          setBodyVars,
          index,
          'value',
          '${' + `${variable.name}` + '}'
        );
      }
    };

    return (
      <Popover
        trigger='click'
        placement='topRight'
        open={showPopover}
        content={
          <SelectVariables
            decorarTexto={decorarVariable}
            context='text'
            nodeId={nodoSeleccionado.id}
            setShowPopover={setShowPopover}
            index={index}
            excludedTypes={['audio', 'video', 'location', 'document', 'image']}
          />
        }
      >
        <span
          onClick={() => setShowPopover(true)}
          style={{ cursor: 'pointer' }}
        >
          <TbCodeDots />
        </span>
      </Popover>
    );
  };

  const decorarTexto = (wrappingString, emoji, variable) => {
    //Respetar posiciones de parametros para que funcione desde cualquier nodo

    setBody((prevState) =>
      agregarVariableWhatsapp(prevState, variable, 'encodeInput')
    );
  };

  const onFinish = (values) => {
    if (!values.variableName) {
      return messageApi.error('Ingrese un nombre para la variable');
    }

    values.variable = {};

    let variableName = getVariableName(values.variableName, 'encode');

    values.variableName = variableName;
    values.variable.name = variableName;
    values.variable.type = 'encode';
    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    values.label = nombreNodo;

    values.inputType = 'string';

    values.body = body;

    values.bodyVars = null;

    values.secret = null;

    if (secret.visible) {
      if (!secret.value) {
        messageApi.info('Ingrese un secret u oculteló');
        return;
      }

      values.secret = secret.value;
    }

    if (isContentJSON) {
      values.inputType = 'json';

      if (hasVars) {
        values.bodyVars = bodyVars;
      } else {
        let flag = isJSON(body);

        if (!flag) {
          messageApi.info('El contenido ingresado no es un JSON');
          return;
        }
      }
    }

    values.outputType = 'base64';

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      setBody(data.body);

      if (data.inputType === 'json') {
        setIsContentJSON(true);
      }

      if (data.bodyVars) {
        setBodyVars(data.bodyVars);
        setHasVars(true);
      }

      if (data.secret) {
        setSecret({
          visible: true,
          value: data.secret
        });
      }

      let variableName;
      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf('_') + 1);
      } else {
        variableName = '';
      }

      form.setFieldsValue({
        variableName: variableName
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      name='encode-form'
      layout='vertical'
      onFinish={onFinish}
      requiredMark={false}
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <MdLockOutline
            style={{ color: 'var(--dark-color)' }}
            size={32}
          />
        }
      />
      <span
        className='horizontal'
        style={{ margin: '16px 0px', justifyContent: 'space-between' }}
      >
        <span className='horizontal gap8'>
          <p>Contenido</p>
          <span
            className='horizontal gap8'
            style={{
              fontSize: 13,
              padding: '2px 4px',
              backgroundColor: '#f4f4f4',
              borderRadius: 6
            }}
          >
            <p>Texto</p>
            <Switch
              size='small'
              onChange={(v) => {
                setIsContentJSON(v);
              }}
              checked={isContentJSON}
            />
            <p>JSON</p>
          </span>
        </span>
        {isContentJSON && (
          <span
            className='horizontal gap8'
            style={{
              fontSize: 13,
              padding: '2px 4px',
              backgroundColor: '#f4f4f4',
              borderRadius: 6
            }}
          >
            <Switch
              size='small'
              checked={hasVars}
              onChange={(v) => setHasVars(v)}
            />
            <p>Con variables</p>
          </span>
        )}
      </span>
      <>
        {hasVars ? (
          <div className='variables-body-wrapper'>
            <span className='horizontal variables-body-header-wrapper'>
              <p className='variables-body-header'>Key</p>
              <p className='variables-body-header'>Value</p>
              <p className='variables-body-header'>Type</p>
            </span>
            {bodyVars.map((variable, index) => {
              return (
                <span className='variable-body-item' key={index}>
                  <span className='columna-body-item'>
                    <Input
                      value={variable.key}
                      onChange={(v) =>
                        handleValueItemBody(
                          setBodyVars,
                          index,
                          'key',
                          v.target.value
                        )
                      }
                    />
                  </span>
                  <span className='columna-body-item'>
                    <Input
                      value={variable.value}
                      onChange={(v) =>
                        handleValueItemBody(
                          setBodyVars,
                          index,
                          'value',
                          v.target.value
                        )
                      }
                      addonAfter={<AddonVariable index={index} />}
                    />
                  </span>
                  <Select
                    value={handleVariableType(
                      variable.type,
                      variable.value,
                      flowVariables
                    )}
                    onChange={(v) =>
                      handleValueItemBody(setBodyVars, index, 'type', v)
                    }
                    options={[
                      { value: 'string', label: 'String' },
                      { value: 'number', label: 'Number' }
                    ]}
                  />
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteItemBody(setBodyVars, index)}
                  >
                    <FiX />
                  </span>
                </span>
              );
            })}
            <span style={{ width: '100%', display: 'flex', margin: '8px 0px' }}>
              <Button
                className='btn-aceptar btn-oscuro'
                style={{ margin: '0px 8px 8px auto' }}
                type='primary'
                size='small'
                onClick={() => addBodyItem(setBodyVars)}
              >
                Agregar item
              </Button>
            </span>
          </div>
        ) : (
          <span className='encode-form-content'>
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              onChange={(v) => setBody(v.target.value)}
              value={body}
              id='encodeInput'
            />
            <BotonAgregarVariable
              decorarTexto={decorarTexto}
              nodeId={nodoSeleccionado.id}
            />
          </span>
        )}
      </>

      <div style={{ margin: '16px 0px' }}>
        {!secret.visible ? (
          <Button
            onClick={() =>
              setSecret((prevState) => {
                return { ...prevState, visible: true };
              })
            }
          >
            Agregar secret
          </Button>
        ) : (
          <>
            <Form.Item
              label={
                <span
                  className='horizontal gap8'
                  onClick={(e) => e.preventDefault()}
                >
                  <p>Secret</p>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setSecret({ value: '', visible: false });
                    }}
                  >
                    Quitar
                  </Button>
                </span>
              }
            >
              <Input
                placeholder='Ingrese una palabra o frase'
                value={secret.value}
                onChange={(v) =>
                  setSecret((prevState) => {
                    return { ...prevState, value: v.target.value };
                  })
                }
              />
            </Form.Item>
          </>
        )}
      </div>
      <Form.Item
        label='Nombre de la variable'
        name='variableName'
        initialValue={nodoSeleccionado?.data?.variableName}
        rules={[
          {
            pattern: /^[a-zA-Z0-9-]+$/,
            message: 'Se permiten solo letras, números o "-"'
          },
          { required: true, message: 'Es necesario que ingrese un nombre' }
        ]}
        // style={{ width: "63%" }}
      >
        <Input addonBefore={'encode_'} />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
            // disabled={method === "POST" ? false : !isValid}
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
