import { Button, Form, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { FiPlus, FiX } from 'react-icons/fi';
import {
  handleConditionValue,
  handleConditionsCount,
  handleConditionsOperator
} from '../../../../../helpers/utilidadesTemplates';
import { updateNode } from '../utils/updateNode';
import { GlobalContext } from '../../../../context/GlobalContext';
import { operadores } from '../utils/utilidadesFlow';
import { RiGitBranchLine } from "react-icons/ri";
import { HeaderForm } from './componentsForm/HeaderForm';
import { Tag } from '../../../tag/Tag';
import { FiInfo } from "react-icons/fi";

export const TagBranchForm = () => {
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    edges,
    setEdges
  } = useContext(FlowContext);

  const [form] = Form.useForm();

  const { messageApi, etiquetas } = useContext(GlobalContext);

  // const [selectedContacts, setSelectedContacts] = useState([]);
  const [nombreNodo, setNombreNodo] = useState('');
  const [condiciones, setCondiciones] = useState([
    { id: 1, value: '', operator: [], childId: '' }
  ]);




  const onFinish = (values) => {

    let valores = {};

    valores.label = nombreNodo;
    valores.handleCount = 1;

    let condicionesIds = condiciones.map((element) => element.id.toString());

    setEdges((prevState) => {
      let filtro = [...prevState].filter((element) => {
        let flag = true;
        if (
          element.source === nodoSeleccionado.id &&
          element.sourceHandle &&
          element.sourceHandle !== 'default'
        ) {
          if (!condicionesIds.includes(element.sourceHandle)) {
            flag = false;
          }
        }
        return flag;
      });

      return filtro;
    });

    valores.cases = condiciones;
    valores.handleCount += condiciones.length;


    setNodes((prevState) => updateNode(nodoSeleccionado, valores, prevState));
    setMostrarDrawer(false);
  };



  useEffect(() => {

    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      if (data.cases?.length > 0) {
        let casos = [...data.cases];

        let lastElement = casos[casos.length - 1];

        if (lastElement?.value === undefined || lastElement?.value === '') {
          casos.pop();
        }

        setCondiciones(casos);

        casos.forEach(({ id, operator, value }) => {
          let index = id - 1;
          form.setFieldsValue({
            [`operador-${index}`]: operator[0],
            [`tagsValues-${index}`]: value
          });
        });

      } else {
        //setCondiciones([]);
        setCondiciones([
          { id: 1, value: '', operator: [], childId: '' }
        ]);
      }

    }
  }, [nodoSeleccionado]);


  const operadoresOptions = operadores['string'].filter(item => item.value === "contains" || item.value === "doesNotContain");


  const tagOptions = etiquetas.map((etiqueta) => ({
    label: (
      <Tag hex={etiqueta.color} nombre={etiqueta.name} key={etiqueta._id} />
    ),
    value: etiqueta._id,
    tagName: etiqueta.name
  }));


  const tagHandleFilter = (inputValue, option) => {
    return option?.tagName?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const handleChangeTags = (value, index) => {

    setCondiciones((prevState) =>
      handleConditionValue(
        prevState,
        value,
        index,
        //variable.type
      )
    )
  };


  return (
    <Form
      form={form}
      layout='vertical'
      name='formTagBranch'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<RiGitBranchLine size={32} />}
      />

      <span
        className="horizontal"
        style={{
          color: "#505050",
          alignItems: "flex-start",
          marginBottom: "20px",
        }}
      >
        <span style={{ marginTop: "2px" }}>
          <FiInfo />
        </span>
        <span>
          <p>Evalúa las etiquetas asociadas al contacto que está interactuando con el flujo. Basándose en las condiciones definidas.</p>
        </span>
      </span>

      <Form.Item label='Condiciones'>
        {/* {selectedContacts.length > 0 && ( */}
        <div
          className='template-crear-lista'
          style={{ margin: '0px 0px 0px 0px' }}
        >
          {condiciones?.map((condicion, index) => {
            return (
              <div className='item-wrapper' key={index}>
                <Form.Item
                  name={`operador-${index}`}
                  style={{ marginBottom: '8px' }}
                  label={
                    <span
                      className='horizontal-list'
                      style={{ width: '100%' }}
                    >
                      <p>Condición</p>
                      {index > 0 && (
                        <FiX
                          style={{ cursor: 'pointer' }}
                          size={20}
                          onClick={() => {
                            setCondiciones((prevState) =>
                              handleConditionsCount('resta', prevState, index)
                            );
                          }}
                        />
                      )}

                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Campo requerido'
                    }
                  ]}
                >
                  <Select
                    onChange={(v) =>
                      setCondiciones((prevState) =>
                        handleConditionsOperator(prevState, v, index)
                      )
                    }
                    options={operadoresOptions}
                    value={condicion.operator?.[0]}
                  />
                </Form.Item>








                {/* //////////////////////////// SELECT ETIQUETAS /////////////////////////////*/}
                <Form.Item
                  name={`tagsValues-${index}`}
                  label='Etiqueta'
                  style={{ marginBottom: '8px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Campo requerido'
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    options={tagOptions}
                    filterOption={tagHandleFilter}
                    showSearch
                    onChange={(value) => { handleChangeTags(value, index) }}
                  //maxTagCount={MAX_TAG_COUNT}
                  />
                </Form.Item>






















              </div>
            );
          })}
          <Button
            className='btn-agregar btn-oscuro'
            type='primary'
            onClick={() => {
              setCondiciones((prevState) =>
                handleConditionsCount('suma', prevState)
              );
            }}
            style={{ marginLeft: 'auto' }}
          >
            <FiPlus /> Condición
          </Button>
        </div>
        {/* )} */}
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
