import axios from 'axios';

export const updateUserRole = async (apiKey, data) => {
  try {
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/updateUserRole`,
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getContactByUserId = async (apiKey, userId) => {
  try {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/getContactByUserId/${userId}`,
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json'
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const upsertUserContact = async (apiKey, data) => {
  try {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/upsertUserContact`,
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserById = async (instance, id) => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/users/getUserById/${id}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
