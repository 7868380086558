import { useContext } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { Tag } from '../../../tag/Tag';

export const ExtrasTags = ({ contacto }) => {
  const { etiquetas } = useContext(GlobalContext);

  function renderTag(tagId, index) {
    const tag = etiquetas?.find((etiqueta) => etiqueta._id === tagId);

    if (tag) {
      return <Tag hex={tag.color} nombre={tag.name} key={`tag-${index}`} />;
    } else {
      return <span key={`tag-${index}`}></span>;
    }
  }

  return (
    <div className='ExtrasTags'>
      {contacto.tags?.length > 0 ? (
        <span className='horizontal'>
          {contacto.tags?.map((etiqueta, index) => {
            return renderTag(etiqueta, index);
          })}
        </span>
      ) : (
        <span className='extras-sin-etiquetas'>
          El contacto no tiene etiquetas
        </span>
      )}
    </div>
  );
};
