import { useContext, useEffect, useMemo } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { Handle, Position } from "reactflow";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { TbTrash } from "react-icons/tb";
import { FiTag } from "react-icons/fi";
import { Tag } from "../../../tag/Tag";
import { GlobalContext } from "../../../../context/GlobalContext";
import { TbDeviceMobileX } from "react-icons/tb";
import { TbDeviceMobilePlus } from "react-icons/tb";

export const TagContactNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const { etiquetas } = useContext(GlobalContext);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const borrarNode = useBorrarNode(node.id);

  const tagData = useMemo(() => {
    if (node.data?.tag && etiquetas) {
      return etiquetas.find((etiqueta) => etiqueta._id === node.data.tag);
    }

    return null;
  }, [etiquetas, node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div style={{ height: "32px" }}>
          <FiTag color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">
            {node.data?.addTag === false
              ? "Quitar etiqueta"
              : "Agregar etiqueta"}
          </span>
          <span className="mensaje-nodo">{node.data.label}</span>
        </div>
      </div>
      {tagData?._id && (
        <div style={{ margin: "16px 0px " }}>
          {node.data.addTag === false ? (
            <TbDeviceMobileX />
          ) : (
            <TbDeviceMobilePlus />
          )}
          <Tag nombre={tagData.name} hex={tagData.color} />
        </div>
      )}
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "12px" }}>
        {node?.id}
      </div>
    </div>
  );
};
