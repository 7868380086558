export const getTotalByCartItem = (producto) => {
  const { count, item } = producto;

  if (item?.price) {
    let price = item.price.split(" ")?.[0]?.replace(",", ".");

    return (count * price)?.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return false;
  }
};

export const getCartTotal = (cartDetail) => {
  if (cartDetail?.length > 0) {
    const total = cartDetail.reduce((total, producto) => {
      let price = Number(
        getTotalByCartItem(producto).replace(".", "").replace(",", ".")
      );

      if (!Number.isNaN(price)) {
        return total + price;
      } else {
        return total;
      }
    }, 0);

    return `$${total.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  return `$${(0).toFixed(2)}`;
};
