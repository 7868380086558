import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsMedium,
  BsTwitterX,
} from "react-icons/bs";
import "./footer.css";

export const Footer = () => {
  const scrollToTop = () => {
    const element = document.getElementById("landing-header");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer-wrapper">
      <div className="footer-column">
        <img
          alt="logo-brocoly"
          src={`${process.env.PUBLIC_URL}/assets/landing-images/logo-brocoly.png`}
          style={{ height: "2.5rem" }}
        />
        <p className="footer-column-title">Oficina</p>
        <p className="footer-text">Villa María, Córdoba</p>
        <p className="footer-text">Santa Fe 1238 - Oficina D</p>
        <p className="footer-text">Teléfono: (+54) 0353 4012-614</p>
      </div>

      <span className="horizontal gap-8" style={{ marginTop: "auto" }}>
        <p className="footer-text">Seguinos en</p>
        <a href="#linkedin" className="anchor-footer">
          <BsLinkedin fill="white" />
        </a>
        <a href="#intagram" className="anchor-footer">
          <BsInstagram fill="white" />
        </a>
        <a href="#intagram" className="anchor-footer">
          <BsMedium fill="white" />
        </a>
        <a href="#x" className="anchor-footer">
          <BsTwitterX fill="white" />
        </a>
        <a href="#facebook" className="anchor-footer">
          <BsFacebook fill="white" />
        </a>
      </span>

      <img
        src={`${process.env.PUBLIC_URL}/assets/landing-images/scroll-to-top.png`}
        className="image-go-top pointer"
        alt="boton-top"
        onClick={scrollToTop}
      />
    </footer>
  );
};
