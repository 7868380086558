export const getTier = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.messaging_limit_tier) {
    switch (businessCompliance?.messaging_limit_tier) {
      case 'TIER_50':
        return '50';
      case 'TIER_250':
        return '250';
      case 'TIER_1K':
        return '1K';
      case 'TIER_10K':
        return '10K';
      case 'TIER_100K':
        return '100K';
      case 'TIER_UNLIMITED':
        return 'Ilimitado';
      default:
        return 'Error';
    }
  }
};

export const getTierMessage = (businessCompliance) => {
  if (businessCompliance && businessCompliance?.messaging_limit_tier) {
    switch (businessCompliance?.messaging_limit_tier) {
      case 'TIER_50':
        return '50 mensajes';
      case 'TIER_250':
        return '250 mensajes';
      case 'TIER_1K':
        return '1000 mensajes';
      case 'TIER_10K':
        return '10000 mensajes';
      case 'TIER_100K':
        return '100000 mensajes';
      case 'TIER_UNLIMITED':
        return 'Ilimitado';
      default:
        return 'Error';
    }
  }
};
