import { Button, Input, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { IoDuplicateOutline } from 'react-icons/io5';
import { GlobalContext } from '../../../../context/GlobalContext';
import { validateDuplicateTrigger } from '../utils/validateDuplicateTrigger';
import axios from 'axios';
import { getFlows, newFlow } from '../../../../../helpers/fetchData/fetchFlows';

export const CardBotDuplicar = ({ flow, setFlows, setLoading }) => {
  const [showDuplicate, setShowDuplicate] = useState(false);

  return (
    <>
      {showDuplicate && (
        <ModalDuplicar
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
          setShowDuplicate={setShowDuplicate}
        />
      )}
      <Tooltip title='Duplicar'>
        <span style={{ cursor: 'pointer' }}>
          <IoDuplicateOutline
            size={20}
            onClick={() => setShowDuplicate(true)}
          />
        </span>
      </Tooltip>
    </>
  );
};

const ModalDuplicar = ({ flow, setFlows, setLoading, setShowDuplicate }) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [duplicateData, setDuplicateData] = useState({
    name: '',
    trigger: '',
    description: ''
  });
  const [dupLoading, setDupLoading] = useState(false);

  function handleDuplicateData(value, type) {
    setDuplicateData((prevState) => {
      let data = { ...prevState };

      data[type] = value;

      return data;
    });
  }

  async function onFinish() {
    if (!duplicateData.name || !duplicateData.trigger) {
      return messageApi.info('Debe ingresar un nombre y una palabra clave');
    }

    setDupLoading(true);

    let flag = await validateDuplicateTrigger(
      duplicateData.trigger,
      messageApi,
      instance?.userData?.apiKey
    );

    if (!flag) {
      setDupLoading(false);
      return;
    }

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/getFlowById?flowId=${flow?._id}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    axios
      .request(config)
      .then((res) => {
        let data = res?.data;

        if (data.flowData) {
          let flow = {
            publishedBy: null,
            publishedAt: null,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            status: 'draft',
            createdBy: null,
            useContextVars: true,
            name: duplicateData.name,
            description: duplicateData.description,
            trigger: [duplicateData.trigger],
            steps: data.flowData.steps,
            flowVars: data.flowData.flowVars
          };

          let nodes = [...data.flowArchitectureData?.nodes];

          nodes[0].data.triggers = [duplicateData.trigger];

          let flowArchitecture = {
            edges: data.flowArchitectureData?.edges,
            variablesNodos: data.flowArchitectureData?.variablesNodos,
            nodes: nodes
          };

          let flowData = {
            flow: flow,
            flowArchitecture: flowArchitecture,
            idInstance: instance?._id
          };

          newFlow(instance?.userData?.apiKey, flowData)
            .then((res) => {
              if (res?.status === 200) {
                messageApi.open({
                  type: 'success',
                  content: 'Bot guardado correctamente como borrador',
                  style: {
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px'
                  }
                });
                setShowDuplicate(false);
                getFlows(setFlows, messageApi, setLoading, instance);
              } else {
                messageApi.error('No se pudo guardar el Bot');
              }
            })
            .catch((error) => {
              messageApi.error('No se pudo guardar el Bot');
            })
            .finally(() => {
              setDupLoading(false);
            });
        } else {
          messageApi.error('No se pudo obtener información del bot a duplicar');
        }
      })
      .catch((error) => {
        messageApi.error('No se pudo obtener información del bot a duplicar');
      })
      .finally(() => setDupLoading(false));
  }

  return (
    <div className='confirm-wrapper' style={{ position: 'fixed' }}>
      <div
        className='confirm-form'
        style={{ padding: '16px' }}
        onClick={(e) => e.stopPropagation()}
      >
        <span style={{ width: '100%' }}>
          <p className='label-duplicate'>Nombre</p>
          <Input
            value={duplicateData.name}
            onChange={(v) => handleDuplicateData(v.target.value, 'name')}
          />
        </span>
        <span style={{ width: '100%' }}>
          <p className='label-duplicate'>Descripción</p>
          <Input
            value={duplicateData.description}
            onChange={(v) => handleDuplicateData(v.target.value, 'description')}
          />
        </span>
        <span style={{ width: '100%' }}>
          <p className='label-duplicate'>Trigger</p>
          <Input
            value={duplicateData.trigger}
            onChange={(v) => handleDuplicateData(v.target.value, 'trigger')}
          />
        </span>
        <div
          className='botones-wrapper-content'
          style={{ marginTop: '8px', width: '100%' }}
        >
          <Button
            type='primary'
            className='btn-guardar'
            onClick={() => onFinish()}
            loading={dupLoading}
          >
            Aceptar
          </Button>
          <Button
            className='btn-cancelar'
            type='secondary'
            onClick={() => setShowDuplicate(false)}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};
