import { Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { updateEnvioMasivo } from "../../../../helpers/fetchData/fetchEnviosMasivos";
import { ContactsFilters } from "../../contactsFilters/ContactsFilters";
import { CONTACT_FILTERS } from "../../../../utils/CONTACT_FILTERS";

export const EnviosSubscriptions = ({
  drawerData,
  setDrawerData,
  setEnviosMasivos,
}) => {
  const { messageApi, instance } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState(CONTACT_FILTERS);

  const guardarSuscripciones = () => {
    let data = {
      ...drawerData.item,
      filters: filters,
    };

    setLoading(true);
    updateEnvioMasivo(instance?.userData?.apiKey, drawerData.item?._id, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Suscripciones guardadas correctamente");
          setEnviosMasivos((prevState) => {
            return prevState?.map((element) => {
              if (element._id === drawerData.item._id) {
                return { ...element, filters: filters };
              } else {
                return element;
              }
            });
          });
          setDrawerData({ open: false, item: null });
        } else {
          messageApi.error("Ocurrió un error al guardar suscripciones");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al guardar suscripciones");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (drawerData.item?.filters) {
      setFilters(drawerData.item.filters);
    }
  }, [drawerData]);

  return (
    <div className="form-nodos" style={{ gap: 16 }}>
      <p style={{ fontWeight: 600, fontSize: 16 }}>{drawerData.item?.name}</p>
      <ContactsFilters filters={filters} setFilters={setFilters} />
      <span className="form-custom-footer" style={{ marginBottom: 12 }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            loading={loading}
            onClick={guardarSuscripciones}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() =>
              setDrawerData({
                open: false,
                item: null,
              })
            }
          >
            Cancelar
          </Button>
        </div>
      </span>
    </div>
  );
};
