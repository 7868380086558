import { Button, Form} from "antd";
import { useContext, useEffect, useState } from "react";
import { SendTemplateContext } from "../../../../../context/SendTemplateContext";
import { updateNode } from "../../../flows/utils/updateNode";
import { ContactsFilters } from "../../../../contactsFilters/ContactsFilters";
import { CONTACT_FILTERS } from "../../../../../../utils/CONTACT_FILTERS";

export const ContactsForm = ({ edit }) => {
  const {
    setMostrarDrawer,
    nodoSeleccionado,
    setNodes,
  } = useContext(SendTemplateContext);

  const [filters, setFilters] = useState(CONTACT_FILTERS);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.filters = filters;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));

    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado.data) {
      const { filters } = nodoSeleccionado.data;

      setFilters(filters);
    }
  }, [nodoSeleccionado]);


  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <ContactsFilters filters={filters} setFilters={setFilters} />
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          {!edit && (
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
            >
              Guardar
            </Button>
          )}
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            block={edit}
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            {edit ? "Cerrar" : "Cancelar"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
