import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { DIVISAS } from "../../catalog/utils/divisas";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import { updateList } from "../../../../../../../helpers/fetchData/fetchProductsLists";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const EditList = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { setCatalogDetail, configDrawerContent, setConfigDrawerContent } =
    useContext(SettingsContext);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (v) => {
    const { item } = configDrawerContent;

    let data = {
      name: v.name,
      id: v.id,
      currency: v.currency,
    };

    setLoading(true);
    updateList(instance?.userData?.apiKey, item._id, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Lista configurada correctamente");
          setCatalogDetail((prevState) => {
            let object = { ...prevState };

            let listas = object?.lists.map((element) => {
              if (element._id === item._id) {
                return {
                  _id: item._id,
                  ...data,
                };
              } else {
                return element;
              }
            });

            object.lists = listas;

            return object;
          });

          setConfigDrawerContent({ visible: false, content: "", item: null });
        } else {
          messageApi.error("Ocurrió un error al configurar una lista");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al configurar una lista");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (configDrawerContent?.item) {
      const { item } = configDrawerContent;

      form.setFieldsValue({
        name: item.name,
        id: item.id,
        currency: item.currency,
      });
    }
  }, [configDrawerContent]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="newImagen"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Editar lista</p>
      <Form.Item
        label="Nombre"
        name="name"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Identificador"
        name="id"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Divisa"
        style={{ width: 280 }}
        name="currency"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={DIVISAS} />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setConfigDrawerContent({
                visible: false,
                content: "",
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
