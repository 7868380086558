import { useAccess } from "../../../../../hooks/useAccess";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import {
  newTest,
  updateFlowStatus,
} from "../../../../../helpers/fetchData/fetchFlows";

import { accessDataFormat } from "../../../../../helpers/access";
import { CardBotEliminar } from "./CardBotEliminar";
import { CardBotDuplicar } from "./CardBotDuplicar";
import { CardBotEditar } from "./CardBotEditar";
import { CardBotPublicar } from "./CardBotPublicar";
import { CardBotVolverBorrador } from "./CardBotVolverBorrador";
import { Button, Popover, Tooltip } from "antd";
import { MdOutlineChecklistRtl } from "react-icons/md";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { SelectContactos } from "../../../selectContactos/SelectContactos";

export const CardBotActions = ({ flow, setFlows, setLoading }) => {
  const { messageApi, instance, contactos } = useContext(GlobalContext);

  const [testData, setTestData] = useState({ open: false, contactId: null });
  const [loadingTest, setLoadingTest] = useState(false);

  const isAllowed = useAccess();

  function updateStatus(flowId, status) {
    let data = {
      flowId: flowId,
      newStatus: status,
    };

    updateFlowStatus(data, messageApi, setFlows, setLoading, instance);
  }

  const handleTest = () => {
    if (!testData?.contactId) {
      messageApi.info("Seleccione un contacto, por favor");
      return;
    }

    const phoneNumber = contactos.find(
      (element) => element._id === testData.contactId
    )?.phones?.[0]?.phone;

    const data = { flowId: flow._id, phoneNumber: phoneNumber };

    setLoadingTest(true);
    newTest(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Enviamos una plantilla al contacto indicado");

          setTestData({ open: false, contactId: null });
          return;
        }

        messageApi.error("Ocurrió un error al iniciar un test");
      })
      .catch((error) => {
        console.log("error", error);

        messageApi.error("Ocurrió un error al iniciar un test");
      })
      .finally(() => setLoadingTest(false));
  };

  return (
    isAllowed(accessDataFormat("bots", "manage")) && (
      <span
        className="fila-acciones card-bot-actions"
        style={{ marginTop: "auto" }}
      >
        <CardBotEditar flow={flow} />
        {flow.status === "draft" ? (
          <CardBotPublicar flow={flow} updateStatus={updateStatus} />
        ) : (
          <CardBotVolverBorrador flow={flow} updateStatus={updateStatus} />
        )}
        <CardBotDuplicar
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
        />
        {flow?.status?.toLowerCase() === "draft" && (
          <Popover
            trigger="click"
            placement="bottom"
            open={testData?.open}
            content={
              <div className="columna">
                <b style={{ marginInline: "auto" }}>Iniciar prueba</b>
                <span className="horizontal">
                  <p>Seleccionar contacto</p>

                  <BoxInfo
                    message={[
                      "Se enviará una plantilla al número del contacto seleccionado.",
                      "Al confirmar iniciará la prueba.",
                    ]}
                    hideMode={true}
                  />
                </span>
                <span style={{ width: 220 }}>
                  <SelectContactos
                    value={testData?.contactId}
                    setValue={(v) => setTestData({ open: true, contactId: v })}
                    placeholder="Seleccione un contacto"
                  />
                </span>
                <div
                  className="botones-wrapper-content"
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 8,
                  }}
                >
                  <Button
                    type="primary"
                    className="btn-agregar"
                    onClick={handleTest}
                    icon={
                      loadingTest ? (
                        <LoadingIcon size="small" color="#FFFFFF" />
                      ) : null
                    }
                    style={{ opacity: loadingTest ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    className="btn-cancelar"
                    type="secondary"
                    onClick={() =>
                      setTestData({ open: false, contactId: null })
                    }
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            }
          >
            <span>
              <Tooltip title="Probar">
                <span style={{ height: "18px", cursor: "pointer" }}>
                  <MdOutlineChecklistRtl
                    size={20}
                    onClick={() => setTestData({ open: true, contactId: null })}
                  />
                </span>
              </Tooltip>
            </span>
          </Popover>
        )}
        <CardBotEliminar
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
        />
      </span>
    )
  );
};
