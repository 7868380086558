import { useEffect, useState } from "react";

// Contador de elementos (tickets/notas/media):
export const useCounter = (
  filteredTickets,
  filteredNotes,
  filteredMedia,
  tab
) => {
  const [contador, setContador] = useState(0);
  const [tipoContador, setTipoContador] = useState("tickets");

  useEffect(() => {
    const countMap = {
      tickets: filteredTickets?.length,
      notas: filteredNotes?.length,
      archivos: filteredMedia?.length,
    };

    const count = countMap[tab];

    setContador(count);
    setTipoContador(count === 1 ? tab.slice(0, -1) : tab);
  }, [filteredTickets, filteredNotes, filteredMedia, tab]);

  return { contador, tipoContador };
};
