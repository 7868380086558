import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { Button, Checkbox, Form, Input, Select, Spin, Switch } from 'antd';
import { FlowContext } from '../../../../context/FlowContext';
import { updateNode } from '../utils/updateNode';
import { BiSupport } from 'react-icons/bi';
import { getTeams } from '../../../../../helpers/fetchData/fetchTeams';
import { getInvitationsByInstance } from '../../../../../helpers/fetchData/fetchInvitation';
import { LoadingOutlined } from '@ant-design/icons';
import { BoxInfo } from '../../../box-info/BoxInfo';
import { HeaderForm } from './componentsForm/HeaderForm';

export const ContactAgentForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { nodoSeleccionado, setMostrarDrawer, setNodes, setEdges } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [usuarios, setUsuarios] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [showTeams, setShowTeams] = useState(false);
  const [teams, setTeams] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [showTeamAgents, setShowTeamAgents] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(false);

  const [showAgents, setShowAgents] = useState(false);

  const getTeamAgents = () => {
    if (selectedTeam) {
      let teamUsers =
        teams?.find((team) => team._id === selectedTeam)?.users || [];

      return teamUsers?.map((agent) => {
        let usuario = usuarios?.find((user) => user._id === agent);

        if (usuario) {
          return {
            value: agent,
            label: usuario.name
          };
        }

        return {};
      });
    }

    return [];
  };

  const onFinish = (values) => {
    values.label = nombreNodo;

    values.team = null;
    values.agent = null;

    if (showTeams && selectedTeam) {
      values.team = selectedTeam;
      values.teamName =
        teams?.find((element) => element._id === selectedTeam)?.name || '';
    }

    if ((showTeamAgents || showAgents) && selectedAgent) {
      values.agent = selectedAgent;
      values.agentName =
        usuarios?.find((element) => element._id === selectedAgent)?.name || '';
    }

    values.handleCount = 2;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTeams(true);
      getTeams(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
              setTeams(res.data);
            }
          } else {
            messageApi.error('Ocurrió un error al obtener equipos');
          }
        })
        .catch((error) =>
          messageApi.error('Ocurrió un error al obtener equipos')
        )
        .finally(() => setLoadingTeams(false));
    }
  }, [instance]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingUsers(true);
      getInvitationsByInstance(instance.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setUsuarios(res.data?.activeUsers || []);
          } else {
            messageApi.error('Ocurrió un error al obtener usuarios');
          }
        })
        .catch((error) =>
          messageApi.error('Ocurrió un error al obtener usuarios')
        )
        .finally(() => setLoadingUsers(false));
    }
  }, [instance]);

  useEffect(() => {
    if (!showTeams) {
      setSelectedTeam(null);
      setShowTeamAgents(false);
    }
  }, [showTeams]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { team, agent } = nodoSeleccionado.data;

      if (team) {
        setShowTeams(true);
        setSelectedTeam(team);

        if (agent) {
          setShowTeamAgents(true);
        }
      }

      if (agent) {
        setSelectedAgent(agent);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='form-contact-agent'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<BiSupport size={32} />}
      />

      {(loadingTeams || loadingUsers) && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined size={26} spin />
        </div>
      )}
      {!loadingTeams && !loadingUsers && (
        <Form.Item
          label={
            <span className='horizontal gap8'>
              <Switch
                size='small'
                onChange={(v) => setShowTeams(v)}
                checked={showTeams}
              />
              <p>Asignar a un equipo</p>
            </span>
          }
        >
          {showTeams && (
            <Select
              loading={loadingTeams}
              options={teams?.map((equipo) => {
                return {
                  value: equipo._id,
                  label: equipo.name
                };
              })}
              onChange={(v) => setSelectedTeam(v)}
              value={selectedTeam}
            />
          )}
        </Form.Item>
      )}
      {selectedTeam && !loadingUsers && (
        <Form.Item
          label={
            <span className='horizontal gap8'>
              <Switch
                size='small'
                onChange={(v) => {
                  setShowTeamAgents(v);
                  setSelectedAgent(null);
                }}
                checked={showTeamAgents}
              />
              <p>Asignar a un agente del equipo</p>
            </span>
          }
        >
          {showTeamAgents && (
            <Select
              loading={loadingUsers}
              options={getTeamAgents()}
              value={selectedAgent}
              onChange={(v) => setSelectedAgent(v)}
            />
          )}
        </Form.Item>
      )}
      {!showTeams && !loadingUsers && !loadingTeams && (
        <Form.Item
          label={
            <span className='horizontal gap8'>
              <Switch
                size='small'
                onChange={(v) => setShowAgents(v)}
                checked={showAgents}
              />
              <p>Asignar a un agente en particular</p>
            </span>
          }
        >
          {showAgents && (
            <Select
              loading={loadingUsers}
              options={usuarios?.map((element) => {
                return {
                  label: element.name,
                  value: element._id
                };
              })}
              value={selectedAgent}
              onChange={(v) => setSelectedAgent(v)}
            />
          )}
        </Form.Item>
      )}
      {!loadingTeams && !loadingUsers && (
        <>
          {selectedAgent && selectedTeam && (
            <span
              className='horizontal'
              style={{
                color: '#505050',
                alignItems: 'flex-start',
                marginBottom: '20px'
              }}
            >
              <span style={{ marginTop: '2px' }}>
                <BoxInfo
                  message='En caso de no encontrarse disponible el agente seleccionado
                  podrá tomarlo otro agente del equipo.'
                />
              </span>
            </span>
          )}
          {selectedAgent && !selectedTeam && (
            <span
              className='horizontal'
              style={{
                color: '#505050',
                alignItems: 'flex-start',
                marginBottom: '20px'
              }}
            >
              <span style={{ marginTop: '2px' }}>
                <BoxInfo
                  message='En caso de no encontrarse disponible el agente seleccionado se
                  asignará otro agente disponible.'
                />
              </span>
            </span>
          )}
          {selectedAgent && !selectedTeam && (
            <span
              className='horizontal'
              style={{
                color: '#505050',
                alignItems: 'flex-start',
                marginBottom: '20px'
              }}
            >
              <span style={{ marginTop: '2px' }}>
                <BoxInfo
                  message='En caso de no encontrarse disponible el agente seleccionado se
                  asignará otro agente disponible.'
                />
              </span>
            </span>
          )}
        </>
      )}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
