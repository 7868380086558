import axios from "axios";

export const getOpenAiKey = async (apiKey) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/integrations/openAI/getApiKey`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const upsertOpenAiKey = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/integrations/openAI/upsertApiKey`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteOpenAiKey = async (apiKey) => {
    try {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/integrations/openAI/deleteApiKey`,
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
      };
  
      const res = axios.request(config);
  
      return await res;
    } catch (error) {
      console.log(error);
    }
  };

  export const getAssistants = async (apiKey) => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/integrations/openAI/getAssistants`,
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
      };
  
      const res = axios.request(config);
  
      return await res;
    } catch (error) {
      console.log(error);
    }
  };