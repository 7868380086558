import axios from "axios";

export const getTemplates = async (apiKey) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/templates/getAllTemplates`,
      headers: {
        "Content-Type": "application/json",
        Authorization: apiKey,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
