export const templateCharLimits = {
  header: 60,
  body: 1085,
  footer: 60,
};

export const validateCharLimits = (templateVariables, xlsData, messageApi) => {
  let error = false;
  ["header", "body"].forEach((seccion) => {
    let { value: originalText, vars } = templateVariables[seccion];

    console.log(
      "TEMPLATE VARIABLES SECCION",
      seccion,
      templateVariables[seccion]
    );

    xlsData.forEach((fila, index) => {
      if (seccion === "header") {
        if (typeof originalText === "object" && originalText.link) {
        } else {
          originalText = originalText?.toString() || "";
          let texto = originalText?.replace(
            /\{\{(\d+)\}\}/g,
            (match, number) => {
              if (vars[number - 1]) {
                return fila[vars[number - 1]?.value];
              } else {
                return "variable aqui";
              }
            }
          );

          if (texto.length > templateCharLimits[seccion]) {
            error = true;
            messageApi.info(
              `El registro n° ${
                index + 1
              } excede el límite de caracteres en ${seccion?.toUpperCase()}`
            );
          }
        }
      } else {
        let texto = originalText?.replace(/\{\{(\d+)\}\}/g, (match, number) => {
          if (vars[number - 1]) {
            return fila[vars[number - 1]?.value];
          } else {
            return "variable aqui";
          }
        });

        if (texto.length > templateCharLimits[seccion]) {
          error = true;
          messageApi.info(
            `El registro n° ${
              index + 1
            } excede el límite de caracteres en ${seccion?.toUpperCase()}`
          );
        }
      }
    });
  });

  return error;
};
