import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, Select, Switch } from 'antd';
import { updateNode } from '../utils/updateNode';
import { FiTag } from 'react-icons/fi';
import { HeaderForm } from './componentsForm/HeaderForm';

export const TagContactForm = () => {
  const { messageApi, etiquetas } = useContext(GlobalContext);
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [selectedTag, setSelectedTag] = useState(null);

  const [addTag, setAddTag] = useState(true);

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (!selectedTag) {
      messageApi.info('Es necesario que seleccione una etiqueta');
      return;
    }

    values.tag = selectedTag;
    values.addTag = addTag;

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { label, tag, addTag } = nodoSeleccionado?.data;
      setNombreNodo(label || '');

      if (tag) {
        setSelectedTag(tag);
      }

      if (addTag === false) {
        setAddTag(false);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='form-formula'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<FiTag style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Switch
        checked={addTag}
        checkedChildren='Agregar etiqueta'
        unCheckedChildren='Quitar etiqueta'
        onChange={(v) => setAddTag(v)}
        style={{ width: 'fit-content', margin: '16px 0px' }}
      />
      <Form.Item label='Etiqueta'>
        <Select
          options={etiquetas.map((etiqueta) => {
            return { label: etiqueta.name, value: etiqueta._id };
          })}
          value={selectedTag || null}
          onChange={(v) => setSelectedTag(v)}
          placeholder='Seleccione una etiqueta'
        />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
