import { Button, Popover, Spin, Tag, Tooltip, Form, Input } from "antd";
import { Base64 } from "js-base64";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MdOutlinePause } from "react-icons/md";
import { TbLogs, TbTrash } from "react-icons/tb";
import { IoDuplicateOutline, IoMegaphoneOutline } from "react-icons/io5";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import {
  deleteCampaign,
  duplicateCampaign,
  updateStatus,
} from "../../../../helpers/fetchData/fetchCampaigns";
import { GlobalContext } from "../../../context/GlobalContext";
import dayjs from "dayjs";
import { accessDataFormat } from "../../../../helpers/access";
import { useAccess } from "../../../../hooks/useAccess";
import { IoIosStats } from "react-icons/io";
import LoadingIcon from "../../Loader/LoadingIcon";
import { CreatedBy } from "./CreatedBy";

const AccionesCampaigns = ({ item, setCampaigns }) => {
  const navigate = useNavigate();

  const { instance, messageApi } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);

  const [loading, setLoading] = useState(false); // Para cualquier acción

  const [showDuplicate, setShowDuplicate] = useState(false);

  const [duplicateData, setDuplicateData] = useState({
    name: "",
  });

  const [form] = Form.useForm();

  const isAllowed = useAccess();

  const handleNavigate = () => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/campaigns/details?payload=${data}`);
  };

  const goToData = () => {
    const { name, _id, contactsCount = 0, recipients = [], createdBy } = item;
    // let contactsCount = contactsCount || 0;

    let data = { _id, name, contactsCount, recipients, createdBy };
    let obj64 = Base64.encode(JSON.stringify(data), true);

    navigate(`/campaigns/data?data=${obj64}`);
  };

  const goToLogs = () => {
    const data = Base64.encode(
      JSON.stringify({ _id: item?._id, name: item.name }),
      true
    );
    navigate(`/campaigns/logs?payload=${data}`);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteCampaign(instance?.userData?.apiKey, id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Campaña eliminada correctamente");
          setCampaigns((prevState) => {
            let array = [];
            array = prevState.filter((element) => element._id !== id);

            return array;
          });

          setShowPopover(false);
        } else {
          messageApi.error("Ocurrió un error al eliminar una campaña");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al eliminar una campaña");
      })
      .finally(() => setLoading(false));
  };

  const handleDuplicar = () => {
    if (!duplicateData.name) {
      messageApi.info("Debe ingresar un nombre");
      return;
    }

    const obj = {
      ...item,
      ...duplicateData,
      status: "copied",
      contactsCount: null,
      recipients: null,
      updatedAt: null,
      createdAt: Date.now(),
    };

    delete obj._id;

    setLoading(true);

    duplicateCampaign(instance?.userData?.apiKey, obj)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Envío creado correctamente");
          const { insertedId } = res.data;

          setCampaigns((prevState) => {
            let array = [{ ...obj, _id: insertedId }, ...prevState];

            return array;
          });

          setShowDuplicate(false);
          setDuplicateData({
            name: "",
          });
        } else {
          messageApi.error("Ocurrió un error al duplicar una campaña");
        }
      })
      .catch((error) => {
        console.log("error", error);
        messageApi.error("Ocurrió un error al duplicar una campaña");
      })
      .finally(() => setLoading(false));
  };

  const handleDuplicateData = (value, type) => {
    setDuplicateData((prevState) => {
      let data = { ...prevState };

      data[type] = value;

      return data;
    });
  };

  const handleStatusChange = (status) => {
    setLoading(true);

    updateStatus(instance?.userData?.apiKey, item._id, { status: status })
      .then((res) => {
        if (res?.status === 200) {
          setCampaigns((prevState) => {
            let array =
              prevState?.map((element) => {
                if (element?._id === item?._id) {
                  return { ...element, status: status };
                }
                return element;
              }) || [];

            return array;
          });
        } else {
          messageApi.error(
            "Ocurrió un error al actualizar el estado de una campaña"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        messageApi.error(
          "Ocurrió un error al actualizar el estado de una campaña"
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <span
      className="horizontal gap8"
      style={{ justifyContent: "center", fontSize: 18 }}
    >
      <Tooltip className="pointer" title="Ver">
        <span>
          <FiEye onClick={handleNavigate} />
        </span>
      </Tooltip>
      {item?.status !== "pending" && (
        <>
          {isAllowed(accessDataFormat("broadcast", "show")) && (
            <Tooltip className="pointer" title="Estadísticas">
              <span>
                <IoIosStats onClick={goToData} />
              </span>
            </Tooltip>
          )}
        </>
      )}

      {isAllowed(accessDataFormat("broadcast", "manage")) && (
        <Tooltip className="pointer" title="Copiar">
          <span>
            <IoDuplicateOutline onClick={() => setShowDuplicate(true)} />
          </span>
        </Tooltip>
      )}

      {item?.status?.toLowerCase() === "pending" &&
        isAllowed(accessDataFormat("broadcast", "manage")) && (
          <Tooltip className="pointer" title="Pausar">
            <span>
              <MdOutlinePause onClick={() => handleStatusChange("stopped")} />
            </span>
          </Tooltip>
        )}

      {/* {item?.status?.toLowerCase() === "stopped" &&
        isAllowed(accessDataFormat("broadcast", "manage")) && (
          <Tooltip className="pointer" title="Activar">
            <span>
              <IoPlay onClick={() => handleStatusChange("pending")} />
            </span>
          </Tooltip>
        )} */}

      {showDuplicate && (
        <div className="confirm-wrapper" style={{ position: "fixed" }}>
          <Form
            className="confirm-form"
            style={{ padding: "16px" }}
            onClick={(e) => e.stopPropagation()}
            onFinish={handleDuplicar}
            layout="vertical"
            form={form}
          >
            <b>Duplicar envío</b>
            <Form.Item label="Nombre">
              <Input
                value={duplicateData.name}
                onChange={(v) => handleDuplicateData(v.target.value, "name")}
              />
            </Form.Item>

            <div
              className="botones-wrapper-content"
              style={{ marginTop: "8px", width: "100%" }}
            >
              <Button
                type="primary"
                className="btn-guardar"
                htmlType="submit"
                icon={
                  loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
                }
                style={{ opacity: loading ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                className="btn-cancelar"
                type="secondary"
                onClick={() => setShowDuplicate(false)}
              >
                Cancelar
              </Button>
            </div>
          </Form>
        </div>
      )}

      {isAllowed(accessDataFormat("broadcast", "manage")) && (
        <>
          <Tooltip title="Logs" className="pointer">
            <span>
              <TbLogs onClick={goToLogs} />
            </span>
          </Tooltip>

          <Popover
            trigger="click"
            placement="bottomRight"
            open={showPopover}
            content={
              <div className="columna-simple">
                <span>{`¿Está seguro de borrar ${item?.name}?`}</span>
                <p
                  style={{
                    color: "#858585",
                    fontSize: 12,
                    margin: "8px 0px 16px",
                    fontWeight: 400,
                  }}
                >
                  Al eliminar se borrarán tambien las métricas asociadas
                </p>

                <div
                  className="botones-wrapper-content"
                  style={{ width: "100%" }}
                >
                  <Button
                    type="primary"
                    className="btn-borrar"
                    onClick={() => handleDelete(item._id)}
                    icon={
                      loading ? (
                        <LoadingIcon size="small" color="#FFFFFF" />
                      ) : null
                    }
                    style={{ opacity: loading ? 0.65 : 1 }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    className="btn-cancelar"
                    type="secondary"
                    onClick={() => setShowPopover(false)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            }
          >
            <span>
              <Tooltip className="pointer" title="Eliminar">
                <span>
                  <TbTrash onClick={() => setShowPopover(true)} />
                </span>
              </Tooltip>
            </span>
          </Popover>
        </>
      )}
    </span>
  );
};

export const getContactosCount = (schedule) => {
  return schedule?.reduce((total, item) => {
    return (total += item.contacts?.length);
  }, 0);
};

const getCampaignMetrics = (id, metricas) => {
  let campaign = metricas.find((element) => element.campaignId === id);

  if (!campaign?.data) return { sent: 0, delivered: 0, read: 0 };

  let values = campaign.data.reduce(
    (total, envio) => {
      return {
        sent: (total.sent += envio?.sent || 0),
        delivered: (total.delivered += envio?.delivered || 0),
        read: (total.read += envio?.read || 0),
      };
    },
    {
      sent: 0,
      delivered: 0,
      read: 0,
    }
  );

  return values;
};

const renderLoader = (size = 20) => {
  return (
    <span
      style={{ height: 60, width: 79, display: "grid", placeItems: "center" }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: size,
            }}
            spin
          />
        }
      />
    </span>
  );
};

export const columnsCampaigns = (
  campaignsMetrics,
  navigate,
  loadingGraphs,
  setCampaigns
) => {
  const handleNavigate = (item) => {
    const data = Base64.encode(JSON.stringify(item), true);
    navigate(`/campaigns/details?payload=${data}`);
  };

  return [
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      render: (data, item) => (
        <span
          className="horizontal gap8"
          onClick={() => handleNavigate(item)}
          style={{ cursor: "pointer" }}
        >
          <IoMegaphoneOutline
            style={{ color: "var(--dark-color)" }}
            size={20}
          />
          <b>{data}</b>
        </span>
      ),
    },
    {
      title: "Plantilla",
      key: "template",
      dataIndex: "template",
      render: (data) => {
        return data?.template?.name;
      },
    },
    {
      title: "Fecha de creación",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "center",
      render: (data) => {
        return dayjs(data, "x").format("DD/MM/YYYY");
      },
    },
    {
      title: "Creado por",
      key: "createdBy",
      dataIndex: "createdBy",
      align: "center",
      render: (createdBy) => <CreatedBy userId={createdBy} />,
    },
    {
      title: "Fecha programada",
      key: "schedule",
      dataIndex: "schedule",
      align: "center",
      render: (data) => {
        const wholeData = (
          <span
            className="horizontal gap4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: 222,
              flexWrap: "wrap",
            }}
          >
            {data.map((schedule, index) => (
              <Tag color="geekblue" key={index}>
                {schedule.date}
              </Tag>
            ))}
          </span>
        );

        if (data.length > 2) {
          return (
            <Popover content={wholeData}>
              <span className="horizontal gap4">
                {data.slice(0, 2).map((schedule, index) => (
                  <Tag color="geekblue" key={index}>
                    {schedule.date}
                  </Tag>
                ))}
                <p>...</p>
              </span>
            </Popover>
          );
        }

        return wholeData;
      },
    },
    {
      title: "Destinatarios",
      key: "contactsCount",
      dataIndex: "contactsCount",
      align: "center",
      render: (data) => data || 0,
    },
    {
      title: "Estado",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (data) => handleStatus(data),
    },
    // {
    //   title: "Enviados",
    //   key: "",
    //   dataIndex: "",
    //   align: "center",
    //   width: 60,
    //   render: (data, item) => {
    //     if (loadingGraphs) return renderLoader();

    //     const metricas = getCampaignMetrics(item._id, campaignsMetrics);
    //     return (
    //       <span className="pie-graph-wrapper" key="sent">
    //         <PieGraph
    //           data={metricas}
    //           type="sent"
    //           contactosCount={item.contactsCount}
    //         />
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Entregados",
    //   key: "",
    //   dataIndex: "",
    //   align: "center",
    //   width: 60,
    //   render: (data, item) => {
    //     if (loadingGraphs) return renderLoader();

    //     const metricas = getCampaignMetrics(item._id, campaignsMetrics);
    //     return (
    //       <span className="pie-graph-wrapper" key="delivered">
    //         <PieGraph
    //           data={metricas}
    //           type="delivered"
    //           contactosCount={item.contactsCount}
    //         />
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Leídos",
    //   key: "",
    //   dataIndex: "",
    //   align: "center",
    //   width: 60,
    //   render: (data, item) => {
    //     if (loadingGraphs) return renderLoader();

    //     const metricas = getCampaignMetrics(item._id, campaignsMetrics);
    //     return (
    //       <span className="pie-graph-wrapper" key="read">
    //         <PieGraph
    //           data={metricas}
    //           type="read"
    //           contactosCount={item.contactsCount}
    //         />
    //       </span>
    //     );
    //   },
    // },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      render: (data, item) => {
        return (
          <AccionesCampaigns
            item={item}
            key={item._id}
            setCampaigns={setCampaigns}
          />
        );
      },
    },
  ];
};

// export const COLORES_PIE = ['#598F3B', '#B34949'];
export const COLORES_PIE = ["#8cac29", "#F69C41"];

export const handleStatus = (status) => {
  // if (!status) {
  //   return <></>;
  // }

  switch (status?.toLowerCase()) {
    case "sent":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Enviado
        </Tag>
      );
    case "copied":
      return (
        <Tag icon={<CopyOutlined />} color="blue">
          Copiado
        </Tag>
      );
    case "stopped":
      return (
        <Tag icon={<PauseCircleOutlined />} color="gold">
          Pausado
        </Tag>
      );
    case "pending":
    default:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          Pendiente
        </Tag>
      );
  }
};
