import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { FlowContext } from "../../../../context/FlowContext";
import { Button, Form, Input, Select } from "antd";
import { updateNode } from "../utils/updateNode";
import { getFlows } from "../../../../../helpers/fetchData/fetchFlows";
import { LiaRobotSolid } from "react-icons/lia";
import { HeaderForm } from "./componentsForm/HeaderForm";

export const GoToFlowForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");

  const [flows, setFlows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [loading, setLoading] = useState(true);

  const onFinish = (values) => {
    values.label = nombreNodo;

    if (!selectedFlow) {
      messageApi.info("Es necesario que seleccione un envío masivo");
      return;
    }

    values.flowId = selectedFlow;

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { label, flowId } = nodoSeleccionado?.data;
      setNombreNodo(label || "");

      if (flowId) {
        setSelectedFlow(flowId);
      }
    }
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getFlows(setFlows, messageApi, setLoading, instance);
    }
  }, [instance]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { flowId } = nodoSeleccionado.data;

      if (flowId) {
        setSelectedFlow(flowId);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="form-formula"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <LiaRobotSolid style={{ color: "var(--dark-color)" }} size={32} />
        }
      />

      <Form.Item label="Seleccione un bot">
        <Select
          options={
            flows
              ?.filter((element) => element.status?.toLowerCase() === "publish")
              ?.map((flow) => {
                return {
                  label: flow.name,
                  value: flow._id,
                };
              }) || []
          }
          value={loading ? null : selectedFlow || null}
          onChange={(v) => setSelectedFlow(v)}
          loading={loading}
          placeholder="Seleccione un bot"
        />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
