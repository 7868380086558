export const FORMULAS = [
  {
    label: 'Operaciones matemáticas',
    value: 'math',
    formulas: [
      {
        value: 'sum',
        label: 'Suma dos valores (sum)',
        multiple: 2
      },
      {
        value: 'subtract',
        label: 'Resta el segundo valor del primero (subtract)',
        multiple: 2
      },
      {
        value: 'times',
        label: 'Multiplica dos valores (times)',
        multiple: 2
      },
      {
        value: 'divide',
        label: 'Divide el primer valor por el segundo (divide)',
        multiple: 2
      },
      {
        value: 'toFloat',
        label: 'Convierte el valor a punto flotante (toFloat)',
        multiple: false
      },
      {
        value: 'toInteger',
        label: 'Convierte el valor a entero (toInteger)',
        multiple: false
      },
      {
        value: 'quotient',
        label: 'Devuelve la parte entera de la división (quotient)',
        multiple: 2
      },
      {
        value: 'remainder',
        label: 'Devuelve el resto de la división (remainder)',
        multiple: 2
      },
      {
        value: 'round',
        label: 'Redondea el valor al entero más cercano (round)',
        multiple: false
      },
      {
        value: 'random',
        label: 'Genera un número aleatorio (random)',
        multiple: 2
      },
      {
        value: 'truncate',
        label: 'Devuelve la parte entera de un número (truncate)',
        multiple: false
      },
    ]
  },
  {
    label: 'Operaciones de comparación',
    value: 'compare',
    formulas: [
      {
        value: 'equal',
        label: 'Verifica si dos valores son iguales (equal)',
        multiple: 2
      },
      {
        value: 'notEqual',
        label: 'Verifica si dos valores no son iguales (notEqual)',
        multiple: 2
      },
      {
        value: 'greater',
        label: 'Verifica si el primer valor es mayor que el segundo (greater)',
        multiple: 2
      },
      {
        value: 'greaterOrEqual',
        label: 'Verifica si el primer valor es mayor o igual al segundo (greaterOrEqual)',
        multiple: 2
      },
      {
        value: 'less',
        label: 'Verifica si el primer valor es menor que el segundo (less)',
        multiple: 2
      },
      {
        value: 'lessOrEqual',
        label: 'Verifica si el primer valor es menor o igual al segundo (lessOrEqual)',
        multiple: 2
      }
    ]
  },
  {
    label: 'Operaciones de texto',
    value: 'text',
    formulas: [
      {
        value: 'length',
        label: 'Retorna la longitud de una cadena (length)',
        multiple: false
      },
      {
        value: 'toUpper',
        label: 'Convierte toda la cadena a mayúsculas (toUpper)',
        multiple: false
      },
      {
        value: 'toLower',
        label: 'Convierte toda la cadena a minúsculas (toLower)',
        multiple: false
      },
      {
        value: 'capitalize',
        label: 'Primer letra de cadena en mayúscula (capitalize)',
        multiple: false
      },
      {
        value: 'toString',
        label: 'Convierte un valor en una cadena (toString)',
        multiple: false
      },
      {
        value: 'trim',
        label: 'Elimina espacios en blanco de extremos (trim)',
        multiple: false
      },
      {
        value: 'contains',
        label: 'Verifica si una cadena contiene una subcadena (contains)',
        multiple: 2
      },
      {
        value: 'concat',
        label: 'Concatena (une) dos cadenas (concat)',
        multiple: 2
      },
      {
        value: 'replace',
        label: 'Reemplaza una parte de una cadena por otra (replace)',
        multiple: 3,
        titles: [
          'ingrese un valor a modificar (input)',
          'ingrese el valor a reemplazar (input)',
          'ingrese el nuevo valor (input)'
        ]
      },
      {
        value: 'slice',
        label: 'Extrae una parte de una cadena (slice)',
        multiple: 3,
        titles: [
          'ingrese un valor a modificar (input)',
          'ingrese el valor inicial (indice) (input)',
          'ingrese el valor final (indice - opcional) (input)'
        ]
      },
    ]
  },
  {
    label: 'Operaciones con fechas',
    value: 'date',
    formulas: [
      {
        value: 'eeuu',
        label: 'Estadounidense (MM DD YYYY)',
        multiple: false,
        separators: [
          {
            value: '/',
            date_format: [
              {
                value: 'MM/DD/YYYY HH:mm:ss',
                label: 'MM/DD/YYYY HH:mm:ss (07/23/2024 08:30:00)'
              },
              {
                value: 'MM/DD/YYYY HH:mm',
                label: 'MM/DD/YYYY HH:mm (07/23/2024 08:30)'
              },
              {
                value: 'MM/DD/YYYY',
                label: 'MM/DD/YYYY (07/23/2024)'
              },
              {
                value: 'MM/YYYY',
                label: 'MM/YYYY (07/2024)'
              },
              {
                value: 'MM/YY',
                label: 'MM/YY (07/24)'
              },
              {
                value: 'MM/DD',
                label: 'MM/DD (07/23)'
              }
            ]
          },
          {
            value: '-',
            date_format: [
              {
                value: 'MM-DD-YYYY HH:mm:ss',
                label: 'MM-DD-YYYY HH:mm:ss (07-23-2024 08:30:00)'
              },
              {
                value: 'MM-DD-YYYY HH:mm',
                label: 'MM-DD-YYYY HH:mm (07-23-2024 08:30)'
              },
              {
                value: 'MM-DD-YYYY',
                label: 'MM-DD-YYYY (07-23-2024)'
              },
              {
                value: 'MM-YYYY',
                label: 'MM-YYYY (07-2024)'
              },
              {
                value: 'MM-YY',
                label: 'MM-YY (07-24)'
              },
              {
                value: 'MM-DD',
                label: 'MM-DD (07-23)'
              }
            ]
          }
        ]
      },
      {
        value: 'europe',
        label: 'Europeo (DD MM YYYY)',
        multiple: false,
        separators: [
          {
            value: '/',
            date_format: [
              {
                value: 'DD/MM/YYYY HH:mm:ss',
                label: 'DD/MM/YYYY HH:mm:ss (23/07/2024 08:30:00)'
              },
              {
                value: 'DD/MM/YYYY HH:mm',
                label: 'DD/MM/YYYY HH:mm (23/07/2024 08:30)'
              },
              {
                value: 'DD/MM/YYYY',
                label: 'DD/MM/YYYY (23/07/2024)'
              },
              {
                value: 'MM/YYYY',
                label: 'MM/YYYY (07/2024)'
              },
              {
                value: 'MM/YY',
                label: 'MM/YY (07/24)'
              },
              {
                value: 'DD/MM',
                label: 'DD/MM (23/07)'
              }
            ]
          },
          {
            value: '-',
            date_format: [
              {
                value: 'DD-MM-YYYY HH:mm:ss',
                label: 'DD-MM-YYYY HH:mm:ss (23-07-2024 08:30:00)'
              },
              {
                value: 'DD-MM-YYYY HH:mm',
                label: 'DD-MM-YYYY HH:mm (23-07-2024 08:30)'
              },
              {
                value: 'DD-MM-YYYY',
                label: 'DD-MM-YYYY (23-07-2024)'
              },
              {
                value: 'MM-YYYY',
                label: 'MM-YYYY (07-2024)'
              },
              {
                value: 'MM-YY',
                label: 'MM-YY (07-24)'
              },
              {
                value: 'DD-MM',
                label: 'DD-MM (23-07)'
              }
            ]
          }
        ]
      },
      {
        value: 'iso',
        label: 'ISO 8601 (YYYY MM DD)',
        multiple: false,
        separators: [
          {
            value: '/',
            date_format: [
              {
                value: 'YYYY/MM/DD HH:mm:ss',
                label: 'YYYY/MM/DD HH:mm:ss (2024/07/23 08:30:00)'
              },
              {
                value: 'YYYY/MM/DD HH:mm',
                label: 'YYYY/MM/DD HH:mm (2024/07/23 08:30)'
              },
              {
                value: 'YYYY/MM/DD',
                label: 'YYYY/MM/DD (2024/07/23)'
              },
              {
                value: 'YYYY/MM',
                label: 'YYYY/MM (2024/07)'
              },
              {
                value: 'YY/MM',
                label: 'YY/MM (24/07)'
              },
              {
                value: 'MM/DD',
                label: 'MM/DD (07-23)'
              }
            ]
          },
          {
            value: '-',
            date_format: [
              {
                value: 'YYYY-MM-DD HH:mm:ss',
                label: 'YYYY-MM-DD HH:mm:ss (2024-07-23 08:30:00)'
              },
              {
                value: 'YYYY-MM-DD HH:mm',
                label: 'YYYY-MM-DD HH:mm (2024-07-23 08:30)'
              },
              {
                value: 'YYYY-MM-DD',
                label: 'YYYY-MM-DD (2024-07-23)'
              },
              {
                value: 'YYYY-MM',
                label: 'YYYY-MM (2024-07)'
              },
              {
                value: 'YY-MM',
                label: 'YY-MM (24-07)'
              },
              {
                value: 'MM-DD',
                label: 'MM-DD (07-23)'
              }
            ]
          }
        ]
      },
      {
        value: 'large',
        label: 'Largo',
        multiple: false,
        language: [
          {
            value: 'es',
            date_format: [
              {
                value: 'dddd, D [de] MMMM, YYYY', // Lunes, 23 de septiembre, 2024
                label: 'dddd, D [de] MMMM, YYYY (lunes, 23 de septiembre, 2024)'
              },
              {
                value: 'D [de] MMMM, YYYY', // 23 de septiembre, 2024
                label: 'D [de] MMMM, YYYY (23 de septiembre, 2024)'
              },
              {
                value: 'dddd, D [de] MMMM', // Lunes, 23 de Septiembre
                label: 'dddd, D [de] MMMM (lunes, 23 de septiembre)'
              },
              {
                value: 'D [de] MMMM', // 23 de Septiembre
                label: 'D [de] MMMM (23 de septiembre)'
              },
              {
                value: 'dddd', // Lunes
                label: 'dddd (lunes)'
              },
              {
                value: 'MMMM', // Septiembre
                label: 'MMMM (septiembre)'
              },
              {
                value: 'YYYY', // 2024
                label: 'YYYY (2024)'
              },
              {
                value: 'M', // MES 1 (1-12)
                label: 'Mes (1-12)'
              },
              {
                value: 'D', // Dia 1 (1-31)
                label: 'Dia (1-31)'
              },
              {
                value: 'dddd, HH:mm', // lunes, 20:30hs
                label: 'dddd, HH:mm (lunes, 20:30)'
              },
            ]
          },
          {
            value: 'en',
            date_format: [
              {
                value: 'dddd, MMMM D, YYYY', // Monday, September 23, 2024
                label: 'dddd, MMMM D, YYYY (Monday, September 23, 2024)'
              },
              {
                value: 'MMMM D, YYYY', // September 23, 2024
                label: 'MMMM D, YYYY (September 23, 2024)'
              },
              {
                value: 'dddd, MMMM D', // Monday, September 23
                label: 'dddd, MMMM D (Monday, September 23)'
              },
              {
                value: 'MMMM D', // September 23
                label: 'MMMM D (September 23)'
              },
              {
                value: 'dddd', // Monday
                label: 'dddd (Monday)'
              },
              {
                value: 'MMMM', // September
                label: 'MMMM (September)'
              },
              {
                value: 'YYYY', // 2024
                label: 'YYYY (2024)'
              },
              {
                value: 'M', // MES 1 (1-12)
                label: 'Month (1-12)'
              },
              {
                value: 'D', // Dia 1 (1-31)
                label: 'Day (1-31)'
              },
              {
                value: 'dddd, HH:mm', // Monday, 20:30hs
                label: 'dddd, HH:mm (Monday, 20:30)'
              },
            ]
          }
        ]
      },
      {
        value: 'time',
        label: 'Horario',
        multiple: false,
        date_format: [
          {
            value: 'HH:mm:ss',
            label: 'HH:mm:ss (08:30:00)'
          },
          {
            value: 'HH:mm',
            label: 'HH:mm (08:30)'
          },
          {
            value: 'HH',
            label: 'HH (08)'
          },
          {
            value: 'mm',
            label: 'mm (30)'
          },
          {
            value: 'ss',
            label: 'ss (00)'
          },
        ]
      },
      {
        value: 'timestamp',
        label: 'Timestamp (Unix)',
        multiple: false,
        date_format: [
          {
            value: 'x',
            label: 'Unix ms timestamp (1410715640579)'
          },
          {
            value: 'X',
            label: 'Unix sec timestamp (1410715640.579)'
          }
        ]
      }
    ],
  },
  {
    label: 'Operaciones con variables',
    value: 'variable',
    formulas: [
      {
        value: 'setValue',
        label: 'Asignar valor',
        multiple: false,
        titles: [
          'ingrese el nuevo valor para '
        ],
        hasVariableLabel: true
      }
    ]
  },
];



export const SEPARATORS = [
  {
    value: '/',
    label: '/'
  },
  {
    value: '-',
    label: '-'
  }
];


export const DAYJS_LOCALE = [
  {
    value: 'es',
    label: 'Español',
    days: ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo']
  },
  {
    value: 'en',
    label: 'Ingles',
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  }
];

export const TIME_ZONES = [
  { value: 'UTC', label: 'UTC (GMT+0)' },
  { value: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires (GMT-3)' },
  { value: 'America/New_York', label: 'New York (GMT-4)' },
  { value: 'America/Los_Angeles', label: 'Los Angeles (GMT-7)' },
  { value: 'America/Bogota', label: 'Bogotá (GMT-5)' },
  { value: 'America/Mexico_City', label: 'Ciudad de México (GMT-5)' },
  { value: 'America/Sao_Paulo', label: 'São Paulo (GMT-3)' },
  { value: 'America/Santiago', label: 'Santiago, Chile (GMT-3)' },
  { value: 'America/Montevideo', label: 'Montevideo, Uruguay (GMT-3)' },
  { value: 'Europe/Madrid', label: 'Madrid, Spain (GMT+2)' },
  { value: 'Europe/London', label: 'London (GMT+1)' },
  { value: 'Europe/Paris', label: 'Paris (GMT+2)' },
  { value: 'Asia/Tokyo', label: 'Tokyo (GMT+9)' },
  { value: 'Asia/Shanghai', label: 'Shanghai (GMT+8)' },
  { value: 'Australia/Sydney', label: 'Sydney (GMT+10)' }
];
