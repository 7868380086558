import axios from "axios";

export const deleteTabla = async (_id, instance) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/tablas/deleteTabla/${_id}`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};