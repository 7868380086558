import { FaCircle } from 'react-icons/fa';
import { Tooltip } from 'antd';
import {
  getColorVerification,
  getTitleVerification
} from '../../utils/getVerification';
import { BoxInfoPopUp } from '../../../../../../box-info/BoxInfoPopUp';

export const MetaVerification = ({ businessCompliance }) => {
  const colorVerification = getColorVerification(businessCompliance);
  const titleVerification = getTitleVerification(businessCompliance);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 25
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <span>Verificación de Meta</span>
        <BoxInfoPopUp
          hideMode
          message={
            <p>
              La <b>Verificación de Meta</b> es un proceso que permite confirmar
              la autenticidad de tu empresa.
            </p>
          }
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <FaCircle fill={colorVerification} size={8} />
        <Tooltip title={titleVerification}>
          <span
            style={{
              color: colorVerification,
              fontSize: 13,
              fontWeight: 500,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: 150
            }}
          >
            {titleVerification}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
