export const actualizarLista = (
  ambito,
  data,
  setElements,
  etiquetas,
  contactos, 
  _id
) => {
  //! Corregir, necesito _ids para actualizar y que quede funcional

  let array = [];
  if (ambito === "etiquetas") {
    setElements((prevState) => {
      array = [data, ...prevState];
      // let nuevoTag = {
      //   _id: Date.now(),
      //   name: data.name.toUpperCase(),
      //   description: data.description,
      //   color: data.color,
      //   instanceId: null,
      // };

      return array;
    });
  } else {

    setElements((prevState) => {
      array = [...prevState];

      let nuevoContacto = {
        _id: _id,
        ...data,
      };

      array.push(nuevoContacto);

      return array;
    });
  }
};
