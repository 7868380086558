import axios from "axios";

export const putTabla = async (data, instance) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/tablas/editTabla`,
      data: data,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};