export const getCursorPosition = (id) => {
  let input = document.getElementById(id);

  let cursorPosition = null;

  if (input) {
    cursorPosition = input.selectionStart;
  }

  return cursorPosition;
};
