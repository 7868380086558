import axios from "axios";

export const newStatus = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetStatuses`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editStatus = async (apiKey, id, data) => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetStatuses/${id}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteStatus = async (apiKey, id) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetStatuses/${id}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
