export const getCurrencyByList = (catalogDetail, selectedListId) => {
  if (selectedListId === "sin lista") return null;
  if (catalogDetail?.lists) {
    return (
      catalogDetail?.lists?.find((lista) => lista.id === selectedListId)
        ?.currency || ""
    );
  }
};

export const getCurrencyByListForTable = (catalogDetail, selectedListId) => {
  if (selectedListId === "sin lista") return "";
  if (catalogDetail?.lists) {
    return (
      catalogDetail?.lists?.find((lista) => lista.id === selectedListId)
        ?.currency || ""
    );
  }
};

export const handlePriceByList = (setPricesByList, index, value) => {
  setPricesByList((prevState) => {
    let array = [...prevState];

    if (index >= 0) {
      array[index] = { ...array[index], price: value };
    }

    return array;
  });
};
