import axios from 'axios';

export const fetchProfile = (
  application,
  setWhatsappProfile,
  setLoadingProfile,
  messageApi,
  apiKey
) => {
  if (application?.length > 0) {
    let count = 0;
    let fetch = () => {
      if (count <= 5) {
        count += 1;
        setLoadingProfile(true);
        getProfile(application[0], apiKey)
          .then((res) => {
            if (res?.status === 200) {
              setWhatsappProfile([res.data]);
            } else {
              setTimeout(() => {
                fetch();
              }, 2000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              fetch();
            }, 2000);
          })
          .finally(() => setLoadingProfile(false));
      } else {
        messageApi.error('Error al obtener perfiles');
      }
    };

    fetch();
  }
};

export const getProfile = async (application, apiKey) => {
  try {
    if (application) {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_PRODUCCION}/wb/getProfileInfo`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: apiKey
        }
      };
      
      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

//?phoneNumberId=${application.lines[0].phoneNumberId}&accessToken=${application.token}

export const getBusinessCompliance = async (application, apiKey) => {
  try {
    if (application) {
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_PRODUCCION}/wb/getBusinessCompliance`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: apiKey
        }
      };

      const res = await axios(config);

      return res;
    }
  } catch (err) {
    console.log(err);
  }
};
