import { useContext, useState } from "react";
import { TemplateContext } from "../../../../../context/TemplateContext";
import { OpcionesTemplate } from "./OpcionesTemplate";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { NewTemplate } from "./NewTemplate";

const CreateTemplate = () => {
  const { templateStage } = useContext(GlobalContext);

  const [templateSelection, setTemplateSelection] = useState("");
  const [preview, setPreview] = useState("");

  const handleTemplateStage = () => {
    switch (templateStage) {
      case 0:
        return <OpcionesTemplate />;
      case 1:
        return <NewTemplate />;
      default:
        return <OpcionesTemplate />;
    }
  };

  return (
    <TemplateContext.Provider
      value={{
        templateSelection,
        setTemplateSelection,
        preview,
        setPreview,
      }}
    >
      {handleTemplateStage()}
    </TemplateContext.Provider>
  );
};

export default CreateTemplate;
