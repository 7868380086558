import { Tag, Tooltip } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { MdOutlineBlock } from "react-icons/md";
import { updateUserAccess } from "../../../../../helpers/fetchData/fetchInstance";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FiEdit3 } from "react-icons/fi";
import LoadingIcon from "../../../Loader/LoadingIcon";

export const ROLE_PERMISSIONS = {
  bots: [0],
  contacts: [0],
  broadcast: [0],
  settings: [0],
  workspaces: [0],
  channels: [0],
  users: [0],
  teams: [0],
  templates: [0],
  tags: [0],
  integrations: [0],
  clients: [0],

  chat: [0],
  catalog: [0],
  budgets: [0],
  chatSettings: [0],
};

export const ROLE_PERMISSIONS_MODULES = [
  // { id: "chat", label: "Conversaciones" },
  // { id: "budgets", label: "Presupuestos" },
  { id: "contacts", label: "Contactos" },
  { id: "clients", label: "Clientes" },
  { id: "bots", label: "Bots" },
  { id: "broadcast", label: "Campañas" },
  { id: "settings", label: "Configuración" },
];

export const ROLE_PERMISSIONS_SETTINGS = [
  { id: "workspaces", label: "Workspaces" },
  { id: "channels", label: "Canales" },
  { id: "users", label: "Usuarios y roles" },
  { id: "teams", label: "Equipos" },
  { id: "templates", label: "Plantillas" },
  // { id: "catalog", label: "Productos y servicios" },
  { id: "tags", label: "Etiquetas" },
  { id: "integrations", label: "Integraciones" },
  // { id: "chatSettings", label: "Chat" },
];

export const RADIO_OPTIONS = [
  { value: 0, label: "Esconder" },
  { value: 1, label: "Mostrar" },
  { value: 2, label: "Gestionar" },
];

export const columnasInvitaciones = () => {
  return [
    {
      title: "Enviado a",
      key: "emailTo",
      dataIndex: "emailTo",
      render: (data, item) => {
        return data;
      },
    },
    {
      title: "Invitado por",
      key: "invitedBy",
      dataIndex: "invitedBy",
      render: (data) => (
        <span className="columna gap0">
          <p>{data.name}</p>
          <p style={{ color: "#7a7c7f" }}>{data.email}</p>
        </span>
      ),
    },
    {
      title: "Estado",
      key: "status",
      dataIndex: "status",
      render: (data) => renderTag(data),
    },
    {
      title: "...",
      key: "",
      dataIndex: "...",
      align: "center",
      render: (data) => {
        return "";
      },
    },
  ];
};

const SIZE = 18;

const isAdmin = (userData) => {
  return userData?.roleInfo?.name === "ADMIN";
};

const AccionesUsuarios = ({ item, setUsuarios, setDrawerData }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const { userData } = instance;

  const [loading, setLoading] = useState(false);

  const handleUserStatus = (status) => {
    if (userData?.apiKey && item?._id) {
      const data = { status: status, userId: item._id };

      setLoading(true);
      updateUserAccess(userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Estado actualizado correctamente");
            setUsuarios((prevState) => {
              let array = [...prevState];

              array = array.map((element) => {
                if (element._id === item._id) {
                  element.association.status = status;
                }

                return element;
              });

              return array;
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  return (
    <span className="horizontal gap8" style={{ justifyContent: "center" }}>
      {isAdmin(userData) &&
        item?._id !== userData?.userId &&
        (item.association?.status === false ? (
          <Tooltip title="Activar">
            <span className="icono-acciones">
              {loading ? (
                <LoadingIcon size="default" />
              ) : (
                <FaRegCircleCheck
                  size={SIZE}
                  onClick={() => handleUserStatus(true)}
                />
              )}
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Desactivar">
            <span className="icono-acciones">
              {loading ? (
                <LoadingIcon size="default" />
              ) : (
                <MdOutlineBlock
                  size={SIZE}
                  onClick={() => handleUserStatus(false)}
                />
              )}
            </span>
          </Tooltip>
        ))}
      {isAdmin(userData) && (
        <Tooltip title="Editar">
          <span className="icono-acciones">
            <FiEdit3
              size={SIZE}
              onClick={() => {
                setDrawerData({ open: true, data: item });
              }}
            />
          </span>
        </Tooltip>
      )}
    </span>
  );
};

const renderRole = (roles, user) => {
  if (roles && user) {
    const role = roles.find(
      (element) => element._id === user.association.roleId
    );

    return role?.name || "";
  }

  return "";
};

export const columnasUsuarios = (setUsuarios, setDrawerData, roles) => {
  return [
    {
      title: "Usuario",
      key: "name",
      dataIndex: "name",
      render: (data, item) => {
        return (
          <span className="columna gap0">
            <p>{item.name}</p>
            <p style={{ color: "#7a7c7f" }}>{item.email}</p>
          </span>
        );
      },
    },
    {
      title: "Rol",
      key: "",
      dataIndex: "",
      render: (data, item) => {
        return <Tag color="geekblue">{renderRole(roles, item)}</Tag>;
      },
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      render: (data, item) => (
        <AccionesUsuarios
          item={item}
          setUsuarios={setUsuarios}
          setDrawerData={setDrawerData}
        />
      ),
    },
  ];
};

const renderTag = (status) => {
  if (status?.toLowerCase() === "canceled") {
    return <Tag color="volcano">Rechazado</Tag>;
  } else {
    return <Tag color="orange">Pendiente</Tag>;
  }
};
