import { Button, Result } from "antd";
import React from "react";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { IoLockClosedOutline } from "react-icons/io5";
import LoadingIcon from '../../../Loader/LoadingIcon';

export const Expired = ({
  setIsNota,
  setConfigTemplate,
  loadingNewChat,
  sendDefaultTemplate,
  defaultTemplate,
  loadingDefaultTemplate,
}) => {
  return (
    <span className="chat-inner-input-wrapper">
      <Result
        icon={<IoLockClosedOutline size={24} />}
        style={{ padding: "0px" }}
        status="info"
        title={
          <p style={{ fontSize: "15px" }}>
            La ventana de conversación ha expirado
          </p>
        }
        subTitle={
          <p style={{ fontSize: "13px" }}>
            Utilice una plantilla para iniciar una conversación con este
            contacto nuevamente
          </p>
        }
        extra={[
          <span
            className="horizontal"
            style={{ justifyContent: "center" }}
            key="extra-buttons"
          >
            {defaultTemplate && (
              <Button
                type="primary"
                onClick={() => sendDefaultTemplate()}
                className="horizontal gap0"
                // loading={loadingDefaultTemplate}
                icon={loadingDefaultTemplate ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
                style={{ opacity: loadingDefaultTemplate ? 0.65 : 1 }}
              >
                Inicio rápido
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => setConfigTemplate({ open: true, data: null })}
            >
              <span className="horizontal">
                <span style={{ height: "14px", display: "flex" }}>
                  <FaRegFilePowerpoint />
                </span>
                <p>Seleccionar plantilla</p>
              </span>
            </Button>
            <Button onClick={() => setIsNota(true)} key="nota">
              Cargar nota
            </Button>
          </span>,
        ]}
      />
    </span>
  );
};
