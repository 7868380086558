export const handleFlowVariablesSetVariables = (
  setFlowVariables,
  id,
  variables,
  messageApi
) => {
  let mismoNombre = [];
  setFlowVariables((prevState) => {
    let array = [...prevState];

    array = array.filter((element) => {
      if (element.idNodo !== id) {
        return true;
      } else {
        if (element.kind) {
          return true;
        } else {
          return false;
        }
      }
    });

    variables = variables.map((variable) => {
      return {
        idNodo: id,
        name: variable.name,
        type: variable.type,
        value: variable.value,
      };
    });

    variables.forEach((variable) => {
      let flag = array.some((element) => element.name === variable.name);

      if (flag) {
        messageApi.error(
          `No se pudo guardar la ${variable.name}, intente con otro nombre`
        );
        mismoNombre.push(variable.name);
      }
    });

    if (mismoNombre.length > 0) {
      return array;
    } else {
      array = [...array, ...variables];
      return array;
    }
  });

  return mismoNombre;
};
