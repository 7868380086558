import { useContext, useMemo, useState } from "react";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { Button, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FiPlus } from "react-icons/fi";
import { columnasEstados } from "./utils/columns";

export const TablaEstados = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { catalogDetail, setCatalogDetail, setConfigDrawerContent } =
    useContext(SettingsContext);
    
  const [busqueda, setBusqueda] = useState("");

  const filteredStatuses = useMemo(() => {
    let array = [];
    if (catalogDetail?.statuses) {
      array = catalogDetail.statuses;
      if (busqueda) {
        array = array.filter((element) =>
          element.name?.toLowerCase()?.includes(busqueda.toLowerCase())
        );
      }
    }

    return array;
  }, [busqueda, catalogDetail]);

  return (
    <>
      <span className="horizontal" style={{ marginBottom: "16px" }}>
        <Input
          type="search"
          placeholder="Buscar estados"
          onChange={(v) => setBusqueda(v.target.value)}
          addonAfter={<SearchOutlined onClick={() => {}} />}
          style={{ maxWidth: "200px", marginLeft: "auto" }}
        />
        <Button
          className="btn-agregar boton-dashed-32"
          onClick={() =>
            setConfigDrawerContent({
              visible: true,
              content: "newStatus",
              item: null,
            })
          }
        >
          <FiPlus />
          <p>Estado</p>
        </Button>
      </span>
      <Table
        columns={columnasEstados}
        dataSource={filteredStatuses}
        rowKey={"_id"}
        size="small"
        pagination={false}
        className="tabla-config"
      />
    </>
  );
};
