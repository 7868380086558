import { useEffect } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { encode } from "js-base64";

export const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  // useEffect(() => {

  // Si location /landing  No forzar esto.
  //   if (isAuthenticated) {
  //     console.log("USER", user);

  //     window.localStorage.setItem("token", encode(JSON.stringify(user)));
  //     //* gestionar router, use contacto para navigate a una ruta privada
  //     navigate("/main");
  //   }
  // }, [isAuthenticated, navigate, user]);

  return (
    <header className="header-wrapper" id="landing-header">
      <img
        alt="logo-brocoly"
        src={`${process.env.PUBLIC_URL}/assets/landing-images/logo-brocoly.png`}
        style={{ height: "2.5rem" }}
      />
      <span className="horizontal gap16" style={{ marginLeft: "auto" }}>
        <button className="landing-boton-generico landing-boton-oscuro">
          Registro
        </button>
        <button
          className="landing-boton-generico"
          onClick={() => {
            loginWithRedirect({ ui_locales: "es" });
          }}
        >
          Ingresar
        </button>
      </span>
    </header>
  );
};
