import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { getAllInstances } from "../../../../../../helpers/fetchData/fetchBrocolyAdmin";
import { AiOutlineApi } from "react-icons/ai";
import { Input, Table } from "antd";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import { workspacesColumns } from "./columns";

const style = { width: "100%", maxWidth: 1200 };

export const Workspaces = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(false);

  const [busqueda, setBusqueda] = useState("");

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getAllInstances(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              res.data.sort((a, b) => b.createdAt - a.createdAt);
              setInstances(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener workspaces");
          }
        })
        .catch((error) => {
          console.log("error", error);
          messageApi.error("Ocurrió un error al obtener workspaces");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  const filteredData = useMemo(() => {
    if (instances?.length < 1) {
      return [];
    }

    let array = [...instances];

    if (busqueda) {
      const search = busqueda.toLowerCase();
      array = array.filter((element) => {
        if (element.orgName?.toLowerCase()?.includes(search)) {
          return true;
        }

        if (element.name?.toLowerCase()?.includes(search)) {
          return true;
        }

        return false;
      });
    }

    return array;
  }, [instances, busqueda]);

  return (
    <div
      className="config-main-wrapper"
      style={{ width: "clamp(500px, 1000px, 100%)" }}
    >
      <div className="test-main-header" style={style}>
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <AiOutlineApi size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Workspaces</span>
            </span>
            <p className="header-subtitle">Espacios de trabajo vigentes</p>
          </span>
          <Input.Search
            style={{ maxWidth: 250 }}
            value={busqueda}
            placeholder="Buscar..."
            onChange={(v) => setBusqueda(v.target.value)}
          />
        </span>
      </div>

      <div className="tabla-config" style={{ maxWidth: 1200 }}>
        <Table
          columns={workspacesColumns()}
          dataSource={filteredData}
          loading={{
            spinning: loading,
            indicator: <LoadingIcon size="large" />,
          }}
          rowKey={"_id"}
          size="small"
          className="tabla-config"
          style={style}
        />
      </div>
    </div>
  );
};
