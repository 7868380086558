import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { RESPONSE_COLORS } from '../utils';

export const CampaignDataDonut = ({ buttons, replies, cantSent }) => {
  const repliesData = buttons.map((b) => ({
    name: b.text,
    value: 0
  }));

  replies.forEach((reply) => {
    const index = repliesData.findIndex((r) => r.name === reply.buttonPayload);

    if (index !== -1) {
      repliesData[index].value = reply.count;
    }
  });

  const totalResponses = replies.reduce((acc, reply) => acc + reply.count, 0);
  const unrespondedCount = cantSent - totalResponses;

  if (unrespondedCount > 0) {
    repliesData.push({
      name: 'No respondidos',
      value: unrespondedCount,
      color: '#bfbfbf'
    });
  }

  return (
    <div className='CampaignDataDonut'>
      <span className='campaign-data-donut-title'>Respuestas</span>

      <div style={{ width: '100%', height: 200 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey='value'
              data={repliesData}
              innerRadius={40}
              outerRadius={70}
              fill='#36cfc9'
            >
              {repliesData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.name === 'No respondidos'
                      ? '#B8BBBE'
                      : RESPONSE_COLORS[index % RESPONSE_COLORS.length]
                  }
                />
              ))}
            </Pie>
            <Tooltip cursor={false} content={<TooltipContent />} />
            <Legend iconSize={10} layout='horizontal' verticalAlign='bottom' />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const TooltipContent = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const pl = payload[0].payload;

    return (
      <div className='campaign-data-time-tooltip'>
        <div className='campaign-data-time-tooltip-content'>
          <div
            className='campaign-data-time-tooltip-color'
            style={{ backgroundColor: pl.fill }}
          />
          <span className='campaign-data-time-tooltip-label'>{pl.name}</span>
          <span className='campaign-data-time-tooltip-value'>{pl.value}</span>
        </div>
      </div>
    );
  }
};
