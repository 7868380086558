import { useContext } from "react";
import { Handle, Position } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { getNodeName } from "../utils/getNodeName";
import { PiTicketLight } from 'react-icons/pi';
import { CgArrowLongRight } from "react-icons/cg"; // alternativa de flecha
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { SiOpenai } from "react-icons/si";
import { Tag } from "../../../tag/Tag";



export const CreateTicketNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className='borrar-nodo' onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}

      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
      />

      <div className='node-header'>
        <PiTicketLight style={{ color: 'var(--dark-color)' }} size={32} />
        <div className='node-description'>
          <span className='node-type'>{getNodeName(node)}</span>
          <span className='mensaje-nodo'>{node.data?.label}</span>
        </div>
      </div>
 
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '4px' }}>
        <span>
          {
            node.data?.ticketType === 'autoAI' ? <span style={{ display: "flex", alignItems: "center" }}><SiOpenai style={{ color: 'var(--dark-color)' }} size={24} /></span>
              :
              <Tag nombre={node.data?.nodeViewData?.ticketTypeLabel || 'Ticket'} hex={node.data?.nodeViewData?.ticketTypeColor || '#4A4A4A'} />
          }
        </span>
        <span style={{ display: "flex", alignItems: "center" }}><MdKeyboardDoubleArrowRight /></span>
        <span>
          <Tag nombre={node.data?.nodeViewData?.asignadoLabel || 'Usuario'} hex={'#4A4A4A'} />
        </span>
      </div>

      <span className='contenedor-handles'>
        <span className='linea-handle'>
          <p>Next step</p>
          <Handle
            type='source'
            className='custom-handle next-step'
            position={Position.Right}
            id='nextstep'
            isConnectable={checkValidConnection(edges, node.id, 'nextstep')}
          />
        </span>
      </span>

      <div className='path-id'>{node?.id}</div>
    </div>
  );
};
