import { Button, Popover, Tooltip } from "antd";
import { TbTrash } from "react-icons/tb";
import { deleteFlow } from "../../../../../helpers/fetchData/fetchFlows";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";

export const CardBotEliminar = ({ flow, setFlows, setLoading }) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Popover
      trigger="click"
      open={showPopover}
      content={
        <ContentPopoverEliminar
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
          setShowPopover={setShowPopover}
        />
      }
    >
      <span>
        <Tooltip title="Eliminar">
          <span style={{ cursor: "pointer" }}>
            <TbTrash size={20} onClick={() => setShowPopover(true)} />
          </span>
        </Tooltip>
      </span>
    </Popover>
  );
};

const ContentPopoverEliminar = ({
  flow,
  setFlows,
  setLoading,
  setShowPopover,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loadingBorrar, setLoadingBorrar] = useState(false);

  function handleDelete(flowId) {
    let data = JSON.stringify({
      flowId: flowId,
    });

    deleteFlow(
      data,
      messageApi,
      setFlows,
      setLoading,
      instance,
      setLoadingBorrar,
      setShowPopover
    );
  }

  return (
    <div className="columna-simple">
      <span
        style={{ marginBottom: "8px" }}
      >{`¿Está seguro de borrar ${flow?.name}?`}</span>
      <div className="botones-wrapper-content" style={{ width: "100%" }}>
        <Button
          type="primary"
          className="btn-borrar"
          onClick={() => handleDelete(flow._id)}
          loading={loadingBorrar}
        >
          Confirmar
        </Button>
        <Button
          className="btn-cancelar"
          type="secondary"
          onClick={() => setShowPopover(false)}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
