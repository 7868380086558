import { FaWhatsapp } from 'react-icons/fa';
import { AppInfoActions } from './AppInfoActions';
import LoadingIcon from '../../../../../../Loader/LoadingIcon';
import { SlScreenSmartphone } from 'react-icons/sl';
import { formatPhoneNumber } from '../../../../../rightDrawer/utils/formatPhoneNumber';
import { Tag, Tooltip } from 'antd';
import { GlobalContext } from '../../../../../../../context/GlobalContext';
import { useContext } from 'react';
import { getColorStatus, getTitleStatus } from '../../utils/getStatus';
import { FiCopy } from 'react-icons/fi';

export const AppInfo = ({ businessCompliance, loading }) => {
  const { application, messageApi } = useContext(GlobalContext);

  const titleStatus = getTitleStatus(businessCompliance);
  const colorStatus = getColorStatus(businessCompliance);

  const handlePhoneNumberCopy = () => {
    if (application?.[0]?.lines[0]?.phoneNumber) {
      navigator.clipboard.writeText(application?.[0]?.lines[0]?.phoneNumber);
      messageApi.info(
        `Número de teléfono copiado: ${application?.[0]?.lines[0]?.phoneNumber}`
      );
    }
  };

  const handleBusinessIdCopu = () => {
    if (application?.[0]?.businessId) {
      navigator.clipboard.writeText(application?.[0]?.businessId);
      messageApi.info(`Business ID copiado: ${application?.[0]?.businessId}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 200
      }}
    >
      <span
        className='horizontal'
        style={{
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            fontSize: 13
          }}
        >
          <FaWhatsapp fill='#25D366' size={24} />
        </div>

        <span className='horizontal'>{<AppInfoActions />}</span>
      </span>

      {loading ? (
        <span
          style={{
            height: '100%',
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginTop: 8
          }}
        >
          <div
            style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}
          >
            {application?.[0]?.lines && (
              <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <SlScreenSmartphone />
                <p
                  style={{
                    fontWeight: '500',
                    color: '#252525',
                    display: 'inline'
                  }}
                >
                  {formatPhoneNumber(application?.[0]?.lines[0]?.phoneNumber)}
                </p>
              </span>
            )}

            <Tooltip title='Copiar número de teléfono'>
              <span style={{ color: '#000', cursor: 'pointer' }}>
                <FiCopy onClick={handlePhoneNumberCopy} />
              </span>
            </Tooltip>
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span>ID</span>
              <p
                style={{
                  fontWeight: '500',
                  color: '#252525',
                  display: 'inline'
                }}
              >
                {application?.[0]?.businessId}
              </p>
            </span>

            <Tooltip title='Copiar Business ID'>
              <span style={{ color: '#000', cursor: 'pointer' }}>
                <FiCopy onClick={handleBusinessIdCopu} />
              </span>
            </Tooltip>
          </div>

          <Tag style={{ width: 'fit-content' }} color={colorStatus}>
            {titleStatus}
          </Tag>
        </div>
      )}
    </div>
  );
};
