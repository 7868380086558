import { Tag } from "antd";
import dayjs from "dayjs";

export const renderConvoDate = (timestamp, type) => {
  if (timestamp) {
    timestamp = timestamp.toString();
    let date = dayjs(timestamp, "x")?.format("DD/MM/YYYY HH:mm");

    return (
      <span
        className="horizontal"
        style={{ justifyContent: "center", position: "relative" }}
        key={timestamp}
      >
        <span className="linea-puntos"></span>
        <Tag
          color={type === "Iniciada" ? "green" : "volcano"}
          className="fade-in"
        >{`${type} ${date}`}</Tag>
      </span>
    );
  } else {
    return null;
  }
};
