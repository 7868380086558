import { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { getAllClients } from '../../../../helpers/fetchData/fetchClients';
import { GlobalContext } from '../../../context/GlobalContext';
import LoadingIcon from '../../Loader/LoadingIcon';
import { filterLabelOptions } from '../../../../utils/filterLabelOptions';

const MAX_TAG_COUNT = 5;

export const SelectClients = ({ editMode, form, drawerConfig }) => {
  const { instance, messageApi, clientes, setClientes } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [filteredClientes, setFilteredClientes] = useState([]);

  const { item } = drawerConfig;

  // Obtener los clientes activos:
  useEffect(() => {
    const fetchClients = async () => {
      if (!instance) {
        return;
      }

      setLoading(true);
      try {
        const res = await getAllClients(instance.userData.apiKey);

        if (res?.status === 200 && Array.isArray(res.data)) {
          const activeClients = res.data.filter((client) => client.state === 1);
          setClientes(res.data);
          setFilteredClientes(activeClients);
        } else {
          messageApi.error('Ocurrió un error al obtener los clientes');
        }
      } catch (err) {
        console.error(err);
        messageApi.error('Ocurrió un error al obtener los clientes');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [instance]);

  // Setear clientes asociados actuales al editar un contacto:
  useEffect(() => {
    const contactClients = filteredClientes
      .filter((cliente) => cliente.contacts?.includes(item?._id))
      .map((cliente) => cliente._id);

    form.setFieldsValue({ clients: contactClients });
  }, [filteredClientes, item, form]);

  // Clientes a mostrar en el selector:
  const options = filteredClientes.map((cliente) => ({
    label: cliente.name,
    value: cliente._id
  }));

  return (
    <Form
      className='form-nodos'
      form={form}
      name='selectClients'
      layout='vertical'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Clientes</p>

      {loading ? (
        <span
          style={{
            height: 300,
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <Form.Item name='clients'>
          <Select
            mode='multiple'
            allowClear
            maxTagCount={MAX_TAG_COUNT}
            placeholder='Seleccione clientes'
            options={options}
            filterOption={filterLabelOptions}
            showSearch
          />
        </Form.Item>
      )}
    </Form>
  );
};
