import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Popover } from 'antd';
import { AiFillThunderbolt } from 'react-icons/ai';
import { IoMegaphoneOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export const SelectTriggerModal = ({ open, setOpen }) => {
  function handleCancel() {
    setOpen(false);
  }

  return (
    <Modal
      title={<SelectTriggerTitle />}
      open={open}
      width={400}
      footer={null}
      destroyOnClose
      onCancel={handleCancel}
    >
      <div className='select-trigger-card-container'>
        <SelectTriggerCard
          title='Palabra clave'
          icon={<AiFillThunderbolt fill='rgb(244, 184, 0)' size={42} />}
        />
        <SelectTriggerCard
          title='Campaña'
          icon={<IoMegaphoneOutline size={42} />}
        />
      </div>
    </Modal>
  );
};

const SelectTriggerCard = ({ title, icon }) => {
  const navigate = useNavigate();

  function handleClick() {
    switch (title) {
      case 'Palabra clave':
        navigate('/flows/new/trigger');
        break;
      case 'Campaña':
        navigate('/flows/new/broadcast');
        break;
      default:
        break;
    }
  }

  return (
    <div className='SelectTriggerCard' onClick={handleClick}>
      <span className='horizontal'>{icon}</span>
      <span>{title}</span>
    </div>
  );
};

const SelectTriggerTitle = () => {
  return (
    <div className='select-trigger-modal-title'>
      <span>Seleccionar el disparador</span>
      <Popover placement='right' content={<SelectTriggerHelp />}>
        <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
      </Popover>
    </div>
  );
};

const SelectTriggerHelp = () => {
  return (
    <div className='SelectTriggerHelp'>
      <p>
        El disparador puede ser una <b>Palabra clave</b> ingresada por el
        usuario.
      </p>
      <p>
        O bien puede ser una <b>Campaña</b>, previamente creada en Mensajes.
      </p>
    </div>
  );
};
