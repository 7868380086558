import { BsGoogle } from "react-icons/bs";
import { SiOpenai } from "react-icons/si";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { Handle, Position } from "reactflow";
import { Tag } from "antd";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { manageWorkingNode } from "../utils/manageWorkingNode";
import { getNodeName } from "../utils/getNodeName";

export const CallFunctionNode = (node) => {
  const {
    setMostrarDrawer,
    setNodoSeleccionado,
    edges,
    nodes,
    setNodes,
    setEdges,
  } = useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
    manageWorkingNode(nodes, setNodes, edges, setEdges, node, true);
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <SiOpenai size={32} />
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data?.label}</span>
        </div>
      </div>
      {node.data && (
        <div className="transcribe-audio-column">
          {node.data.variableAudio && (
            <Tag color="green">{node.data.variableAudio}</Tag>
          )}
          {node.data.variableText && (
            <Tag color="green">{node.data.variableText}</Tag>
          )}
          <span className="linea-transcribe-audio"></span>
        </div>
      )}
      <div className="path-id">{node?.id}</div>
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
    </div>
  );
};
