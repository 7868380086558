import { Form } from "antd";
import { BoxInfo } from "../box-info/BoxInfo";
import { VariableInput } from "../variableInput/VariableInput";


export const TemplateFooter = ({templateVariables, setTemplateVariables, varOptions}) => {
  return (
    <>
       {templateVariables?.footer?.value &&
          templateVariables.footer?.type?.toUpperCase() === "TEXT" && (
            <>
              <Form.Item label={<b style={{ fontSize: 15 }}>Pie</b>}>
                <pre
                  className="template-section-value"
                  id="template-footer"
                ></pre>
              </Form.Item>
              {templateVariables.footer.placeholder?.length > 0 && (
                <>
                  <BoxInfo
                    message="Puede cambiar el modo de ingreso haciendo click en el
                        indicador de variable."
                  />
                  <Form.Item>
                    {templateVariables.footer.placeholder.map((item, index) => {
                      return (
                        <VariableInput
                          item={item}
                          index={index}
                          section="footer"
                          templateVariables={templateVariables}
                          setTemplateVariables={setTemplateVariables}
                          key={`footerVar-${index}`}
                          varOptions={varOptions}
                        />
                      );
                    })}
                  </Form.Item>
                </>
              )}
            </>
          )}
    </>
  )
}
