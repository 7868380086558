import { Button, Input, Radio, Select } from "antd";
import { useContext, useState } from "react";
import { BsMegaphoneFill, BsBriefcaseFill } from "react-icons/bs";
import { FaKey } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { TemplateContext } from "../../../../../context/TemplateContext";

export const OpcionesTemplate = () => {
  const { setTemplateStage } = useContext(GlobalContext);
  const { setTemplateSelection } = useContext(TemplateContext);

  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateLanguage, setTemplateLanguage] = useState("");

  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    const charRegex = new RegExp("[a-z0-9_]");
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!charRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <div className="main-wrapper">
      <div className="main-header" style={{width: "100%"}}>
        <span style={{ fontSize: "24px", fontWeight: "bold" }}>
          Nueva plantilla de mensaje
        </span>
        <span className="contenedor-botones">
          <Button
            type="secundary"
            className="btn-secundario"
            onClick={() => navigate("/settings/templates")}
            tabIndex={6}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            className="btn-agregar"
            onClick={() => {
              setTemplateSelection({
                templateType: templateType,
                templateName: templateName,
                templateLanguage: templateLanguage,
              });
              setTemplateStage(1);
            }}
            disabled={!templateType || !templateLanguage || !templateName}
            autoFocus={!templateType || !templateLanguage || !templateName}
            tabIndex={5}
          >
            Continuar
          </Button>
        </span>
      </div>
      <div className="create-template-content">
        <div className="template-options-wrapper">
          <b style={{ fontSize: "16px" }}>Categoría</b>
          <span style={{ fontSize: "14px", color: "rgba(28,43,51,.65)" }}>
            Elige una plantilla de mensaje.
          </span>
          <Radio.Group
            onChange={(e) => {
              setTemplateType(e.target.value);
            }}
            size="large"
          >
            <span style={{ display: "flex", flexDirection: "column" }}>
              <Radio
                value={"MARKETING/CUSTOM"}
                style={{ lineHeight: "1", padding: "8px 0px" }}
                tabIndex={0}
              >
                <div className="radio-option-wrapper">
                  <BsMegaphoneFill size={20} />
                  <div className="radio-option-description">
                    <span className="radio-option-title">
                      Marketing / Personalizado
                    </span>
                    <span className="radio-option-caption">
                      Envía ofertas promocionales, anuncios y más para aumentar
                      el reconocimiento y la interacción.
                    </span>
                  </div>
                </div>
              </Radio>
              <Radio
                value={"MARKETING/CATALOG"}
                tabIndex={1}
                style={{ lineHeight: "1", padding: "8px 0px" }}
              >
                <div className="radio-option-wrapper">
                  <BsMegaphoneFill size={20} />
                  <div className="radio-option-description">
                    <span className="radio-option-title">
                      Marketing / Mensaje multiproducto
                    </span>
                    <span className="radio-option-caption">
                      Una lista de productos de tu catálogo.
                    </span>
                  </div>
                </div>
              </Radio>
            </span>
            <Radio
              value={"TRANSACTIONAL"}
              style={{ lineHeight: "1", padding: "8px 0px" }}
              tabIndex={2}
            >
              <div className="radio-option-wrapper">
                <BsBriefcaseFill size={20} />
                <div className="radio-option-description">
                  <span className="radio-option-title">Servicios</span>
                  <span className="radio-option-caption">
                    Envía mensajes sobre un pedido o una cuenta existentes.
                  </span>
                </div>
              </div>
            </Radio>
            <Radio
              value={"OTP"}
              style={{ lineHeight: "1", padding: "8px 0px" }}
              tabIndex={3}
            >
              <div className="radio-option-wrapper">
                <FaKey size={20} />
                <div className="radio-option-description">
                  <span className="radio-option-title">Autenticación</span>
                  <span className="radio-option-caption">
                    Envía códigos para verificar una transaccion o un inicio de
                    sesión.
                  </span>
                </div>
              </div>
            </Radio>
          </Radio.Group>
        </div>
        <div className="template-options-wrapper">
          <b style={{ fontSize: "16px" }}>Nombre</b>
          <span style={{ fontSize: "14px", color: "rgba(28,43,51,.65)" }}>
            Asigna un nombre a la plantilla de mensaje.
          </span>
          <Input
            placeholder="Escribe el nombre de la plantilla de mensaje.."
            onChange={(e) => {
              setTemplateName(e.target.value.toLowerCase().replace(/ /g, "_"));
            }}
            style={{ width: "100%" }}
            showCount
            maxLength={512}
            onKeyPress={(e) => handleKeyPress(e)}
            tabIndex={3}
          />
        </div>
        <div className="template-options-wrapper">
          <b style={{ fontSize: "16px" }}>Idiomas</b>
          <span style={{ fontSize: "14px", color: "rgba(28,43,51,.65)" }}>
            Elige idiomas para la plantilla de mensaje. Puedes eliminar o
            agregar idiomas más adelante.
          </span>
          <Select
            style={{ width: "100%" }}
            onChange={(e) => setTemplateLanguage(e)}
            showAction="focus"
            tabIndex={4}
          >
            <Select.Option id="es_AR" value="es_AR">
              Español
            </Select.Option>
          </Select>
        </div>
      </div>
    </div>
  );
};
