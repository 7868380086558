import { Button, Form, Input, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { HiVariable } from 'react-icons/hi';
import { FiX } from 'react-icons/fi';
import {
  handleVariablesCount,
  handleVariablesValue
} from '../utils/utilidadesFlow';
import { handleFlowVariablesSetVariables } from '../utils/handleFlowVariablesSetVariables';
import { updateNode } from '../utils/updateNode';
import { HeaderForm } from './componentsForm/HeaderForm';

export const SetVariablesForm = () => {
  const {
    setMostrarDrawer,
    nodoSeleccionado,
    setNodoSeleccionado,
    setFlowVariables,
    setNodes
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [nombreNodo, setNombreNodo] = useState('');
  const [variables, setVariables] = useState([
    { name: '', value: '', type: 'string' }
  ]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (variables.length < 1) {
      return messageApi.info('Ingrese al menos una variable antes de guardar');
    }

    let flag = false;

    variables.forEach((variable) => {
      if (!variable.name || !variable.value || !variable.type) {
        flag = true;
      }
    });

    if (flag) {
      return messageApi.info(
        'Todas las variables deben tener nombre, valor y tipo definido'
      );
    }

    values.variables = variables;
    values.label = nombreNodo;

    let mismoNombre = handleFlowVariablesSetVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      variables,
      messageApi
    );

    if (mismoNombre.length > 0) {
      return messageApi.info(
        `Los siguientes nombres ya se encontraban: ${mismoNombre}. Intente con otros.`
      );
    } else {
      setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
      setMostrarDrawer(false);
    }
  };

  useEffect(() => {
    if (nodoSeleccionado.data) {
      let { data } = nodoSeleccionado;
      if (data.variables) {
        setVariables(data.variables);
      }
      setNombreNodo(data.label);
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formMedia'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<HiVariable style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Form.Item label='Variables'>
        <div className='columna'>
          <span className='horizontal-grid-3' style={{ marginRight: '20px' }}>
            <b>Nombre</b>
            <b>Valor</b>
            <b>Tipo</b>
          </span>
          {variables?.map((element, index) => {
            return (
              <div className='horizontal' key={index}>
                <span
                  className='horizontal-grid-3'
                  style={{ marginBottom: '4px' }}
                >
                  <Input
                    value={element.name}
                    onChange={(v) =>
                      setVariables((prevState) =>
                        handleVariablesValue(
                          'name',
                          v.target.value,
                          index,
                          prevState
                        )
                      )
                    }
                  />
                  <Input
                    value={element.value}
                    onChange={(v) =>
                      setVariables((prevState) =>
                        handleVariablesValue(
                          'value',
                          v.target.value,
                          index,
                          prevState
                        )
                      )
                    }
                  />
                  <Select
                    options={[
                      { value: 'string', label: 'String' },
                      { value: 'Number', label: 'Number' }
                    ]}
                    value={element.type}
                    onChange={(v) =>
                      setVariables((prevState) =>
                        handleVariablesValue('type', v, index, prevState)
                      )
                    }
                  />
                </span>
                <span>
                  <FiX
                    onClick={() =>
                      setVariables((prevState) =>
                        handleVariablesCount('resta', prevState, index)
                      )
                    }
                  />
                </span>
              </div>
            );
          })}
          <span>
            <Button
              className='btn-agregar btn-oscuro'
              type='primary'
              style={{ marginLeft: 'auto' }}
              onClick={() =>
                setVariables((prevState) =>
                  handleVariablesCount('suma', prevState)
                )
              }
            >
              Agregar variable
            </Button>
          </span>
        </div>
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
