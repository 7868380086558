import axios from "axios";

export const executeExternalClientsLoad = async (key) => {
  try {
    if (key) {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_API_PRODUCCION}/clients/executeExternalClientsLoad`,
        headers: {
          Authorization: key,
        },
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const executeExternalClientsLoadExcel = async (key, data) => {
  try {
    if (key) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/clients/executeExternalClientsLoadExcel`,
        headers: {
          Authorization: key,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllClients = async (key) => {
  let config = {
    method: "get",
    url: `${process.env.REACT_APP_API_PRODUCCION}/clients/getAllClients`,
    headers: {
      Authorization: key,
      "Content-Type": "application/json",
    },
  };

  const res = axios(config);

  return await res;
};
