import { useNavigate } from 'react-router-dom';
import { ImWhatsapp } from 'react-icons/im';
import { Tag } from 'antd';
import { CardBotActions } from './CardBotActions';
import { getCompletedPercentage } from '../utils/getCompletedPercentage';
import { CardBotQRPreview } from './CardBotQRPreview';
import LoadingIcon from '../../../Loader/LoadingIcon';

export const CardBot = ({
  flow,
  setFlows,
  setLoading,
  qrCodes,
  setQrCodes,
  flowLogs,
  loadingLogs
}) => {
  const { name, description, status, trigger, type } = flow;

  const navigate = useNavigate();

  function statusColor(status) {
    if (status) {
      switch (status) {
        case 'draft':
          return '';
        case 'publish':
          return 'green';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  const qrCode = qrCodes?.find((qr) => qr.flowId === flow._id);
  const flowMetrics = flowLogs?.find((metric) => metric.flowId === flow._id);

  return (
    <div className='CardBot' key={flow?._id}>
      <div className='card-bot-first-container'>
        <div className='card-bot-first-row'>
          <div
            className='horizontal gap8'
            onClick={() => navigate(`/flows/edit?id=${flow?._id}`)}
            style={{ cursor: 'pointer' }}
          >
            <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={20} />
            <span className='card-bot-name'>{name}</span>
          </div>

          <span className='fila-triggers' style={{ flexWrap: 'nowrap' }}>
            <Tag color={statusColor(status)}>
              {status === 'publish' ? 'ACTIVO' : 'BORRADOR'}
            </Tag>

            {qrCode && (
              <CardBotQRPreview
                flow={flow}
                qrCode={qrCode}
                setQrCodes={setQrCodes}
              />
            )}
          </span>
        </div>

        <p className='card-bot-description'>{description}</p>
        <div className='card-bot-row' style={{ alignItems: 'flex-start' }}>
          <span className='card-bot-label'>Disparador:</span>
          {flow?.type === 'broadcast' ? (
            <Tag color='blue'>Campaña</Tag>
          ) : (
            <>
              <span className='fila-triggers'>
                {trigger.map((text, index) => (
                  <Tag color='gold' key={index}>
                    {text}
                  </Tag>
                ))}
              </span>
            </>
          )}
        </div>

        {type !== 'broadcast' && (
          <>
            {loadingLogs ? (
              <span
                style={{
                  height: 40,
                  width: '100%',
                  display: 'grid',
                  placeItems: 'center'
                }}
              >
                <LoadingIcon size='large' />
              </span>
            ) : (
              <>
                <div className='card-bot-row'>
                  <span className='card-bot-label'>Ejecuciones:</span>
                  <span>{flowMetrics?.executionCount || '-'}</span>
                </div>

                <div className='card-bot-row'>
                  <span className='card-bot-label'>% de éxito:</span>
                  <span>
                    {flowMetrics?.completedCount
                      ? `${getCompletedPercentage(flowMetrics)}% (${
                          flowMetrics?.completedCount
                        })`
                      : '-'}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className='card-bot-data-container'>
        <CardBotActions
          flow={flow}
          setFlows={setFlows}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};
