import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FiSliders } from "react-icons/fi";
import { Button, Modal, Spin, Tooltip } from "antd";
import { getBudgetConfig } from "../../../../../../helpers/fetchData/fetchBudgetConfig";
import { BudgetConfig } from "./BudgetConfig";
import { LoadingOutlined } from "@ant-design/icons";
import { executeExternalProductsLoad } from "../../../../../../helpers/fetchData/fetchProducts";
import { LoadNotification } from "./budgetConfigSteps/LoadNotification";
import LoadingIcon from '../../../../Loader/LoadingIcon';

const SIZE = 16;

const SETTED = "Configurado";
const NOT_SETTED = "No ha sido configurado aún";

export const ProductsConfig = ({ fetchCatalogDetail }) => {
  const { instance, messageApi, notificationApi } = useContext(GlobalContext);

  const [budgetConfig, setBudgetConfig] = useState(null);
  const [showBudgetConfig, setShowBudgetConfig] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEjecutar, setLoadingEjecutar] = useState(false);

  const closeModal = () => {
    setShowBudgetConfig(null);
  };

  const handleProductsLoad = () => {
    setLoadingEjecutar(true);
    executeExternalProductsLoad(instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          notificationApi.success({
            message: "Carga de productos completada correctamente.",
            description: <LoadNotification data={res.data} />,
            placement: "bottomRight",
            duration: 10,
          });
          fetchCatalogDetail();
        } else {
          messageApi.error(
            "Ocurrió un error al ejecutar la carga de productos"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al ejecutar la carga de productos");
      })
      .finally(() => setLoadingEjecutar(false));
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getBudgetConfig(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data) {
              setBudgetConfig(res.data);
            }
          } else {
            messageApi.error(
              "Ocurrió un error al obtener configuracion de productos"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error(
            "Ocurrió un error al obtener configuracion de productos"
          );
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  if (loading) {
    return (
      <div className="products-config">
        <Spin
          style={{
            margin: 50
          }}
          indicator={
            <LoadingIcon size="large" />
          }
        />

      </div>
    );
  }

  return (
    <div className="products-config">
      <div className="products-config-section">
        <span className="horizontal-between">
          <p className="products-config-section-title">Carga de productos</p>
          <Tooltip title="Configurar">
            <span
              onClick={() => setShowBudgetConfig("productsLoad")}
              style={{ height: SIZE }}
            >
              <FiSliders size={SIZE} className="pointer" />
            </span>
          </Tooltip>
        </span>
        <p className="products-config-section-subtitle">
          {budgetConfig?.productsLoad ? SETTED : NOT_SETTED}
        </p>
        {budgetConfig?.productsLoad && (
          <Button
            className="btn-oscuro"
            type="primary"
            style={{ marginLeft: "auto", width: "fit-content", opacity: loadingEjecutar ? 0.65 : 1 }}
            onClick={handleProductsLoad}
            //loading={loadingEjecutar}
            icon={loadingEjecutar ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          >
            Ejecutar
          </Button>
        )}
      </div>
      <div className="products-config-section">
        <span className="horizontal-between">
          <p className="products-config-section-title">Gestión de stocks</p>
          <Tooltip title="Configurar">
            <span
              onClick={() => setShowBudgetConfig("stockManagement")}
              style={{ height: SIZE }}
            >
              <FiSliders size={SIZE} className="pointer" />
            </span>
          </Tooltip>
        </span>
        <p className="products-config-section-subtitle">
          {budgetConfig?.stockManagment ? SETTED : NOT_SETTED}
        </p>
      </div>
      <div className="products-config-section">
        <span className="horizontal-between">
          <p className="products-config-section-title">
            Integración con terceros
          </p>
          <Tooltip title="Configurar">
            <span
              onClick={() => setShowBudgetConfig("output")}
              style={{ height: SIZE }}
            >
              <FiSliders size={SIZE} className="pointer" />
            </span>
          </Tooltip>
        </span>
        <p className="products-config-section-subtitle">
          {budgetConfig?.output ? SETTED : NOT_SETTED}
        </p>
      </div>
      <Modal
        open={showBudgetConfig}
        onCancel={closeModal}
        footer={null}
        destroyOnClose
      >
        {showBudgetConfig === "productsLoad" && (
          <BudgetConfig
            budgetConfig={budgetConfig}
            setBudgetConfig={setBudgetConfig}
            closeModal={closeModal}
          />
        )}
        {showBudgetConfig === "stockManagement" && <p>Gestión de stocks</p>}
        {showBudgetConfig === "output" && <p>Integración con terceros</p>}
      </Modal>
    </div>
  );
};
