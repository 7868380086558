
export const checkIsArray = (response) => {
  let flag = false;
  if (response) {
    if (Array.isArray(JSON.parse(response))) {
      flag = true;
    }
  }

  return flag;
};

export const getVariableName = (url) => {
  if (url) {
    let regex = /\${(.*?)}/g;
    var match = regex.exec(url);
    var variableName = match ? match[1] : "";
    return variableName;
  }
};

export const checkResponse = (response) => {
  let flag = false;
  if (response) {
    if (response !== "Error al probar petición") {
      flag = true;
    }
  }

  return flag;
};

export const isSaved = (
  variable,
  base,
  nodoSeleccionado,
  flowVariables,
  nodeId
) => {
  if (base.includes(variable)) {
    return {
      name: variable,
      type: getType("http", variable, nodoSeleccionado, flowVariables, nodeId),
      checked: true,
    };
  } else {
    return {
      name: variable,
      type: "string",
      checked: false,
    };
  }
};

export const isSavedIndexed = (
  variable,
  base,
  nodoSeleccionado,
  flowVariables,
  nodeId
) => {
  if (base.includes(variable.name)) {
    return {
      name: variable.name,
      display: variable.display,
      type: "string",
      checked: true,
    };
  } else {
    return {
      name: variable.name,
      display: variable.display,
      type: "string",
      checked: false,
    };
  }
};

const getType = (
  prefijo,
  variable,
  nodoSeleccionado,
  flowVariables,
  nodeId
) => {
  let variableName = nodoSeleccionado.data.variable.name.substring(
    nodoSeleccionado.data.variable.name.indexOf("_") + 1
  );
  variableName = `${prefijo}_${variableName}_${variable}`;

  let filtro = flowVariables
    .filter((item) => item.idNodo === nodeId)
    .filter((item) => item.name === variableName);

  if (filtro.length > 0) {
    return filtro[0].type;
  } else {
    return "string";
  }
};