import { useContext, useState } from "react";
import { Button, Form, Input, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { FILE_TYPES } from "../../../../../../helpers/fetchData/fetchMedia";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { normFile } from "../../../flows/utils/cargaMedia";

export const NewImagen = ({ modal = false, setNuevaImagen }) => {
  const { messageApi, instance } =
    useContext(GlobalContext);

  const { recursos, setRecursos, setConfigDrawerContent } = useContext(SettingsContext);

  const [form] = Form.useForm();
  const [mediaType, setMediaType] = useState("image");

  const [loading, setLoading] = useState(false);

  const { Dragger } = Upload;

  const customRequest = (v) => {
    if (FILE_TYPES[mediaType].accept.includes(v.file.type)) {
      if (v.file.size < 1048576 * FILE_TYPES[mediaType].maxSize) {
        v.onSuccess();
      } else {
        messageApi.error(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
        v.onError(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
      }
    } else {
      messageApi.error("El formato no es válido");
      v.onError("El formato no es válido");
    }
  };

  const handleCancelar = () => {
    if (!modal) {
      setConfigDrawerContent({
        visible: false,
        content: "",
        item: null,
      });
    } else {
      setNuevaImagen({ visible: false, data: null, addedNew: false });
    }
  };

  const onFinish = async (values) => {
    if (!values.name) {
      return messageApi.error(
        "Es necesario que ingrese un nombre para el archivo"
      );
    }

    if (!values.file) {
      return messageApi.error("Es necesario que seleccione un archivo");
    }

    let myHeaders = new Headers();

    let token = instance?.userData?.apiKey;

    myHeaders.append("Authorization", token);

    let blob = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () =>
        resolve(new Blob([reader.result], { type: values.file[0]?.type }));
      reader.readAsArrayBuffer(values.file[0]?.originFileObj);
    });

    let formData = new FormData();
    formData.append("messaging_product", "whatsapp");
    formData.append("file", blob, values.file[0]?.name);
    formData.append(
      "data",
      JSON.stringify({
        name: values.name,
        description: values.description,
      })
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_PRODUCCION}/upload`, requestOptions)
      .then((res) => res.text())
      .then((result) => {
        let object = JSON.parse(result);

        if (object.image?._id) {
          messageApi.success("Imagen subida correctamente");

          setRecursos((prevState) => {
            let array = [...prevState, object.image];

            return array;
          });
        }

        if (modal) {
          setNuevaImagen({
            visible: false,
            addedNew: true,
            data: object.image,
          });
        } else {
          setConfigDrawerContent({
            visible: false,
            content: "",
            item: null,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        messageApi.error("No se pudo subir el archivo a Meta");
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="newImagen"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Nuevo recurso</p>
      <Form.Item
        name="file"
        rules={[{ required: true, message: "Seleccione un archivo" }]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Dragger
          name="file"
          customRequest={customRequest}
          maxCount={1}
          accept={FILE_TYPES[mediaType].accept}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre un archivo aquí
          </p>
          <p className="ant-upload-hint">{`Soporta archivos en formato ${FILE_TYPES[mediaType].formatos}.`}</p>
          <p className="ant-upload-hint">{`Con un máximo de ${FILE_TYPES[mediaType].maxSize}MB.`}</p>
        </Dragger>
      </Form.Item>
      <Form.Item
        name="name"
        label="Nombre del archivo"
        rules={[{ required: true, message: "Ingrese un nombre" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Descripción"
        style={modal ? { marginBottom: 80 } : { marginBottom: 0 }}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              handleCancelar();
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
