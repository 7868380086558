import axios from "axios";

export const putFiltro = async (data, instance) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/filters/editFilter`,
      data: data,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};