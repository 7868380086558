import { useContext, useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { BiGitBranch } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";
import { useBorrarNode } from "../utils/useBorrarNode";
import { Tag } from "antd";
import { checkValidConnection, handleOperator } from "../utils/utilidadesFlow";
import { getNodeName } from "../utils/getNodeName";

export const BranchNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const updateNodeInternals = useUpdateNodeInternals();

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <BiGitBranch size={32} />
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data?.label}</span>
        </div>
      </div>
      <Tag color="green" style={{ alignSelf: "start" }}>
        {node.data?.variable?.name}
      </Tag>
      <div className="branch-conditions-wrapper">
        {node?.data?.cases?.map((caso, index) => {
          if (caso.value || caso.value === 0) {
            return (
              <span className="case-display" key={index}>
                <span className="horizontal" style={{ gap: "4px" }}>
                  <p>{handleOperator(caso.operator?.[0])}</p>
                  <b>{caso.value}</b>
                </span>
                <span className="linea-cases"></span>
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`${caso.id}`}
                  isConnectable={checkValidConnection(
                    edges,
                    node.id,
                    `${caso.id}`
                  )}
                />
              </span>
            );
          } else {
            <></>;
          }
        })}
        <span className="case-display case-default">
          <span className="linea-cases"></span>
          <p>Otro...</p>
          <Handle
            className=""
            type="source"
            position={Position.Right}
            id="default"
            isConnectable={checkValidConnection(edges, node.id, "default")}
          />
        </span>
      </div>
      <div className="path-id">{node?.id}</div>
    </div>
  );
};
