import { Empty } from "antd";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { ImWhatsapp } from "react-icons/im";
import { DefaultBotDrawer } from "./DefaultBotDrawer";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  getDefaultFlow,
  getFlows,
} from "../../../../../../helpers/fetchData/fetchFlows";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const DefaultBotCard = ({ showDrawer, setShowDrawer }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [loadingDefault, setLoadingDefault] = useState(true);
  const [defaultFlow, setDefaultFlow] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getFlows(setFlows, messageApi, setLoading, instance);

      setLoadingDefault(true);
      getDefaultFlow(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data?.flowId) {
              setDefaultFlow(res.data.flowId);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener bot por defecto");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener bot por defecto")
        )
        .finally(() => setLoadingDefault(false));
    }
  }, [instance]);

  useEffect(() => {
    if (showDrawer && defaultFlow) {
      setSelectedFlow(defaultFlow);
    }
  }, [showDrawer, defaultFlow]);

  const flowData = useMemo(() => {
    if (!defaultFlow) {
      return null;
    }

    const flow = flows?.find((element) => element._id === defaultFlow);

    return flow || null;
  }, [flows, defaultFlow]);

  if (loadingDefault || loading) {
    return (
      <span
        style={{
          marginTop: 100,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingIcon size="large" />
      </span>
    );
  }

  return (
    <>
      {flowData ? (
        <div className="DefaultBot">
          <div className="CardBot">
            <div className="card-bot-first-container default-bot-first-container">
              <div className="card-bot-first-row default-bot-first-row">
                <div className="horizontal gap8" style={{ cursor: "pointer" }}>
                  <ImWhatsapp
                    style={{ color: "var(--whatsapp-color)" }}
                    size={20}
                  />
                  <span className="card-bot-name default-bot-name">
                    {flowData?.name}
                  </span>
                </div>
              </div>

              <p className="card-bot-description">{flowData?.description}</p>
            </div>
          </div>
          <BoxInfo
            message={
              "Este bot se ejecutará en caso de que se inicie una conversación con su Whatsapp Business sin utilizar alguna de las palabras claves."
            }
          />
        </div>
      ) : (
        <>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <BoxInfo
            message={
              "Configure un bot que se ejecutará en caso de que se inicie una conversación con su Whatsapp Business sin utilizar alguna de las palabras claves."
            }
          />
        </>
      )}

      <DefaultBotDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        flows={flows}
        selectedFlow={selectedFlow}
        setSelectedFlow={setSelectedFlow}
        setDefaultFlow={setDefaultFlow}
      />
    </>
  );
};
