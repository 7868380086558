import { Input, Select } from "antd";
import { useMemo, useState } from "react";
import { getVariableTag } from "../../../utils/getVariableTag";

export const VariableInput = ({
  item,
  index,
  section,
  templateVariables,
  setTemplateVariables,
  varOptions,
}) => {
  const [isSelect, setIsSelect] = useState(
    templateVariables[section]?.vars[index]?.type === "text" ? false : true
  );

  const handleOptionsVars = (value, section, index, isSelect) => {
    let type = isSelect ? "var" : "text";

    setTemplateVariables((prevState) => {
      let secciones = { ...prevState };
      secciones[section].vars[index] = { type: type, value: value };

      return secciones;
    });
  };

  const handleModeType = (e) => {
    // e.preventDefault();

    setIsSelect((prevState) => {
      handleOptionsVars(null, section, index, !prevState);
      return !prevState;
    });
    // setIsSelect(false);
  };

  const formattedVarOptions = useMemo(() => {
    return varOptions?.map((element) => {
      return {
        value: element.value,
        label: (
          <span className="horizontal gap8" style={{ width: "100%" }}>
            {getVariableTag(element.value)}
            <p>{element.label}</p>
          </span>
        ),
        label2: element.label,
      };
    });
  }, [varOptions]);

  return (
    <span className="horizontal" style={{ margin: "8px 0px", gap: "0px" }}>
      <span
        className="prefix-select"
        onClick={(e) => handleModeType(e)}
        style={{ cursor: "pointer" }}
      >
        {item}
      </span>
      {isSelect ? (
        <Select
          options={formattedVarOptions}
          className="options-template-variables"
          onChange={(v) => handleOptionsVars(v, section, index, isSelect)}
          value={templateVariables[section]?.vars[index]?.value}
          showSearch
          filterOption={(input, option) =>
            (option.label2 ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Seleccione una variable"
        />
      ) : (
        <Input
          key={`${section}-${index}-i`}
          value={templateVariables[section]?.vars[index]?.value}
          onChange={(v) =>
            handleOptionsVars(v.target.value, section, index, isSelect)
          }
          placeholder="Ingrese un texto"
          style={{ borderRadius: "0px 6px 6px 0px" }}
        />
      )}
    </span>
  );
};
