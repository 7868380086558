import { Modal } from 'antd';
import { useState } from 'react';
import {
  FaRegFile,
  FaRegFileExcel,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileWord
} from 'react-icons/fa6';

const SIZE = 20;

export const DocumentPreview = ({ src, record }) => {
  const [openModal, setOpenModal] = useState(false);

  const getIcon = () => {
    switch (record?.mimetype) {
      case 'application/pdf':
        return (
          <FaRegFilePdf
            size={SIZE}
            color='darkred'
            className='pointer'
            onClick={() => setOpenModal(true)}
          />
        );
      case 'application/msword':
        return <FaRegFileWord color='blue' size={SIZE} />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FaRegFileWord color='blue' size={SIZE} />;
      case 'application/vnd.ms-excel':
        return <FaRegFileExcel color='green' size={SIZE} />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FaRegFileExcel color='green' size={SIZE} />;
      case 'application/vnd.ms-powerpoint':
        return <FaRegFilePowerpoint color='brown' size={SIZE} />;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <FaRegFilePowerpoint color='brown' size={SIZE} />;
      default:
        return <FaRegFile size={SIZE} />;
    }
  };

  return (
    <>
      {getIcon()}

      <Modal
        className='preview-modal'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <span>
          <iframe
            className='image-preview'
            title='pdf-preview'
            src={src}
            width={1024}
            height={700}
          />
        </span>
      </Modal>
    </>
  );
};
