import { Button, Form, Input } from 'antd';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { useState } from 'react';

export const EditarEmail = ({ dataEmail, setDataEmail, setShowEdit }) => {
  const [formEmail] = Form.useForm();

  const [host, setHost] = useState(dataEmail.host);
  const [port, setPort] = useState(dataEmail.port);
  const [user, setUser] = useState(dataEmail.auth.user);
  const [pass, setPass] = useState(dataEmail.auth.pass);
  const [name, setName] = useState(dataEmail.from.name);
  const [address, setAddress] = useState(dataEmail.from.address);
  const [loading, setLoading] = useState(false);

  function onFinish() {
    /* if (inputValue) {
      const data = { apiKey: inputValue };

      fetchUpsertOpenAiKey(data);
    } else {
      messageApi.error('El campo no puede estar vacío');
    } */
    setShowEdit(false);
  }

  /* async function fetchUpsertOpenAiKey(data) {
    try {
      setLoading(true);
      await upsertOpenAiKey(apiKey, data);
      messageApi.success('API Key cambiada');
      setShowEdit(false);
      setOpenAiKey(inputValue);
    } catch (error) {
      console.error(error);
      messageApi.error('Error al cargar la API Key');
    } finally {
      setLoading(false);
    }
  } */

  return (
    <div style={{ width: 500 }}>
      <Form
        form={formEmail}
        name='formEmail'
        style={{
          margin: '30px 0'
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label='Host'
          rules={[
            {
              required: true,
              message: 'Host requerido'
            }
          ]}
        >
          <Input value={host} onChange={(e) => setHost(e.target.value)} />
        </Form.Item>

        <Form.Item
          label='Puerto'
          rules={[
            {
              required: true,
              message: 'Puerto requerido'
            }
          ]}
        >
          <Input value={port} onChange={(e) => setPort(e.target.value)} />
        </Form.Item>

        <Form.Item
          label='Usuario'
          rules={[
            {
              required: true,
              message: 'Usuario requerido'
            }
          ]}
        >
          <Input value={user} onChange={(e) => setUser(e.target.value)} />
        </Form.Item>

        <Form.Item
          label='Contraseña'
          rules={[
            {
              required: true,
              message: 'Contraseña requerida'
            }
          ]}
        >
          <Input.Password
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label='Nombre'
          rules={[
            {
              required: true,
              message: 'Nombre requerido'
            }
          ]}
        >
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>

        <Form.Item
          label='Dirección de correo'
          rules={[
            {
              required: true,
              message: 'Dirección de correo requerida'
            }
          ]}
        >
          <Input value={address} onChange={(e) => setAddress(e.target.value)} />
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            <Button
              type='primary'
              htmlType='submit'
              icon={
                loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button onClick={() => setShowEdit(false)}>Cancelar</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
