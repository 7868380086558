import axios from 'axios';

export const validateTrigger = (
  inputValue,
  arrayInput,
  arrayTriggers,
  messageApi,
  setTriggers,
  instance,
  setLoading
) => {
  let newArray = [...arrayTriggers];
  let promises = [];

  arrayInput.forEach((trigger) => {
    if (!arrayTriggers.includes(trigger)) {
      setLoading(true);

      const data = JSON.stringify({
        trigger: trigger
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/flows/validateTrigger`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance?.userData?.apiKey
        },
        data: data
      };

      const promise = axios
        .request(config)
        .then((res) => {
          if (res?.status === 200) {
            newArray.push(trigger);
          } else {
            messageApi.error(
              `El disparador ${trigger} no es válido, ya está siendo utilizado en otro bot o es una palabra reservada`
            );
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error(
            `No se pudo validar el disparador ${trigger}. ${error?.response?.data?.message}`
          );
        })
        .finally(() => setLoading(false));

      promises.push(promise);
    } else {
      messageApi.error(`El disparador ${trigger} no es válido, debe ser único`);
    }
  });

  // Espera a que todas las promesas se resuelvan para ejecutar el código dentro:
  Promise.all(promises).then(() => {
    setTriggers(newArray);
  });
};
