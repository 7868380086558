import axios from "axios";

export const getTickets = async (apiKey) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/ticketTypes/getTicketTypes`,
      headers: {
        Authorization: apiKey,
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newTicketType = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/ticketTypes/addTicketType`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editTicketType = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_PRODUCCION}/ticketTypes/editTicketType`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteTicketType = async (apiKey, id) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_PRODUCCION}/ticketTypes/deleteTicketType/${id}`,
      headers: {
        Authorization: apiKey,
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
