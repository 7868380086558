import { Tooltip } from 'antd';
import { FiCloudOff } from 'react-icons/fi';

export const CardBotVolverBorrador = ({ flow, updateStatus }) => {
  return (
    <Tooltip title='Volver a borrador'>
      <span style={{ cursor: 'pointer' }}>
        <FiCloudOff size={20} onClick={() => updateStatus(flow._id, 'draft')} />
      </span>
    </Tooltip>
  );
};
