import { Radio, Spin, DatePicker } from 'antd';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { EstadisticasConversaciones } from './EstadisticasConversaciones';
import { EstadisticasMensajes } from './EstadisticasMensajes';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const Estadisticas = () => {
  const { application, messageApi } = useContext(GlobalContext);

  const currentMonth = dayjs().startOf('month');

  const [selectedOption, setSelectedOption] = useState('conversations'); // Tabs
  const [conversationStats, setConversationStats] = useState({});
  const [messageStats, setMessageStats] = useState({});
  const [loadingEstadisticas, setLoadingEstadisticas] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(currentMonth);

  useEffect(() => {
    fetchData();

    async function fetchData() {
      if (application && application[0]) {
        try {
          const [dataConversaciones, dataMensajes] = await Promise.all([
            getEstadisticas(
              'conversation_analytics',
              datePickerValue.unix(),
              datePickerValue.endOf('month').unix(),
              'DAILY',
              ['CONVERSATION_CATEGORY', 'CONVERSATION_TYPE']
            ),
            getEstadisticas(
              'analytics',
              datePickerValue.unix(),
              datePickerValue.endOf('month').unix(),
              'DAY'
            )
          ]);
          setConversationStats(dataConversaciones);
          setMessageStats(dataMensajes);
        } catch (error) {
          console.error(error);
          messageApi.error('Error al cargar las estadísticas');
        }
      }
    }

    async function getEstadisticas(
      field,
      startDay,
      endDay,
      granularity,
      dimensions
    ) {
      setLoadingEstadisticas(true);

      const dimensionsQuery = dimensions ? `.dimensions(${dimensions})` : '';
      const url = `https://graph.facebook.com/v17.0/${application?.[0]?.lines?.[0]?.wabaId}?fields=${field}.start(${startDay}).end(${endDay}).granularity(${granularity})${dimensionsQuery}`;

      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${application?.[0]?.token}` }
        });
        return response.data;
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEstadisticas(false);
      }
    }
  }, [application, datePickerValue]);

  return (
    <div className='Estadisticas'>
      <div className='Estadisticas-row'>
        <Radio.Group
          optionType='button'
          buttonStyle='solid'
          style={{ fontWeight: '400' }}
          value={selectedOption}
          options={[
            { value: 'conversations', label: 'Todas las conversaciones' },
            { value: 'messages', label: 'Todos los mensajes' }
          ]}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <DatePicker
          format='MM-YYYY'
          picker='month'
          value={datePickerValue}
          onChange={(v) => {
            setDatePickerValue(v.startOf('month'));
          }}
          allowClear={false}
          allowEmpty={false}
          maxDate={dayjs().startOf('month').add(1, 'm')}
        />
      </div>
      <Spin
        indicator={<LoadingIcon size='large' />}
        spinning={loadingEstadisticas}
      >
        {selectedOption === 'conversations' ? (
          <EstadisticasConversaciones
            conversationStats={conversationStats}
            datePickerValue={datePickerValue}
          />
        ) : (
          <EstadisticasMensajes
            messageStats={messageStats}
            datePickerValue={datePickerValue}
          />
        )}
      </Spin>
    </div>
  );
};
