import { Button, Card, Form, Input, Spin, Tooltip } from "antd";
import "./main.css";
import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthCode from "react-auth-code-input";
import { GlobalContext } from "../../context/GlobalContext";
import { LoadingOutlined } from "@ant-design/icons";
import { handleKeyPressInstanceName } from "../Layout/flows/utils/httpRequest";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import {
  confirmDraft,
  validateInstanceName,
} from "../../../helpers/fetchData/fetchInstance";
import { getFormattedInstanceName } from "../../../utils/getFormattedInstanceName";
// import { validateInstanceName } from "../../../helpers/validateInstanceName";

export const MailConfirmation = () => {
  const { user } = useAuth0();
  const { instance, setInstance, messageApi, setWorkspaces } =
    useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(0);
  const [otp, setOtp] = useState("");

  const [form] = Form.useForm();

  const SIZE = 6;

  const handleOnChange = (value) => {
    setOtp(value);
  };

  const estadoInstancia = (check) => {
    switch (check) {
      case 0:
        return <></>;
      case 1:
        return (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: "16px",
                }}
                spin
              />
            }
          />
        );
      case 2:
        return (
          <AiOutlineCheckCircle style={{ color: "var(--primary-color)" }} />
        );
      default:
        return (
          <Tooltip>
            <span>
              <FiAlertCircle style={{ color: "#cc0033" }} />
            </span>
          </Tooltip>
        );
    }
  };

  const validarNombreInstancia = (nombre) => {
    let flag = "";
    let fetch = () => {
      return new Promise((resolve, reject) => {
        validateInstanceName(nombre)
          .then((res) => {
            if (res?.status === 200) {
              if (res.data.message === "OK.") {
                flag = false;
              } else {
                flag = true;
              }
              resolve(flag);
            } else {
              messageApi.error("No se pudo validar el nombre.");
              reject(true);
            }
          })
          .catch(function (error) {
            messageApi.error("No se pudo validar el nombre.");
            reject(true);
          });
      });
    };

    return fetch();
  };

  const sendConfirmation = async (v) => {
    let orgName = v.name?.trim() || "";

    let instanceName = getFormattedInstanceName(orgName);

    if (!instanceName) {
      messageApi.info("Ingrese un nombre");
      return;
    }

    if (otp?.length < SIZE) {
      messageApi.info("Complete el código");
    }

    let data = {
      idDraft: instance.idDraft,
      userEmail: user.email,
      userName: user.name,
      otp: otp.toString(),
      name: instanceName.toLowerCase(),
      orgName: orgName,
    };

    // let config = {
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_PRODUCCION}/instance/confirmDraft`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    await validarNombreInstancia(instanceName)
      .then((flag) => {
        if (!flag) {
          // const request = axios(config);
          setLoading(true);
          confirmDraft(data)
            .then((res) => {
              if (res?.status === 200) {
                setWorkspaces((prevState) => {
                  let object = {
                    ...prevState,
                    draft: { ...prevState.draft, status: "ALTA PENDING" },
                  };

                  return object;
                });
                setInstance(null);

                messageApi.success(
                  "Mail confirmado correctamente, ¡nos contactaremos a la brevedad!"
                );
              } else if (res?.status === 409) {
                messageApi.error(res.data.error);
              } else {
                messageApi.error("Ocurrió un error al confirmar el código");
              }
            })
            .catch((error) => {
              console.log(error);
              messageApi.error("Ocurrió un error al confirmar el código");
            })
            .finally(() => setLoading(false));
        } else {
          messageApi.error("No se pudo validar el nombre");
        }
      })
      .catch((error) => {
        setCheck(3);
      });
  };

  useEffect(() => {
    if (instance?.orgName) {
      form.setFieldsValue({
        name: instance.orgName,
      });
    }
  }, [instance]);

  return (
    <div className="instance-main-container">
      <Form
        form={form}
        name="mail-validation"
        layout="vertical"
        className="first-registry-form"
        onFinish={sendConfirmation}
      >
        <b style={{ fontSize: 20, marginBottom: 0 }}>{instance?.userEmail}</b>
        <p style={{ fontSize: 12, marginBottom: 24 }}>
          Enviamos un código de confirmación al mail, revise su bandeja.
        </p>
        {/* <Form.Item label="Nombre de su empresa" name="orgName">
          <Input />
        </Form.Item> */}
        <Form.Item
          label="Nombre de su empresa"
          name="name"
          onKeyPress={(e) => handleKeyPressInstanceName(e)}
          rules={[
            {
              validator(rule, value) {
                setCheck(1);
                return new Promise((resolve, reject) => {
                  if (value.length > 3) {
                    if (!value.match(new RegExp(/^[a-zA-Z0-9 ]*$/))) {
                      setCheck(3);
                      reject("No se permiten caracteres especiales");
                    } else {
                      validarNombreInstancia(getFormattedInstanceName(value))
                        .then((flag) => {
                          if (!flag) {
                            setCheck(2);
                            resolve();
                          } else {
                            setCheck(3);
                            reject("El nombre no se encuentra disponible");
                          }
                        })
                        .catch((error) => {
                          setCheck(3);
                          reject("No se pudo validar el nombre");
                        });
                    }
                  } else {
                    reject("Ingrese como mínimo 4 caracteres");
                    setCheck(0);
                  }
                });
              },
            },
          ]}
        >
          <Input
            type="text"
            suffix={estadoInstancia(check)}
            maxLength={30}
            showCount
          />
        </Form.Item>

        <Form.Item label="Ingrese el código de confirmación">
          <Card style={{ border: "none", padding: 0 }}>
            <AuthCode
              length={SIZE}
              allowedCharacters="numeric"
              autoFocus={true}
              value={otp}
              onChange={handleOnChange}
              containerClassName="container-auth-code"
              inputClassName="inputs-auth-code"
            />
          </Card>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <span className="horizontal">
            <Button type="primary" block htmlType="submit" loading={loading}>
              Confirmar
            </Button>
            <Button
              type="secondary"
              block
              style={{ border: "1px solid #d8d8d8" }}
              onClick={() => setInstance({})}
            >
              Volver
            </Button>
          </span>
        </Form.Item>
      </Form>
    </div>
  );
};
