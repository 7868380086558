export const actualizarNewProduct = (setCatalogDetail, data, res) => {
  setCatalogDetail((prevState) => {
    let object = { ...prevState };

    let products = [...object?.products];

    let indice = null;

    if (data.listId === "sin lista") {
      indice = products?.findIndex((list) => list._id === null);
    } else {
      indice = products?.findIndex((list) => list._id === data.listId);
    }

    if (indice > -1) {
      products[indice].products = [
        { ...data, ...res.data?.product },
        ...products[indice]?.products,
      ];

      object.products = products;

      return object;
    } else {
      products?.push({
        _id: data.listId,
        products: [{ ...data, ...res.data?.product }],
      });

      object.products = products;

      return object;
    }
  });
};

export const actualizarUpdateProduct = (setCatalogDetail, data, res) => {
  setCatalogDetail((prevState) => {
    let object = { ...prevState };

    let products = [...object?.products];

    let indice = null;

    if (data.listId === "sin lista") {
      indice = products?.findIndex((list) => list._id === null);
    } else {
      indice = products?.findIndex((list) => list._id === data.listId);
    }

    // ELIMINA REGISTRO DE LA LISTA ANTES DE PEGAR LE NUEVO;
    products?.forEach((lista, listIndex) => {
      lista.products.forEach((producto, productIndex) => {
        if (producto._id === data._id) {
          products[listIndex].products.splice(productIndex, 1);
        }
      });
    });

    if (indice > -1) {
      //SI HAY PRODUCTOS EN LA LISTA
      let innerProduct = [
        { ...data, ...res.data?.product },
        ...products[indice].products,
      ];

      products[indice].products = innerProduct;

      object.products = products;

      return object;
    } else {
      // SI ES UNA LISTA SIN PRODUCTOS (NO ESTÁ LISTADA EN PRODUCTS) HAY QUE AGREGARLA
      products?.push({
        _id: data.listId,
        products: [{ ...data }],
      });

      object.products = products;

      return object;
    }
  });
};

export const actualizarDeleteProduct = (setCatalogDetail, id) => {
  setCatalogDetail((prevState) => {
    let object = { ...prevState };

    let listIndex = -1;

    let productIndex = -1;

    object.products?.forEach((list, index) => {
      let indice = list.products.findIndex((product) => product._id === id);
      if (indice > -1) {
        listIndex = index;
        productIndex = indice;
      }
    });

    if (listIndex > -1 && productIndex > -1) {
      let productId =
        object.products[listIndex]?.products?.[productIndex]?.id?.toString();

      if (productId) {
        let conjuntos = object.productsSets.map((conjunto) => {
          if (conjunto.filter) {
            let filtro = JSON.parse(conjunto.filter);
            filtro = filtro.retailer_id.is_any.filter(
              (identificador) => identificador !== productId
            );

            filtro = JSON.stringify({ retailer_id: { is_any: filtro } });
            return {
              ...conjunto,
              filter: filtro,
            };
          } else {
            return conjunto;
          }
        });

        object.productsSets = conjuntos;
      }

      object.products[listIndex].products.splice(productIndex, 1);
    }

    return object;
  });
};

export const actualizarUpdatePrices = (
  setCatalogDetail,
  selectedRowKeys,
  porcentaje
) => {
  const parsedPercent = parseFloat(porcentaje);

  setCatalogDetail((prevState) => {
    let object = { ...prevState };

    let array = object.products.map((producto) => {
      if (selectedRowKeys.includes(producto.id)) {
        const currentPrice = parseFloat(
          producto.price?.toString()?.replace(",", ".")
        );

        const updatedPrice = (currentPrice * (1 + parsedPercent / 100))
          ?.toFixed(2)
          ?.replace(".", ",");

        return {
          ...producto,
          price: updatedPrice,
        };
      } else {
        return producto;
      }
    });

    object.products = array;

    return object;
  });
};
