import { useEffect, useState } from 'react';

const mediaTypes = ['audio', 'image', 'document', 'video'];

export const useMedia = (tickets) => {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    if (tickets?.length > 0) {
      let ticketsWithAttachments = [];
      tickets.forEach((ticket) =>
        ticket.messages?.forEach((message) => {
          if (mediaTypes.includes(message.type)) {
            ticketsWithAttachments.push(message);
          }
        })
      );

      setMedia(ticketsWithAttachments);
    }
  }, [tickets]);

  return media;
};
