import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  Button,
  Drawer,
  Form,
  Input,
  Popover,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import {
  formatearVariablesComunes,
  hasSingleVariable,
  isInterpolate,
} from "../../../flows/utils/httpRequest";
import { FiX } from "react-icons/fi";
import {
  addBodyItem,
  deleteItemBody,
  handleValueItemBody,
  handleVariableType,
} from "../../../flows/utils/httpRequestBody";
import { RequestVarsForm } from "../../../flows/forms/RequestVarsForm";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { UrlParams } from "../../../../urlParams/UrlParams";
import { handleInnerVarsEnvios } from "../../../../../../utils/handleInnerVarsEnvios";
import { RequestHeaders } from "../../../../requestHeaders/RequestHeaders";

export const HttpRequestVars = ({
  setMostrarRequestDrawer,
  nodeId,
  setRequestResult,
  hideIndexed,
  nodeData,
  urlParams,
  setUrlParams,
  modulo = "envios",
}) => {
  const [form] = Form.useForm();
  const { messageApi } = useContext(GlobalContext);

  const [mostrarDrawerVars, setMostrarDrawerVars] = useState(false);

  const [method, setMethod] = useState("GET");
  const [url, setUrl] = useState("");
  // const [contentType, setContentType] = useState("application/json");
  // const [authorizationType, setAuthorizationType] = useState("No auth");
  const [headers, setHeaders] = useState([]);
  const [hasBody, setHasBody] = useState(false);
  const [bodyType, setBodyType] = useState("JSON");
  const [body, setBody] = useState("");
  const [variables, setVariables] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [showPopoverProbar, setShowPopoverProbar] = useState(false);
  const [hasVars, setHasVars] = useState(false);
  const [bodyVars, setBodyVars] = useState([]);
  const [indexado, setIndexado] = useState(false);
  const [variablesIDX, setVariablesIDX] = useState([]);
  const [matchClient, setMatchClient] = useState(false);

  // const [authData, setAuthData] = useState({ type: "", object: {}, value: "" });

  const handleVars = (url, setMostrarDrawerVars, messageApi) => {
    if (isInterpolate(url)) {
      setMostrarDrawerVars(true);
      // } else {
      //   messageApi.error("Solo puede configurar una variable");
      // }
    } else {
      setMostrarDrawerVars(true);
    }
  };

  const varOptions = useMemo(() => {
    let array = [];

    if (indexado && variablesIDX) {
      array = [
        ...variablesIDX.map((element) => {
          return {
            value: element.name,
            label: element.name,
          };
        }),
      ];
    }

    return array;
  }, [variablesIDX, indexado]);

  const onFinish = (values) => {
    if (url) {
      let valores = {
        method: method,
        url: url,
        urlParams: urlParams,
        indexado: indexado,
        variable: {
          innerVars: !indexado
            ? handleInnerVarsEnvios(variables, "innerVars")
            : [],
          indexedVars: indexado
            ? handleInnerVarsEnvios(variablesIDX, "indexedVars")
            : [],
        },
        matchClient: matchClient,
      };

      valores.headers = [...headers];

      if (method === "POST") {
        valores.hasBody = hasBody;
        valores.bodyType = bodyType;
        valores.hasVars = hasVars;
        if (!hasVars) {
          let rawString = body.replace(/'/g, '"').replace(/,\s*}/g, "}");
          if (rawString) {
            valores.body = rawString;
          }
        } else {
          if (bodyVars?.length > 0) {
            valores.bodyVars = bodyVars;

            let flag = bodyVars.some(
              (element) => !element.key || !element.value || !element.type
            );

            if (flag) {
              return messageApi.info(
                "Cada item del body debe contenener key, value y type"
              );
            }

            if (bodyVars.some((element) => !hasSingleVariable(element.value))) {
              return messageApi.info(
                "Los valores deben contener una variable como máximo"
              );
            }
          } else {
            return messageApi.info("Incluya al menos un item en el body");
          }
        }
      }

      if (indexado) {
        valores.cursor = values.cursor || null;
      }

      setRequestResult(valores);
      setMostrarRequestDrawer(false);
    } else {
      messageApi.info("Debe incluir una URL");
    }
  };

  useEffect(() => {
    if (nodeData?.httpRequest?.url) {
      const { indexado, url, cursor, headers, matchClient } =
        nodeData.httpRequest;

      setUrl(url);
      if (indexado) {
        setIndexado(indexado);
        form.setFieldsValue({ cursor: cursor });
      }

      if (headers) {
        setHeaders(headers.filter((element) => element.value && element.key));
      }

      if (matchClient) {
        setMatchClient(true);
      }
    }
  }, [nodeData]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formRequest"
      requiredMark={false}
      onFinish={onFinish}
      className="form-nodos"
    >
      <b style={{ marginBottom: 8 }}>Configurar petición</b>
      <Form.Item label="Método">
        <Select
          value={method}
          onChange={(v) => setMethod(v)}
          options={[
            { value: "GET", label: "GET" },
            { value: "POST", label: "POST" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="URL"
        rules={[{ required: true, message: "Campo requerido" }]}
        style={{ marginBottom: 8 }}
      >
        <Input.TextArea
          autoSize={{ minRows: 2 }}
          type="text"
          onChange={(v) => setUrl(v.target.value)}
          value={url}
          id="urlInput"
        />
      </Form.Item>
      <UrlParams
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        modulo={modulo}
      />
      <div style={{ padding: 8 }}></div>
      <RequestHeaders
        headers={headers}
        setHeaders={setHeaders}
        modulo={modulo}
      />
      {method !== "GET" && (
        <span
          className="horizontal"
          style={{ margin: "16px 0px", justifyContent: "space-between" }}
        >
          <span className="horizontal">
            <p>Body</p>
            <Switch
              onChange={(v) => {
                setHasBody(v);
              }}
              checked={hasBody}
            />
          </span>
          {hasBody && method !== "GET" && (
            <span className="horizontal">
              <p>JSON</p>
              <Switch checked={hasVars} onChange={(v) => setHasVars(v)} />
              <p>Con variables</p>
            </span>
          )}
        </span>
      )}
      {hasBody && method !== "GET" && (
        <>
          {hasVars ? (
            <div className="variables-body-wrapper">
              <span className="horizontal variables-body-header-wrapper">
                <p className="variables-body-header">Key</p>
                <p className="variables-body-header">Value</p>
                <p className="variables-body-header">Type</p>
              </span>
              {bodyVars.map((variable, index) => {
                return (
                  <span className="variable-body-item" key={index}>
                    <span className="columna-body-item">
                      <Input
                        value={variable.key}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            "key",
                            v.target.value
                          )
                        }
                      />
                    </span>
                    <span className="columna-body-item">
                      <Input
                        value={variable.value}
                        onChange={(v) =>
                          handleValueItemBody(
                            setBodyVars,
                            index,
                            "value",
                            v.target.value
                          )
                        }
                        // addonAfter={<AddonVariable index={index} />}
                      />
                    </span>
                    <Select
                      value={handleVariableType(
                        variable.type,
                        variable.value
                        // flowVariables
                      )}
                      onChange={(v) =>
                        handleValueItemBody(setBodyVars, index, "type", v)
                      }
                      options={[
                        { value: "string", label: "String" },
                        { value: "number", label: "Number" },
                      ]}
                    />
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteItemBody(setBodyVars, index)}
                    >
                      <FiX />
                    </span>
                  </span>
                );
              })}
              <span
                style={{ width: "100%", display: "flex", margin: "8px 0px" }}
              >
                <Button
                  className="btn-aceptar btn-oscuro"
                  style={{ margin: "0px 8px 8px auto" }}
                  type="primary"
                  size="small"
                  onClick={() => addBodyItem(setBodyVars)}
                >
                  Agregar item
                </Button>
              </span>
            </div>
          ) : (
            <Form.Item>
              <Input.TextArea
                autoSize={{ minRows: 2 }}
                onChange={(v) => setBody(v.target.value)}
                value={body}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item style={{ marginTop: 12 }}>
        {method === "POST" ? (
          <Popover
            trigger="click"
            placement="topRight"
            open={showPopoverProbar}
            content={
              <div className="columna-simple">
                <span style={{ marginBottom: "8px" }}>
                  ¿Está seguro de probar esta petición?
                </span>
                <span style={{ marginBottom: "16px", fontWeight: "normal" }}>
                  Esto puede tener un impacto no deseado.
                </span>
                <div className="botones-wrapper-content">
                  <Button
                    type="primary"
                    className="btn-guardar"
                    onClick={() => {
                      setShowPopoverProbar(false);
                      handleVars(url, setMostrarDrawerVars, messageApi);
                    }}
                  >
                    Confirmar
                  </Button>
                  <Button
                    className="btn-cancelar"
                    type="secondary"
                    onClick={() => setShowPopoverProbar(false)}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              type="secondary"
              className="btn-oscuro"
              onClick={() => setShowPopoverProbar(true)}
              block
            >
              Probar
            </Button>
          </Popover>
        ) : (
          <Button
            type="secondary"
            onClick={() => handleVars(url, setMostrarDrawerVars, messageApi)}
            className="btn-oscuro"
            block
          >
            Probar
          </Button>
        )}
      </Form.Item>
      {indexado && varOptions && (
        <Form.Item
          style={{ marginBottom: 24 }}
          name="cursor"
          label={
            <span className="columna">
              <Switch
                checked={matchClient}
                unCheckedChildren="Contacto"
                checkedChildren="Cliente"
                onChange={(v) => setMatchClient(v)}
                style={{ width: "fit-content" }}
              />
              {matchClient
                ? "Seleccione un campo con el que identificará el cliente (ID cuenta)"
                : "Seleccione un campo con el que identificará el contacto (Número de teléfono)"}
            </span>
          }
          rules={[
            {
              required: true,
              message: "Es necesario que seleccione una variable",
            },
          ]}
        >
          <Select options={varOptions || []} />
        </Form.Item>
      )}
      <BoxInfo
        message={
          'Al elegir una variable obtenida a traves de una petición, asegúrese que sea tipo "String" o "Number", de otro modo podría fallar.'
        }
      />
      {!indexado && variables?.length > 0 && (
        <Form.Item
          label="Variables seleccionadas"
          style={{ marginTop: "16px" }}
        >
          <span className="columna">
            {variables.map((element, index) => {
              if (element.checked) {
                return (
                  <Tooltip title={element.name} key={index}>
                    <Tag
                      color="green"
                      style={{ maxWidth: "100%", width: "fit-content" }}
                    >
                      {formatearVariablesComunes(element.name)}
                    </Tag>
                  </Tooltip>
                );
              } else {
                return <></>;
              }
            })}
          </span>
        </Form.Item>
      )}
      {indexado && variablesIDX?.length > 0 && (
        <Form.Item
          label="Variables seleccionadas"
          style={{ marginTop: "16px" }}
        >
          <span className="columna">
            {variablesIDX.map((element, index) => {
              if (element.checked) {
                return (
                  <Tooltip title={element.name} key={index}>
                    <Tag
                      color="green"
                      style={{ maxWidth: "100%", width: "fit-content" }}
                    >
                      {element.name.substring(element.name.indexOf("[idx]"))}
                    </Tag>
                  </Tooltip>
                );
              } else {
                return <></>;
              }
            })}
          </span>
        </Form.Item>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            disabled={method === "POST" ? false : !isValid}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarRequestDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
      <Drawer
        open={mostrarDrawerVars}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <RequestVarsForm
          setMostrarDrawerVars={setMostrarDrawerVars}
          method={method}
          url={url}
          body={body}
          bodyVars={bodyVars}
          hasBody={hasBody}
          setVariables={setVariables}
          nodeId={nodeId}
          setIsValid={setIsValid}
          indexado={indexado}
          setIndexado={setIndexado}
          variablesIDX={variablesIDX}
          setVariablesIDX={setVariablesIDX}
          hideIndexed={hideIndexed}
          urlParams={urlParams}
          setUrlParams={setUrlParams}
          headers={headers}
          setHeaders={setHeaders}
        />
      </Drawer>
    </Form>
  );
};
