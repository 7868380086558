import { useContext, useMemo } from "react";
import { GlobalContext } from "../components/context/GlobalContext";
import { DEVSAPP_INSTANCE_ID } from "../utils/DEVS_INSTANCE";

// Id de instancia "devsapp"

export const useIsDevsInstance = () => {
  const { instance } = useContext(GlobalContext);

  const isDevsInstance = useMemo(() => {
    if (!instance?._id) {
      return false;
    }

    return instance?._id === DEVSAPP_INSTANCE_ID;
  }, [instance]);

  return { isDevsInstance };
};
