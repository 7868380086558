/*
data = Datos binarios que componen al archivo
fileName = Nombre con que se quiere guardar el archivo, incluyendo la extensión.
fileType = El tipo MIME del archivo (ver https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
*/

export const downloadFile = (data, fileName, fileType) => {
  // Crea un blob:
  const blob = new Blob([data], { type: fileType });

  // Crea una URL temporal para el blob:
  const url = window.URL.createObjectURL(blob);

  // Crea un elemento a:
  const a = document.createElement('a');
  a.href = url;

  // Establece el nombre del archivo a descargar:
  a.download = fileName;

  // Simula un click para descargar el archivo:
  a.click();

  // Libera la URL temporal creada para evitar fugas de memoria
  window.URL.revokeObjectURL(url);
};

/*
fileUrl = URL del archivo
fileName = Nombre con que se quiere guardar el archivo, incluyendo la extensión.
fileType = El tipo MIME del archivo (ver https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
*/
export const downloadFileFromURL = async (fileUrl, fileName, fileType) => {
  try {
    // Realiza la solicitud a la URL para obtener el archivo:
    const response = await fetch(fileUrl);

    if (!response.ok) {
      console.error('Error al descargar el archivo');
    }

    // Convierte los datos en un ArrayBuffer, el formato adecuado para tratar datos binarios:
    const data = await response.arrayBuffer();

    downloadFile(data, fileName, fileType);
  } catch (err) {
    console.error('Error al obtener el archivo:', err);
  }
};
