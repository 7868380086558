import { Button, Form, Input, Result } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineApi } from "react-icons/ai";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useAuth0 } from "@auth0/auth0-react";
import { createDraftWorkspace } from "../../../../../../helpers/fetchData/fetchInstance";
import { BoxInfo } from "../../../../box-info/BoxInfo";

export const SinInstancia = () => {
  const { messageApi, setInstance, workspaces, setWorkspaces } =
    useContext(GlobalContext);
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const data = {
      industry: values.industry,
      orgName: values.orgName,
      website: values.website,
      role: values.role,
      phoneNumber: values.phoneNumber,
      email: user?.email,
      contactName: user?.name || "",
    };

    setLoading(true);
    createDraftWorkspace(data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Enviamos un mail con el código de validación");

          const dataDraft = { ...res?.data?.data, status: "MAIL PENDING" };

          delete dataDraft._id;

          setWorkspaces((prevState) => {
            return {
              ...prevState,
              draft: dataDraft,
            };
          });
          setInstance(dataDraft);
        } else {
          messageApi.error("No se pudo validar el código");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al registrar un workspace");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="columna"
      style={{
        maxHeight: "100%",
        marginTop: "10vh",
        border: "1px solid #d8d8d8",
        borderRadius: 8,
        padding: 24,
        width: 500,
      }}
    >
      <Result
        style={{ padding: 8 }}
        icon={<AiOutlineApi size={56} color="var(--primary-color)" />}
        title={
          workspaces?.instance?.length > 0
            ? "¿Desea crear un espacio de trabajo?"
            : "No existe workspace creado"
        }
        subTitle={
          workspaces?.instance?.length > 0
            ? ""
            : "Es necesario crear uno para utilizar la plataforma"
        }
      />
      <div
        className="columna gap8"
        // style={{ justifyContent: "center" }}
        key={0}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Nombre de su empresa"
            name="orgName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Industria"
            name="industry"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Página de la empresa" name="website">
            <Input type="web" />
          </Form.Item>
          <Form.Item label="¿Cuál es su rol dentro de la empresa?" name="role">
            <Input />
          </Form.Item>
          <Form.Item
            label="Teléfono de contacto"
            name="phoneNumber"
            type="tel"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: "Solo se permiten caracteres númericos",
              },
              {
                min: 11,
                max: 12,
                message:
                  "El número debe contener entre 11 y 12 caracteres (ej: 543535123456)",
              },
            ]}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item label="Email">
            <Input type="email" value={user?.email || ""} disabled />
          </Form.Item>
          <BoxInfo message="Enviaremos un código de confirmación a este email" />
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              className="botones-wrapper-content"
              style={{ justifyContent: "space-between", marginTop: 24 }}
            >
              <Button
                type="primary"
                className="btn-agregar"
                htmlType="submit"
                loading={loading}
              >
                Confirmar
              </Button>
              <Button onClick={() => setInstance({})}>Volver</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
