import { Button, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../../../context/GlobalContext";
import { newMessageCategory, updateCategory } from "../../../../../../../../helpers/fetchData/fetchSavedMessages";
import LoadingIcon from "../../../../../../Loader/LoadingIcon";

export const NewCategory = ({
  setMensajesDrawer,
  setGrupos,
  edit = false,
  mensajesDrawer,
}) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);

  const confirmar = () => {
    let titulo = title.trim() || "";

    if (!titulo) {
      return messageApi.info("Es necesario que ingrese un título");
    }

    setLoading(true);

    if (!edit) {
      newMessageCategory(instance?.userData?.apiKey, { name: titulo })
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Categoría creada correctamente");

            if (res.data.id) {
              setGrupos((prevState) => {
                let array = [...prevState];

                array.push({
                  _id: res.data.id,
                  name: titulo,
                  savedReplies: [],
                });

                return array;
              });
            }

            setMensajesDrawer({ visible: false, content: "", item: null });
          } else {
            messageApi.error("Ocurrió un error al crear una categoría");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al crear una categoría");
        })
        .finally(() => setLoading(false));
    } else {
      let data = {
        name: titulo,
        categoryId: mensajesDrawer.item._id,
      };

      updateCategory(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Categoría creada correctamente");

            setGrupos((prevState) => {
              let array = [...prevState];

              let index = array.findIndex(
                (element) => element._id === data.categoryId
              );

              if (index >= 0) {
                array[index] = { ...array[index], name: data.name };
              }

              return array;
            });

            setMensajesDrawer({ visible: false, content: "", item: null });
          } else {
            messageApi.error("Ocurrió un error al crear una categoría");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al crear una categoría");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (edit) {
      if (mensajesDrawer.item?.name) {
        setTitle(mensajesDrawer.item.name);
      }
    }
  }, [mensajesDrawer]);

  return (
    <div
      className="form-nodos"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <b style={{ marginBottom: "16px" }}>
        {!edit ? "Nueva categoría" : "Editar categoría"}
      </b>
      <p style={{ marginBottom: 8 }}>Título</p>
      <Input value={title} onChange={(v) => setTitle(v.target.value)} />
      <div
        className="botones-wrapper-content form-custom-footer"
        style={{ marginBottom: 12 }}
      >
        <Button
          type="primary"
          className="btn-guardar"
          size="large"
          onClick={() => confirmar()}
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Guardar
        </Button>
        <Button
          className="btn-cancelar"
          size="large"
          type="secondary"
          onClick={() => {
            setMensajesDrawer({ visible: false, content: "", item: null });
          }}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
