import { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, Radio, Select, Tabs, Badge, Tooltip } from 'antd';
import { updateNode } from '../utils/updateNode';
import { PiMathOperations } from 'react-icons/pi';
import { handleFlowVariables } from '../utils/handleFlowVariables';
import { getVariableName } from '../utils/getVariableName';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import { FORMULAS, SEPARATORS, DAYJS_LOCALE, TIME_ZONES } from '../utils/formulas';
import { HeaderForm } from './componentsForm/HeaderForm';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/es'; // Importar español
import 'dayjs/locale/en'; // Importar inglés

dayjs.extend(customParseFormat);


const INPUT_TYPES = [
  { value: 'string', label: 'Texto' },
  { value: 'number', label: 'Número' },
  { value: 'variable', label: 'Variable' },
  { value: 'regexp', label: 'RegExp' },
  // { value: 'json', label: 'JSON' }
];

const DEFAULT_LABEL = 'Ingrese un valor (input)';

const InputTypes = ({ element, index, setInput, form }) => {

  const { flowVariables } = useContext(FlowContext);
  const { messageApi } = useContext(GlobalContext);
  const [hasVars, setHasVars] = useState(false);
  const [bodyVars, setBodyVars] = useState([]);

  const handleValue = (value) => {
    setInput((prevState) => {
      let array = [...prevState];

      array[index] = {
        ...array[index],
        value: value
      };

      return array;
    });
  };

  //console.log("element:", element)

  form.setFieldsValue({ [`input-${index}`]: element.value });

  if (element.type === 'string') {

    return (
      <Input.TextArea
        value={element.value}
        //onChange={(v) => handleValue(v.target.value)}
        onChange={(v) => {
          handleValue(v.target.value);
          form.setFieldsValue({ [`input-${index}`]: v.target.value });
        }}
      />
    );
  } else if (element.type === 'number') {
    return (
      <Input
        type='number'
        value={element.value}
        onChange={(v) => {
          handleValue(v.target.value);
          form.setFieldsValue({ [`input-${index}`]: v.target.value });
        }}
      />
    );
  } else if (element.type === 'variable') {
    return (
      <Select
        value={element.value}
        //onChange={(v) => handleValue(v)}
        onChange={(v) => {
          handleValue(v);
          form.setFieldsValue({ [`input-${index}`]: v });
        }}
        options={flowVariables?.map((element) => {
          return { value: element.name, label: element.name };
        })}
      />
    );
  } else if (element.type === 'regexp') {
    return (
      <Input.TextArea
        value={element.value}
        onChange={(v) => {
          handleValue(v.target.value);
          form.setFieldsValue({ [`input-${index}`]: v.target.value });
        }}
      />
    );

  } else if (element.type === 'JSON') {
    // return (
    //   <>
    //     {hasVars ? (
    //       <div className="variables-body-wrapper">
    //         <span className="horizontal variables-body-header-wrapper">
    //           <p className="variables-body-header">Key</p>
    //           <p className="variables-body-header">Value</p>
    //           <p className="variables-body-header">Type</p>
    //         </span>
    //         {element.value?.map((variable, index) => {
    //           return (
    //             <span className="variable-body-item" key={index}>
    //               <span className="columna-body-item">
    //                 <Input
    //                   value={variable.key}
    //                   onChange={(v) =>
    //                     handleValueItemBody(
    //                       setBodyVars,
    //                       index,
    //                       "key",
    //                       v.target.value
    //                     )
    //                   }
    //                 />
    //               </span>
    //               <span className="columna-body-item">
    //                 <Input
    //                   value={variable.value}
    //                   onChange={(v) =>
    //                     handleValueItemBody(
    //                       setBodyVars,
    //                       index,
    //                       "value",
    //                       v.target.value
    //                     )
    //                   }
    //                   addonAfter={<AddonVariable index={index} />}
    //                 />
    //               </span>
    //               <Select
    //                 // value={handleVariableType(
    //                 //   variable.type,
    //                 //   variable.value,
    //                 //   flowVariables
    //                 // )}
    //                 onChange={(v) => {
    //                   //   handleValueItemBody(setBodyVars, index, "type", v)
    //                 }}
    //                 options={[
    //                   { value: "string", label: "String" },
    //                   { value: "number", label: "Number" },
    //                 ]}
    //               />
    //               <span
    //                 style={{ cursor: "pointer" }}
    //                 // onClick={() => deleteItemBody(setBodyVars, index)}
    //               >
    //                 <FiX />
    //               </span>
    //             </span>
    //           );
    //         })}
    //         <span style={{ width: "100%", display: "flex", margin: "8px 0px" }}>
    //           <Button
    //             className="btn-aceptar btn-oscuro"
    //             style={{ margin: "0px 8px 8px auto" }}
    //             type="primary"
    //             size="small"
    //             // onClick={() => addBodyItem(setBodyVars)}
    //           >
    //             Agregar item
    //           </Button>
    //         </span>
    //       </div>
    //     ) : (
    //       <Form.Item>
    //         <Input.TextArea
    //           autoSize={{ minRows: 2 }}
    //           onChange={(v) => handleValue(v.target.value)}
    //           value={element.value}
    //         />
    //       </Form.Item>
    //     )}
    //   </>
    // );
  }
};

export const FormulaForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);
  const {
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    nodes,
    setFlowVariables,
    setEdges
  } = useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [selectedType, setSelectedType] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);

  // Operaciones con variables
  const [showPopover, setShowPopover] = useState(false);
  const [variable, setVariable] = useState();
  const [variableLabel, setVariableLabel] = useState('variable');

  //Conversion date: INPUT
  const [selectedOrderI, setSelectedOrderI] = useState(null);
  const [selectedSeparatorI, setSelectedSeparatorI] = useState(null);
  const [selectedFinalFormatI, setSelectedFinalFormatI] = useState(null);
  const [selectedLanguageI, setSelectedLanguageI] = useState(null);
  const [selectedTimeZoneI, setSelectedTimeZoneI] = useState(null);

  //Conversion date: OUTPUT
  const [selectedOrderO, setSelectedOrderO] = useState(null);
  const [selectedSeparatorO, setSelectedSeparatorO] = useState(null);
  const [selectedFinalFormatO, setSelectedFinalFormatO] = useState(null);
  const [selectedLanguageO, setSelectedLanguageO] = useState(null);
  const [selectedTimeZoneO, setSelectedTimeZoneO] = useState(null);


  //Validacion campos date
  const [tabIHasErrors, setTabIHasErrors] = useState(false);
  const [tabOHasErrors, setTabOHasErrors] = useState(false);

  const [input, setInput] = useState([{ type: 'string', value: '' }]);

  const onFinish = (values) => {

    // console.log('values', values)

    if ((values.formatI && values.formatO) && (values.formatI === values.formatO)) {
      if (values.languageI && values.languageO) {
        if (values.languageI === values.languageO) return messageApi.error('El formato de entrada y salida son iguales');
      } else {
        return messageApi.error('El formato de entrada y salida son iguales');
      }
    }

    let data = {};

    // values.label = nombreNodo;
    data.label = nombreNodo;

    // values.type = selectedType;
    data.type = selectedType;

    // values.operation = selectedOperation;
    data.operation = selectedOperation;

    // Solo en caso de Tipo operacion = date
    if (selectedType === 'date') {

      //Validacion formato de entrada valido con input proporcionado
      const dateTest = values['input-0'];
      let isValid = true;

      // No se puede saber que valor tendra la variable.
      if (input[0].type !== 'variable') {
        isValid = validateDate(dateTest.trim(), selectedFinalFormatI, selectedLanguageI);
      }

      if (!isValid) {
        return messageApi.error('Ingrese un valor de input que corresponda al formato de entrada');
      }

      // values.operation = 'date';
      data.operation = 'date';

      let dateInputValues = {
        orderI: selectedOrderI,
        separatorI: selectedSeparatorI,
        languageI: selectedLanguageI,
        finalFormatI: selectedFinalFormatI,
        timeZoneI: selectedTimeZoneI
      };

      let dateOutputValues = {
        orderO: selectedOrderO,
        separatorO: selectedSeparatorO,
        languageO: selectedLanguageO,
        finalFormatO: selectedFinalFormatO,
        timeZoneO: selectedTimeZoneO
      };

      data.dateInputValues = dateInputValues;
      data.dateOutputValues = dateOutputValues;
    }
    //console.log('data: ', data)
    //return

    data.input = input;

    if (selectedType !== 'variable') {
      if (!values.variableName) {
        return messageApi.error('Ingrese un nombre para la variable');
      }
  
      data.variable = { name: '', type: values.variableType };
  
      let variableName = getVariableName(values.variableName, 'formula');
  
      data.variableName = variableName;
  
      data.variable.name = variableName;
      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        data.variable,
        messageApi,
        setNodes,
        setEdges
      );
  
      if (!flag) return;
    } else {
      if (!variable) {
        return messageApi.error('Seleccione una variable');
      }
      data.variable = variable;
    }
    
    data.handleCount = 1;

    // El segundo parametro de updateNode es el que almacenara visualmente los valores del bloque.
    setNodes((prevState) => updateNode(nodoSeleccionado, data, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {

    let operation = FORMULAS.find(
      (element) => element.value === selectedType
    )?.formulas.find((element) => element.value === selectedOperation);

    if (selectedType === 'date') operation = { multiple: false };

    if (operation) {
      if (operation.multiple) {

        setInput((prevState) => {
          let array = [...prevState];
          //console.log("array", array)
          if (array.length !== operation.multiple) {
            //console.log('if de multiples')
            array = [];
            for (let i = 0; i < operation.multiple; i++) {
              array.push({
                type: 'string',
                value: '',
              });
            }
          }
          return array;
        });

      } else {

        setInput((prevState) => {
          let array = [...prevState];
          //console.log("array", array)
          if (array.length > 1) {
            //console.log('if de 1')
            array = [{ type: 'string', value: '' }];
          }
          return array;
        });
      }
    }
  }, [selectedOperation]);

  const formulaOptions = useMemo(() => {
    if (selectedType) {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas ||
        []
      );
    } else {
      return [];
    }
  }, [selectedType]);

  const dateFormatsI = useMemo(() => {
    if (selectedOrderI === 'eeuu' || selectedOrderI === 'europe' || selectedOrderI === 'iso') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderI)?.separators
          .find((element) => element.value === selectedSeparatorI)?.date_format
        || []
      );
    } else if (selectedOrderI === 'large') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderI)?.language
          .find((element) => element.value === selectedLanguageI)?.date_format
        || []
      );
    } else if (selectedOrderI === 'time') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderI)?.date_format
        || []
      );
    }
    else if (selectedOrderI === 'timestamp') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderI)?.date_format
        || []
      );
    }
    else {
      return [];
    }
  }, [selectedType, selectedOrderI, selectedSeparatorI, selectedLanguageI]);

  const dateFormatsO = useMemo(() => {
    if (selectedOrderO === 'eeuu' || selectedOrderO === 'europe' || selectedOrderO === 'iso') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderO)?.separators
          .find((element) => element.value === selectedSeparatorO)?.date_format
        || []
      );
    } else if (selectedOrderO === 'large') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderO)?.language
          .find((element) => element.value === selectedLanguageO)?.date_format
        || []
      );
    } else if (selectedOrderO === 'time') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderO)?.date_format
        || []
      );
    }
    else if (selectedOrderO === 'timestamp') {
      return (
        FORMULAS.find((element) => element.value === selectedType)?.formulas
          .find((element) => element.value === selectedOrderO)?.date_format
        || []
      );
    }
    else {
      return [];
    }
  }, [selectedType, selectedOrderO, selectedSeparatorO, selectedLanguageO]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { data } = nodoSeleccionado;

      setNombreNodo(data.label || '');

      if (data.type) {
        setSelectedType(data.type);
        form.setFieldsValue({ type: data.type });
      }

      if (data.operation) {
        setSelectedOperation(data.operation);
        form.setFieldsValue({ operation: data.operation });
      }

      if (data.input) {
        setInput(data.input);
      }

      if(data.variable && data.variable?.idNodo) {
        setVariable(data.variable);
        form.setFieldsValue({ variable: data.variable?.name });
        if (showPopover) {
          setShowPopover(false);
        }
      }

      if (data.variableName) {
        form.setFieldsValue({
          variableName: data.variableName.substring(
            data.variableName.indexOf('_') + 1
          )
        });
      }

      if (data.variableType) {
        form.setFieldsValue({
          variableType: data.variable.type || 'string'
        });
      }

      // //Operaciones con fechas
      if (data.type && data.type === 'date') {
        const { dateInputValues } = data;
        const { dateOutputValues } = data;

        setSelectedOrderI(dateInputValues.orderI);
        setSelectedSeparatorI(dateInputValues.separatorI);
        setSelectedFinalFormatI(dateInputValues.finalFormatI);
        setSelectedLanguageI(dateInputValues.languageI);
        setSelectedTimeZoneI(dateInputValues.timeZoneI);

        form.setFieldsValue({
          typeI: dateInputValues.orderI,
          languageI: dateInputValues.languageI,
          separatorI: dateInputValues.separatorI,
          formatI: dateInputValues.finalFormatI,
          timeZoneI: dateInputValues.timeZoneI
        });

        setSelectedOrderO(dateOutputValues.orderO);
        setSelectedSeparatorO(dateOutputValues.separatorO);
        setSelectedFinalFormatO(dateOutputValues.finalFormatO);
        setSelectedLanguageO(dateOutputValues.languageO);
        setSelectedTimeZoneO(dateOutputValues.timeZoneO);

        form.setFieldsValue({
          typeO: dateOutputValues.orderO,
          languageO: dateOutputValues.languageO,
          separatorO: dateOutputValues.separatorO,
          formatO: dateOutputValues.finalFormatO,
          timeZoneO: dateOutputValues.timeZoneO
        });
      }
    }
  }, [nodoSeleccionado]);


  const operationMemo = useMemo(() => {
    if (selectedOperation) {
      return FORMULAS.find(
        (element) => element.value === selectedType
      )?.formulas.find(
        (element) => element.value === selectedOperation
      );
    }

  }, [selectedOperation]);

  useEffect(() => {
    if (selectedType === 'variable') {
      form.setFieldsValue({ variable: variable.name });
    }
  }, [variable]);

  useEffect(() => {
    if (selectedOrderI === 'timestamp') {
      form.setFieldsValue({ timeZoneI: 'UTC' });
    }
  }, [selectedOrderI, selectedFinalFormatI]);

  useEffect(() => {
    if (selectedOrderO === 'timestamp') {
      form.setFieldsValue({ timeZoneO: 'UTC' });
    }
  }, [selectedOrderO, selectedFinalFormatO]);


  const tab_items = [
    {
      key: '1',
      label: (
        <>
          {tabIHasErrors ? <Badge status="error" text="Formato de entrada" />
            :
            'Formato de entrada'
          }
        </>
      ),
      children: (
        <>
          <Form.Item label="Tipo" name="typeI"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={formulaOptions || []}
              value={selectedOrderI || null}
              onChange={(v) => {
                setSelectedOrderI(v);
                setSelectedLanguageI(null);
                setSelectedSeparatorI(null);
                setSelectedFinalFormatI(null);
                setSelectedTimeZoneI(null);
                form.setFieldsValue({ languageI: undefined, separatorI: undefined, formatI: undefined, timeZoneI: undefined });
              }}
            />
          </Form.Item>

          {(selectedOrderI === 'large' && selectedOrderI !== 'timestamp') && (
            <Form.Item label="Idioma" name="languageI"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={DAYJS_LOCALE || []}
                value={selectedLanguageI || null}
                onChange={(v) => {
                  setSelectedLanguageI(v);
                  setSelectedFinalFormatI(null);
                  form.setFieldsValue({ formatI: undefined });
                }}
              />
            </Form.Item>
          )}

          {(selectedOrderI !== 'time' && selectedOrderI !== 'large' && selectedOrderI !== 'timestamp') && (
            <Form.Item label="Separador" name="separatorI"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={SEPARATORS || []}
                value={selectedSeparatorI || null}
                onChange={(v) => {
                  setSelectedSeparatorI(v);
                  setSelectedFinalFormatI(null);
                  form.setFieldsValue({ formatI: undefined });
                }}
              />
            </Form.Item>
          )}

          {/* {selectedOrderI !== 'timestamp' && ( */}
          <Form.Item label="Formato" name="formatI"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={dateFormatsI.map((option) => ({
                value: option.value,
                label: (
                  <Tooltip title={option.label} placement="top">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{option.label.split(' (')[0]}</span>
                      <span>({option.label.split(' (')[1]}</span>
                    </div>
                  </Tooltip>

                )
              })) || []}
              value={selectedFinalFormatI || null}
              onChange={(v) => {
                setSelectedFinalFormatI(v);
                setSelectedTimeZoneI(null);
                form.setFieldsValue({ timeZoneI: undefined });
              }}
            />
          </Form.Item>
          {/* )} */}

          {(selectedOrderI === 'timestamp' || selectedOrderI === 'time') && (
            <Form.Item label="Zona horaria" name="timeZoneI"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={TIME_ZONES || []}
                value={selectedTimeZoneI || null}
                onChange={(v) => setSelectedTimeZoneI(v)}
                disabled={selectedOrderI === 'timestamp'}
              />
            </Form.Item>
          )}

        </>
      ),
      forceRender: true, // Asegura que el contenido se renderice aunque la pestaña no esté activa

    },
    {
      key: '2',
      label: (
        <>
          {tabOHasErrors ? <Badge status="error" text="Formato de salida" />
            :
            'Formato de salida'
          }
        </>
      ),
      children: (
        <>
          <Form.Item label="Tipo" name="typeO"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={formulaOptions || []}
              value={selectedOrderO || null}
              onChange={(v) => {
                setSelectedOrderO(v);
                setSelectedLanguageO(null);
                setSelectedSeparatorO(null);
                setSelectedFinalFormatO(null);
                setSelectedTimeZoneO(null);
                form.setFieldsValue({ languageO: undefined, separatorO: undefined, formatO: undefined, timeZoneO: undefined });
              }}
            />
          </Form.Item>

          {(selectedOrderO === 'large' && selectedOrderO !== 'timestamp') && (
            <Form.Item label="Idioma" name="languageO"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={DAYJS_LOCALE || []}
                value={selectedLanguageO || null}
                onChange={(v) => {
                  setSelectedLanguageO(v);
                  setSelectedFinalFormatO(null);
                  form.setFieldsValue({ formatO: undefined });
                }}
              />
            </Form.Item>
          )}

          {(selectedOrderO !== 'time' && selectedOrderO !== 'large' && selectedOrderO !== 'timestamp') && (
            <Form.Item label="Separador" name="separatorO"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={SEPARATORS || []}
                value={selectedSeparatorO || null}
                onChange={(v) => {
                  setSelectedSeparatorO(v);
                  setSelectedFinalFormatO(null);
                  form.setFieldsValue({ formatO: undefined });
                }}
              />
            </Form.Item>
          )}


          <Form.Item label="Formato" name="formatO"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={dateFormatsO.map((option) => ({
                value: option.value,
                label: (
                  <Tooltip title={option.label} placement="top">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{option.label.split(' (')[0]}</span>
                      <span>({option.label.split(' (')[1]}</span>
                    </div>
                  </Tooltip>

                )
              })) || []}
              value={selectedFinalFormatO || null}
              onChange={(v) => {
                setSelectedFinalFormatO(v);
                setSelectedTimeZoneO(null);
                form.setFieldsValue({ timeZoneO: undefined });
              }}
            />
          </Form.Item>

          {(selectedOrderO === 'timestamp' || selectedOrderO === 'time') && (
            <Form.Item label="Zona horaria" name="timeZoneO"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select
                options={TIME_ZONES || []}
                value={selectedTimeZoneO || null}
                onChange={(v) => setSelectedTimeZoneO(v)}
                disabled={selectedOrderO === 'timestamp'}
              />
            </Form.Item>
          )}
        </>
      ),
      forceRender: true,
    }
  ];

  const onFinishFailed = (errorInfo) => {

    const { errorFields } = errorInfo;

    // Nombres de los campos  Input a comprobar
    const fieldNamesToCheckInput = ['typeI', 'languageI', 'separatorI', 'formatI', 'timeZoneI'];

    // Nombres de los campos  Output a comprobar
    const fieldNamesToCheckOutput = ['typeO', 'languageO', 'separatorO', 'formatO', 'timeZoneO'];

    // Verificar si alguno de los errores corresponde a los nombres especificados
    const hasErrorsInput = errorFields.some(field =>
      field.name.some(name => fieldNamesToCheckInput.includes(name))
    );
    const hasErrorsOutput = errorFields.some(field =>
      field.name.some(name => fieldNamesToCheckOutput.includes(name))
    );

    if (hasErrorsInput || hasErrorsOutput) {
      messageApi.error("Formato de entrada y Formato de salida requeridos");
    }
    if (hasErrorsInput) {
      setTabIHasErrors(true)
    } else {
      setTabIHasErrors(false)
    }
    if (hasErrorsOutput) {
      setTabOHasErrors(true)
    } else {
      setTabOHasErrors(false)
    }
  };



  //VALIDACION  DE FECHAS
  const validateDate = (date, format, language) => {

    // console.log('validateDate', date, format, language)

    if (language) {

      let dateLanguage = '';
      let daysOfWeek = getDaysByLocale(language);

      if (language === 'es') {
        dayjs.locale('es'); // valida solo en minusculas
      } else {
        dayjs.locale('en'); // valida solo con primer letra en mayus
      }

      if (format.includes("dddd")) {

        let dateSlice = separarPorPrimeraComa(date);
        let dateSlice1 = dateSlice[0];
        let dateSlice2 = '';

        let formatSlice = separarPorPrimeraComa(format);

        // Validacion de primera parte (dddd)
        let firstPartIsValid = isValidDay(quitarTildes(dateSlice1), daysOfWeek);

        // Validacion de segunda parte (,...)
        let secondPartIsValid = false;
        if (dateSlice.length === 2) {
          dateSlice2 = dateSlice[1]
          secondPartIsValid = dayjs(dateSlice2, formatSlice[1], true).isValid();

          if (firstPartIsValid === true && secondPartIsValid === true) {
            return true
          } else {
            return false
          }
        };

        if (firstPartIsValid === true) {
          return true
        } else {
          return false
        }
      }
    }

    if (format === 'X') return dayjs(date, format).isValid();
    return dayjs(date, format, true).isValid();
  };

  const separarPorPrimeraComa = (cadena) => {
    const index = cadena.indexOf(',');
    return index !== -1
      ? [cadena.slice(0, index), cadena.slice(index)]
      : [cadena];
  };

  const capitalizeFirstLetter = (value) => {
    //if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const getDaysByLocale = (value) => {
    const locale = DAYJS_LOCALE.find(locale => locale.value === value);
    return locale ? locale.days : null;
  }

  const isValidDay = (dayInput, daysOfWeek) => {
    return daysOfWeek.includes(dayInput);
  }

  const quitarTildes = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  useEffect(()=>{
    if (variable?.name) setVariableLabel(variable.name)
  },[variable]);

  // console.log('nodes', nodes)
  // console.log('nodoSeleccionado', nodoSeleccionado)
  // console.log('setInput - input: ', input[0].type)

  // console.log('selectedType:', selectedType)
  // console.log('selectedOperation', selectedOperation)

  // console.log('selectedOrderI:', selectedOrderI)
  // console.log('selectedSeparatorI:', selectedSeparatorI)
  // console.log('selectedLanguageI:', selectedLanguageI)
  // console.log('selectedFinalFormatI:', selectedFinalFormatI)
  // console.log('setSelectedTimeZoneI', selectedTimeZoneI)

  // console.log('selectedFinalFormatI: ', selectedFinalFormatI)
  // console.log('selectedFinalFormatO: ', selectedFinalFormatO)
  // console.log('variable?.name: ',variable?.name)

  return (
    <Form
      form={form}
      layout='vertical'
      name='form-formula'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      onFinishFailed={onFinishFailed}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <PiMathOperations style={{ color: 'var(--dark-color)' }} size={32} />
        }
      />

      <Form.Item label='Tipo de operación'
        name="type"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select
          options={FORMULAS}
          value={selectedType || null}
          onChange={(v) => {
            setSelectedOperation(null);
            form.setFieldsValue({ operation: undefined });
            setSelectedType(v);
          }}
        />
      </Form.Item>
      {selectedType !== 'date' && (
        <Form.Item label='Operación a realizar'
        name="operation"
        rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select
            options={formulaOptions || []}
            value={selectedOperation || null}
            onChange={(v) => setSelectedOperation(v)}
          />
        </Form.Item>
      )}
      {selectedType === 'date' && (
        <span id="date-custom-tabs">
          <Tabs defaultActiveKey="1" items={tab_items} style={{ height: "40%" }}
          //onChange={onChange}
          />
        </span>
      )}

      {selectedType === 'variable' && (
        <Form.Item label='Variable'
        name="variable"
        rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Input
            disabled={true}
            style={{ color: '#252525' }}
            value={variable?.name}
          />
          <BotonAgregarVariable
            nodeId={nodoSeleccionado.id}
            seccion='convertir'
            isBranch={true}
            context='buttons'
            variableConvertir={setVariable}
            boton={
              <Button
                className='btn-agregar'
                type='primary'
                size='small'
                style={{ borderRadius: '6px', marginTop: '8px' }}
                onClick={() => setShowPopover(true)}
              >
                Elegir variable
              </Button>
            }
          />
        </Form.Item>
      )}

      {input?.map((element, index) => {
        return (
          <Form.Item key={index}
            name={`input-${index}`}
            rules={[{ required: true, message: "Campo requerido" }]}
            label={operationMemo?.titles && operationMemo?.hasVariableLabel 
              ? `${operationMemo?.titles[index]} ${variableLabel}` 
              : operationMemo?.titles ? 
                `${operationMemo?.titles[index]}` 
                : DEFAULT_LABEL}>
            <>
              <Radio.Group
                optionType='button'
                buttonStyle='solid'
                options={index === 1 && operationMemo?.value === 'replace' ?
                  INPUT_TYPES :
                  INPUT_TYPES.filter(item => item.value !== 'regexp')
                }
                value={element.type}
                onChange={(v) =>
                  setInput((prevState) => {
                    let array = [...prevState];

                    array[index] = {
                      ...array[index],
                      type: v.target.value,
                      value: null
                    };

                    return array;
                  })
                }
                style={{ marginBottom: 8 }}
              />
              <InputTypes
                element={element}
                index={index}
                setInput={setInput}
                input={input}
                form={form}
              />
            </>
          </Form.Item>
        );
      })}

      {selectedType !== 'variable' && (
        <span className='horizontal' style={{ justifyContent: 'space-between' }}>
        <Form.Item
          label='Nombre de la variable (output)'
          name='variableName'
          style={{ width: '63%' }}
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Input addonBefore='formula' placeholder='response' />
        </Form.Item>
        <Form.Item
          label='Tipo'
          name='variableType'
          value='string'
          initialValue='string'
          style={{ width: '35%' }}
        >
          <Select
            options={[
              { value: 'string', label: 'String' },
              { value: 'number', label: 'Number' },
              { value: 'json', label: 'JSON' },
              { value: 'boolean', label: 'Boolean' }
            ]}
          />
        </Form.Item>
      </span>
      )}
      
      {/* <Form.Item
        label="Nombre de la variable de salida (output)"
        name="variableName"
        initialValue={nodoSeleccionado?.data?.variableName}
      >
        <Input addonBefore="formula_" style={{ width: "63%" }} />
      </Form.Item> */}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
