
import { Main } from "../ui/main/Main";

export const InitialView = () => {
  
  return (
    <div className="flexContainer">
      <Main />
    </div>
  );
};
