import { isInterpolate } from "./httpRequest";

export const addBodyItem = (setBodyVars) => {
  setBodyVars((prevState) => [
    ...prevState,
    { key: "", value: "", type: "string" },
  ]);
};

export const handleValueItemBody = (setBodyVars, index, column, value) => {
  setBodyVars((prevState) => {
    let array = [...prevState];

    array[index][column] = value;

    return array;
  });
};

export const deleteItemBody = (setBodyVars, index) => {
  const borrarVar = (prevState) => {
    let array = [...prevState];

    array.splice(index, 1);

    return array;
  };

  setBodyVars((prevState) => borrarVar(prevState));
};

const onlyOneVariable = (text) => {
  let flag = true;
  if (text) {
    const regex = /\${[^\s}]+}/g;
    let filtro = text.match(regex);

    if (filtro?.length > 1) {
      flag = false;
    }
  }

  return flag;
};

export const getInterpolatedVariableName = (text) => {
  const regex = /\${[^\s}]+}/g;
  let matches = text.match(regex);
  if (matches.length > 0) {
    return matches;
  }

  return null;
};

export const getInterpolatedVariableType = (name, flowVariables) => {
  if (name) {
    name = name.replace("${", "").replace("}", "");
  }
  let filtro = flowVariables.filter((element) => element.name === name);

  if (filtro.length > 0) {
    return filtro[0].type;
  } else {
    return "string";
  }
};

export const handleVariableType = (type, value, flowVariables) => {
  value = value?.trim() || "";

  if (value) {
    if (isInterpolate(value)) {
      if (!onlyOneVariable(value)) {
        type = "string";
      } else {
        let matches = getInterpolatedVariableName(value);

        if (matches.length > 0) {
          if (value.length > matches[0].length) {
            type = "string";
          } else {
            type = getInterpolatedVariableType(matches[0], flowVariables);
          }
        }
      }
    }
  }

  return type;
};
