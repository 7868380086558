import { Button, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import { updateNode } from "../utils/updateNode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { manageWaitNode } from "../utils/manageWaitNode";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { clearNodeVariables } from "../utils/clearNodeVariables";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { getTemplates } from "../../../../../helpers/getTemplates";
import { SelectContactos } from "../../../selectContactos/SelectContactos";

export const MessageContactForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    mostrarDrawer,
    setMostrarDrawer,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [inputText, setInputText] = useState("");
  const [auto, setAuto] = useState(true);
  const [nombreNodo, setNombreNodo] = useState("");
  const [form] = Form.useForm();
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);


  const onFinish = (values) => {

    values.contacts = selectedContacts;
    if (selectedContacts.length < 1) {
      return messageApi.error("Contactos es un campo requerido");
    }
    values.label = nombreNodo;
    values.auto = true;
    if (!inputText || inputText?.trim() === '') {
      return messageApi.error("Texto es un campo requerido");
    }
    values.bodyText = cleanLabel(inputText);
    values.headerType = "text";

    if (!defaultTemplate || defaultTemplate.length === 0 || defaultTemplate?.name !== "brocoly_msg_contact") {
      return messageApi.error("No se encontró el template brocoly_msg_contact");
    }
    values.template = defaultTemplate;
    values.caption = null;

    values.variable = {};

    // En caso de desabilitar "esperar respuesta" hay que borrar la variable
    clearNodeVariables(nodoSeleccionado?.id, setFlowVariables);

    values.handleCount = 1;

    manageWaitNode(nodes, setNodes, edges, setEdges, nodoSeleccionado, auto);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (!mostrarDrawer) {
      setInputText("");
    }
  }, [mostrarDrawer]);


  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      setInputText(data.bodyText);

      if (data.contacts) {
        setSelectedContacts(data.contacts);
      }

    }
  }, [nodoSeleccionado]);


  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingTemplate(true);
      getTemplates(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res.data)) {
            //console.log('template res: ', res.data)
            let defaultTemplate = res.data?.find((item) => item.name === 'brocoly_msg_contact');
            setDefaultTemplate(defaultTemplate);
          } else {
            //console.log('Ocurrió un error al obtener plantillas');
            messageApi.error("Ocurrió un error al obtener plantillas");
          }
        })
        .catch((err) => {
          //console.log('Ocurrió un error al obtener plantillas');
          messageApi.error("Ocurrió un error al obtener plantillas");
        })
        .finally(() => setLoadingTemplate(false));
    }
  }, [instance]);


  const cleanLabel = (text) =>
    text
      .replace(/\s+/g, ' ')
      .replace(/\n|\r|\t/g, '')
      .replace(/ {5,}/g, '    ')
      .trim();

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />

      <div style={{ marginBottom: "24px" }}>
        <SelectContactos
          value={selectedContacts}
          setValue={setSelectedContacts}
          mode="multiple"
        />
      </div>

      <HeaderTextOrMedia
        messageType={"text"}
        //setMessageType={setMessageType}
        nodeId={nodoSeleccionado?.id}
        // headerMediaType={headerMediaType}
        // setHeaderMediaType={setHeaderMediaType}
        text={inputText}
        setText={setInputText}
        // url={url}
        // setURL={setURL}
        // setPreview={setPreview}
        // caption={caption}
        // setCaption={setCaption}
        // location={location}
        // setLocation={setLocation}
        context="others"
      />

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            loading={loadingTemplate}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
              setAuto(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
