export const getTotalByItem = (id, idsCart, allProducts, cartDetail) => {
  if (idsCart?.includes(id)) {
    let item = allProducts.find((element) => element.id === id);

    if (item?.price) {
      let price = item.price.split(" ")?.[0]?.replace(",", ".");

      let index = cartDetail?.findIndex((element) => element.id === id);

      if (index >= 0) {
        return (cartDetail[index]?.count * price).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getCountByItem = (id, idsCart, cartDetail) => {
  if (idsCart.includes(id)) {
    let cartItem = cartDetail.find((element) => element.id === id);

    return cartItem?.count;
  } else {
    return 0;
  }
};

export const handleItemRemove = (id, idsCart, cartDetail, setCartDetail) => {
  if (idsCart?.includes(id)) {
    let index = cartDetail?.findIndex((element) => element.id === id);

    if (index >= 0) {
      setCartDetail((prevState) => {
        let array = [...prevState];

        let count = array[index].count;

        if (count > 1) {
          count = count - 1;
        } else {
          array.splice(index, 1);
          return array;
        }

        array[index] = {
          ...array[index],
          count: count,
        };

        return array;
      });
    }
  }
};

export const handleItemAdd = (
  producto,
  idsCart,
  cartDetail,
  setCartDetail,
) => {
  if (producto.id == null) {
    return false;
  }

  if (idsCart?.includes(producto.id)) {
    let index = cartDetail?.findIndex((element) => element.id === producto.id);

    if (index >= 0) {
      setCartDetail((prevState) => {
        let array = [...prevState];

        array[index] = {
          ...array[index],
          count: array[index].count + 1,
        };

        return array;
      });
    }
  } else {
    setCartDetail((prevState) => {
      let array = [...prevState];

      array.push({ id: producto.id, count: 1, item: producto });

      return array;
    });
  }
};

export const getProducts = (productsLists, setAllProducts) => {
  let array = [];

  if (Array.isArray(productsLists)) {
    productsLists?.forEach((lista) =>
      lista?.products?.forEach((producto) => {
        array.push(producto);
      })
    );
  }

  setAllProducts(array);
};

export const handleRemoveItemFromCart = (id, setCartDetail) => {
  setCartDetail((prevState) => {
    let array = [...prevState];

    let index = array.findIndex((element) => element.id === id);

    if (index >= 0) {
      array.splice(index, 1);
    }

    return array;
  });
};

export const handleItemCount = (
  value,
  producto,
  setCartDetail,
  idsCart,
  cartDetail,
) => {
  let number = parseInt(value);

  if (!Number.isNaN(number) && number >= 0) {
    if (number === 0) {
      handleRemoveItemFromCart(producto.id, setCartDetail);
    }

    if (idsCart?.includes(producto.id)) {
      let index = cartDetail?.findIndex((element) => element.id === producto.id);

      if (index >= 0) {
        setCartDetail((prevState) => {
          let array = [...prevState];

          array[index] = {
            ...array[index],
            count: number,
          };

          return array;
        });
      }
    } else {
      setCartDetail((prevState) => {
        let array = [...prevState];

        // let item = catalogDetail.find((element) => element.id === id);

        array.push({ id: producto.id, count: number, item: producto });

        return array;
      });
    }
  }
};
