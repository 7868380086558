import axios from "axios";

export const testRequest = async (request, apiKey) => {
  const config = {
    url: `${process.env.REACT_APP_API_PRODUCCION}/testRequest`,
    method: "post",
    headers: {
      authorization: apiKey,
    },
    data: request,
  };

  return await axios(config);
};
