import { Table, Tooltip } from "antd";
import { AccionesContactos } from "./AccionesContactos";
import { EtiquetasXContactos } from "./EtiquetasXContactos";
import { formatPhoneNumber } from "../rightDrawer/utils/formatPhoneNumber";
import { getContactName } from "../campaigns/campaignBuilder/utils/getContactName";
import { MdOutlineBlock } from "react-icons/md";
import useFetchCollection from "../../../../hooks/useFetchCollection";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import LoadingIcon from "../../Loader/LoadingIcon";
import { getBlacklist } from "../../../../helpers/fetchData/fetchBlacklist";
import { getAdditionalFields } from "../../../../helpers/fetchData/fetchAdditionalFields";

const columns = (additionalFields) => {
  let array = [
    {
      title: "Nombre completo",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      fixed: "left",
      render: (data, record) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              textDecoration: record.isBlocked ? "line-through" : "none",
            }}
          >
            {record.isBlocked && (
              <Tooltip title="Este contacto ha sido bloqueado">
                <span>
                  <MdOutlineBlock style={{ fontSize: 18, color: "red" }} />
                </span>
              </Tooltip>
            )}
            {getContactName(data)}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "emails",
      key: "emails",
      ellipsis: true,
      render: (data) => data?.[0]?.email,
    },
    {
      title: "Teléfono",
      dataIndex: "phones",
      key: "phones",
      width: 150,
      render: (data) => (
        <p className="monospace-font">{formatPhoneNumber(data?.[0]?.phone)}</p>
      ),
    },
    {
      title: "Etiquetas",
      dataIndex: "tags",
      key: "tags",
      render: (data) => {
        if (!data) return <></>;
        return <EtiquetasXContactos data={data} />;
      },
    },
  ];

  // Campos adicionales
  if (additionalFields) {
    additionalFields.forEach((field) => {
      if (field.isVisible) {
        array.push({
          title: field.name,
          key: field._id,
          dataIndex: field._id,
          ellipsis: true,
          align: "center",
          render: (_, record) => {
            const contactAdditionalField = record.additionalFields?.find(
              (f) => f._id == field._id
            );

            if (contactAdditionalField) {
              return contactAdditionalField.value;
            }
            return null;
          },
        });
      }
    });
  }

  array.push({
    title: "...",
    dataIndex: "",
    align: "center",
    key: "",
    fixed: "right",
    width: 120,
    render: (dataIndex, item) => <AccionesContactos item={item} />,
  });

  return array;
};

export const ContactsTable = ({ search, setLoading, page, setPage }) => {
  const { instance, messageApi, contactos, setContactos } =
    useContext(GlobalContext);

  const [bloqueados, setBloqueados] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [loadingAdditionalFields, setLoadingAdditionalFields] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getBlacklist(instance, messageApi, setBloqueados);
    }
  }, [instance]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoadingAdditionalFields
      );
    }
  }, [instance]);

  const [limit] = useState(10);

  const { data, loading, error } = useFetchCollection(
    "contacts",
    page,
    limit,
    search
  );

  useEffect(() => {
    let array = data?.items?.filter((contacto) => !contacto.isArchived) || [];

    array = array?.map((contacto) => {
      const isBlocked = bloqueados.some((bloqueado) => {
        return bloqueado.number === contacto.phones?.[0]?.phone;
      });
      return {
        ...contacto,
        isBlocked: isBlocked,
      };
    });

    setContactos(array);
  }, [data, bloqueados]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <Table
      columns={columns(additionalFields)}
      dataSource={contactos || []}
      onChange={(a, b) => {
        setPage(a?.current);
      }}
      rowKey="_id"
      size="small"
      pagination={{
        pageSize: limit,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} contactos`,
        total: data?.totalItems,
      }}
      tableLayout="fixed"
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size="large" />,
      }}
      scroll={{ x: "max-content" }}
    />
  );
};
