import { Form, Input } from "antd";
import { Base64 } from "js-base64";

export const BasicAuth = ({ setAuthData, authData }) => {
  const onChange = (type, value) => {
    setAuthData((prevState) => {
      let data = { ...prevState };

      let object = { ...data.object };
      object[type] = value;

      data.object = object;

      let user = data.object.user;
      let password = data.object.password;

      let string = `${user || ""}:${password || ""}`;

      data.value = Base64.encode(string, true);
      data.type = "Basic Auth";

      if (!data.value.startsWith("Basic ")) {
        data.value = `Basic ${data.value}`;
      }

      return data;
    });
  };
  return (
    <span className="horizontal" style={{ width: "100%" }}>
      <Form.Item label="Usuario" style={{ width: "50%" }}>
        <Input
          value={authData?.object?.user}
          onChange={(v) => onChange("user", v.target.value)}
        />
      </Form.Item>
      <Form.Item label="Contraseña" style={{ width: "50%" }}>
        <Input
          value={authData?.object?.password}
          onChange={(v) => onChange("password", v.target.value)}
        />
      </Form.Item>
    </span>
  );
};
