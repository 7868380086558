import { FiShoppingCart } from "react-icons/fi";
import { CatalogContent } from "./CatalogContent";

export const Catalog = () => {
  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <FiShoppingCart size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">
                Productos y servicios
              </span>
            </span>
            <p className="header-subtitle">
              Gestione sus productos y servicios, podrá generar órdenes de
              compra
            </p>
          </span>
        </span>
      </div>

      <CatalogContent />
    </div>
  );
};
