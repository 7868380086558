import { EnviosLogs } from "../ui/Layout/enviosMasivos/enviosLogs/EnviosLogs";
import MainLayout from "../ui/Layout/MainLayout";

export const EnviosLogsView = () => {
  return (
    <MainLayout>
      <EnviosLogs />
    </MainLayout>
  );
};
