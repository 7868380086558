import { Input } from "antd";

export const HeaderForm = ({ nombreNodo, setNombreNodo, icon }) => {
  return (
    <span className="horizontal" style={{ marginBottom: "16px", gap: "4px" }}>
      {icon}
      <Input
        variant="borderless"
        style={{ fontSize: "16px", border: "1px solid white" }}
        onChange={(v) => setNombreNodo(v.target.value)}
        value={nombreNodo}
      />
    </span>
  );
};
