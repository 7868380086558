import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Image, Spin } from 'antd';
import { FiEdit3 } from 'react-icons/fi';
import { EditarPerfilNegocio } from './EditarPerfilNegocio';
import { useAccess } from '../../../../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../../../../helpers/access';
import { getProfile } from '../../../../../../../helpers/fetchData/fetchProfile';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const PerfilNegocio = () => {
  const { application, whatsappProfile, instance } = useContext(GlobalContext);

  const [drawerEditarPerfil, setDrawerEditarPerfil] = useState(false);
  const [picture, setPicture] = useState('');
  const [loadingPicture, setLoadingPicture] = useState(false);

  const isAllowed = useAccess();

  useEffect(() => {
    const getPicture = async () => {
      try {
        setLoadingPicture(true);
        const perfil = await getProfile(
          application[0],
          instance?.userData?.apiKey
        );

        let picture;
        if (perfil?.status === 200) {
          picture = perfil?.data?.profile_picture_url;
        }

        return picture;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setLoadingPicture(false);
      }
    };

    const fetchData = async () => {
      try {
        const pictureUrl = await getPicture();

        setPicture(pictureUrl);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [application, instance, whatsappProfile]);

  return (
    <div className='lines-list-wrapper'>
      {application?.[0]?.lines &&
        whatsappProfile?.[0]?.verified_name &&
        application[0].lines.map((linea) => {
          return (
            <div className='card-linea-wrapper' key={linea.phoneNumberId}>
              <div className='card-wapp-header'>
                {loadingPicture ? (
                  <Spin
                    style={{
                      height: 60,
                      width: 60,
                      display: 'grid',
                      placeItems: 'center'
                    }}
                    indicator={<LoadingIcon size='large' />}
                  >
                    <Image
                      width={60}
                      src={'/assets/image-placeholder.png'}
                      preview={false}
                    />
                  </Spin>
                ) : (
                  <Image
                    width={60}
                    src={picture || '/assets/image-placeholder.png'}
                    preview={false}
                  />
                )}

                <div className='card-wapp-description'>
                  <span className='nombre-estado-wrapper'>
                    <p>
                      {whatsappProfile[0].verified_name &&
                        whatsappProfile[0].verified_name}
                    </p>
                    {/* {whatsappProfile[0].code_verification_status && (
                      <MdVerified size={16} color='var(--primary-color)' />
                    )} */}
                    {isAllowed(accessDataFormat('channels', 'manage')) && (
                      <FiEdit3
                        onClick={() => setDrawerEditarPerfil(true)}
                        size={16}
                        style={{ marginLeft: '128px', cursor: 'pointer' }}
                      />
                    )}
                  </span>
                  <span className='scope-tag2'>
                    {whatsappProfile[0].display_phone_number &&
                      whatsappProfile[0].display_phone_number}
                  </span>
                  <span className='scope-tag2'>
                    {whatsappProfile[0].address && whatsappProfile[0].address}
                  </span>
                  <span className='scope-tag2'>
                    {whatsappProfile[0].email && whatsappProfile[0].email}
                  </span>
                  <span className='scope-tag2'>
                    {whatsappProfile[0].websites?.length > 0 &&
                      whatsappProfile[0].websites[0]}
                  </span>
                </div>
              </div>

              <EditarPerfilNegocio
                drawerEditarPerfil={drawerEditarPerfil}
                setDrawerEditarPerfil={setDrawerEditarPerfil}
              />
            </div>
          );
        })}
    </div>
  );
};
