import { MetaBusinessInfo } from './MetaBusinessInfo';
import { AppInfo } from './AppInfo';

export const AppCard = ({ businessCompliance, loading }) => {
  return (
    <span
      className='horizontal fade-in'
      style={{
        marginLeft: '36px',
        marginBottom: '16px',
        gap: 0
      }}
    >
      <span className='channel-wrapper'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
            height: '100%'
          }}
        >
          <AppInfo businessCompliance={businessCompliance} loading={loading} />
          <MetaBusinessInfo
            businessCompliance={businessCompliance}
            loadingBusinessCompliance={loading}
          />
        </div>
      </span>
    </span>
  );
};
