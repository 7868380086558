import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa6';

export const AudioPreview = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    // Cuando el audio termina, cambia el estado para mostrar el ícono de play:
    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    audioElement.addEventListener('ended', handleAudioEnded);

    // Limpia el evento cuando el componente se desmonta:
    return () => {
      audioElement.removeEventListener('ended', handleAudioEnded);
    };
  }, []);

  return (
    <>
      <audio ref={audioRef} src={src}></audio>

      <Button
        icon={isPlaying ? <FaPause /> : <FaPlay />}
        onClick={togglePlayPause}
        shape='circle'
      ></Button>
    </>
  );
};
