import { Button, Form, Input, Spin, Transfer } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  createTeam,
  manageTeam,
} from "../../../../../../helpers/fetchData/fetchTeams";
import { LoadingOutlined } from "@ant-design/icons";
import { getInvitationsByInstance } from "../../../../../../helpers/fetchData/fetchInvitation";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const NewTeam = ({ showDrawer, setShowDrawer, setTeams }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [usuarios, setUsuarios] = useState([]);

  const [loadingUsers, setLoadingUsers] = useState(true);

  const [loading, setLoading] = useState(false);

  const [targetKeys, setTargetKeys] = useState([]);

  const getOptions = () => {
    let array = [];

    if (usuarios?.length > 0) {
      array = usuarios
        .filter((user) => {
          // Si es modo editar y tiene asignado este team.
          if (showDrawer?.edit && showDrawer?.item?._id === user?.team?._id) {
            return true;
          }

          // En otro caso si tiene team no permitir agregar a otro
          if (user?.team?._id) {
            return false;
          }

          return true;
        })
        ?.map((user) => {
          return {
            id: user._id,
            title: user.name,
          };
        });
    }

    return array;
  };

  const filterOption = (inputValue, option) => {
    let inputLowerCase = inputValue.toLowerCase();

    if (option.title.toLowerCase().indexOf(inputLowerCase) > -1) {
      return true;
    }

    return false;
  };

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const onFinish = (v) => {
    v.name = v.name.trim();

    if (!v.name) {
      return messageApi.info("Por favor, ingrese un nombre");
    }

    setLoading(true);

    if (!showDrawer.edit) {
      const data = {
        name: v.name,
        description: v.description || "",
        users: targetKeys,
      };

      createTeam(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.newTeam?.insertedId) {
              messageApi.success("Equipo creado correctamente");
              setTeams((prevState) => {
                let array = [...prevState];

                array.push({ ...data, _id: res?.data?.newTeam?.insertedId });

                return array;
              });

              setShowDrawer({
                open: false,
                item: null,
              });
            } else {
              messageApi.error("Ocurrió un error al crear un equipo");
            }
          } else {
            messageApi.error("Ocurrió un error al crear un equipo");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al crear un equipo")
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data = {
        team: {
          name: v.name,
          description: v.description || "",
          id: showDrawer.item?._id,
        },
        users: targetKeys,
      };
      manageTeam(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Equipo creado correctamente");
            setTeams((prevState) => {
              let array = [...prevState];

              let index = array.findIndex(
                (element) => element._id === showDrawer?.item?._id
              );

              if (index >= 0) {
                array[index] = { ...data.team, users: data.users };
              }

              return array;
            });

            setShowDrawer({
              open: false,
              item: null,
            });
          } else {
            messageApi.error("Ocurrió un error al crear un equipo");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al crear un equipo")
        )
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (instance?._id) {
      setLoadingUsers(true);
      getInvitationsByInstance(instance.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setUsuarios(res.data?.activeUsers || []);
          } else {
            messageApi.error("Ocurrió un error al obtener usuarios");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener usuarios")
        )
        .finally(() => setLoadingUsers(false));
    }
  }, [instance]);

  useEffect(() => {
    if (showDrawer?.edit && showDrawer.item) {
      const { item } = showDrawer;
      form.setFieldsValue({
        name: item.name,
        description: item.description,
      });

      setTargetKeys(item.users || []);
    }
  }, [showDrawer]);

  return (
    <Form
      form={form}
      name="newProduct"
      layout="vertical"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <b style={{ marginBottom: 16 }}>
        {showDrawer?.edit ? "Editar grupo" : "Nuevo grupo"}
      </b>
      <Form.Item
        name="name"
        label="Nombre"
        rules={[{ required: true, message: "Ingrese un nombre" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Descripción">
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      {loadingUsers ? (
        <Spin
          style={{ width: "100%", marginInline: "auto", marginTop: 100 }}
          indicator={
            <LoadingIcon size="large" />
          }
        />
      ) : (
        <Form.Item label="Miembros">
          <Transfer
            style={{ width: "100%" }}
            dataSource={getOptions() || []}
            showSearch
            filterOption={filterOption}
            onChange={handleChange}
            targetKeys={targetKeys}
            rowKey={(record) => record.id}
            listStyle={{
              width: "calc(50% - 20px)",
              height: "calc(100vh - 300px)",
              minHeight: "300px",
              overflowY: "auto",
            }}
            locale={{
              itemUnit: "",
              itemsUnit: "",
              searchPlaceholder: "Buscar",
            }}
            titles={["Usuarios", "Seleccionados"]}
            render={(item) => item.title}
          />
        </Form.Item>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setShowDrawer({
                open: false,
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
