import { getLastNode } from "./handleVariablesDisponibles";

export const handleVariablesIndexadas = (
  flowVariables,
  nodeId,
  edges,
  variableName
) => {
  let array = [...flowVariables];

  let nodosAnteriores = getLastNode(edges, nodeId);

  array = flowVariables.filter((element) => {
    let flag = false;
    if (element.kind) {
      if (
        element.kind === "indexedVars" &&
        nodosAnteriores.has(element.idNodo)
      ) {
        flag = true;
      }
    }

    return flag;
  });

  variableName =
    "index_" + variableName?.substring(variableName?.indexOf("_") + 1);

  array = array.map((element) => {
    let name = element.name;

    name = name.replace(/\[idx\]/g, `[${variableName}]`);

    element = { ...element, name: name };

    return element;
  });

  const removeDuplicates = (array) => {
    const uniqueMap = new Map();
    array.forEach((element) => {
      uniqueMap.set(element.name, element);
    });

    return Array.from(uniqueMap.values());
  };

  return { indexedVars: removeDuplicates(array) };
};
