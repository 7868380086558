import { Button, Drawer, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import {
  handleDisableList,
  handleItemCount,
  handleItemValue,
  handleListCount,
  handleListName,
  handleListValue,
} from "../../../../../helpers/utilidadesTemplates";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { updateNode } from "../utils/updateNode";
import { getVariableName } from "../utils/getVariableName";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useUpdateNodeInternals } from "reactflow";
import { MediaForm } from "./MediaForm";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { HeaderForm } from "./componentsForm/HeaderForm";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { LineaGris } from "../../../linea-gris/LineaGris";
import { FiX } from "react-icons/fi";
import { validateListContent } from "../utils/validateListContent";

export const ListForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [preview, setPreview] = useState("");
  const [listContent, setListContent] = useState({
    button: "",
    sections: [{ title: "", rows: [] }],
  });
  const [disableButton, setDisableButton] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [url, setURL] = useState("");
  const [location, setLocation] = useState({});
  const [caption, setCaption] = useState("");
  const [mostrarDrawerMedia, setMostrarDrawerMedia] = useState(false);

  const updateNodeInternals = useUpdateNodeInternals();

  const handleAuto = () => {
    return (
      <div className="form-item-column">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <Form.Item
            label="Nombre de la variable"
            name="variableName"
            initialValue={nodoSeleccionado?.data?.variableName}
            style={{ width: "48%" }}
          >
            <Input addonBefore="flow_" placeholder="response" />
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="variableType"
            initialValue="number"
            style={{ width: "48%" }}
          >
            <Select options={[{ value: "number", label: "Number" }]} />
          </Form.Item>
        </span>
        <BoxInfo
          message={[
            `En caso de no configurar el nombre de una variable se tomará por
            defecto "flow_response".`,
            `La respuesta se tomará de acuerdo a la posición (Number) en la
            lista. Empezando por 1.`,
          ]}
        />
      </div>
    );
  };

  const onFinish = (values) => {
    if (!bodyText) {
      return messageApi.info("Es necesario que ingrese cuerpo del mensaje");
    }
    values.label = nombreNodo;
    values.auto = false;
    values.headerType = messageType;

    values.headerText = headerText;
    values.bodyText = bodyText;
    values.footerText = footerText;

    values.variable = {};

    const error = validateListContent(listContent, messageApi);
    if (error) {
      return;
    }
    
    let rowsCount = 0;

    listContent?.sections?.forEach((section) => {
      rowsCount += section.rows?.length;
    });

    values.handleCount = rowsCount;

    if (!values.variableName) {
      values.variable.name = "flow_response";
      values.variable.type = "number";
    } else {
      values.handleCount = 1;
      values.variable.name = getVariableName(
        values.variableName,
        values.variableType
      );
      values.variable.type = values.variableType;
      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        values.variable,
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;
    }

    if (messageType === "media") {
      values.headerMediaType = headerMediaType;

      if (headerMediaType === "location") {
        if (!location.latitude || !location.longitude) {
          return messageApi.info("Latitud y longitud son campos obligatorios");
        }

        values.location = location;
      } else {
        if (url) {
          values.url = url;
        } else {
          return messageApi.info("Ingrese una url");
        }

        if (caption) {
          values.caption = caption;
          values.bodyText = "";
        }
      }
    } else {
      values.caption = null;
    }

    if (listContent) {
      values.listContent = listContent;

      let articlesCount = 0;

      if (listContent.sections.length > 10) {
        return messageApi.info(
          "La lista no puede contener mas de 10 secciones"
        );
      }

      listContent.sections.forEach(
        (section) => (articlesCount += section.rows.length)
      );

      if (articlesCount > 30) {
        return messageApi.info(
          "La lista no puede contener mas de 30 articulos en total"
        );
      }
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
    updateNodeInternals(nodoSeleccionado.id);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.headerText !== undefined) {
        setHeaderText(data.headerText);
      }

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }

      if (data.footerText !== undefined) {
        setFooterText(data.footerText);
      }

      if (data.listContent !== undefined) {
        const clone = structuredClone(data.listContent);

        setListContent(clone);
      }

      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      let mediaType = "image";

      if (data.headerMediaType || data.headerMediaImage) {
        mediaType = data.headerMediaType || data.headerMediaImage;
      }

      setHeaderMediaType(mediaType);

      setNombreNodo(data.label);

      if (data.location) {
        const clone = structuredClone(data.location);
        setLocation(clone);
      }

      if (data.url) {
        setURL(data.url);
      }

      if (data.caption) {
        setCaption(data.caption);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
        listName: data.listName,
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />

      <Form.Item label={<span className="label-secciones">ENCABEZADO</span>}>
        <HeaderTextOrMedia
          messageType={messageType}
          setMessageType={setMessageType}
          nodeId={nodoSeleccionado?.id}
          headerMediaType={headerMediaType}
          setHeaderMediaType={setHeaderMediaType}
          text={headerText}
          setText={setHeaderText}
          url={url}
          setURL={setURL}
          setPreview={setPreview}
          caption={caption}
          setCaption={setCaption}
          location={location}
          setLocation={setLocation}
          context="interactive-list"
        />
      </Form.Item>
      <LineaGris />
      <FormattedTextArea
        value={bodyText}
        setValue={setBodyText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textBody"
        label="CUERPO"
        boldLabel
        minRows={3}
      />
      <LineaGris />
      <FormattedTextArea
        value={footerText}
        setValue={setFooterText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textFooter"
        label="PIE DEL MENSAJE"
        boldLabel
        minRows={3}
        showDecorators={false}
      />
      <LineaGris />
      <Form.Item
        label={<span className="label-secciones">NOMBRE DE LA LISTA</span>}
      >
        <Input
          value={listContent?.button}
          maxLength={20}
          showCount
          onChange={(v) =>
            setListContent(handleListName(listContent, v.target.value))
          }
        />
      </Form.Item>
      <div style={{ marginBottom: "16px" }}>
        {listContent?.sections?.map((element, index) => {
          return (
            <div className="template-crear-lista" key={index}>
              <span className="horizontal-list">
                <p>Nombre de la sección</p>
                <FiX
                  style={{ cursor: "pointer" }}
                  size={20}
                  onClick={() => {
                    setListContent(
                      handleListCount("resta", listContent, index)
                    );
                    setDisableButton(
                      handleDisableList("resta", listContent, index)
                    );
                  }}
                />
              </span>
              <Input
                style={{ maxWidth: "400px" }}
                onChange={(v) =>
                  setListContent(
                    handleListValue(listContent, v.target.value, index)
                  )
                }
                value={listContent.sections[index].title}
                showCount
                maxLength={24}
              />
              {listContent.sections[index].rows?.length > 0 && (
                <div className="lista-item">
                  {listContent.sections[index].rows?.map((element, row) => {
                    return (
                      <div className="item-wrapper" key={`${index}-${row}`}>
                        <span className="horizontal-list">
                          <b>Nombre del item</b>
                          <FiX
                            style={{ cursor: "pointer" }}
                            size={20}
                            onClick={() => {
                              setListContent(
                                handleItemCount(
                                  "resta",
                                  listContent,
                                  index,
                                  row
                                )
                              );
                              setDisableButton(
                                handleDisableList("resta", listContent, index)
                              );
                            }}
                          />
                        </span>
                        <Input
                          style={{ maxWidth: "400px" }}
                          onChange={(v) =>
                            setListContent(
                              handleItemValue(
                                listContent,
                                v.target.value,
                                index,
                                row,
                                "title"
                              )
                            )
                          }
                          value={listContent.sections[index]?.rows[row]?.title}
                          showCount
                          maxLength={24}
                        />
                        <span>
                          <b>Descripción</b>
                          <Input
                            style={{ maxWidth: "400px" }}
                            onChange={(v) =>
                              setListContent(
                                handleItemValue(
                                  listContent,
                                  v.target.value,
                                  index,
                                  row,
                                  "description"
                                )
                              )
                            }
                            value={
                              listContent.sections[index]?.rows[row]
                                ?.description
                            }
                            showCount
                            maxLength={72}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
              <span>
                <Button
                  style={{ marginLeft: "auto" }}
                  className="btn-agregar btn-oscuro"
                  type="primary"
                  size="small"
                  onClick={() => {
                    setListContent(handleItemCount("suma", listContent, index));
                  }}
                  disabled={disableButton}
                >
                  Agregar item
                </Button>
              </span>
            </div>
          );
        })}

        <Button
          className="btn-agregar"
          type="primary"
          onClick={() => {
            setListContent(handleListCount("suma", listContent));
            setDisableButton(handleDisableList("suma", listContent));
          }}
          disabled={disableButton}
        >
          Agregar otra sección
        </Button>
      </div>
      <LineaGris />
      {handleAuto()}
      <Drawer
        open={mostrarDrawerMedia}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <MediaForm
          url={url}
          setURL={setURL}
          setMostrarDrawerMedia={setMostrarDrawerMedia}
          mediaType={headerMediaType}
        />
      </Drawer>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
