import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Button, Spin } from 'antd';
import { SiOpenai } from 'react-icons/si';
import { getOpenAiKey } from '../../../../../../../helpers/fetchData/fetchOpenAI';
import { FiCopy, FiEdit3 } from 'react-icons/fi';
import { EditarOpenAi } from './EditarApiKey';
import { LoadingOutlined } from '@ant-design/icons';
import { EliminarApiKey } from './EliminarApiKey';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const OpenAi = ({ setSelectedIntegration }) => {
  const [openAiKey, setOpenAiKey] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [loadingOpenAiApiKey, setLoadingOpenAiApiKey] = useState(false);

  const { instance, messageApi } = useContext(GlobalContext);
  const apiKey = instance?.userData?.apiKey;

  useEffect(() => {
    setLoadingOpenAiApiKey(true);
    async function fetchOpenAiKey() {
      try {
        const openAiKey = await getOpenAiKey(apiKey);
        setOpenAiKey(openAiKey.data.apiKey);
      } catch (error) {
        console.error(error);
        messageApi.error('Error al cargar la API Key');
      } finally {
        setLoadingOpenAiApiKey(false);
      }
    }

    if (apiKey) {
      fetchOpenAiKey();
    }
  }, [apiKey]);

  return (
    <div>
      <span className='horizontal' style={{ justifyContent: 'space-between' }}>
        <span className='horizontal'>
          <p className='integration-title'>OpenAI</p>
          <SiOpenai size={24} />
        </span>
        <Button
          className='btn-cancelar'
          style={{ width: 'fit-content' }}
          onClick={() => setSelectedIntegration(null)}
        >
          Volver
        </Button>
      </span>

      {showEdit ? (
        <EditarOpenAi
          openAiKey={openAiKey}
          setOpenAiKey={setOpenAiKey}
          setShowEdit={setShowEdit}
        />
      ) : (
        <CardApiKey
          openAiKey={openAiKey}
          apiKey={apiKey}
          setShowEdit={setShowEdit}
          loadingOpenAiApiKey={loadingOpenAiApiKey}
          setOpenAiKey={setOpenAiKey}
        />
      )}
    </div>
  );
};

const CardApiKey = ({
  openAiKey,
  apiKey,
  setShowEdit,
  loadingOpenAiApiKey,
  setOpenAiKey
}) => {
  const { messageApi } = useContext(GlobalContext);

  function copyApiKey() {
    if (openAiKey) {
      navigator.clipboard.writeText(openAiKey);
      messageApi.success('API Key copiada');
    } else {
      messageApi.error('No hay ninguna API Key vinculada');
    }
  }

  return (
    <Spin
      style={{ width: 650 }}
      size='large'
      indicator={<LoadingIcon size="large" />}
      spinning={loadingOpenAiApiKey}
    >
      <div
        className='card-config'
        style={{ width: 650, marginTop: 20, padding: 20 }}
      >
        <div className='apikey-content'>
          <span className='apikey-name card-config-description'>
            <b>API Key</b>
            <span className='instance-id scope-tag3'>
              {openAiKey ? openAiKey : 'Ninguna API Key vinculada'}
            </span>
          </span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              width: 70
            }}
          >
            <FiCopy
              style={{ cursor: 'pointer' }}
              size={18}
              onClick={copyApiKey}
            />
            <FiEdit3
              style={{ cursor: 'pointer' }}
              size={18}
              onClick={() => setShowEdit(true)}
            />
            <EliminarApiKey apiKey={apiKey} setOpenAiKey={setOpenAiKey} />
          </span>
        </div>
      </div>
    </Spin>
  );
};
