import { useContext, useState } from 'react';
import { Tag } from '../../../../tag/Tag';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { Button, Col, Drawer, Form, Input, Popover, Row } from 'antd';
import {
  deleteClosingType,
  editClosingType
} from '../../../../../../helpers/fetchData/fetchClosingTypes';
import {
  editTicketType,
  deleteTicketType
} from '../../../../../../helpers/fetchData/fetchTickets';
import { FiEdit3 } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import LoadingIcon from '../../../../Loader/LoadingIcon';

const AccionesTickets = ({ item, setTiposTicket }) => {
  const [showDrawerEditar, setShowDrawerEditar] = useState(false);

  function handleClickEdit() {
    setShowDrawerEditar(true);
  }

  return item.name !== 'CIERRE POR INACTIVIDAD' ? (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
      <span style={{ height: 18 }}>
        <FiEdit3 className='icono-acciones' onClick={handleClickEdit} />
      </span>

      <PopoverEliminarTipoTicket item={item} setTiposTicket={setTiposTicket} />

      <DrawerEditarTipoTicket
        item={item}
        setTiposTicket={setTiposTicket}
        showDrawerEditar={showDrawerEditar}
        setShowDrawerEditar={setShowDrawerEditar}
      />
    </div>
  ) : null;
};

const PopoverEliminarTipoTicket = ({ item, setTiposTicket }) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [showPopoverEliminar, setShowPopoverEliminar] = useState(false);

  function deleteTipoTicket() {
    deleteTicketType(instance?.userData?.apiKey, item._id)
      .then((res) => {
        if (res?.status === 200) {
          setTiposTicket((prevState) => {
            let newArray = prevState.filter((i) => i !== item);

            return newArray;
          });

          messageApi.success('Tipo de ticket eliminado');
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error('Error al eliminar el tipo de ticket');
      });
  }

  return (
    <Popover
      open={showPopoverEliminar}
      content={
        <span className='columna' style={{ maxWidth: 280 }}>
          <p>{`¿Está seguro de eliminar ${item.name}?`}</p>
          <span className='horizontal'>
            <Button
              type='primary'
              className='btn-borrar'
              onClick={() => deleteTipoTicket()}
            >
              Confirmar
            </Button>
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={() => setShowPopoverEliminar(false)}
            >
              Cancelar
            </Button>
          </span>
        </span>
      }
    >
      <span style={{ height: 18 }}>
        <TbTrash
          className='icono-acciones'
          onClick={() => setShowPopoverEliminar(true)}
        />
      </span>
    </Popover>
  );
};

const DrawerEditarTipoTicket = ({
  item,
  setTiposTicket,
  showDrawerEditar,
  setShowDrawerEditar
}) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { TextArea } = Input;

  const [loadingEditar, setLoadingEditar] = useState(false);

  const [formEditarTipoTicket] = Form.useForm();

  function onFinish(form) {
    setLoadingEditar(true);

    const data = {
      _id: item._id,
      name: form.name,
      color: form.color,
      description: form.description
    };

    editTicketType(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          setTiposTicket((prevState) => {
            let newArray = [...prevState];

            const index = newArray.findIndex((i) => i._id === item._id);
            newArray[index] = { ...newArray[index], ...form };

            return newArray;
          });

          messageApi.success('Tipo de ticket modificado');
          onClose();
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error('Error al modificar el tipo de ticket');
      })
      .finally(() => setLoadingEditar(false));
  }

  function onClose() {
    setShowDrawerEditar(false);
  }

  return (
    <Drawer
      title='Editar tipo de ticket'
      width={500}
      placement='right'
      styles={{ header: { display: 'none' }, body: { padding: '0px' } }}
      open={showDrawerEditar}
      onClose={onClose}
      destroyOnClose
    >
      <Form
        className='form-nodos'
        name='editarTipoTicket'
        form={formEditarTipoTicket}
        requiredMark='optional'
        layout='vertical'
        onFinish={onFinish}
        style={{ padding: 24 }}
        preserve={false}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Editar tipo de ticket
        </p>

        <Row style={{ justifyContent: 'space-between' }}>
          <Col span={16}>
            <Form.Item
              label='Nombre'
              name='name'
              initialValue={item.name}
              rules={[
                {
                  required: true,
                  message: 'Campo requerido'
                }
              ]}
            >
              <Input
                size='large'
                maxLength={25}
                showCount
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label='Color'
              name='color'
              initialValue={item.color}
              rules={[
                {
                  required: true,
                  message: 'Campo requerido'
                }
              ]}
            >
              <Input type='color' size='large' />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Descripción'
          name='description'
          initialValue={item.description}
        >
          <TextArea
            size='large'
            autoSize={{ minRows: 4 }}
            maxLength={150}
            showCount
          />
        </Form.Item>

        <Form.Item className='form-custom-footer'>
          <div className='botones-wrapper-content'>
            <Button
              type='primary'
              htmlType='submit'
              className='btn-guardar'
              size='large'
              //loading={loadingEditar}
              icon={
                loadingEditar ? (
                  <LoadingIcon size='small' color='#FFFFFF' />
                ) : null
              }
              style={{ opacity: loadingEditar ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              size='large'
              type='secondary'
              onClick={onClose}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export const columnasTiposTickets = (setTiposTicket) => [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 225,
    render: (dataIndex, item) => {
      return (
        <div className='tag-wrapper'>
          <Tag hex={item.color} nombre={item.name} />
        </div>
      );
    }
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    width: 400
  },
  {
    title: '...',
    dataIndex: '',
    key: '',
    align: 'center',
    width: 80,
    render: (dataIndex, item) => {
      return <AccionesTickets item={item} setTiposTicket={setTiposTicket} />;
    }
  }
];

const AccionesClosingTypes = ({ item, setClosingTypes, setDrawerData }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);
  const [loadingBorrar, setLoadingBorrar] = useState(false);

  const handleDelete = () => {
    setLoadingBorrar(true);
    deleteClosingType(instance?.userData?.apiKey, item._id)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success('Tipo de cierre borrado correctamente');
          setClosingTypes((prevState) =>
            prevState.filter((element) => element._id !== item._id)
          );
        } else {
          messageApi.error('Ocurrió un error al borrar un tipo de cierre');
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('Ocurrió un error al borrar un tipo de cierre');
      })
      .finally(() => setLoadingBorrar(false));
  };

  return (
    <div className='horizontal'>
      <span style={{ height: 18 }}>
        <FiEdit3
          className='icono-acciones'
          onClick={() => setDrawerData({ open: true, item: item })}
        />
      </span>
      <Popover
        open={showPopover}
        content={
          <span className='columna' style={{ maxWidth: 280 }}>
            <p>{`¿Está seguro de eliminar ${item.name}?`}</p>
            <span className='horizontal'>
              <Button
                type='primary'
                className='btn-borrar'
                onClick={handleDelete}
                //loading={loadingBorrar}
                icon={
                  loadingBorrar ? (
                    <LoadingIcon size='small' color='#FFFFFF' />
                  ) : null
                }
                style={{ opacity: loadingBorrar ? 0.65 : 1 }}
              >
                Confirmar
              </Button>
              <Button
                type='secondary'
                className='btn-cancelar'
                onClick={() => setShowPopover(false)}
              >
                Cancelar
              </Button>
            </span>
          </span>
        }
      >
        <span style={{ height: 18 }}>
          <TbTrash
            className='icono-acciones'
            onClick={() => setShowPopover(true)}
          />
        </span>
      </Popover>
    </div>
  );
};

export const closingTypesColumns = (setClosingTypes, setDrawerData) => [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 225,
    render: (dataIndex, item) => {
      return (
        <div className='tag-wrapper'>
          <Tag hex={item.color} nombre={item.name} />
        </div>
      );
    }
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    width: 400
  },
  {
    title: '...',
    dataIndex: '',
    key: '',
    align: 'center',
    width: 80,
    render: (dataIndex, item) => {
      return (
        <AccionesClosingTypes
          item={item}
          setClosingTypes={setClosingTypes}
          setDrawerData={setDrawerData}
        />
      );
    }
  }
];
