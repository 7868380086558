import { Button, Modal } from 'antd';
import { useState } from 'react';
import { FaVideo } from 'react-icons/fa6';

export const VideoPreview = ({ src }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        icon={<FaVideo />}
        onClick={() => setOpenModal(true)}
        shape='circle'
      ></Button>

      <Modal
        className='preview-modal'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <video
          style={{
            borderRadius: 4
          }}
          src={src}
          controls
        ></video>
      </Modal>
    </>
  );
};
