// Función que separa los campos adicionales del resto. Guarda los campos adicionales en la key 'additionalFields' y deja sueltos los demás datos.

export const filterAdditionalFields = (data, additionalFields) => {
  const result = { additionalFields: [] };

  // Convertir 'additionalFields' en un objeto para facilitar la búsqueda. Las keys son los IDs de los campos adicionales y los valores son toda la información de cada uno:
  const additionalFieldMap = additionalFields.reduce((map, field) => {
    map[field._id] = field;
    return map;
  }, {});

  // Recorrer las keys de 'data' para filtrar los campos adicionales:
  Object.keys(data).forEach((key) => {
    if (additionalFieldMap[key]) {
      // Si es un campo se añade al array un objeto con el ID y el valor asignado en el formulario:
      result.additionalFields.push({ _id: key, value: data[key] });
    } else {
      // Si no es un campo, se agrega directamente al resultado:
      result[key] = data[key];
    }
  });

  return result;
};
