export const handleAnimatedEdges = (nodoSeleccionado, setEdges) => {
  if (nodoSeleccionado?.id) {
    setEdges((prevState) => {
      let array = prevState.map((edge) => {
        if (edge.id.includes("fallback")) {
          return edge;
        }

        if (
          edge.source === nodoSeleccionado.id ||
          edge.target === nodoSeleccionado.id
        ) {
          return { ...edge, animated: true };
        } else {
          return { ...edge, animated: false };
        }
      });

      return array;
    });
  } else {
    setEdges((prevState) => {
      let array = prevState.map((edge) => {
        if (edge.id.includes("fallback")) {
          return edge;
        } else {
          return { ...edge, animated: false };
        }
      });

      return array;
    });
  }
};
