import { Drawer, Table } from "antd";
import { contactsLeadsColumns } from "./columns";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { getContactsLeads } from "../../../../../helpers/fetchData/fetchContactsLeads";
import { ContactEditor } from "../../../drawers/ContactEditor";

export const TablaContactsLeads = ({
  leads,
  setLeads,
  filteredLeads,
  setContactos,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [drawerConfig, setDrawerConfig] = useState({
    visible: false,
    content: "",
  });

  const handleDrawerContent = () => {
    switch (drawerConfig?.content) {
      case "Agregar lead a contacto":
        return (
          <ContactEditor
            drawerConfig={drawerConfig}
            setDrawerConfig={setDrawerConfig}
            setLeads={setLeads}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getContactsLeads(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setLeads(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener leads");
          }
        })
        .catch((error) => {
          console.log("error", error);
          messageApi.error("Ocurrió un error al obte  ner leads");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  return (
    <>
      <Table
        columns={contactsLeadsColumns(setContactos, setDrawerConfig, setLeads)}
        dataSource={filteredLeads || []}
        rowKey="_id"
        size="small"
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} leads`,
        }}
        // className="tabla-generica"
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size="large" />,
        }}
      />
      <Drawer
        width={500}
        destroyOnClose
        maskClosable={true}
        placement={"right"}
        closable={true}
        closeIcon={null}
        onClose={() => setDrawerConfig({ visible: false, content: "" })}
        open={drawerConfig?.visible}
        key={"leadsDrawer"}
        styles={{ header: { display: "none" }, body: { padding: "0px" } }}
      >
        {handleDrawerContent()}
      </Drawer>
    </>
  );
};
