import { Table } from "antd";
import { columnasEnvios } from "../../enviosMasivos/enviosBuilder/utils/columns";
import { useNavigate } from "react-router-dom";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { useState } from "react";

const PAGE_SIZE = 8;

export const EnviosMasivosTab = ({
  filteredEnviosMasivos,
  setEnviosMasivos,
  setDrawerData,
  setDrawerReturn,
  loading,
}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <Table
      columns={columnasEnvios(
        navigate,
        setEnviosMasivos,
        setDrawerData,
        setDrawerReturn
      )}
      dataSource={filteredEnviosMasivos || []}
      rowKey={"_id"}
      size="small"
      pagination={{
        pageSize: PAGE_SIZE,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} envíos masivos`,
      }}
      onChange={handleTableChange}
      className="tabla-generica"
      style={{ maxWidth: "100%" }}
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size="large" />,
      }}
    />
  );
};
