import { Checkbox, Col, Input, Row, Select, Space, Tooltip } from "antd";
import { FiInfo, FiX } from "react-icons/fi";
import {
  handleButtonsCount,
  handleNewButtonValues,
} from "../../../../../../helpers/utilidadesTemplates";

export const TemplateButton = ({ button, setButtonsContent, index }) => {
  const handleButtonType = () => {
    switch (button.type) {
      case "MARKETING":
        return (
          <div className="marketing-button-wrapper" key={index}>
            <div className="call-input-group">
              <span
                className="horizontal"
                style={{ width: "100%", marginBottom: 8 }}
              >
                <Space.Compact>
                  <Row gutter={8} style={{ width: "100%", margin: 0 }}>
                    <Col style={{ width: "40%" }}>
                      <b className="template-button-title">Texto del botón</b>
                      <Input
                        value={button.text}
                        showCount
                        maxLength={25}
                        disabled={true}
                      />
                    </Col>
                    <Col style={{ width: "60%" }}>
                      <b className="template-button-title">
                        Texto de pie de página
                      </b>
                      <Input value={button.footer} disabled={true} />
                    </Col>
                  </Row>
                </Space.Compact>
              </span>
              <Checkbox
                checked={button.checked}
                onChange={(v) =>
                  handleNewButtonValues(
                    setButtonsContent,
                    index,
                    "checked",
                    v.target.checked
                  )
                }
              >
                Entiendo que es responsabilidad de "CUENTA WSP" dejar de enviar
                mensajes de marketing a los clientes que desactivan esa opción.
              </Checkbox>
            </div>
            <FiX
              style={{ cursor: "pointer" }}
              size={20}
              onClick={() => {
                setButtonsContent((prevState) =>
                  handleButtonsCount("resta", prevState, index)
                );
              }}
            />
          </div>
        );
      case "URL":
        return (
          <div className="horizontal" style={{ width: "100%" }}>
            <Space.Compact className="call-input-group">
              <Row gutter={8} style={{ width: "100%", margin: 0 }}>
                <Col style={{ width: "30%" }}>
                  <b className="template-button-title">Texto del botón</b>
                  <Input
                    maxLength={25}
                    showCount
                    value={button.text}
                    onChange={(v) => {
                      handleNewButtonValues(
                        setButtonsContent,
                        index,
                        "text",
                        v.target.value
                      );
                    }}
                  />
                </Col>
                <Col style={{ width: "20%" }}>
                  <div key="url">
                    <b className="template-button-title">Tipo de URL</b>
                    <Select
                      style={{ width: "100%" }}
                      value={button.urlType}
                      onChange={(v) => {
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "urlType",
                          v
                        );
                      }}
                    >
                      <Select.Option value="static">Estática</Select.Option>
                      <Select.Option value="dynamic">Dinámica</Select.Option>
                    </Select>
                  </div>
                </Col>
                <Col style={{ width: "50%" }}>
                  <b className="template-button-title">URL del sitio web</b>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      showCount
                      maxLength={2000}
                      onChange={(v) => {
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "url",
                          v.target.value
                        );
                      }}
                      value={button.url}
                    />
                    {button.urlType === "dynamic" && (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "4px",
                          color: "rgba(28,43,51,.65)",
                        }}
                      >
                        <p>{"{{1}}"}</p>
                        <Tooltip
                          color="white"
                          placement="right"
                          title={
                            <div className="columna-tooltip">
                              <span className="texto-tooltip">
                                Al agregar una variable, se crea un enlace
                                personalizado para que el cliente vea su
                                información. Solo se puede agregar una variable
                                al final de una URL.
                              </span>
                              <a
                                href="https://business.facebook.com/business/help/2055875911147364?id=2129163877102343"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Más Información
                              </a>
                            </div>
                          }
                        >
                          <span style={{ height: 22, padding: 4 }}>
                            <FiInfo />
                          </span>
                        </Tooltip>
                      </span>
                    )}
                  </span>
                </Col>
              </Row>
            </Space.Compact>
            <span>
              <FiX
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  setButtonsContent((prevState) =>
                    handleButtonsCount("resta", prevState, index)
                  );
                }}
              />
            </span>
          </div>
        );
      case "PHONE_NUMBER":
        return (
          <div className="horizontal" style={{ width: "100%" }}>
            <Space.Compact className="call-input-group">
              <Row gutter={8} style={{ width: "100%", margin: 0 }}>
                <Col style={{ width: "30%" }}>
                  <b className="template-button-title">Texto del botón</b>
                  <Input
                    maxLength={25}
                    showCount
                    value={button.text}
                    onChange={(v) => {
                      handleNewButtonValues(
                        setButtonsContent,
                        index,
                        "text",
                        v.target.value
                      );
                    }}
                  />
                </Col>
                <Col style={{ width: "20%" }}>
                  <div key="url">
                    <b className="template-button-title">País</b>
                    <Select
                      style={{ width: "100%" }}
                      value={button.country}
                      onChange={(v) => {
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "country",
                          v
                        );
                      }}
                      options={[
                        { value: "54", label: "AR +54" },
                        { value: "1", label: "US +1" },
                      ]}
                    />
                  </div>
                </Col>
                <Col style={{ width: "50%" }}>
                  <b className="template-button-title">Número de teléfono</b>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      showCount
                      maxLength={20}
                      onChange={(v) => {
                        let value = v.target.value;
                        value = v.target.value.replace(/[^0-9.]/g, "");
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "phone_number",
                          value
                        );
                      }}
                      value={button.phone_number}
                    />
                    {button.select === "dynamic" && (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "4px",
                          color: "rgba(28,43,51,.65)",
                        }}
                      >
                        <p>{"{{1}}"}</p>
                        <Tooltip
                          color="white"
                          placement="right"
                          title={
                            <div className="columna-tooltip">
                              <span className="texto-tooltip">
                                Al agregar una variable, se crea un enlace
                                personalizado para que el cliente vea su
                                información. Solo se puede agregar una variable
                                al final de una URL.
                              </span>
                              <a
                                href="https://business.facebook.com/business/help/2055875911147364?id=2129163877102343"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Más Información
                              </a>
                            </div>
                          }
                        >
                          <FiInfo style={{ padding: "4px" }} />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                </Col>
              </Row>
            </Space.Compact>
            <span>
              <FiX
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  setButtonsContent((prevState) =>
                    handleButtonsCount("resta", prevState, index)
                  );
                }}
              />
            </span>
          </div>
        );
      case "QUICK_REPLY":
        return (
          <div className="template-crear-boton" key={index}>
            <div className="horizontal">
              <span className="call-input-group">
                <Row style={{ width: "calc(100% - 8px)" }}>
                  <Col style={{ width: "100%" }}>
                    <b className="template-button-title">Texto del botón</b>
                    <Input
                      onChange={(v) =>
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "text",
                          v.target.value
                        )
                      }
                      value={button.text}
                      showCount
                      maxLength={25}
                    />
                  </Col>
                </Row>
              </span>
              <FiX
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  setButtonsContent((prevState) =>
                    handleButtonsCount("resta", prevState, index)
                  );
                }}
              />
            </div>
          </div>
        );
      case "COPY_CODE":
        return (
          <div className="template-crear-boton" key={index}>
            <div className="horizontal">
              <span className="call-input-group">
                <div
                  gutter={8}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <Col style={{ width: "40%" }}>
                    <b className="template-button-title">Texto del botón</b>
                    <Input
                      style={{ color: "#252525" }}
                      value={button.text}
                      disabled
                    />
                  </Col>
                  <Col style={{ width: "100%" }}>
                    <b className="template-button-title">
                      Agregar código de oferta de muestra
                    </b>
                    <p className="template-button-description">
                      Para ayudarnos a revisar tu plantilla de mensaje, agrega
                      un ejemplo para el código de oferta
                    </p>
                  </Col>
                  <Col style={{ width: "100%" }}>
                    <b className="template-button-title">Código de oferta</b>
                    <Input
                      onChange={(v) =>
                        handleNewButtonValues(
                          setButtonsContent,
                          index,
                          "example",
                          v.target.value
                        )
                      }
                      value={button.example}
                      showCount
                      maxLength={15}
                    />
                  </Col>
                </div>
              </span>
              <FiX
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  setButtonsContent((prevState) =>
                    handleButtonsCount("resta", prevState, index)
                  );
                }}
              />
            </div>
          </div>
        );
    }
  };

  return handleButtonType();
};
