import { Button, Form, Input, Radio, Upload } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { InboxOutlined } from "@ant-design/icons";
import { FILE_TYPES } from "../../../../helpers/fetchData/fetchMedia";
import { normFile } from "../flows/utils/cargaMedia";
import { META_API_VERSION } from "../../../../helpers/fetchData/fetchApplication";
import {
  newChatOK,
  updateConversations,
  updateLiveConversation,
} from "./utils/chatFunctions";
import { newChat } from "../../../../helpers/fetchData/fetchChats";

export const MediaPicker = ({
  selectedConversation,
  setShowModalMedia,
  liveConversation,
  socket,
  setConversations,
  setLiveConversation,
  selectedContact,
  setSelectedConversation,
  setActiveTabKey,
  setNotAssignedTickets,
  myTeams,
}) => {
  const { messageApi, instance, application } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [mediaType, setMediaType] = useState("image");

  const [loading, setLoading] = useState(false);

  const { Dragger } = Upload;

  const customRequest = (v) => {
    if (FILE_TYPES[mediaType].accept.includes(v.file.type)) {
      if (v.file.size < 1048576 * FILE_TYPES[mediaType].maxSize) {
        v.onSuccess();
      } else {
        messageApi.error(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
        v.onError(
          `El tamaño excede el limite ${FILE_TYPES[mediaType].maxSize}MB`
        );
      }
    } else {
      messageApi.error("El formato no es válido");
      v.onError("El formato no es válido");
    }
  };

  const sendNewAttachment = (id, data) => {
    if (socket.connected && selectedConversation?._id) {
      let message = {
        messaging_product: "whatsapp",
        type: mediaType,
        [mediaType]: {
          id: id,
        },
        recipient_type: "individual",
        timestamp: Date.now(),
        to: selectedConversation?.phoneNumber,
        chatId: selectedConversation?._id,
        userType: "agent",
        doNotReturn: true,
      };

      if (mediaType === "document" && data?.originalName) {
        message[mediaType].filename = data.originalName;
      }

      if (!["audio", "sticker"].includes(mediaType) && data?.caption) {
        message[mediaType].caption = data.caption;
      }

      socket.emit("sendMessage", { message });

      updateLiveConversation(message, setLiveConversation);
      updateConversations(message, setConversations, setNotAssignedTickets);
    } else if (!selectedConversation?._id && selectedContact?._id) {
      let message = {
        messaging_product: "whatsapp",
        type: mediaType,
        [mediaType]: {
          id: id,
        },
        recipient_type: "individual",
        timestamp: Date.now(),
        to: selectedConversation?.phoneNumber,
        userType: "agent",
        doNotReturn: true,
      };

      if (mediaType === "document" && data?.originalName) {
        message[mediaType].filename = data.originalName;
      }

      if (!["audio", "sticker"].includes(mediaType) && data?.caption) {
        message[mediaType].caption = data.caption;
      }

      let dataNewChat = {
        phoneNumber: selectedContact?.phoneNumber,
        templateData: null,
        textMessage: null,
        mediaMessage: message,
      };

      newChat(instance?.userData?.apiKey, dataNewChat, myTeams, messageApi)
        .then((res) => {
          if (res?.status === 200) {
            newChatOK(
              res,
              setSelectedConversation,
              setActiveTabKey,
              selectedContact,
              instance
            );

            updateConversations(
              message,
              setConversations,
              setNotAssignedTickets
            );
          }
        })
        .catch((err) => console.log(err));
    }

    setLoading(false);
    setShowModalMedia(false);
  };

  const onFinish = async (values) => {
    if (!values.file) {
      messageApi.error("Es necesario que seleccione un archivo");
      return;
    }

    if (!application[0]) {
      messageApi.error("No se encontró canal de whatsapp");
      return;
    }

    let myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${application?.[0]?.token}`);

    let formData = new FormData();
    formData.append("messaging_product", "whatsapp");
    formData.append(
      "file",
      values.file[0]?.originFileObj,
      values.file[0]?.name
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    setLoading(true);
    let idPhone = application[0]?.lines?.[0]?.phoneNumberId;

    //Es necesario ID en este caso. Para enviarlo en el momento.
    fetch(
      `https://graph.facebook.com/${META_API_VERSION}/${idPhone}/media`,
      requestOptions
    )
      .then((res) => res.text())
      .then((result) => {
        const id = JSON.parse(result)?.id;
        if (id) {
          if (application[0]?.cloudStorageEnabled) {
            // Tiene habilitado almacenamiento dedicado
            let myHeaders = new Headers();

            myHeaders.append("Authorization", instance?.userData?.apiKey);

            let formData = new FormData();
            formData.append(
              "file",
              values.file[0]?.originFileObj,
              values.file[0]?.name
            );

            formData.append("id", id);
            formData.append("mediaType", mediaType);
            formData.append("caption", values.caption);
            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formData,
              redirect: "follow",
            };

            // Guarda en base de datos.
            fetch(
              `${process.env.REACT_APP_API_PRODUCCION}/chatMedia`,
              requestOptions
            )
              .then((res) => res.json())
              .then((res) => {
                if (res?.status === 200) {
                  // Envia el mensaje por whatsapp
                  sendNewAttachment(id, res.data);
                } else {
                  messageApi.error("Ocurrió un error al guardar el archivo");
                }
              })
              .catch((error) => {
                console.log(error);
                messageApi.error("Ocurrió un error al guardar el archivo");
              });
          } else {
            // No tiene habilitado almacenamiento dedicado
            sendNewAttachment(id);
          }
        } else {
          messageApi.error("No se pudo subir el archivo a Meta");
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        messageApi.error("No se pudo subir el archivo a Meta");
      });
  };

  return (
    <div className="column" style={{ width: 400 }}>
      <Form
        form={form}
        layout="vertical"
        name="cargarMedia"
        // style={{ padding: "12px" }}
        requiredMark={false}
        onFinish={(v) => onFinish(v)}
        className="form-nodos"
      >
        <span className="form-title" style={{ marginBottom: 8 }}>
          Enviar archivo
        </span>
        <Form.Item>
          <Radio.Group
            onChange={(v) => {
              setMediaType(v.target.value);
            }}
            className="radio-media"
            style={{
              width: "100%",
            }}
            size="small"
            buttonStyle="solid"
            value={mediaType}
          >
            <Radio.Button value="image">Imagen</Radio.Button>
            <Radio.Button value="video">Video</Radio.Button>
            <Radio.Button value="document">Documento</Radio.Button>
            <Radio.Button value="audio">Audio</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="file"
          rules={[{ required: true, message: "Seleccione un archivo" }]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger
            name="file"
            customRequest={customRequest}
            maxCount={1}
            accept={FILE_TYPES[mediaType].accept}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Haga click o arrastre un archivo aquí
            </p>
            <p className="ant-upload-hint">{`Soporta archivos en formato ${FILE_TYPES[mediaType].formatos}.`}</p>
            <p className="ant-upload-hint">{`Con un máximo de ${FILE_TYPES[mediaType].maxSize}MB.`}</p>
          </Dragger>
        </Form.Item>
        {mediaType !== "audio" && (
          <Form.Item name="caption" label="Agrega un comentario (opcional)">
            <Input.TextArea />
          </Form.Item>
        )}
        <Form.Item style={{ marginBottom: "0px" }}>
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              loading={loading}
            >
              Enviar
            </Button>
            <Button
              className="btn-cancelar"
              type="secondary"
              onClick={() => {
                setLoading(false);
                setShowModalMedia(false);
              }}
            >
              Cerrar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
