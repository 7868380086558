import { Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import {
  getFlowLogs,
  getFlows,
} from "../../../../helpers/fetchData/fetchFlows";
import { columnsFlows } from "./utils/flowColums";
import LoadingIcon from "../../Loader/LoadingIcon";

const SIZE = 10;

export const FlowsMainTable = ({
  statusFiltro,
  setFlows,
  filteredFlows,
  loading,
  setLoading,
  qrCodes,
  setQrCodes,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);
  // const [flows, setFlows] = useState([]);
  // const [filteredFlows, setFilteredFlows] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [flowLogs, setFlowLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const getPagination = (array, name, size = 10) => {
    if (array?.length > 0) {
      return {
        current: currentPage,
        onChange: (page) => setCurrentPage(page),
        pageSize: size,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} ${name}`,
      };
    } else {
      return false;
    }
  };

  const getCurrentPageFlows = () => {
    const startIndex = (currentPage - 1) * SIZE;
    const endIndex = startIndex + SIZE;

    return filteredFlows.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingLogs(true);

      const currentIds = getCurrentPageFlows()?.map((flow) => flow._id);
      if (currentIds?.length > 0) {
        getFlowLogs(instance.userData.apiKey, { ids: currentIds })
          .then((res) => {
            if (res?.status === 200) {
              setFlowLogs(res?.data);
            }
          })
          .catch((err) => {
            console.error(err);
            messageApi.error("No se pudo obtener las métricas de los bots");
          })
          .finally(() => setLoadingLogs(false));
      }
    }
  }, [instance, filteredFlows, currentPage]);

  useEffect(() => {
    setLoading(true);
    if (instance?._id) {
      getFlows(setFlows, messageApi, setLoading, instance);
    }
  }, [instance]);

  // useEffect(() => {
  //   if (!loading) {
  //     setLoadingFilter(true);
  //     let flujos = flows;
  //     if (flows.length > 0) {
  //       if (statusFiltro !== "todos") {
  //         flujos = flujos.filter((flow) => flow.status === statusFiltro);
  //       }
  //     }

  //     setLoadingFilter(false);
  //   }
  // }, [flows, statusFiltro]);

  return (
    <Table
      columns={columnsFlows(
        navigate,
        setFlows,
        setLoading,
        qrCodes,
        setQrCodes,
        flowLogs,
        loadingLogs
      )}
      dataSource={filteredFlows}
      rowKey="_id"
      size="small"
      className="tabla-generica"
      pagination={getPagination(filteredFlows, "bots", SIZE)}
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size="large" />,
      }}
    />
  );
};
