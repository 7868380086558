import { Result } from "antd";
import React from "react";
import { IoLockClosedOutline } from "react-icons/io5";

export const NotMine = ({ selectedConversation }) => {
  return (
    <span className="chat-inner-input-wrapper">
      <Result
        icon={<IoLockClosedOutline size={24} />}
        style={{ padding: "0px" }}
        status="info"
        title={
          <p style={{ fontSize: "15px" }}>
            Conversación asignada a otro agente
          </p>
        }
        subTitle={
          <p style={{ fontSize: "13px" }}>
            Solo el agente asignado puede interactuar.
          </p>
        }
      />
    </span>
  );
};
