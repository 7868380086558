// useFetchCollection.js
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../components/context/GlobalContext";

const useFetchCollection = (collectionName, page, limit, search) => {
  const { instance } = useContext(GlobalContext);

  const [data, setData] = useState({ items: [], totalItems: 0, totalPages: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PRODUCCION}/fetchCollection/${collectionName}`,
          {
            params: { page, limit, search },
            headers: {
              Authorization: instance?.userData?.apiKey,
            },
          }
        );
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (instance?.userData?.apiKey) {
      fetchData();
    }
  }, [collectionName, page, limit, search, instance]);

  return { data, loading, error };
};

export default useFetchCollection;


