import { BrocolyAdmin } from "../ui/Layout/brocoly-admin/BrocolyAdmin";
import MainLayout from "../ui/Layout/MainLayout";

export const BrocolyAdminView = ({ children }) => {
  return (
    <MainLayout>
      <BrocolyAdmin>{children}</BrocolyAdmin>
    </MainLayout>
  );
};
