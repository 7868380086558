export function getLaboral(data) {
  const { company, title, department } = data;

  if (!company && !title && !department) {
    return null;
  }

  let jobDescription = [];

  if (title) {
    jobDescription.push(title);
  }

  if (department) {
    if (title) {
      jobDescription.push(` - ${department}`);
    } else {
      jobDescription.push(department);
    }
  }

  if (company) {
    if (jobDescription.length > 0) {
      jobDescription.push(<br key='line-break' />);
    }
    jobDescription.push(company);
  }

  return <span className='show-contact-job'>{jobDescription}</span>;
}
