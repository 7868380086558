import { Button, Form, Select } from 'antd';
import { useContext, useEffect, useMemo } from 'react';
import { updateNode } from '../../../flows/utils/updateNode';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { Tag } from '../../../../tag/Tag';
import { EnviosMasivosContext } from '../../../../../context/EnviosMasivosContext';

export const ButtonForm = ({ edit }) => {
  const { etiquetas, messageApi } = useContext(GlobalContext);
  const { setMostrarDrawer, nodoSeleccionado, setNodes, flows, loadingFlows } =
    useContext(EnviosMasivosContext);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (!values.flow && !values.tag) {
      messageApi.info('Debe seleccionar al menos un bot o etiqueta');
      return;
    }

    if (values.tag) {
      values.tag = [values.tag];
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));

    setMostrarDrawer(false);
  };

  const broadcastFlows = useMemo(() => {
    let array = flows?.filter((element) => {
      if (
        element.type === 'broadcast' &&
        element.status?.toLowerCase() === 'publish'
      ) {
        return true;
      } else {
        return false;
      }
    });

    return array.map((element) => {
      return {
        value: element._id,
        label: element.name
      };
    });
  }, [flows]);

  const tagsOptions = useMemo(() => {
    if (etiquetas?.length > 0) {
      return etiquetas.map((element) => {
        return {
          value: element._id,
          label: (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                position: 'relative'
              }}
            >
              <Tag hex={element.color} nombre={element.name} />
            </span>
          )
        };
      });
    }
  }, [etiquetas]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { flow, tag } = nodoSeleccionado.data;

      form.setFieldsValue({
        flow: flow || null,
        tag: tag || null
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <Form.Item label='Seleccione un bot' name='flow'>
        <Select options={broadcastFlows || []} allowClear />
      </Form.Item>
      <Form.Item label='Seleccione una etiqueta' name='tag'>
        <Select options={tagsOptions} size='large' allowClear />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          {!edit && (
            <Button
              type='primary'
              htmlType='submit'
              className='btn-guardar'
              size='large'
            >
              Guardar
            </Button>
          )}
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            block={edit}
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            {edit ? 'Cerrar' : 'Cancelar'}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
