import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { updateNode } from "../utils/updateNode";
import { getVariableName } from "../utils/getVariableName";
import { GlobalContext } from "../../../../context/GlobalContext";
import { manageWaitNode } from "../utils/manageWaitNode";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { LineaGris } from "../../../linea-gris/LineaGris";
import { SlLocationPin } from "react-icons/sl";


export const RequestLocationForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    mostrarDrawer,
    setMostrarDrawer,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [inputText, setInputText] = useState("");
  const [nombreNodo, setNombreNodo] = useState("");
  const [variableType, setVariableType] = useState("string");
  const [location, setLocation] = useState({});
  const [form] = Form.useForm();


  const handleAuto = () => {

      return (
        <div>
          <div className="form-item-column">
            <span
              className="horizontal"
              style={{ justifyContent: "space-between" }}
            >
              <Form.Item
                label="Nombre de la variable"
                name="variableName"
                initialValue={nodoSeleccionado?.data?.variableName}
                style={{ width: "63%" }}
              >
                <Input addonBefore={`${variableType}_`} placeholder={`001`} />
              </Form.Item>
              <Form.Item
                label="Tipo"
                name="variableType"
                initialValue={"geo"}
                style={{ width: "33%" }}
              >
                <Select
                  options={[                
                    { value: "geo", label: "Location" },
                  ]}
                  onChange={(v) => setVariableType(v)}
                  value={variableType}
                  disabled={true}
                />
              </Form.Item>
            </span>
            <BoxInfo
              message={[
                `Al tratarse de una ubicación, la variable siempre será de tipo Location.`
              ]}
            />
          </div>
        </div>
      );
  }; 

  const onFinish = (values) => {
    values.label = nombreNodo;
    values.auto = false;
    values.bodyText = inputText ? cleanLabel(inputText) : "";
    values.headerType = "text";
    values.caption = null;

    values.variable = {};

      if (!values.variableName) {
        return messageApi.error("Ingrese un nombre para la variable");
      }
      let variableName = getVariableName(values.variableName, 'geo');
      values.variableName = variableName;
      values.variable.name = variableName;
      values.variable.type = 'geo';

      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        values.variable,
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;

    values.handleCount = 1;

    manageWaitNode(nodes, setNodes, edges, setEdges, nodoSeleccionado, false);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (!mostrarDrawer) {
      setInputText("");
    }
  }, [mostrarDrawer]);


  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      setVariableType("geo");

      setInputText(data.bodyText);

      if (data.location) {
        setLocation(data.location);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName });
    }
  }, [nodoSeleccionado]);


  const cleanLabel = (text) =>
    text
      .replace(/\s+/g, ' ')
      .replace(/\n|\r|\t/g, '')
      .replace(/ {5,}/g, '    ')
      .trim();


  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <SlLocationPin style={{ color: "var(--dark-color)" }} size={32} />
        }
      />
      <HeaderTextOrMedia
        messageType={"text"}
        //setMessageType={setMessageType}
        nodeId={nodoSeleccionado?.id}
        //headerMediaType={headerMediaType}
        //setHeaderMediaType={setHeaderMediaType}
        text={inputText}
        setText={setInputText}
        //url={url}
        //setURL={setURL}
        //setPreview={setPreview}
        //caption={caption}
        //setCaption={setCaption}
        location={location}
        setLocation={setLocation}
        context="others"
      />
      <LineaGris />

      {handleAuto()}

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
              //setAuto(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
