import { getBezierPath } from "reactflow";
import "./customEdges.css";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { FiX } from "react-icons/fi";

const foreignObjectSize = 40;

export const ButtonEdge = ({
  id,
  source,
  target,
  style,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  selected,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { setEdges, nodoSeleccionado, nodes } = useContext(FlowContext);

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    setEdges((prevState) => prevState.filter((edge) => edge.id !== id));
  };

  const isDeletable = () => {
    if (selected) {
      // if (source === "0") {
      //   return false;
      // }

      let indice = nodes.filter((node) => node.id === target);

      if (indice.length > 0) {
        if (["saveResponse", "workingNode"].includes(indice[0].type)) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const isNodeSelected = () => {
    let flag = false;
    if (nodoSeleccionado) {
      let nodeId = nodoSeleccionado.id;
      if (nodeId) {
        if (
          (source === nodeId || target === nodeId) &&
          !id.includes("fallback")
        ) {
          flag = true;
        }
      }
    }

    return flag;
  };

  if (isNodeSelected()) {
    return (
      <>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path-selector"
          d={edgePath}
          markerEnd={markerEnd}
          fillRule="evenodd"
        />
        <path
          id={id}
          style={{ stroke: "var(--whatsapp-color)", animated: true }}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
          fillRule="evenodd"
        />
        {isDeletable() && (
          <foreignObject
            width={foreignObjectSize}
            height={foreignObjectSize}
            x={labelX - foreignObjectSize / 2}
            y={labelY - foreignObjectSize / 2}
            className="edgebutton-foreignobject"
          >
            <div>
              <button
                className="edgebutton"
                onClick={(event) => onEdgeClick(event, id)}
              >
                <FiX />
              </button>
            </div>
          </foreignObject>
        )}
      </>
    );
  }

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path-selector"
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        fillRule="evenodd"
      />
      {isDeletable() && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={labelX - foreignObjectSize / 2}
          y={labelY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
        >
          <div>
            <button
              className="edgebutton"
              onClick={(event) => onEdgeClick(event, id)}
            >
              <FiX />
            </button>
          </div>
        </foreignObject>
      )}
    </>
  );
};
