export function getTipoDireccion(tipo) {
  switch (tipo.toLowerCase()) {
    case 'home':
      return 'hogar';
    case 'work':
      return 'trabajo';
    default:
      break;
  }
}
