import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Checkbox, Image, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { getInvitationsByInstance } from "../../../helpers/fetchData/fetchInvitation";

export const Dashboard = () => {
  const { instance, application, messageApi, loadingApplication } =
    useContext(GlobalContext);

  const [showRequiredConfig, setShowRequiredConfig] = useState(false);

  const [usuarios, setUsuarios] = useState([]);

  const navigate = useNavigate();

  const closeModal = () => {
    setShowRequiredConfig(false);
  };

  useEffect(() => {
    if (instance?._id) {
      localStorage.setItem("workspace", instance?._id);
    }
  }, []);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getInvitationsByInstance(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            const { activeUsers } = res.data;
            setUsuarios(activeUsers || []);
          } else {
            messageApi.error("Ocurrió un error al obtener usuarios");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener usuarios")
        );
    }
  }, [instance]);

  useEffect(() => {
    if (instance?._id && !loadingApplication && application?.length === 0) {
      setShowRequiredConfig(true);
    }
  }, [application, loadingApplication, instance]);

  return (
    <div
      className="horizontal"
      style={{ width: "100%", height: "100%", justifyContent: "center" }}
    >
      <Modal open={showRequiredConfig} onCancel={closeModal} footer={null}>
        <div className="columna">
          <b style={{ fontSize: 20, marginBottom: 16, lineHeight: "20px" }}>
            Configuraciónes necesarias
          </b>
          <Checkbox checked={application?.length > 0}>
            <span className="horizontal">
              <p>Configure su cuenta de WhatsApp.</p>
              <a onClick={() => navigate("/settings/channels")}>Canales</a>
            </span>
          </Checkbox>
          <Checkbox checked={usuarios?.length > 0}>
            <span className="horizontal">
              <p>Agregue usuarios.</p>
              <a onClick={() => navigate("/settings/users")}>
                Usuarios y roles
              </a>
            </span>
          </Checkbox>
          <Checkbox checked={application?.length > 0}>
            <span className="horizontal">
              <p>Agregue equipos.</p>
              <a onClick={() => navigate("/settings/teams")}></a>
            </span>
          </Checkbox>
        </div>
      </Modal>

      <Image
        src="https://binamics.nyc3.digitaloceanspaces.com/logos/logo-brocoly-02.png"
        preview={false}
        width={200}
      />
    </div>
  );
};
