import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { isSelectedClass } from "../../../../../../helpers/isSelected";
import { FaRegFilePowerpoint } from "react-icons/fa6";
import { useContext, useEffect, useMemo, useState } from "react";
import { PreviewTemplate } from "../../../settings/content/templates/PreviewTemplate";
import { formatoTextHtml } from "../../../../../../helpers/utilidadesTemplates";
import { Statistic } from "antd";
import { getPorcentaje, innerStatsProps } from "../../utils";
import { EnviosMasivosContext } from "../../../../../context/EnviosMasivosContext";

export const TemplateNode = (node) => {
  const { setNodoSeleccionado, setMostrarDrawer, edges, nodes } =
    useContext(EnviosMasivosContext);

  const handleClick = () => {
    setNodoSeleccionado(node);
    setMostrarDrawer(true);
  };

  const updateNodeInternals = useUpdateNodeInternals();

  const [buttonsContent, setButtonsContent] = useState([]);

  useEffect(() => {
    // NECESARIO PARA QUE RECONOZCA LOS HANDLES CONDICIONALES.
    updateNodeInternals(node.id);
  }, [node]);

  useEffect(() => {
    if (node.data.templateVariables?.buttons?.value) {
      setButtonsContent(node.data.templateVariables.buttons.value);
    }
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => handleClick()}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header-send-template">
        <div>
          <FaRegFilePowerpoint size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">Seleccionar</span>
          <span className="mensaje-nodo">Plantilla</span>
        </div>
      </div>

      {node.data.templateVariables && (
        <span style={{ marginTop: 8 }}>
          <PreviewTemplate
            ambito="sendTemplate"
            // inputText={inputText}
            bodyContent={formatoTextHtml(
              node.data.templateVariables.body?.value
            )}
            buttonsContent={buttonsContent || []}
            footerContent={node.data.templateVariables.footer?.value}
            headerContent={node.data.templateVariables.header?.value}
            headerType={node.data.templateVariables?.header?.type}
            renderTemplateButtons={true}
            interactions={null}
            edges={edges}
            node={node}
          />
        </span>
      )}
    </div>
  );
};
