import { useContext, useEffect, useState } from "react";
import { Spin, Switch, Tooltip, Drawer } from 'antd';
import { FiPlay, FiSliders, FiUsers } from "react-icons/fi";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from '../../../../Loader/LoadingIcon';
import './client-config.css';
import { DrawerConfigClient } from "./DrawerConfigClient";
import { LoadNotification } from "../../content/catalog/budgetConfigSteps/LoadNotification";
import { executeExternalClientsLoad } from "../../../../../../helpers/fetchData/fetchClients";
import { getClientConfig, upsertClientConfig } from "../../../../../../helpers/fetchData/fetchClientConfig";
import { useNavigate } from "react-router-dom";
import { accessDataFormat } from "../../../../../../helpers/access";


const SIZE = 20;
const SETTED = "Configurado";
const NOT_SETTED = "No ha sido configurado aún";

export const ClientsConfig = ({ }) => {
  const { instance, messageApi, notificationApi } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const [loading, setLoading] = useState(true);
  const [loadingEjecutar, setLoadingEjecutar] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const navigate = useNavigate();

  const [clientsConfig, setClientsConfig] = useState(null);
  const [showClientConfig, setShowClientConfig] = useState(false);

  const [stage, setStage] = useState(0);

  const [mostrarRequestDrawer, setMostrarRequestDrawer] = useState(false);

  const [chkCron, setChkCron] = useState();


  const handleClientsLoad = () => {
    setLoadingEjecutar(true);
    executeExternalClientsLoad(instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          notificationApi.success({
            message: "Carga de clientes completada correctamente.",
            description: <LoadNotification data={res.data} />,
            placement: "bottomRight",
            duration: 10,
          });

        } else {
          messageApi.error(
            "Ocurrió un error al ejecutar la carga de clientes"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al ejecutar la carga de clientes");
      })
      .finally(() => setLoadingEjecutar(false));
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getClientConfig(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (res.data) {
              setClientsConfig(res.data);

              if (res.data?.clientsLoad) {
                const { cron } = res.data?.clientsLoad;

                if (cron) {
                  if (cron.enable) {
                    setChkCron(cron.enable);
                  } else {
                    setChkCron(false);
                  }
                }
              }
            }
          } else {
            messageApi.error(
              "Ocurrió un error al obtener configuracion de clientes"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error(
            "Ocurrió un error al obtener configuracion de clientes"
          );
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);


  const onChangeHttp = (e) => {
    setChkCron(e)

    let data = { ...clientsConfig };
    data.clientsLoad.cron.enable = e;
    setLoadingSwitch(true);
    upsertClientConfig(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Configuración guardada correctamente");
          setClientsConfig((prevState) => {
            let obj = { ...prevState };
            obj.clientsLoad = data.clientsLoad;
            return obj;
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoadingSwitch(false));
  };


  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <FiUsers size={24} color='var(--primary-color)' />
              </span>
              <span className="header-title-templates">Clientes</span>
            </span>
            <p className="header-subtitle">
              Configure cómo se cargarán los clientes en el sistema.
            </p>
          </span>
        </span>
      </div>

      {loading ? (
        <span
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Spin
            style={{
              marginTop: 100,
              marginInline: "auto",
            }}
            indicator={
              <LoadingIcon size="large" />
            }
          />
        </span>
      ) : (
        <div>

          <div className="products-config">
            <div className="products-config-section" >
              <span className="horizontal-between">
                <p className="products-config-section-title">Manual</p>
                <div className="btn-config-container" style={{ justifyContent: "flex-end" }} >
                  {/* <Switch checked={chkManual} onChange={onChangeManual} /> */}
                  {isAllowed(accessDataFormat("budgets", "manage")) && (<Tooltip title="Configurar">
                    <span
                      onClick={() =>
                        //setShowClientConfig("manualLoad")
                        navigate(`/settings/users`)
                      }
                      style={{ height: SIZE }}
                    >
                      <FiSliders size={SIZE} className="pointer" />
                    </span>
                  </Tooltip>)}
                </div>
              </span>
              <p className="products-config-section-subtitle">
                Configurado en 'Usuarios y roles', en sección 'Configuración'.
              </p>
            </div>

            <div className="products-config-section">
              <span className="horizontal-between">
                <p className="products-config-section-title">Excel</p>
                <div className="btn-config-container" style={{ justifyContent: "flex-end" }} >
                  {/* <Switch checked={chkExcel} onChange={onChangeExcel} /> */}
                  <Tooltip title="Configurar">
                    <span
                      onClick={() => {
                        setShowClientConfig("excelLoad")
                        setMostrarRequestDrawer(true);
                        setStage(3);
                      }}
                      style={{ height: SIZE }}
                    >
                      <FiSliders size={SIZE} className="pointer" />
                    </span>
                  </Tooltip>
                </div>

              </span>
              <p className="products-config-section-subtitle">
                {clientsConfig?.clientsLoadExcel ? SETTED : NOT_SETTED}  {/* Esto es el configurado o no configurado*/}
              </p>
            </div>

            <div className="products-config-section">
              <span className="horizontal-between">
                <p className="products-config-section-title">HTTP Request</p>
                <div className="btn-config-container" style={{ justifyContent: "flex-end" }}>
                  {clientsConfig?.clientsLoad ?
                    <Tooltip title="Petición automática">
                      <span>
                        <Switch checked={chkCron} onChange={onChangeHttp} loading={loadingSwitch} />
                      </span>
                    </Tooltip>
                    : ''}
                  <Tooltip title="Configurar">
                    <span
                      onClick={() => {
                        setShowClientConfig('httpLoad');
                        setMostrarRequestDrawer(true);
                        setStage(0);
                      }}
                      style={{ height: SIZE }}
                    >
                      <FiSliders size={SIZE} className="pointer" />
                    </span>
                  </Tooltip>
                </div>
              </span>
              <p className="products-config-section-subtitle">
                {clientsConfig?.clientsLoad ? SETTED : NOT_SETTED}
              </p>
              {clientsConfig?.clientsLoad && (

                <Tooltip title="Ejecutar">
                  <span
                    onClick={handleClientsLoad}
                    style={{ marginLeft: "auto" }}>
                    {
                      loadingEjecutar ? <LoadingIcon size="default" /> :

                        <FiPlay
                          size={SIZE}
                          className="pointer"
                        />
                    }
                  </span>
                </Tooltip>

              )}
            </div>

            <Drawer
              open={mostrarRequestDrawer}
              width={450}
              className="drawer-vars"
              //push={{ distance: "450px" }}
              destroyOnClose
            >
              {showClientConfig === "httpLoad" && (
                <DrawerConfigClient
                  clientsConfig={clientsConfig}
                  setClientsConfig={setClientsConfig}
                  setMostrarRequestDrawer={setMostrarRequestDrawer}
                  setStage={setStage}
                  stage={stage}
                  chkCron={chkCron}
                  setChkCron={setChkCron}
                />
              )}
              {showClientConfig === "excelLoad" && (
                <DrawerConfigClient
                  clientsConfig={clientsConfig}
                  setClientsConfig={setClientsConfig}
                  setMostrarRequestDrawer={setMostrarRequestDrawer}
                  setStage={setStage}
                  stage={stage}
                />
              )}
              {showClientConfig === "manualLoad" && <p>Carga manual</p>}
            </Drawer>

            {/* <Modal
              //open={showClientConfig}
              onCancel={closeModal}
              footer={null}
              destroyOnClose
            >
              {showClientConfig === "httpLoad" && (
                <DrawerConfigClient
                  clientsConfig={clientsConfig}
                  setClientsConfig={setClientsConfig}
                  closeModal={closeModal}
                />
              )}
              {showClientConfig === "manualLoad" && <p>Carga manual</p>}
              {showClientConfig === "excelLoad" && <p>Carga excel</p>}
            </Modal> */}
          </div>

        </div>
      )}
    </div>
  );
};