import { Button, Drawer, Spin } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  useViewport
} from 'reactflow';
import 'reactflow/dist/style.css';
import { FlowContext } from '../../../context/FlowContext';
import './flows.css';
import { ConfirmFlow } from './ConfirmFlow';
import { HandleForm } from './forms/HandleForm';
import { nodeTypes } from './nodeTypes';
import { edgeTypes } from './customEdges/edgeTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../../../context/GlobalContext';
import { handleAnimatedEdges } from './utils/handleAnimatedEdges';
import { FlowAllowed } from './FlowAllowed';
import LoadingIcon from '../../Loader/LoadingIcon';
import { WarningModal } from '../../modals/WarningModal';
import { PlusOutlined } from '@ant-design/icons';
import { CargaMedia } from '../../modals/CargaMedia';
import { CargarMediaButton } from '../../buttons/CargarMediaButton';
import { CONTACT_FILTERS } from '../../../../utils/CONTACT_FILTERS';
import { GenerateQrModal } from './GenerateQrModal';
import { getQrCodes } from '../../../../helpers/fetchData/fetchQrCodes';

const initialNodes = [
  {
    id: '0',
    position: { x: 0, y: 0 },
    type: 'trigger',
    data: {
      label: 'Palabras claves'
    },
    origin: [0.5, 0.5]
  }
];

const initialEdges = [];

const initialFlowVariables = [
  {
    idNodo: '0',
    name: 'flow_response',
    type: 'string',
    label: 'Última respuesta'
  }
];

export const Flows = ({ edit, type }) => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const { messageApi, instance } = useContext(GlobalContext);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { screenToFlowPosition } = useReactFlow();

  const [loading, setLoading] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [nodoSeleccionado, setNodoSeleccionado] = useState();
  const [mostrarDrawer, setMostrarDrawer] = useState(false);
  const [mostrarDrawerVars, setMostrarDrawerVars] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDrawerPermisos, setShowDrawerPermisos] = useState(false);
  const [openGenerateQrModal, setOpenGenerateQrModal] = useState(false);
  const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);

  const [filters, setFilters] = useState(CONTACT_FILTERS);

  const [flowAEditar, setFlowAEditar] = useState({});
  const [flowVariables, setFlowVariables] = useState(initialFlowVariables);

  const nextNodeId = useRef((initialNodes?.length + 1).toString());
  const idHandle = useRef(null);

  const onConnect = useCallback((params) => {
    let parametros = { ...params };

    if (params.sourceHandle) {
      if (params.sourceHandle === 'fallback') {
        parametros.animated = true;
        parametros.style = {
          stroke: '#FF4A44'
        };
      }
    }

    setEdges((eds) => addEdge(parametros, eds));
  }, []);

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    connectingNodeId.current = nodeId;
    idHandle.current = handleId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        const id = nextNodeId.current.toString();

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY
          }),
          type: 'inicial',
          data: { label: `Sin configurar` },
          origin: [0.5, 0.5]
        };

        setNodes((nds) => nds.concat(newNode));

        let newEdge = {
          id,
          source: connectingNodeId.current,
          target: id
        };

        if (idHandle) {
          newEdge = {
            id,
            source: connectingNodeId.current,
            target: id,
            sourceHandle: idHandle.current
          };

          if (idHandle?.current === 'fallback') {
            newEdge.type = 'fallback';
            newEdge.animated = true;
          }
        }

        setEdges((eds) => eds.concat(newEdge));

        setNodoSeleccionado(newNode);
        setMostrarDrawer(true);
      }
    },
    [screenToFlowPosition]
  );

  const handleGuardar = () => {
    setShowConfirm(true);
  };

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (edit) {
      const id = new URLSearchParams(location.search).get('id');
      if (id && instance?.userData?.apiKey) {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_PRODUCCION}/flows/getFlowById?flowId=${id}`,
          headers: {
            Authorization: instance?.userData?.apiKey
          }
        };

        axios
          .request(config)
          .then((res) => {
            if (res?.status === 200) {
              setFlowAEditar(res.data);
              setNodes(res.data?.flowArchitectureData?.nodes);
              setEdges(res.data?.flowArchitectureData?.edges);
              setFlowVariables(res.data?.flowArchitectureData?.variablesNodos);
            } else {
              messageApi.error(
                'No se pudo obtener informacion del bot elegido'
              );
            }
          })
          .catch((error) => {
            messageApi.error('No se pudo obtener información del bot elegido');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  }, [location, instance]);

  useEffect(() => {
    if (Array.isArray(nodes)) {
      let number = Number(nodes[nodes.length - 1]?.id);
      number += 1;
      nextNodeId.current = number.toString();
    }
  }, [nodes]);

  useEffect(() => {
    handleAnimatedEdges(nodoSeleccionado, setEdges);
  }, [nodoSeleccionado]);

  useEffect(() => {
    if (!mostrarDrawer) {
      setNodoSeleccionado({});
    }
  }, [mostrarDrawer]);

  useEffect(() => {
    if (type === 'broadcast') {
      setNodes((prevState) => {
        let array = [...prevState];

        array[0] = {
          ...array[0],
          type: 'broadcast'
        };
        return array;
      });
    }
  }, [type]);

  useEffect(() => {
    if (flowAEditar?.flowData?.filters) {
      setFilters(flowAEditar?.flowData?.filters);
    }
  }, [flowAEditar]);

  function handleBackClick() {
    // setIsModalOpen(true);
    navigate('/flows');
  }

  const handleGenerateQr = () => {
    setOpenGenerateQrModal(true);
  };

  /* function handleModalOk() {
    setIsModalOpen(false);
    navigate('/flows');
  } */

  const handleAddNote = useCallback(() => {
    try {
      setNodoSeleccionado({ type: 'inicial' });
      setMostrarDrawer(true);
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    if (flowAEditar?.flowData && instance?.userData?.apiKey) {
      getQrCodes(instance)
        .then((res) => {
          if (res?.status === 200) {
            setQrCodes(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('No se pudo cargar los códigos QR');
        });
    }
  }, [instance, flowAEditar]);

  useEffect(() => {
    if (flowAEditar?.flowData && qrCodes) {
      const isHaveQrCode = qrCodes.some(
        (qr) => qr.flowId === flowAEditar.flowData._id
      );

      setIsQrCodeGenerated(isHaveQrCode);
    }
  }, [qrCodes]);

  return (
    <FlowContext.Provider
      value={{
        mostrarDrawer,
        setMostrarDrawer,
        nodoSeleccionado,
        setNodoSeleccionado,
        nodes,
        setNodes,
        edges,
        setEdges,
        flowVariables,
        setFlowVariables,
        showConfirm,
        setShowConfirm,
        flowAEditar,
        mostrarDrawerVars,
        setMostrarDrawerVars,
        showDrawerPermisos,
        setShowDrawerPermisos,
        filters,
        setFilters,
        type,
        nextNodeId,
        reactFlowWrapper
      }}
    >
      <div className='main-wrapper-flow' ref={reactFlowWrapper}>
        <Drawer
          open={mostrarDrawer}
          onClose={() => {
            setMostrarDrawer(false);
          }}
          destroyOnClose
          width={nodoSeleccionado?.type === 'httpRequest' ? 500 : 450}
          mask={nodoSeleccionado?.type === 'inicial' ? true : false}
          closable={true}
        >
          <HandleForm
            nodoSeleccionado={nodoSeleccionado}
            setNodoSeleccionado={setNodoSeleccionado}
            mostrarDrawer={mostrarDrawer}
            setMostrarDrawer={setMostrarDrawer}
          />
        </Drawer>
        <Drawer
          open={showDrawerPermisos}
          onClose={() => {
            setShowDrawerPermisos(false);
          }}
          destroyOnClose
          width={500}
          mask={false}
        >
          <FlowAllowed />
        </Drawer>
        <div className='flow-header'>
          <span>
            <span>Bot builder</span>
            {flowAEditar?.flowData?.name && (
              <span className='flow-name'>{` - ${flowAEditar.flowData?.name}`}</span>
            )}
          </span>
          <div className='panel-flow'>
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={handleBackClick}
            >
              Volver
            </Button>
            {flowAEditar?.flowData?.type === 'trigger' &&
              flowAEditar?.flowData?.status === 'publish' &&
              edit &&
              !isQrCodeGenerated && (
                <Button
                  type='secondary'
                  className='btn-cancelar'
                  onClick={handleGenerateQr}
                >
                  Generar QR
                </Button>
              )}
            <CargarMediaButton />
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={handleAddNote}
              icon={<PlusOutlined size={12} />}
            >
              Nodo
            </Button>
            <Button
              type='secondary'
              className='btn-cancelar'
              onClick={() => setShowDrawerPermisos(true)}
            >
              Permisos
            </Button>
            <Button
              type='primary'
              className='btn-agregar'
              onClick={() => {
                handleGuardar();
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
        {showConfirm && <ConfirmFlow edit={edit} type={type} />}
        {loading ? (
          <Spin
            style={{ marginTop: 100 }}
            indicator={<LoadingIcon size='large' />}
          />
        ) : (
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            fitView={true}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={{
              type: 'buttonedge'
            }}
            connectionLineType='bezier'
            deleteKeyCode={null}
          >
            <Controls showInteractive={false} />
            <MiniMap pannable zoomable />
          </ReactFlow>
        )}
      </div>
      {/* <WarningModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onOk={handleModalOk}
      /> */}
      {openGenerateQrModal && (
        <GenerateQrModal
          setOpen={setOpenGenerateQrModal}
          flow={flowAEditar}
          setIsQrCodeGenerated={setIsQrCodeGenerated}
        />
      )}
    </FlowContext.Provider>
  );
};
