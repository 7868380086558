import MainLayout from '../ui/Layout/MainLayout';
import { FlowsMainContent } from '../ui/Layout/flows/FlowsMainContent';

export const FlowsView = () => {
  return (
    <MainLayout>
      <FlowsMainContent />
    </MainLayout>
  );
};
