import axios from "axios";

export const fetchPresupuestos = async (key) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/orderCart/getBudgets`,
      headers: {
        Authorization: key,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
