import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdOutlineTimer } from "react-icons/md";

export const ExpirationCountdown = ({
  chatFormattedData,
  selectedConversation,
  setIsConversationExpired,
  liveConversation,
}) => {
  const [time, setTime] = useState(null);

  const getDifference = (timestamp) => {
    if (!timestamp) {
      timestamp = 0;
    }

    if (timestamp && timestamp?.length < 12) {
      timestamp = timestamp * 1000;
    }

    let diferencia = timestamp - Number(dayjs().format("x"));

    if (diferencia <= 0) {
      setIsConversationExpired(true);
      return "";
    }

    return diferencia;
  };

  const formatTime = () => {
    if (Number(time)) {
      let secondsTime = Math.floor(time / 1000);
      const horas = Math.floor(secondsTime / (60 * 60));

      const minutos = Math.floor((secondsTime % (60 * 60)) / 60);

      const segundos = Math.floor(secondsTime % 60);

      const formattedTime =
        (horas < 10 ? "0" : "") +
        horas +
        ":" +
        (minutos < 10 ? "0" : "") +
        minutos +
        ":" +
        (segundos < 10 ? "0" : "") +
        segundos;

      return formattedTime || "";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (chatFormattedData.expirationTimestamp) {
      setTime(getDifference(chatFormattedData.expirationTimestamp));
      const interval = setInterval(
        () => setTime(getDifference(chatFormattedData.expirationTimestamp)),
        1000
      );

      return () => clearInterval(interval);
    } else {
      let lastUserMessage = liveConversation?.filter(
        (element) => element.userType === "user"
      );

      if (lastUserMessage?.length > 0) {
        lastUserMessage = lastUserMessage[lastUserMessage.length - 1];

        let timestamp = lastUserMessage.timestamp;

        if (timestamp) {
          if (timestamp?.length < 12) {
            timestamp = timestamp * 1000;
          }

          setTime(getDifference(timestamp));
          const interval = setInterval(
            () => setTime(getDifference(timestamp)),
            1000
          );

          return () => clearInterval(interval);
        }
      }

      setTime(null);
    }
  }, [chatFormattedData, selectedConversation]);

  return (
    <span className="expiration-countdown-wrapper">
      {formatTime() && (
        <>
          <MdOutlineTimer size={13} />
          <p style={{ width: 56 }}>{formatTime()}</p>
        </>
      )}
    </span>
  );
};
