import { Confirm } from "../components/ui/Confirm";
import { MainLayout } from "../components/ui/MainLayout";

export const ConfirmView = () => {
  return (
    <MainLayout title="Presupuesto">
      <Confirm />
    </MainLayout>
  );
};
