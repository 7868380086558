import { Button, Empty, Select } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { ProductCard } from "./ProductCard";
import { getCartTotal, getTotalByCartItem } from "../../utils/cart";
import { PresupuestosContext } from "../../context/PresupuestosContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { getContactName } from "../../../ui/Layout/campaigns/campaignBuilder/utils/getContactName";
import { SelectContactos } from "../../../ui/selectContactos/SelectContactos";

export const Cart = () => {
  const {
    cartDetail,
    presupuestoDrawer,
    setPresupuestoDrawer,
    payload,
    setPayload,
  } = useContext(PresupuestosContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const handleGoTo = (route) => {
    setPresupuestoDrawer((prevState) => {
      return { ...prevState, stage: route };
    });
  };

  const idsCart = useMemo(() => {
    return cartDetail?.map((element) => element.id);
  }, [cartDetail]);

  const handleClient = (id) => {
    setPayload({
      hash: null,
      payload: {
        clientId: id,
        userNumber: null,
      },
    });
  };

  return (
    <div className="home-wrapper">
      {!presupuestoDrawer.edit && (
        // <Select
        //   options={contactos?.map((contacto) => {
        //     return {
        //       label: getContactName(contacto.name),
        //       value: contacto._id,
        //     };
        //   })}
        //   placeholder="Seleccione un cliente"
        //   onChange={(v) => handleClient(v)}
        //
        // />
        <div style={{ marginInline: 12 }}>
          <SelectContactos
            value={null}
            setValue={(v) => handleClient(v)}
            placeholder="Seleccione un contacto"
          />
        </div>
      )}
      {!cartDetail.length ? (
        <div className="product-list">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Agrega artículos a tu carrito"
            style={{ marginTop: "20%" }}
          />
        </div>
      ) : (
        <div className="product-list">
          {cartDetail?.map((element) => {
            let total = getTotalByCartItem(element);
            const producto = element.item;

            return (
              <ProductCard
                producto={producto}
                total={total}
                idsCart={idsCart}
                key={producto.id}
                fromCart={true}
              />
            );
          })}
        </div>
      )}
      <span className="horizontal cart-footer">
        <span className="product-description" style={{ fontSize: 18 }}>
          <p className="product-total" style={{ marginRight: 4 }}>
            {cartDetail.length}
          </p>
          Artículos
        </span>
        <span className="product-description" style={{ fontSize: 18 }}>
          Total
          <p className="product-total" style={{ marginLeft: 4 }}>
            {getCartTotal(cartDetail)}
          </p>
        </span>
        <Button
          type="primary"
          style={{ marginLeft: "auto" }}
          onClick={() => handleGoTo("confirm")}
          disabled={
            cartDetail?.data?.payload?.length < 1 || !payload?.payload?.clientId
          }
        >
          Confirmar
        </Button>
      </span>
    </div>
  );
};
