import { Result } from "antd";
import { useContext, useEffect } from "react";
import { IoLockClosedOutline } from "react-icons/io5";
import { GlobalContext } from "../../../../context/GlobalContext";
import { expirationTime } from "../../../../../helpers/fetchData/fetchChats";

export const WaitingConversation = ({
  selectedConversation,
  setSelectedConversationData,
}) => {
  const { instance } = useContext(GlobalContext);

  useEffect(() => {
    if (instance?.userData?.apiKey && selectedConversation?.phoneNumber) {
      const interval = setInterval(() => {
        expirationTime(
          instance.userData.apiKey,
          selectedConversation?.phoneNumber
        )
          .then((res) => {
            if (res?.status === 200) {
              if (res.data?.expiration_timestamp) {
                setSelectedConversationData((prevState) => {
                  console.log(prevState);

                  return {
                    ...prevState,
                    expirationTimestamp: {
                      expiration_timestamp: res.data.expiration_timestamp,
                    },
                  };
                });
                clearInterval(interval);
              }
            }
          })
          .catch((err) => console.log(err));
      }, 20000);
      
      return () => clearInterval(interval);
    }
  }, [instance, selectedConversation]);

  return (
    <span className="chat-inner-input-wrapper">
      <Result
        icon={<IoLockClosedOutline size={24} />}
        style={{ padding: "0px" }}
        status="info"
        title={
          <p style={{ fontSize: "15px" }}>
            Esperando que se abra una conversación
          </p>
        }
        subTitle={
          <p style={{ fontSize: "13px" }}>
            Esperaremos a recibir un mensaje de parte del usuario.
          </p>
        }
      />
    </span>
  );
};
