import { Image, Tooltip } from 'antd';
import { useContext } from 'react';
import { FiSettings } from 'react-icons/fi';
import { GlobalContext } from '../../../../../../context/GlobalContext';

export const SinApp = () => {
  const { setAppStage } = useContext(GlobalContext);
  return (
    <>
      <span className='channel-wrapper fade-in' style={{ marginLeft: '36px' }}>
        <span
          className='horizontal'
          style={{
            justifyContent: 'space-between',
            marginBottom: '12px'
          }}
        >
          <Image width={42} src='/assets/WhatsApp.png' preview={false} />
          <Tooltip title='Configurar' placement='top'>
            <FiSettings
              size={20}
              style={{ cursor: 'pointer', color: '#252525' }}
              onClick={() => setAppStage(1)}
            />
          </Tooltip>
        </span>

        <span>
          <p style={{ marginBottom: '4px' }}>
            No existe aplicación asociada a su workspace.
          </p>
          <p>
            Es necesario configurar una aplicación (Meta) para poder utilizar la
            plataforma.
          </p>
        </span>
      </span>
    </>
  );
};
