import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BoxInfo } from "../../../../../box-info/BoxInfo";
import { handleAuthType } from "../../../../flows/forms/AutorizationType/handleAuthType";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const ProductsLoadGet = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
}) => {
  const { messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [authorizationType, setAuthorizationType] = useState("No auth");

  const [authData, setAuthData] = useState({
    type: "No auth",
    object: {},
    value: "",
  });

  const handleFetch = async (values) => {
    try {
      let config = {
        url: values.url,
        method: values.method,
        headers: { "Content-Type": "application/json" },
      };

      if (authData.type !== "No auth") {
        config.headers.authorization = authData.value;
      }

      setTmpConfig((prevState) => {
        let obj = { ...prevState };

        let productsLoad = { ...obj.productsLoad };

        productsLoad.request = {
          url: values.url,
          method: values.method,
          authData: authData,
        };

        obj.productsLoad = productsLoad;

        return obj;
      });

      setLoading(true);

      await axios(config)
        .then((res) => {
          if (res?.status === 200) {
            setResult(res.data);
            handleStage(1);
          } else {
            messageApi.error("No obtuvimos respuesta a la petición");
          }
        })
        .catch((error) => {
          messageApi.error("No obtuvimos respuesta a la petición");
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tmpConfig?.productsLoad) {
      const { request, objMap } = tmpConfig.productsLoad;

      if (request) {
        form.setFieldsValue({
          url: request.url,
          method: request.method,
        });

        if (request.authData) {
          setAuthData(request.authData);
        }
      }
    }
  }, []);

  return (
    <div className="budget-config-wrapper fade-in">
      <b>Configuremos cómo cargará los productos</b>
      <BoxInfo
        message={[
          "Configure una petición donde obtendremos un listado de productos.",
          "La petición deberá responder con un array de productos o al menos un objeto.",
        ]}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFetch}
        name="productsLoadGet"
      >
        <Form.Item
          name="url"
          label="URL"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item name="method" label="Método" initialValue={"get"}>
          <Select
            options={[
              { value: "get", label: "GET" },
              { value: "post", label: "POST" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Tipo de autorización"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select
            value={authorizationType}
            onChange={(v) => {
              setAuthData({ type: "", object: {}, value: "" });
              setAuthorizationType(v);
            }}
            options={[
              { value: "No auth", label: "Sin autorización" },
              { value: "Bearer", label: "Bearer Token" },
              { value: "Basic", label: "Basic" },
              { value: "Basic auth", label: "Basic Auth" },
            ]}
          />
        </Form.Item>
        {handleAuthType(authorizationType, setAuthData, authData)}
        <span className="horizontal" style={{ marginTop: 24 }}>
          <Button
            className="btn-oscuro"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "auto", opacity: loading ? 0.65 : 1 }}
            //loading={loading}
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          >
            Continuar
          </Button>
        </span>
      </Form>
    </div>
  );
};
