import { Button, Image } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  FiFile,
  FiImage,
  FiMapPin,
  FiMusic,
  FiPlayCircle,
} from "react-icons/fi";
import { HiPhone, HiOutlineExternalLink } from "react-icons/hi";
import { CgDetailsMore } from "react-icons/cg";
import { Handle, Position } from "reactflow";
import { parser } from "html-metadata-parser";
import { FlowContext } from "../../../context/FlowContext";
import { checkValidConnection } from "./utils/utilidadesFlow";
import { isInterpolate, renderHTMLTag } from "./utils/httpRequest";
import dayjs from "dayjs";
import { TbExternalLink } from "react-icons/tb";
import { handleListIds } from "./utils/handleListIds";
import { formatoTextHtml } from "../../../../helpers/utilidadesTemplates";

export const PreviewNode = ({
  node,
  ambito,
  bodyContent,
  buttonsCallContent,
  buttonsContent,
  buttonCta,
  buttonsMarketingContent,
  footerContent,
  headerContent,
  headerMediaType,
  headerType,
  inputText,
  preview,
  listContent,
  showInnerHandles,
  selected,
}) => {
  const [showButtons, setShowButtons] = useState(false);
  const [showCalls, setShowCalls] = useState(false);
  const [showMarketing, setShowMarketing] = useState(false);
  const [validateHTML, setValidateHTML] = useState(null);
  const [pagePreview, setPagePreview] = useState(null);

  const { edges } = useContext(FlowContext);

  const handleOnChangeHeader = () => {
    switch (headerMediaType) {
      case "image":
        if (
          node?.data?.headerContent?.header?.value?.link &&
          !isInterpolate(node?.data?.headerContent?.header?.value?.link)
        ) {
          return (
            <Image
              src={node?.data?.headerContent?.header?.value?.link}
              style={{ width: "100%", objectFit: "cover" }}
            />
          );
        }
        return <FiImage color="white" size={100} />;
      // case "image":
      //   return <FiImage color="white" size={100} />;
      case "video":
        return <FiPlayCircle color="white" size={100} />;
      case "document":
        return <FiFile color="white" size={100} />;
      case "audio":
        return <FiMusic color="white" size={100} />;
      case "location":
        return <FiMapPin color="white" size={100} />;
      default:
        return <FiImage color="white" size={100} />;
    }
  };

  const handleButtonMarketing = () => {
    let array = [...buttonsMarketingContent];

    array.forEach((boton, index) => {
      if (boton.type === "default") {
        array.push(boton);
        array.splice(index, 1);
      }
    });

    return array.map((boton, index) => {
      return (
        <Button className="boton-accion-template" key={index}>
          {boton.text}
          <Handle
            type="source"
            position={Position.Right}
            id={`${index}`}
            isConnectable={checkValidConnection(edges, node.id, `${index}`)}
          />
        </Button>
      );
    });
  };

  const handleTextWithUrl = () => {
    if (bodyContent?.includes("<span>") || bodyContent?.includes("</span>")) {
      return;
    }

    if (bodyContent) {
      const regex = /((http|https):\/\/[^\s]+)/g;
      const urlsEncontradas = bodyContent.match(regex);

      if (urlsEncontradas?.length > 0) {
        parser(urlsEncontradas[0]).then((res) => {
          let tmp = {
            meta: res.meta,
            og: res.og,
            url: urlsEncontradas[0],
          };

          setPagePreview(tmp);
        });
      }
    }
  };

  // const handleConnection = (index) => {
  //   let flag = false;
  //   if (edges) {
  //     edges.forEach((edge) => {
  //       if (edge.source === node.id) {
  //         if (edge.sourceHandle === String(index)) {
  //           flag = true;
  //         }
  //       }
  //     });
  //   }

  //   return flag;
  // };

  // const handleConnectionList = (seccion, indexItem) => {
  //   let flag = false;
  //   if (edges) {
  //     edges.forEach((edge) => {
  //       if (edge.source === node.id) {
  //         if (edge.sourceHandle === `${seccion}-${indexItem}`) {
  //           flag = true;
  //         }
  //       }
  //     });
  //   }

  //   return flag;
  // };

  const renderSection = (section, index) => {
    if (node.type === "sendMultiProduct") {
      return (
        <span className="list-seccion" key={index}>
          <span className="list-seccion-title">{section.title}</span>
          {section.product_items?.length > 0 &&
            section.product_items.map((row, rowIndex) => {
              return (
                <span className="list-row" key={`${index}-${rowIndex}`}>
                  <span
                    className="list-row-title"
                    id={`${node.id}-${rowIndex}-title`}
                  >
                    {renderHTMLTag(row.title, `${node.id}-${rowIndex}-title`)}
                  </span>
                  <span
                    className="list-row-description"
                    id={`${node.id}-${rowIndex}-description`}
                  >
                    {renderHTMLTag(
                      row.description,
                      `${node.id}-${rowIndex}-description`
                    )}
                  </span>
                  {!showInnerHandles && (
                    <>
                      <Handle
                        className="list-item-handle"
                        type="source"
                        position={Position.Right}
                        id={`${index}-${rowIndex}`}
                        isConnectable={checkValidConnection(
                          edges,
                          node.id,
                          `${index}-${rowIndex}`
                        )}
                      />
                      {/* {handleConnectionList(index, rowIndex) && (
                        <div
                          className="linea-trucha-lista"
                          style={
                            selected
                              ? { backgroundColor: "var(--whatsapp-color)" }
                              : {}
                          }
                        ></div>
                      )} */}
                    </>
                  )}
                </span>
              );
            })}
        </span>
      );
    } else {
      return (
        <span className="list-seccion" key={index}>
          <span className="list-seccion-title">{section.title}</span>
          {section.rows?.length > 0 &&
            section.rows.map((row, rowIndex) => {
              return (
                <span className="list-row" key={`${index}-${rowIndex}`}>
                  <span
                    className="list-row-title"
                    id={`${node.id}-${rowIndex}-title`}
                  >
                    <p className="list-row-id">{row.id}</p>
                    {renderHTMLTag(row.title, `${node.id}-${rowIndex}-title`)}
                  </span>
                  <span
                    className="list-row-description"
                    id={`${node.id}-${rowIndex}-description`}
                  >
                    {renderHTMLTag(
                      row.description,
                      `${node.id}-${rowIndex}-description`
                    )}
                  </span>
                  {!showInnerHandles && (
                    <>
                      <Handle
                        className="list-item-handle"
                        type="source"
                        position={Position.Right}
                        id={`${index}-${rowIndex}`}
                        isConnectable={checkValidConnection(
                          edges,
                          node.id,
                          `${index}-${rowIndex}`
                        )}
                      />
                      {/* {handleConnectionList(index, rowIndex) && (
                        <div
                          className="linea-trucha-lista"
                          style={
                            selected
                              ? { backgroundColor: "var(--whatsapp-color)" }
                              : {}
                          }
                        ></div>
                      )} */}
                    </>
                  )}
                </span>
              );
            })}
        </span>
      );
    }
  };

  useEffect(() => {
    if (buttonsContent) {
      if (buttonsContent.length > 0) {
        setShowButtons(false);
        buttonsContent.forEach((element) => {
          if (element !== "") {
            setShowButtons(true);
          }
        });
      } else {
        setShowButtons(false);
      }
    }
  }, [buttonsContent]);

  useEffect(() => {
    if (buttonsCallContent) {
      if (buttonsCallContent.length > 0) {
        setShowCalls(false);
        buttonsCallContent.forEach((element) => {
          if (element.text !== "") {
            setShowCalls(true);
          }
        });
      } else {
        setShowCalls(false);
      }
    }
  }, [buttonsCallContent]);

  useEffect(() => {
    if (buttonsMarketingContent) {
      if (buttonsMarketingContent.length > 0) {
        setShowMarketing(false);
        buttonsMarketingContent.forEach((element) => {
          if (element.text !== "") {
            setShowMarketing(true);
          }
        });
      } else {
        setShowMarketing(false);
      }
    }
  }, [buttonsMarketingContent]);

  useEffect(() => {
    const regex = /<\/?[^>]+(>|$)/g;
    if (inputText) {
      setValidateHTML(inputText.match(regex));
    } else {
      setValidateHTML(null);
    }
  }, [inputText]);

  useEffect(() => {
    if (ambito && (bodyContent || bodyContent === "")) {
      let divBodyContent = document.getElementById(ambito);
      if (divBodyContent) {
        if (validateHTML === null) {
          const html = formatoTextHtml(bodyContent.split("\n").join("<br>"));
          divBodyContent.innerHTML = html;
        } else {
          divBodyContent.innerText = bodyContent.split("\n").join("<br>");
        }
      }

      handleTextWithUrl();
    }
  }, [bodyContent, validateHTML, ambito]);

  useEffect(() => {
    if (footerContent || footerContent === "") {
      let pFooterContent = document.getElementById(`${node.id}-footer`);
      if (pFooterContent) {
        const html = formatoTextHtml(footerContent.split("\n").join("<br>"));
        pFooterContent.innerHTML = html;
      }
    }
  }, [footerContent]);

  useEffect(() => {
    if (headerContent || headerContent === "") {
      let pHeaderContent = document.getElementById(`${node.id}-header`);
      if (pHeaderContent) {
        const html = formatoTextHtml(headerContent.split("\n").join("<br>"));
        pHeaderContent.innerHTML = html;
      }
    }
  }, [headerContent]);

  return (
    <div className="node-preview-container">
      <div className={!ambito.includes("node") ? "bubble-place" : ""}>
        <div
          className={
            showButtons || showCalls || showMarketing
              ? "node-bubble fit-wrapper"
              : "node-bubble"
          }
        >
          {headerType === "text" && pagePreview && (
            <div className="page-preview-wrapper">
              {pagePreview.og.image && (
                <div className="page-preview-left">
                  <img width={100} src={pagePreview.og.image} alt="preview" />
                </div>
              )}
              <div
                className={
                  pagePreview.og.image
                    ? "page-preview-right"
                    : "page-preview-right full"
                }
              >
                <span
                  style={{ fontSize: "15px", color: "rgba(17,27,33,0.87)" }}
                >
                  {pagePreview.meta.title}
                </span>
                <span style={{ fontSize: "14px", color: "rgba(17,27,33,0.6)" }}>
                  {pagePreview.meta.description}
                </span>
                <span style={{ fontSize: "13px", color: "rgba(17,27,33,0.3)" }}>
                  {pagePreview.url}
                </span>
              </div>
            </div>
          )}
          {headerType === "media" && !preview && (
            <span className="media-header-wrapper">
              {handleOnChangeHeader()}
            </span>
          )}
          {headerMediaType === "image" && preview && (
            <img
              className="image-preview"
              src={preview}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/assets/image-placeholder.png";
              }}
              alt="preview"
              width="calc(100%)"
              height={preview ? "auto" : "200px"}
            />
          )}
          {headerMediaType === "audio" && preview && (
            <audio
              controls
              src={preview}
              style={{
                width: "100%",
                backgroundColor: "#f1f3f4",
                borderRadius: "8px",
              }}
            />
          )}
          {headerMediaType === "document" && preview && (
            <iframe
              className="image-preview"
              title="pdf-preview"
              src={preview}
              width="calc(100%)"
              height="200px"
            />
          )}
          {headerMediaType === "video" && preview && (
            <video
              controls
              className="image-preview"
              src={preview}
              width="calc(100%)"
              height="200px"
            />
          )}
          <div className="txt">
            {headerType === "text" && headerContent && (
              <p className="name" id={`${node.id}-header`}>
                {/* {headerContent && headerContent} */}
              </p>
            )}

            <p className="message" id={ambito}></p>

            {node?.data?.location?.name && (
              <p className="message">{node?.data?.location?.name}</p>
            )}

            {node?.data?.location?.address && (
              <p className="message">{node?.data?.location?.address}</p>
            )}
            {footerContent && (
              <p className="footer" id={`${node.id}-footer`}></p>
            )}
          </div>
          <span className="timestamp-wrapper">
            <span className="timestamp">{dayjs().format("hh:mm a")}</span>
          </span>
          {showCalls && (
            <div className="layout-botones-call">
              {buttonsCallContent.map((boton) => {
                return boton.type === "llamada" ? (
                  <span className="linea-horizontal-call" key={boton.type}>
                    <HiPhone size={16} className="call" />
                    <a className="help-link call" href={boton.link} type="tel">
                      <p>{boton.text}</p>
                    </a>
                  </span>
                ) : (
                  <span className="linea-horizontal-call" key={boton.type}>
                    <HiOutlineExternalLink size={16} className="call" />
                    <a
                      className="help-link call"
                      href={boton.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>{boton.text}</p>
                    </a>
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {buttonCta && (
        <div className="node-buttons-layout">
          <span className="button-cta">
            <TbExternalLink />
            <p>{buttonCta.display_text}</p>
          </span>
        </div>
      )}
      {showButtons && (
        <div className="node-buttons-layout">
          {buttonsContent?.map((boton, index) => {
            return (
              <Button
                className="boton-accion-template botones-con-linea"
                key={index}
              >
                <p className="boton-indice">{index + 1}</p>
                {boton}
                {!showInnerHandles && (
                  <>
                    <Handle
                      type="source"
                      position={Position.Right}
                      id={`${index}`}
                      isConnectable={checkValidConnection(
                        edges,
                        node.id,
                        `${index}`
                      )}
                    />
                    {/* {handleConnection(index) && (
                      <div
                        className="linea-trucha"
                        style={
                          selected
                            ? { backgroundColor: "var(--whatsapp-color)" }
                            : {}
                        }
                      ></div>
                    )} */}
                  </>
                )}
              </Button>
            );
          })}
        </div>
      )}
      {showMarketing && handleButtonMarketing()}
      {listContent?.sections?.length > 0 && (
        <div className="node-list-layout">
          <span className="titulo-lista">
            <CgDetailsMore />
            {listContent.button}
          </span>
          {handleListIds(listContent)?.sections?.map((section, index) => {
            return renderSection(section, index);
          })}
        </div>
      )}
    </div>
  );
};
