import { clearInnerVars } from "./clearInnerVars";
import { updateInnerVars } from "./updateInnerVars";

export const handleVariablesIndexadasLista = (
  selectedVariable,
  nodeId,
  flowVariables,
  setFlowVariables,
  messageApi
) => {
  let index = flowVariables.filter(
    (element) => element.name === selectedVariable
  );

  if (index.length > 0) {
    index = index[0].idNodo;

    let filtro = flowVariables.filter((element) => {
      let flag = false;
      if (element.kind) {
        if (element.idNodo === index && element.kind === "indexedVars") {
          flag = true;
        }
      }
      return flag;
    });

    clearInnerVars(setFlowVariables, nodeId, messageApi, "indexedVars");

    let variableName =
      "index_" +
      selectedVariable?.substring(selectedVariable?.indexOf("_") + 1);

    filtro.map((element) => {
      let name = element.name;

      name = name.replace(/\[idx\]/g, `[${variableName}]`);

      updateInnerVars(setFlowVariables, {
        idNodo: nodeId,
        name: name,
        type: "string",
        kind: "indexedVars",
      });
    });
  } else {
    messageApi.error("No se pudo setear variables indexadas de la lista");
  }
};
