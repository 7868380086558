import axios from "axios";

export const getBudgetConfig = async (apiKey) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetConfig`,
      headers: {
        Authorization: apiKey,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const upsertBudgetConfig = async (apiKey, data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetConfig`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    console.log(config);
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBudgetConfig = async (apiKey) => {
  try {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/budgetConfig`,
      headers: {
        Authorization: apiKey,
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
