import { Form, Input } from "antd";
import { BotonAgregarVariable } from "./BotonAgregarVariable";

export const HeaderTypeLocation = ({ location, setLocation, nodeId }) => {
  const decorarLocation = (seccion, item, variable) => {
    setLocation((prevState) => {
      let object = { ...prevState };

      object[item] = "${" + variable.name + "}";

      return object;
    });
  };

  return (
    <>
      <Form.Item
        label={
          <span
            className="horizontal"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <p>Latitud</p>
            <BotonAgregarVariable
              context="location"
              seccion="latitude"
              decorarTexto={decorarLocation}
              nodeId={nodeId}
              showContextVars={false}
              lookingForTypes={["geo_latitude"]}
            />
          </span>
        }
      >
        <Input
          value={location.latitude}
          onChange={(v) =>
            setLocation((prevState) => {
              let object = { ...prevState };

              object.latitude = v.target.value;

              return object;
            })
          }
        />
      </Form.Item>
      <Form.Item
        label={
          <span
            className="horizontal"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <p>Longitud</p>
            <BotonAgregarVariable
              context="location"
              seccion="longitude"
              decorarTexto={decorarLocation}
              nodeId={nodeId}
              showContextVars={false}
              lookingForTypes={["geo_longitude"]}
            />
          </span>
        }
      >
        <Input
          value={location.longitude}
          onChange={(v) =>
            setLocation((prevState) => {
              let object = { ...prevState };

              object.longitude = v.target.value;

              return object;
            })
          }
        />
      </Form.Item>
      <Form.Item
        label={
          <span
            className="horizontal"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <p>Nombre</p>
            <BotonAgregarVariable
              context="location"
              seccion="name"
              decorarTexto={decorarLocation}
              nodeId={nodeId}
              showContextVars={false}
              lookingForTypes={["geo_name"]}
            />
          </span>
        }
      >
        <Input
          value={location.name}
          onChange={(v) =>
            setLocation((prevState) => {
              let object = { ...prevState };

              object.name = v.target.value;

              return object;
            })
          }
        />
      </Form.Item>
      <Form.Item
        label={
          <span
            className="horizontal"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <p>Dirección</p>
            <BotonAgregarVariable
              context="location"
              seccion="address"
              decorarTexto={decorarLocation}
              nodeId={nodeId}
              showContextVars={false}
              lookingForTypes={["geo_address"]}
            />
          </span>
        }
      >
        <Input
          value={location.address}
          onChange={(v) =>
            setLocation((prevState) => {
              let object = { ...prevState };

              object.address = v.target.value;

              return object;
            })
          }
        />
      </Form.Item>
    </>
  );
};
