import { Button, Form } from 'antd';

export const FooterForm = ({ setMostrarDrawer }) => {
  return (
    <Form.Item className='form-custom-footer'>
      <div className='botones-wrapper-content'>
        <Button
          type='primary'
          htmlType='submit'
          className='btn-guardar'
          size='large'
        >
          Guardar
        </Button>
        <Button
          className='btn-cancelar'
          size='large'
          type='secondary'
          onClick={() => {
            setMostrarDrawer(false);
          }}
        >
          Cancelar
        </Button>
      </div>
    </Form.Item>
  );
};
