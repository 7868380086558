import { useContext } from "react";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { FlowContext } from "../../../../context/FlowContext";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { Handle, Position } from "reactflow";
import { Tooltip } from "antd";
import { TbSparkles } from "react-icons/tb";

export const WorkingNode = (node) => {
  const { edges } = useContext(FlowContext);

  return (
    <div className={isSelectedClass(node.selected)}>
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "50%" }}
        isConnectableStart={false}
        isConnectableEnd={false}
      />
      <Tooltip title="Espera un momento" placement="top">
        <span>
          <TbSparkles size={32} />
        </span>
      </Tooltip>
      <Handle
        type="source"
        className="custom-handle next-step"
        style={{ right: "-10px" }}
        position={Position.Right}
        id="nextstep"
        isConnectable={checkValidConnection(edges, node.id, "nextstep")}
      />
    </div>
  );
};
