import { Drawer } from 'antd';
import { GlobalContext } from '../../../context/GlobalContext';
import { useContext } from 'react';
import PlusContent from './contentDrawer/PlusContent';
import HelpContent from './contentDrawer/HelpContent';

const SideDrawer = () => {
  const { sideDrawerConfig, setSideDrawerConfig } = useContext(GlobalContext);

  const handleContent = () => {
    const { content } = sideDrawerConfig;

    switch (content) {
      case 'plus':
        return <PlusContent />;
      case 'help':
        return <HelpContent />;
      default:
        break;
    }
  };

  return (
    <Drawer
      destroyOnClose
      onClose={() => {
        setSideDrawerConfig({ visible: false, content: '' });
      }}
      closeIcon={null}
      open={sideDrawerConfig.visible}
      placement='right'
      styles={{ header: { display: 'none' }, body: { padding: '0px' } }}
      width={500}
    >
      {handleContent()}
    </Drawer>
  );
};

export default SideDrawer;
