import { Form, Input } from "antd";
import { BotonAgregarVariable } from "../BotonAgregarVariable";
import { agregarVariableWhatsapp } from "../../../../../../helpers/utilidadesTemplates";

export const BearerToken = ({ setAuthData, authData, nodeId }) => {
  const decorarTexto = (wrappingString, emoji, variable) => {
    setAuthData((prevState) => {
      let object = { ...prevState };

      object.value = agregarVariableWhatsapp(
        prevState.value,
        variable,
        "bearerInput"
      );

      return object;
    });
  };

  const renderLabel = () => {
    // Para usarlo en nodos, flow u otro contexto
    if (nodeId != null) {
      return (
        <span
          className="horizontal"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <p>Bearer</p>
          <BotonAgregarVariable
            decorarTexto={decorarTexto}
            nodeId={nodeId}
            showContextVars={false}
          />
        </span>
      );
    } else {
      return "Bearer";
    }
  };

  return (
    <Form.Item label={renderLabel()}>
      <Input.TextArea
        autoSize={{ minRows: 2 }}
        value={authData.value}
        onChange={(v) =>
          setAuthData({
            type: "Bearer",
            object: { token: v.target.value },
            value: v.target.value,
          })
        }
        id="bearerInput"
      />
    </Form.Item>
  );
};
