import { Spin } from 'antd';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { FiCopy, FiEdit3 } from 'react-icons/fi';
import { useContext } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { EliminarDireccionEmail } from './EliminarDireccionEmail';

export const CardEmail = ({
  dataEmail,
  setDataEmail,
  loadingCardEmail,
  setLoadingCardEmail,
  setShowEdit
}) => {
  const { messageApi } = useContext(GlobalContext);

  function copyDireccionEmail() {
    if (dataEmail.from.address) {
      navigator.clipboard.writeText(dataEmail.from.address);
      messageApi.success('Dirección de correo electrónico copiada');
    } else {
      messageApi.error('No hay ninguna dirección vinculada');
    }
  }

  return (
    <Spin
      style={{ width: 650 }}
      size='large'
      indicator={<LoadingIcon size='large' />}
      spinning={loadingCardEmail}
    >
      <div
        className='card-config'
        style={{ width: 650, marginTop: 20, padding: 20 }}
      >
        <div className='apikey-content'>
          <span className='apikey-name card-config-description'>
            <b>Dirección de correo</b>
            <span className='instance-id scope-tag3'>
              {dataEmail ? dataEmail.from.address : 'Ningún correo vinculado'}
            </span>
          </span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              width: 70
            }}
          >
            <FiCopy
              style={{ cursor: 'pointer' }}
              size={18}
              onClick={copyDireccionEmail}
            />
            <FiEdit3
              style={{ cursor: 'pointer' }}
              size={18}
              onClick={() => setShowEdit(true)}
            />
            <EliminarDireccionEmail
              dataEmail={dataEmail}
              setDataEmail={setDataEmail}
            />
          </span>
        </div>
      </div>
    </Spin>
  );
};
