import { useContext } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Tooltip } from "antd";
import { PiAddressBookBold } from "react-icons/pi";
import { accessDataFormat } from "../../../../../helpers/access";
import { useAccess } from "../../../../../hooks/useAccess";

const ICON_SIZE = 20;

export const contactsLeadsColumns = (setContactos, setDrawerConfig, setLeads) => {
  return [
    { title: "Nombre", key: "profileName", dataIndex: "profileName" },
    {
      title: "Teléfono",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      render: (data) => <p className="monospace-font">{data}</p>,
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      width: 100,
      render: (item) => (
        <AccionesContactLeads
          item={item}
          setContactos={setContactos}
          setDrawerConfig={setDrawerConfig}
          setLeads={setLeads}
        />
      ),
    },
  ];
};

const AccionesContactLeads = ({ item, setContactos, setDrawerConfig, setLeads }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const handleAddContact = () => {
    let object = {};

    if (item?.phoneNumber) {
      object.phones = [{ phone: item.phoneNumber }];
    }

    if (item?.profileName) {
      let arrayName = item.profileName.split(" ");

      let name = {};

      let arrayLength = arrayName?.length;

      if (arrayLength > 2) {
        name.first_name = arrayName[0];
        name.middle_name = arrayName[1];
        name.last_name = arrayName[2];
      } else if (arrayLength === 2) {
        name.first_name = arrayName[0];
        name.last_name = arrayName[1];
      } else {
        name.first_name = arrayName[0];
      }

      object.name = name;
    }

    if (item?._id) {
      object.leadId = item._id;
    }

    setDrawerConfig({
      visible: true,
      content: "Agregar lead a contacto",
      item: object,
    });
  };

  return (
    <span className="horizontal">
      {isAllowed(accessDataFormat("contacts", "manage")) && (
        <Tooltip title="Agregar contacto">
          <span onClick={handleAddContact}>
            <PiAddressBookBold size={ICON_SIZE} />
          </span>
        </Tooltip>
      )}
    </span>
  );
};
