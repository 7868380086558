export const CUSTOM_LOCALE = {
  everyText: "Cada",
  emptyMonths: "cada mes",
  emptyMonthDays: "Cada día del mes",
  emptyMonthDaysShort: "día del mes",
  emptyWeekDays: "Cada día de la semana",
  emptyWeekDaysShort: "día de la semana",
  emptyHours: "cada hora",
  emptyMinutes: "cada minuto",
  emptyMinutesForHourPeriod: "todo",
  yearOption: "año",
  monthOption: "mes",
  weekOption: "semana",
  dayOption: "día",
  hourOption: "hora",
  minuteOption: "minuto",
  rebootOption: "reboot",
  prefixPeriod: "Cada",
  prefixMonths: "",
  prefixMonthDays: "",
  prefixWeekDays: "",
  prefixWeekDaysForMonthAndYearPeriod: "",
  prefixHours: "",
  prefixMinutes: ":",
  prefixMinutesForHourPeriod: "",
  suffixMinutesForHourPeriod: "minuto(s)",
  errorInvalidCron: "Expresión no válida",
  clearButtonText: "Limpiar",
  // Respetar el orden en los arrays
  weekDays: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  altWeekDays: ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"],
  altMonths: [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ],
};
