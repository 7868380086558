import { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { GlobalContext } from '../../../../context/GlobalContext';
import { getLaboral } from '../utils/getLaboral';
import { getDireccion } from '../utils/getDireccion';
import { getProvincia } from '../utils/getProvincia';
import { getTipoDireccion } from '../utils/getTipoDireccion';
import { getCumpleanios } from '../utils/getCumpleanios';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { getAdditionalFields } from '../../../../../helpers/fetchData/fetchAdditionalFields';
import { DEFAULT_CONTACT_KEYS } from '../../../utils/defaultContactKeys';
import { getAllClients } from '../../../../../helpers/fetchData/fetchClients';
import { EtiquetasXContactos } from '../../contactos/EtiquetasXContactos';

export const ShowContact = () => {
  const {
    rightDrawerConfig,
    setRightDrawerConfig,
    instance,
    messageApi,
    clientes,
    setClientes
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [activeClientes, setActiveClientes] = useState([]);
  const [contactClientes, setContactClientes] = useState([]);

  const { item } = rightDrawerConfig;

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoading
      );

      getAllClients(instance.userData?.apiKey)
        .then((res) => {
          setClientes(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [instance]);

  useEffect(() => {
    const newActiveClientes = clientes.filter((cliente) => cliente.state === 1);

    setActiveClientes(newActiveClientes);
  }, [clientes]);

  useEffect(() => {
    const newContactClientes = activeClientes.filter((cliente) =>
      cliente.contacts?.includes(item?._id)
    );
    setContactClientes(newContactClientes);
  }, [activeClientes, item]);

  const { addresses, birthday, emails, name, org, phones, tags } = item;

  const contactAdditionalFields = item.additionalFields;

  return (
    <div className='ShowContact'>
      <div className='show-contact-container'>
        <span className='show-contact-name'>{`${name?.first_name || ''} ${
          name?.middle_name || ''
        } ${name?.last_name || ''}`}</span>

        <span className='show-contact-job'>{getLaboral(org)}</span>

        {tags?.length > 0 && <EtiquetasXContactos data={tags} />}

        <div className='show-contact-info-container'>
          {birthday && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Fecha de nacimiento
              </span>
              <span className='show-contact-info'>
                {getCumpleanios(birthday)}
              </span>
            </div>
          )}

          <div className='show-contact-info-subcontainer'>
            <span className='show-contact-info-label'>Teléfono</span>
            <span className='show-contact-info'>
              {formatPhoneNumber(phones?.[0]?.phone)}
            </span>
          </div>

          {emails?.[0].email && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Correo electrónico
              </span>
              <span className='show-contact-info'>{emails[0].email}</span>
            </div>
          )}

          {(addresses?.[0]?.street ||
            addresses?.[0]?.city ||
            addresses?.[0]?.zip) && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>
                Dirección del {getTipoDireccion(addresses?.[0]?.type)}
              </span>
              <span className='show-contact-info'>
                {getDireccion(addresses[0])}
              </span>
              <span className='show-contact-info'>
                {getProvincia(addresses[0])}
              </span>
            </div>
          )}

          {additionalFields?.length > 0 &&
            contactAdditionalFields?.length > 0 && (
              <div className='show-contact-info-subcontainer'>
                {contactAdditionalFields.map((field, index) => {
                  const additionalField = additionalFields.find(
                    (af) => af._id === field._id
                  );

                  return additionalField ? (
                    <>
                      <span className='show-contact-info-label'>
                        {additionalField.name}
                      </span>
                      <span className='show-contact-info'>{field.value}</span>
                      {index < contactAdditionalFields.length - 1 &&
                        field.value && <div className='divider' />}
                    </>
                  ) : null;
                })}
              </div>
            )}

          {contactClientes && contactClientes.length > 0 && (
            <div className='show-contact-info-subcontainer'>
              <span className='show-contact-info-label'>Clientes</span>
              {contactClientes.map((cliente, index) => (
                <>
                  <span className='show-contact-info'>{cliente.name}</span>
                  {index < contactClientes?.length - 1 && cliente && (
                    <div className='divider' />
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className='show-contact-close-btn'>
        <Button
          style={{
            flex: '1',
            marginBottom: 16
          }}
          type='primary'
          size='large'
          onClick={() =>
            setRightDrawerConfig({ visible: false, content: '', item: null })
          }
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
};

// const Etiquetas = ({ data }) => {
//   const { etiquetas } = useContext(GlobalContext);

//   function getEtiquetas(tag) {
//     return etiquetas.find((element) => element._id === tag);
//   }

//   return (
//     <div className='celda-tags'>
//       {data?.map((tag, index) => {
//         const obj = getEtiquetas(tag);

//         return (
//           <Tag hex={obj?.color} nombre={obj?.name} key={obj?._id || index} />
//         );
//       })}
//     </div>
//   );
// };
