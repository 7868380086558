import { ReactFlowProvider } from "reactflow";
import MainLayout from "../ui/Layout/MainLayout";
import { CampaignBuilder } from "../ui/Layout/campaigns/campaignBuilder/CampaignBuilder";

export const CampaignBuilderView = ({ edit = false }) => {
  return (
    <MainLayout>
      <ReactFlowProvider>
        <CampaignBuilder edit={edit} />
      </ReactFlowProvider>
    </MainLayout>
  );
};
