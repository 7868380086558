import { Tag } from "../../../tag/Tag";

export const renderTicketType = (id, ticketTypes) => {
  if (id && ticketTypes?.length > 0) {
    const ticket = ticketTypes.find((element) => element._id === id);

    if (ticket) {
      return <Tag hex={ticket.color} nombre={ticket.name} />;
    }
  }

  return <></>;
};

export const renderClosingType = (id, closingTypes) => {
  if (id && closingTypes?.length > 0) {
    const type = closingTypes.find((element) => element._id === id);

    if (type) {
      return <Tag hex={type.color} nombre={type.name} />;
    }
  }

  return <></>;
};
