import { useState } from 'react';
import { LiaRobotSolid } from 'react-icons/lia';
import { Button, Tabs } from 'antd';
import { DefaultBotCard } from './DefaultBotCard';
import { ErrorMessages } from './errorMessages/ErrorMessages';

export const Bots = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const [activeKey, setActiveKey] = useState(1);

  const itemsTabs = [
    {
      key: 1,
      label: 'Bot por defecto',
      children: (
        <DefaultBotCard showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      )
    },
    { key: 2, label: 'Mensajes de error', children: <ErrorMessages /> }
  ];

  const handleChangeTab = (tab) => {
    setActiveKey(tab);
  };

  return (
    <div className='config-main-wrapper'>
      <div className='test-main-header'>
        <span
          className='horizontal'
          style={{ justifyContent: 'space-between' }}
        >
          <span className='columna' style={{ gap: '0px' }}>
            <span className='config-header-inline'>
              <span style={{ height: '24px' }}>
                <LiaRobotSolid size={24} color='var(--primary-color)' />
              </span>
              <span className='header-title-templates'>Bots</span>
            </span>
            <p className='header-subtitle'>Administre sus bots</p>
          </span>
          {activeKey === 1 && (
            <Button
              className='btn-agregar boton-dashed fade-in'
              onClick={() => setShowDrawer(true)}
            >
              Bot por defecto
            </Button>
          )}
        </span>
      </div>

      <div className='conf-content' style={{ marginBottom: 16 }}>
        <Tabs items={itemsTabs} onChange={handleChangeTab} />
      </div>
    </div>
  );
};
