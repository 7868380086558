export const newAttachedEdge = (type, parentId, id, edges, setEdges) => {
  let newEdge = {
    id: `${type}-${parentId}`,
    source: parentId,
    target: id.toString(),
    sourceHandle: "nextstep",
  };

  let updatedEdges = [...edges];

  let flag = false;

  for (let i = 0; i < updatedEdges.length; i++) {
    if (updatedEdges[i].id === id) {
      updatedEdges[i] = newEdge;
      flag = true;
    }
  }

  if (!flag) {
    updatedEdges.push(newEdge);
  }

  setEdges((prevState) => {
    let updatedEdges = [...prevState];

    let flag = false;

    for (let i = 0; i < updatedEdges.length; i++) {
      if (updatedEdges[i].id === id) {
        updatedEdges[i] = newEdge;
        flag = true;
      }
    }

    if (!flag) {
      updatedEdges.push(newEdge);
    }

    return updatedEdges;
  });
};
