export const CardPricing = ({ title, size, current }) => {
  return (
    <div className='CardBot CardPricing'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 56
        }}
      >
        <span style={{ fontSize: 18 }}>{title}</span>
        <span style={{ fontSize: 28 }}>{size}</span>
      </div>
      {current && <span style={{ fontSize: 12 }}>Este es tu plan actual</span>}
    </div>
  );
};
