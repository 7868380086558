import React from "react";
import { FiCopy } from "react-icons/fi";
import { HiOutlineExternalLink, HiPhone } from "react-icons/hi";
import { IoArrowUndoSharp } from "react-icons/io5";

export const AllButtons = ({ buttonsContent, setShowAll }) => {
  const SIZE = 14;
  const switchType = (boton, index) => {
    switch (boton.type) {
      case "PHONE_NUMBER":
        return (
          <>
            <HiPhone size={SIZE} className="call" />
            <a className="help-link call" href={boton.phone_number} type="tel">
              <p>{boton.text}</p>
            </a>
          </>
        );
      case "URL":
        return (
          <>
            <HiOutlineExternalLink size={SIZE} className="call" />
            <a
              className="help-link call"
              href={boton.link}
              target="_blank"
              rel="noreferrer"
            >
              <p>{boton.text}</p>
            </a>
          </>
        );
      case "COPY_CODE":
        return (
          <>
            <FiCopy size={SIZE} className="call" />
            <a className="help-link call" href={boton.link} type="tel">
              <p>{boton.text}</p>
            </a>
          </>
        );
      default:
        return (
          <>
            <IoArrowUndoSharp size={SIZE} className="call" />
            <p className="help-link call">{boton.text}</p>
          </>
        );
    }
  };
  return (
    <div className="all-buttons-mask" onClick={() => setShowAll(false)}>
      <div className="layout-botones-all" style={{ backgroundColor: "white" }}>
        {buttonsContent?.map((boton, index) => {
          return (
            <span
              className="linea-horizontal-call"
              key={index}
              style={index === 0 ? { borderTop: "none" } : {}}
            >
              {switchType(boton, index)}
            </span>
          );
        })}
      </div>
    </div>
  );
};
