import { Button, DatePicker, Form, TimePicker } from "antd";
import { useContext, useEffect, useState } from "react";
import { SendTemplateContext } from "../../../../../context/SendTemplateContext";
import { updateNode } from "../../../flows/utils/updateNode";
import dayjs from "dayjs";
import { GlobalContext } from "../../../../../context/GlobalContext";
import {
  DATE_FORMAT,
  defaultTime,
  toCron,
  validarCalendarios,
} from "../utils/schedule";
import { BoxInfo } from "../../../../box-info/BoxInfo";

export const ScheduleForm = ({ edit }) => {
  const { setMostrarDrawer, nodoSeleccionado, setNodes, nodes } =
    useContext(SendTemplateContext);

  const { messageApi } = useContext(GlobalContext);

  const [tmpSchedules, setTmpSchedules] = useState([
    { date: null, time: null },
  ]);

  const [form] = Form.useForm();

  const onFinish = () => {
    // Checkea que todos los segmentos tengan fecha y hora.
    const isValid = tmpSchedules.every((schedule) => {
      if (schedule.date && schedule.time) {
        return true;
      }

      return false;
    });

    if (!isValid) {
      messageApi.info("Debe seleccionar una fecha y hora para cada segmento");
      return;
    }

    let date = null;

    let time = null;

    let array = [];

    tmpSchedules.forEach((schedule) => {
      date = dayjs(schedule.date, DATE_FORMAT).format("DD/MM/YYYY");
      time = dayjs(schedule.time, DATE_FORMAT).format("HH:mm");
      let dateTime = dayjs(`${date} ${time}`, DATE_FORMAT);

      let cron = toCron(dateTime);

      dateTime = dayjs(dateTime).format(DATE_FORMAT);

      array.push({
        date: dateTime,
        time: cron,
      });
    });

    if (
      validarCalendarios(
        array.map((element) => element.date),
        messageApi
      )
    )
      return;

    const data = {
      schedule: array,
      immediate: false,
    };

    setNodes((prevState) => updateNode(nodoSeleccionado, data, prevState));

    setMostrarDrawer(false);
  };

  const handleDates = (index, type, v) => {
    const value = dayjs(v).format(DATE_FORMAT);

    setTmpSchedules((prevState) => {
      let array = [...prevState];

      array[index] = { ...array[index], [type]: value };

      return array;
    });

    if (tmpSchedules.length > 1) {
      defaultTime(index, setTmpSchedules);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf("day");
  };

  useEffect(() => {
    const { data } = nodoSeleccionado;

    if (data) {
      if (data.schedule) {
        setTmpSchedules(
          data.schedule.map((segmento) => {
            return { date: segmento.date, time: segmento.date };
          })
        );
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <span className="horizontal gap8" style={{ marginBottom: 8 }}>
        <b>Calendario</b>
      </span>
      {tmpSchedules &&
        tmpSchedules.map((schedule, index) => {
          return (
            <div key={index} style={{ marginTop: 8 }}>
              {/* <p style={{ marginBottom: 4 }}>{`Segmento ${index + 1}`}</p> */}
              <span className="horizontal gap8">
                <Form.Item label="Día" className="half-50">
                  <DatePicker
                    disabledDate={disabledDate}
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    value={schedule?.date && dayjs(schedule.date, DATE_FORMAT)}
                    onChange={(v) => handleDates(index, "date", v)}
                  />
                </Form.Item>
                <Form.Item label="Hora" className="half-50">
                  <TimePicker
                    format={"HH:mm"}
                    style={{ width: "100%" }}
                    value={schedule?.time && dayjs(schedule.time, DATE_FORMAT)}
                    onChange={(v) => handleDates(index, "time", v)}
                    showNow={false}
                  />
                </Form.Item>
              </span>
            </div>
          );
        })}

      {tmpSchedules?.length > 1 && (
        <BoxInfo
          message="Al actualizar el primer calendario se sugerirá la fecha de
            envío para el resto de los segmentos."
        />
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          {!edit && (
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
            >
              Guardar
            </Button>
          )}
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            block={edit}
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            {edit ? "Cerrar" : "Cancelar"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
