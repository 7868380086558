import { Button, Drawer, Form, Input, Select } from 'antd';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { addAdditionalField } from '../../../../../../../helpers/fetchData/fetchAdditionalFields';

export const NewAdditionalField = ({ open, setOpen, setAdditionalFields }) => {
  const [loading, setLoading] = useState(false);

  const { instance, messageApi } = useContext(GlobalContext);

  const [formAdditionalField] = Form.useForm();

  function handleFinish(v) {
    const formValues = { ...v };

    addAdditionalField(
      instance,
      messageApi,
      formValues,
      setAdditionalFields,
      setLoading,
      setOpen
    );
  }

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      closeIcon={null}
      placement='right'
      styles={{ header: { display: 'none' }, body: { padding: '0px' } }}
      width={500}
      destroyOnClose
    >
      <Form
        className='form-nodos'
        requiredMark='optional'
        form={formAdditionalField}
        name='newAdditionalField'
        style={{ padding: '24px' }}
        layout='vertical'
        onFinish={handleFinish}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Nuevo campo adicional
        </p>

        <Form.Item
          label='Nombre'
          name='name'
          rules={[
            {
              required: true,
              message: 'Nombre requerido'
            }
          ]}
        >
          <Input size='large' />
        </Form.Item>

        <Form.Item
          label='Tipo'
          name='type'
          rules={[
            {
              required: true,
              message: 'Tipo requerido'
            }
          ]}
        >
          <Select
            size='large'
            options={[
              {
                value: 'string',
                label: 'Texto corto'
              },
              {
                value: 'number',
                label: 'Número'
              },
              {
                value: 'text',
                label: 'Texto'
              }
            ]}
          />
        </Form.Item>

        <Form.Item className='form-custom-footer'>
          <div className='botones-wrapper-content'>
            <Button
              type='primary'
              htmlType='submit'
              className='btn-guardar'
              size='large'
              //loading={loading}
              icon={
                loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              size='large'
              type='secondary'
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
