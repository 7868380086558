export const StatInfo = ({ type }) => {
  const infoMap = {
    allConversations: (
      <div className='statInfo'>
        <p>
          Número de conversaciones entre tu negocio y cuentas en Whatsapp. Una
          conversación incluye todos los mensajes que se entregan en un período
          de 24 horas.
        </p>
        <p>
          Una conversación inicia cuando se entrega el primer mensaje del
          negocio y finaliza 24 horas después. El primer mensaje lo puede enviar
          el negocio (<strong>marketing, utilidad o autenticación</strong>) o
          puede ser una respuesta en un plazo de 24 horas a un mensaje recibido
          de una cuenta (<strong>servicio</strong>).
        </p>
      </div>
    ),
    freeConversations: (
      <div className='statInfo'>
        <p>
          Número de conversaciones por mensaje en WhatsApp entre tu empresa y
          los usuarios que son gratis.
        </p>
        <p>
          Una conversación empieza cuando se entrega el primer mensaje de la
          empresa y finaliza 24 horas después, a excepción de las conversaciones
          gratuitas desde punto de acceso, que tienen un plazo de 72 horas.
        </p>
        <p>
          Para esta métrica, se define al usuario como la persona o la entidad
          con la que la empresa se comunica por mensajes.
        </p>
      </div>
    ),
    freeTiers: (
      <div className='statInfo'>
        <p>
          Número de conversaciones por mensaje en WhatsApp que no suponen ningún
          gasto porque las primeras 1.000 conversaciones de servicio del mes de
          tu cuenta de WhatsApp Business son gratis.
        </p>
        <p>
          Si usas las 1.000 conversaciones de servicio gratuitas, es posible que
          no veas ese número exacto aqui debido a pequeñas variaciones en el
          procesamiento de datos. Sin embargo, siempre obtendrás 1.000
          conversaciones de servicio gratuitas por mes antes de que se cobren
          conversaciones pagadas.
        </p>
      </div>
    ),
    freeEntries: (
      <div className='statInfo'>
        <p>
          Número de conversaciones por mensaje en WhatsApp que no suponen ningún
          gasto porque las conversaciones se inician desde puntos de entrada
          específicos, los cuales incluyen{' '}
          <a
            href='https://www.facebook.com/business/help/447934475640650?id=371525583593535s'
            target='_blank'
            rel='noreferrer'
          >
            anuncios de clic a WhatsApp
          </a>{' '}
          y{' '}
          <a
            href='https://www.facebook.com/help/1638565256396310'
            target='_blank'
            rel='noreferrer'
          >
            llamadas a la acción de páginas de Facebook
          </a>
          .
        </p>
        <p>
          El intervalo de conversación para cada conversación gratuita desde
          punto de acceso es de 72 horas. Solo la primera conversación que se
          inicia desde el punto de acceso es gratuita. Las conversaciones
          posteriores con el usuario se cobran.
        </p>

        <p>
          <a
            href='https://developers.facebook.com/docs/whatsapp/pricing/conversationpricing/#free-entry-points-conversations'
            target='_blank'
            rel='noreferrer'
          >
            Obtén más información.
          </a>
        </p>
      </div>
    ),
    paidConversations: (
      <div className='statInfo'>
        <p>
          Número de conversaciones por mensaje en WhatsApp entre tu empresa y
          los usuarios que se cobran. Una conversación incluye todos los
          mensajes que se entregan en un período de 24 horas.
        </p>
        <p>
          Una conversación inicia cuando se entrega el primer mensaje del
          negocio y finaliza 24 horas después. El primer mensaje lo puede enviar
          el negocio (<strong>marketing, utilidad o autenticación</strong>) o
          puede ser una erespuesta en un plazo de 24 horas a un mensaje recibido
          de una cuenta (<strong>servicio</strong>).
        </p>
        <p>
          Para esta métrica, se define al usuario como la persona o la entidad
          con la que la empresa se comunica por mensajes.
        </p>
        <p>
          <strong>Cargos</strong>
        </p>
        <p>
          Cargos totales aproximados de conversaciones por WhatsApp. Estos
          cargos pueden diferir de lo que se muestra en las facturas debido a
          variaciones en el procesamiento de datos.
        </p>
        <p>
          El cargo de cada conversación pagada se determina en función de la
          tarifa asignada al país o la región del número de teléfono de la
          cuenta de WhatsApp y de si el negocio inició la conversación (
          <strong>marketing, utilidad o autenticación</strong>) o respondió en
          un plazo de 24 horas el mensaje recibido de una cuenta (
          <strong>servicio</strong>). Consulta{' '}
          <a
            href='https://developers.facebook.com/docs/whatsapp/pricing/conversationpricing/#rate-cards-for-countries-and-regions'
            target='_blank'
            rel='noreferrer'
          >
            Tarifas
          </a>{' '}
          para obtener información específica sobre los precios.
        </p>
      </div>
    )
  };

  return infoMap[type] || null;
};
