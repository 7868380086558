import { Button, Form, Input } from "antd";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { MdStickyNote2 } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { updateNode } from "../utils/updateNode";


export const NoteForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [text, setText] = useState("");

  const onFinish = (values) => {
    values.label = nombreNodo;

    values.text = text;

    values.handleCount = 1;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      const { label, text } = nodoSeleccionado.data;

      setNombreNodo(label || "");

      if (text) {
        setText(text);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={onFinish}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <MdStickyNote2 style={{ color: "var(--dark-color)" }} size={32} />
        }
      />
      <Input.TextArea
        autoSize={{ minRows: 2 }}
        value={text}
        onChange={(v) => setText(v.target.value)}
      />

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
