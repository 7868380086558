import { Button, Form, Input } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../../../context/GlobalContext";
import { newSavedMessage } from "../../../../../../../../helpers/fetchData/fetchSavedMessages";
import LoadingIcon from "../../../../../../Loader/LoadingIcon";
// import { GlobalContext } from "../../../../../../context/GlobalContext";
// import { newSavedMessage } from "../../../../../../../helpers/fetchData/fetchSavedMessages";
// import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const NewSavedMessage = ({
  mensajesDrawer,
  setMensajesDrawer,
  setGrupos,
}) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (v) => {
    let { titulo, value } = v;

    titulo = titulo.trim() || "";

    value = value.trim() || "";

    if (!titulo) {
      return messageApi.info("Es necesario que ingrese un título");
    }

    if (!value) {
      return messageApi.info("Es necesario que ingrese un mensaje");
    }

    let data = {
      name: titulo,
      value: value,
      categoryId:
        mensajesDrawer.item?._id === "default"
          ? null
          : mensajesDrawer.item?._id,
    };

    setLoading(true);

    newSavedMessage(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Mensaje creado correctamente");

          if (res.data.id) {
            let object = {
              _id: res.data.id,
              categoryId: mensajesDrawer.item?._id,
              name: data.name,
              value: data.value,
            };

            setGrupos((prevState) => {
              let array = [...prevState];

              let index = array.findIndex(
                (element) => element._id === object.categoryId
              );

              if (index >= 0) {
                let savedReplies = [...array[index]?.savedReplies];

                savedReplies.push(object);

                array[index].savedReplies = savedReplies;
              }

              return array;
            });

            setMensajesDrawer({ visible: false, content: "", item: null });
          }
        } else {
          messageApi.error("Ocurrió un error al crear una categoría");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al crear una categoría");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="newMessage"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <b
        style={{ marginBottom: "16px" }}
      >{`Nuevo mensaje - ${mensajesDrawer?.item?.name}`}</b>
      <Form.Item
        label="Título"
        name="titulo"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Mensaje"
        name="value"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Input.TextArea
          autoSize={{ minRows: 2 }}
          placeholder="Escriba un mensaje"
        />
      </Form.Item>
      <div
        className="botones-wrapper-content form-custom-footer"
        style={{ marginBottom: 12 }}
      >
        <Button
          type="primary"
          className="btn-guardar"
          size="large"
          htmlType="submit"
          icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
          style={{ opacity: loading ? 0.65 : 1 }}
        >
          Guardar
        </Button>
        <Button
          className="btn-cancelar"
          size="large"
          type="secondary"
          onClick={() => {
            setMensajesDrawer({ visible: false, content: "", item: null });
          }}
        >
          Cancelar
        </Button>
      </div>
    </Form>
  );
};
