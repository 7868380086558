import MainLayout from "../ui/Layout/MainLayout";
import { Campaigns } from "../ui/Layout/campaigns/Campaigns";

export const CampaignsView = ({tab}) => {
  return (
    <MainLayout>
      <Campaigns tab={tab}/>
    </MainLayout>
  );
};
