import {
  IoAlertCircleOutline,
  IoArrowUndoOutline,
  IoCheckmark,
  IoCheckmarkDone,
  IoHelpCircleOutline,
  IoPeopleOutline
} from 'react-icons/io5';
import LoadingIcon from '../../../Loader/LoadingIcon';

const ICON_SIZE = 20;

export const CampaignStatCard = ({
  stat,
  data,
  extra,
  loading,
  isInteractive,
  onClick,
  isSelected
}) => {
  const getIcon = () => {
    switch (stat) {
      case 'recipients':
        return <IoPeopleOutline color='midnightblue' size={ICON_SIZE} />;
      case 'sent':
        return <IoCheckmark color='gray' size={ICON_SIZE} />;
      case 'delivered':
        return <IoCheckmarkDone color='gray' size={ICON_SIZE} />;
      case 'read':
        return <IoCheckmarkDone color='deepskyblue' size={ICON_SIZE} />;
      case 'replied':
        return <IoArrowUndoOutline color='seagreen' size={ICON_SIZE} />;
      case 'failed':
        return <IoAlertCircleOutline color='darkred' size={ICON_SIZE} />;
      default:
        return <IoHelpCircleOutline size={ICON_SIZE} />;
    }
  };

  const getTitle = () => {
    switch (stat) {
      case 'recipients':
        return 'Destinatarios';
      case 'sent':
        return 'Enviados';
      case 'delivered':
        return 'Entregados';
      case 'read':
        return 'Leídos';
      case 'replied':
        return 'Respondidos';
      case 'failed':
        return 'Fallidos';
      default:
        return 'Título';
    }
  };

  return (
    <div
      className={`CampaignStatCard ${isInteractive && 'interactive'} ${
        isSelected && 'selected'
      }`}
      onClick={onClick}
    >
      {loading ? (
        <span
          style={{
            height: '100%',
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <>
          <div className='campaign-stat-card-header'>
            <span className='campaign-stat-card-title'>{getTitle()}</span>
            <span>{getIcon()}</span>
          </div>

          <div className='campaign-stat-card-content'>
            <span className='campaign-stat-card-value'>{data || 0}</span>
            {stat !== 'recipients' ? (
              <span className='campaign-stat-card-extra'>{extra}</span>
            ) : (
              <span className='campaign-stat-card-extra'>
                Total de contactos receptores
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
