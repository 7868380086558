import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Checkbox, Select } from "antd";
import "./contactsFilters.css";
import { LineaGris } from "../linea-gris/LineaGris";
import { getAllClients } from "../../../helpers/fetchData/fetchClients";
import { Tag } from "../tag/Tag";
import { filterLabelOptions } from "../../../utils/filterLabelOptions";
import { BoxInfo } from "../box-info/BoxInfo";
import { SelectContactos } from "../selectContactos/SelectContactos";

const filterTags = (value, option) =>
  option?.name?.toLowerCase()?.includes(value.toLowerCase());

const MAX_TAG_COUNT = 5;

export const ContactsFilters = ({ filters, setFilters }) => {
  const { instance, etiquetas } = useContext(GlobalContext);

  const [clientes, setClientes] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleValues = (key, newValue) => {
    // Quitar 'TODOS'

    if (Array.isArray(newValue) && newValue?.includes("TODOS")) {
      newValue = newValue.filter((element) => element !== "TODOS");
    }

    if (key) {
      setFilters((prevState) => {
        let object = { ...prevState };

        object[key] = newValue;

        return object;
      });
    }
  };

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);

      const apiKey = instance.userData.apiKey;

      getAllClients(apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setClientes(
              res.data.map((element) => {
                return {
                  value: element._id,
                  label: element.name,
                };
              })
            );
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [instance]);

  // const contactsOptions = useMemo(() => {
  //   if (contactos?.length > 0) {
  //     return contactos.map((element) => {
  //       return {
  //         value: element._id,
  //         label: getContactName(element.name),
  //       };
  //     });
  //   }

  //   return [];
  // }, [contactos]);

  const tagsOptions = useMemo(() => {
    if (etiquetas?.length > 0) {
      return etiquetas.map((element) => {
        return {
          value: element._id,
          name: element.name,
          label: <Tag hex={element.color} nombre={element.name} />,
        };
      });
    }

    return [];
  }, [etiquetas]);

  const handleSelectAll = (v, type) => {
    if (!type || !["clients", "contacts", "tags"].includes(type)) {
      return;
    }

    if (v.target.checked) {
      // true
      setFilters((prevState) => {
        let obj = { ...prevState };

        obj[type] = ["TODOS"];

        return obj;
      });
    } else {
      //false
      setFilters((prevState) => {
        let obj = { ...prevState };

        obj[type] = [];

        return obj;
      });
    }
  };

  return (
    <div className="columna">
      <div>
        <span className="horizontal-between">
          <b>Incluidos</b>
        </span>
      </div>
      <div className="columna" style={{ margin: "8px 0px" }}>
        <span className="horizontal-between">
          <p>Contactos</p>
          <Checkbox
            checked={filters?.contacts === "TODOS"}
            onChange={(v) => handleSelectAll(v, "contacts")}
          >
            Seleccionar todos
          </Checkbox>
        </span>
        <SelectContactos
          value={filters?.contacts}
          setValue={(v) => handleValues("contacts", v)}
          mode="multiple"
        />
      </div>
      <div className="columna" style={{ margin: "8px 0px" }}>
        <span className="horizontal-between">
          <span className="horizontal">
            <p>Cuentas</p>
            <BoxInfo
              message={
                "Agregará todos los contactos asociados a cada cuenta seleccionada"
              }
              hideMode={true}
            />
          </span>
          <Checkbox
            checked={!loading && filters?.clients === "TODOS"}
            onChange={(v) => handleSelectAll(v, "clients")}
          >
            Seleccionar todos
          </Checkbox>
        </span>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          maxTagCount={MAX_TAG_COUNT}
          placeholder="Seleccione clientes"
          value={loading ? [] : filters?.clients}
          onChange={(v) => handleValues("clients", v)}
          options={clientes}
          filterOption={filterLabelOptions}
          showSearch
          loading={loading}
        />
      </div>

      <div className="columna" style={{ margin: "8px 0px" }}>
        <span className="horizontal-between">
          <span className="horizontal">
            <p>Etiquetas</p>
            <BoxInfo
              message={
                "Agregará todos los contactos asociados a cada etiqueta seleccionada"
              }
              hideMode={true}
            />
          </span>
          <Checkbox
            checked={filters?.tags === "TODOS"}
            onChange={(v) => handleSelectAll(v, "tags")}
          >
            Seleccionar todos
          </Checkbox>
        </span>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          maxTagCount={MAX_TAG_COUNT}
          placeholder="Seleccione etiquetas"
          value={filters?.tags}
          onChange={(v) => handleValues("tags", v)}
          options={tagsOptions}
          filterOption={filterTags}
          showSearch
        />
      </div>
      <LineaGris />

      <div className="columna">
        <b>Excluidos</b>
        <div className="columna" style={{ margin: "8px 0px" }}>
          <p>Contactos</p>
          <SelectContactos
            value={filters?.excludedContacts}
            setValue={(v) => handleValues("excludedContacts", v)}
            mode="multiple"
          />
        </div>
        <div className="columna" style={{ margin: "8px 0px" }}>
          <p>Clientes</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            maxTagCount={MAX_TAG_COUNT}
            placeholder="Seleccione clientes"
            value={filters?.excludedClients}
            onChange={(v) => handleValues("excludedClients", v)}
            options={clientes}
            filterOption={filterLabelOptions}
            loading={loading}
            showSearch
          />
        </div>

        <div className="columna" style={{ margin: "8px 0px" }}>
          <p>Etiquetas</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            maxTagCount={MAX_TAG_COUNT}
            placeholder="Seleccione etiquetas"
            value={filters?.excludedTags}
            onChange={(v) => handleValues("excludedTags", v)}
            options={tagsOptions}
            filterOption={filterTags}
            showSearch
          />
        </div>
      </div>
      <LineaGris />
    </div>
  );
};
