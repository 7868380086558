import { Button, Form, Input, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { editStatus } from "../../../../../../../helpers/fetchData/fetchBudgetStatuses";

export const EditStatus = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const {
    catalogDetail,
    setCatalogDetail,
    selectedCatalog,
    configDrawerContent,
    setConfigDrawerContent,
  } = useContext(SettingsContext);

  const [loading, setLoading] = useState(false);

  const [editable, setEditable] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (v) => {
    v.name = v.name?.trim() || "";

    if (v.name.length < 3) {
      return messageApi.info(
        "Es necesario que el nombre contenga al menos 3 caracteres"
      );
    }

    setLoading(true);

    const data = {
      name: v.name,
      description: v.description || "",
      editable: editable,
    };

    editStatus(instance.userData.apiKey, configDrawerContent.item?._id, data)
      .then((res) => {
        if (res?.status === 200) {
          setCatalogDetail((prevState) => {
            let object = { ...prevState };

            let array = object.statuses?.map((element) => {
              if (element._id === configDrawerContent.item?._id) {
                return {
                  ...element,
                  ...data,
                };
              } else {
                return element;
              }
            });

            object.statuses = array;

            return object;
          });

          setConfigDrawerContent({ visible: false, content: "", item: null });
        } else {
          messageApi.error("Ocurrió un error al editar un estado");
        }
      })
      .catch((error) => {
        messageApi.error("Ocurrió un error al editar un estado");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (configDrawerContent?.item) {
      const { item } = configDrawerContent;
      form.setFieldsValue({
        name: item?.name,
        description: item?.description,
      });

      if (item?.editable != null) {
        setEditable(item.editable);
      }
    }
  }, [configDrawerContent]);

  return (
    <Form
      form={form}
      name="updateConjunto"
      layout="vertical"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Editar estado</p>
      <Form.Item name="name" label="Nombre">
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Descripción">
        <Input.TextArea autoSize={{ minRows: 2 }} />
      </Form.Item>
      <div className="horizontal">
        <p>Permitir editar</p>
        <Switch checked={editable} onChange={(v) => setEditable(v)} />
      </div>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            className="btn-guardar"
            size="large"
            htmlType="submit"
            loading={loading}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setConfigDrawerContent({
                visible: false,
                content: "",
                item: null,
              });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
