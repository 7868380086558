import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LoginOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { formatPhoneNumber } from '../../rightDrawer/utils/formatPhoneNumber';
import LoadingIcon from '../../../Loader/LoadingIcon';

const PAGE_SIZE = 8;

export const CampaignDataRecipientsTable = ({
  filter,
  data,
  loading,
  campaignData
}) => {
  const getStatusProps = (status) => {
    switch (status) {
      case 'sent':
        return {
          children: 'Enviado',
          color: 'cyan',
          icon: <SendOutlined />
        };
      case 'delivered':
        return {
          children: 'Entregado',
          color: 'blue',
          icon: <LoginOutlined />
        };
      case 'read':
        return {
          children: 'Leído',
          color: 'success',
          icon: <CheckCircleOutlined />
        };
      case 'failed':
        return {
          children: 'Fallido',
          color: 'error',
          icon: <ExclamationCircleOutlined />
        };
      default:
        return {
          children: 'Pendiente',
          color: 'default',
          icon: <ClockCircleOutlined />
        };
    }
  };

  const getLastItem = (items) => {
    const result = items?.sort((a, b) => b.timestamp - a.timestamp)[0];

    return result;
  };

  const recipientsColumns = [
    {
      title: 'Destinatario',
      key: 'name',
      dataIndex: 'name',
      render: (text) => (text ? text : '-')
    },
    {
      title: 'Teléfono',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      align: 'center',
      render: (text) => formatPhoneNumber(text)
    },
    {
      title: 'Estado de envío',
      key: 'messageStatuses',
      dataIndex: 'messageStatuses',
      align: 'center',
      render: (text) => {
        const lastStatus = getLastItem(text);

        return <Tag {...getStatusProps(lastStatus?.status)} />;
      }
    }
  ];

  if (campaignData?.hasButtons) {
    recipientsColumns.push({
      title: 'Respuesta',
      key: 'responses',
      dataIndex: 'responses',
      align: 'center',
      render: (text) => {
        const lastReply = getLastItem(text);

        return lastReply?.selectedButton?.text ? (
          <Tag>{lastReply.selectedButton.text}</Tag>
        ) : (
          '-'
        );
      }
    });
  }

  const filteredRecipientsData = data.filter((recipient) => {
    switch (filter) {
      case 'sent':
        return recipient.messageStatuses.some((ms) => ms.status === 'sent');
      case 'delivered':
        return recipient.messageStatuses.some(
          (ms) => ms.status === 'delivered'
        );
      case 'read':
        return recipient.messageStatuses.some((ms) => ms.status === 'read');
      case 'replied':
        return recipient.responses?.length > 0;
      case 'failed':
        return recipient.messageStatuses.some((ms) => ms.status === 'failed');
      default:
        return true;
    }
  });

  return (
    <Table
      columns={recipientsColumns}
      dataSource={filteredRecipientsData || []}
      rowKey='_id'
      size='small'
      pagination={{
        pageSize: PAGE_SIZE,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} destinatarios`
      }}
      className='tabla-generica'
      style={{ maxWidth: '100%' }}
      loading={{
        spinning: loading,
        indicator: <LoadingIcon size='large' />
      }}
    />
  );
};
