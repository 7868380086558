export const validateListContent = (listContent, messageApi) => {
  let error = false;

  if (!listContent) {
    messageApi.info("Se necesita un título para el boton de la lista");
    error = true;
    return error;
  }

  if (!listContent?.button) {
    messageApi.info("Se necesita un título para el boton de la lista");
    error = true;
    return error;
  }

  if (!listContent?.sections) {
    messageApi.info("No hay secciones");
    error = true;
    return error;
  }

  listContent?.sections?.forEach((section, index) => {

    //* Máximo 10 secciones y 10 filas por sección
    // Si tiene mas de una seccion, todas necesitan un título
    if (listContent.sections.length > 1 && !section?.title) {
      messageApi.info("Cada sección necesita un título");
      error = true;
      return true;
    }

    if (section?.rows?.length < 1) {
      messageApi.info("Cada sección necesita contenido");
      error = true;
      return true;
    }

    if (section.rows.some((element) => !element.title)) {
      messageApi.info("Cada item necesita un título");
      error = true;
      return true;
    }

    return false;
  });

  return error;
};
