import { SinInstancia } from "./SinInstancia";

export const Instance = () => {

  return (
    <div className="instance-main-container">
      <SinInstancia />
    </div>
  );
};
