import { Tag } from 'antd';
import { ImWhatsapp } from 'react-icons/im';
import { FlowsAcciones } from '../FlowsAcciones';
import { getCompletedPercentage } from './getCompletedPercentage';
import { CardBotQRPreview } from '../cardBot/CardBotQRPreview';
import LoadingIcon from '../../../Loader/LoadingIcon';

export const columnsFlows = (
  navigate,
  setFlows,
  setLoading,
  qrCodes,
  setQrCodes,
  flowLogs,
  loadingLogs
) => {
  return [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (data, item) => {
        const qrCode = qrCodes?.find((qr) => qr.flowId === item._id);

        return (
          <span className='horizontal gap8'>
            <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={20} />
            {qrCode && (
              <CardBotQRPreview
                flow={item}
                qrCode={qrCode}
                setQrCodes={setQrCodes}
              />
            )}
            <span
              onClick={() => navigate(`/flows/edit?id=${item?._id}`)}
              style={{ cursor: 'pointer' }}
            >
              <b>{data}</b>
            </span>
          </span>
        );
      }
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (data) => (
        <span className='fila-triggers'>
          <Tag color={statusColor(data)}>
            {data === 'publish' ? 'Activo' : 'Borrador'}
          </Tag>
        </span>
      )
    },
    {
      title: 'Disparador/es',
      dataIndex: 'trigger',
      key: 'trigger',
      render: (data, item) => {
        return (
          <span className='fila-triggers'>
            {item.type === 'broadcast' ? (
              <Tag color='blue'>Campaña</Tag>
            ) : (
              data.map((trigger, index) => {
                return (
                  <Tag color='gold' key={index}>
                    {trigger}
                  </Tag>
                );
              })
            )}
          </span>
        );
      }
    },
    {
      title: 'Ejecuciones',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (_, item) => {
        const flowMetrics = flowLogs?.find((flow) => flow.flowId === item._id);

        return (
          <>
            {item.type !== 'broadcast' ? (
              <>
                {loadingLogs ? (
                  <span
                    style={{
                      height: 16,
                      width: '100%',
                      display: 'grid',
                      placeItems: 'center'
                    }}
                  >
                    <LoadingIcon size='large' />
                  </span>
                ) : (
                  <span>
                    {flowMetrics?.executionCount
                      ? flowMetrics.executionCount
                      : '-'}
                  </span>
                )}
              </>
            ) : (
              '-'
            )}
          </>
        );
      }
    },
    {
      title: '% de éxito',
      dataIndex: '_id',
      key: '_id',
      align: 'center',
      render: (_, item) => {
        const flowMetrics = flowLogs?.find((flow) => flow.flowId === item._id);

        return (
          <>
            {item.type !== 'broadcast' ? (
              <>
                {loadingLogs ? (
                  <span
                    style={{
                      height: 16,
                      width: '100%',
                      display: 'grid',
                      placeItems: 'center'
                    }}
                  >
                    <LoadingIcon size='large' />
                  </span>
                ) : (
                  <span>
                    {flowMetrics?.completedCount
                      ? `${getCompletedPercentage(flowMetrics)}% (${
                          flowMetrics.completedCount
                        })`
                      : '-'}
                  </span>
                )}
              </>
            ) : (
              '-'
            )}
          </>
        );
      }
    },
    {
      title: '...',
      align: 'center',
      dataIndex: '',
      key: '',
      width: '80px',
      render: (data, item) => (
        <FlowsAcciones
          item={item}
          setFlows={setFlows}
          setLoading={setLoading}
        />
      )
    }
  ];
};

const statusColor = (data) => {
  if (data) {
    switch (data) {
      case 'draft':
        return '';
      case 'publish':
        return 'green';
      default:
        return '';
    }
  } else {
    return '';
  }
};
