import { Spin } from 'antd';
import { LoadingOutlined  } from '@ant-design/icons';

const LoadingIcon = ({ size = 'default', color = '#77BE42' }) => {
  let iconSize;
  switch (size) {
    case 'mainLoader':
      iconSize = 64;
      break;
    case 'small':
      iconSize = 12;
      break;
    case 'large':
      iconSize = 24;
      break;
    case 'default':
    default:
      iconSize = 18;
  }

  const antIcon = <LoadingOutlined style={{ fontSize: iconSize, color: color }} spin />;

  return <Spin indicator={antIcon} />;
};

export default LoadingIcon;