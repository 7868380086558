import { useContext } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { Popover, Spin, Tooltip } from "antd";
import { renderAgent } from "../../utils/renderAgent";
import { renderClosingType, renderTicketType } from "../../utils/renderTags";
import { findUser, scrollTo } from "../../utils/chatFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import {
  MdOutlineFilePresent,
  MdOutlineStickyNote2,
  MdSchedule,
} from "react-icons/md";
import dayjs from "dayjs";
import LoadingIcon from '../../../../Loader/LoadingIcon';


export const TicketsTab = ({
  tickets,
  setSelectedTicket,
  setSelectedDate,
  ticketTypes,
  closingTypes,
  handleTabChange,
  loading,
}) => {
  const sortedTickets = tickets
    ?.slice()
    .sort((a, b) => b.createdAt - a.createdAt);

  return (
    <div className="extras-section">
      <Spin spinning={loading} indicator={<LoadingIcon size="default" />}>
        <span className="extras-section-content fade-in">
          {sortedTickets && sortedTickets.length > 0 ? (
            sortedTickets.map((ticket) => (
              <Ticket
                key={ticket._id}
                ticket={ticket}
                setSelectedTicket={setSelectedTicket}
                setSelectedDate={setSelectedDate}
                ticketTypes={ticketTypes}
                closingTypes={closingTypes}
                handleTabChange={handleTabChange}
              />
            ))
          ) : (
            <span className="extras-no-hay">No hay tickets</span>
          )}
        </span>
      </Spin>
    </div>
  );
};

const Ticket = ({
  ticket,
  setSelectedTicket,
  setSelectedDate,
  ticketTypes,
  closingTypes,
  handleTabChange,
}) => {
  let otherAgents = [];
  ticket.messages?.forEach((message) => {
    if (message.systemType === "reassign") {
      otherAgents.push(message.userFrom);
    }
  });

  const { usuarios } = useContext(GlobalContext);

  const types = [ticket.ticketType, ticket.closingType];

  const duracionTicket = dayjs(ticket.closeDate, "x").diff(
    dayjs(ticket.createdAt, "x"),
    "minutes"
  );

  function getOtherAgentsNames() {
    let names = [];

    if (otherAgents.length > 0) {
      otherAgents.forEach((agent) => {
        names.push(findUser(agent, usuarios)?.name);
      });
    }

    return names;
  }

  return (
    <div className="Ticket" onClick={() => scrollTo(ticket._id)}>
      <div className="ticket-tags-container">
        {renderTicketType(types[0], ticketTypes)}
        {renderClosingType(types[1], closingTypes)}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="ticket-actions-container">
          <div>
            <Tooltip title="Media">
              <span>
                <MdOutlineFilePresent
                  size={20}
                  onClick={() => {
                    setSelectedTicket(ticket);
                    setSelectedDate(null);
                    handleTabChange("archivos");
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Notas">
              <span>
                <MdOutlineStickyNote2
                  size={20}
                  onClick={() => {
                    setSelectedTicket(ticket);
                    setSelectedDate(null);
                    handleTabChange("notas");
                  }}
                />
              </span>
            </Tooltip>
          </div>

          <div className="ticket-date-container">
            <Tooltip
              title={
                ticket.closeDate
                  ? `${dayjs(ticket.createdAt, "x").format(
                    "DD/MM/YYYY HH:mm"
                  )} - ${dayjs(ticket.closeDate, "x").format(
                    "DD/MM/YYYY HH:mm"
                  )}`
                  : `${dayjs(ticket.createdAt, "x").format("DD/MM/YYYY HH:mm")}`
              }
            >
              <span className="ticket-dateTime">
                {dayjs(ticket.createdAt, "x").format("DD/MM/YYYY")}
              </span>
            </Tooltip>
            <MdSchedule size={20} />
            <span>
              {ticket.closeDate
                ? `${duracionTicket === 0 ? "1" : duracionTicket} min`
                : "--"}
            </span>
          </div>

          <Popover
            content={
              <>
                <span>{findUser(ticket.userId, usuarios)?.name}</span>
                {otherAgents.length > 0
                  ? getOtherAgentsNames().map((name, index) => (
                    <div key={index}>
                      <br />
                      <span key={index}>{name}</span>
                    </div>
                  ))
                  : ""}
              </>
            }
          >
            {renderAgent(usuarios, ticket.userId, otherAgents)}
          </Popover>
        </div>
      </div>
    </div>
  );
};
