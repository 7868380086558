import {
  Button,
  Popover,
  Table,
  Row,
  Col,
  Checkbox,
  Input,
  Form,
  Radio,
  Space,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import "./tablaClientes.css";
import { MenuOutlined } from "@ant-design/icons";
import { TbTrash } from "react-icons/tb";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./formConfigTabla.css";
import { postTabla } from "./fetchData/tablas/postTabla";
import { deleteTabla } from "./fetchData/tablas/deleteTabla";
import { putTabla } from "./fetchData/tablas/putTabla";
import LoadingIcon from "../../Loader/LoadingIcon";

//Draggable table (Modal)
const columnsDraggable = [
  {
    key: "sort",
  },
  {
    title: "",
    dataIndex: "title",
    render: (item, record, index) => (
      <>
        {index + 1}º - {item}
      </>
    ),
  },
];

const RowDragg = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

function FormConfigTabla({
  editarTablaValues,
  setTablaSelecc,
  setDataColumns,
  dataColumns,
  setDrawerConfigTabla,
  action,
  setIdTablaSelecc,
  setRefetchClientes,
}) {
  const { messageApi, instance } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [showError, setShowError] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const [loading, setLoading] = useState(false);

  const [baseCheckedItems, setBaseCheckedItems] = useState(
    editarTablaValues?.columnas
      ?.filter((item) => !item.hidden)
      ?.map((item) => item.key)
  );

  useEffect(() => {
    //console.log('action en form:', action)
    if (editarTablaValues && action === "editar") {
      form.setFieldsValue({
        nombreTabla: editarTablaValues?.nombreTabla || "",
        checkedColumns: editarTablaValues?.columnas
          ?.filter((item) => !item.hidden)
          ?.map((item) => item.key),
        fixedCols: editarTablaValues?.fixedOption,
      });
    }
  }, []);

  const handleIndexFix = async (v) => {
    await setTablaSelecc((prevState) => {
      let array = { ...prevState };

      for (let index = 0; index < array.columnas.length; index++) {
        array.columnas[index].index = index; // Asignacion de nuevo indice. En la tabla que estamos parados.en sus columnas[para cada columa].en su campo 'index' = nuevo indice.

        array.columnas[index].fixed = ""; // Se ponen todos los valores de las columnas en su propiedad fixed como vacias (sin fijar).
      }

      // Fijar columna/s. Se asignan los nuevos valores a la propiedad fixed segun la opcion seleccionada.
      switch (
        editarTablaValues.columnas?.filter((item) => !item.hidden).length > 4
          ? v.fixedCols
          : 0
      ) {
        case 1:
          for (let index = 0; index < array.columnas.length; index++) {
            if (index == 0) {
              array.columnas[index].fixed = "left";
            }
          }
          // console.log("Fijar primera");
          break;
        case 2:
          for (let index = 0; index < array.columnas.length; index++) {
            if (index == 0 || index == 1) {
              array.columnas[index].fixed = "left";
            }
          }
          // console.log("Fijar primera y segunda");
          break;
        case 3:
          for (let index = 0; index < array.columnas.length; index++) {
            if (index == 0) {
              array.columnas[index].fixed = "left";
            }
            if (index == editarTablaValues.columnas?.length - 1) {
              array.columnas[index].fixed = "right";
            }
          }
          // console.log("Fijar primera y ultima");
          break;
        case 4:
          for (let index = 0; index < array.columnas.length; index++) {
            array.columnas[index].fixed = "";
          }
          // console.log("Ninguna");
          break;
        default:
          break;
      }

      return array;
    });
  };

  //Checkbox columns
  const onChange = (checkedColumns) => {
    //console.log('onChange checkbox group', checkedColumns);

    // Si no hay ninguna columna checked, no sigue la ejecucion. Aca podemos establecer un minimo de cols seleccionadas requeridas luego, en conjunto con el rules del form.item.
    if (checkedColumns.length < 1) {
      return;
    }

    // Se ponen todas las columnas ocultas para luego mostrarse solo las seleccionadas.
    setTablaSelecc((prevState) => {
      let array = { ...prevState };

      for (let index = 0; index < array?.columnas?.length; index++) {
        array.columnas[index].hidden = true;
      }

      return array;
    });

    // Se realiza la busqueda de las columnas a mostrar y se ponen a la vista.
    setTablaSelecc((prevState) => {
      let array = { ...prevState };
      let columnas = [...array.columnas];

      for (const checkedCol of checkedColumns) {
        let result = editarTablaValues.columnas?.find(
          (col) => col.key === checkedCol
        );
        if (result) {
          let indexResult = editarTablaValues.columnas.indexOf(result);
          columnas[indexResult].hidden = false;
        }
      }

      return array;
    });

    //Solucion provisional al problema que surge al ocultar una columna, fijar, y que el orden afecte de manera negativa al fijado, ya que podemos ocultar la primer columna, seguiria manteniendo el primer indice, y al querer fijar la visible siguiente, enrealidad fijamos la oculta ya que sigue con el primer indice, y el orden debe cambiar sino se rompe la tabla. Por lo tanto envio las columnas ocultas a la penultima posicion en el array, para permitir fijar la primera, o primera y segunda o primera y ultima columna sin problema.

    //Se comparan los baseCheckedItems con checkedColumns actuales para obtener cual fue la ultima columna clicked, ya sea para mostrar u ocultar, da igual. Luego actualizamos la base con la nueva data.

    //Obtengo el id de la ultima columna clicked.
    let clickedCheck = 0;

    if (baseCheckedItems.length > checkedColumns.length) {
      clickedCheck = baseCheckedItems.filter(
        (x) => !checkedColumns.includes(x)
      );
    } else {
      clickedCheck = checkedColumns.filter(
        (x) => !baseCheckedItems.includes(x)
      );
    }

    //Se actualiza el valor base por los nuevos, asi en cada click que hago en un checkbox. mando la columna a la penultima posicion.
    setBaseCheckedItems(checkedColumns);

    //Armo manualmente las columnas visibles y ocultas en ESTE momento.
    //Visibles
    let vc = editarTablaValues.columnas.filter((col) =>
      checkedColumns.includes(col.key)
    );
    //No visibles
    let hc = editarTablaValues.columnas.filter(
      (col) => !checkedColumns.includes(col.key)
    );

    let active = {
      id: clickedCheck[0],
    };

    let over = {
      id: editarTablaValues?.columnas[editarTablaValues?.columnas?.length - 2]
        .key,
    };

    changeOrder(active, over, vc, hc);
  };

  const changeOrder = (active, over, visibleCols, hiddenCols) => {
    if (active.id !== over?.id) {
      //Dentro del array de arrays, obtenemos el array de objetos a modificar
      let tableColumns = visibleCols;

      const activeIndex = tableColumns?.findIndex((i) => i.key === active.id);
      const overIndex = tableColumns?.findIndex((i) => i.key === over?.id);

      tableColumns = arrayMove(tableColumns, activeIndex, overIndex);

      let hiddenColumns = hiddenCols;

      for (const hideCol of hiddenColumns) {
        tableColumns.splice(tableColumns.length - 1, 0, hideCol);
      }

      setTablaSelecc((prevState) => {
        let array = { ...prevState };
        let columnas = [...tableColumns];
        array.columnas = columnas;

        return array;
      });
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      //Dentro del array de arrays, obtenemos el array de objetos a modificar
      let tableColumns = editarTablaValues?.columnas
        ?.filter((item) => !item.hidden)
        .map((item) => item);

      //Lo modificamos de acuerdo a la logica de abajo (activeIndex y overIndex,etc)
      const activeIndex = tableColumns?.findIndex((i) => i.key === active.id);
      const overIndex = tableColumns?.findIndex((i) => i.key === over?.id);

      //Hacemos el setDataColumns en el array modificado de acuerdo al idTablaSelecc.
      tableColumns = arrayMove(tableColumns, activeIndex, overIndex);

      //En base a este array de columnas filtrado, que tiene el orden deseado, debemos agregar en su penultimo indice, las columnas ocultas, para siempre tener la primer, segunda y ultima columna visible (las posibles opciones del fijado), en dichas posiciones.
      let hiddenColumns = editarTablaValues.columnas
        ?.filter((item) => item.hidden)
        .map((item) => item);

      for (const hideCol of hiddenColumns) {
        tableColumns.splice(tableColumns.length - 1, 0, hideCol);
      }

      //Asigno el nuevo orden de columnas modificado(en este punto, arrastrando y soltando, aun NO se ha modificado el valor del indice de cada columna, solo se modifica su orden de aparicion visualmente para guiar al usuario de como quedaria).
      setTablaSelecc((prevState) => {
        let array = { ...prevState };
        let columnas = [...tableColumns];
        array.columnas = columnas;

        return array;
      });
    }
  };

  //Funcion que crea una NUEVA tabla
  const crearTabla = async (v) => {
    //console.log('Intentando crear', v)

    // Se encarga de asignar los nuevos indices modificados a cada columna y el fijado de columnas.
    await handleIndexFix(v);
    if (instance?._id) {
      setLoading(true);
      let data = {
        nombreTabla: v.nombreTabla,
        fixedOption: v.fixedCols ? v.fixedCols : 4,
        columnas: editarTablaValues.columnas,
      };
      // console.log('post API', data)
      postTabla(data, instance)
        .then((res) => {
          // console.log('res', res)
          if (res?.status === 201) {
            // console.log(res?.data);
            messageApi.success("Tabla creada correctamente");

            let nuevaTabla = {
              _id: res?.data.new_id,
              key: res?.data.new_id, // Unique key para React
              ...data,
            };

            setDataColumns((prevState) => {
              return [...prevState, nuevaTabla];
            });

            setRefetchClientes((prevState) => {
              return !prevState;
            });

            //Setea tab activa debido a la nueva tabla
            setIdTablaSelecc(res?.data.new_id);

            setDrawerConfigTabla(false);
          } else {
            messageApi.error("Ocurrió un error al crear tabla");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al crear tabla");
        })
        .finally(() => setLoading(false));
    }
  };

  //Funcion que EDITA una tabla
  const editarTabla = async (v) => {
    // console.log('Intentando editar', v)

    // Metodo que se encarga de asignar los nuevos indices modificados a cada columna y el fijado de columnas.
    await handleIndexFix(v);

    if (instance?._id) {
      setLoading(true);

      let data = {
        _id: editarTablaValues._id,
        columnas: editarTablaValues.columnas,
        nombreTabla: v.nombreTabla,
        fixedOption:
          editarTablaValues.columnas?.filter((item) => !item.hidden).length > 4
            ? v.fixedCols
            : 4,
      };

      putTabla(data, instance)
        .then((res) => {
          // console.log('res', res)
          if (res?.status === 200) {
            // console.log(res?.data);
            messageApi.success("Tabla editada correctamente");

            setDataColumns((prevState) => {
              let array = [...prevState];
              // console.log('data setState', editarTablaValues)
              let indexTabla = array.findIndex(
                (i) => i._id == editarTablaValues._id
              );

              array[indexTabla] = editarTablaValues; //Tabla entera
              array[indexTabla].nombreTabla = v.nombreTabla; //Nombre
              array[indexTabla].fixedOption =
                editarTablaValues.columnas?.filter((item) => !item.hidden)
                  .length > 4
                  ? v.fixedCols
                  : 4; //fixedOption
              return array;
            });

            setDrawerConfigTabla(false);
          } else {
            messageApi.error("Ocurrió un error al editar tabla");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al editar tabla");
        })
        .finally(() => setLoading(false));
    }
  };

  const eliminarTabla = async () => {
    // console.log('Eliminar tabla', editarTablaValues);

    if (dataColumns.length == 1) {
      messageApi.warning("Debe poseer al menos una tabla");
      return;
    }

    if (instance?._id) {
      deleteTabla(editarTablaValues._id, instance)
        .then((res) => {
          // console.log('res', res)
          if (res?.status === 200) {
            // console.log(res?.data);
            messageApi.success("Tabla eliminada correctamente");

            setDataColumns((prevState) => {
              let array = [...prevState];
              let indexTabla = array.findIndex(
                (i) => i._id == editarTablaValues._id
              );
              array.splice(indexTabla, 1);

              //Setea tab activa debido a la eliminacion de la actual,
              setIdTablaSelecc(array.length > 0 ? array[0]._id.toString() : "");
              return array;
            });

            setDrawerConfigTabla(false);
          } else {
            messageApi.error("Ocurrió un error al eliminar tabla");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al eliminar tabla");
        })
        .finally(() => setLoading(false));
    }

    // setDataColumns(prevState => {

    //     let array = [...prevState]
    //     let indexTabla = array.findIndex((i) => i.idTabla == editarTablaValues.idTabla);
    //     array.splice(indexTabla, 1);

    //     //Setea tab activa debido a la eliminacion de la actual,
    //     setIdTablaSelecc(array.length > 0 ? (array[0].idTabla).toString() : '');
    //     return array
    // });

    //setIdTablaSelecc(key);
    // messageApi.success("Tabla eliminada correctamente");
  };

  const onChangeFixed = (e) => {
    //setRadioGrValue()
  };

  // console.log('editarTablaValues', editarTablaValues);
  // console.log('action', action);

  return (
    <Form
      form={form}
      name="formConfigTable"
      layout="vertical"
      onFinish={
        editarTablaValues && action === "editar" ? editarTabla : crearTabla
      }
      className="form-configTabla"
    >
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Configuración de tabla
        </p>{" "}
        {/* Esto puede variar con titulos de 'Nueva tabla' o 'Editar tabla'. */}
        <Popover
          open={showPopover}
          content={
            <div className="columna">
              <p>¿Está seguro de borrar esta tabla?</p>
              <span className="horizontal">
                <Button
                  type="primary"
                  className="btn-borrar"
                  onClick={() => eliminarTabla()}
                  //loading={loading}
                  icon={
                    loading ? (
                      <LoadingIcon size="small" color="#FFFFFF" />
                    ) : null
                  }
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  type="secondary"
                  className="btn-cancelar"
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </span>
            </div>
          }
        >
          <span>
            <Tooltip title="Eliminar">
              <span>
                <TbTrash
                  size={16}
                  onClick={() => setShowPopover(true)}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Tooltip>
          </span>
        </Popover>
      </Row>

      <Form.Item
        label="Nombre"
        name="nombreTabla"
        rules={[
          {
            required: true,
            message: "Campo requerido",
          },
        ]}
        //className="half-50"
      >
        <Input name="nombreTabla" />
      </Form.Item>

      <Row
        style={
          showError
            ? { border: "1px solid #ff4d4f" }
            : { border: "1px solid #d9d9d9" }
        }
        className="form-columnsItem"
      >
        <Col xs={10} sm={10} md={10}>
          <Row>
            <div className="form-label-columns">Columnas disponibles</div>
          </Row>
          <Row className="form-col-list-contenedor">
            <Form.Item
              //label="Nombre"
              name="checkedColumns"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: (_, value) => {
                    //console.log('custom validator', value)
                    if (value && value.length > 0) {
                      setShowError(false);
                      return Promise.resolve();
                    } else {
                      setShowError(true);
                      return Promise.reject();
                    }
                  },
                },
              ]}
            >
              <Checkbox.Group onChange={onChange}>
                {editarTablaValues.columnas
                  ?.map((column, i) => (
                    <Col
                      span={12}
                      key={column.key}
                      className="form-chk-col-list"
                    >
                      <Checkbox value={column.key}>
                        <div style={{ fontSize: "13px" }}>{column.title}</div>
                      </Checkbox>
                    </Col>
                  ))
                  .sort((a, b) => a?.key - b?.key)}

                {/* Para ordenar de manera alfabetica las columnas disponibles, lo vamos a hacer desde el backend. */}
              </Checkbox.Group>
            </Form.Item>
          </Row>
        </Col>

        <Col xs={10} sm={10} md={10}>
          <Row>
            <div className="form-label-columns">
              Columnas seleccionadas (
              {
                editarTablaValues.columnas?.filter((item) => !item.hidden)
                  .length
              }
              )
            </div>
          </Row>
          <Row className="form-col-list-contenedor">
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={editarTablaValues.columnas
                  ?.filter((item) => !item.hidden)
                  .map((item) => item.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  className="draggable-table-tablaClientes"
                  components={{
                    body: {
                      row: RowDragg,
                    },
                  }}
                  rowKey="key"
                  columns={columnsDraggable}
                  dataSource={editarTablaValues.columnas?.filter(
                    (item) => !item.hidden
                  )}
                  size="small"
                  pagination={false}
                />
              </SortableContext>
            </DndContext>
          </Row>
        </Col>
      </Row>

      {showError ? (
        <p className="form-col-list-alert">Seleccione al menos una columna</p>
      ) : (
        ""
      )}

      {editarTablaValues.columnas?.filter((item) => !item.hidden).length > 4 ? (
        <Row
          style={{ border: "1px solid #d9d9d9" }}
          className="form-columnsItem"
        >
          <Col xs={24} sm={24} md={24}>
            <Row>
              <div className="form-label-fijar">Fijar columnas:</div>
            </Row>
            <Row className="form-col-fijar-contenedor">
              <Form.Item
                name="fixedCols"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChangeFixed}
                  optionType="button"
                  id="rgFixed"
                >
                  <Space direction="vertical">
                    <Radio value={1} className="form-col-fijar-options">
                      {editarTablaValues.columnas
                        ?.filter((item) => !item.hidden)
                        .map((column, i) => (
                          <Space
                            key={column.key}
                            direction="horizontal"
                            className={
                              i == "0"
                                ? "form-columnsItem-fixed"
                                : "form-columnsItem-noFixed"
                            }
                          >
                            {column.title}
                          </Space>
                        ))
                        .sort((a, b) => a?.index - b?.index)}
                    </Radio>

                    <Radio value={2} className="form-col-fijar-options">
                      {editarTablaValues.columnas
                        ?.filter((item) => !item.hidden)
                        .map((column, i) => (
                          <Space
                            key={column.key}
                            direction="horizontal"
                            className={
                              i == "0" || i == "1"
                                ? "form-columnsItem-fixed"
                                : "form-columnsItem-noFixed"
                            }
                          >
                            {column.title}
                          </Space>
                        ))
                        .sort((a, b) => a?.index - b?.index)}
                    </Radio>

                    <Radio value={3} className="form-col-fijar-options">
                      {editarTablaValues.columnas
                        ?.filter((item) => !item.hidden)
                        .map((column, i) => (
                          <Space
                            key={column.key}
                            direction="horizontal"
                            className={
                              i == "0" ||
                              i ==
                                editarTablaValues.columnas?.filter(
                                  (item) => !item.hidden
                                ).length -
                                  1
                                ? "form-columnsItem-fixed"
                                : "form-columnsItem-noFixed"
                            }
                          >
                            {column.title}
                          </Space>
                        ))
                        .sort((a, b) => a?.index - b?.index)}
                    </Radio>

                    <Radio value={4}>Ninguna</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Row>

            <Row>
              <div
                className="form-columnsItem-fixed"
                style={{ paddingLeft: "24px" }}
              >
                *columnas fijas
              </div>
            </Row>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loading}
            icon={
              loading ? <LoadingIcon size="default" color="#FFFFFF" /> : null
            }
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => setDrawerConfigTabla(false)}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default FormConfigTabla;
