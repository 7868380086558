import axios from "axios";

export const getRoles = async (key) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/role`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createRole = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/newRole`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateRole = async (key, data) => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/editRole`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createTeam = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/teams/createTeam`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const manageTeam = async (key, data) => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_PRODUCCION}/teams/manageTeam`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getTeams = async (key) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PRODUCCION}/teams/getTeams`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
