export const isSelectedClass = (selected) => {
  if (selected) {
    return "each-node-wrapper selected";
  } else {
    return "each-node-wrapper";
  }
};

export const isSelected = (node, nodoSeleccionado) => {
  if (nodoSeleccionado?.id === node.id) {
    return true;
  } else {
    return false;
  }
};
