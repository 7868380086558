import { IoMegaphoneOutline } from "react-icons/io5";
import { Handle, Position } from "reactflow";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { getNodeName } from "../utils/getNodeName";
// import { isSelectedClass } from "../../../../../helpers/isSelected";

export const BroadcastNode = (node) => {
  const { edges } = useContext(FlowContext);

  return (
    <div
      className="each-node-wrapper"
      //   onClick={() => {
      //     setNodoSeleccionado(node);
      //     // setMostrarDrawer(true);
      //   }}
    >
      <div
        className="node-header"
        style={{ alignItems: "center", marginBottom: 0 }}
      >
        <div style={{ height: "32px" }}>
          <IoMegaphoneOutline color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          {/* <span className="node-type">{getNodeName(node)}</span> */}
          <span className="mensaje-nodo">Campaña</span>
        </div>
      </div>
      <Handle
        type="source"
        className="custom-handle trigger-handle"
        position={Position.Right}
        isConnectable={checkValidConnection(edges, node.id)}
      />
    </div>
  );
};
