import { Drawer, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { closingTypesColumns } from './utils';
import { getClosingTypes } from '../../../../../../helpers/fetchData/fetchClosingTypes';
import { ClosingTypesForm } from './ClosingTypesForm';
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const ClosingTypes = ({ drawerData, setDrawerData }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const [closingTypes, setClosingTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoading(true);
      getClosingTypes(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setClosingTypes(res.data);
          } else {
            messageApi.error('Ocurrío un error al obtener tipos de cierre');
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error('Ocurrío un error al obtener tipos de cierre');
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  return (
    <div>
      <Table
        className='tabla-config'
        columns={closingTypesColumns(setClosingTypes, setDrawerData)}
        dataSource={closingTypes || []}
        //loading={loading}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size='large' />
        }}
        size='small'
        rowKey='_id'
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} tipos de cierre`
        }}
      />

      <Drawer open={drawerData?.open} width={450} destroyOnClose={true}>
        <ClosingTypesForm
          drawerData={drawerData}
          setDrawerData={setDrawerData}
          setClosingTypes={setClosingTypes}
        />
      </Drawer>
    </div>
  );
};
