import { useEffect, useState } from "react";
import { renderConvoDate } from "./utils/renderConvoDate";
import dayjs from "dayjs";
import { renderClosingType, renderTicketType } from "./utils/renderTags";
import { renderDateDivider } from "./utils/renderDateDivider";
import { Message } from "./Message";
import {
  focusChatInput,
  scrollTo,
  scrollToBottom,
} from "./utils/chatFunctions";

const Ticket = ({ ticket, ticketTypes, closingTypes }) => {
  return (
    <div className="chat-messages-container" id={ticket._id}>
      {ticket?.createdAt && renderConvoDate(ticket.createdAt, "Iniciada")}
      {ticket?.messages?.map((element, index) => {
        element = {
          ...element,
          date: dayjs(element.timestamp, "x")?.format("DD/MM/YYYY"),
          status: element.status || "read",
          id: element._id,
        };

        return (
          <div
            key={element.id}
            className="message-date-wrapper"
            id={element.id}
          >
            {renderDateDivider(element, index, ticket.messages)}
            <Message data={element} />
          </div>
        );
      })}
      {ticket?.status?.toUpperCase() === "CLOSED" && ticket?.closeDate && (
        <>
          <span className="horizontal" style={{ marginInline: "auto" }}>
            {renderTicketType(ticket.ticketType, ticketTypes)}
            {renderClosingType(ticket.closingType, closingTypes)}
          </span>
          {renderConvoDate(ticket.closeDate, "Finalizada")}
        </>
      )}
    </div>
  );
};

export const ConversationTickets = ({
  chatFormattedData,
  ticketTypes,
  closingTypes,
  wrapperRef,
}) => {
  const [filteredTickets, setFilteredTickets] = useState([]);

  const handleScroll = () => {
    const container = wrapperRef.current;

    if (container.scrollTop === 0) {
      setFilteredTickets((prevMessages) => {
        if (!prevMessages || prevMessages?.length === 0) {
          return [];
        }

        // const lastTicketId = prevMessages[0]?._id;
        const ticketsCount = prevMessages.length + 1 || 0;

        if (ticketsCount >= chatFormattedData.messages?.length) {
          return chatFormattedData.messages;
        }

        const ticketsToRender = chatFormattedData.messages.slice(
          chatFormattedData.messages.length - ticketsCount,
          chatFormattedData.messages.length
        );

        // setTimeout(() => {
        //   if (lastTicketId) {
        //     scrollTo(lastTicketId);
        //   }
        // }, 300);

        return ticketsToRender;
      });
    }
  };

  useEffect(() => {
    // Renderiza primero los ultimos 3 tickets;
    if (chatFormattedData?.messages?.length > 0) {
      const ticketsCount = chatFormattedData.messages.length;

      let initialIndex = ticketsCount - 3;

      if (initialIndex < 0) {
        initialIndex = 0;
      }

      const ticketsToRender = chatFormattedData.messages.slice(
        initialIndex,
        ticketsCount
      );

      setFilteredTickets(ticketsToRender);

      if (chatFormattedData?.messages?.length > 0) {
        scrollToBottom(wrapperRef, "start");

        focusChatInput();
      }
    }
  }, [chatFormattedData]);

  useEffect(() => {
    const container = wrapperRef.current;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {filteredTickets?.map((ticket) => {
        return (
          <Ticket
            ticket={ticket}
            ticketTypes={ticketTypes}
            closingTypes={closingTypes}
            key={ticket._id}
          />
        );
      })}
    </>
  );
};
