import { useContext, useState, useEffect } from "react";
import { Button, Form } from "antd";
import { GlobalContext } from "../../../context/GlobalContext";
import { putCliente } from "./fetchData/formCliente/putCliente";
import LoadingIcon from "../../Loader/LoadingIcon";
import "./formClientes.css";
import { SelectContactos } from "../../selectContactos/SelectContactos";

function LinkContactsToClient({
  editarClientesValues,
  setDrawerLinkContacts,
  setCliente,
  setRefetchClientes,
}) {
  const [formCliente] = Form.useForm();

  const { messageApi, instance } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);

  useEffect(() => {
    if (editarClientesValues) {
      //console.log('editarClientesValues', editarClientesValues)
      setTargetKeys(editarClientesValues.contacts);
    }
  }, []);

  const onFinish = (v) => {
    //console.log('relacionar contactos a cliente', v);

    if (instance?._id) {
      let { key, state, ...client } = editarClientesValues;

      let data = {
        ...client,
        contacts: targetKeys,
      };

      setLoading(true);
      putCliente(data, instance)
        .then((res) => {
          console.log(res);
          if (res?.status === 200) {
            messageApi.success("Contactos relacionados correctamente");
            setDrawerLinkContacts(false);
            setCliente(undefined);
            // actualizarLista(
            //     "editar",
            //     data,
            //     setClientes,
            //     editarClientesValues._id,
            //     idTablaSelecc
            // );
            setRefetchClientes((prevState) => {
              return !prevState;
            });
          } else {
            messageApi.error("Ocurrió un error al relacionar contactos");
          }
        })
        .catch((error) => {
          // console.log('error catch', error);
          messageApi.error("Ocurrió un error al relacionar contactos");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Form
      form={formCliente}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: "24px" }}
      className="form-clientes"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Relacionar contactos</p>
      <SelectContactos
        value={targetKeys}
        setValue={setTargetKeys}
        mode="multiple"
      />

      <Form.Item className="form-clientes-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={
              loading ? <LoadingIcon size="default" color="#FFFFFF" /> : null
            }
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setCliente(undefined);
              setDrawerLinkContacts(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default LinkContactsToClient;
