import { useContext, useEffect, useState } from 'react';
import { Button, Radio } from 'antd';
import { FiPlus } from 'react-icons/fi';
import { useAccess } from '../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../helpers/access';
import { SelectTriggerModal } from './SelectTriggerModal';
import { GlobalContext } from '../../../context/GlobalContext';
import { getFlows } from '../../../../helpers/fetchData/fetchFlows';
import { FlowsMainCards } from './FlowsMainCards';
import { FlowsMainTable } from './FlowsMainTable';
import { RiGalleryView2, RiTableView } from 'react-icons/ri';
import { Buscador } from '../../buscadores/Buscador';
import { CargarMediaButton } from '../../buttons/CargarMediaButton';
import { getQrCodes } from '../../../../helpers/fetchData/fetchQrCodes';

export const FlowsMainContent = () => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [statusFiltro, setStatusFiltro] = useState('publish');
  const [loading, setLoading] = useState(true);
  const [openSelectTriggerModal, setOpenSelectTriggerModal] = useState(false);
  const [flows, setFlows] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(true);
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [searchedFlows, setSearchedFlows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewType, setViewType] = useState('gallery');
  const [qrCodes, setQrCodes] = useState([]);

  const isAllowed = useAccess();

  useEffect(() => {
    setLoading(true);
    if (instance?._id) {
      getFlows(setFlows, messageApi, setLoading, instance);

      getQrCodes(instance)
        .then((res) => {
          setQrCodes(res.data);
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('Error al obtener los códigos QR');
        });
    }
  }, [instance]);

  useEffect(() => {
    if (!loading) {
      setLoadingFilter(true);
      let flujos = flows;
      if (flows.length > 0) {
        if (statusFiltro !== 'todos') {
          flujos = flujos.filter((flow) => flow.status === statusFiltro);
        }
      }

      setLoadingFilter(false);

      setFilteredFlows(flujos);
      setSearchedFlows(flujos);
      setCurrentPage(1); // Reinicia la página actual cuando cambian los filtros
    }
  }, [flows, statusFiltro]);

  return (
    <div className='main-wrapper'>
      <div className='main-header'>
        <span>Bots</span>
        <span className='horizontal'>
          <Buscador
            element='bot'
            data={filteredFlows}
            setFilteredData={setSearchedFlows}
            searchKeys={['name', 'trigger', 'description']}
            maxWidth={200}
          />
          <Radio.Group
            onChange={(v) => setStatusFiltro(v.target.value)}
            value={statusFiltro}
            optionType='button'
            buttonStyle='solid'
            style={{ fontWeight: '400' }}
            options={[
              { value: 'todos', label: 'Todos' },
              { value: 'draft', label: 'Borrador' },
              { value: 'publish', label: 'Activo' }
            ]}
          />
        </span>

        <div className='espacio-botones'>
          <Radio.Group
            onChange={(v) => setViewType(v.target.value)}
            value={viewType}
            optionType='button'
            buttonStyle='outline'
            // style={{ fontWeight: '400' }}
            options={[
              {
                value: 'gallery',
                label: (
                  <div className='iconos-select-view'>
                    <RiGalleryView2 size={20} />
                  </div>
                )
              },
              {
                value: 'table',
                label: (
                  <div className='iconos-select-view'>
                    <RiTableView size={20} />
                  </div>
                )
              }
            ]}
          />

          {isAllowed(accessDataFormat('bots', 'manage')) && (
            <>
              <CargarMediaButton />

              <Button
                type='primary'
                className='btn-agregar'
                onClick={() => {
                  setOpenSelectTriggerModal(true);
                }}
              >
                <FiPlus />
                Bot
              </Button>
            </>
          )}
        </div>
      </div>

      {viewType === 'gallery' ? (
        <FlowsMainCards
          qrCodes={qrCodes}
          setQrCodes={setQrCodes}
          filteredFlows={searchedFlows}
          setFlows={setFlows}
          loading={loading}
          setLoading={setLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <FlowsMainTable
          statusFiltro={statusFiltro}
          filteredFlows={searchedFlows}
          setFlows={setFlows}
          loading={loading}
          setLoading={setLoading}
          qrCodes={qrCodes}
          setQrCodes={setQrCodes}
        />
      )}

      <SelectTriggerModal
        open={openSelectTriggerModal}
        setOpen={setOpenSelectTriggerModal}
      />
    </div>
  );
};
