export const getContactName = (name) => {
  let nombre = "";

  if (typeof name === "string") {
    return name;
  }

  if (!name || typeof name !== "object") {
    return name;
  }

  if (name.last_name) {
    nombre += name.last_name.trim();
  }

  if (name.first_name) {
    nombre += ` ${name.first_name.trim()}`;
  }

  if (name.middle_name) {
    nombre += ` ${name.middle_name.trim()}`;
  }

  return nombre;
};
