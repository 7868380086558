import { useContext, useEffect } from "react";
import { AiFillThunderbolt } from "react-icons/ai";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { MdOutlineInput } from "react-icons/md";
import { BsGearFill } from "react-icons/bs";
import { Tag } from "antd";
import { checkValidConnection } from "../utils/utilidadesFlow";

export const TriggerNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [node]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      <div className="node-header">
        <div>
          <AiFillThunderbolt fill="rgb(244, 184, 0)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">Disparador</span>
          <span className="mensaje-nodo">{node?.data?.label}</span>
        </div>
      </div>
      <div className="mensaje-entrante">
        <span className="mensaje-entrante-header">
          {!node?.data?.triggers ? (
            <>
              <BsGearFill fill="#656565" />
              <p>Configurar disparador...</p>
            </>
          ) : (
            <>
              <MdOutlineInput />
              <p>Mensaje entrante...</p>
            </>
          )}
        </span>
        {node?.data?.triggers && (
          <div className="triggers-layout">
            {node?.data?.triggers?.map((trigger, index) => {
              return (
                <Tag
                  key={`${trigger}-${index}`}
                  color="gold"
                  style={{ margin: "0px" }}
                >
                  {trigger}
                </Tag>
              );
            })}
          </div>
        )}
      </div>
      <div className="path-id">{node?.id}</div>
      <Handle
        type="source"
        className="custom-handle trigger-handle"
        position={Position.Right}
        isConnectable={checkValidConnection(edges, node.id)}
      />
    </div>
  );
};
