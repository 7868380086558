export const actualizarListaFiltros = (action, data, setElements, _id, operador, idGrupoSelecc) => {


    if (action === "crear") {

        setElements(prevState => {
            let array = [...prevState];
            //Si estamos creando por AND:
            if (operador === 'AND') {

                let nuevoItem = {
                    _id: _id,
                    key: _id, // Unique key para React
                    ...data,
                };

                const indexGrupoSelecc =  array.findIndex((item) => item._id === idGrupoSelecc);

                array[indexGrupoSelecc].filtros.splice(array[indexGrupoSelecc].filtros.length, 0, nuevoItem);
            };

            //Si estamos creando por OR:
            if (operador === 'OR') {
                let nuevoGrupoItem = {
                    _id: _id.new_idGrupo,
                    key: _id.new_idGrupo, // Unique key para React
                    ...data,
                };

                nuevoGrupoItem.filtros[0] = {...data.filtros[0], _id: _id.new_idCondicion, key: _id.new_idCondicion};

                // Asigna el idGrupo para incluirlo en el nuevo grupo.
                nuevoGrupoItem.filtros[0].filtroId = _id.new_idGrupo;


                // newGrupo.filtros.push(nuevoItem);
                array.splice(array.length, 0, nuevoGrupoItem);
            };

            return array
        });

    };

    if (action === 'editar') {

        setElements(prevState => {
            let array = [...prevState];

            let grupoFiltro = array.find((item) => item._id == idGrupoSelecc).filtros;
            let indexGrupo = array.findIndex((i) => i._id == idGrupoSelecc);
            let indexFiltro = grupoFiltro?.findIndex((i) => i._id == _id);

            array[indexGrupo].filtros[indexFiltro] = {_id: _id, ...data};

            return array
        });
    };

    if (action === 'eliminar') {

        setElements(prevState => {

            let array = [...prevState]
  
            let grupoFiltro = array.find((item) => item._id == idGrupoSelecc).filtros;
            let indexFiltro = grupoFiltro?.findIndex((i) => i._id == _id);
            let indexGrupo = array.findIndex((i) => i._id == idGrupoSelecc);

            array[indexGrupo].filtros.splice(indexFiltro, 1);

            //Si el grupo ya no contiene filtros, eliminamos tambien dicho grupo.
            if (grupoFiltro.length < 1) {
                array.splice(indexGrupo, 1)
            }

            return array
        });
    }
};