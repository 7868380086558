import { Tag } from "antd";

export const renderTeam = (teams, teamId) => {
  if (teams && teamId) {
    const team = teams.find((element) => element._id === teamId);

    if (team?.name) return <Tag color="geekblue">{team.name}</Tag>;
  }

  return <></>;
};
