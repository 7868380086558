import { Button, Drawer, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { GlobalContext } from "../../../context/GlobalContext";
import { useNavigate, useLocation } from "react-router-dom";
import { getCampaigns } from "../../../../helpers/fetchData/fetchCampaigns";
// import { io } from 'socket.io-client';
import { getEnviosMavisos } from "../../../../helpers/fetchData/fetchEnviosMasivos";
import { EnviosSubscriptions } from "../enviosMasivos/EnviosSubscriptions";
import { accessDataFormat } from "../../../../helpers/access";
import { useAccess } from "../../../../hooks/useAccess";
import { EnviosReturn } from "../enviosMasivos/EnviosReturn";
import { Buscador } from "../../buscadores/Buscador";
import { CampaignTab } from "./tabs/CampaignTab";
import { EnviosMasivosTab } from "./tabs/EnviosMasivosTab";

/* const socket = io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: false,
  timeout: 20000,
  transports: ['polling', 'websocket']
  // extraHeaders: {
  //   "ngrok-skip-browser-warning": "69420",
  // },
}); */

export const Campaigns = ({ tab }) => {
  const { instance, messageApi, application } = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();
  const isAllowed = useAccess();

  const [campaigns, setCampaigns] = useState([]);
  // const [isConnected, setIsConnected] = useState(false);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [enviosMasivos, setEnviosMasivos] = useState([]);
  const [filteredEnviosMasivos, setFilteredEnviosMasivos] = useState([]);
  const [activeTab, setActiveTab] = useState(tab || "campañas");
  const [drawerData, setDrawerData] = useState({ open: false, item: null });
  const [drawerReturn, setDrawerReturn] = useState({
    open: false,
    item: null,
  });
  const [loading, setLoading] = useState(false);
  const [campaignsMetrics, setCampaignsMetrics] = useState([]);

  useEffect(() => {
    if (!instance?.userData?.apiKey) {
      return;
    }

    if (activeTab === "campañas") {
      setLoading(true);
      getCampaigns(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setCampaigns(res.data);

            let array = res.data.map((element) => {
              return {
                campaignId: element._id,
                sent: 0,
                delivered: 0,
                read: 0,
              };
            });

            setCampaignsMetrics(array);
          } else {
            messageApi.error("Ocurrió un error al obtener campañas");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al obtener campañas");
        })
        .finally(() => setLoading(false));
    } else if (activeTab === "envios") {
      setLoading(true);
      getEnviosMavisos(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setEnviosMasivos(res.data);
          } else {
            messageApi.error("Ocurrió un error al obtener campañas");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al obtener campañas");
        })
        .finally(() => setLoading(false));
    }
  }, [instance, activeTab]);

  /* const socketConnection = () => {
    if (activeTab === 'campañas') {
      if (instance?.userData?.apiKey && application?.[0]) {
        socket.auth = {
          token: instance.userData.apiKey,
          instanceName: instance.name,
          metaToken: application[0].token,
          phoneNumberId: application[0].lines?.[0]?.phoneNumberId
        };

        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (activeTab === 'campañas') {
      function onConnect(message) {
        setIsConnected(true);
        messageApi.success('Conexión establecida');
      }

      function onDisconnect(message) {
        setIsConnected(false);
        // messageApi.error("Se desconectó");
        const flag = socketConnection();

        if (flag) {
          socket.connect();

          return () => {
            socket.disconnect();
          };
        }
      }

      // socket.on escucha eventos
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
      };
    }
  }, [activeTab]);

  useEffect(() => {
    const flag = socketConnection();

    if (flag) {
      socket.connect();

      // Cuanto se desmonte el componente se desconecta
      return () => {
        socket.disconnect();
      };
    }
  }, [instance, application, activeTab]);

  useEffect(() => {
    if (activeTab === 'campañas') {
      const receiveMessage = (message) => {
        if (message?.campaignId) {
          setCampaignsMetrics((prevState) => {
            let array = [...prevState];

            let campaignIndex = array.findIndex(
              (element) => element.campaignId === message.campaignId
            );

            let formattedMetric = { ...message, metricsId: message.metricId };
            delete formattedMetric.campaignId;
            delete formattedMetric.metricId;

            if (campaignIndex >= 0) {
              if (message.metricId) {
                let metricIndex = array[campaignIndex].data?.findIndex(
                  (element) => (element.metricsId = message.metricId)
                );

                if (metricIndex >= 0) {
                  array[campaignIndex].data[metricIndex] = formattedMetric;
                } else {
                  array[campaignIndex]?.data?.push(formattedMetric);
                }
              }
            } else {
              array.push({
                campaignId: message.campaignId,
                data: [formattedMetric]
              });
            }

            return array;
          });
        }
      };

      socket.on('tableMetrics', receiveMessage);

      return () => {
        socket.off('tableMetrics', receiveMessage);
      };
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'campañas') {
      const refreshCampaigns = (message) => {
        if (Array.isArray(message)) {
          setCampaignsMetrics(message);
        } else {
          setCampaignsMetrics((prevState) => [message, ...prevState]);
        }
      };

      socket.on('refreshCampaigns', refreshCampaigns);

      return () => {
        socket.off('refreshCampaigns', refreshCampaigns);
      };
    } else {
      socket.disconnect();
    }
  }, [activeTab]);

  const enviarIds = () => {
    let ids = campaigns
      .slice(currentPage * PAGE_SIZE - PAGE_SIZE, currentPage * PAGE_SIZE)
      .map((element) => element._id);

    setLoadingGraphs(true);
    getCampaignsMetrics(ids, instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          if (Array.isArray(res?.data?.data)) {
            setCampaignsMetrics(res?.data?.data);
            setLoadingGraphs(false);
          }
        } else {
          messageApi.error('Ocurrió un error al obtener metricas');
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error('Ocurrió un error al obtener metricas');
      });
  };

  useEffect(() => {
    if (activeTab === 'campañas') {
      if (campaigns.length > 0 && isConnected) {
        enviarIds();
      }
    }
  }, [campaigns, currentPage, isConnected, activeTab]); */

  useEffect(() => {
    setFilteredCampaigns([...campaigns]);
  }, [campaigns]);

  useEffect(() => {
    setFilteredEnviosMasivos(enviosMasivos);
  }, [enviosMasivos]);

  useEffect(() => {
    setActiveTab(tab);
  }, [location, tab]);

  /*   useEffect(() => {
    if (searchTerm === '') {
      setFilteredCampaigns(campaigns);
      setFilteredEnviosMasivos(enviosMasivos);
    } else {
      if (activeTab === 'campañas') {
        const searchedCampaigns = campaigns.filter((campaign) =>
          // Obtiene un arreglo de los valores de todas las propiedades (Object.values), los "stringifica" y luego verifica si al menos alguno de ellos incluye lo buscado (some):
          Object.values(campaign).some((value) => {
            const normalizedValue = Array.isArray(value)
              ? value.join(', ')
              : typeof value === 'object' && value !== null
              ? JSON.stringify(value)
              : String(value);

            return normalizedValue
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          })
        );

        setFilteredCampaigns(searchedCampaigns);
      } else {
        const searchedEnviosMasivos = enviosMasivos.filter((envioMasivo) =>
          Object.values(envioMasivo).some((value) => {
            const normalizedValue = Array.isArray(value)
              ? value.join(', ')
              : typeof value === 'object' && value !== null
              ? JSON.stringify(value)
              : String(value);

            return normalizedValue
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          })
        );

        setFilteredEnviosMasivos(searchedEnviosMasivos);
      }
    }
  }, [activeTab, searchTerm, campaigns, enviosMasivos]); */

  return (
    <div className="main-wrapper">
      <div className="main-header">
        <span>Mensajes</span>
        <Buscador
          element={activeTab === "campañas" ? "campaña" : "envío masivo"}
          data={activeTab === "campañas" ? campaigns : enviosMasivos}
          setFilteredData={
            activeTab === "campañas"
              ? setFilteredCampaigns
              : setFilteredEnviosMasivos
          }
          searchKeys={
            activeTab === "campañas"
              ? ["name", "template.template.name"]
              : ["name", "template.template.name"]
          }
        />
        {isAllowed(accessDataFormat("broadcast", "manage")) && (
          <div className="espacio-botones">
            {activeTab === "envios" ? (
              <Button
                type="primary"
                className="btn-agregar"
                onClick={() => navigate("/envios/new")}
              >
                <FiPlus /> Envío masivo
              </Button>
            ) : (
              <Button
                type="primary"
                className="btn-agregar"
                onClick={() => {
                  // socket.disconnect();
                  navigate("/campaigns/new");
                }}
              >
                <FiPlus /> Campaña
              </Button>
            )}
          </div>
        )}
      </div>
      <Tabs
        style={{ marginInline: "7.5%" }}
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={(v) => {
          navigate(`/mensajes/${v}`);
          // setActiveTab(v);
        }}
        items={[
          {
            label: "Campañas",
            key: "campañas",
            children: (
              <CampaignTab
                filteredCampaigns={filteredCampaigns}
                setCampaigns={setCampaigns}
                loading={loading}
                campaignsMetrics={campaignsMetrics}
              />
            ),
          },
          {
            label: "Envíos masivos",
            key: "envios",
            children: (
              <EnviosMasivosTab
                filteredEnviosMasivos={filteredEnviosMasivos}
                setEnviosMasivos={setEnviosMasivos}
                setDrawerData={setDrawerData}
                setDrawerReturn={setDrawerReturn}
                loading={loading}
              />
            ),
          },
        ]}
      />
      <Drawer
        open={drawerData?.open}
        width={450}
        destroyOnClose
        maskClosable={true}
        placement={"right"}
        closable={true}
        closeIcon={null}
        onClose={() => setDrawerData({ open: false, item: null })}
      >
        <EnviosSubscriptions
          drawerData={drawerData}
          setDrawerData={setDrawerData}
          setEnviosMasivos={setEnviosMasivos}
        />
      </Drawer>

      <Drawer
        open={drawerReturn?.open}
        width={450}
        destroyOnClose
        maskClosable={true}
        placement={"right"}
        closable={true}
        closeIcon={null}
        onClose={() => setDrawerReturn({ open: false, item: null })}
      >
        <EnviosReturn
          drawerReturn={drawerReturn}
          setDrawerReturn={setDrawerReturn}
          setEnviosMasivos={setEnviosMasivos}
        />
      </Drawer>
    </div>
  );
};
