import { Button, Form, Select, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { updateNode } from "../utils/updateNode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { manageWaitNode } from "../utils/manageWaitNode";
import { HeaderForm } from "./componentsForm/HeaderForm";
import { SiOpenai } from "react-icons/si";
import { getAssistants } from "../../../../../helpers/fetchData/fetchOpenAI";
import { clearNodeVariables } from "../utils/clearNodeVariables";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { BoxInfoPopUp } from "../../../box-info/BoxInfoPopUp";
import { LineaGris } from "../../../linea-gris/LineaGris";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { getVariableName } from "../utils/getVariableName";
const dayjs = require('dayjs'); 



export const OpenAIAssistantForm = () => {
  const {
    setFlowVariables,
    nodoSeleccionado,
    mostrarDrawer,
    setMostrarDrawer,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(FlowContext);

  const { instance, messageApi } = useContext(GlobalContext);
  const { TextArea } = Input;


  const [inputText, setInputText] = useState("");
  const [nombreNodo, setNombreNodo] = useState("");
  const [loadingAssistants, setLoadingAssistants] = useState(true);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState({});
  const [variableType, setVariableType] = useState("string");
  const [form] = Form.useForm();



  const handleAuto = () => {

    return (
      <div>
        <div className="form-item-column">
          <span
            className="horizontal"
            style={{ justifyContent: "space-between" }}
          >
            <Form.Item
              label="Nombre de la variable"
              name="variableName"
              initialValue={nodoSeleccionado?.data?.variableName}
              style={{ width: "63%" }}
            >
              <Input addonBefore={`${variableType}_`} placeholder={`response`} />
            </Form.Item>
            <Form.Item
              label="Tipo"
              name="variableType"
              initialValue={"string"}
              style={{ width: "33%" }}
            >
              <Select
                options={[
                  { value: "string", label: "String" },
                ]}
                onChange={(v) => setVariableType(v)}
                value={variableType}
                disabled={true}
              />
            </Form.Item>
          </span>
          <BoxInfo
            message={[
              `La variable siempre será de tipo String.`
            ]}
          />
        </div>
      </div>
    );
  };



  const onFinish = (values) => {
    values.label = nombreNodo;
    values.auto = false;
    values.caption = null;
    values.assistantName = selectedAssistant?.name;

    // En caso de desabilitar "esperar respuesta" hay que borrar la variable
    clearNodeVariables(nodoSeleccionado?.id, setFlowVariables);

    values.variable = {};

    if (!values.variableName) {
      return messageApi.error("Ingrese un nombre para la variable");
    }
    let variableName = getVariableName(values.variableName, 'string');
    values.variableName = variableName;
    values.variable.name = variableName;
    values.variable.type = 'string';

    let flag = handleFlowVariables(
      setFlowVariables,
      nodoSeleccionado.id,
      values.variable,
      messageApi,
      setNodes,
      setEdges
    );

    if (!flag) return;

    values.handleCount = 1;

    manageWaitNode(nodes, setNodes, edges, setEdges, nodoSeleccionado, true);
    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (!mostrarDrawer) {
      setInputText("");
    }
  }, [mostrarDrawer]);


  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      setInputText(data.bodyText);

      setVariableType("flow");

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
        assistant: data.assistant
      });

      const assistant = assistants.find(as => as.id === data.assistant);
      setSelectedAssistant(assistant);
    }
  }, [nodoSeleccionado, assistants]);

  useEffect(() => {

    setLoadingAssistants(true);

    const fetchAssistants = async () => {
      try {
        const res = await getAssistants(instance?.userData?.apiKey);

        if (res.status === 200) {
          setAssistants(res?.data?.data);
        } else {
          console.error("Error en la respuesta:", res.status);
        }
      } catch (error) {
        console.error("Error al obtener los asistentes:", error);
      } finally {
        setLoadingAssistants(false);
      }
    };

    fetchAssistants();
  }, [instance]);


  const assistantsOptions = assistants?.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const handleChangeAssistants = (value) => {

    const assistant = assistants.find(as => as.id === value);
    setSelectedAssistant(assistant);
  };


  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >

      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <SiOpenai style={{ color: "var(--dark-color)" }} size={32} />
        }
      />

      {/* Informacion asistente seleccionado */}
      {!loadingAssistants ?
        <>

          <Form.Item label='Asistente'
            name='assistant'
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              allowClear
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              style={{
                width: '100%',
              }}
              onChange={handleChangeAssistants}
              options={assistantsOptions}
              loading={loadingAssistants}
            />
          </Form.Item>
          {
            selectedAssistant && (<div className='ShowContact'>
              {/* <p style={{ marginBottom: 16, fontWeight: 600 }}>Datos del Contacto</p> */}

              <div className='show-contact-container'>
                <span className='show-contact-name'>{`${selectedAssistant?.name || ''}`}</span>

                <span className='show-contact-job'>{selectedAssistant?.object || ''}</span>

                <div className='show-contact-info-container'>
                  <div className='show-contact-info-subcontainer'>
                    <span className='show-assistant-info-label'>
                      Fecha de creación
                    </span>
                    {
                      selectedAssistant?.created_at && (
                        <span className='show-contact-info'>
                          {dayjs(selectedAssistant?.created_at * 1000).format('DD/MM/YYYY')}
                        </span>
                      )
                    }

                  </div>

                  <div className='show-contact-info-subcontainer'>
                    <span className='show-assistant-info-label'>Modelo</span>
                    {
                      selectedAssistant?.model && (
                        <span className='show-contact-info'>
                          {selectedAssistant?.model}
                        </span>
                      )
                    }
                  </div>


                  <div className='show-contact-info-subcontainer'>
                    <span className='show-assistant-info-label'>
                      Instrucciones
                    </span>
                    <TextArea className="show-contact-info" rows={4} value={selectedAssistant?.instructions || ''} readOnly />
                  </div>


                  {(selectedAssistant?.tools && selectedAssistant?.tools?.length > 0 && selectedAssistant?.tools?.some(item => item.type === "function")) && (
                    <div className='show-contact-info-subcontainer'>
                      <span className='show-assistant-info-label'>
                        Funciones
                      </span>

                      {selectedAssistant?.tools?.map((tool, index) => {
                        const properties = tool?.function?.parameters?.properties || {};
                        const propertyNames = Object.keys(properties).join(", ");
                        const required = tool?.function?.parameters?.required || [];
                        const requiredNames = required.join(", ");

                        return (
                          <div key={index} className='show-contact-info' style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            <span>{tool?.function?.name}</span>
                            <BoxInfoPopUp
                              hideMode
                              message={
                                <>
                                  <div>
                                    {tool?.function?.description}.
                                  </div>
                                  <br />
                                  {propertyNames && propertyNames.length > 0 && (
                                    <div>Parámetros: {propertyNames}</div>
                                  )}
                                  {required?.length > 0 && (<div>Requeridos: {requiredNames}</div>)}
                                </>
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            )}


          <LineaGris />

          {handleAuto()}
        </>
        :
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
          <LoadingIcon size="large" />
        </div>
      }


      <Form.Item className="form-custom-footer" style={{ paddingTop: '4px' }}>
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={loadingAssistants ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loadingAssistants ? 0.65 : 1 }}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
              //setAuto(true);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
