import { Button, Popover, Tooltip } from "antd";
import dayjs from "dayjs";
import { HiDownload } from "react-icons/hi";
import { downloadJSON } from "../../../../../utils/downloadJSON";
import { useContext, useState } from "react";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { GlobalContext } from "../../../../context/GlobalContext";
import { TbTrash } from "react-icons/tb";
import { useAccess } from "../../../../../hooks/useAccess";
import { accessDataFormat } from "../../../../../helpers/access";
import { deleteEnvioLogs } from "../../../../../helpers/fetchData/fetchEnviosMasivos";

export const envioLogsColumns = (setLogs) => {
  return [
    {
      title: "Fecha de envío",
      key: "timestamp",
      dataIndex: "timestamp",
      render: (data) => {
        if (data) {
          const date = dayjs(data, "x").format("DD/MM/YYYY HH:mm");
          return <p className="monospace-font">{date}</p>;
        }
      },
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      align: "center",
      width: 60,
      render: (data, item) => (
        <AccionesEnviosLogs item={item} setLogs={setLogs} />
      ),
    },
  ];
};

const AccionesEnviosLogs = ({ item, setLogs }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const isAllowed = useAccess();

  const [showPopover, setShowPopover] = useState(false);

  const [loading, setLoading] = useState(false);

  const date = dayjs(item?.timestamp, "x").format("DD/MM/YYYY HH:mm");

  const handleDelete = () => {
    setLoading(true);
    deleteEnvioLogs(instance?.userData?.apiKey, item?._id)
      .then((res) => {
        if (res?.status === 200) {
          setLogs((prevState) => {
            let array = [...prevState];

            array = array.filter((element) => element._id !== item._id);

            return array;
          });
        } else {
          messageApi.error("Ocurrió un error al eliminar el log");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al eliminar el log");
      })
      .finally(() => setLoading(false));
  };

  return (
    <span className="horizontal">
      <Tooltip title="Descargar">
        <span>
          <HiDownload size={20} onClick={() => downloadJSON(item, `${date}`)} />
        </span>
      </Tooltip>
      {isAllowed(accessDataFormat("broadcast", "manage")) && (
        <Popover
          trigger="click"
          placement="bottomRight"
          open={showPopover}
          content={
            <div className="columna-simple gap-16">
              <span>{`¿Está seguro que desea eliminar el log?`}</span>
              <div
                className="botones-wrapper-content"
                style={{ width: "100%" }}
              >
                <Button
                  type="primary"
                  className="btn-borrar"
                  onClick={handleDelete}
                  //loading={loadingBorrar}
                  icon={
                    loading ? (
                      <LoadingIcon size="small" color="#FFFFFF" />
                    ) : null
                  }
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  className="btn-cancelar"
                  type="secondary"
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          }
        >
          <Tooltip className="pointer" title="Eliminar">
            <span style={{ height: 20 }}>
              <TbTrash size={18} onClick={() => setShowPopover(true)} />
            </span>
          </Tooltip>
        </Popover>
      )}
    </span>
  );
};
