import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalContext';
import { FlowContext } from '../../../../context/FlowContext';
import { Button, Form, Input, Popover, Select } from 'antd';
import { updateNode } from '../utils/updateNode';
import { TbMessage } from 'react-icons/tb';
import { BotonAgregarVariable } from './BotonAgregarVariable';
import {
  agregarVariableWhatsapp,
  formatoTextHtml
} from '../../../../../helpers/utilidadesTemplates';
import { FiBold, FiItalic } from 'react-icons/fi';
import { AiOutlineStrikethrough } from 'react-icons/ai';
import EmojiPicker from 'emoji-picker-react';
import { BsEmojiSmile } from 'react-icons/bs';
import { HeaderForm } from './componentsForm/HeaderForm';

export const SendMessageToChatForm = () => {
  const { messageApi, instance } = useContext(GlobalContext);

  const { nodoSeleccionado, setMostrarDrawer, setNodes, flowVariables } =
    useContext(FlowContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState('');

  const [bodyText, setBodyText] = useState('');
  const [bodyHtml, setBodyHtml] = useState('');

  const decorarTexto = (seccion, wrappingString, emoji, variable) => {
    let textArea = document.getElementById(seccion);

    if (textArea) {
      let s = textArea.selectionStart;
      let e = textArea.selectionEnd;

      let oldValue = textArea.value;

      if (!['', '${', 'emoji'].includes(wrappingString)) {
        let newValue =
          oldValue.slice(0, s) +
          wrappingString +
          oldValue.slice(s, e) +
          wrappingString +
          oldValue.slice(e, oldValue.length);
        textArea.value = newValue;
      }

      if (wrappingString === '${') {
        textArea.value = agregarVariableWhatsapp(
          textArea.value,
          variable,
          'textBody'
        );
      }

      if (wrappingString === 'emoji') {
        textArea.value = oldValue + emoji.emoji;
      }

      setBodyText(textArea.value);

      if (!textArea.value) {
        setBodyHtml('');
      } else {
        setBodyHtml(formatoTextHtml(textArea.value));
      }
    }
  };

  const onFinish = (values) => {
    values.label = nombreNodo;

    values.handleCount = 1;

    if (!bodyText) {
      messageApi.info('Es necesario que ingrese un mensaje');
      return;
    }

    values.bodyHtml = bodyHtml;
    values.bodyText = bodyText;

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      setNombreNodo(data.label);

      if (data.bodyHtml) {
        setBodyHtml(data.bodyHtml);
      }

      if (data.bodyText) {
        setBodyText(data.bodyText);
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='message-to-chat-form'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<TbMessage size={32} />}
      />

      {/* Text area que pueda agregar variable */}
      <Form.Item label={<span className='label-secciones'>Mensaje</span>}>
        <>
          <Input.TextArea
            className='input-text-area'
            placeholder='Escribir texto'
            maxLength={1024}
            autoSize={{ minRows: 3 }}
            showCount
            id='textBody'
            onChange={() => decorarTexto('textBody', '')}
            value={bodyText}
            style={{ marginTop: '8px' }}
          />
          <div
            className='contenedor-botones-texto'
            style={{ marginTop: '4px', marginBottom: '20px' }}
          >
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '*')}
            >
              <FiBold size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '_')}
            >
              <FiItalic size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '~')}
            >
              <AiOutlineStrikethrough size={14} />
            </div>
            <div
              className='boton-texto'
              onClick={() => decorarTexto('textBody', '```')}
              style={{ fontSize: '14px' }}
            >
              {`${'</>'}`}
            </div>
            <Popover
              trigger='click'
              content={
                <EmojiPicker
                  onEmojiClick={(e) => decorarTexto('textBody', 'emoji', e)}
                  emojiStyle='native'
                  lazyLoadEmojis={true}
                />
              }
            >
              <div className='boton-texto'>
                <BsEmojiSmile />
              </div>
            </Popover>
            <BotonAgregarVariable
              nodeId={nodoSeleccionado.id}
              seccion='textBody'
              decorarTexto={decorarTexto}
              context='buttons'
            />
          </div>
        </>
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
