import { HiOutlineExternalLink, HiPhone } from "react-icons/hi";
import { FiCopy } from "react-icons/fi";
import { IoArrowUndoSharp } from "react-icons/io5";
import { getXlsData } from "../Layout/flows/utils/cargaMedia";
import { formatoTextHtml } from "../../../helpers/utilidadesTemplates";

export const getVariablesCount = (
  text,
  section,
  type,
  data,
  setTemplateVariables,
  setHeaderMediaType,
  templateSeleccionado
) => {
  const regex = /\{\{(\d+)\}\}/g;

  if (section === "BUTTONS") {
    setTemplateVariables((prevState) => {
      return {
        ...prevState,
        [section.toLowerCase()]: {
          value: text,
          type: type,
        },
      };
    });
  } else if (section === "CAROUSEL") {
    console.log(text, section, type);

    // "components": [

    //   /* Message bubble */
    //   {
    //     "type": "BODY",
    //     "text": "<BUBBLE_TEXT>",
    //     "example": {
    //       "body_text": [["<BUBBLE_TEXT_VAR_EXAMPLE>"]]
    //     }
    //   },

    //   /* Carousel cards */
    //   {
    //     "type": "CAROUSEL",
    //     "cards": [

    //       /* First carousel card */
    //       {
    //         "components": [
    //           {
    //             "type": "HEADER",
    //             "format": "<CARD_HEADER_FORMAT>" ("IMAGE" / "VIDEO"),
    //             "example": {
    //               "header_handle": ["<CARD_HEADER_HANDLE>"]
    //             }
    //           },
    //           {
    //             "type": "BODY",
    //             "text": "<CARD_BODY_TEXT>",
    //             "example": {
    //               "body_text": [["<CARD_BODY_TEXT_VAR_EXAMPLE>"]]
    //             }
    //           },
    //           {
    //             "type": "BUTTONS",
    //             "buttons": [
    //               {
    //                 "type": "QUICK_REPLY",
    //                 "text": "<QUICK_REPLY_BUTTON_TEXT>"
    //               },
    //               {
    //                 "type": "URL",
    //                 "text": "<URL_BUTTON_TEXT>",
    //                 "url": "<URL_BUTTON_URL>",
    //                 "example": ["<URL_BUTTON_VAR_EXAMPLE>"]
    //               }
    //             ]
    //           }
    //         ]
    //       },

    //       /* Addt'l cards would follow, using same structure as first card */

    //     ]
    // }
    // ]
  } else {
    const variables = text.match(regex) || [];
    if (text === "HEADER") {
      setHeaderMediaType(section?.toLowerCase());
    }

    let array = [];

    if (data?.templateVariables) {
      array = variables.map((_, index) => {
        if (
          data.templateVariables?.[section?.toLowerCase()]?.vars?.[index] &&
          data.template?.id === templateSeleccionado.id
        ) {
          return data?.templateVariables?.[section?.toLowerCase()]?.vars?.[
            index
          ];
        }
        return { type: "var", value: null };
      });
    }

    setTemplateVariables((prevState) => {
      return {
        ...prevState,
        [section.toLowerCase()]: {
          value: text,
          type: type,
          placeholder: variables,
          vars: array,
        },
      };
    });
  }
};

export const removeButtonNodes = (setNodes, setEdges, nodeId) => {
  setNodes((prevState) => {
    let array = prevState.filter((element) => element.type !== "button");

    return array;
  });

  setEdges((prevState) => {
    let array = prevState.filter((element) => element.source !== nodeId);

    return array;
  });
};

export const handleButtonVars = (
  v,
  arrayIndex,
  type,
  setButtonsVars,
  valueType
) => {
  setButtonsVars((prevState) => {
    let array = [...prevState];
    let value = v?.trim();

    if (type === "COPY_CODE" && valueType !== "var") {
      value = value?.replace(/[^a-zA-Z0-9]/g, "");
    }

    if (arrayIndex >= 0) {
      array[arrayIndex] = {
        ...array[arrayIndex],
        value: value,
        valueType: valueType,
      };
    }

    return array;
  });
};

export const hasVariable = (text) => {
  if (text) {
    const regex = /\{\{(\d+)\}\}/g;

    return regex.test(text);
  } else {
    return false;
  }
};

export const getButtonVarValue = (index, buttonsVars) => {
  if (buttonsVars) {
    return buttonsVars.find((element) => element.index === index)?.value;
  }
};

export const getButtonVars = (buttonsContent, buttonsVars, setButtonsVars) => {
  let buttons = [];

  buttonsContent?.forEach((element, index) => {
    const boton = buttonsVars?.find((element) => element.index === index);

    if (element.type === "COPY_CODE") {
      buttons.push({
        ...element,
        index: index,
        value: boton?.value || "",
        valueType: boton?.valueType || "text",
      });
    }

    if (element.type === "URL") {
      if (hasVariable(element.url)) {
        buttons.push({
          ...element,
          index: index,
          value: boton?.value || "",
          valueType: boton?.valueType || "text",
        });
      }
    }
  });

  setButtonsVars(buttons);
};

export const customRequest = async (v, messageApi, setXlsData) => {
  if (
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel".includes(
      v.file.type
    )
  ) {
    if (v.file.size < 1048576 * 100) {
      let res = await getXlsData(v.file);

      let data = null;
      try {
        data = JSON.parse(res);
      } catch (err) {
        console.log(err);
      }

      if (Array.isArray(data)) {
        v.onSuccess();
        setXlsData(data);
      } else {
        messageApi.error("No se pudo procesar el archivo o no contiene data");
        v.onError("No se pudo procesar el archivo o no contiene data");
      }
    } else {
      messageApi.error("El tamaño excede el limite 100MB");
      v.onError("El tamaño excede el limite 100MB");
    }
  } else {
    messageApi.error("El formato no es válido");
    v.onError("El formato no es válido");
  }
};

export const validateXlsColumn = (
  xlsData,
  xlsColumn,
  messageApi,
  setXlsColumn
) => {
  if (xlsData?.length > 0 && xlsColumn) {
    // Dejar == null, checkea si es null o undefined
    if (
      xlsData.some(
        (element) => element[xlsColumn] == null || element[xlsColumn] === ""
      )
    ) {
      messageApi.error("La columna que eligió contiene celdas vacias");
      setXlsColumn(null);
      return false;
    }
  }

  return true;
};

export const handleAddonVariables = (element, index) => {
  let type = "URL";
  let placeholder = "Complete la URL";

  if (element.type === "COPY_CODE") {
    type = "Código";
    placeholder = "Ingrese el código a enviar";
  }

  return {
    placeholder: placeholder,
    addon: `${type} - ${index + 1}`,
  };
};

const SIZE = 14;
export const switchType = (boton) => {
  switch (boton.type) {
    case "PHONE_NUMBER":
      return (
        <>
          <HiPhone size={SIZE} className="call" />
          <span className="help-link call">
            <p>{boton.text}</p>
          </span>
        </>
      );
    case "URL":
      return (
        <>
          <HiOutlineExternalLink size={SIZE} className="call" />
          <span className="help-link call">
            <p>{boton.text}</p>
          </span>
        </>
      );
    case "COPY_CODE":
      return (
        <>
          <FiCopy size={SIZE} className="call" />
          <span className="help-link call">
            <p>{boton.text}</p>
          </span>
        </>
      );
    default:
      return (
        <>
          <IoArrowUndoSharp size={SIZE} className="call" />
          <p className="help-link call">{boton.text}</p>
        </>
      );
  }
};

export const getTemplatesOptions = (data) => {
  if (data) {
    let filtroFlows = data.filter(
      (flow) => !flow.name.toLowerCase().startsWith("sample")
    );
    filtroFlows = filtroFlows.map((template) => {
      return { value: template.id, label: template.name };
    });

    return filtroFlows;
  }

  return [];
};

// export const renderButtonInput = (
//   index,
//   boton,
//   buttonsVars,
//   setButtonsVars,
//   varOptions = []
// ) => {
//   let indices = buttonsVars?.map((element) => element.index);

//   if (!indices.includes(index)) {
//     return <></>;
//   }

//   let obj = handleAddonVariables(boton, index);

//   return (
//     <>
//       <span style={{ width: "100%", padding: "0px 8px" }}>
//         <ButtonsVariableInput
//           index={index}
//           boton={boton}
//           buttonsVars={buttonsVars}
//           setButtonsVars={setButtonsVars}
//           placeholder={obj.placeholder}
//           varOptions={varOptions}
//         />
//       </span>
//     </>
//   );
// };

export const renderHTMLContent = (templateVariables) => {
  ["header", "body", "footer"].forEach((seccion) => {
    let content = templateVariables?.[seccion]?.value;

    if (content) {
      let element = document.getElementById(`template-${seccion}`);

      if (element) {
        element.innerHTML = formatoTextHtml(content);
      }
    }
  });
};

export const handleInputCoordinates = (
  type,
  value,
  setViewport,
  setMarker,
  messageApi
) => {
  try {
    value = parseFloat(value);

    if (value < -90) {
      value = -90;
    }

    if (value > 90) {
      value = 90;
    }

    if (!value) {
      value = 0;
    }

    setViewport((prevState) => {
      let obj = { ...prevState };

      obj[type] = value;

      setMarker((prevState) => {
        let mark = { ...prevState };

        mark.longitude = mark.longitude || 0;
        mark.latitude = mark.latitude || 0;
        mark[type] = value;

        return mark;
      });

      return obj;
    });
  } catch (error) {
    messageApi.info("El valor ingresado no es válido");
  }
};

export const addMarker = (e, setMarker) => {
  let obj = {
    longitude: e.lngLat?.lng,
    latitude: e.lngLat?.lat,
  };

  setMarker(obj);
};

export const SECTIONS = ["HEADER", "BODY", "FOOTER", "BUTTONS", "CAROUSEL"];

export const handleTemplateVariables = (
  templateSeleccionado,
  setHeaderMediaType,
  buttonsVars,
  setButtonsVars,
  prevData,
  setTemplateVariables
) => {
  if (templateSeleccionado?.id) {
    let components = templateSeleccionado.components;

    if (components) {
      SECTIONS.forEach((section) => {
        let item = components.find(
          (component) => component?.type?.toUpperCase() === section
        );

        if (item) {
          if (section === "HEADER") {
            if (item.format?.toLowerCase() !== "text") {
              setHeaderMediaType(item.format.toLowerCase());
            }
          }

          if (section === "BUTTONS") {
            getButtonVars(item?.buttons, buttonsVars, setButtonsVars);
          }

          getVariablesCount(
            item.text ? item.text : item?.buttons ? item.buttons : "",
            section,
            item.format ? item.format : "TEXT",
            prevData,
            setTemplateVariables,
            setHeaderMediaType,
            templateSeleccionado
          );
        } else {
          setTemplateVariables((prevState) => {
            return {
              ...prevState,
              [section.toLowerCase()]: null,
            };
          });
        }
      });
    }
  }
};
