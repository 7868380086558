import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { Spin, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getContactName } from "../../../../campaigns/campaignBuilder/utils/getContactName";
import { getBlacklist } from "../../../../../../../helpers/fetchData/fetchBlacklist";
import LoadingIcon from "../../../../../Loader/LoadingIcon";
import { BuscadorBloqueos } from "../bloqueos/BuscadorBloqueos";
import { AccionesBloqueos } from "../bloqueos/AccionesBloqueos";

export const TablaBloqueos = () => {
  const { messageApi, instance, contactos } = useContext(GlobalContext);

  const [bloqueados, setBloqueados] = useState([]);
  const [loadingBloqueados, setLoadingBloqueados] = useState(false);

  useEffect(() => {
    getBlacklist(instance, messageApi, setBloqueados, setLoadingBloqueados);
  }, [instance]);

  // Datos a mostrar en la tabla:
  const dataTable = bloqueados.map((item) => {
    const dataItem = {
      id: item._id,
      name: nombreContacto(item),
      phoneNumber: item.number,
    };

    return dataItem;
  });

  const columnasBloqueados = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      onFilter: (value, item) =>
        item.name.toLowerCase().trim().includes(value.toLowerCase().trim()),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <BuscadorBloqueos
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: <SearchOutlined />,
    },
    {
      title: "Teléfono",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (data) => <p className="monospace-font">{data}</p>,
    },
    {
      title: "...",
      dataIndex: "",
      key: "",
      render: (_, item) => (
        <AccionesBloqueos item={item} setBloqueados={setBloqueados} />
      ),
    },
  ];

  function nombreContacto(item) {
    const contacto = contactos.find(
      (i) => i.phones?.[0]?.phone === item.number?.toString()
    );
    return contacto && contacto.name
      ? getContactName(contacto.name)
      : "Sin agendar";
  }

  return (
    <div className="TablaBloqueos-container">
      <Spin
        style={{ marginTop: 50 }}
        spinning={loadingBloqueados}
        indicator={<LoadingIcon size="large" />}
      >
        <Table
          className="tabla-config"
          columns={columnasBloqueados}
          dataSource={dataTable || []}
          size="small"
          rowKey="_id"
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} bloqueados`,
          }}
        />
      </Spin>
    </div>
  );
};
