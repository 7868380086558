import { Input, Select, Spin } from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getProducts, getTotalByItem } from "../../utils/home";
import { LoadingOutlined } from "@ant-design/icons";
import { ProductCard } from "./ProductCard";
import { PresupuestosContext } from "../../context/PresupuestosContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { loadMoreArticles } from "../../utils/infiniteScroll";
import { getProductsCompleteData } from "../../../../helpers/fetchData/fetchProductsLists";
import LoadingIcon from '../../../../../src/components/ui/Loader/LoadingIcon';

export const Home = ({ edit = false }) => {
  const {
    catalogDetail,
    setCatalogDetail,
    cartDetail,
    setCartDetail,
    allProducts,
    setAllProducts,
    selectedList,
    setSelectedList,
    presupuestoDrawer,
    setPayload,
  } = useContext(PresupuestosContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [busqueda, setBusqueda] = useState("");

  const [loading, setLoading] = useState(false);

  const [visibleArticles, setVisibleArticles] = useState([]);

  const containerRef = useRef(null);

  const idsCart = useMemo(() => {
    return cartDetail?.map((element) => element.id);
  }, [cartDetail]);

  useEffect(() => {
    if (catalogDetail?.lists) {
      return;
    }

    setLoading(true);
    getProductsCompleteData(instance?.userData?.apiKey)
      .then((result) => {
        if (result.status === 200) {
          setCatalogDetail(result.data);
          getProducts(result.data.products, setAllProducts);
        } else {
          messageApi.error("Ocurrió un error al obtener detalles del catálogo");
        }
      })
      .catch((error) =>
        messageApi.error("Ocurrió un error al obtener detalles del catálogo")
      )
      .finally(() => setLoading(false));
  }, []);

  const getFilteredProducts = useMemo(() => {
    if (catalogDetail?.products) {
      let listName =
        catalogDetail.lists.find((element) => element._id === selectedList)
          ?.name || "";

      if (selectedList === null) {
        return [];
      }

      let productsOnTheList =
        catalogDetail.lists?.find((lista) => lista._id === selectedList)
          ?.products || [];

      if (selectedList === "sin lista") {
        let idsOnLists = [];

        catalogDetail.lists.forEach((lista) => {
          if (lista.products?.length > 0) {
            idsOnLists.push(...lista.products);
          }
        });

        catalogDetail.products?.forEach((product) => {
          if (!idsOnLists.includes(product.id)) {
            productsOnTheList.push(product.id);
          }
        });
      }

      if (productsOnTheList?.length) {
        let productsArray = catalogDetail?.products?.filter((product) =>
          productsOnTheList.includes(product.id)
        );

        if (busqueda) {
          productsArray = productsArray?.filter((product) =>
            product.title?.toLowerCase()?.includes(busqueda.toLowerCase())
          );
        }

        return productsArray.map((element) => {
          return { ...element, listName: listName };
        });
      }
    }

    return [];
  }, [catalogDetail, selectedList, busqueda]);

  const handleScroll = () => {
    const container = containerRef.current;

    if (!container) return;

    const windowHeight = container.clientHeight;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;

    if (windowHeight + scrollTop >= scrollHeight - 100) {
      // Near the bottom of the container, load more articles
      loadMoreArticles(
        visibleArticles,
        setVisibleArticles,
        getFilteredProducts
      );
    }
  };

  useEffect(() => {
    // Load the initial batch of articles
    if (getFilteredProducts?.length > 0) {
      if (visibleArticles?.length < 1) {
        loadMoreArticles(
          visibleArticles,
          setVisibleArticles,
          getFilteredProducts
        );
      }

      // Add the scroll event listener to the container
      const container = containerRef.current;
      container.addEventListener("scroll", handleScroll);

      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [visibleArticles, getFilteredProducts]);

  useEffect(() => {
    setVisibleArticles([]);
  }, [selectedList]);

  useEffect(() => {
    if (presupuestoDrawer?.edit) {
      if (Array.isArray(presupuestoDrawer.data?.payload)) {
        setCartDetail(presupuestoDrawer.data.payload);
      }

      if (presupuestoDrawer?.data) {
        setPayload({
          hash: null,
          payload: {
            clientId: presupuestoDrawer.data?.clientId,
            userNumber: null,
          },
        });
      }
    }
  }, [presupuestoDrawer]);

  const listOptions = useMemo(() => {
    return [
      ...(catalogDetail?.lists || []),
      { _id: "sin lista", name: "Sin lista" },
    ]?.map((lista) => {
      let label = lista.name;

      if (lista.id) {
        label += ` - ${lista.id}`;
      }

      return {
        value: lista._id,
        label: label,
      };
    });
  }, [catalogDetail]);

  useEffect(() => {
    if (listOptions?.length > 0) {
      setSelectedList(listOptions[0]?.value);
    }
  }, [listOptions]);

  return (
    <div className="home-wrapper" style={{ gap: 8 }}>
      <span className="search-wrapper">
        <Select
          style={{ width: "100%" }}
          options={listOptions}
          value={selectedList}
          placeholder="Seleccione una lista"
          onChange={(v) => setSelectedList(v)}
        />
      </span>
      <span className="search-wrapper">
        <Input.Search
          placeholder="Buscar productos..."
          value={busqueda}
          onChange={(v) => setBusqueda(v.target.value)}
        />
      </span>
      {loading ? (
        <Spin 
          style={{ marginTop: "40%" }}
          indicator={
            <LoadingIcon size="large" />
          }
        />
      ) : (
        <div className="product-list" ref={containerRef}>
          {visibleArticles?.map((producto) => {
            let total = getTotalByItem(
              producto.id,
              idsCart,
              allProducts,
              cartDetail
            );

            return (
              <ProductCard
                producto={producto}
                total={total}
                idsCart={idsCart}
                key={producto.id}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
