import { Button, Form, Input, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../context/FlowContext';
import { useTranslateNodes } from './utils/useTranslateNodes';
import axios from 'axios';
import { GlobalContext } from '../../../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { newFlow } from '../../../../helpers/fetchData/fetchFlows';
import LoadingIcon from '../../Loader/LoadingIcon';

export const ConfirmFlow = ({ edit, type }) => {
  const [form] = Form.useForm();

  const { flowVariables, setShowConfirm, edges, nodes, flowAEditar, filters } =
    useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const navigate = useNavigate();

  const { errorInt, steps, triggers, flowVars } = useTranslateNodes(
    nodes,
    edges
  );

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    // let control = null;

    // if (filters?.content?.length > 0) {
    //   control = {};
    //   control[permisosData.type] = permisosData.content;
    // }

    let flow = {
      publishedBy: null,
      publishedAt: null,
      createdAt: !edit ? Date.now() : flowAEditar?.flowData?.createdAt,
      updatedAt: !edit ? null : Date.now(),
      status: !edit ? 'draft' : flowAEditar?.flowData.status,
      description: values.description ? values.description : '',
      createdBy: !edit ? null : flowAEditar?.flowData?.createdBy,
      useContextVars: true,
      name: values.name ? values.name : '',
      trigger: triggers,
      steps: steps,
      flowVars: flowVars,
      type: type,
      filters: filters
    };

    //? LOS VALUES NULL VAN ASI, SE COMPLETAN EN BASE DE DATOS

    if (errorInt.length === 0) {
      let data = {
        flow: flow,
        flowArchitecture: {
          nodes: nodes,
          edges: edges,
          variablesNodos: flowVariables
        }
      };

      setLoading(true);

      if (!edit) {
        newFlow(instance?.userData?.apiKey, data)
          .then((res) => {
            if (res?.status === 200) {
              messageApi.open({
                type: 'success',
                content: 'Bot editado correctamente',
                style: {
                  position: 'absolute',
                  bottom: '16px',
                  right: '16px'
                }
              });
              navigate('/flows');
            } else {
              messageApi.error('No se pudo guardar el Bot');
            }
          })
          .catch((error) => {
            messageApi.error('No se pudo guardar el Bot');
          })
          .finally(() => setLoading(false));
      } else {
        // EDIT FLOW
        data.idFlow = flowAEditar.flowData._id;
        data.idFlowArchitecture = flowAEditar.flowArchitectureData._id;

        let config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_PRODUCCION}/flows/editFlow`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: instance?.userData?.apiKey
          },
          data: data
        };

        axios
          .request(config)
          .then((res) => {
            if (res?.status === 200) {
              messageApi.open({
                type: 'success',
                content: 'Bot editado correctamente',
                style: {
                  position: 'absolute',
                  top: '16px',
                  right: '16px'
                }
              });
              navigate('/flows');
            } else {
              messageApi.error('No se pudo editar el Bot');
            }
          })
          .catch((error) => {
            messageApi.error('No se pudo editar el Bot');
          })
          .finally(() => setLoading(false));
      }
    }
  };

  useEffect(() => {
    if (edit) {
      if (flowAEditar) {
        form.setFieldsValue({
          name: flowAEditar.flowData?.name,
          description: flowAEditar.flowData?.description
        });
      }
    }
  }, []);

  return (
    <div className='confirm-wrapper' onClick={() => setShowConfirm(false)}>
      <div className='confirm-form' onClick={(e) => e.stopPropagation()}>
        {!loading ? (
          <Form
            form={form}
            layout='vertical'
            name='editarNodo'
            style={{ padding: '24px' }}
            requiredMark={false}
            onFinish={(v) => onFinish(v)}
            className='form-nodos'
          >
            <Form.Item style={{ marginBottom: '12px' }}>
              <span className='form-title' style={{ fontSize: 16 }}>
                Confirmar bot
              </span>
            </Form.Item>
            {errorInt.length === 0 ? (
              <>
                <Form.Item
                  name='name'
                  label='Nombre'
                  rules={[
                    {
                      required: true,
                      message: 'Campo requerido'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name='description' label='Descripción'>
                  <Input.TextArea />
                </Form.Item>
              </>
            ) : (
              <Form.Item>
                <div className='errors-wrapper'>
                  {errorInt.map((error, index) => {
                    return <span key={index}>{error}</span>;
                  })}
                </div>
              </Form.Item>
            )}
            <Form.Item style={{ marginBottom: '0px' }}>
              <div className='botones-wrapper-content'>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='btn-guardar'
                  size='large'
                  disabled={errorInt.length > 0 ? true : false}
                >
                  Guardar
                </Button>
                <Button
                  className='btn-cancelar'
                  size='large'
                  type='secondary'
                  onClick={() => {
                    setShowConfirm(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </Form.Item>
          </Form>
        ) : (
          <Spin
            style={{
              margin: 50
            }}
            indicator={<LoadingIcon size='large' />}
          />
        )}
      </div>
    </div>
  );
};
