import { Button, Form, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { Cron } from 'react-js-cron';
import { CUSTOM_LOCALE } from "../../../enviosMasivos/enviosBuilder/utils/customLocale";
import LoadingIcon from '../../../../Loader/LoadingIcon';
import { upsertClientConfig } from "../../../../../../helpers/fetchData/fetchClientConfig";

export const ClientsLoadCron = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setClientsConfig,
  setMostrarRequestDrawer,
  chkCron, 
  setChkCron
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [cronValue, setCronValue] = useState('30 * * * *');

  const onFinish = async (values) => {
    try {

      // console.log('fromCron onFinish: ', values)
      let dataCron = {};

      if (!cronValue) {
        // Validar
        messageApi.info('No es un valor válido');
        return;
      }

      dataCron.schedule = [cronValue];

      dataCron.enable = values.chkEnableCron;

      let clientsLoad = {
        request: tmpConfig.clientsLoad?.request,
        fullRequest: tmpConfig.clientsLoad.fullRequest,
        objMap: tmpConfig.clientsLoad?.objMap,
        cron: dataCron,
        bajaClient: values.chkEnableBaja
      };

      let data = { clientsLoad: clientsLoad };
      // console.log('data', data)
      setLoading(true);

      upsertClientConfig(instance?.userData?.apiKey, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Configuración guardada correctamente");
            setClientsConfig((prevState) => {
              let obj = { ...prevState };

              obj.clientsLoad = clientsLoad;

              return obj;
            });
            setMostrarRequestDrawer(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));


    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tmpConfig?.clientsLoad) {
      const { cron, bajaClient } = tmpConfig.clientsLoad;

      if (cron) {
        // console.log('UE CRON: ', cron)
        form.setFieldsValue({
          chkEnableCron: cron.enable,
        });

        if (cron.enable) setChkCron(cron.enable);

        setCronValue(cron.schedule[0]);
      }

      if (bajaClient) {
        //console.log('UE bajaClient: ', bajaClient)
        form.setFieldsValue({
          chkEnableBaja: bajaClient,
        });
      }
    }
  }, []);


  const onChangeCronSwitch = (v) => {
    setChkCron(!chkCron);
  };


  //console.log('cronValue', cronValue)

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >

      <b style={{ marginBottom: "12px" }}>Configuración adicional</b>
      <span style={{ marginBottom: "24px" }}>
        <BoxInfo
          message={[
            "Configure la frecuencia con la que se hará la petición.",
            "Se realizará de manera automática en caso de ser activada.",
          ]}
        />
      </span>


      <div style={{ display: "flex", justifyContent: "space-beetween", gap: "16px", marginTop: "20px" }}>
        <Form.Item name="chkEnableCron">
          <Switch onChange={onChangeCronSwitch} />
        </Form.Item>
        <Cron
          value={cronValue}
          setValue={setCronValue}
          clearButton={false}
          locale={CUSTOM_LOCALE}
        //disabled={!chkCron}
        />
      </div>

      <span style={{ marginTop: "16px" }}>
        <BoxInfo
          message={[
            "Configure qué tratamiento se le dará a los clientes eliminados.",
            "Se darán de baja los clientes en caso de ser activada.",
          ]}
        />
      </span>
      <div className="baja-clients">
        <Form.Item name="chkEnableBaja" initialValue={false}>
          <Switch checkedChildren="Dar de baja" unCheckedChildren="Mantener activos" />
        </Form.Item>
      </div>

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => handleStage(1)}
          >
            Volver
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Guardar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
