import { Form } from "antd";
import { BoxInfo } from "../box-info/BoxInfo";
import { VariableInput } from "../variableInput/VariableInput";

export const TemplateBody = ({
  templateVariables,
  setTemplateVariables,
  varOptions,
}) => {
  return (
    <>
      {templateVariables?.body?.value &&
        templateVariables?.body?.type?.toUpperCase() === "TEXT" && (
          <>
            <Form.Item label={<b style={{ fontSize: 15 }}>Cuerpo</b>}>
              <pre className="template-section-value" id="template-body"></pre>
            </Form.Item>
            <span style={{ padding: "8px 0px" }}></span>
            {templateVariables?.body?.placeholder?.length > 0 && (
              <>
                <BoxInfo
                  message="Puede cambiar el modo de ingreso haciendo click en el
                        indicador de variable."
                />
                <Form.Item>
                  {templateVariables?.body?.placeholder?.map((item, index) => {
                    return (
                      <VariableInput
                        item={item}
                        index={index}
                        section="body"
                        templateVariables={templateVariables}
                        setTemplateVariables={setTemplateVariables}
                        key={`bodyVar-${index}`}
                        varOptions={varOptions}
                      />
                    );
                  })}
                </Form.Item>
              </>
            )}
          </>
        )}
    </>
  );
};
