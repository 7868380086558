import { Drawer, Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { NewCategory } from "./forms/NewCategory";
import { NewSavedMessage } from "./forms/NewSavedMessage";
import { savedRepliesColumns, expandedRowRender } from "./column";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { getGroupReplies } from "../../../../../../../helpers/fetchData/fetchSavedMessages";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const SavedMessages = ({ mensajesDrawer, setMensajesDrawer }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [grupos, setGrupos] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleDrawerContent = () => {
    switch (mensajesDrawer.content) {
      case "categoria":
        return (
          <NewCategory
            setMensajesDrawer={setMensajesDrawer}
            setGrupos={setGrupos}
          />
        );
      case "mensaje":
        return (
          <NewSavedMessage
            mensajesDrawer={mensajesDrawer}
            setMensajesDrawer={setMensajesDrawer}
            setGrupos={setGrupos}
          />
        );
      case "editarCategoria":
        return (
          <NewCategory
            setMensajesDrawer={setMensajesDrawer}
            setGrupos={setGrupos}
            edit={true}
            mensajesDrawer={mensajesDrawer}
          />
        );
      case "newSavedMessageCategory":
        return <></>;
      default:
        break;
    }
  };

  useEffect(() => {
    if (instance?._id) {
      setLoading(true);
      getGroupReplies(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setGrupos(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener los mensajes");
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error("Ocurrió un error al obtener los mensajes");
        })
        .finally(() => setLoading(false));
    }
  }, [instance]);

  return (
    <div>
      {/* <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <LuSquareSlash size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">
                Mensajes predeterminados
              </span>
            </span>
            <p className="header-subtitle">
              Administre mensajes predeterminados para sus chats
            </p>
          </span>
          <div className="espacio-botones">
            {isAllowed(accessDataFormat("savedMessages", "manage")) && (
              <Button
                className="btn-agregar boton-dashed"
                onClick={() =>
                  setMensajesDrawer({
                    visible: true,
                    content: "categoria",
                    item: null,
                  })
                }
              >
                <FiPlus /> Categoría
              </Button>
            )}
          </div>
        </span>
      </div> */}
      {/* <Tabs  /> */}
      <Table
        dataSource={grupos || []}
        columns={savedRepliesColumns(setMensajesDrawer, setGrupos)}
        loading={{
          spinning: loading,
          indicator: <LoadingIcon size="large" />,
        }}
        rowKey={"_id"}
        size="small"
        pagination={false}
        className="tabla-config"
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record, setGrupos),
          expandRowByClick: true,
          rowExpandable: (record) => record.savedReplies.length > 0,
        }}
      />
      <Drawer open={mensajesDrawer.visible} width={450}>
        {handleDrawerContent()}
      </Drawer>
    </div>
  );
};
