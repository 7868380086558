import { FaArrowRight } from "react-icons/fa";

export const Content2 = () => {
  return (
    <section className="landing-section" style={{ alignItems: "center" }}>
      {/* Izq */}
      <div
        className="landing-section-column"
        style={{ width: "50%", height: "fit-content" }}
      >
        <span className="horizontal gap-8" style={{ marginBottom: 24 }}>
          <span className="landing-linea-blanca"></span>
          <p className="landing-datos-upper-text">
            Guía a tus clientes a interacciones automáticas
          </p>
        </span>
        <h3 className="landing-section-title">
          <span className="landing-font-highlight">Construya en minutos</span>
          <br />
          su propio bot
        </h3>

        <p className="landing-section-subtitle">
          Crea flujos de chat en un abrir y cerrar de ojos. brocoly es una
          <br /> solución flexible que se adapta a tus necesidades específicas,
          <br /> permitiéndite diseñar de forma sencilla incluso los flujos más
          <br /> complejos
        </p>

        <span className="horizontal gap16" style={{ marginTop: 50 }}>
          <button className="landing-boton-generico landing-boton-bordered">
            <p>Saber más</p>
            <FaArrowRight />
          </button>
          <button className="landing-boton-generico landing-boton-bordered">
            <p>Descargar flyers</p>
            <FaArrowRight />
          </button>
        </span>
      </div>
      <div className="landing-section-column" style={{ width: "50%" }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/landing-images/section2.png`}
          alt="section1"
          className="landing-image-2"
        />
      </div>
    </section>
  );
};
