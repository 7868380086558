import React, { useContext } from "react";
import { Handle, Position } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { useBorrarNode } from "../utils/useBorrarNode";
import { TbTrash } from "react-icons/tb";
import { AiOutlineQuestionCircle } from "react-icons/ai";

export const DefaultNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
      // style={{width: "100px", height: "100px"}}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "50%" }}
        isConnectableStart={false}
      />
      <AiOutlineQuestionCircle size={32} />
    </div>
  );
};
