import { useContext, useEffect, useState } from 'react';
import { Button, Drawer, Form, Input } from 'antd';
import LoadingIcon from '../../../../../Loader/LoadingIcon';
import { editMediaResource } from '../../../../../../../helpers/fetchData/fetchMedia';
import { GlobalContext } from '../../../../../../context/GlobalContext';

export const DrawerEditFile = ({ item, setFiles, open, setOpen }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [editFileForm] = Form.useForm();

  useEffect(() => {
    if (item) {
      editFileForm.setFieldsValue({
        name: item.name || '',
        caption: item.caption || ''
      });
    }
  }, [editFileForm, item]);

  const handleFinish = (values) => {
    const data = { ...values, _id: item._id };

    if (instance) {
      setLoading(true);
      editMediaResource(instance, data)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Archivo editado correctamente');

            setFiles((prevState) => {
              let array = [...prevState];

              const index = array.findIndex((file) => file._id === item._id);

              if (index >= 0) {
                array[index] = { ...array[index], ...values };
              }

              return array;
            });

            setOpen(false);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('Error al editar el archivo');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Drawer
      styles={{ header: { display: 'none' }, body: { padding: '24px' } }}
      width={500}
      placement='right'
      open={open}
      closeIcon={null}
      onClose={() => {
        setOpen(false);
      }}
      destroyOnClose
    >
      <Form
        className='form-nodos'
        style={{ overflow: 'hidden', padding: 0 }}
        layout='vertical'
        form={editFileForm}
        name='editFile'
        onFinish={handleFinish}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>Editar archivo</p>

        <Form.Item
          label='Nombre'
          name='name'
          rules={[
            {
              required: true,
              message: 'Nombre requerido'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Descripción' name='caption'>
          <Input />
        </Form.Item>

        <Form.Item className='form-custom-footer form-contact-footer'>
          <div className='botones-wrapper-content'>
            <Button
              type='primary'
              className='btn-guardar'
              size='large'
              icon={
                loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
              htmlType='submit'
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              size='large'
              type='secondary'
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
