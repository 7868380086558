import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { TbTrash } from "react-icons/tb";
import { Handle, Position } from "reactflow";
import { FiClock } from "react-icons/fi";
import { Tag } from "antd";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { getNodeName } from "../utils/getNodeName";

export const TimeNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const Dias = ({ weekdays }) => {
    let spans = <></>;

    const handleName = (day) => {
      switch (day) {
        case 0:
          return "D";
        case 1:
          return "L";
        case 2:
          return "M";
        case 3:
          return "X";
        case 4:
          return "J";
        case 5:
          return "V";
        case 6:
          return "S";
        default:
          return "";
      }
    };

    if (weekdays.length > 0) {
      if (weekdays.includes(0)) {
        weekdays = weekdays.filter((day) => day !== 0);
        weekdays = weekdays.sort();
        weekdays.push(0);
      } else {
        weekdays = weekdays.sort();
      }

      spans = weekdays.map((day) => {
        return (
          <span
            key={day}
            className="dia-item dia-selected"
            style={{ width: "18px", height: "18px", fontSize: "12px" }}
          >
            {handleName(day)}
          </span>
        );
      });
    }

    return <>{spans}</>;
  };

  const formatHorario = (time) => {
    let horas = "";

    const concatHora = (object) => {
      horas += `${object.hour}:${object.minute}`;
    };

    if (time) {
      concatHora(time.from);
      horas += ` - `;
      concatHora(time.to);
    }

    return horas;
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <FiClock style={{ color: "var(--dark-color)" }} size={32} />
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data?.label}</span>
        </div>
      </div>
      <Tag color="green" style={{ alignSelf: "start" }}>
        flow_response
      </Tag>
      <div className="branch-conditions-wrapper">
        {node?.data?.payload?.map((caso, index) => {
          if (caso.value?.length > 0) {
            return (
              <span className="case-display case-time" key={index}>
                <span className="" style={{ gap: "4px" }}>
                  {caso.value.map((horario, indexHorario) => {
                    return (
                      <div
                        className="node-time-granularity"
                        key={`${index}-${indexHorario}`}
                      >
                        <span className="horizontal">
                          <Dias weekdays={horario.granularities?.weekdays} />
                        </span>
                        <span className="horizontal">
                          <p>{formatHorario(horario.granularities?.time)}</p>
                        </span>
                      </div>
                    );
                  })}
                </span>
                <span className="linea-cases"></span>
                <Handle
                  className="handle-date-time"
                  type="source"
                  position={Position.Right}
                  id={`${index + 1}`}
                  isConnectable={checkValidConnection(
                    edges,
                    node.id,
                    `${index + 1}`
                  )}
                />
              </span>
            );
          } else {
            <></>;
          }
        })}
        <span className="case-display case-default">
          <p>Otro...</p>
          <span className="linea-cases"></span>
          <Handle
            className="handle-date-time"
            type="source"
            position={Position.Right}
            id="default"
            isConnectable={checkValidConnection(edges, node.id, "default")}
          />
        </span>
      </div>
      <div className="path-id">{node?.id}</div>
    </div>
  );
};
