import { Form, Input } from "antd";
import React from "react";
import { agregarVariableWhatsapp } from "../../../../../../helpers/utilidadesTemplates";
import { BotonAgregarVariable } from "../BotonAgregarVariable";

export const Basic = ({ setAuthData, authData, nodeId }) => {
  const onChange = (value) => {
    setAuthData((prevState) => {
      let data = { ...prevState };

      data.object = { hash: value };

      data.type = "Basic";

      data.value = value;

      return data;
    });
  };

  const decorarTexto = (wrappingString, emoji, variable) => {
    setAuthData((prevState) => {
      let object = { ...prevState };

      object.value = agregarVariableWhatsapp(
        prevState.value,
        variable,
        "basicInput"
      );

      return object;
    });
  };

  const renderLabel = () => {
    // Para usarlo en nodos, flow u otro contexto
    if (nodeId != null) {
      return (
        <span
          className="horizontal"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <p>Hash Base64</p>
          <BotonAgregarVariable
            decorarTexto={decorarTexto}
            nodeId={nodeId}
            showContextVars={false}
          />
        </span>
      );
    } else {
      return "Hash Base64";
    }
  };

  return (
    <Form.Item label={renderLabel()}>
      <Input onChange={(v) => onChange(v.target.value)} id="basicInput" />
    </Form.Item>
  );
};
