import { Radio, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { TablaArticulos } from "./TablaArticulos";
import { TablaListas } from "./TablaListas";
import { SettingsContext } from "../../../../../context/SettingsContext";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { LoadingOutlined } from "@ant-design/icons";
import { getProductsCompleteData } from "../../../../../../helpers/fetchData/fetchProductsLists";
import { TablaEstados } from "./TablaEstados";
import { ProductsConfig } from "./ProductsConfig";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const CatalogContent = () => {
  const { instance, messageApi } = useContext(GlobalContext);
  const { setStage, selectedCatalog, setCatalogDetail } =
    useContext(SettingsContext);

  const [loading, setLoading] = useState(true);

  const [section, setSection] = useState("lists");

  const switchContent = () => {
    switch (section) {
      case "products":
        return <TablaArticulos fetchCatalogDetail={fetchCatalogDetail} />;
      case "lists":
        return <TablaListas />;
      case "statuses":
        return <TablaEstados />;
      case "config":
        return <ProductsConfig fetchCatalogDetail={fetchCatalogDetail} />;
      default:
        return <></>;
    }
  };

  const fetchCatalogDetail = () => {
    setLoading(true);
    if (instance?.userData?.apiKey) {
      getProductsCompleteData(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            setCatalogDetail(res.data);
          } else {
            messageApi.error(
              "Ocurrió un error al obtener detalles del catálogo"
            );
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener detalles del catálogo")
        )
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchCatalogDetail();
  }, [instance]);

  return (
    <div className="catalog-content">
      <span style={{ marginBottom: "16px" }}>
        {selectedCatalog?.name}{" "}
        <p
          style={{
            display: "inline",
            fontWeight: "500",
            color: "var(--primary-color)",
          }}
        >
          {selectedCatalog?.id}
        </p>
      </span>
      <span className="horizontal-between" style={{ marginBottom: "24px" }}>
        <Radio.Group
          onChange={(v) => setSection(v.target.value)}
          optionType="button"
          buttonStyle="solid"
          value={section}
          options={[
            { value: "lists", label: "Listas" },
            { value: "products", label: "Artículos" },
            { value: "statuses", label: "Estados" },
            { value: "config", label: "Configuración" },
          ]}
        />
      </span>
      {loading ? (
        <Spin
          style={{
            margin: 50,
          }}
          indicator={<LoadingIcon size="large" />}
        />
      ) : (
        switchContent()
      )}
    </div>
  );
};
