import { Button, Form, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { flattenFirstElementGetProducts } from "../../../../flows/utils/flattenJSON";
import { BoxInfo } from "../../../../../box-info/BoxInfo";
import { upsertBudgetConfig } from "../../../../../../../helpers/fetchData/fetchBudgetConfig";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { SettingsContext } from "../../../../../../context/SettingsContext";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const ProductsLoadGetExample = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  closeModal,
  setBudgetConfig,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const { catalogDetail } = useContext(SettingsContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleGuardar = (values) => {
    let objMap = {
      title: values.title,
      sku: values.sku,
      price: values.price,
      brand: values.brand,
      description: values.description,
    };

    let pricesByList = [];

    listas?.forEach((lista) => {
      if (values[lista?._id]) {
        pricesByList.push({ listId: lista._id, value: values[lista._id] });
      }
    });

    objMap.pricesByList = pricesByList;

    let productsLoad = {
      request: tmpConfig.productsLoad?.request,
      objMap: objMap,
    };

    let data = { productsLoad: productsLoad };

    setLoading(true);
    upsertBudgetConfig(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Configuración guardada correctamente");
          setBudgetConfig((prevState) => {
            let obj = { ...prevState };

            obj.productsLoad = productsLoad;

            return obj;
          });
          closeModal();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const keyOptions = useMemo(() => {
    if (result) {
      let flattenedArray = flattenFirstElementGetProducts(result);

      return (
        flattenedArray?.map((element) => {
          return { value: element, label: element };
        }) || []
      );
    }

    return [];
  }, [result]);

  const listas = useMemo(() => {
    return catalogDetail?.lists || [];
  }, [catalogDetail]);

  useEffect(() => {
    if (tmpConfig?.productsLoad?.objMap) {
      const { title, sku, price, brand, description, pricesByList } =
        tmpConfig.productsLoad.objMap;

      form.setFieldsValue({
        title: title,
        sku: sku,
        price: price,
        brand: brand,
        description: description,
      });

      // if (pricesByList) {
      //   form.setFieldsValue({
      //     ...pricesByList,
      //   });
      // }
    }
  }, [tmpConfig, listas]);

  return (
    <div className="budget-config-wrapper fade-in">
      <b>Mapeo sobre el resultado de la petición</b>
      <BoxInfo
        message={
          "Necesitamos asociar las características de sus artículos para que coincidan con el formato utilizado en Brocoly."
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={handleGuardar}
        name="productsLoad"
        className="form-nodos"
      >
        <Form.Item
          name="title"
          label="Nombre"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select options={keyOptions} allowClear />
        </Form.Item>
        <Form.Item
          name="sku"
          label="SKU (Código de identificación)"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select options={keyOptions} allowClear />
        </Form.Item>
        <Form.Item
          name="price"
          label="Precio"
          rules={[{ required: true, message: "Campo requerido" }]}
        >
          <Select options={keyOptions} allowClear />
        </Form.Item>
        <Form.Item name="brand" label="Marca">
          <Select options={keyOptions} allowClear />
        </Form.Item>
        <Form.Item name="description" label="Descripción">
          <Select options={keyOptions} allowClear />
        </Form.Item>
        {listas?.length > 0 && (
          <div className="columna">
            <p style={{ fontWeight: 500 }}>Listas de precios</p>
            {listas.map((lista) => {
              return (
                <Form.Item
                  name={lista._id}
                  label={`${lista.name} - ${lista.id}`}
                  key={`${lista.name} - ${lista.id}`}
                >
                  <Select options={keyOptions} allowClear />
                </Form.Item>
              );
            })}
          </div>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <span className="horizontal-between" style={{ marginTop: 24 }}>
            <Button
              type="secondary"
              onClick={() => handleStage(0)}
              className="btn-cancelar"
              style={{ width: "fit-content" }}
            >
              Volver
            </Button>
            <Button
              className="btn-oscuro"
              type="primary"
              htmlType="submit"
              //loading={loading}
              icon={
                loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
          </span>
        </Form.Item>
      </Form>
    </div>
  );
};
