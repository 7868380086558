import { Form, Select } from "antd";

export const TemplateSelector = ({
  templateOptions,
  templates,
  setTemplateSeleccionado,
  templateSeleccionado,
  loadingTemplates,
}) => {
  return (
    <>
      <Form.Item
        label={<b style={{ fontSize: 15 }}>Plantilla</b>}
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select
          options={templateOptions}
          onChange={(v) => {
            setTemplateSeleccionado(
              templates?.filter((flow) => flow?.id === v)[0]
            );
          }}
          loading={loadingTemplates}
          value={loadingTemplates ? null : templateSeleccionado?.id}
          showSearch
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Seleccione una plantilla"
        />
      </Form.Item>
    </>
  );
};
