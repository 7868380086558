import axios from 'axios';

export const getFilesInBd = async (instance) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/storage/getFilesInBd`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getFolderSize = async (instance) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/storage/getFolderSize`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
