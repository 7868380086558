import { Button, Form, Select, Switch } from "antd";
import { useContext, useEffect, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { getLastNode } from "../utils/handleVariablesDisponibles";
import { borrarFallback } from "../utils/borrarFallback";
import { updateNode } from "../utils/updateNode";
import { useUpdateNodeInternals } from "reactflow";
import { newFallbackEdge } from "../utils/newFallbackEdge";
import { BoxInfo } from "../../../box-info/BoxInfo";

export const SaveResponseForm = () => {
  const {
    setMostrarDrawer,
    nodoSeleccionado,
    nodes,
    setNodes,
    edges,
    setEdges
  } = useContext(FlowContext);

  const [error, setError] = useState(false);

  const [options, setOptions] = useState([]);

  const [fallback, setFallback] = useState([]);

  const updateNodeInternals = useUpdateNodeInternals();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.error = { status: error };
    values.label = 'saveResponse';

    values.handleCount = 1;

    if (values.error?.status) {
      values.handleCount += 1;

      values.fallback = fallback;

      newFallbackEdge(nodoSeleccionado.id, fallback, setEdges);
    } else {
      setEdges((prevState) => borrarFallback(prevState, nodoSeleccionado.id));
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);

    updateNodeInternals(nodoSeleccionado.id);
  };

  useEffect(() => {
    let set = getLastNode(edges, nodoSeleccionado.id);
    let array = [];

    nodes.map((node) => {
      if (node.type !== 'trigger' && set.has(node.id)) {
        let string = `#${node.id} ${node.data?.label}`;
        let value = node.id;

        return array.push({ label: string, value: value });
      }
    });

    setOptions(array);
  }, [nodes]);

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.error?.status) {
        setError(data.error.status);
      }

      if (data.fallback) {
        setFallback(data.fallback);
      } else {
        let indice = edges.filter(
          (edge) => edge.target === nodoSeleccionado.id
        );

        if (indice.length > 0) {
          setFallback(indice[0].source);
        }
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formText'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <Form.Item>
        <span className='linea-esperar-switch' style={{ marginBottom: '0px' }}>
          <span> En caso de error</span>
          <Switch onChange={(v) => setError(v)} checked={error} />
        </span>
      </Form.Item>
      {error && (
        <div className='error-div'>
          <Form.Item label='Fallback'>
            <Select
              options={options}
              value={fallback}
              onChange={(v) => setFallback(v)}
              showSearch
              filterOption={(input, option) =>
                (option.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <BoxInfo
            message={`Al momento de elegir el paso "fallback" tenga en cuenta que debe
                ser uno anterior al actual. Revise que se obtengan todas las
                variables necesarias para continuar con el flujo.`}
          />
        </div>
      )}
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
