import { useContext, useMemo } from "react";
import { GlobalContext } from "../components/context/GlobalContext";
import { BROCOLY_INSTANCES } from "../utils/BROCOLY_INSTANCES";

export const useIsBrocolyAdminInstance = () => {
  const { instance } = useContext(GlobalContext);

  const isBrocolyAdminInstance = useMemo(() => {
    if (!instance?._id) {
      return false;
    }

    return BROCOLY_INSTANCES.includes(instance?._id);
  }, [instance]);

  return { isBrocolyAdminInstance };
};
