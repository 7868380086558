import axios from 'axios';

export const getFlows = (setFlows, messageApi, setLoading, instance) => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_PRODUCCION}/flows/getFlows`,
    headers: {
      Authorization: instance?.userData?.apiKey
    }
  };

  axios
    .request(config)
    .then((res) => {
      if (Array.isArray(res?.data)) {
        setFlows(res.data);
      } else {
        messageApi.error('Error al cargar flows');
      }
    })
    .catch((error) => {
      messageApi.error('Error al cargar flows');
    })
    .finally(() => setLoading(false));
};

export const updateFlowStatus = (
  data,
  messageApi,
  setFlows,
  setLoading,
  instance
) => {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_PRODUCCION}/flows/updateFlowStatus`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: instance?.userData?.apiKey
    },
    data: data
  };

  axios
    .request(config)
    .then((res) => {
      if (res?.status === 200) {
        setLoading(true);
        getFlows(setFlows, messageApi, setLoading, instance);
        if (data.newStatus === 'draft') {
          messageApi.success('Bot devuelto a borrador');
        } else {
          messageApi.success('Bot publicado');
        }
      } else {
        messageApi.error('No se pudo actualizar el estado del flow');
      }
    })
    .catch((error) => {
      messageApi.error('No se pudo actualizar el estado del flow');
    });
};

export const deleteFlow = (
  data,
  messageApi,
  setFlows,
  setLoading,
  instance,
  setLoadingBorrar,
  setShowPopover
) => {
  try {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/deleteFlow`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance?.userData?.apiKey
      },
      data: data
    };

    setLoadingBorrar(true);

    axios
      .request(config)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(true);
          getFlows(setFlows, messageApi, setLoading, instance);
          setShowPopover(false);
          messageApi.success('Bot eliminado');
        } else {
          messageApi.error('No se pudo eliminar el flow');
        }
      })
      .catch((error) => {
        messageApi.error('No se pudo eliminar el flow');
      })
      .finally(() => setLoadingBorrar(false));
  } catch (error) {
    console.log(error);
    messageApi.error('No se pudo eliminar el flow');
  }
};

export const newFlow = async (apiKey, data) => {
  try {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/newFlow`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      },
      data: data
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newDefaultFlow = async (apiKey, id) => {
  try {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/defaultFlow`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      },
      data: { id }
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getDefaultFlow = async (apiKey) => {
  try {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/defaultFlow`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      }
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newTest = async (apiKey, data) => {
  try {
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/newTest`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      },
      data: data
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getFlowLogs = async (apiKey, data) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/flows/getFlowLogs`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      },
      data: data
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
