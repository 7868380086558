import { Table, Tooltip } from "antd";
import { getContactName } from "../../../../campaigns/campaignBuilder/utils/getContactName";
import { MdOutlineBlock } from "react-icons/md";
import { useContext } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
// import { AccionesContactos } from '../../../../contactos/AccionesContactos';
import LoadingIcon from "../../../../../Loader/LoadingIcon";
import { AccionesArchivo } from "../accionesArchivados/AccionesArchivo";
import { EtiquetasXContactos } from "../../../../contactos/EtiquetasXContactos";

export const ArchivedContacts = () => {
  const { contactos, loadingContactos, visibleColumns } =
    useContext(GlobalContext);

  // Hacer una query que traiga esto.
  const archivedContactos = contactos.filter(
    (contacto) => contacto.isArchived === true
  );

  const columns = [
    {
      title: "Nombre completo",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      fixed: "left",
      render: (data, record) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              textDecoration: record.isBlocked ? "line-through" : "none",
            }}
          >
            {record.isBlocked && (
              <Tooltip title="Este contacto ha sido bloqueado">
                <span>
                  <MdOutlineBlock style={{ fontSize: 18, color: "red" }} />
                </span>
              </Tooltip>
            )}
            {getContactName(data)}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "emails",
      key: "emails",
      ellipsis: true,
      render: (data) => data[0]?.email,
    },
    {
      title: "Teléfono",
      dataIndex: "phones",
      key: "phones",
      width: 150,
      render: (data) => <p className="monospace-font">{data[0]?.phone}</p>,
    },
    {
      title: "Etiquetas",
      dataIndex: "tags",
      key: "tags",
      render: (data) => {
        if (!data) return <></>;
        return <EtiquetasXContactos data={data} />;
      },
    },
  ];

  // Campos adicionales
  if (visibleColumns) {
    visibleColumns.forEach((field) => {
      if (field.isVisible) {
        columns.push({
          title: field.label,
          key: field.key,
          dataIndex: field.key,
          ellipsis: true,
        });
      }
    });
  }

  columns.push({
    title: "...",
    dataIndex: "",
    align: "center",
    key: "",
    fixed: "right",
    render: (dataIndex, item) => <AccionesArchivo item={item} />,
  });

  return (
    <Table
      className="tabla-config"
      columns={columns}
      dataSource={archivedContactos || []}
      rowKey="_id"
      size="small"
      pagination={{
        pageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} contactos`,
      }}
      loading={{
        spinning: loadingContactos,
        indicator: <LoadingIcon size="large" />,
      }}
    />
  );
};

// const Etiquetas = ({ data }) => {
//   const { etiquetas } = useContext(GlobalContext);

//   const getEtiquetas = (tag) => {
//     return etiquetas.find((element) => element._id === tag);
//   };

//   return (
//     <div className='celda-tags'>
//       {data?.map((tag, index) => {
//         const obj = getEtiquetas(tag);

//         return (
//           <Tag hex={obj?.color} nombre={obj?.name} key={obj?._id || index} />
//         );
//       })}
//     </div>
//   );
// };
