export const clearInnerVars = (setFlowVariables, id, messageApi, section) => {
  setFlowVariables((prevState) => {
    return prevState?.filter((item) => {
      if (
        (item.idNodo === id && !item?.kind === section) ||
        item.idNodo !== id
      ) {
        return true;
      } else {
        return false;
      }
    });
  });
};
