const ACCESS_PERMISSION = {
  hide: 0,
  show: 1,
  manage: 2,
};

export const accessDataFormat = (
  module,
  action = "show",
  redirect = null,
  specific = null
) => {
  return {
    module: module,
    requiredLevel: ACCESS_PERMISSION[action],
    redirect: redirect,
    specific: specific,
  };
};
