import { useContext, useEffect, useMemo, useState } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { Handle, Position } from "reactflow";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { TbTrash } from "react-icons/tb";
import { GlobalContext } from "../../../../context/GlobalContext";
import { getEnviosMavisos } from "../../../../../helpers/fetchData/fetchEnviosMasivos";
import { Tag } from "antd";
import { FaLink } from "react-icons/fa6";
import { TbDeviceMobileX } from "react-icons/tb";
import { TbDeviceMobilePlus } from "react-icons/tb";

export const SubscriptionNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const { instance, messageApi } = useContext(GlobalContext);

  const [envios, setEnvios] = useState([]);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const borrarNode = useBorrarNode(node.id);

  const envioData = useMemo(() => {
    if (node.data?.idEnvioMasivo && envios) {
      return envios.find((envio) => envio._id === node.data.idEnvioMasivo);
    }

    return null;
  }, [envios, node]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      getEnviosMavisos(instance.userData.apiKey)
        .then((res) => {
          if (res?.status === 200 && Array.isArray(res?.data)) {
            setEnvios(res.data);
            return;
          }

          messageApi.error("Ocurrió un error al obtener envíos");
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener envíos")
        );
    }
  }, [instance]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div style={{ height: "32px" }}>
          <FaLink color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">
            {node.data?.addSubscription === false
              ? "Quitar suscripción"
              : "Suscribir contacto"}
          </span>
          <span className="mensaje-nodo">{node.data.label}</span>
        </div>
      </div>
      {envioData?.name && (
        <div className="horizontal gap8" style={{ margin: "8px 0px " }}>
          {node.data?.addSubscription === false ? (
            <TbDeviceMobileX />
          ) : (
            <TbDeviceMobilePlus />
          )}
          <Tag color="geekblue">{envioData.name}</Tag>
        </div>
      )}
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "12px" }}>
        {node?.id}
      </div>
    </div>
  );
};
