import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Spin, Input, Switch, Checkbox } from "antd";
import { useContext, useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { flattenFirstElement, flattenJSON } from "../utils/flattenJSON";
import { FlowContext } from "../../../../context/FlowContext";
import { GlobalContext } from "../../../../context/GlobalContext";
import {
  checkIsArray,
  checkResponse,
  isSaved,
  isSavedIndexed,
} from "../utils/requestVarsForm";
import { FilaCheck } from "./FilaCheck";
import {
  getVariablesFromText,
  isInterpolate,
  isJSON,
} from "../utils/httpRequest";
import { addUrlParams } from "../../../../../utils/addUrlParams";
import { testRequest } from "../../../../../helpers/fetchData/fetchTestRequest";

export const RequestVarsForm = ({
  setMostrarDrawerVars,
  method,
  url,
  body,
  bodyVars,
  hasBody,
  setVariables,
  nodeId,
  innerVars,
  indexedVars,
  setIsValid,
  setVariablesIDX,
  indexado,
  setIndexado,
  hideIndexed,
  urlParams,
  setUrlParams,
  headers,
  setHeaders,
}) => {
  const { flowVariables, nodoSeleccionado } = useContext(FlowContext);

  const { messageApi, instance } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [loadingProbar, setLoadingProbar] = useState(false);
  const [response, setResponse] = useState(null);
  const [tmpVars, setTmpVars] = useState([]);
  const [error, setError] = useState(null);

  const [errorData, setErrorData] = useState(null);

  const [test, setTest] = useState(false);
  const [urlTestValues, setUrlTestValues] = useState([]);
  const [tmpIndexado, setTmpIndexado] = useState(false);
  const [tmpIndexedVars, setTmpIndexedVars] = useState([]);

  // const [headerVars, setHeaderVars] = useState([]);
  const [bodyMockVars, setBodyMockVars] = useState([]);

  const handleTest = (url) => {
    try {
      setError(null);
      setErrorData(null);
      setLoadingProbar(true);

      let testUrl = url;

      if (urlParams?.length > 0) {
        if (
          urlParams.some((element) => {
            if (!element.key) return true;
            if (!element.value) return true;
            return false;
          })
        ) {
          messageApi.info("Todo parametro debe contener una key y value");
          setLoadingProbar(false);
          setError("Todo parametro debe contener una key y value");
          return;
        }

        testUrl = addUrlParams(url, urlParams);
      }

      const testHeaders = {};

      if (headers?.length > 0) {
        if (
          headers.some((element) => {
            if (!element.key) return true;
            if (!element.value) return true;
            return false;
          })
        ) {
          messageApi.info("Todo header debe contener una key y value");
          setLoadingProbar(false);
          setError("Todo header debe contener una key y value");
          return;
        }

        if (headers?.length > 0) {
          headers.forEach((header) => {
            let value = header.value;
            if (isInterpolate(value) || header.type === "var") {
              value = header.testValue;
            }
            testHeaders[header.key] = value;
          });
        }
      }

      let config = {
        method: method,
        url: testUrl,
        headers: testHeaders,
      };

      if (method === "POST" && hasBody) {
        if (isJSON(body) || bodyMockVars.length > 0) {
          if (bodyMockVars.length > 0) {
            let obj = {};
            bodyMockVars.forEach((element) => {
              obj[element.key] = element.value;
            });
            config.data = JSON.stringify(obj);
          } else {
            let obj = JSON.parse(body);

            let string = JSON.stringify(obj);

            config.data = string;
          }
        } else {
          setResponse(null);
          setError("El body ingresado no es un JSON");
          setLoadingProbar(false);
          return;
        }
      }

      testRequest(config, instance?.userData?.apiKey)
        .then((res) => {
          const contentLength = JSON.stringify(res?.data).length;

          const SIZE = 100000;
          if (contentLength > SIZE) {
            setError(
              `La respuesta a la petición excede el limite de ${SIZE} caracteres, una petición así podría interrumpir el funcionamiento del sistema.`
            );
          } else {
            setResponse(JSON.stringify(res.data, null, 2));
            setIsValid(true);
          }
        })
        .catch((error) => {
          if (error?.response?.data) {
            setErrorData(JSON.stringify(error.response.data, null, 2));
          }

          setResponse(null);

          setIsValid(false);
        })
        .finally(() => {
          setLoadingProbar(false);
        });
    } catch (error) {
      console.log("error");
      setResponse(null);

      setIsValid(false);
    }
  };

  const handleConfirmar = () => {
    let text = url;

    if (urlTestValues?.length > 0) {
      text = text.replace(/\${([^}]+)}/g, (match, key) => {
        const item = urlTestValues.find((item) => item.key === key);

        return item ? item.testValue : match;
      });
    }

    if (urlParams?.length > 0) {
      if (
        urlParams.some((element) => {
          if (!element.key) return true;
          if (!element.value) return true;
          return false;
        })
      ) {
        messageApi.info("Todo parametro debe contener una key y value");

        return;
      }

      if (
        urlParams.some((element) => {
          if (!element.type === "var" && element.testValue == null) {
            messageApi.info(`Ingrese un valor para probar ${element.key}`);
            return true;
          }
          return false;
        })
      ) {
        return;
      }
    }

    handleTest(text);
  };

  const onFinish = (v) => {
    setVariables(tmpVars);
    setVariablesIDX(tmpIndexedVars);
    setMostrarDrawerVars(false);
    setIndexado(tmpIndexado);
  };

  const checkTest = (url, bodyVars) => {
    let flag = false;

    if (isInterpolate(url)) {
      flag = true;

      const variablesNames = getVariablesFromText(url);

      const array = variablesNames.map((element) => {
        // Checkear si ya tiene valor (setVariables);
        const value =
          flowVariables?.find((variable) => variable?.name === element)
            ?.value || "";
        return {
          key: element,
          value: value,
          testValue: value,
        };
      });

      setUrlTestValues(array);
    }

    if (urlParams.some((element) => element.type === "var")) {
      flag = true;
    }

    if (urlParams.some((element) => isInterpolate(element.value))) {
      flag = true;
    }

    if (headers?.some((element) => element.type === "var")) {
      flag = true;
    }

    if (headers.some((element) => isInterpolate(element.value))) {
      flag = true;
    }

    if (bodyVars.length > 0) {
      flag = true;

      setBodyMockVars(
        bodyVars.map((element) => {
          return {
            name: element?.value?.replace("${", "")?.replace("}", ""),
            value: "",
            key: element.key,
          };
        })
      );
    }

    return flag;
  };

  useEffect(() => {
    if (response) {
      let array = flattenJSON(response);
      if (innerVars?.length > 0) {
        let base = innerVars?.map((item) => {
          let primero = item.name.substring(item.name.indexOf("_") + 1);
          return primero.substring(primero.indexOf("_") + 1);
        });

        setTmpVars(
          array.map((variable) => {
            return isSaved(
              variable,
              base,
              nodoSeleccionado,
              flowVariables,
              nodeId
            );
          })
        );
      } else {
        setTmpVars(
          array.map((variable) => {
            return { name: variable, type: "string", checked: false };
          })
        );
      }

      let indexedArray = flattenFirstElement(response);

      if (indexedVars?.length > 0) {
        let base = indexedVars?.map((item) => {
          return item.name.substring(item.name.indexOf("[idx]"));
        });

        setTmpIndexedVars(
          indexedArray?.map((variable) => {
            return isSavedIndexed(
              variable,
              base,
              nodoSeleccionado,
              flowVariables,
              nodeId
            );
          })
        );
      } else {
        setTmpIndexedVars(
          indexedArray?.map((variable) => {
            return {
              name: variable.name,
              display: variable.display,
              type: "iterableList",
              checked: false,
            };
          })
        );
      }
    }
  }, [response]);

  useEffect(() => {
    if (url) {
      if (checkTest(url, bodyVars)) {
        setTest(true);
      } else {
        handleTest(url);
      }
    } else {
      handleTest(url);
    }
  }, [method, url, body, headers, hasBody, bodyVars]);

  useEffect(() => {
    if (indexado !== null) {
      setTmpIndexado(indexado);
    }
  }, []);

  return (
    <div className="drawer-vars">
      <Form
        form={form}
        layout="vertical"
        name="formVars"
        requiredMark={false}
        onFinish={(v) => onFinish(v)}
        className="form-nodos"
      >
        <b style={{ marginBottom: 8 }}>Prueba</b>
        {test && (
          <>
            {isInterpolate(url) &&
              urlTestValues.map((element, index) => {
                return (
                  <Form.Item
                    label={`Ingrese un valor para probar ${element.key}`}
                    key={`url-var-${index}`}
                  >
                    <span className="horizontal">
                      <Input
                        value={element.testValue}
                        onChange={(e) =>
                          setUrlTestValues((prevState) => {
                            let array = [...prevState];

                            array[index] = {
                              ...array[index],
                              testValue: e.target.value,
                            };

                            return array;
                          })
                        }
                      />
                    </span>
                  </Form.Item>
                );
              })}
            {urlParams?.length > 0 && (
              <>
                <b>Parámetros</b>
                {urlParams.map((element, index) => {
                  return (
                    <Form.Item
                      label={
                        element.type === "var" || isInterpolate(element.value)
                          ? `Ingrese un valor para probar ${element.key}`
                          : element.key
                      }
                      key={`urlvar-${index}`}
                    >
                      <Input
                        value={
                          element.type === "var" || isInterpolate(element.value)
                            ? element.testValue
                            : element.value
                        }
                        onChange={(e) =>
                          setUrlParams((prevState) => {
                            let array = [...prevState];

                            array[index] = {
                              ...array[index],
                              testValue: e.target.value,
                            };

                            return array;
                          })
                        }
                        disabled={
                          element.type === "text" &&
                          !isInterpolate(element.value)
                        }
                      />
                    </Form.Item>
                  );
                })}
              </>
            )}

            {headers?.length > 0 && (
              <>
                <b>Headers</b>
                {headers.map((element, index) => {
                  return (
                    <Form.Item
                      label={
                        element.type === "var" || isInterpolate(element.value)
                          ? `Ingrese un valor para probar ${element.key}`
                          : element.key
                      }
                      key={`urlvar-${index}`}
                    >
                      <Input
                        value={
                          element.type === "var" || isInterpolate(element.value)
                            ? element.testValue
                            : element.value
                        }
                        onChange={(e) =>
                          setHeaders((prevState) => {
                            let array = [...prevState];

                            array[index] = {
                              ...array[index],
                              testValue: e.target.value,
                            };

                            return array;
                          })
                        }
                        disabled={
                          element.type === "text" &&
                          !isInterpolate(element.value)
                        }
                      />
                    </Form.Item>
                  );
                })}
              </>
            )}

            {/* {headerVars.length > 0 && (
              <Form.Item label="Variable de autenticación">
                {headerVars.map((element, index) => {
                  return (
                    <Input
                      key={index}
                      value={element.value}
                      onChange={(v) =>
                        setHeaderVars((prevState) => {
                          let array = [...prevState];

                          array[index].value = v.target.value;
                          return array;
                        })
                      }
                    />
                  );
                })}
              </Form.Item>
            )} */}
            {bodyMockVars?.length > 0 && (
              <Form.Item label="Variables body">
                <div className="columna">
                  {bodyMockVars.map((element, index) => {
                    return (
                      <Input
                        key={index}
                        value={element.value}
                        placeholder={element.key}
                        onChange={(v) =>
                          setBodyMockVars((prevState) => {
                            let array = [...prevState];

                            array[index].value = v.target.value;

                            return array;
                          })
                        }
                      />
                    );
                  })}
                </div>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                onClick={handleConfirmar}
                className="btn-guardar btn-oscuro"
                type="primary"
              >
                Confirmar
              </Button>
            </Form.Item>
          </>
        )}
        {error && <p style={{ fontSize: "18px" }}>{error}</p>}

        {errorData && (
          <div style={{ margin: "8px 0px" }}>
            <b>Error</b>
            <SyntaxHighlighter
              language="javascript"
              wrapLongLines
              customStyle={{ borderRadius: "8px" }}
              style={dracula}
            >
              {errorData}
            </SyntaxHighlighter>
          </div>
        )}
        {checkIsArray(response) && !hideIndexed && (
          <div className="columna">
            <span className="horizontal" style={{ marginBottom: 8 }}>
              <span>Indexar respuesta</span>
              <Switch
                checked={tmpIndexado}
                onChange={(v) => setTmpIndexado(v)}
              />
            </span>
            {tmpIndexado && (
              <div>
                <p>Variables Indexadas</p>
                <Checkbox
                  style={{ padding: 4 }}
                  onChange={(v, a) => {
                    setTmpIndexedVars((prevState) => {
                      let array = prevState.map((element) => {
                        return { ...element, checked: v.target.checked };
                      });

                      return array;
                    });
                  }}
                >
                  <b>Seleccionar todo</b>
                </Checkbox>
                {tmpIndexedVars?.length > 0 &&
                  tmpIndexedVars.map((item, index) => (
                    <FilaCheck
                      item={item}
                      key={index}
                      index={index}
                      tmpVars={tmpIndexedVars}
                      setTmpVars={setTmpIndexedVars}
                      context="indexed"
                    />
                  ))}
              </div>
            )}
          </div>
        )}
        {!tmpIndexado && tmpVars?.length > 0 && (
          <div
            className="columna"
            style={{ marginBottom: "12px", marginTop: "8px", gap: "0px" }}
          >
            <Checkbox
              style={{ padding: 4 }}
              onChange={(v) => {
                setTmpVars((prevState) => {
                  let array = prevState.map((element) => {
                    return { ...element, checked: v.target.checked };
                  });

                  return array;
                });
              }}
            >
              <b>Seleccionar todo</b>
            </Checkbox>
            {checkResponse(response) &&
              tmpVars &&
              tmpVars.map((item, index) => (
                <FilaCheck
                  item={item}
                  key={index}
                  index={index}
                  tmpVars={tmpVars}
                  setTmpVars={setTmpVars}
                />
              ))}
          </div>
        )}
        {loadingProbar ? (
          <span
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    margin: 50,
                  }}
                  spin
                />
              }
            />
          </span>
        ) : (
          response && (
            <div style={{ margin: "8px 0px" }}>
              <SyntaxHighlighter
                language="javascript"
                wrapLongLines
                customStyle={{ borderRadius: "8px" }}
                style={dracula}
              >
                {response}
              </SyntaxHighlighter>
            </div>
          )
        )}
        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={() => {
                setMostrarDrawerVars(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
