import { useContext, useMemo } from "react";
import { FlowContext } from "../../../../context/FlowContext";
import { useBorrarNode } from "../utils/useBorrarNode";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { Handle, Position } from "reactflow";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { TbTrash } from "react-icons/tb";
import { PiMathOperations } from "react-icons/pi";
import { FORMULAS } from "../utils/formulas";
import { getVariableName } from "../utils/getVariableName";
import { Tag } from "antd";
import { getNodeName } from "../utils/getNodeName";

export const FormulaNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  const getOperationLabel = useMemo(() => {
    let operation = null;

    FORMULAS.forEach((type) => {
      type.formulas.find((formula) => {
        if (formula.value === node.data.operation) {
          operation = formula;
        }
      });
    });

    return operation?.label || "";
  })

  const borrarNode = useBorrarNode(node.id);
  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setNodoSeleccionado(node);
        setMostrarDrawer(true);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div style={{ height: "32px" }}>
          <PiMathOperations color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node.data.label}</span>
        </div>
      </div>
      <div className="formula-node-description gap-16">
        {node.data?.operation && <b>{getOperationLabel}</b>}
        {node.data?.variableName && (
          <Tag color="green" style={{ width: "fit-content" }}>
            {node.data.variableName}
          </Tag>
        )}
      </div>
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "12px" }}>
        {node?.id}
      </div>
    </div>
  );
};
