import axios from "axios";

export const sendPresupuesto = async (data, key) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/orderCart/newOrder`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBudgetStatus = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/orderCart/updateStatus`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
