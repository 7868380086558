import { FaArrowRight } from "react-icons/fa";

export const SabiasQue = () => {
  return (
    <section
      className="landing-section landing-section-clientes"
      style={{ position: "relative" }}
    >
      <img
        alt="landing-bottom3"
        className="landing-bottom-image-absolute"
        src={`${process.env.PUBLIC_URL}/assets/landing-images/bottom3.png`}
      />
      <h3 className="landing-section-title-sm">
        ¿Sabías que
        <br />
        automatizando tus procesos <br />
        <span className="landing-font-highlight">puedes liberar tiempo</span>
        <br />
        para tus tareas más estratégicas?
      </h3>

      <p className="landing-section-subtitle-grande">¡Solicita una demo hoy!</p>

      <div className="landing-bottom-highlight-background">
        <div className="landing-bottom-box">
          <div className="landing-bottom-layout">
            <img
              className="landing-bottom-img"
              src={`${process.env.PUBLIC_URL}/assets/landing-images/bottom1.png`}
              alt="bottom-1"
            />
            <div className="landing-bottom-box-column">
              <p className="landing-bottom-title">¡Escríbenos!</p>
              <div className="horizontal">
                <p className="landing-section-subtitle">
                  Solicita una demo gratuita de nuestra <br />
                  plataforma con solo enviarnos un <br />
                  mensaje.
                </p>
              </div>
              <button
                className="landing-boton-generico landing-boton-light-bordered"
                style={{ marginTop: "auto" }}
              >
                <p>Solicitar demo</p>
                <FaArrowRight />
              </button>
            </div>
          </div>
          <div className="landing-bottom-divider"></div>
          <div className="landing-bottom-layout">
            <img
              className="landing-bottom-img"
              src={`${process.env.PUBLIC_URL}/assets/landing-images/bottom2.png`}
              alt="bottom-1"
            />

            <div className="landing-bottom-box-column">
              <p className="landing-bottom-title">
                ¡Habla con un representante!
              </p>
              <div className="horizontal">
                <p className="landing-section-subtitle">
                  Coordina una visita guiada interactiva <br />
                  de brocoly para resolver todas tus dudas.
                </p>
              </div>
              <button
                className="landing-boton-generico landing-boton-light-bordered"
                style={{ marginTop: "auto" }}
              >
                <p>Chatear con un representante</p>
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
