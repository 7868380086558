import { Button, Form, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { updateNode } from '../utils/updateNode';
import { GlobalContext } from '../../../../context/GlobalContext';
import { TbClockPause } from 'react-icons/tb';
import { HeaderForm } from './componentsForm/HeaderForm';

export const SetDelayForm = () => {
  const { setMostrarDrawer, setNodes, nodoSeleccionado, nodes } =
    useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [nombreNodo, setNombreNodo] = useState('');

  const onFinish = (values) => {
    let seconds = Number(values.seconds);

    if (seconds >= 1 && seconds <= 60) {
      values.label = nombreNodo;
      values.seconds = seconds;

      setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
      setMostrarDrawer(false);
    } else {
      messageApi.info('Ingrese un número entre 1 y 60');
    }
  };

  useEffect(() => {
    if (nodoSeleccionado.data) {
      let { data } = nodoSeleccionado;
      setNombreNodo(data.label);
      if (data.seconds) {
        form.setFieldsValue({
          seconds: data.seconds
        });
      }
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='formBranch'
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className='form-nodos'
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={<TbClockPause style={{ color: 'var(--dark-color)' }} size={32} />}
      />

      <Form.Item
        name='seconds'
        label='Tiempo (entre 1 y 60 segundos)'
        rules={[
          { required: true, message: 'Campo requerido' }
          // { type: "number", message: "Ingrese números enteros" },
        ]}
      >
        <Input type='number' className='half-50' />
      </Form.Item>
      <Form.Item className='form-custom-footer'>
        <div className='botones-wrapper-content'>
          <Button
            type='primary'
            htmlType='submit'
            className='btn-guardar'
            size='large'
          >
            Guardar
          </Button>
          <Button
            className='btn-cancelar'
            size='large'
            type='secondary'
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
