import { useContext, useEffect, useMemo, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { PreviewNode } from "../PreviewNode";
import { useBorrarNode } from "../utils/useBorrarNode";
import { TbTrash } from "react-icons/tb";
import {
  checkValidConnection,
  handleInnerEdges,
} from "../utils/utilidadesFlow";
import { handleListIds } from "../utils/handleListIds";
import { getNodeName } from "../utils/getNodeName";

export const ListNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges, setEdges } =
    useContext(FlowContext);

  const updateNodeInternals = useUpdateNodeInternals();

  const [showNext, setShowNext] = useState(true);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  useEffect(() => {
    if (node.data?.variable?.name) {
      if (node.data.variable.name !== "flow_response") {
        setShowNext(true);
        handleInnerEdges(node.id, setEdges, true);
      } else {
        setShowNext(false);
        handleInnerEdges(node.id, setEdges, false);
      }
    }
  }, [node]);

  useEffect(() => {
    updateNodeInternals(node.id);
  }, [showNext]);

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div className="answer-node">
          <ImWhatsapp size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node?.data?.label}</span>
        </div>
      </div>
      <PreviewNode
        node={node}
        ambito={`nodes-${node.id}`}
        bodyContent={node?.data?.bodyText}
        buttonsCallContent={node?.data?.buttonsCallContent}
        buttonsContent={node?.data?.buttonsContent}
        buttonsMarketingContent={node?.data?.buttonsMarketingContent}
        footerContent={node?.data?.footerText}
        headerContent={node?.data?.headerText}
        headerMediaType={node?.data?.headerMediaType}
        headerType={node?.data?.headerType}
        inputText={node?.data?.inputText}
        preview={node?.data?.url}
        listContent={node?.data?.listContent}
        showInnerHandles={showNext}
        selected={node.selected}
      />
      <span className="contenedor-handles">
        {showNext && (
          <span className="linea-handle">
            <p>Next step</p>
            <Handle
              type="source"
              className="custom-handle next-step"
              position={Position.Right}
              id="nextstep"
              isConnectable={checkValidConnection(edges, node.id, "nextstep")}
            />
          </span>
        )}
        {node?.data?.variable?.error?.status && (
          <span className="linea-handle">
            <p>Fallback</p>
            <Handle
              type="source"
              className="custom-handle fallback"
              position={Position.Right}
              id="fallback"
              isConnectable={checkValidConnection(edges, node.id, "fallback")}
            />
          </span>
        )}
      </span>
      <div className="path-id" style={{ marginTop: "24px" }}>
        {node?.id}
      </div>
    </div>
  );
};
