import {
  Button,
  Col,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  FiBold,
  FiEdit3,
  FiFile,
  FiHelpCircle,
  FiImage,
  FiInfo,
  FiItalic,
  FiMapPin,
  FiPlayCircle,
} from "react-icons/fi";
import { AiFillBulb, AiOutlineStrikethrough } from "react-icons/ai";
import { Muestra } from "./Muestra";
import {
  handleButtonsCountType,
  formatoTextHtml,
  agregarVariable,
  agregarVariableHeader,
  isButtonTypeDisabled,
} from "../../../../../../helpers/utilidadesTemplates";
import { TemplateAuthentication } from "./TemplateAuthentication";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { TemplateContext } from "../../../../../context/TemplateContext";
import { BUTTON_OPTIONS } from "../../utils/smallUtilities";
import { TemplateButton } from "./TemplateButton";
import { PreviewTemplate } from "./PreviewTemplate";
import { AllButtons } from "./AllButtons";

export const NewTemplate = () => {
  const [headerType, setHeaderType] = useState("none");
  const [headerContent, setHeaderContent] = useState("");
  const [bodyContent, setBodyContent] = useState("");
  const [inputText, setInputText] = useState("");
  const [footerContent, setFooterContent] = useState("");
  const [buttonsContent, setButtonsContent] = useState([]);
  const [alertaHeader, setAlertaHeader] = useState(false);
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [disableEnviar, setDisableEnviar] = useState(false);
  const [caducidad, setCaducidad] = useState({
    bool: false,
    value: 1,
    message: "",
  });

  const [otpType, setOtpType] = useState("autocomplete");

  const [showAll, setShowAll] = useState(false);

  const { setTemplateStage, messageApi } = useContext(GlobalContext);
  const { templateSelection, setTemplateSelection, preview, setPreview } =
    useContext(TemplateContext);

  const footerMarketing = '¿No te interesa? Toca "Detener promociones"';

  const handleSelect = (e) => {
    setFooterContent("");
    setButtonsContent([]);
    let temp = { ...templateSelection };
    temp.templateType = e;
    setTemplateSelection(temp);
  };

  const handleGoBack = () => {
    setTemplateStage(0);
  };

  const decorarTexto = (wrappingString) => {
    let textArea = document.getElementById("text");

    if (textArea) {
      let s = textArea.selectionStart;
      let e = textArea.selectionEnd;

      let oldValue = textArea.value;

      if (wrappingString !== "") {
        let newValue =
          oldValue.slice(0, s) +
          wrappingString +
          oldValue.slice(s, e) +
          wrappingString +
          oldValue.slice(e, oldValue.length);
        textArea.value = newValue;
      }

      if (wrappingString === "{{}}") {
        textArea.value = agregarVariable(inputText);
      }

      setInputText(textArea.value);

      if (!textArea.value) {
        setBodyContent("");
      } else {
        setBodyContent(formatoTextHtml(textArea.value));
      }
    }
  };

  const enviarTemplate = () => {
    let html = `{"type":"HEADER","format":"TEXT","text": "${headerContent}"}
        {"type":"BODY","text":"${bodyContent}"}
      {"type":"FOOTER","text":"${footerContent}"}`;

    if (buttonsContent && buttonsContent.length > 0) {
      html += `type: "BUTTONS",
          buttons: [
            ${buttonsContent.map((element) => {
              return JSON.stringify({ type: "QUICK_REPLY", text: element });
            })}
          ],
        }`;
    }
    console.log(html);
  };

  const hasDefaultButton = useMemo(() => {
    return buttonsContent.some(({ type }) => type === "MARKETING");
  }, [buttonsContent]);

  const shouldDisableEnviar = useMemo(() => {
    if (templateSelection?.templateType === "OTP") {
      if (caducidad.bool === true && caducidad.message) {
        return true;
      }
    }
    if (!bodyContent) return true;
    if (headerType === "media" && !preview) return true;
    // if (["desactivar", "custom"].includes(buttonType)) return false;
    // if (buttonsMarketingContent.length > 0) {
    //   return !buttonsMarketingContent.some(
    //     ({ type, check }) => type === "default" && check
    //   );
    // }
    return false;
  }, [bodyContent, preview, headerType, caducidad, templateSelection]);

  useEffect(() => {
    setDisableEnviar(shouldDisableEnviar);
    setFooterContent(hasDefaultButton ? footerMarketing : "");
  }, [shouldDisableEnviar, hasDefaultButton]);

  return (
    <div className="main-wrapper">
      <div className="main-header" style={{ width: "100%" }}>
        <div className="template-header-opciones">
          <span className="template-header-name">
            {templateSelection &&
              `${templateSelection.templateName} • ${templateSelection.templateLanguage}`}
            <FiEdit3
              size={18}
              className="icono-editar"
              onClick={() => console.log("editar nombre")}
            />
          </span>
          <span
            className="template-header-opciones"
            style={{ gap: "8px", fontSize: "14px" }}
          >
            Categoría:
            <Select
              style={{ width: "200px" }}
              onChange={(e) => handleSelect(e)}
              defaultValue={templateSelection?.templateType}
              options={[
                {
                  label: "Marketing / Personalizado",
                  value: "MARKETING/CUSTOM",
                },
                {
                  label: "Marketing / Catálogo",
                  value: "MARKETING/CATALOG",
                },
                { label: "Servicios", value: "TRANSACTIONAL" },
                { label: "Autenticación", value: "OTP" },
              ]}
            />
          </span>
        </div>
        <span className="contenedor-botones">
          <a
            href="https://business.facebook.com/business/help/2055875911147364?id=2129163877102343"
            target="_blank"
            className="help-link"
            rel="noreferrer"
          >
            <FiHelpCircle style={{ marginRight: "4px" }} /> Servicio de ayuda
          </a>
          <Button
            type="secundary"
            className="btn-secundario"
            onClick={() => handleGoBack()}
          >
            Atras
          </Button>
        </span>
      </div>
      <div className="create-template-content">
        <Row className="row-create-template">
          <Col className="columna-datos-template">
            <div className="columna-datos-header">
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                Editar Plantilla
              </span>
              <span className="contenedor-botones">
                <Button
                  type="primary"
                  className="btn-guardar"
                  style={{ width: "auto" }}
                  disabled={disableEnviar}
                  onClick={() => enviarTemplate()}
                >
                  Enviar
                </Button>
              </span>
            </div>
            {templateSelection?.templateType === "OTP" ? (
              <TemplateAuthentication
                setBodyContent={setBodyContent}
                setFooterContent={setFooterContent}
                caducidad={caducidad}
                setCaducidad={setCaducidad}
                buttonsContent={buttonsContent}
                setButtonsContent={setButtonsContent}
                otpType={otpType}
                setOtpType={setOtpType}
              />
            ) : (
              <>
                {templateSelection?.templateType?.includes("CATALOG") && (
                  <div className="item-columna-datos">
                    <p className="item-dato-titulo">Catálogo</p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        color: "rgba(28,43,51,.65)",
                      }}
                    >
                      Elige un catálogo para enviar una lista de artículos a tus
                      clientes. El catálogo está conectado con tu cuenta de
                      WhatsApp Business y solo se pueden enviar hasta 30
                      artículos.
                    </p>
                    <Button
                      type="primary"
                      className="btn-agregar"
                      style={{ alignSelf: "flex-start" }}
                    >
                      Conectar catálogo
                    </Button>
                  </div>
                )}
                <div className="item-columna-datos">
                  <span className="item-dato-titulo">
                    Encabezado (opcional)
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      color: "rgba(28,43,51,.65)",
                    }}
                  >
                    Agrega un título o elige el tipo de contenido multimedia que
                    usarás para este encabezado.
                  </span>
                  <span style={{ display: "flex", gap: "12px" }}>
                    <Select
                      style={{
                        width: "150px",
                        fontSize: "14px",
                        height: "32px",
                      }}
                      onChange={(e) => {
                        setHeaderType(e);
                        setHeaderMediaType("image");
                        setPreview("");
                      }}
                      defaultValue="none"
                    >
                      <Select.Option id="1" value="none">
                        Ninguna
                      </Select.Option>
                      <Select.Option id="2" value="text">
                        Mensaje de texto
                      </Select.Option>
                      <Select.Option id="3" value="media">
                        Media
                      </Select.Option>
                    </Select>
                    {headerType === "text" && (
                      <div className="header-text-wrapper">
                        <Input
                          placeholder="Escribir texto"
                          onChange={(v) => {
                            setHeaderContent(v.target.value);
                            let matches = headerContent.match(/{{\s*\d+\s*}}/g);
                            let count = matches ? matches.length : 0;
                            if (count < 1 || v.target.value === "") {
                              setAlertaHeader(false);
                            } else {
                              setAlertaHeader(true);
                            }
                          }}
                          showCount
                          maxLength={60}
                          value={headerContent}
                        />
                        <div className="contenedor-botones-texto">
                          <Tooltip>
                            <Button
                              className="btn-agregar btn-oscuro"
                              type="primary"
                              onClick={() => {
                                let obj = agregarVariableHeader(headerContent);
                                setHeaderContent(obj.headerContent);
                                setAlertaHeader(obj.flag);
                              }}
                              disabled={alertaHeader}
                            >
                              Agregar variable
                            </Button>
                          </Tooltip>
                          <Tooltip
                            color="white"
                            placement="right"
                            title={
                              <div className="columna-tooltip">
                                <span className="texto-tooltip">
                                  Este es el texto que especificas en la API que
                                  se personalizará según el cliente, como su
                                  nombre o número de nota de pago.
                                </span>
                                <a
                                  href="https://developers.facebook.com/docs/whatsapp/api/messages/message-templates"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Más Información
                                </a>
                              </div>
                            }
                          >
                            <span style={{ margin: "4px", height: "14px" }}>
                              <FiInfo />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </span>
                  {headerType === "media" && (
                    <>
                      <Radio.Group
                        onChange={(v) => {
                          setHeaderMediaType(v.target.value);
                          setPreview("");
                        }}
                        className="radio-media"
                        defaultValue="image"
                      >
                        <Space direction="horizontal">
                          <Radio
                            value="image"
                            className="radio-medio-item"
                            size="large"
                          >
                            <div className="columna-simple">
                              <FiImage size={80} color="#ccd0d5" />
                              Imagen
                            </div>
                          </Radio>
                          <Radio value="video" className="radio-medio-item">
                            <div className="columna-simple">
                              <FiPlayCircle size={80} color="#ccd0d5" />
                              Video
                            </div>
                          </Radio>
                          <Radio value="document" className="radio-medio-item">
                            <div className="columna-simple">
                              <FiFile size={80} color="#ccd0d5" /> Documento
                            </div>
                          </Radio>
                          <Radio value="location" className="radio-medio-item">
                            <div className="columna-simple">
                              <FiMapPin size={80} color="#ccd0d5" /> Ubicación
                            </div>
                          </Radio>
                        </Space>
                      </Radio.Group>

                      {headerMediaType !== "location" && (
                        <span>
                          <Muestra headerMediaType={headerMediaType} />
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div className="item-columna-datos">
                  <span className="item-dato-titulo">Texto</span>
                  <span className="item-dato-subtitulo">
                    Ingresa el texto del mensaje en el idioma que seleccionaste.
                  </span>
                  <Input.TextArea
                    className="input-text-area"
                    placeholder="Escribir texto"
                    onChange={(v) => decorarTexto("")}
                    autoSize={true}
                    showCount
                    maxLength={1024}
                    id="text"
                    value={inputText}
                    style={{ marginBottom: "16px" }}
                  />
                  <div className="contenedor-botones-texto">
                    <div
                      className="boton-texto"
                      onClick={() => decorarTexto("*")}
                    >
                      <FiBold size={16} />
                    </div>
                    <div
                      className="boton-texto"
                      onClick={() => decorarTexto("_")}
                    >
                      <FiItalic size={16} />
                    </div>
                    <div
                      className="boton-texto"
                      onClick={() => decorarTexto("~")}
                    >
                      <AiOutlineStrikethrough size={16} />
                    </div>
                    <div
                      className="boton-texto"
                      onClick={() => decorarTexto("```")}
                    >
                      {`${"</>"}`}
                    </div>
                    <Button
                      className="btn-agregar btn-oscuro"
                      type="primary"
                      onClick={() => decorarTexto("{{}}")}
                    >
                      Agregar variable
                    </Button>
                    <Tooltip
                      color="white"
                      placement="right"
                      title={
                        <div className="columna-tooltip">
                          <span className="texto-tooltip">
                            Este es el texto que especificas en la API que se
                            personalizará según el cliente, como su nombre o
                            número de nota de pago.
                          </span>
                          <a
                            href="https://developers.facebook.com/docs/whatsapp/api/messages/message-templates"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Más Información
                          </a>
                        </div>
                      }
                    >
                      <span style={{ margin: "4px", height: "14px" }}>
                        <FiInfo />
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <div className="item-columna-datos">
                  <span className="item-dato-titulo">
                    Pie de página (opcional)
                  </span>
                  <span className="item-dato-subtitulo">
                    Agrega una breve línea de texto en la parte inferior de la
                    plantilla del mensaje.
                  </span>
                  <Input
                    placeholder="Escribir texto"
                    onChange={(v) => setFooterContent(v.target.value)}
                    showCount
                    maxLength={60}
                    disabled={buttonsContent.some(
                      (element) => element.type === "MARKETING"
                    )}
                    value={footerContent}
                  />
                </div>
                <div className="item-columna-datos">
                  <span className="item-dato-titulo">Botones (opcional)</span>
                  <span className="item-dato-subtitulo">
                    Crea botones que les permitan a los clientes responder a tu
                    mensaje o realizar una acción.
                  </span>
                  {templateSelection?.templateType?.includes("MARKETING") && (
                    <div className="advertencia-marketing-wrapper">
                      <div>
                        <AiFillBulb size={16} fill="rgb(220, 224, 86)" />
                      </div>
                      <div className="contenido-advertencia-marketing">
                        <p>
                          Te recomendamos agregar el botón "Desactivar
                          marketing"
                        </p>
                        <p style={{ lineHeight: "20px" }}>
                          Permite que los clientes soliciten desactivar todos
                          los mensajes de marketing. Esto puede ayudar a reducir
                          los bloqueos de los clientes y aumentar tu
                          calificación de calidad.{" "}
                          <a
                            href="https://www.facebook.com/business/help/448422200528701"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Más información.
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                  <Popover
                    disabled={buttonsContent?.length >= 10}
                    placement="topLeft"
                    content={
                      <div className="button-type-pop-wrapper">
                        {BUTTON_OPTIONS.map((section, index) => {
                          return (
                            <span
                              className="button-type-section"
                              style={
                                index === 0
                                  ? {
                                      borderBottom: "1px solid #e8e8e8",
                                      paddingBottom: 4,
                                    }
                                  : {}
                              }
                              key={`section-${index}`}
                            >
                              <p className="button-type-section-title">
                                {section.title}
                              </p>
                              {section.children.map((type, childIndex) => {
                                if (
                                  type.type?.toLowerCase() === "marketing" &&
                                  !templateSelection?.templateType
                                    ?.toLowerCase()
                                    ?.includes("marketing")
                                ) {
                                  return false;
                                } else {
                                  return (
                                    <span
                                      className={
                                        isButtonTypeDisabled(
                                          type.type,
                                          buttonsContent
                                        )
                                          ? "button-type-wrapper type-disabled"
                                          : "button-type-wrapper"
                                      }
                                      onClick={() => {
                                        if (
                                          !isButtonTypeDisabled(
                                            type.type,
                                            buttonsContent
                                          )
                                        ) {
                                          setButtonsContent((prevState) =>
                                            handleButtonsCountType(
                                              prevState,
                                              type.type
                                            )
                                          );
                                        }
                                      }}
                                      key={`childIndex-${childIndex}`}
                                    >
                                      <p className="button-type-title">
                                        {type.title}
                                      </p>
                                      <p className="button-type-subtitle">
                                        {type.subtitle}
                                      </p>
                                    </span>
                                  );
                                }
                              })}
                            </span>
                          );
                        })}
                      </div>
                    }
                  >
                    <Button
                      className="btn-agregar"
                      style={{ width: "fit-content" }}
                      type="primary"
                      disabled={buttonsContent?.length >= 10}
                    >
                      Agregar un botón
                    </Button>
                  </Popover>
                  {buttonsContent?.map((button, index) => {
                    return (
                      <TemplateButton
                        button={button}
                        index={index}
                        setButtonsContent={setButtonsContent}
                        key={`boton-${index}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </Col>
          <Col className="columna-ejemplo-template">
            <span className="preview-title">Vista previa</span>
            <PreviewTemplate
              ambito="edit"
              inputText={inputText}
              bodyContent={bodyContent}
              buttonsContent={buttonsContent}
              footerContent={footerContent}
              headerContent={headerContent}
              headerType={headerType}
              showAll={showAll}
              setShowAll={setShowAll}
            />
            {showAll && (
              <AllButtons
                buttonsContent={buttonsContent}
                setShowAll={setShowAll}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
