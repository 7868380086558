import { Button, Popover, Tag, Tooltip } from "antd";
import { useContext, useMemo, useState } from "react";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { AiOutlineApi } from "react-icons/ai";
import { createNewInstance } from "../../../../../../helpers/fetchData/fetchBrocolyAdmin";

export const solicitudesColumns = (setDrafts) => {
  return [
    {
      title: "Nombre de la organización",
      key: "orgName",
      dataIndex: "orgName",
    },
    {
      title: "Nombre del contacto",
      key: "contactName",
      dataIndex: "contactName",
    },
    {
      title: "Teléfono",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 120,
      align: "right",
      render: (data) => <p className="monospace-font">{data}</p>,
    },
    { title: "Email", key: "userEmail", dataIndex: "userEmail" },
    {
      title: "Estado",
      key: "status",
      dataIndex: "status",
      render: (data) => {
        if (data === "MAIL PENDING") {
          return <Tag color="orange">{data}</Tag>;
        } else if (data === "ALTA PENDING") {
          return <Tag color="geekblue">{data}</Tag>;
        }
      },
    },
    {
      title: "OTP",
      key: "otp",
      dataIndex: "otp",
      width: 80,
      align: "center",
      render: (data) =>
        data && (
          <Tag color="black">
            <p className="monospace-font" style={{ fontWeight: 800 }}>
              {data}
            </p>
          </Tag>
        ),
    },
    {
      title: "...",
      key: "",
      dataIndex: "",
      width: 80,
      align: "center",
      render: (item) => {
        return <AccionesSolicitudes item={item} setDrafts={setDrafts} />;
      },
    },
  ];
};

const AccionesSolicitudes = ({ item, setDrafts }) => {
  const { messageApi, instance } = useContext(GlobalContext);

  const [showPopover, setShowPopover] = useState(false);

  const [loading, setLoading] = useState(false);

  const status = useMemo(() => item?.status?.toUpperCase(), [item]);

  const handleCreate = () => {
    setLoading(true);

    createNewInstance(item, instance?.userData?.apiKey)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Workspace creado correctamente");
          setShowPopover(false);
          setDrafts((prevState) => {
            return prevState.filter((element) => element._id !== item?._id);
          });
        } else {
          messageApi.error("Ocurrío un error al crear un workspace");
        }
      })
      .catch((error) => {
        console.log("error", error);
        messageApi.error("Ocurrío un error al crear un workspace");
      })
      .finally(() => setLoading(false));
  };

  return (
    <span className="horizontal" style={{ justifyContent: "center" }}>
      {status === "ALTA PENDING" && (
        <Popover
          trigger="click"
          placement="bottomRight"
          open={showPopover}
          content={
            <div className="columna-simple gap-16">
              <span>{`¿Está seguro de crear el workspace ${item?.name}?`}</span>

              <div
                className="botones-wrapper-content"
                style={{ width: "100%" }}
              >
                <Button
                  type="primary"
                  className="btn-guardar"
                  onClick={handleCreate}
                  //loading={loadingBorrar}
                  icon={
                    loading ? (
                      <LoadingIcon size="small" color="#FFFFFF" />
                    ) : null
                  }
                  style={{ opacity: loading ? 0.65 : 1 }}
                >
                  Confirmar
                </Button>
                <Button
                  className="btn-cancelar"
                  type="secondary"
                  onClick={() => setShowPopover(false)}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          }
        >
          {/* <span style={{height: 20}}> */}
          <Tooltip className="pointer" title="Crear workspace">
            <span style={{ height: 20 }}>
              <AiOutlineApi size={18} onClick={() => setShowPopover(true)} />
            </span>
          </Tooltip>
          {/* </span> */}
        </Popover>
      )}
    </span>
  );
};
