import { Image, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { formatFileSize } from '../../../utils/formatFileSize';
import dayjs from 'dayjs';
import { AccionesMedia } from './AccionesMedia';
import { AudioPreview } from '../previews/AudioPreview';
import { VideoPreview } from '../previews/VideoPreview';
import { DocumentPreview } from '../previews/DocumentPreview';

export const FilesTable = ({ mediaType, filteredFiles, setFiles, loading }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'publicURL',
      key: 'publicURL',
      align: 'center',
      render: (text, record) => renderPreview(text, record)
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Descripción',
      dataIndex: 'caption',
      key: 'caption',
      ellipsis: true
    },
    {
      title: 'Tamaño',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
      render: (text) => (text ? formatFileSize(text) : '-')
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text, 'x').format('DD/MM/YY HH:mm')
    },
    {
      title: '...',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (_, record) => <AccionesMedia item={record} setFiles={setFiles} />
    }
  ];

  const renderPreview = (text, record) => {
    switch (mediaType) {
      case 'image':
        return (
          <Image
            src={text}
            width={50}
            style={{
              borderRadius: 4
            }}
          />
        );
      case 'audio':
        return <AudioPreview src={text} />;
      case 'video':
        return <VideoPreview src={text} />;
      case 'document':
        return <DocumentPreview src={text} record={record} />;
      default:
        return '';
    }
  };

  const getElement = () => {
    switch (mediaType) {
      case 'image':
        return 'imágenes';
      case 'audio':
        return 'audios';
      case 'video':
        return 'videos';
      case 'document':
        return 'documentos';
      default:
        return '';
    }
  };

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined size={26} spin style={{ marginTop: 50 }} />}
    >
      <Table
        className='tabla-config'
        style={{ maxWidth: 900 }}
        rowKey='_id'
        size='small'
        columns={columns}
        dataSource={filteredFiles || []}
        pagination={{
          pageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} ${getElement()}`
        }}
      />
    </Spin>
  );
};
