import { Select, Tag } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { filterLabelOptions } from "../../../utils/filterLabelOptions";
import useFetchCollection from "../../../hooks/useFetchCollection";
import { multipleContactsByIds } from "../../../helpers/fetchData/fetchContacts";

const MAX_TAG_COUNT = 10;

export const SelectContactos = ({
  mode = "",
  value,
  setValue,
  maxWidth = null,
  placeholder = "",
}) => {
  const { instance, messageApi, contactos, setContactos } =
    useContext(GlobalContext);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [collectionLength, setCollectionLength] = useState(0);

  const [optionsMap, setOptionsMap] = useState(new Map());

  const [innerSearchValue, setInnerSearchValue] = useState("");

  const [initialValues, setInitialValues] = useState(null);

  const [checkedInitialValues, setCheckedInitialValues] = useState(false);

  const debouncedSearch = useCallback(
    (value) => {
      setSearch(value);
      setPage(1);
    },
    [setSearch, setPage]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedSearch(innerSearchValue);
    }, 500); // Debounce delay of 500ms

    // Si el usuario vuelve a escribir antes de 500ms, se cancela el seteo de search (deboucedSearch)
    return () => clearTimeout(handler);
  }, [innerSearchValue, debouncedSearch]);

  const { data, loading, error } = useFetchCollection(
    "contacts",
    page,
    20,
    search
  );

  const handlePopupScroll = (e) => {
    const { target } = e;

    if (
      !loading &&
      options?.length < collectionLength &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      // Esto va a triggerear otro fetch;
      setPage((prevState) => (prevState += 1));
    }
  };

  const handleOnSearch = (value) => {
    setInnerSearchValue(value);
    debouncedSearch(value);
  };

  const addOptions = (newOptions) => {
    setOptionsMap((prev) => {
      const updated = new Map(prev);

      newOptions.forEach((option) => {
        updated.set(option._id, option);
      });

      return updated;
    });
  };

  useEffect(() => {
    if (Array.isArray(data?.items) && data?.items?.length) {
      addOptions(data?.items);

      if (data.collectionTotal && !collectionLength) {
        setCollectionLength(data.collectionTotal);
      }
    }
  }, [data]);

  const options = useMemo(() => {
    return Array.from(optionsMap.values()).map((item) => {
      let label = item.fullName;

      if (item?.userId) {
        label = (
          <div className="horizontal">
            <p>{item.fullName}</p>
            <Tag color="geekblue">USUARIO</Tag>
          </div>
        );
      }

      return {
        label: label,
        value: item._id,
        fullName: item.fullName,
      };
    });
  }, [optionsMap]);

  useEffect(() => {
    // Iniciar con los contactos que hay.
    // No incluir CONTACTOS como dependencia
    if (contactos?.length > 0) {
      addOptions(contactos);
    }
  }, []);

  useEffect(() => {
    // Actualizo array de contactos tambien.
    setContactos(() => Array.from(optionsMap, ([key, value]) => value));
  }, [optionsMap]);

  const handleChange = (v) => {
    setCheckedInitialValues(true);
    setValue(v);
  };

  useEffect(() => {
    // Checkeo que los valores preseleccionados existan como contactos;

    if (checkedInitialValues) {
      // Se valida solo una vez
      return;
    }

    if (!instance?.userData?.apiKey) {
      return;
    }

    if (!value) {
      return;
    }

    let idsArray = [];

    if (Array.isArray(value)) {
      idsArray = [...value];
    } else {
      // Selección única
      idsArray.push(value);
    }

    if (idsArray?.length > 0) {
      // se buscan idsArray
      multipleContactsByIds(instance?.userData?.apiKey, idsArray)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
              addOptions(res.data);

              // se recibe res.data
              let idsObtenidos = res.data.map((element) => element._id);

              // Buscar cuales hay que sacar.
              let idsADescartar = idsArray.filter(
                (element) => !idsObtenidos?.includes(element)
              );

              setCheckedInitialValues(true);
              // Revisar si hay contactos seleccionados que ya no existen.

              if (Array.isArray(value)) {
                // Limpio los contactos que ya no existen.
                const filteredValue = value?.filter(
                  (element) => !idsADescartar.includes(element)
                );

                setValue(filteredValue);
              }
            }
          }
        })
        .catch((error) => console.log(error));
    }
  }, [value, instance]);

  return (
    <Select
      mode={mode}
      // labelInValue
      value={loading ? null : value}
      allowClear
      style={{
        width: "100%",
        maxWidth: maxWidth,
      }}
      maxTagCount={MAX_TAG_COUNT}
      placeholder={
        placeholder
          ? placeholder
          : mode === "multiple"
          ? "Seleccione contactos"
          : "Seleccione un contacto"
      }
      onChange={handleChange}
      options={options || []}
      showSearch
      filterOption={(value, option) =>
        option?.fullName?.toLowerCase()?.includes(value.toLowerCase())
      }
      virtual={true}
      onPopupScroll={(e) => handlePopupScroll(e)}
      onSearch={handleOnSearch}
      loading={loading}
    />
  );
};
