import { CampaignData } from '../ui/Layout/campaigns/campaignData/CampaignData';
import MainLayout from '../ui/Layout/MainLayout';

export const CampaignsDataView = () => {
  return (
    <MainLayout>
      <CampaignData />
    </MainLayout>
  );
};
