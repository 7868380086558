import dayjs from "dayjs";

export const DATE_FORMAT = "DD/MM/YYYY HH:mm";

export const defaultTime = (index, setTmpSchedules) => {
  if (index !== 0) return;
  // Si se modificó el primer segmento

  setTmpSchedules((prevState) => {
    let { date, time } = prevState[0];

    // Primer segmento completo, con fecha y hora
    if (!date || !time) return prevState;

    let tmpDate = dayjs(date, DATE_FORMAT).format("DD/MM/YYYY");
    let tmpTime = dayjs(time, DATE_FORMAT).format("HH:mm");

    let timestamp = dayjs(`${tmpDate} ${tmpTime}`, DATE_FORMAT).unix() * 1000;

    // 24 hs + 1 min
    const multiplicador = 24 * 60 * 60 * 1000 + 60000;

    let array = [...prevState];

    // Actualiza fecha y hora de los segmentos posteriores al 0
    array = array.map((schedule, index) => {
      if (index === 0) return schedule;

      let dateTime = dayjs(timestamp + multiplicador * index).format(
        DATE_FORMAT
      );

      let obj = {
        date: dateTime,
        time: dateTime,
      };

      return obj;
    });

    return array;
  });
};

export const validarCalendarios = (array, messageApi) => {
  let diferencia24 = 86460;

  let flag = false;

  array.forEach((segmento, index) => {
    if (!flag) {
      // Fecha posterior a la actual
      if (dayjs(segmento, DATE_FORMAT).isBefore()) {
        messageApi.info(
          "Por favor seleccione una hora posterior a la del momento"
        );

        flag = true;
      }

      if (index > 0) {
        // Diferencia de al menos 24 hs y 1 min
        let dateTime = dayjs(segmento, DATE_FORMAT).unix();

        let previousDateTime = dayjs(array[index - 1], DATE_FORMAT).unix();

        let diferenciaReal = dateTime - previousDateTime;

        if (diferenciaReal < diferencia24) {
          messageApi.info(
            `La diferencia del segmento ${
              index + 1
            } y el anterior debe ser mayor a 24hs`
          );

          flag = true;
        }
      }
    }
  });

  // if (flag) 
  // }

  return flag;
};

export const toCron = (dateTime) => {
  // dayjs a formato CRON
  let cron = `${dayjs(dateTime).minute()} ${dayjs(dateTime).hour()} ${dayjs(
    dateTime
  ).date()} ${dayjs(dateTime).month() + 1} *`;

  return cron;
};
