import { useContext } from "react";
import { FlowContext } from "../../../../context/FlowContext";

export const useBorrarNode = (nodeId) => {
  const { edges, setNodes, setEdges, setFlowVariables } =
    useContext(FlowContext);

  const borrarNode = () => {
    let id = edges.filter((edge) => edge.source === nodeId);

    if (id.length > 0) {
      id = id[0].target;
      setNodes((prevState) => [
        ...prevState.filter((node) => {
          if (
            node.id === nodeId ||
            (node.id === id && node.type === "saveResponse")
          ) {
            return false;
          } else {
            return true;
          }
        }),
      ]);
    }

    setNodes((prevState) => [
      ...prevState.filter((node) => node.id !== nodeId),
    ]);

    setEdges((prevState) =>
      prevState.filter((edge) => {
        if (edge.source === nodeId || edge.target === nodeId) {
          return false;
        } else {
          return true;
        }
      })
    );

    setFlowVariables((prevState) =>
      prevState.filter((variable) => variable.idNodo !== nodeId)
    );
  };

  return borrarNode;
};
