import { useContext, useEffect } from 'react';
import { CardWapp } from './CardWapp';
import { SinApp } from './SinApp';
import { CrearApp } from './CrearApp';
import { EditarApp } from './EditarApp';
import { GlobalContext } from '../../../../../../context/GlobalContext';
import { Spin } from 'antd';
import LoadingIcon from '../../../../../Loader/LoadingIcon';

export const Application = () => {
  const { appStage, setAppStage, application, loadingApplication } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!loadingApplication) {
      if (application?.length > 0) {
        setAppStage(2);
      } else {
        setAppStage(0);
      }
    }
  }, [application]);

  const handleStage = () => {
    switch (appStage) {
      case 0:
        return <SinApp />;
      case 1:
        return <CrearApp />;
      case 2:
        return <CardWapp />;
      case 3:
        return <EditarApp />;
      case 4:
        return (
          <Spin
            style={{
              marginTop: 100
            }}
            indicator={<LoadingIcon size='large' />}
          />
        );
      default:
        return <SinApp />;
    }
  };

  return <>{handleStage()}</>;
};
