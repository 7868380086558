
const SIZE = 10;

export const loadMoreArticles = (
  visibleArticles,
  setVisibleArticles,
  allArticles
) => {
  const nextBatchEndIndex = visibleArticles?.length + SIZE;

  if (nextBatchEndIndex <= allArticles?.length) {
    const nextArticles = allArticles.slice(0, nextBatchEndIndex);

    setVisibleArticles(nextArticles);
  } else {
    setVisibleArticles(allArticles);
  }
};
