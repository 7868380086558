import { Button, Drawer, Form, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import { agregarVariableWhatsapp } from "../../../../../helpers/utilidadesTemplates";
import { updateNode } from "../utils/updateNode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useUpdateNodeInternals } from "reactflow";
import { MediaForm } from "./MediaForm";
import { BotonAgregarVariable } from "./BotonAgregarVariable";
import { HeaderForm } from "./componentsForm/HeaderForm";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { LineaGris } from "../../../linea-gris/LineaGris";

export const CtaButtonForm = () => {
  const { nodoSeleccionado, setMostrarDrawer, setNodes } =
    useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [preview, setPreview] = useState("");

  const [button, setButton] = useState({
    display_text: "",
    url: "",
  });

  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [url, setURL] = useState("");

  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState({});
  const [mostrarDrawerMedia, setMostrarDrawerMedia] = useState(false);

  const updateNodeInternals = useUpdateNodeInternals();

  const decorarURL = (wrappingString, emoji, variable) => {
    setButton((prevState) => {
      return {
        ...prevState,
        url: agregarVariableWhatsapp(prevState.url, variable, "urlInput"),
      };
    });
  };

  const onFinish = (values) => {
    if (!bodyText) {
      messageApi.info("Es necesario que ingrese cuerpo del mensaje");
      return;
    }

    if (!button.display_text || !button.url) {
      messageApi.info("Texto del botón y URL son campos obligatorios");
      return;
    }
    values.label = nombreNodo;
    values.auto = false;
    values.headerType = messageType;

    values.headerText = headerText;
    values.bodyText = bodyText;
    values.footerText = footerText;

    values.button = button;

    values.variable = {};

    values.handleCount = 1;

    if (messageType === "media") {
      values.headerMediaType = headerMediaType;

      if (headerMediaType === "location") {
        if (!location.latitude || !location.longitude) {
          return messageApi.info("Latitud y longitud son campos obligatorios");
        }

        values.location = location;
      } else {
        if (url) {
          values.url = url;
        } else {
          return messageApi.info("Ingrese una url");
        }

        if (caption) {
          values.caption = caption;
          values.bodyText = "";
        }
      }
    } else {
      values.caption = null;
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
    updateNodeInternals(nodoSeleccionado.id);
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.headerText !== undefined) {
        setHeaderText(data.headerText);
      }

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }

      if (data.footerText !== undefined) {
        setFooterText(data.footerText);
      }

      if (data.button !== undefined) {
        setButton(data.button);
      }

      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      let mediaType = "image";

      if (data.headerMediaType || data.headerMediaImage) {
        mediaType = data.headerMediaType || data.headerMediaImage;
      }
      setHeaderMediaType(mediaType);

      setNombreNodo(data.label);

      if (data.location) {
        const clone = structuredClone(data.location);
        setLocation(clone);
      }

      if (data.url) {
        setURL(data.url);
      }

      if (data.caption) {
        setCaption(data.caption);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color" }} size={32} />
        }
      />

      <Form.Item label={<span className="label-secciones">ENCABEZADO</span>}>
        <HeaderTextOrMedia
          messageType={messageType}
          setMessageType={setMessageType}
          nodeId={nodoSeleccionado?.id}
          headerMediaType={headerMediaType}
          setHeaderMediaType={setHeaderMediaType}
          text={headerText}
          setText={setHeaderText}
          url={url}
          setURL={setURL}
          setPreview={setPreview}
          caption={caption}
          setCaption={setCaption}
          location={location}
          setLocation={setLocation}
          context="interactive-ctaurl"
        />
      </Form.Item>
      <LineaGris />
      <FormattedTextArea
        value={bodyText}
        setValue={setBodyText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textBody"
        label="CUERPO"
        boldLabel
        minRows={3}
      />
      <LineaGris />
      <FormattedTextArea
        value={footerText}
        setValue={setFooterText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textFooter"
        label="PIE DEL MENSAJE"
        boldLabel
        minRows={3}
        showDecorators={false}
      />
      <LineaGris />
      <div style={{ marginBottom: "16px" }}>
        <div className="template-crear-boton">
          <b>Texto del botón</b>
          <div className="horizontal">
            <Input
              onChange={(v) =>
                setButton((prevState) => {
                  return { ...prevState, display_text: v.target.value };
                })
              }
              value={button.display_text}
              showCount
              maxLength={20}
            />
          </div>
          <b>URL</b>
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            value={button.url}
            id="urlInput"
            onChange={(v) =>
              setButton((prevState) => {
                return { ...prevState, url: v.target.value };
              })
            }
          />
          <BotonAgregarVariable
            decorarTexto={decorarURL}
            nodeId={nodoSeleccionado.id}
          />
        </div>
      </div>
      <Drawer
        open={mostrarDrawerMedia}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <MediaForm
          url={url}
          setURL={setURL}
          setMostrarDrawerMedia={setMostrarDrawerMedia}
          mediaType={headerMediaType}
        />
      </Drawer>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
