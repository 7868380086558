export const CONTEXT_VARIABLES = [
  {
    name: "context_name",
    label: "Nombre del contacto",
    type: "string",
  },
  {
    name: "context_phoneNumber",
    label: "Teléfono del contacto",
    type: "string",
  },
  {
    name: "context_currentTime",
    label: "Hora actual",
    type: "string",
  },
  {
    name: "context_currentDate",
    label: "Fecha actual (DD/MM/YYYY)",
    type: "string",
  },
  {
    name: "context_currentDay",
    label: "Día del mes",
    type: "string",
  },
  {
    name: "context_currentMonth",
    label: "Mes actual",
    type: "string",
  },
  {
    name: "context_currentYear",
    label: "Año actual",
    type: "string",
  },
  // {
  //   name: "context_conversationId",
  //   label: "ID de la conversación",
  //   type: "string",
  // },
];

export const CONTACT_VARIABLES = [
  {
    name: "contact_firstName",
    value: "contact_firstName",
    type: "string",
    label: "Nombre de pila del contacto",
  },
  {
    name: "contact_lastName",
    value: "contact_lastName",
    type: "string",
    label: "Apellido del contacto",
  },
  {
    name: "contact_name",
    value: "contact_name",
    type: "string",
    label: "Nombre completo del contacto",
  },
  {
    name: "contact_phone",
    value: "contact_phone",
    type: "string",
    label: "Teléfono del contacto",
  },
  {
    name: "contact_fullAddress",
    value: "contact_fullAddress",
    type: "string",
    label: "Domicilio completo del contacto",
  },
  {
    name: "contact_street",
    value: "contact_street",
    type: "string",
    label: "Domicilio (calle y número) del contacto",
  },
  {
    name: "contact_zip",
    value: "contact_zip",
    type: "string",
    label: "Codigo postal del contacto",
  },
  {
    name: "contact_city",
    value: "contact_city",
    type: "string",
    label: "Ciudad del contacto",
  },
  {
    name: "contact_state",
    value: "contact_state",
    type: "string",
    label: "Provincia del contacto",
  },
  {
    name: "contact_country",
    value: "contact_country",
    type: "string",
    label: "País del contacto",
  },
  {
    name: "contact_email",
    value: "contact_email",
    type: "string",
    label: "Email del contacto",
  },
  {
    name: "contact_org",
    value: "contact_org",
    type: "string",
    label: "Organización del contacto",
  },
  {
    name: "contact_birthday",
    value: "contact_birthday",
    type: "string",
    label: "Fecha de nacimiento del contacto",
  },
];

export const BROADCAST_VARIABLES = [
  {
    name: "broadcast_text",
    value: "broadcast_text",
    type: "string",
    label: "Opción elegida (texto)",
  },
  {
    name: "broadcast_index",
    value: "broadcast_index",
    type: "number",
    label: "Opción elegida (índice)",
  },
];
