import { useContext, useState, useEffect } from 'react';
import { FlowContext } from '../../../../context/FlowContext';
import { ImWhatsapp } from 'react-icons/im';
import { BiGitBranch, BiSupport } from 'react-icons/bi';
import { FiArchive, FiClock, FiTag, FiUsers, FiXCircle } from 'react-icons/fi';
import { BsSoundwave } from 'react-icons/bs';
import { SlLocationPin } from "react-icons/sl";
import {
  TbAuth2Fa,
  TbClockPause,
  TbExternalLink,
  TbMessage
} from 'react-icons/tb';
import {
  MdOutlineHttp,
  MdLockOutline,
  MdLockOpen,
  MdOutlineStickyNote2
} from 'react-icons/md';
import { GrCycle } from 'react-icons/gr';
import { HiVariable } from 'react-icons/hi';
import { manageWorkingNode } from '../utils/manageWorkingNode';
import { AiOutlineMail, AiOutlineShoppingCart } from 'react-icons/ai';
import { SiOpenai } from 'react-icons/si';
import { PiAddressBookBold, PiMathOperations, PiTicketLight } from 'react-icons/pi';
import { FaLink } from 'react-icons/fa6';
import { LiaRobotSolid } from 'react-icons/lia';
import { NODE_TYPES } from '../nodeTypes';
import { useReactFlow } from 'reactflow';
import { Buscador } from '../../../buscadores/Buscador';
import { RiGitBranchLine } from "react-icons/ri";

const SIZE = 32;

export const InitialForm = () => {
  const {
    nodoSeleccionado,
    setNodes,
    setNodoSeleccionado,
    setMostrarDrawer,
    nodes,
    edges,
    setEdges,
    nextNodeId,
    reactFlowWrapper
  } = useContext(FlowContext);

  const [filteredBloques, setFilteredBloques] = useState(NODE_TYPES);
  const [categorizedNodeTypes, setCategorizedNodeTypes] = useState({});

  const { screenToFlowPosition } = useReactFlow();

  const handleSetType = (type) => {
    try {
      if (nodoSeleccionado?.id) {
        // Es un nodo ya creado por arrastre, hay que darle un type
        setNodes((prevState) => {
          let updatedNodes = [...prevState];
          let index = updatedNodes.findIndex(
            (node) => node.id === nodoSeleccionado.id
          );
          updatedNodes[index].type = type.id;
          updatedNodes[index].data.label = type.name;

          setNodoSeleccionado(updatedNodes[index]);
          return updatedNodes;
        });

        if (['note'].includes(type.id)) {
          //Borro el edge de la nota
          setEdges((prevState) => {
            const nodeId = nodoSeleccionado?.id;
            let array = prevState.filter((edge) => {
              if (
                edge.source === nodeId ||
                edge.target === nodeId ||
                edge.sourceHandle === nodeId
              ) {
                return false;
              }

              return true;
            });

            return array;
          });
        }
      } else {
        // Se va a crear un nodo desde el boton de agregar nodo.
        const id = nextNodeId.current.toString();

        let positionX =
          reactFlowWrapper.current.getBoundingClientRect().width / 2 || 0;

        let positionY =
          reactFlowWrapper.current.getBoundingClientRect().height / 10 || 0;

        const newNode = {
          id,
          position: screenToFlowPosition({
            x: positionX || 0,
            y: positionY || 0
          }),
          zIndex: 99999,
          type: type.id,
          data: { label: type.name }
        };

        setNodoSeleccionado(newNode);

        setNodes((prevState) => {
          let array = [...prevState];

          array.push(newNode);

          // console.log("array", array);

          return array;
        });
      }

      if (['textToSpeech', 'transcribeAudio'].includes(type.id)) {
        manageWorkingNode(
          nodes,
          setNodes,
          edges,
          setEdges,
          nodoSeleccionado,
          false
        );
      }

      if (['end', 'isContact'].includes(type.id)) {
        setMostrarDrawer(false);
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const handleIcon = (type) => {
    switch (type.id) {
      case 'message':
        return (
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={SIZE} />
        );
      case 'buttons':
        return (
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={SIZE} />
        );
      case 'list':
        return (
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={SIZE} />
        );
      case 'branch':
        return <BiGitBranch size={SIZE} />;
      case "tagBranch":
        return <RiGitBranchLine size={SIZE} />;
      case "budget":
        return (
          <AiOutlineShoppingCart
            style={{ color: 'var(--dark-color)' }}
            size={SIZE}
          />
        );
      case 'dateTime':
        return <FiClock style={{ color: 'var(--dark-color)' }} size={SIZE} />;
      // case "textToSpeech":
      //   return (
      //     <RxSpeakerLoud style={{ color: "var(--dark-color)" }} size={SIZE} />
      //   );
      case 'transcribeAudio':
        return <BsSoundwave size={SIZE} />;
      case 'httpRequest':
        return (
          <MdOutlineHttp style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      case 'iterableList':
        return <GrCycle style={{ color: 'var(--dark-color)' }} size={SIZE} />;
      case 'oneTimePassword':
        return <TbAuth2Fa style={{ color: 'var(--dark-color)' }} size={SIZE} />;
      case 'setDelay':
        return (
          <TbClockPause style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      case 'sendTemplate':
        return <FiArchive style={{ color: 'var(--dark-color)' }} size={SIZE} />;
      case 'setVariables':
        return (
          <HiVariable style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      case 'sendMultiProduct':
        return (
          <AiOutlineShoppingCart
            style={{ color: 'var(--dark-color)' }}
            size={SIZE}
          />
        );
      case 'sendSingleProduct':
        return (
          <AiOutlineShoppingCart
            style={{ color: 'var(--dark-color)' }}
            size={SIZE}
          />
        );
      case 'end':
        return <FiXCircle size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'encode':
        return (
          <MdLockOutline size={SIZE} style={{ color: 'var(--dark-color)' }} />
        );
      case 'decode':
        return (
          <MdLockOpen size={SIZE} style={{ color: 'var(--dark-color)' }} />
        );
      case 'cta_button':
        return (
          <TbExternalLink
            size={SIZE}
            style={{ color: 'var(--whatsapp-color)' }}
          />
        );
      case 'callFunction':
        return <SiOpenai size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'contactAgent':
        return <BiSupport size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'isContact':
        return (
          <PiAddressBookBold
            size={SIZE}
            style={{ color: 'var(--dark-color)' }}
          />
        );
      case 'formula':
        return (
          <PiMathOperations
            size={SIZE}
            style={{ color: 'var(--dark-color)' }}
          />
        );
      case 'sendMessageToChat':
        return <TbMessage size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'tagContact':
        return <FiTag size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'subscription':
        return <FaLink size={SIZE} style={{ color: 'var(--dark-color)' }} />;
      case 'goToFlow':
        return (
          <LiaRobotSolid size={SIZE} style={{ color: 'var(--dark-color)' }} />
        );
      case 'sendEmail':
        return (
          <AiOutlineMail size={SIZE} style={{ color: 'var(--dark-color)' }} />
        );
      case 'note':
        return (
          <MdOutlineStickyNote2
            size={SIZE}
            style={{ color: 'var(--dark-color)' }}
          />
        );
      case "clientsByContact":
        return <FiUsers size={SIZE} color={{ color: "var(--dark-color)" }} />;
      case 'messageContact':
        return (
          <ImWhatsapp style={{ color: 'var(--whatsapp-color)' }} size={SIZE} />
        );
      case 'requestLocation':
        return (
          <SlLocationPin style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      case 'openAIAssistant':
        return (
          <SiOpenai style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      case 'createTicket':
        return (
          <PiTicketLight style={{ color: 'var(--dark-color)' }} size={SIZE} />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    // Agrupar nodos por categorías:
    const newCategorizedNodeTypes = filteredBloques.reduce((acc, type) => {
      if (!acc[type.category]) {
        acc[type.category] = [];
      }
      acc[type.category].push(type);
      return acc;
    }, {});

    setCategorizedNodeTypes(newCategorizedNodeTypes);
  }, [filteredBloques]);

  // Ordenar categorías alfabéticamente, excepto 'Otros':
  const sortedCategories = Object.keys(categorizedNodeTypes).sort((a, b) => {
    if (a === 'Otros') {
      return 1;
    }
    if (b === 'Otros') {
      return -1;
    }

    return a.localeCompare(b);
  });

  return (
    <>
      <p style={{ marginBottom: 16, fontSize: 18, fontWeight: 600 }}>
        Seleccione un tipo de bloque
      </p>

      <Buscador
        element='bloque'
        data={NODE_TYPES}
        setFilteredData={setFilteredBloques}
        searchKeys={['name', 'category', 'description']}
        maxWidth='100%'
      />

      <div className='types-container'>
        {sortedCategories.map((category) => (
          <div key={category} className='types-category-container'>
            <p style={{ margin: '16px 0 8px 0' }}>{category}</p>
            {categorizedNodeTypes[category].map((type) => (
              <div
                key={type.id}
                className='type-item'
                onClick={() => handleSetType(type)}
              >
                <div>{handleIcon(type)}</div>

                <div className='type-item-text-container'>
                  <span style={{ fontWeight: '500' }}>{type.name}</span>
                  <span style={{ color: '#a6a6a6' }}>{type.description}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
