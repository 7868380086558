import { Button, Popover } from "antd";
import { SelectVariables } from "../SelectVariables";
import { useState } from "react";

export const BotonAgregarVariable = ({
  nodeId,
  seccion = "",
  decorarTexto,
  excludedTypes = [],
  lookingForTypes = [],
  showContextVars = true,
  context = "text",
  indexedVars = false,
  selectedVariable = null,
  isBranch = false,
  arrayIndex,
  variableConvertir = null
}) => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div
      className="contenedor-botones-texto fade-in"
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Popover
        content={
          <SelectVariables
            decorarTexto={decorarTexto}
            context={context}
            seccion={seccion}
            nodeId={nodeId}
            isBranch={isBranch}
            setShowPopover={setShowPopover}
            showContextVars={showContextVars}
            excludedTypes={excludedTypes}
            lookingForTypes={lookingForTypes}
            indexedVars={indexedVars}
            selectedVariable={selectedVariable}
            arrayIndex={arrayIndex}
            variableConvertir={variableConvertir}
          />
        }
        trigger="click"
        placement="bottom"
        open={showPopover}
        style={{
          backgroundColor: "black",
          border: "1px solid black",
          margin: -12,
          padding: 0,
        }}
      >
        {isBranch ? (
          <Button
            className="btn-agregar"
            type="primary"
            size="small"
            style={{ borderRadius: "6px", marginTop: "8px" }}
            onClick={() => setShowPopover(true)}
          >
            Elegir variable
          </Button>
        ) : (
          <Button
            className="btn-agregar btn-oscuro fade-in"
            type="primary"
            size="small"
            style={{ borderRadius: "6px" }}
            onClick={() => setShowPopover(true)}
          >
            Agregar variable
          </Button>
        )}
      </Popover>
    </div>
  );
};
