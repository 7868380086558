import { Button, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from "../../../../Loader/LoadingIcon";
import {
  getContactByUserId,
  upsertUserContact,
} from "../../../../../../helpers/fetchData/fetchUsers";
import { SelectContactos } from "../../../../selectContactos/SelectContactos";

export const EditUser = ({ roles, setDrawerData, drawerData, setUsuarios }) => {
  const { instance, messageApi, setContactos } = useContext(GlobalContext);

  const [selectedRol, setSelectedRol] = useState(null);
  const [loadingEditar, setLoadingEditar] = useState(false);
  const [loadingDataContacto, setLoadingDataContacto] = useState(true);

  const [contactId, setContactId] = useState(null);

  const [form] = Form.useForm();

  const actualizarLista = (data) => {
    setContactos((prevState) => {
      let array = [...(prevState || [])];

      // Busco otros contactos que tengan el userId.
      const indexPrev = array.findIndex(
        (contacto) => contacto?.userId === data?.userId
      );

      if (indexPrev >= 0) {
        // Si hay alguno, se lo quito.
        array[indexPrev] = { ...array[indexPrev], userId: null };
      }

      // Busco el contacto seleccionado.
      const index = array.findIndex(
        (contacto) => contacto._id === data.contactId
      );

      if (index >= 0) {
        // Si está en contactos, actualizo el userId
        array[index] = { ...array[index], userId: data.userId };
      }

      return array;
    });

    setDrawerData({ open: false, data: null });
  };

  const handleEdit = (v) => {
    if (!selectedRol) {
      messageApi.info("Es necesario que seleccione un rol");
      return;
    }

    let data = {
      userId: drawerData.data?._id,
      roleId: selectedRol,
      contactId: contactId,
    };

    setLoadingEditar(true);
    upsertUserContact(instance?.userData?.apiKey, data)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Usuario actualizado correctamente");
          setUsuarios((prevState) => {
            let array = prevState.map((usuario) => {
              if (usuario._id === drawerData.data?._id) {
                usuario.association = {
                  roleId: selectedRol,
                  name: roles.find((rol) => rol._id === selectedRol)?.name,
                };
              }

              return usuario;
            });

            return array;
          });

          actualizarLista(data);
          setDrawerData({ open: false, data: null });
        } else {
          messageApi.error("Ocurrió un error al editar un contacto");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al editar un contacto");
      })
      .finally(() => setLoadingEditar(false));
  };

  useEffect(() => {
    if (drawerData.data?.association?.roleId) {
      setSelectedRol(drawerData.data.association.roleId);
    }
  }, [drawerData]);

  useEffect(() => {
    if (instance?.userData?.apiKey && drawerData?.data?._id) {
      setLoadingDataContacto(true);

      form.setFieldsValue({
        email: drawerData?.data?.email,
      });

      getContactByUserId(instance.userData.apiKey, drawerData.data._id)
        .then((res) => {
          if (res?.status === 200) {
            let item = res?.data;

            if (item?._id) {
              // Si está guardado como contacto;
              setContactId(item._id);
            } else {
              messageApi.info("El usuario no tiene un contacto asociado");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al obtener info del usuario");
        })
        .finally(() => setLoadingDataContacto(false));
    }
  }, [drawerData, instance]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formText"
      requiredMark={false}
      onFinish={handleEdit}
      className="form-nodos"
    >
      <b style={{ marginBottom: 16 }}>Editar usuario</b>
      {loadingDataContacto ? (
        <div style={{ margin: 50, display: "grid", placeItems: "center" }}>
          <LoadingIcon />
        </div>
      ) : (
        <>
          <Form.Item label="Seleccione un rol">
            <Select
              options={roles?.map((rol) => {
                return {
                  label: rol.name,
                  value: rol._id,
                };
              })}
              onChange={(v) => setSelectedRol(v)}
              value={selectedRol}
            />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Contacto">
            <SelectContactos value={contactId} setValue={setContactId} />
          </Form.Item>

          {/* <span className="horizontal gap8">
            <Form.Item
              label="Nombre"
              name="first_name"
              className="half-50"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Segundo nombre"
              name="middle_name"
              className="half-50"
            >
              <Input />
            </Form.Item>
          </span>
          <span className="horizontal gap8">
            <Form.Item
              label="Apellido"
              name="last_name"
              className="half-50"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Fecha de nacimiento"
              name="birthday"
              className="half-50"
              initialValue={null}
            >
              <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </span>
          <Form.Item
            label="Teléfono"
            name="phone"
            rules={[
              {
                pattern: new RegExp(/^[0-9]*$/),
                message: "No se permiten espacios ni caracteres especiales",
              },
              {
                min: 11,
                max: 12,
                message:
                  "El número debe tener 11-12 dígitos. Por ejemplo: 543535123456",
              },
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Input placeholder="543535362409" />
          </Form.Item>

          <Form.Item label="Organización" name="company">
            <Input />
          </Form.Item>
          <span className="horizontal gap8">
            <Form.Item
              label="Departamento"
              name="department"
              className="half-50"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Cargo" name="title" className="half-50">
              <Input />
            </Form.Item>
          </span>
          <span className="horizontal gap8">
            <Form.Item label="País" name="country" className="half-50">
              <Input />
            </Form.Item>
            <Form.Item
              label="Estado o provincia"
              name="state"
              className="half-50"
            >
              <Input />
            </Form.Item>
          </span>
          <span className="horizontal gap8">
            <Form.Item label="Localidad" name="city" className="half-50">
              <Input />
            </Form.Item>
            <Form.Item label="Dirección" name="street" className="half-50">
              <Input />
            </Form.Item>
          </span>
          <span className="horizontal gap8">
            <Form.Item label="Código postal" name="zip" className="half-50">
              <Input />
            </Form.Item>
            <Form.Item
              label="Tipo de dirección"
              name="type"
              initialValue="home"
              className="half-50"
            >
              <Select
                options={[
                  { label: "Casa", value: "home" },
                  { label: "Trabajo", value: "work" },
                ]}
              />
            </Form.Item>
          </span> */}
        </>
      )}
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            //loading={loadingEditar}
            icon={
              loadingEditar ? (
                <LoadingIcon size="small" color="#FFFFFF" />
              ) : null
            }
            style={{ opacity: loadingEditar ? 0.65 : 1 }}
            // onClick={handleEdit}
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setDrawerData({ open: false, data: null });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
