import { Checkbox, Input, Radio } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { handleCallButtons } from "../../../../../../helpers/utilidadesTemplates";

export const TemplateAuthentication = ({
  setBodyContent,
  setFooterContent,
  caducidad,
  setCaducidad,
  buttonsContent,
  setButtonsContent,
  otpType,
  setOtpType,
  originalComponents = null,
}) => {
  const [securityReco, setSecurityReco] = useState(false);

  const handleReco = (v) => {
    setSecurityReco(v);
  };

  const handleCaducidad = (type, v) => {
    if (type === "bool") {
      setCaducidad((prevState) => {
        return { ...prevState, bool: v, message: "" };
      });
    } else {
      if (Number(v) < 1 || Number(v) > 90) {
        setCaducidad((prevState) => {
          return {
            ...prevState,
            value: v,
            message: "Ingresa un valor entre 1 y 90",
          };
        });
      } else {
        setCaducidad((prevState) => {
          return {
            ...prevState,
            value: v,
            message: "",
          };
        });
      }
    }
  };

  const handleOtpType = (v) => {
    setOtpType(v);
  };

  useEffect(() => {
    if (otpType === "autocomplete") {
      setButtonsContent([
        {
          type: "COPY_CODE",
          copyType: "autocomplete",
          text: "Autocompletar",
          link: "www.test.com.ar",
        },
        {
          type: "COPY_CODE",
          copyType: "copy",
          text: "Copiar código",
          link: "1245",
        },
      ]);
    } else {
      setButtonsContent([
        {
          type: "COPY_CODE",
          copyType: "copy",
          text: "Copiar código",
          link: "1245",
        },
      ]);
    }
  }, [otpType]);

  useEffect(() => {
    setFooterContent(`Este código caduca en ${caducidad.value} minutos`);
  }, [caducidad]);

  useEffect(() => {
    if (originalComponents) {
      let body = originalComponents?.components?.find(
        (element) => element.type === "BODY"
      );

      if (body) {
        if (
          body.text
            ?.toLowerCase()
            ?.includes("por tu seguridad, no lo compartas.")
        ) {
          setSecurityReco(true);
        }
      }
    }
  }, [originalComponents]);

  useEffect(() => {
    if (securityReco) {
      setBodyContent(
        "Tu código de verificación es {{1}}. Por tu seguridad, no lo compartas."
      );
    } else {
      setBodyContent("Tu código de verificación es {{1}}.");
    }
  }, [securityReco]);

  return (
    <>
      <div className="item-columna-datos">
        <p className="item-dato-titulo">Entrega del código</p>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            color: "rgba(28,43,51,.65)",
          }}
        >
          Elige cómo los clientes envían el código de WhatsApp a tu app. Obtén
          información sobre como enviar{" "}
          {
            <a href="#" target="_blank" rel="noreferrer">
              plantillas de mensajes de autenticación
            </a>
          }
        </p>
      </div>
      <Radio.Group
        className="radio-otp"
        value={otpType}
        onChange={(v) => handleOtpType(v.target.value)}
      >
        <Radio
          value="autocomplete"
          style={{ lineHeight: "1", padding: "8px 0px", marginBottom: "8px" }}
          tabIndex={0}
        >
          <div className="radio-option-wrapper">
            <div className="radio-option-description">
              <span className="radio-option-title">Autocompletar</span>
              <span className="radio-option-caption">
                Se recomienda como la opción mas sencilla para los clientes. El
                código se envía a tu app cuando un cliente toca el botón. Cuando
                no sea posible autocompletar, se enviará un mensaje para copiar
                el código.
              </span>
              {otpType === "autocomplete" && (
                <div className="form-autocompletar">
                  <span className="horizontal">
                    <span
                      className="form-autocompletar-item"
                      style={{ width: "50%" }}
                    >
                      <p>Texto del botón para autocompletar</p>
                      <Input
                        maxLength={25}
                        value={buttonsContent[0]?.text}
                        onChange={(v) =>
                          setButtonsContent((prevState) =>
                            handleCallButtons(
                              prevState,
                              "text",
                              0,
                              v.target.value
                            )
                          )
                        }
                        showCount
                      />
                    </span>
                    <span
                      className="form-autocompletar-item"
                      style={{ width: "50%" }}
                    >
                      <p>Texto del botón para copiar código</p>
                      <Input
                        maxLength={25}
                        value={buttonsContent[1]?.text}
                        onChange={(v) =>
                          setButtonsContent((prevState) =>
                            handleCallButtons(
                              prevState,
                              "text",
                              1,
                              v.target.value
                            )
                          )
                        }
                        showCount
                      />
                    </span>
                  </span>
                  <span className="form-autocompletar-item">
                    <p>Nombre del paquete</p>
                    <Input placeholder="Ingresa el texto (com.example.myapplication)" />
                  </span>
                  <span className="form-autocompletar-item">
                    <p>Hash de firma de la app</p>
                    <Input placeholder="Ingresa el texto" />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Radio>
        <Radio
          value="copy"
          style={{ lineHeight: "1", padding: "8px 0px" }}
          tabIndex={1}
        >
          <div className="radio-option-wrapper">
            <div className="radio-option-description">
              <span className="radio-option-title">Copiar código</span>
              <span className="radio-option-caption">
                Autenticación básica con configuración rápida. Los clientes
                copian y pegan el código en tu app.
              </span>
              {otpType === "copy" && (
                <div className="form-autocompletar">
                  <span
                    className="form-autocompletar-item"
                    style={{ width: "50%" }}
                  >
                    <p>Texto del botón</p>
                    <Input
                      maxLength={25}
                      value={buttonsContent[0]?.text}
                      onChange={(v) =>
                        setButtonsContent((prevState) =>
                          handleCallButtons(
                            prevState,
                            "text",
                            0,
                            v.target.value
                          )
                        )
                      }
                      showCount
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Radio>
      </Radio.Group>
      <div className="item-columna-datos">
        <p className="item-dato-titulo">Contenido del mensaje</p>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            color: "rgba(28,43,51,.65)",
          }}
        >
          El contenido de las plantillas de mensajes de autenticación no se
          puede editar. Puedes agregar contenido adicional de las siguientes
          opciones.
        </p>
        <span className="columna">
          <Checkbox
            onChange={(v) => handleReco(v.target.checked)}
            checked={securityReco}
          >
            Agregar recomendación de seguridad
          </Checkbox>
          <Checkbox
            onChange={(v) => handleCaducidad("bool", v.target.checked)}
            checked={caducidad?.bool}
          >
            Agrega la fecha de caducidad para el código
          </Checkbox>
          {caducidad.bool && (
            <div className="form-autocompletar-item">
              <span
                className="form-autocompletar-item"
                style={{ width: "150px" }}
              >
                <p>Caduca en</p>
                <Input
                  type="number"
                  value={caducidad.value}
                  addonAfter="minutos"
                  min="1"
                  max="90"
                  onChange={(v) => handleCaducidad("value", v.target.value)}
                />
              </span>
              {caducidad.message && (
                <span className="mensaje-error">
                  <AiOutlineWarning color="#aa4a44" />
                  <span>{caducidad.message}</span>
                </span>
              )}
            </div>
          )}
        </span>
      </div>
    </>
  );
};
