import { BloquearContacto } from '../../../../contactos/accionesContactos/BloquearContacto';
import { DesbloquearContacto } from '../../../../contactos/accionesContactos/DesbloquearContacto';
import { VerContacto } from '../../../../contactos/accionesContactos/VerContacto';
import { DesarchivarContacto } from './DesarchivarContacto';

export const AccionesArchivo = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        justifyContent: 'center'
      }}
    >
      <VerContacto item={item} />
      {item.isBlocked ? (
        <DesbloquearContacto item={item} />
      ) : (
        <BloquearContacto item={item} />
      )}
      <DesarchivarContacto item={item} />
    </div>
  );
};
