import { Button, Drawer, Form, Input, Select, Upload } from "antd";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { InboxOutlined } from "@ant-design/icons";
import { normFile } from "../../../../flows/utils/cargaMedia";
import axios from "axios";
import LoadingIcon from "../../../../../Loader/LoadingIcon";

export const EditarPerfilNegocio = ({
  drawerEditarPerfil,
  setDrawerEditarPerfil,
}) => {
  const { whatsappProfile, setWhatsappProfile, messageApi, instance } =
    useContext(GlobalContext);

  const { Dragger } = Upload;
  const { TextArea } = Input;

  const [formEditarPerfilNegocio] = Form.useForm();

  const [loading, setLoading] = useState(false);

  function onClose() {
    setDrawerEditarPerfil(false);
  }

  function onFinish(form) {
    setLoading(true);

    let data = new FormData();
    data.append("messaging_product", "whatsapp");
    data.append("address", form.address);
    data.append("description", form.description);
    data.append("email", form.email);
    data.append("vertical", form.vertical);
    data.append("websites", form.websites);

    if (form.image?.[0] && form.image[0].status === "done") {
      data.append("file", form.image[0].originFileObj);
    }

    const dataObject = {
      address: form.address,
      description: form.description,
      email: form.email,
      vertical: form.vertical,
      websites: [form.websites],
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PRODUCCION}/wb/updateWhatsappBusinessProfile`,
      headers: {
        Authorization: instance?.userData?.apiKey,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Perfil editado");
          setWhatsappProfile((prevState) => {
            const newWhatsappProfile = { ...prevState[0], ...dataObject };
            return [newWhatsappProfile];
          });
          onClose();
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("Error al editar el perfil");
      })
      .finally(() => setLoading(false));
  }

  // IMAGEN
  function getImageSize(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const image = new Image();

        image.onload = function () {
          const size = {
            width: this.width,
            height: this.height,
          };
          resolve(size);
        };
        image.src = e.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function customRequest(req) {
    if (req.file.type === "image/png") {
      getImageSize(req.file)
        .then((size) => {
          if (size.width === 640 && size.height === 640) {
            req.onSuccess();
            messageApi.success("Imagen cargada");
          } else {
            messageApi.error("El tamaño de la imagen debe ser de 640x640");
            req.onError("El tamaño de la imagen debe ser de 640x640");
          }
        })
        .catch((error) => {
          console.error("Error al obtener el tamaño de la imagen:", error);
        });
    } else {
      messageApi.error("El formato no es válido");
      req.onError("El formato no es válido");
    }
  }

  // CATEGORÍA
  const categoryOptions = [
    {
      value: "AUTO",
      label: "Automóviles",
    },
    {
      value: "BEAUTY",
      label: "Belleza, spa y estética",
    },
    {
      value: "APPAREL",
      label: "Ropa y accesorios",
    },
    {
      value: "EDU",
      label: "Educación",
    },
    {
      value: "ENTERTAIN",
      label: "Entretenimiento",
    },
    {
      value: "EVENT_PLAN",
      label: "Servicios y planificación de eventos",
    },
    {
      value: "FINANCE",
      label: "Finanzas y banca",
    },
    {
      value: "GROCERY",
      label: "Comida y comestibles",
    },
    {
      value: "GOVT",
      label: "Servicio público",
    },
    {
      value: "HOTEL",
      label: "Hotel y alojamiento",
    },
    {
      value: "HEALTH",
      label: "Medicina y salud",
    },
    {
      value: "NONPROFIT",
      label: "Organización sin fines de lucro",
    },
    {
      value: "PROF_SERVICES",
      label: "Servicios profesionales",
    },
    {
      value: "RETAIL",
      label: "Compras y ventas minoristas",
    },
    {
      value: "TRAVEL",
      label: "Viajes y transporte",
    },
    {
      value: "RESTAURANT",
      label: "Restaurante",
    },
    {
      value: "OTHER",
      label: "Otro",
    },
  ];

  return (
    <Drawer
      title="Editar perfil"
      width={500}
      placement="right"
      styles={{ header: { display: "none" } }}
      open={drawerEditarPerfil}
      onClose={onClose}
      destroyOnClose
    >
      <Form
        className="form-nodos"
        name="editarPerfilNegocio"
        form={formEditarPerfilNegocio}
        layout="vertical"
        onFinish={onFinish}
        requiredMark="optional"
        // style={{ padding: 24 }}
        preserve={false}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Editar perfil de negocio
        </p>

        <Form.Item
          label="Imagen de perfil"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger
            customRequest={customRequest}
            maxCount={1}
            accept="image/png"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Haga click o arrastre un archivo aquí
            </p>
            <p className="ant-upload-hint">Soporta archivos en formato PNG.</p>
            <p className="ant-upload-hint">Con un tamaño de 640x640.</p>
          </Dragger>
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Nombre"
          required
        >
          <p className="editar-perfil-form-default-text">
            {whatsappProfile[0].verified_name &&
              whatsappProfile[0].verified_name}
          </p>
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Número de teléfono"
          required
        >
          <p className="editar-perfil-form-default-text">
            {whatsappProfile[0].display_phone_number &&
              whatsappProfile[0].display_phone_number}
          </p>
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Categoría"
          name="vertical"
          initialValue={
            whatsappProfile[0].vertical && whatsappProfile[0].vertical
          }
          required
        >
          <Select size="large" options={categoryOptions} />
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Descripción"
          name="description"
          initialValue={
            whatsappProfile[0].description && whatsappProfile[0].description
          }
        >
          <TextArea
            size="large"
            autoSize={{ minRows: 4 }}
            maxLength={512}
            showCount
          />
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Dirección"
          name="address"
          initialValue={
            whatsappProfile[0].address && whatsappProfile[0].address
          }
        >
          <Input size="large" maxLength={256} showCount />
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Correo electrónico"
          name="email"
          initialValue={whatsappProfile[0].email && whatsappProfile[0].email}
        >
          <Input size="large" maxLength={128} showCount />
        </Form.Item>

        <Form.Item
          className="editar-perfil-formItem-input"
          label="Sitio web"
          name="websites"
          initialValue={
            whatsappProfile[0].websites?.length > 0 &&
            whatsappProfile[0].websites[0]
              ? whatsappProfile[0].websites[0]
              : ""
          }
        >
          <Input size="large" maxLength={256} showCount />
        </Form.Item>

        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              className="btn-guardar"
              type="primary"
              htmlType="submit"
              size="large"
              icon={
                loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              type="secondary"
              size="large"
              onClick={onClose}
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
