import { FilterFilled } from "@ant-design/icons";
import { Checkbox, Input, Popover } from "antd";

export const ExtrasBuscador = ({ filtros, setFiltros }) => {
  return (
    <div className="extras-buscador">
      <Input placeholder="Buscar..." allowClear />
      <Popover
        placement="bottomRight"
        arrow={false}
        trigger="click"
        content={<PopoverFiltros filtros={filtros} setFiltros={setFiltros} />}
      >
        <span style={{ height: 14 }} className="pointer">
          <FilterFilled style={{ color: "#bbb" }} />
        </span>
      </Popover>
    </div>
  );
};

const PopoverFiltros = ({ filtros, setFiltros }) => {
  return (
    <Checkbox.Group
      className="extra-buscador-checkbox"
      value={filtros}
      onChange={(checkedValues) => setFiltros(checkedValues)}
      options={[
        {
          label: "Tickets",
          value: "tickets",
        },
        {
          label: "Notas",
          value: "notes",
        },
        {
          label: "Media",
          value: "media",
        },
      ]}
    />
  );
};
