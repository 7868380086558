import axios from 'axios';

export const validateDuplicateTrigger = async (value, messageApi, apiKey) => {
  let data = JSON.stringify({
    trigger: value
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_PRODUCCION}/flows/validateTrigger`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: apiKey
    },
    data: data
  };

  let flag = false;

  await axios
    .request(config)
    .then((res) => {
      if (res?.status === 200) {
        flag = true;
      } else {
        messageApi.error(
          'El disparador no es válido, ya está siendo utilizado en otro flow o es una palabra reservada'
        );
      }
    })
    .catch((error) => {
      console.log(error);
      messageApi.error(
        `No se pudo validar el disparador. ${
          error?.response?.data?.message || ''
        }`
      );
    });

  return flag;
};
