import { Button, Form, Radio, Select, Switch } from "antd";
import Dragger from "antd/es/upload/Dragger";
// import { customRequest } from "../Layout/enviosMasivos/enviosBuilder/utils/template";
import { InboxOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { customRequest } from "./utils";

export const VariableTypes = ({
  templateSeleccionado,
  setVarsType,
  varsType,
  setXlsData,
  xlsOptions,
  matchClientXls,
  setMatchClientXls,
  xlsColumn,
  setXlsColumn,
  setMostrarRequestDrawer
}) => {
  const { messageApi } = useContext(GlobalContext);

  return (
    <>
      {templateSeleccionado?.id && (
        <>
          <Form.Item label="Tipo de variables">
            <Radio.Group
              onChange={(v) => setVarsType(v.target.value)}
              value={varsType}
              optionType="button"
              buttonStyle="solid"
              options={[
                { value: "none", label: "Sistema" },
                { value: "xls", label: "Excel" },
                { value: "http", label: "HTTP Request" },
              ]}
            />
          </Form.Item>

          {varsType === "xls" && (
            <>
              <Form.Item label="Ingrese un archivo para utilizar variables">
                <Dragger
                  name="file"
                  customRequest={(v) =>
                    customRequest(v, messageApi, setXlsData)
                  }
                  maxCount={1}
                  accept={
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Haga click o arrastre un archivo aquí
                  </p>
                  <p className="ant-upload-hint">{`Soporta archivos en formato xls, xlsx.`}</p>
                  <p className="ant-upload-hint">{`Con un máximo de 100MB.`}</p>
                </Dragger>
              </Form.Item>
              {xlsOptions?.length > 0 && (
                <Form.Item
                  label={
                    <span className="columna">
                      <Switch
                        checked={matchClientXls}
                        unCheckedChildren="Contacto"
                        checkedChildren="Cliente"
                        onChange={(v) => setMatchClientXls(v)}
                        style={{ width: "fit-content" }}
                      />
                      {matchClientXls
                        ? "Seleccione una columna con la que identificará el cliente (ID cuenta)"
                        : "Seleccione una columna con el que identificará el contacto (Número de teléfono)"}
                    </span>
                  }
                >
                  <Select
                    options={xlsOptions}
                    value={xlsColumn}
                    onChange={(v) => setXlsColumn(v)}
                  />
                </Form.Item>
              )}
            </>
          )}

          {varsType === "http" && (
            <Form.Item>
              <Button
                className="dark-color"
                onClick={() => setMostrarRequestDrawer(true)}
              >
                Configurar petición
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};
