import { useState } from "react";
import { Input } from "antd";
import Fuse from "fuse.js";

export const Buscador = ({
  element,
  data,
  setFilteredData,
  searchKeys,
  maxWidth = 250,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const fuse = new Fuse(data, {
    keys: searchKeys,
    threshold: 0.3,
  });

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value) {
      const results = fuse.search(value);

      const newFilteredData = results.map((result) => result.item);
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(data);
    }
  };

  return (
    <Input.Search
      style={{ maxWidth }}
      placeholder={`Buscar ${element}...`}
      value={searchTerm}
      onChange={handleChange}
      allowClear
    />
  );
};
