import { Button, Form, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { flattenFirstElementGetProducts } from "../../../flows/utils/flattenJSON";
import { BoxInfo } from "../../../../box-info/BoxInfo";
import { GlobalContext } from "../../../../../context/GlobalContext";
import LoadingIcon from "../../../../Loader/LoadingIcon";

export const ClientsLoadGetForm = ({
  handleStage,
  tmpConfig,
  setTmpConfig,
  result,
  setResult,
  setClientsConfig,
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleGuardar = (values) => {
    let objMap = {
      idSistema: values.idSistema, // Cuenta, identificador usado por ellos.
      name: values.name,
      email: values.email,
      cuit: values.cuit,
      phone: values.phone,
    };

    setTmpConfig((prevState) => {
      let obj = { ...prevState };

      let clientsLoad = { ...obj.clientsLoad };

      clientsLoad.objMap = objMap;

      obj.clientsLoad = clientsLoad;

      return obj;
    });

    handleStage(2);
  };

  const keyOptions = useMemo(() => {
    if (result) {
      let flattenedArray = flattenFirstElementGetProducts(result);

      return (
        flattenedArray?.map((element) => {
          return { value: element, label: element };
        }) || []
      );
    }

    return [];
  }, [result]);


  useEffect(() => {
    if (tmpConfig?.clientsLoad?.objMap) {
      const { name, idSistema, email, cuit, phone } =
        tmpConfig.clientsLoad.objMap;


      form.setFieldsValue({
        name: name,
        idSistema: idSistema,
        email: email,
        cuit: cuit,
        phone: phone,
      });

    }
  }, [tmpConfig]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleGuardar}
      name="clientsLoad"
      className="form-nodos"
    >
      <b style={{ marginBottom: "12px" }}>Mapeo sobre el resultado de la petición</b>
      <span style={{ marginBottom: "24px" }}>
        <BoxInfo
          message={
            "Necesitamos asociar las características de sus clientes para que coincidan con el formato utilizado en Brocoly."
          }
        />
      </span>

      <Form.Item
        name="idSistema"
        label="Cuenta (Código de identificación)"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name="name"
        label="Razón social"
        rules={[{ required: true, message: "Campo requerido" }]}
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
      >
        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name="cuit"
        label="CUIT">

        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Teléfono">

        <Select options={keyOptions} allowClear />
      </Form.Item>

      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => handleStage(0)}
          >
            Volver
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
            icon={loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null}
            style={{ opacity: loading ? 0.65 : 1 }}
          >
            Continuar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
