import dayjs from "dayjs";

export const handleTimeConditionsCount = (operacion, condiciones, index) => {
  let array = [...condiciones];

  if (operacion === "suma") {
    array.push({
      childId: "",
      value: [
        {
          granularities: {
            weekdays: [],
            time: {
              from: {
                hour: "00",
                minute: "00",
              },
              to: {
                hour: "00",
                minute: "00",
              },
            },
          },
        },
      ],
    });
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleGranularityCount = (
  operacion,
  condiciones,
  index,
  granularityIndex
) => {
  let array = [...condiciones];

  let horarios = [...array[index].value];

  if (operacion === "suma") {
    horarios.push({
      granularities: {
        weekdays: [],
        time: {
          from: {
            hour: "00",
            minute: "00",
          },
          to: {
            hour: "00",
            minute: "00",
          },
        },
      },
    });
  } else {
    horarios.splice(granularityIndex, 1);
  }
  array[index].value = horarios;

  return array;
};

export const handleGranularityValues = (
  type,
  value,
  condiciones,
  index,
  granularityIndex
) => {
  let array = structuredClone(condiciones);

  let item = array[index].value[granularityIndex].granularities;

  if (type === "dia") {
    if (!item.weekdays.includes(value)) {
      item.weekdays.push(value);
    } else {
      item.weekdays = item.weekdays.filter((day) => day !== value);
    }
  } else {
    let dateTime = value.split(":");
    if (type === "inicio") {
      item.time.from.hour = dateTime[0];
      item.time.from.minute = dateTime[1];
    } else {
      item.time.to.hour = dateTime[0];
      item.time.to.minute = dateTime[1];
    }
  }

  array[index].value[granularityIndex].granularities = item;

  return array;
};

export const operadores = {
  number: [
    {
      value: "equalTo",
      label: "Igual a",
    },
    {
      value: "notEqualTo",
      label: "Distinto a",
    },
    {
      value: "greaterEqualTo",
      label: "Mayor o igual a",
    },
    {
      value: "lessEqualTo",
      label: "Menor o igual a",
    },
    {
      value: "greaterThan",
      label: "Mayor a",
    },
    {
      value: "lessThan",
      label: "Menor a",
    },
  ],
  string: [
    { value: "contains", label: "Contiene" },
    { value: "isEqual", label: "Igual a" },
    { value: "doesNotContain", label: "No contiene" },
    { value: "startsWith", label: "Empieza con" },
    { value: "doesNotStartWith", label: "No empieza con" },
  ],
};

export const handleTime = (dateTime) => {
  let fechaHora = "";
  if (dateTime) {
    fechaHora = `${dateTime.hour}:${dateTime.minute}`;
  }

  let value = dayjs(fechaHora, "HH:mm");

  return value;
};

export const isDaySelected = (value, weekdays) => {
  let className = "dia-item";
  if (weekdays.includes(value)) {
    className = "dia-item dia-selected";
  }
  return className;
};

export const handleOperator = (value) => {
  switch (value) {
    case "equalTo":
      return "Es igual a";
    case "notEqualTo":
      return "Es distinto a";
    case "greaterEqualTo":
      return "Es mayor o igual a";
    case "lessEqualTo":
      return "Es menor o igual a";
    case "greaterThan":
      return "Es mayor a";
    case "lessThan":
      return "Es menor a";
    case "contains":
      return "Contiene";
    case "isEqual":
      return "Es igual a";
    case "doesNotContain":
      return "No contiene";
    case "startsWith":
      return "Comienza con";
    case "doesNotStartWith":
      return "No comienza con";
    default:
      return value;
  }
};

export const handleConnection = (index, nodeId, edges) => {
  let flag = false;
  if (edges) {
    edges.forEach((edge) => {
      if (edge.source === nodeId) {
        if (edge.sourceHandle === String(index)) {
          flag = true;
        }
      }
    });
  }

  return flag;
};

export const handleInnerEdges = (nodeId, setEdges, flag) => {
  setEdges((prevState) => {
    let conectores;
    if (flag) {
      conectores = prevState.filter((edge) => {
        if (edge.source === nodeId && edge.sourceHandle !== "nextstep") {
          return false;
        } else {
          return true;
        }
      });
    } else {
      conectores = prevState.filter((edge) => {
        if (edge.source === nodeId && edge.sourceHandle === "nextstep") {
          return false;
        } else {
          return true;
        }
      });
    }
    return conectores;
  });
};

export const checkValidConnection = (edges, nodeId, handle) => {
  let filteredEdges = edges?.filter((edge) => edge.source === nodeId);

  if (filteredEdges?.length > 0) {
    if (!handle) return false;

    let filteredHandles = filteredEdges.filter(
      (edge) => edge.sourceHandle === handle
    );

    if (filteredHandles.length > 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

// export const isFirstNode = (nodes, nodoSeleccionado) => {
//   let id = nodes[1]?.id;

//   if (nodoSeleccionado.id === id) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const handleVariablesCount = (operacion, variables, index) => {
  let array = [...variables];

  if (operacion === "suma") {
    array.push({ name: "", value: "", type: "string" });
  } else {
    array.splice(index, 1);
  }

  return array;
};

export const handleVariablesValue = (columna, value, index, variables) => {
  let array = [...variables];

  array[index][columna] = value;

  return array;
};

export const checkID = (id) => {
  let flag = /^[0-9]+$/.test(id);

  return flag;
};
