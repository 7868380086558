import { Button, Drawer, Input, Select, Spin, Table, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { FiPlus, FiUsers } from "react-icons/fi";
import {
  getInvitationsByInstance,
  newInvitation,
} from "../../../../../../helpers/fetchData/fetchInvitation";
import { getRoles } from "../../../../../../helpers/fetchData/fetchTeams";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContext } from "../../../../../context/GlobalContext";
import { RoleDetail } from "./RoleDetail";
import { columnasInvitaciones, columnasUsuarios } from "../../utils/users";
import { EditUser } from "./EditUser";
import { accessDataFormat } from "../../../../../../helpers/access";
import { useAccess } from "../../../../../../hooks/useAccess";
import LoadingIcon from '../../../../Loader/LoadingIcon';

export const Users = () => {
  const { instance, messageApi } = useContext(GlobalContext);
  const { user } = useAuth0();
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRol, setSelectedRol] = useState(null);

  const isAllowed = useAccess();

  const [usuarios, setUsuarios] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingInvitation, setLoadingInvitation] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [showRoleDetail, setShowRoleDetail] = useState(null);

  const [drawerData, setDrawerData] = useState({ open: false, data: null });

  const tabItems = [
    {
      key: "1",
      label: "Usuarios",
      children: (
        <Table
          columns={columnasUsuarios(setUsuarios, setDrawerData, roles)}
          dataSource={usuarios || []}
          rowKey={"_id"}
          size="small"
          pagination={false}
          className="tabla-config"
        />
      ),
    },
    {
      key: "2",
      label: "Invitaciones",
      children: (
        <Table
          columns={columnasInvitaciones(instance)}
          dataSource={invitations || []}
          rowKey={"_id"}
          size="small"
          pagination={false}
          className="tabla-config"
        />
      ),
    },
  ];

  const enviarInvitación = () => {
    if (!email) {
      return messageApi.info("Debe ingresar un email válido");
    }

    if (!selectedRol) {
      return messageApi.info("Por favor, seleccione un rol");
    }

    setLoadingInvitation(true);

    newInvitation(instance.userData?.apiKey, {
      email: email,
      roleId: selectedRol,
    })
      .then((res) => {
        if (res?.status === 200) {
          messageApi.success("Invitación enviada correctamente");
          setInvitations((prevState) => {
            let obj = {
              _id: Date.now(),
              emailTo: email,
              status: "PENDING",
              invitedBy: {
                _id: Date.now(),
                name: user.name,
                email: user.email,
              },
            };

            return [obj, ...prevState];
          });

          setSelectedRol(null);
          setEmail("");
        }
      })
      .catch((error) => {
        console.log(error);
        messageApi.error("Ocurrió un error al enviar la invitación");
      })
      .finally(() => setLoadingInvitation(false));
  };

  useEffect(() => {
    if (instance?._id) {
      setLoadingUsers(true);
      setLoadingRoles(true);

      getInvitationsByInstance(instance.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            const { activeUsers, invitations } = res.data;

            setUsuarios(activeUsers || []);
            setInvitations(invitations || []);
          } else {
            messageApi.error("Ocurrió un error al obtener usuarios");
          }
        })
        .catch((error) =>
          messageApi.error("Ocurrió un error al obtener usuarios")
        )
        .finally(() => setLoadingUsers(false));

      getRoles(instance?.userData?.apiKey)
        .then((res) => {
          if (res?.status === 200) {
            if (Array.isArray(res.data)) {
              setRoles(res.data);
            }
          } else {
            messageApi.error("Ocurrió un error al obtener roles");
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al obtener usuarios");
        })
        .finally(() => setLoadingRoles(false));
    }
  }, [instance]);

  return (
    <div className="config-main-wrapper">
      <div className="test-main-header">
        <span
          className="horizontal"
          style={{ justifyContent: "space-between" }}
        >
          <span className="columna" style={{ gap: "0px" }}>
            <span className="config-header-inline">
              <span style={{ height: "24px" }}>
                <FiUsers size={24} color="var(--primary-color)" />
              </span>
              <span className="header-title-templates">Usuarios y roles</span>
            </span>
            <p className="header-subtitle">
              Administra usuarios y sus permisos
            </p>
          </span>
        </span>
      </div>
      <div className="usuarios-roles-content-wrapper">
        {showRoleDetail ? (
          <RoleDetail
            showRoleDetail={showRoleDetail}
            setShowRoleDetail={setShowRoleDetail}
            setRoles={setRoles}
          />
        ) : (
          <>
            <span className="horizontal" style={{ marginBottom: "32px" }}>
              {loadingRoles ? (
                <span
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingIcon size="large" />
                </span>
              ) : (
                <>
                  {roles?.map((rol) => {
                    return (
                      <span
                        key={rol._id}
                        className="rol-wrapper"
                        onClick={() => setShowRoleDetail(rol)}
                      >
                        <div className="rol-content">
                          <p>{rol.name}</p>
                          <p>{rol.description}</p>
                        </div>
                      </span>
                    );
                  })}
                  {isAllowed(accessDataFormat("users", "manage")) && (
                    <span
                      className="rol-wrapper"
                      style={{
                        height: 71,
                        width: 71,
                        display: "grid",
                        placeItems: "center",
                      }}
                      onClick={() => setShowRoleDetail({})}
                    >
                      <FiPlus size={24} style={{ color: "#1d39c4" }} />
                    </span>
                  )}
                </>
              )}
            </span>
            {isAllowed(accessDataFormat("users", "manage")) && (
              <span style={{ marginBottom: "32px" }}>
                <p style={{ marginBottom: "8px", fontWeight: 500 }}>
                  Invitar a un nuevo usuario
                </p>
                <span className="horizontal">
                  <Input
                    placeholder="Ingrese un email"
                    type="email"
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                  />
                  <Select
                    placeholder="Rol"
                    style={{ width: "250px", maxWidth: "40%" }}
                    options={roles?.map((rol) => {
                      return { value: rol._id, label: rol.name };
                    })}
                    value={selectedRol}
                    onChange={(v) => setSelectedRol(v)}
                  />
                  <Button
                    className="btn-agregar boton-dashed-32"
                    onClick={enviarInvitación}
                    //loading={loadingInvitation}
                    icon={loadingInvitation ? <LoadingIcon size="small" /> : null}
                    style={{ opacity: loadingInvitation ? 0.65 : 1 }}
                  >
                    Enviar invitación
                  </Button>
                </span>
              </span>
            )}
            {loadingUsers ? (
              <Spin
              style={{ marginTop: 50 }}
                indicator={
                  <LoadingIcon size="large" />
                }
              />
            ) : (
              <Tabs items={tabItems} />
            )}
          </>
        )}
      </div>

      <Drawer open={drawerData.open} width={450} destroyOnClose key="edit-user">
        <EditUser
          roles={roles}
          setDrawerData={setDrawerData}
          drawerData={drawerData}
          setUsuarios={setUsuarios}
        />
      </Drawer>
    </div>
  );
};
