import { useContext } from "react";
import { Handle, Position } from "reactflow";
import { FlowContext } from "../../../../context/FlowContext";
import { isSelectedClass } from "../../../../../helpers/isSelected";
import { PreviewNode } from "../PreviewNode";
import { useBorrarNode } from "../utils/useBorrarNode";
import { TbTrash } from "react-icons/tb";
import { checkValidConnection } from "../utils/utilidadesFlow";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { getNodeName } from "../utils/getNodeName";

export const MultiProductNode = (node) => {
  const { setMostrarDrawer, setNodoSeleccionado, edges } =
    useContext(FlowContext);

  const borrarNode = useBorrarNode(node.id);

  const handleBorrarNode = (event) => {
    event.stopPropagation();
    setMostrarDrawer(false);
    borrarNode();
  };

  return (
    <div
      className={isSelectedClass(node.selected)}
      onClick={() => {
        setMostrarDrawer(true);
        setNodoSeleccionado(node);
      }}
    >
      {node.selected && (
        <span className="borrar-nodo" onClick={handleBorrarNode}>
          <TbTrash />
        </span>
      )}
      <Handle
        type="target"
        position={Position.Left}
        isConnectableStart={false}
      />
      <div className="node-header">
        <div>
          <AiOutlineShoppingCart color="var(--dark-color)" size={32} />
        </div>
        <div className="node-description">
          <span className="node-type">{getNodeName(node)}</span>
          <span className="mensaje-nodo">{node?.data?.label}</span>
        </div>
      </div>
      <PreviewNode
        node={node}
        ambito={`nodes-${node.id}`}
        bodyContent={node?.data?.bodyText}
        buttonsCallContent={node?.data?.buttonsCallContent}
        buttonsContent={node?.data?.buttonsContent}
        buttonsMarketingContent={node?.data?.buttonsMarketingContent}
        footerContent={node?.data?.footerText}
        headerContent={node?.data?.headerText}
        headerMediaType={node?.data?.headerMediaType}
        headerType={node?.data?.headerType}
        inputText={node?.data?.inputText}
        preview={node?.data?.url}
        listContent={node?.data?.listContent}
        showInnerHandles={true}
        selected={node.selected}
      />
      <span className="contenedor-handles">
        <span className="linea-handle">
          <p>Next step</p>
          <Handle
            type="source"
            className="custom-handle next-step"
            position={Position.Right}
            id="nextstep"
            isConnectable={checkValidConnection(edges, node.id, "nextstep")}
          />
        </span>
      </span>
      <div className="path-id" style={{ marginTop: "24px" }}>
        {node?.id}
      </div>
    </div>
  );
};
