export const updateNode = (nodoSeleccionado, values, nodes) => {
  let nodo = { ...nodoSeleccionado };

  let updatedNode = resolveGenerico(nodo, values);

  let array = [...nodes];

  let index = array.findIndex((node) => node.id === nodoSeleccionado?.id);

  if (updatedNode.position === undefined) {
    updatedNode.position = {
      x: updatedNode.xPos,
      y: updatedNode.yPos,
    };
  }

  array[index] = updatedNode;

  return array;
};

const resolveGenerico = (nodoSeleccionado, values) => {
  let nodo = { ...nodoSeleccionado };
  if (nodo) {
    nodo.data = { ...values };
  }

  return nodo;
};
