export function getCompletedPercentage(flow) {
  let completedPercentage;
  if (flow?.executionCount > 0) {
    completedPercentage = (flow?.completedCount * 100) / flow?.executionCount;
  } else {
    completedPercentage = 0;
  }

  return completedPercentage.toFixed(0);
}
