import { useContext, useEffect, useState } from 'react';
import { CampaignStatCard } from './CampaignStatCard';
import { GlobalContext } from '../../../../context/GlobalContext';
import { getManyCampaignsMetrics } from '../../../../../helpers/fetchData/fetchCampaigns';
import { getTasa } from '../utils';

export const AllCampaignsStats = ({ filteredCampaigns }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [campaignsMetrics, setCampaignsMetrics] = useState(null);
  const [loadingMetrics, setLoadingMetrics] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      setLoadingMetrics(true);

      const campaignIds = filteredCampaigns.map((campaign) => campaign._id);

      getManyCampaignsMetrics(instance, campaignIds)
        .then((res) => {
          if (res?.status === 200) {
            setCampaignsMetrics(res.data);
          } else {
            messageApi.error(
              'Ocurrió un error al obtener las métricas de las campañas'
            );
          }
        })
        .catch((error) => {
          console.error(error);
          messageApi.error(
            'Ocurrió un error al obtener las métricas de las campañas'
          );
        })
        .finally(() => {
          setLoadingMetrics(false);
        });
    }
  }, [instance, filteredCampaigns]);

  const getGeneralStats = () => {
    if (campaignsMetrics) {
      const result = campaignsMetrics.reduce(
        (acc, campaign) => {
          acc.cantRecipients += campaign.cantRecipients;
          acc.cantSent += campaign.cantSent;
          acc.cantDelivered += campaign.cantDelivered;
          acc.cantRead += campaign.cantRead;
          acc.cantFailed += campaign.cantFailed;
          acc.cantReplied += campaign.cantReplied;

          const campaignObj = filteredCampaigns.find(
            (c) => c._id === campaign.campaignId
          );

          // Cantidad de enviados de solo las campañas que tienen botones:
          if (campaignObj && campaignObj?.hasButtons) {
            acc.cantSentWithButtons += campaign.cantSent;
          }

          return acc;
        },
        {
          cantRecipients: 0,
          cantSent: 0,
          cantDelivered: 0,
          cantRead: 0,
          cantFailed: 0,
          cantReplied: 0,
          cantSentWithButtons: 0
        }
      );

      return result;
    }
    return {
      cantRecipients: 0,
      cantSent: 0,
      cantDelivered: 0,
      cantRead: 0,
      cantFailed: 0,
      cantReplied: 0,
      cantSentWithButtons: 0
    };
  };

  const {
    cantRecipients,
    cantSent,
    cantDelivered,
    cantRead,
    cantFailed,
    cantReplied,
    cantSentWithButtons
  } = getGeneralStats();

  return (
    <div className='AllCampaignStats'>
      <CampaignStatCard
        stat='recipients'
        data={cantRecipients}
        loading={loadingMetrics}
      />
      <CampaignStatCard
        stat='sent'
        data={cantSent}
        extra={`Tasa de envío: ${getTasa(cantSent, cantRecipients)}%`}
        loading={loadingMetrics}
      />
      <CampaignStatCard
        stat='delivered'
        data={cantDelivered}
        extra={`Tasa de entrega: ${getTasa(cantDelivered, cantSent)}%`}
        loading={loadingMetrics}
      />
      <CampaignStatCard
        stat='read'
        data={cantRead}
        extra={`Tasa de lectura: ${getTasa(cantRead, cantDelivered)}%`}
        loading={loadingMetrics}
      />
      <CampaignStatCard
        stat='replied'
        data={cantReplied}
        loading={loadingMetrics}
        extra={`Tasa de respuesta: ${getTasa(
          cantReplied,
          cantSentWithButtons
        )}%`}
      />
      <CampaignStatCard
        stat='failed'
        data={cantFailed}
        extra={`Tasa de falla: ${getTasa(cantFailed, cantRecipients)}%`}
        loading={loadingMetrics}
      />
    </div>
  );
};
