import { Button, Drawer, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { FlowContext } from "../../../../context/FlowContext";
import {
  agregarVariableWhatsapp,
  handleItemValue,
  handleListName,
} from "../../../../../helpers/utilidadesTemplates";
import { handleFlowVariables } from "../utils/handleFlowVariables";
import { updateNode } from "../utils/updateNode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useUpdateNodeInternals } from "reactflow";
import { handleVariablesIndexadasLista } from "../utils/handleVariablesIndexadasLista";
import { MediaForm } from "./MediaForm";
import { BotonAgregarVariable } from "./BotonAgregarVariable";
import { BoxInfo } from "../../../box-info/BoxInfo";
import { HeaderForm } from "./componentsForm/HeaderForm";
import FormattedTextArea from "../../../formattedTextArea/FormattedTextArea";
import { HeaderTextOrMedia } from "./HeaderTextOrMedia";
import { LineaGris } from "../../../linea-gris/LineaGris";
import { validateListContent } from "../utils/validateListContent";

export const IterableListForm = () => {
  const {
    flowVariables,
    setFlowVariables,
    nodoSeleccionado,
    setMostrarDrawer,
    setNodes,
    setEdges,
  } = useContext(FlowContext);

  const { messageApi } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [nombreNodo, setNombreNodo] = useState("");
  const [messageType, setMessageType] = useState("text");
  const [headerMediaType, setHeaderMediaType] = useState("image");
  const [preview, setPreview] = useState("");
  const [listContent, setListContent] = useState({
    button: "",
    sections: [{ rows: [{ title: "", description: "" }] }],
  });
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");

  const [selectedVariable, setSelectedVariable] = useState(null);
  const [url, setURL] = useState("");
  const [location, setLocation] = useState({});
  const [caption, setCaption] = useState("");
  const [mostrarDrawerMedia, setMostrarDrawerMedia] = useState(false);

  const updateNodeInternals = useUpdateNodeInternals();

  const getHttpVariableName = () => {
    if (selectedVariable) {
      return selectedVariable.substring(selectedVariable.indexOf("_") + 1);
    } else {
      return "";
    }
  };

  const getHttpOptions = () => {
    let array = [];

    array = flowVariables
      ?.filter((element) => element.type === "itbl")
      ?.map((element) => {
        return {
          value: element.name,
          label: element.name,
        };
      });

    return array;
  };

  const onFinish = (values) => {
    if (!bodyText) {
      return messageApi.info("Es necesario que ingrese cuerpo del mensaje");
    }
    values.label = nombreNodo;
    values.auto = false;
    values.headerType = messageType;

    values.headerText = headerText;
    values.bodyText = bodyText;
    values.footerText = footerText;

    values.variable = {};

    const error = validateListContent(listContent, messageApi);
    if (error) {
      return;
    }
    

    let rowsCount = 0;

    listContent?.sections?.forEach((section) => {
      rowsCount += section.rows?.length;
    });

    values.handleCount = rowsCount;

    if (selectedVariable) {
      values.variableSeleccionada = selectedVariable;
      values.variable.name =
        "index_" +
        selectedVariable.substring(selectedVariable.indexOf("_") + 1);
      values.variable.type = "string";

      handleVariablesIndexadasLista(
        selectedVariable,
        nodoSeleccionado.id,
        flowVariables,
        setFlowVariables,
        messageApi
      );

      let flag = handleFlowVariables(
        setFlowVariables,
        nodoSeleccionado.id,
        values.variable,
        messageApi,
        setNodes,
        setEdges
      );

      if (!flag) return;
    } else {
      return messageApi.error(
        "Debe seleccionar una variable antes de continuar"
      );
    }

    if (messageType === "media") {
      values.headerMediaType = headerMediaType;

      if (headerMediaType === "location") {
        if (!location.latitude || !location.longitude) {
          return messageApi.info("Latitud y longitud son campos obligatorios");
        }

        values.location = location;
      } else {
        if (url) {
          values.url = url;
        } else {
          return messageApi.info("Ingrese una url");
        }

        if (caption) {
          values.caption = caption;
          values.bodyText = "";
        }
      }
    } else {
      values.caption = null;
    }

    if (listContent) {
      delete listContent.sections[0]?.title;

      values.listContent = listContent;

      let articlesCount = 0;

      if (listContent.sections.length > 10) {
        return messageApi.info(
          "La lista no puede contener mas de 10 secciones"
        );
      }

      listContent.sections.forEach(
        (section) => (articlesCount += section.rows.length)
      );

      if (articlesCount > 30) {
        return messageApi.info(
          "La lista no puede contener mas de 30 articulos en total"
        );
      }
    }

    setNodes((prevState) => updateNode(nodoSeleccionado, values, prevState));
    setMostrarDrawer(false);
    updateNodeInternals(nodoSeleccionado.id);
  };

  const decorarItemLista = (seccion, variable) => {
    setListContent((prevState) => {
      let object = { ...prevState };

      object.sections[0].rows[0][seccion] = agregarVariableWhatsapp(
        object.sections[0].rows[0][seccion],
        variable,
        seccion
      );
      // object.sections[0].rows[0][seccion] + "${" + variable.name + "}";

      return object;
    });
  };

  useEffect(() => {
    if (nodoSeleccionado?.data) {
      let data = nodoSeleccionado.data;

      if (data.headerText !== undefined) {
        setHeaderText(data.headerText);
      }

      if (data.bodyText !== undefined) {
        setBodyText(data.bodyText);
      }

      if (data.footerText !== undefined) {
        setFooterText(data.footerText);
      }

      if (data.listContent !== undefined) {
        const clone = structuredClone(data.listContent);
        setListContent(clone);
      }

      if (data.headerType !== undefined) {
        setMessageType(data.headerType);
      } else {
        setMessageType("text");
      }

      let mediaType = "image";

      if (data.headerMediaType || data.headerMediaImage) {
        mediaType = data.headerMediaType || data.headerMediaImage;
      }
      setHeaderMediaType(mediaType);

      if (data.variableSeleccionada) {
        setSelectedVariable(data.variableSeleccionada);
      }

      setNombreNodo(data.label);

      if (data.location) {
        const clone = structuredClone(data.location);
        setLocation(clone);
      }

      if (data.url) {
        setURL(data.url);
      }

      if (data.caption) {
        setCaption(data.caption);
      }

      let variableName = "";

      if (data.variableName) {
        let variable = data.variableName;
        variableName = variable.substring(variable.indexOf("_") + 1);
      }

      form.setFieldsValue({
        variableName: variableName,
        listName: data.listName,
      });
    }
  }, [nodoSeleccionado]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="formBotones"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      className="form-nodos"
    >
      <HeaderForm
        nombreNodo={nombreNodo}
        setNombreNodo={setNombreNodo}
        icon={
          <ImWhatsapp style={{ color: "var(--whatsapp-color)" }} size={32} />
        }
      />

      <Form.Item label={<span className="label-secciones">ENCABEZADO</span>}>
        <HeaderTextOrMedia
          messageType={messageType}
          setMessageType={setMessageType}
          nodeId={nodoSeleccionado?.id}
          headerMediaType={headerMediaType}
          setHeaderMediaType={setHeaderMediaType}
          text={headerText}
          setText={setHeaderText}
          url={url}
          setURL={setURL}
          setPreview={setPreview}
          caption={caption}
          setCaption={setCaption}
          location={location}
          setLocation={setLocation}
          context="interactive-list"
        />
      </Form.Item>
      <LineaGris />
      <FormattedTextArea
        value={bodyText}
        setValue={setBodyText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textBody"
        label="CUERPO"
        boldLabel
        minRows={3}
      />
      <LineaGris />
      <FormattedTextArea
        value={footerText}
        setValue={setFooterText}
        placeholder="Escribir texto"
        nodeId={nodoSeleccionado?.id}
        elementId="textFooter"
        label="PIE DEL MENSAJE"
        boldLabel
        minRows={3}
        showDecorators={false}
      />
      <LineaGris />
      <Form.Item label={<b>SELECCIONE EL BODY RESPONSE INDEXADO</b>}>
        <Select
          options={getHttpOptions()}
          showSearch
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(v) => setSelectedVariable(v)}
          value={selectedVariable}
        />
      </Form.Item>
      {selectedVariable && (
        <div className="form-item-column">
          <span className="horizontal" style={{ width: "100%" }}>
            <Form.Item label="Nombre del indice" style={{ width: "100%" }}>
              <Input
                addonBefore="index_"
                disabled={true}
                style={{ color: "#252525" }}
                value={getHttpVariableName()}
              />
            </Form.Item>
          </span>
          <BoxInfo
            message='Esta variable tomará el indice que se seleccione en la lista para
              ser utilizado posteriormente. Este indice remplazará "[idx]"'
          />
        </div>
      )}
      {selectedVariable && (
        <>
          <Form.Item
            label={<span className="label-secciones">NOMBRE DE LA LISTA</span>}
          >
            <Input
              value={listContent?.button}
              maxLength={20}
              showCount
              onChange={(v) =>
                setListContent(handleListName(listContent, v.target.value))
              }
            />
          </Form.Item>
          <div style={{ marginBottom: "16px" }}>
            {listContent?.sections?.map((element, index) => {
              return (
                <div className="template-crear-lista" key={index}>
                  {listContent.sections[index].rows?.length > 0 && (
                    <div className="lista-item">
                      {listContent.sections[index].rows?.map((element, row) => {
                        return (
                          <div className="item-wrapper" key={`${index}-${row}`}>
                            <span className="horizontal-list">
                              <b>Nombre del item</b>
                            </span>
                            <Input.TextArea
                              style={{ maxWidth: "400px" }}
                              id="title"
                              autoSize={{ minRows: 2 }}
                              onChange={(v) =>
                                setListContent(
                                  handleItemValue(
                                    listContent,
                                    v.target.value,
                                    index,
                                    row,
                                    "title"
                                  )
                                )
                              }
                              value={
                                listContent.sections[index]?.rows[row]?.title
                              }
                            />
                            <BotonAgregarVariable
                              context="iterableList"
                              seccion="title"
                              decorarTexto={decorarItemLista}
                              nodeId={nodoSeleccionado?.id}
                              showContextVars={false}
                              indexedVars={true}
                              selectedVariable={selectedVariable}
                            />
                            <span>
                              <b>Descripción</b>
                              <Input.TextArea
                                style={{ maxWidth: "400px" }}
                                id="description"
                                autoSize={{ minRows: 2 }}
                                onChange={(v) =>
                                  setListContent(
                                    handleItemValue(
                                      listContent,
                                      v.target.value,
                                      index,
                                      row,
                                      "description"
                                    )
                                  )
                                }
                                value={
                                  listContent.sections[index]?.rows[row]
                                    ?.description
                                }
                              />
                            </span>
                            <BotonAgregarVariable
                              context="iterableList"
                              seccion="description"
                              decorarTexto={decorarItemLista}
                              nodeId={nodoSeleccionado?.id}
                              showContextVars={false}
                              indexedVars={true}
                              selectedVariable={selectedVariable}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      <Drawer
        open={mostrarDrawerMedia}
        width={450}
        className="drawer-vars"
        push={{ distance: "450px" }}
        destroyOnClose
      >
        <MediaForm
          url={url}
          setURL={setURL}
          setMostrarDrawerMedia={setMostrarDrawerMedia}
          mediaType={headerMediaType}
        />
      </Drawer>
      <Form.Item className="form-custom-footer">
        <div className="botones-wrapper-content">
          <Button
            type="primary"
            htmlType="submit"
            className="btn-guardar"
            size="large"
          >
            Guardar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            type="secondary"
            onClick={() => {
              setMostrarDrawer(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
