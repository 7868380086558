import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const BuscadorBloqueos = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters
}) => {
  return (
    <div className='TablaBloqueos-Buscador'>
      <Input
        size='small'
        placeholder='Buscar...'
        value={selectedKeys[0]}
        onChange={(event) => {
          setSelectedKeys(event.target.value ? [event.target.value] : []);
          confirm(false);
        }}
        prefix={<SearchOutlined />}
      />
      <Button
        type='primary'
        size='small'
        onClick={() => clearFilters({ confirm: true, closeDropdown: true })}
      >
        Reiniciar
      </Button>
    </div>
  );
};
